const calculateDelay = (minDelay: number, retryNumber: number) => {
  return minDelay * 2 ** retryNumber;
};

/**
 * Returns a function which will be called with an exponential delay in case the given predicate
 * function evaluates to `true`.
 */
export const retry = (
  callback: () => void,
  predicateFn: () => boolean,
  {
    nOfRetries = 10,
    minDelay = 100,
    handleStatusChange,
  }: { nOfRetries: number; minDelay: number; handleStatusChange: (b: boolean) => void }
) => {
  let currentTry = 1;

  const callWithRetry = () => {
    if (currentTry === nOfRetries) {
      // just quit, something is wrong
      if (typeof handleStatusChange === 'function') {
        handleStatusChange(false);
      }
      return;
    }

    // loading is in progress, so we have to wait a bit
    if (predicateFn() && currentTry < nOfRetries) {
      currentTry += 1;
      setTimeout(callWithRetry, calculateDelay(minDelay, currentTry));
      if (typeof handleStatusChange === 'function') {
        handleStatusChange(true);
      }
      return;
    }

    // this should happen most of the time
    if (typeof handleStatusChange === 'function') {
      handleStatusChange(false);
    }
    callback();
  };

  return callWithRetry;
};

/**
 * Retries a promise a specified number of times with a delay between each attempt.
 *
 * @param {() => Promise<unknown>} promiseFunc - The function that returns a promise to retry.
 * @param {number} delay - The delay in milliseconds between each retry.
 * @param {number} maxRetries - The maximum number of retries.
 * @returns {Promise} - A new promise that resolves to the value of the original promise if it fulfills, or rejects with the reason of the last rejection if all retries fail.
 */
export const RETRY_DELAY = 5000;
export const RETRY_ATTEMPTS = 1;
export function retryPromise(promiseFunc: () => Promise<unknown>, delay: number, maxRetries: number) {
  return new Promise((resolve, reject) => {
    function attempt(count: number) {
      promiseFunc()
        .then(resolve)
        .catch(error => {
          // eslint-disable-next-line no-console
          console.warn('Retrying promise', error);
          // eslint-disable-next-line no-console
          console.trace();
          if (count >= maxRetries) reject(error); // If max retries reached, reject with the last error
          else setTimeout(() => attempt(count + 1), delay); // Retry after delay
        });
    }
    // Start the first attempt
    attempt(0);
  });
}

/**
 * Runs functions with the given args, returns true if any of the functions return a truthy value
 * @param  {...any} functions
 */
export const or =
  (...functions) =>
  (...params) => {
    return functions.some(fn => fn(...params));
  };

export const and =
  (...functions) =>
  (...params) =>
    functions.every(callback => callback(...params));

export const not =
  callback =>
  (...params) =>
    !callback(...params);

/**
 * No operation function which always returns undefined.
 */
export const noop = () => undefined;
