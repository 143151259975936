/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

class FakeLoadingDelay extends React.Component {
  static defaultProps = {
    timeout: 5000,
    mayRenderChildren: true,
  };

  state = {
    isFakeLoading: true,
  };

  timeout = null;

  componentDidMount() {
    const { timeout } = this.props;
    this.timeout = setTimeout(() => this.setState({ isFakeLoading: false }), timeout);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { loadingChildren, mayRenderChildren, children } = this.props;
    const { isFakeLoading } = this.state;
    const renderChildren = !isFakeLoading && mayRenderChildren;

    return renderChildren ? children : loadingChildren;
  }
}

export default FakeLoadingDelay;
