/* eslint-disable */
// @ts-nocheck
import cx from 'classnames';

import Handle from '../Handle';
import * as utils from '../utils';
import classes from '../styles.module.less';

const noop = () => {};

export default function createSlider(Component) {
  return class ComponentEnhancer extends Component {
    static displayName = `ComponentEnhancer(${Component.displayName})`;

    static defaultProps = {
      ...Component.defaultProps,
      className: '',
      min: 0,
      max: 100,
      step: 1,
      marks: {},
      handle({ index, ...restProps }) {
        delete restProps.dragging;
        if (restProps.value === null) {
          return null;
        }

        return <Handle {...restProps} key={index} />;
      },
      onBeforeChange: noop,
      onChange: noop,
      onAfterChange: noop,
      disabled: false,
      dots: false,
      railStyle: {},
      readOnly: false,
      dotStyle: {},
      activeDotStyle: {},
    };

    constructor(props) {
      super(props);

      this.handlesRefs = {};
    }

    componentDidMount() {
      // Snapshot testing cannot handle refs, so be sure to null-check this.
      this.document = this.sliderRef && this.sliderRef.ownerDocument;

      const { autoFocus, disabled, readOnly } = this.props;
      if (autoFocus && !disabled && !readOnly) {
        this.focus();
      }
    }

    componentWillUnmount() {
      if (super.componentWillUnmount) super.componentWillUnmount();
      this.removeDocumentEvents();
    }

    onMouseDown = e => {
      if (e.button !== 0) {
        return;
      }
      let position = utils.getMousePosition(e);
      if (!utils.isEventFromHandle(e, this.handlesRefs)) {
        this.dragOffset = 0;
      } else {
        const handlePosition = utils.getHandleCenterPosition(e.target);
        this.dragOffset = position - handlePosition;
        position = handlePosition;
      }
      // this.removeDocumentEvents();
      this.onStart(position);
      this.addDocumentMouseEvents();
    };

    onTouchStart = e => {
      if (utils.isNotTouchEvent(e)) return;

      let position = utils.getTouchPosition(e);
      if (!utils.isEventFromHandle(e, this.handlesRefs)) {
        this.dragOffset = 0;
      } else {
        const handlePosition = utils.getHandleCenterPosition(e.target);
        this.dragOffset = position - handlePosition;
        position = handlePosition;
      }
      this.onStart(position);
      this.addDocumentTouchEvents();
      utils.pauseEvent(e);
    };

    onFocus = e => {
      const { onFocus } = this.props;
      if (utils.isEventFromHandle(e, this.handlesRefs)) {
        const handlePosition = utils.getHandleCenterPosition(e.target);
        this.dragOffset = 0;
        this.onStart(handlePosition);
        utils.pauseEvent(e);
        if (onFocus) {
          onFocus(e);
        }
      }
    };

    onBlur = e => {
      const { onBlur } = this.props;
      this.onEnd();
      if (onBlur) {
        onBlur(e);
      }
    };

    onMouseUp = () => {
      if (this.handlesRefs[this.prevMovedHandleIndex]) {
        this.handlesRefs[this.prevMovedHandleIndex].clickFocus();
      }
    };

    onMouseMove = e => {
      if (!this.sliderRef) {
        this.onEnd();
        return;
      }
      const position = utils.getMousePosition(e);
      this.onMove(e, position - this.dragOffset);
    };

    onTouchMove = e => {
      if (utils.isNotTouchEvent(e) || !this.sliderRef) {
        this.onEnd();
        return;
      }

      const position = utils.getTouchPosition(e);
      this.onMove(e, position - this.dragOffset);
    };

    onKeyDown = e => {
      if (this.sliderRef && utils.isEventFromHandle(e, this.handlesRefs)) {
        this.onKeyboard(e);
      }
    };

    onClickMarkLabel = (e, value) => {
      e.stopPropagation();
      this.onChange({ value });
      // eslint-disable-next-line react/no-unused-state
      this.setState({ value }, () => this.onEnd(true));
    };

    getSliderStart() {
      const slider = this.sliderRef;
      const rect = slider.getBoundingClientRect();

      return rect.left + window.pageXOffset;
    }

    getSliderLength() {
      const slider = this.sliderRef;
      if (!slider) {
        return 0;
      }

      const coords = slider.getBoundingClientRect();
      return coords.width;
    }

    saveSlider = slider => {
      this.sliderRef = slider;
    };

    saveHandle(index, handle) {
      this.handlesRefs[index] = handle;
    }

    focus() {
      if (!this.props.disabled) {
        this.handlesRefs[0].focus();
      }
    }

    blur() {
      if (!this.props.disabled) {
        Object.keys(this.handlesRefs).forEach(key => {
          if (this.handlesRefs[key] && this.handlesRefs[key].blur) {
            this.handlesRefs[key].blur();
          }
        });
      }
    }

    calcValue(offset) {
      const { min, max } = this.props;
      const ratio = Math.abs(Math.max(offset, 0) / this.getSliderLength());
      const value = ratio * (max - min) + min;
      return value;
    }

    calcValueByPos(position) {
      const pixelOffset = position - this.getSliderStart();
      const nextValue = this.trimAlignValue(this.calcValue(pixelOffset));
      return nextValue;
    }

    calcOffset(value) {
      const { min, max } = this.props;
      const ratio = (value - min) / (max - min);
      return ratio * 100;
    }

    addDocumentTouchEvents() {
      if (this.props.readOnly) {
        this.removeDocumentEvents();
      } else {
        window.addEventListener('touchmove', this.onTouchMove);
        window.addEventListener('touchend', this.onEnd);
      }
    }

    addDocumentMouseEvents() {
      if (this.props.readOnly) {
        this.removeDocumentEvents();
      } else {
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onEnd);
      }
    }

    removeDocumentEvents() {
      /* eslint-disable no-unused-expressions */
      window.removeEventListener('touchmove', this.onTouchMove);
      window.removeEventListener('touchend', this.onEnd);

      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onEnd);
      /* eslint-enable no-unused-expressions */
    }

    render() {
      const { className, marks, disabled, children, style, railStyle, readOnly } = this.props;
      const { tracks, handles } = super.render();

      const sliderClassName = cx(classes.slider, className, {
        [classes.sliderwithMarks]: Object.keys(marks).length,
        [classes.disabled]: disabled,
      });

      return (
        <div
          aria-hidden="true"
          ref={this.saveSlider}
          className={sliderClassName}
          onTouchStart={disabled || readOnly ? noop : this.onTouchStart}
          onMouseDown={disabled || readOnly ? noop : this.onMouseDown}
          onMouseUp={disabled || readOnly ? noop : this.onMouseUp}
          onKeyDown={disabled || readOnly ? noop : this.onKeyDown}
          onFocus={disabled || readOnly ? noop : this.onFocus}
          onBlur={disabled || readOnly ? noop : this.onBlur}
          style={style}
        >
          <div className={classes.rail} style={railStyle} />
          {tracks}
          {handles}
          {children}
        </div>
      );
    }
  };
}
