/* eslint-disable */
// @ts-nocheck
import cx from 'classnames';

import { toUSD } from '@/util/currency';

import Field from '@/components/Field';
import Input from '@/components/input';
import Slider from '@/components/Slider';

import classes from './styles.module.less';

const normalizeInputSize = (inputSize, max) => {
  if (inputSize == null) {
    // if inputSize wasn't provided, determine via max
    // these values needs to be discussed
    if (max <= 100) {
      return 'small';
    }
    if (max > 100 && max < 10000) {
      return 'medium';
    }

    return 'large';
  }

  return inputSize;
};

const SliderWithInput = ({
  'data-testid': dataTestId,
  inputClassName,
  inputSize,
  label,
  labelPosition = 'right',
  max = 100,
  min = 0,
  name,
  pattern = '[0-9]*',
  placeholder,
  readOnly,
  sliderClassName,
  step = 1,
  track = true,
  fast = false,
  onKeyPress,
}) => (
  <Field id={name} label={label} name={name} readOnly={readOnly} inputType="number" fast={fast}>
    {({ error, form, name: fieldName, id, onChange, onBlur, value, ...inputProps }) => {
      const normalizedInputSize = normalizeInputSize(inputSize, max);
      const wrapperClasses = cx(classes.wrapper, classes[`${normalizedInputSize}Input`]);

      const setFieldValue = val => form.setFieldValue(fieldName, val);

      const inputOnChange = e => {
        let val = e.target.value;

        // if the value is an empty string, use is clearing the field
        // we shouldn't parse this to the minimum to let them type
        if (val !== '' && val < min) {
          val = min;
        }

        if (val !== '' && val > max) {
          val = max;
        }

        setFieldValue(val);
      };

      const maxLabel = label === '$' ? toUSD(max, 0) : max;
      const minLabel = label === '$' ? toUSD(min, 0) : min;

      return (
        <div className={wrapperClasses}>
          <Input
            autoComplete="off"
            className={cx(classes.input, inputClassName)}
            data-testid={dataTestId}
            error={error}
            id={id}
            label={label}
            labelPosition={labelPosition}
            max={max}
            min={min}
            name={fieldName}
            onBlur={onBlur}
            onChange={inputOnChange}
            pattern={pattern}
            placeholder={placeholder}
            step={step}
            type="number"
            value={value}
            width={16}
            {...inputProps}
            onKeyPress={onKeyPress}
          />
          <Slider
            className={cx(classes.slider, sliderClassName)}
            error={error}
            onChange={setFieldValue}
            max={max}
            maxLabel={maxLabel}
            min={min}
            minLabel={minLabel}
            step={step}
            track={track}
            value={value}
            readOnly={inputProps.readOnly}
          />
        </div>
      );
    }}
  </Field>
);

export default SliderWithInput;
