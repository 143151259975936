/* eslint-disable */
// @ts-nocheck
import React from 'react';

import Track from './common/Track';
import createSlider from './common/createSlider';
import * as utils from './utils';

class Slider extends React.Component {
  constructor(props) {
    super(props);

    const defaultValue = props.defaultValue !== undefined ? props.defaultValue : props.min;
    const value = props.value !== undefined ? props.value : defaultValue;

    this.state = {
      value: this.trimAlignValue(value),
      dragging: false,
    };
  }

  onChange(state) {
    const { props } = this;
    const isNotControlled = !('value' in props);
    const nextState = state.value > this.props.max ? { ...state, value: this.props.max } : state;
    if (isNotControlled) {
      this.setState(nextState);
    }

    const changedValue = nextState.value;
    props.onChange(changedValue);
  }

  onStart(position) {
    this.setState({ dragging: true });
    const { props } = this;
    const prevValue = this.getValue();
    props.onBeforeChange(prevValue);

    const value = this.calcValueByPos(position);
    this.startValue = value;
    this.startPosition = position;

    if (value === prevValue) return;

    this.prevMovedHandleIndex = 0;

    this.onChange({ value });
  }

  onEnd = force => {
    const { dragging } = this.state;
    this.removeDocumentEvents();
    if (dragging || force) {
      this.props.onAfterChange(this.getValue());
    }
    this.setState({ dragging: false });
  };

  onMove(e, position) {
    utils.pauseEvent(e);
    const { value: oldValue } = this.state;
    const value = this.calcValueByPos(position);
    if (value === oldValue) return;

    this.onChange({ value });
  }

  onKeyboard(e) {
    const valueMutator = utils.getKeyboardValueMutator(e);

    if (valueMutator) {
      utils.pauseEvent(e);
      const { state } = this;
      const oldValue = state.value;
      const mutatedValue = valueMutator(oldValue, this.props);
      const value = this.trimAlignValue(mutatedValue);
      if (value === oldValue) return;

      this.onChange({ value });
      this.props.onAfterChange(value);
      this.onEnd();
    }
  }

  getValue() {
    return this.state.value;
  }

  getLowerBound() {
    return this.props.min;
  }

  getUpperBound() {
    return this.state.value;
  }

  trimAlignValue(v, nextProps = {}) {
    if (v === null) {
      return null;
    }

    const mergedProps = { ...this.props, ...nextProps };
    const val = utils.ensureValueInRange(v, mergedProps);
    return utils.ensureValuePrecision(val, mergedProps);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!('value' in nextProps || 'min' in nextProps || 'max' in nextProps)) return;

    const prevValue = this.state.value;
    const value = nextProps.value !== undefined ? nextProps.value : prevValue;
    const nextValue = this.trimAlignValue(value, nextProps);
    if (nextValue === prevValue) return;

    this.setState({ value: nextValue });

    // if the value being passed into the slider is an empty string
    // then something else is controlling it so we shouldn't attempt
    // to change it to the slider's parsed value. We should still update
    // the slider's state with the parsed value so that it will display
    // the thumb in the minimum position.
    if (value !== '' && utils.isValueOutOfRange(value, nextProps)) {
      this.props.onChange(nextValue);
    }
  }

  render() {
    const { error, disabled, handle: handleGenerator, max, maxLabel, min, minLabel, track = true } = this.props;
    const { value, dragging } = this.state;
    const offset = this.calcOffset(value);
    const handle = handleGenerator({
      error,
      offset,
      value,
      dragging,
      disabled,
      min,
      max,
      index: 0,
      ref: h => this.saveHandle(0, h),
    });

    const tracks = track ? (
      <Track offset={0} length={offset} minLabel={minLabel || min} maxLabel={maxLabel || max} />
    ) : null;

    return { tracks, handles: handle };
  }
}

export default createSlider(Slider);
