/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { sub } from 'date-fns';
import { useQuery, useMutation } from '@apollo/client';
import { NB_SAVINGS_TYPE_ID, NB_SAVINGS_TYPES } from 'common/model/budget';
import { ACTIVITY_STATUSES } from 'common/model/activity';
import { GOAL_STATUSES } from 'common/model/goal';

import { serialize, goalFragment, activityFragment } from '@/domain/goals';
import { setSafeGoalValues, setSafeActivityValues } from '@/domain/UserState';

import { SessionContext } from '@/services/session';

import {
  goalsAndActivitiesByKeys as measureGoalsAndActivitiesByKeys,
  getOnlyExclusiveActivitiesOfGoal as getOnlyExclusiveActivitiesOfMeasureGoal,
  getFlatActivitiesOfGoal as getFlatActivitiesOfMeasuredGoal,
  getActivitiesMapOfGoals as getActivitiesMapOfMeasureGoals,
} from '@/pages/Goals/MeasureGoals/config';

import { LIST_ACTIVITIES, GET_DASHBOARD_DATA, LIST_SAVINGS } from '@/graphql/queries';
import { CREATE_GOALS, DELETE_GOALS, UPDATE_ACTIVITIES, UPDATE_GOALS } from '@/graphql/mutations';

function enrichGoalsWithActivities(goal, getAllGoalActivities) {
  return {
    ...goal,
    activities: getAllGoalActivities(goal.type).map(activityConfig => ({
      status: ACTIVITY_STATUSES.ACTIVE,
      type: activityConfig.id,
    })),
  };
}

function enrichGoalPayloadWithActivitiesToUpdate(
  activitiesState,
  goalsState,
  goal,
  getOnlyExclusiveActivities,
  goalNewStatus,
  activitiesNewStatus
) {
  const goalPayload = {
    ...goal,
    activities: getOnlyExclusiveActivities(goalsState, goal.type)
      .map(activityConfig => {
        if (activitiesState[activityConfig.id].activityData) {
          const activityToUpdatePayload = {
            activityId: activitiesState[activityConfig.id].activityData.activityId,
          };

          if (activitiesNewStatus) {
            activityToUpdatePayload.status = activitiesNewStatus;
          }
          return activityToUpdatePayload;
        }
        return null;
      })
      .filter(activity => !!activity),
  };

  if (goalNewStatus) {
    goalPayload.status = goalNewStatus;
  }

  return goalPayload;
}

function buildUpdatedGoalsPayload(
  goalsToUpdate,
  goalsState,
  activitiesState,
  userId,
  getOnlyExclusiveActivities,
  goalNewStatus,
  activitiesNewStatus
) {
  const progressivelyUpdatedGoalsState = { ...goalsState };
  return goalsToUpdate.map(goal => {
    const goalToUpdatePayload = {
      userId,
      goal: enrichGoalPayloadWithActivitiesToUpdate(
        activitiesState,
        progressivelyUpdatedGoalsState,
        goal,
        getOnlyExclusiveActivities,
        goalNewStatus,
        activitiesNewStatus
      ),
    };
    progressivelyUpdatedGoalsState[goal.type] = null;
    return goalToUpdatePayload;
  });
}

function buildDeleteGoalsPayload(goalsToDelete, goalsState, activitiesState, userId, getOnlyExclusiveActivities) {
  return buildUpdatedGoalsPayload(goalsToDelete, goalsState, activitiesState, userId, getOnlyExclusiveActivities);
}

function buildCompleteGoalsPayload(
  goalsToDelete,
  goalsState,
  activitiesState,
  userId,
  getOnlyExclusiveActivities,
  goalNewStatus,
  activitiesNewStatus
) {
  return buildUpdatedGoalsPayload(
    goalsToDelete,
    goalsState,
    activitiesState,
    userId,
    getOnlyExclusiveActivities,
    goalNewStatus,
    activitiesNewStatus
  );
}

function serializeAndEnrichGoals(goals, goalsByKeysConfig) {
  return goals.reduce(
    (serializedGoals, goalData) => {
      if (!Object.keys(goalsByKeysConfig).includes(goalData.type)) {
        return serializedGoals;
      }
      if (goalData.status === GOAL_STATUSES.COMPLETED) {
        return {
          completedGoals: [
            ...serializedGoals.completedGoals,
            { ...goalsByKeysConfig[goalData.type], goalData: setSafeGoalValues(goalData) },
          ],
          ongoingGoals: serializedGoals.ongoingGoals,
        };
      }
      return {
        completedGoals: serializedGoals.completedGoals,
        ongoingGoals: {
          ...serializedGoals.ongoingGoals,
          [goalData.type]: {
            ...goalsByKeysConfig[goalData.type],
            goalData: setSafeGoalValues(goalData),
          },
        },
      };
    },
    { completedGoals: [], ongoingGoals: {} }
  );
}

function serializeAndEnrichActivities(activities, activitiesConfigByKeys) {
  activities
    .filter(
      activityData =>
        activityData.status !== ACTIVITY_STATUSES.CLOSED && activityData.status !== ACTIVITY_STATUSES.DELETED
    )
    .forEach(activityData => {
      if (activitiesConfigByKeys[activityData.type]) {
        activitiesConfigByKeys[activityData.type].activityData = setSafeActivityValues(activityData);
      }
    });
  return activitiesConfigByKeys;
}

type GoalsContextType = {
  goals: {
    loading: boolean;
    loadingActivities: boolean;
    loadingSavings: boolean;
    data: {
      goals: Record<string, unknown>;
      activities: any;
      savings: unknown[];
      notProcessedActivities: any;
      completedGoals: any;
      expenses: unknown[];
      budget: Record<string, unknown> | null;
      debts: unknown[];
    };
    error: any;
    errorActivities: any;
    errorSavings: any;
  };
  activityModalContentVisible: boolean;
  setActivityModalContentVisible: (value: boolean) => void;
  createGoals: (goalsToCreate: any) => Promise<any>;
  deleteGoals: (goalsToDelete: any) => Promise<any>;
  completeGoals: (goalsToUpdate: any) => Promise<any>;
  updateActivities: (activitiesToUpdate: any) => Promise<any>;
  updateGoals: (goalsToUpdate: any) => Promise<any>;
  isUpdatingActivities: boolean;
};

const initialState = {
  goals: {
    loading: false,
    loadingActivities: false,
    data: null,
    error: null,
    errorActivities: null,
  },
  activityModalContentVisible: false,
  setActivityModalContentVisible: () => {},
  createGoals: () => {},
  deleteGoals: () => {},
  completeGoals: () => {},
  updateActivities: () => {},
  isUpdatingActivities: false,
};

export const GoalsContext = React.createContext<GoalsContextType>(initialState);

const savingsTypes = [
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.OTHER_SAVINGS],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EMERGENCY_FUND],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EDUCATION_SAVINGS],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_400X],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_IRA],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.LARGE_PURCHASE],
];

const GoalsProvider = ({ children }) => {
  const [activityModalContentVisible, setActivityModalContentVisible] = React.useState(false);
  const { userId, isAuthenticated } = React.useContext(SessionContext);
  const {
    loading: loadingSavings,
    data: dataSavings,
    error: errorSavings,
  } = useQuery(LIST_SAVINGS, {
    variables: { userId },
    skip: !isAuthenticated,
  });

  const {
    loading: loadingActivities,
    data: dataActivities,
    error: errorActivities,
  } = useQuery(LIST_ACTIVITIES, {
    variables: { userId },
    skip: !isAuthenticated,
  });

  const lastMonthDate = sub(new Date(), { month: 1 });
  const lastMonth = lastMonthDate.getMonth().toString();
  const lastYear = lastMonthDate.getFullYear().toString();
  const { loading, data, error } = useQuery(GET_DASHBOARD_DATA, {
    variables: {
      userId,
      lastMonth,
      lastYear,
      expenses: savingsTypes,
    },
    skip: !isAuthenticated,
  });

  const goalsConfigByKeys = measureGoalsAndActivitiesByKeys;
  const getAllGoalActivities = getFlatActivitiesOfMeasuredGoal;
  const getGoalsActivityMap = getActivitiesMapOfMeasureGoals;
  const getOnlyExclusiveActivities = getOnlyExclusiveActivitiesOfMeasureGoal;

  const { ongoingGoals: goals, completedGoals } = serializeAndEnrichGoals((data || {}).goals || [], goalsConfigByKeys);
  const notProcessedActivities = React.useMemo(() => [...((dataActivities || {}).activities || [])], [dataActivities]);
  const activities = serializeAndEnrichActivities(
    (dataActivities || {}).activities || [],
    getGoalsActivityMap(Object.keys(goals))
  );

  const savings = dataSavings?.listSavings;

  // this creates a new item so we need to update cache manually
  const [createGoals] = useMutation(CREATE_GOALS, {
    update(cache, { data: { createGoals: newGoals } }) {
      cache.modify({
        fields: {
          goals(existingGoalRefs = [], { readField }) {
            const newGoalRefs = newGoals
              .filter(newGoal => !existingGoalRefs.some(ref => readField('goalId', ref) === newGoal.goalId))
              .map(newGoal =>
                cache.writeFragment({
                  data: newGoal,
                  fragment: goalFragment,
                })
              );
            return [...existingGoalRefs, ...newGoalRefs];
          },
          activities(existingActivitiesRefs = [], { readField }) {
            const newActivities = newGoals.reduce(
              (accActivities, newGoal) => [...accActivities, ...newGoal.activities],
              []
            );
            const newActivitiesRefs = newActivities
              .filter(
                newActivity => !existingActivitiesRefs.some(ref => readField('activityId', ref) === newActivity.goalId)
              )
              .map(newActivity =>
                cache.writeFragment({
                  data: newActivity,
                  fragment: activityFragment,
                })
              );
            return [...existingActivitiesRefs, ...newActivitiesRefs];
          },
        },
      });
    },
  });

  // this deletes an item so we need to update cache manually
  const [deleteGoals] = useMutation(DELETE_GOALS, {
    update(cache, { data: { deleteGoals: deletedGoals } }) {
      cache.modify({
        fields: {
          goals(existingGoalRefs = [], { readField }) {
            const newGoalRefs = existingGoalRefs.filter(
              existingGoalRef =>
                !deletedGoals.some(deletedGoal => readField('goalId', existingGoalRef) === deletedGoal.goalId)
            );
            return [...newGoalRefs];
          },
        },
      });
    },
  });
  const [updateGoals] = useMutation(UPDATE_GOALS);
  const [updateActivities, { loading: isUpdatingActivities }] = useMutation(UPDATE_ACTIVITIES);

  const value = React.useMemo(() => {
    return {
      goals: {
        loading,
        loadingActivities,
        loadingSavings,
        data: {
          ...data,
          goals,
          activities,
          savings,
          notProcessedActivities,
          completedGoals,
        },
        error,
        errorActivities,
        errorSavings,
      },
      activityModalContentVisible,
      setActivityModalContentVisible,
      isUpdatingActivities,
      createGoals: async goalsToCreate => {
        const response = await createGoals({
          variables: {
            input: goalsToCreate.map(goal => ({
              userId,
              goal: enrichGoalsWithActivities(serialize(goal), getAllGoalActivities),
            })),
          },
        });
        return response.data;
      },
      deleteGoals: async goalsToDelete => {
        const response = await deleteGoals({
          variables: {
            input: buildDeleteGoalsPayload(goalsToDelete, goals, activities, userId, getOnlyExclusiveActivities),
          },
        });
        return response.data;
      },
      completeGoals: async goalsToUpdate => {
        const response = await updateGoals({
          variables: {
            input: buildCompleteGoalsPayload(
              goalsToUpdate,
              goals,
              activities,
              userId,
              getOnlyExclusiveActivities,
              GOAL_STATUSES.COMPLETED,
              ACTIVITY_STATUSES.CLOSED
            ),
          },
        });
        return response.data;
      },
      // TODO check if activity is created in DB before updating
      updateActivities: async activitiesToUpdate => {
        const response = await updateActivities({
          variables: {
            input: activitiesToUpdate.map(activity => ({
              userId,
              activity: {
                ...activity,
              },
            })),
          },
        });
        return response.data;
      },
      updateGoals: async goalsToUpdate => {
        const response = await updateGoals({
          variables: {
            input: goalsToUpdate.map(goal => ({
              userId,
              goal: {
                ...goal,
              },
            })),
          },
        });
        return response.data;
      },
    };
  }, [
    loading,
    loadingActivities,
    loadingSavings,
    data,
    goals,
    activities,
    savings,
    notProcessedActivities,
    completedGoals,
    error,
    errorActivities,
    errorSavings,
    activityModalContentVisible,
    createGoals,
    userId,
    getAllGoalActivities,
    deleteGoals,
    getOnlyExclusiveActivities,
    updateGoals,
    updateActivities,
    isUpdatingActivities,
  ]);

  return <GoalsContext.Provider value={value}>{children}</GoalsContext.Provider>;
};

export default GoalsProvider;
