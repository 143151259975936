/**
 * Given a valid email (local-part@domain) returns the same email with the local part obfuscated
 */
export const obfuscateEmailAddress = (email: string) => {
  try {
    const [localPart, domain] = email.split('@');
    const obfuscatedLocalPart = localPart.length > 2 ? `${localPart.substring(0, 2)}***` : `${localPart.charAt(0)}***`;
    return `${obfuscatedLocalPart}@${domain}`;
  } catch (e) {
    return null;
  }
};

const trailingSlashRegex = /\/+$/;

/**
 * Removes trailing slash from the given path. Root path ("/") will be ignored.
 */
export const removeTrailingSlash = (path: string) => {
  if (typeof path !== 'string') {
    throw new Error(`removeTrailingSlash only supports strings, argument was ${typeof path}`);
  }

  if (path.length === 0 || path.length === 1) {
    // "/" is a valid path, don't remove that
    return path;
  }

  return path.replace(trailingSlashRegex, '');
};
export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

/**
 * Returns the given string with the first letter capitalized and the rest lowercased.
 * @param s The string to capitalize
 * @returns The capitalized string
 * @example
 * toTitleCase('hello world') // 'Hello world'
 * toTitleCase('HELLO WORLD') // 'Hello world'
 * toTitleCase('hELLO wORLD') // 'Hello world'
 */
export const toTitleCase = (s: string) => {
  return s.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
