/* eslint-disable */
// @ts-nocheck

import { READ_ONLY_DEBT_FIELD_NAMES } from 'common/model/debt';
import { READ_ONLY_EXPENSE_FIELD_NAMES } from 'common/model/expense';

import { EXPENSE_EDIT } from '@/domain/paths';

import { trans } from '@/util/i18n';

export const isQuestionCompound = question => {
  return Array.isArray(question.questions);
};

export const isQuestionConditionallyRendered = question => {
  return typeof question.getQuestions === 'function';
};

export const getQuestionName = question => question.id;

/**
 * Get the answer from a question using a selector. If selector is not there, read the answer
 * from the dedicated field.
 * @param {*} question
 * @param {*} state
 */
export const getAnswer = (question, answersWrapper) => {
  if (isQuestionCompound(question)) {
    return question.questions.map(childQuestion => getAnswer(childQuestion, answersWrapper));
  }

  // in case the selector is there
  if (typeof question.getAnswer === 'function') {
    return question.getAnswer(answersWrapper);
  }

  return answersWrapper.answers[question.id];
};

/**
 * Similar to "getAnswer", but without a wrapper - reads field values from a record.
 * @param {*} question
 * @param {*} model
 */
export const getFieldValue = (question, record) => {
  if (isQuestionCompound(question)) {
    return question.questions.map(childQuestion => getFieldValue(childQuestion, record));
  }

  // in case the selector is there
  if (typeof question.getAnswer === 'function') {
    return question.getAnswer(record);
  }
  return record[question.id];
};

/**
 * Compares the given changes to the previous state and determines if user changed the answer
 * @param {*} state
 * @param {*} changes
 * @param {*} questions
 */
export const haveAnswersChanged = (state, changes, questions) => {
  if (!questions || questions.length === 0) {
    return false;
  }

  // iterate over answers (they contain child question IDs for compound/conditional questions)
  return Object.keys(changes).some(questionId => {
    // returns the parent question in case of compound/conditionally rendered questions

    // can't use Array.find on upper level since we sometimes need to have inner loops returning results as well
    let question;
    questions.forEach(q => {
      // compound questions
      if (isQuestionCompound(q)) {
        question = q.questions.find(childQuestion => childQuestion.id === questionId);
      }

      // conditionally rendered questions

      // this logic will not work in case we hide the parent question (the condition)
      if (isQuestionConditionallyRendered(q)) {
        question = q.getQuestions(changes).find(childQuestion => childQuestion.id === questionId);
      }

      // regular questions
      if (q.id === questionId) {
        question = q;
      }
    });

    if (!question) {
      return true;
    }

    const answer = changes[questionId];

    return getAnswer(question, state) !== answer;
  });
};

/** Util function to perform the trimming of field inputs
 * @param {[Object]} questions - question configs for this form
 * @param {Object} values - submit payload
 */
export const trimQ = (question, values) => {
  const trimmedQ = values[question.id];
  values = { ...values, [question.id]: trimmedQ.trim() };
  return values;
};
const mappingAnswersValues = {
  0: 'no',
  1: 'yes',
  planningAhead: 'planning-ahead',
  receivedBill: 'received-bill',
  billPastDue: 'bill-past-due',
  hasPrivateInsurance: 'has-private-insurance',
  hasMedicare: 'has-medicare',
  hasMedicaid: 'has-medicaid',
  uninsured: 'uninsured',
  rent: 'rent',
  own: 'own',
};
export const formatAnswerValue = value => {
  const values = Object.keys(mappingAnswersValues);
  if (values.includes(value.toString())) {
    return trans(`i18n-questions:answer.${mappingAnswersValues[value]}`);
  }
  return value;
};
export const getDebtEditingPath = (question, accountPath, debtId) => {
  return `${accountPath}/${debtId}/edit/${getQuestionName(question)}`;
};
export const debtEditingPathGenerator = (accountPath, debtId) => question => {
  return getDebtEditingPath(question, accountPath, debtId);
};
export const getExpenseEditingPath = question => {
  return `${EXPENSE_EDIT}/${getQuestionName(question)}`;
};

export const isFieldEditable = question => {
  if (!question) {
    return false;
  }

  return (
    !READ_ONLY_DEBT_FIELD_NAMES.some(fieldName => fieldName === getQuestionName(question)) &&
    !READ_ONLY_EXPENSE_FIELD_NAMES.some(fieldName => fieldName === getQuestionName(question))
  );
};
