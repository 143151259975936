/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';
import { differenceInWeeks, differenceInMonths, differenceInQuarters } from 'date-fns';
import { ACCOUNT_STATUSES, ACCOUNT_TYPES } from 'common/model/debt';
import { NB_SAVINGS_TYPES, NB_SAVINGS_TYPE_ID } from 'common/model/budget';
import { SAVINGS_TYPES } from 'common/model/savings';

const minDebtPaymentSelectorFactory = debtTypes => {
  return createSelector(
    (state, props) => ({ state, props }),
    ({ state }) => {
      const { debts } = state;
      const totalMinPayment = debts.reduce((totalMinPaymentSizeCC, debt) => {
        if (debt.status === ACCOUNT_STATUSES.ACTIVE && debtTypes.includes(debt.type)) {
          return totalMinPaymentSizeCC + debt.minMonthlyPayment;
        }
        return totalMinPaymentSizeCC;
      }, 0);

      return totalMinPayment;
    }
  );
};
export const creditCardMinMonthlyPaymentSelector = minDebtPaymentSelectorFactory([ACCOUNT_TYPES.CREDIT_CARD]);
export const studentMinMonthlyPaymentSelector = minDebtPaymentSelectorFactory([
  ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN,
  ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN,
]);
export const otherMinMonthlyPaymentSelector = minDebtPaymentSelectorFactory([
  ACCOUNT_TYPES.MORTGAGE,
  ACCOUNT_TYPES.HOME_EQUITY_LOAN,
  ACCOUNT_TYPES.AUTO_LOAN,
  ACCOUNT_TYPES.PERSONAL_LOAN,
  ACCOUNT_TYPES.MEDICAL_BILL,
]);

export const getManageUnplannedExpenseGoalDetails = createSelector(
  (state, props) => ({ state, props }),
  ({ state }) => {
    const { goals } = state;
    const { emergencySavings, expenseValue } = goals.manageUnplannedExpenseMeasured.goalData.answers;
    return { emergencySavings, expenseValue };
  }
);

export const getReduceMyExpensesGoalDetails = createSelector(
  (state, props) => ({ state, props }),
  ({ state }) => {
    const { goals } = state;
    const { monthlyExpensesGoal, initialMonthValue } = goals.reduceMyExpensesMeasured.goalData.answers;
    return { monthlyExpensesGoal, initialMonthValue };
  }
);

// this selector is not used for large purchase savings goal
export const getGoalDueDateFactory = goalType => {
  return createSelector(
    (state, props) => ({ state, props }),
    ({ state }) => {
      const { goals, savings } = state;
      return (
        goals[goalType].goalData.answers.goalDate ||
        goals[goalType].goalData.answers.payOffDate ||
        goals[goalType].goalData.answers.retirementDate ||
        savings.find(saving => saving.type === SAVINGS_TYPES.RAINY_DAY).dueDate
      );
    }
  );
};

// this selector is not used for large purchase savings goal
export const getCompletedGoalDueDateFactory = goalType => {
  return createSelector(
    (state, props) => ({ state, props }),
    ({ state }) => {
      const { completedGoals, ongoingGoalsWithCompletedStats, savings: savingsList } = state;

      const savings = savingsList.find(savingsItem => savingsItem.type === SAVINGS_TYPES.RAINY_DAY);

      const allCompletedGoals = [...completedGoals, ...ongoingGoalsWithCompletedStats];
      const goal = allCompletedGoals.find(goalIterator => goalIterator.id === goalType);

      return (
        goal?.goalData.answers.goalDate ||
        goal?.goalData.answers.payOffDate ||
        goal?.goalData.answers.retirementDate ||
        savings?.dueDate
      );
    }
  );
};

export const getSaveForRainyDayGoalDetails = createSelector(
  (state, props) => ({ state, props }),
  ({ state }) => {
    const { savings: savingsList, savingsTransactions } = state;

    const savings = savingsList.find(savingsItem => savingsItem.type === SAVINGS_TYPES.RAINY_DAY);

    const savingsGoal = savings?.amountToSave || 0;
    const alreadySaved = savingsTransactions.reduce((alreadySavedAcc, transactionsMonth) => {
      return (
        alreadySavedAcc +
        transactionsMonth.list
          .filter(transaction => transaction.date > savings.dateCreated)
          .reduce((alreadySavedInMonthAcc, transaction) => {
            if (transaction.expense === NB_SAVINGS_TYPE_ID.EMERGENCY_FUND) {
              return alreadySavedInMonthAcc + transaction.amount;
            }
            return alreadySavedInMonthAcc;
          }, 0)
      );
    }, 0);

    const goalDueDate = savings.dueDate;
    const nrOfContributions = differenceInMonths(goalDueDate, Date.now()) || 1;

    const leftToPay = savingsGoal - alreadySaved;
    const contribution = leftToPay / nrOfContributions;

    return { ...state, savingsGoal, alreadySaved, contribution };
  }
);

export const getSaveForLargePurchaseGoalDetails = createSelector(
  (state, props) => ({ state, props }),
  ({ state, props }) => {
    const { savings: savingsList, savingsTransactions } = state;
    const { savingsId } = props;

    const savings = savingsList.find(savingsItem => savingsItem.savingsId === savingsId);

    const savingsGoal = savings?.amountToSave || 0;
    const alreadySaved = savingsTransactions.reduce((alreadySavedAcc, transactionsMonth) => {
      return (
        alreadySavedAcc +
        transactionsMonth.list.reduce((alreadySavedInMonthAcc, transaction) => {
          if (
            transaction.expense === NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.LARGE_PURCHASE] &&
            transaction.savingsLargePurchaseId === savingsId
          ) {
            return alreadySavedInMonthAcc + transaction.amount;
          }
          return alreadySavedInMonthAcc;
        }, 0)
      );
    }, 0);

    const goalDueDate = savings.dueDate;
    const nrOfContributions = differenceInMonths(goalDueDate, Date.now()) || 1;

    const leftToPay = savingsGoal - alreadySaved;
    const contribution = leftToPay / nrOfContributions;

    return { ...state, savings, savingsGoal, alreadySaved, contribution };
  }
);

export const getSaveForRetirementGoalDetails = createSelector(
  (state, props) => ({ state, props }),
  ({ state }) => {
    const { goals, savingsTransactions } = state;

    const startingDate = goals.saveForRetirementMeasured.goalData.dateCreated;
    const { retirementDate, frequency, contribution } = goals.saveForRetirementMeasured.goalData.answers;
    let estimatedNrOfContributions;
    switch (frequency) {
      case 'weekly':
        estimatedNrOfContributions = differenceInWeeks(retirementDate, startingDate, { roundingMethod: 'floor' });
        break;
      case 'biweekly':
        estimatedNrOfContributions =
          Math.round(differenceInWeeks(retirementDate, startingDate, { roundingMethod: 'floor' }) / 2) || 1;
        break;
      case 'monthly':
        estimatedNrOfContributions = differenceInMonths(retirementDate, startingDate) || 1;
        break;
      case 'bimonthly':
        estimatedNrOfContributions =
          Math.round(differenceInMonths(retirementDate, startingDate, { roundingMethod: 'floor' }) / 2) || 1;
        break;
      case 'quarterly':
        estimatedNrOfContributions =
          differenceInQuarters(retirementDate, startingDate, { roundingMethod: 'floor' }) || 1;
        break;
      default:
        estimatedNrOfContributions = differenceInWeeks(retirementDate, startingDate, { roundingMethod: 'floor' }) || 1;
    }

    const savingsGoal = estimatedNrOfContributions * contribution;
    const alreadySaved = savingsTransactions.reduce((alreadySavedAcc, transactionsMonth) => {
      return (
        alreadySavedAcc +
        transactionsMonth.list
          .filter(transaction => transaction.date > goals.saveForRetirementMeasured.goalData.dateCreated)
          .reduce((alreadySavedInMonthAcc, transaction) => {
            if (
              transaction.expense === NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_400X] ||
              transaction.expense === NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_IRA]
            ) {
              return alreadySavedInMonthAcc + transaction.amount;
            }
            return alreadySavedInMonthAcc;
          }, 0)
      );
    }, 0);

    return { ...state, savingsGoal, alreadySaved, contribution, frequency };
  }
);
