/* eslint-disable */
// @ts-nocheck

import React, { ReactNode, FunctionComponent } from 'react';
import { Card, Rail, Ref, Sticky } from 'semantic-ui-react';
import cx from 'classnames';

import classes from './styles.module.less';

export type RightSidePanelProps = {
  disableSticky: boolean;
  rightSideContent: ReactNode;
};

const RightSidePanel: FunctionComponent<RightSidePanelProps> = ({ disableSticky, rightSideContent }) => {
  const refContainer = React.useRef<HTMLDivElement>(null);

  return (
    <Card.Content>
      {disableSticky ? (
        rightSideContent
      ) : (
        <Ref innerRef={refContainer}>
          <Rail position="right" className={classes.rail}>
            <Sticky offset={76} context={refContainer}>
              {rightSideContent}
            </Sticky>
          </Rail>
        </Ref>
      )}
    </Card.Content>
  );
};

/**
 * This component provides two-sided panel wrapper.
 * On the left side we have content which is behaving in the standard browser way.
 * But on the right side we have a panel that stays fixed in the browser viewport as we scroll within the container.
 * Once we scroll pass the container - the right side panel is no longer sticky to the browser viewport.
 * The sticky behavior is happening only on desktop-sized devices.
 */
export type ContainerWithStickyContentProps = {
  disableSticky: boolean;
  leftSideContent: ReactNode;
  rightSideContent: ReactNode;
};

const ContainerWithStickyContent: FunctionComponent<ContainerWithStickyContentProps> = ({
  disableSticky = false,
  leftSideContent,
  rightSideContent,
}) => (
  <Card.Group className={classes.cardGroup}>
    <Card className={cx(classes.card, classes.leftCard)}>
      <Card.Content>{leftSideContent}</Card.Content>
    </Card>
    <Card className={cx(classes.card, classes.rightCard)}>
      <RightSidePanel disableSticky={disableSticky} rightSideContent={rightSideContent} />
    </Card>
  </Card.Group>
);

export default ContainerWithStickyContent;
