import { ActionFunction, assign, EventObject } from 'xstate';
import { ApolloQueryResult } from '@apollo/client';

import {
  BUDGET_INCOME_PATH,
  BUDGET_EXPENSES_AMOUNTS_PATH,
  BUDGET_OVERVIEW_PATH,
  BUDGET_OVERVIEW_TRANSACTIONS_PATH,
  BASE_PATH_BUDGET_EDIT,
  BUDGET_EDIT_EXPENSES_AMOUNTS_PATH,
  BUDGET_EXPENSES_PATH,
  BUDGET_OVERVIEW_STATISTICS_PATH,
} from '@/domain/paths';
import { trackStartBudget, trackFinishBudget } from '@/domain/stateMachines/budget';
import { getInitialExpenseFormValues, validateCustomExpenses, validateEmptyExpenses } from '@/domain/budget/expenses';
import { BudgetStatusT, cleanupTransactions, mergeTransactionsResult } from '@/domain/budget';
import { TPaymentPlan } from '@/domain/debtsPlan';
import { TAccount } from '@/domain/debts';

import history from '@/util/history';
import { createStorage } from '@/util/storage';
import { generateUniqueId, objectMatchesId } from '@/util/object';

import { Transactions } from '@/types/generated/Transactions';
import { Budget } from '@/types/generated/globalTypes';

import { InitialContextT } from '..';

const storage = createStorage('session');

// Navigation Actions.
const navigateToBudgetMonthlyIncome = () => history.push(BUDGET_INCOME_PATH);
const navigateToBudgetToSelectMonthlyExpenses = () => history.push(BUDGET_EXPENSES_PATH);
const navigateToBudgetToEnterMonthlyExpensesAmounts = () => history.push(BUDGET_EXPENSES_AMOUNTS_PATH);
const navigateToBudgetStatistics = () => history.push(BUDGET_OVERVIEW_STATISTICS_PATH);
const navigateToBudgetOverview = () => history.push(BUDGET_OVERVIEW_PATH);
const navigateToBudgetOverviewTransactionTab = () => history.push(BUDGET_OVERVIEW_TRANSACTIONS_PATH);
const navigateToBudgetEdit = () => history.push(BASE_PATH_BUDGET_EDIT);
const navigateToEditBudgetToEnterMonthlyExpensesAmounts = () => history.push(BUDGET_EDIT_EXPENSES_AMOUNTS_PATH);
const navigateBackFromDeposit: ActionFunction<InitialContextT, EventObject & { data: { navigate: Function } }> = (
  _,
  event
) => event.data.navigate();
const navigateFromUEditFlowBudgetIncome: ActionFunction<
  InitialContextT,
  EventObject & { data: { navigate: Function } }
> = (_, event) => {
  const { navigate } = event.data;

  if (navigate) {
    return navigate();
  }

  // default navigation
  return navigateToBudgetEdit();
};

const setLoadedBudget = assign<
  InitialContextT,
  EventObject &
    ApolloQueryResult<{ data: { transactions: Transactions[]; budget: Budget & { archivedBudgets: Budget[] } } }>
>({
  budget: (context, event) => {
    if (!event?.data?.data) return { ...context.budget, isLoaded: true };

    const { transactions, budget } = event.data.data;

    if (budget) {
      return {
        expenses: validateEmptyExpenses(budget.expenses),
        customExpenses: validateCustomExpenses(budget?.customExpenses),
        netMonthlyIncome: budget.netMonthlyIncome,
        monthlyModalLastDisplayed: budget.monthlyModalLastDisplayed,
        incompleteDebtExpensesModalDateDisplayed: budget.incompleteDebtExpensesModalDateDisplayed,
        updatedFromSavingsFlag: budget.updatedFromSavingsFlag,
        updatedFromDebtManagerFlag: budget.updatedFromDebtManagerFlag,
        isLoaded: true,
        isCreated: true, // Double check if this is always true in this condition
        status: budget.status as BudgetStatusT,
        archivedBudgets: budget.archivedBudgets,
        lastEdited: budget.lastEdited,
        ...(transactions && { transactions: cleanupTransactions(transactions) }),
      };
    }

    if (transactions?.length) {
      return {
        ...context.budget,
        transactions: cleanupTransactions(transactions),
        isLoaded: true,
      };
    }

    return { ...context.budget, isLoaded: true };
  },
});
const clearBudget = assign<InitialContextT>({
  budget: {
    expenses: getInitialExpenseFormValues(),
    customExpenses: [],
    // Setting this value to null allows us to know if the user has interacted with the budget before
    netMonthlyIncome: null,
    monthlyModalLastDisplayed: null,
    incompleteDebtExpensesModalDateDisplayed: null,
    updatedFromSavingsFlag: null,
    updatedFromDebtManagerFlag: null,
    isCreated: false,
    isLoaded: false,
    transactions: [],
    archivedBudgets: [],
    status: 'INACTIVE',
    lastEdited: 0,
  },
});
const setBudget = assign<InitialContextT, EventObject & ApolloQueryResult<{ data: { upsertBudget: Budget } }>>({
  budget: (context, event) => {
    const { upsertBudget } = event?.data?.data || { upsertBudget: null };

    if (!upsertBudget) {
      return { ...context.budget };
    }

    const { expenses, ...rest } = upsertBudget;
    return {
      ...context.budget,
      expenses: validateEmptyExpenses(expenses),
      ...rest,
      status: rest.status as BudgetStatusT,
    };
  },
});
const setBudgetAndPaymentPlan = assign<
  InitialContextT,
  EventObject &
    ApolloQueryResult<{
      data: {
        upsertBudgetAndUpdatePaymentPlanAvailableFunds: {
          // eslint-disable-next-line no-restricted-globals
          budget: Budget & { status: 'ACTIVE' | 'DRAFT' };
          paymentPlan: TPaymentPlan;
          debts: TAccount[];
        };
      };
    }>
>({
  budget: (context: InitialContextT, event) => {
    const {
      upsertBudgetAndUpdatePaymentPlanAvailableFunds: { budget },
    } = event.data.data;

    const { expenses, netMonthlyIncome, monthlyModalLastDisplayed, status, customExpenses } = budget;

    return {
      ...context.budget,
      status,
      expenses: validateEmptyExpenses(expenses),
      customExpenses: validateCustomExpenses(customExpenses),
      netMonthlyIncome,
      monthlyModalLastDisplayed,
    };
  },
  paymentPlan: (_, event) => {
    const {
      upsertBudgetAndUpdatePaymentPlanAvailableFunds: { paymentPlan },
    } = event.data.data;
    return paymentPlan;
  },
  debts: (context, event) => {
    const {
      upsertBudgetAndUpdatePaymentPlanAvailableFunds: { debts },
    } = event.data.data;

    const updatedDebts = context.debts.map(debt => {
      const updatedDebt = debts.find(d => d.debtId === debt.debtId);
      if (updatedDebt) return updatedDebt;
      return debt;
    });

    return updatedDebts;
  },
});
const setLoadedArchivedBudget = assign<
  InitialContextT,
  EventObject & ApolloQueryResult<{ data: { listBudgetSnapshots: Budget[] } }>
>({
  budget: (context, event) => {
    const { listBudgetSnapshots } = event.data.data;

    if (!listBudgetSnapshots?.length) {
      return { ...context.budget };
    }

    const budgetSnapshots: Budget[] = context.budget.archivedBudgets;
    if (!budgetSnapshots) {
      return {
        ...context.budget,
        archivedBudgets: listBudgetSnapshots,
      };
    }

    const uniqueId = generateUniqueId(listBudgetSnapshots[0]);
    const hasSnapshot = budgetSnapshots.some(snapshot => objectMatchesId(snapshot, uniqueId));

    return {
      ...context.budget,
      archivedBudgets: hasSnapshot ? budgetSnapshots : [...budgetSnapshots, listBudgetSnapshots[0]],
    };
  },
});
const setPendingDebtId = assign<InitialContextT, EventObject & { debtId: string }>({
  pendingDebtId: (_, event) => {
    const { debtId } = event;
    if (debtId) return debtId;
    return '';
  },
});
const clearPendingDebtId = assign<InitialContextT>({ pendingDebtId: '' });
const setConfirmedBudget = () => storage.setItem('confirmedBudget', 'true');
const removeConfirmedBudget = () => storage.removeItem('confirmedBudget');
const setTransactions = assign<
  InitialContextT,
  EventObject & ApolloQueryResult<{ data: { upsertTransactions: Transactions[] } }>
>({
  budget: (context: InitialContextT, event) => {
    const { upsertTransactions } = event.data.data;

    if (!upsertTransactions) {
      return context.budget;
    }

    // TODO handle in better way
    const args = Array.isArray(upsertTransactions) ? upsertTransactions : [upsertTransactions];

    return {
      ...context.budget,
      transactions: mergeTransactionsResult(context.budget.transactions, ...args),
    };
  },
});
const setTransactionsAndPaymentPlan = assign<
  InitialContextT,
  EventObject & ApolloQueryResult<{ data: { upsertTransactions: Transactions[]; updatePaymentPlan: TPaymentPlan } }>
>({
  budget: (context: InitialContextT, event) => {
    const { upsertTransactions } = event.data.data;
    return {
      ...context.budget,
      transactions: mergeTransactionsResult(context.budget.transactions, upsertTransactions),
    };
  },
  paymentPlan: (_, event) => {
    const { updatePaymentPlan: paymentPlanResult } = event.data.data;
    return paymentPlanResult;
  },
});

export {
  navigateToBudgetMonthlyIncome,
  navigateToBudgetToSelectMonthlyExpenses,
  navigateToBudgetToEnterMonthlyExpensesAmounts,
  navigateToBudgetStatistics,
  navigateToBudgetOverview,
  navigateToBudgetOverviewTransactionTab,
  navigateToBudgetEdit,
  navigateToEditBudgetToEnterMonthlyExpensesAmounts,
  navigateBackFromDeposit,
  navigateFromUEditFlowBudgetIncome,
  trackStartBudget,
  trackFinishBudget,
  setConfirmedBudget,
  removeConfirmedBudget,
  setLoadedBudget,
  clearBudget,
  setBudget,
  setBudgetAndPaymentPlan,
  setLoadedArchivedBudget,
  setPendingDebtId,
  clearPendingDebtId,
  setTransactions,
  setTransactionsAndPaymentPlan,
};
