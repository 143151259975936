import { VERTICALS_NAMES } from '@/domain/verticals';

import { trans } from '@/util/i18n';

import AutoFlowConfig from '@/pages/new-onboarding/Auto/config';
import HomeFlowConfig from '@/pages/new-onboarding/Home/config';
import MedicalFlowConfig from '@/pages/new-onboarding/Medical/config';
import OtherFlowConfig from '@/pages/new-onboarding/Other/config';
import medicalImageSrc from '@/pages/landingPages/UnplannedExpenses/common/medicalIcon.svg';
import autoImageSrc from '@/pages/landingPages/UnplannedExpenses/common/carIcon.svg';
import homeImageSrc from '@/pages/landingPages/UnplannedExpenses/common/homeIcon.svg';
import otherImageSrc from '@/pages/landingPages/UnplannedExpenses/common/otherIcon.svg';

export const VERTICALS = {
  [VERTICALS_NAMES.MEDICAL]: {
    title: trans('medical-expenses'),
    translationKey: 'medical-expenses',
    icon: medicalImageSrc,
    config: MedicalFlowConfig,
  },
  [VERTICALS_NAMES.AUTO]: {
    title: trans('i18n-unplanned:car-expenses'),
    translationKey: 'car-expenses',
    icon: autoImageSrc,
    config: AutoFlowConfig,
  },
  [VERTICALS_NAMES.HOME]: {
    title: trans('home-expenses'),
    translationKey: 'home-expenses',
    icon: homeImageSrc,
    config: HomeFlowConfig,
  },
  [VERTICALS_NAMES.OTHER]: {
    title: trans('other'),
    translationKey: 'other',
    icon: otherImageSrc,
    config: OtherFlowConfig,
  },
} as const;
