export * from './auto';
export * from './home';
export * from './medical';
export * from './other';
export * from './goals';

/**
 * AARP
 */
export const AARP = 'https://www.aarp.org';
export const AARP_NO_PROTOCOL = 'www.aarp.org';
export const AARP_SPANISH = 'https://www.aarp.org/espanol';
export const AARP_SPANISH_NO_PROTOCOL = 'www.aarp.org/espanol';
export const AARP_APP_FACEBOOK = 'https://www.facebook.com/aarpmoneymap';
export const AARP_APP_FACEBOOK_SHORT = 'facebook.com/aarpmoneymap';
export const AARP_APP_EMAIL_ADDRESS = 'moneymap@aarp.org';
export const AARP_AVOID_SURPRISE_MEDICAL_BILLS = `${AARP}/money/credit-loans-debt/info-2019/avoid-surprise-medical-bills.html`;
export const AARP_BUILD_RAINY_DAY_FUND = `${AARP}/money/budgeting-saving/info-2019/build-rainy-day-fund.html`;
export const AARP_DISPUTE_MEDICAL_BILLS = `${AARP}/money/credit-loans-debt/info-2019/dispute-medical-bills.html`;
export const AARP_DISPUTE_SURPRISE_MEDICAL_BILLS = `${AARP}/money/credit-loans-debt/info-2019/dispute-medical-bills.html`;
export const AARP_EXPLANATION_OF_BENEFITS = `${AARP}/money/credit-loans-debt/info-2019/explanation-of-benefits.html`;
export const AARP_HOW_TO_READ_YOUR_MEDICAL_BILL = `${AARP}/health/health-care-reform/info-03-2011/how_to_read_your_medical_bill_cpt_codes.html`;
export const AARP_KNOW_ABOUT_DEBT_RELIEF_SCAMS = `${AARP}/money/scams-fraud/info-2019/debt-relief.html`;
export const AARP_LOCAL = 'https://local.aarpfoundation.org/';
export const AARP_LOCAL_ASSISTANCE_DIRECTORY = `${AARP}/benefits-discounts/all/local-assistance-directory/`;
export const AARP_MEDICARE_COMBINE_WITH_MEDICAID = `${AARP}/health/medicare-insurance/info-2018/medicaid-dual-eligible.html`;
export const AARP_MEDICARE_COST = `${AARP}/health/medicare-insurance/info-2018/out-of-pocket-prescription-costs.html`;
export const AARP_MEDICARE_MADE_EASY = `${AARP}/health/medicare-insurance/medicare-basics/`;
export const AARP_MEDICAL_BILLS_AFFECT_CREDIT_SCORE = `${AARP}/money/credit-loans-debt/info-2019/medical-bills-affect-credit-score.html`;
export const AARP_NEGOTIATE_SURPRISE_MEDICAL_BILLS = `${AARP}/money/credit-loans-debt/info-2019/negotiate-surprise-medical-bills.html`;
export const AARP_OUT_OF_POCKET_PRESCRIPTION_COSTS = `${AARP}/health/medicare-insurance/info-2018/out-of-pocket-prescription-costs.html`;
export const AARP_PAYING_MEDICAL_DEBT = `${AARP}/money/credit-loans-debt/info-2019/paying-medical-debt.html`;
export const AARP_PRIVACY_POLICY = `${AARP}/about-aarp/privacy-policy/`;
export const AARP_RAINY_DAY_EMERGENCY_FUND = `${AARP}/money/budgeting-saving/info-2019/rainy-day-emergency-fund.html`;
export const AARP_READING_MEDICAL_BILLS = `${AARP}/money/credit-loans-debt/info-2019/reading-medical-bills.html`;
export const AARP_SCAMS_FRAUD_STEER_CLEAR_OF_CAR_REPAIR_RIPOFFS = `${AARP}/money/scams-fraud/info-2017/steer-clear-of-car-repair-ripoffs.html`;
export const AARP_TERMS_OF_SERVICE = `${AARP}/about-aarp/terms-of-service/`;
export const AARP_TYPES_OF_MEDICARE_PLANS = `${AARP}/health/medicare-qa-tool/types-of-medicare-plans/`;
export const AARP_WHAT_CONSUMERS_NEED_TO_KNOW_ABOUT_MEDICAID = `${AARP}/ppi/info-2019/what-consumers-need-to-know-about-medicaid.html`;
export const AARP_SAVI = `${AARP}/money/credit-loans-debt/student-loan.html`;
export const AARP_HOW_TO_CREATE_A_HOUSEHOLD_BUDGET = `${AARP}/money/budgeting-saving/info-2021/how-to-create-a-household-budget.html`;
export const AARP_POST_PANDEMIC_SPENDING_TIPS = `${AARP}/money/budgeting-saving/info-2021/post-pandemic-spending-tips.html`;

/* AARP help */
export const AARP_MONEY_MAP_HELP = 'https://help.aarp.org/s/topic/0TO4P000000pTApWAM/aarp-money-map/';

// TODO: Fix naming and organization: https://aarpqmo.atlassian.net/browse/AB-1552
export const ABAFREELEGALANSWERS = 'https://abafreelegalanswers.org/';
export const AMERICANBAR_FREE_LEGAL_HELP =
  'https://www.americanbar.org/groups/legal_services/flh-home/flh-free-legal-help/';
export const AMERICANBAR_PROGRAMS =
  'https://www.americanbar.org/groups/delivery_legal_services/resources/programs_to_help_those_with_moderate_income/';
export const AUTOMD_REPAIRCOST = 'https://www.automd.com/repaircost/';
export const BENEFITSCHECKUP = 'https://www.benefitscheckup.org/find-my-benefits/#/prescreen';
export const CANCERFAC = 'https://www.cancerfac.org/';
export const CARTALK_MECHANICS_FILES = 'https://www.cartalk.com/mechanics-files';
export const COMMUNITYCATALYST =
  'https://www.communitycatalyst.org/resources/publications/document/CC-ACAHospitalBillsReport-F.pdf?1434480883';
export const CONSUMERFINANCE_FAIR_CREDIT_REPORTING_ACT =
  'https://www.consumer.ftc.gov/articles/pdf-0096-fair-credit-reporting-act.pdf';
export const CONSUMERFINANCE_LAWS_THAT_LIMIT_DEBT_COLLECTORS =
  'https://www.consumerfinance.gov/ask-cfpb/are-there-laws-that-limit-what-debt-collectors-can-say-or-do-en-329/';
export const CONSUMER_REPORTS_CAR_REPAIR_ESTIMATOR = 'https://www.consumerreports.org/cars/repair';
export const FAIRHEALTHCONSUMER = 'https://www.fairhealthconsumer.org';
export const EDMUNDS_CORNER_GARAGE_VS_DEALER_SERVICE_DEPARTMENT =
  'https://www.edmunds.com/car-maintenance/corner-garage-vs-dealer-service-department.html';
export const FAIRHEALTH_CONSUMER = 'https://www.fairhealthconsumer.org/';
export const FINDLAW_CHOOSING_THE_RIGHT_LAWYER =
  'https://hirealawyer.findlaw.com/choosing-the-right-lawyer/state-bar-associations.html';
export const FINDLAW_LAWYERS = 'https://lawyers.findlaw.com/';
export const GOFUNDME = 'https://www.gofundme.com/';
export const GOFUNDME_START = `${GOFUNDME}start/`;
export const GOFUNDME_FUNDRAISING = `${GOFUNDME_START}medical-fundraising`;
export const GOFUNDME_FUNDRAISING_VERTICAL_HOME = `${GOFUNDME_START}emergency-fundraising`;
export const HEALFUNDR = 'https://www.healfundr.org/';
export const HEALTHCARE = 'https://www.healthcare.gov/';
export const HEALTHCAREBLUEBOOK = 'https://www.healthcarebluebook.com/';
export const HEALTHCARE_BLUE_BOOK = 'https://www.healthcarebluebook.com/';
export const HEALTHCARE_GET_COVERAGE = 'https://www.healthcare.gov/get-coverage/';
export const HEALTHCARE_QUALIFYING_LIFE_EVENT = 'https://www.healthcare.gov/glossary/qualifying-life-event/';
export const HEALTHWELLFOUNDATION_PATIENTS = 'https://www.healthwellfoundation.org/patients';
export const HELOCVSHELOAN = 'https://www.thebalance.com/comparing-home-equity-loans-versus-lines-of-credit-4172493';
export const HILLTOPINSTITUTE =
  'https://www.hilltopinstitute.org/wp-content/uploads/publications/CommunityBenefitStateLawProfiles-June2016.pdf';
export const HIREALAWYER =
  'https://hirealawyer.findlaw.com/do-you-need-a-lawyer/do-you-qualify-for-free-legal-aid.html';
export const IRS_CAPITAL_GAINS_AND_LOSSES = 'https://www.irs.gov/taxtopics/tc409';
export const IRS_WHAT_IF_I_WITHDRAW_MONEY_FROM_MY_IRA =
  'https://www.irs.gov/newsroom/what-if-i-withdraw-money-from-my-ira';
export const LAWHELP = 'https://www.lawhelp.org';
export const LOW_INCOME_HOME_ENERGY_ASSISTANCE_PROGRAM = 'https://liheapch.acf.hhs.gov/help';
export const LSC = 'https://www.lsc.gov/what-legal-aid/find-legal-aid';
export const MEDICAID_CONTACT_STATE_PAGE = 'https://www.medicaid.gov/about-us/contact-us/contact-state-page.html';
export const MEDICAID_OUT_OF_POCKET_COSTS =
  'https://www.medicaid.gov/medicaid/cost-sharing/out-of-pocket-costs/index.html';
export const MEDICAID_ELIGIBILITY = 'https://www.medicaid.gov/medicaid/eligibility/index.html';
export const NRIA = 'http://www.nria.org/index.html';
export const USDA_RURAL_DEVELOPMENT_GRANTS =
  'https://www.rd.usda.gov/programs-services/single-family-housing-repair-loans-grants';
export const WORKING_CARS_FOR_WORKING_FAMILIES = 'https://www.workingcarsforworkingfamilies.org/find-a-program';

export const MEDICAID_MANAGED_CARE = 'https://www.medicaid.gov/medicaid/managed-care/index.html';
export const MEDICAID_NEED_TO_KNOW =
  'https://www.aarp.org/ppi/info-2019/what-consumers-need-to-know-about-medicaid.html';
export const MEDICAID_WHO_IS_ELIGIBLE =
  'https://www.hhs.gov/answers/medicare-and-medicaid/who-is-eligible-for-medicaid/index.html';
export const MEDICAID_STATE_OVERVIEWS = 'https://www.medicaid.gov/state-overviews/index.html';

export const MODESTNEEDS_APPLICATIONS = 'https://www.modestneeds.org/for-applicants/';
export const NACBA = 'https://www.nacba.org';
export const NCLC = 'https://library.nclc.org/?0=ip_login_no_cache%3D34526821a717af345db6fba4a8452e80';
export const NCUA = 'https://mapping.ncua.gov/';
export const NEEDYMEDS = ({ state }: { state: string }) => `https://www.needymeds.org/state-programs/list/${state}`;

/**
 * NERDWALLET
 */
export const NERDWALLET = 'https://www.nerdwallet.com';
export const NERDWALLET_BROKERAGE_COMMISSIONS_FEES = `${NERDWALLET}/blog/investing/brokerage-commissions-fees/`;
export const NERDWALLET_HOW_TO_PAY_OFF_DEBT_IN_COLLECTIONS = `${NERDWALLET}/blog/finance/how-to-pay-off-debt-in-collections/`;
export const NERDWALLET_GUIDE_DEBT_SETTLEMENT_NEGOTIATIONS = `${NERDWALLET}/blog/finance/debt-settlement-negotiations/`;
export const NERDWALLET_SELLING_A_STOCK = `${NERDWALLET}/blog/investing/selling-a-stock/`;

export const NFCC = 'http://nfcc.org';
export const NEWCHOICEHEALTH = 'https://www.newchoicehealth.com/';
export const PLUMFUND_BASE = 'https://www.plumfund.com/';
export const PLUMFUND = `${PLUMFUND_BASE}medical-fund/`;
export const PROBONO = 'https://www.probono.net/aba_oppsguide/';
export const REPAIRPAL = 'https://repairpal.com/';
export const SUPERLAWYERS = 'https://attorneys.superlawyers.com/';
export const STATE_INSURANCE_DEPARTMENT = 'https://www.naic.org/state_web_map.htm';
export const TAFCARES_PATIENTS = 'https://tafcares.org/patients';
export const THEBALANCE_STATUTE_OF_LIMITATIONS_ON_DEBT =
  'https://www.thebalance.com/state-by-state-list-of-statute-of-limitations-on-debt-960881';
export const IRS_CLAIM_HARDSIHP_WITHDRAWAL =
  'https://www.irs.gov/retirement-plans/retirement-plans-faqs-regarding-hardship-distributions#1';
export const AARP_PENALTIES_TAXES_EARLY_WITHDRAWAL =
  'https://www.aarp.org/money/budgeting-saving/info-09-2009/pond_retirement_account_withdrawals.html';
export const ROTH_IRA = 'https://www.rothira.com/roth-ira-withdrawal-rules';

/**
 * USCOURTS
 */
export const USCOURTS = 'https://www.uscourts.gov';
export const USCOURTS_CHAPTER_7 = `${USCOURTS}/services-forms/bankruptcy/bankruptcy-basics/chapter-7-bankruptcy-basics`;
export const USCOURTS_CHAPTER_13 = `${USCOURTS}/services-forms/bankruptcy/bankruptcy-basics/chapter-13-bankruptcy-basics`;

export const PATIENTADVOCATE_CARDIO_NEGOTIATING_MED_COST =
  'https://www.patientadvocate.org/cardio-it-matters-sheets/negotiating-medical-costs/';

export const FORESEE_FEEDBACK_LINK =
  'https://nam12.safelinks.protection.outlook.com/?url=https%3A%2F%2Fsurvey.foresee.com%2Ff%2FbZz0EqsFVx&data=05%7C01%7Crballington%40aarp.org%7C93b645ce892c4976495508daffc77580%7Ca395e38b4b754e4493499a37de460a33%7C0%7C0%7C638103529399520876%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=AFBoowVLjfGMIOO3LNIfpGVQGg7gyIaRiuUgFA5bLa0%3D&reserved=0';
