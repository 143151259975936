import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';

import classes from './MicroCopy.module.less';

export type TMicrocopyProps = {
  children?: ReactNode;
  className?: string;
  textAlign?: string;
  size?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const MicroCopy: FunctionComponent<TMicrocopyProps> = ({
  children,
  className = '',
  textAlign = 'left',
  size = 'mini',
  ...props
}) => {
  const styles = cx(classes.microcopy, className, classes[textAlign], classes[size]);
  return (
    <span {...props} className={styles}>
      {children}
    </span>
  );
};

export default MicroCopy;
