import React from 'react';
import cx from 'classnames';

import Image from '@/components/Image';

import infoImage from '@/images/Info-Icon.svg';

import classes from './InfoIcon.module.less';

const InfoIcon = ({ className }: { className?: string }) => (
  <Image src={infoImage} className={cx(classes.infoIcon, className)} />
);

export default InfoIcon;
