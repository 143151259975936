/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import LegacyCurrencyInput from '@/components/input/LegacyCurrencyInput';
import Field from '@/components/Field';
import { useTranslation } from 'react-i18next';

const LegacyCurrencyInputField = ({
  'aria-label': ariaLabel,
  className,
  // automatically select the field value on focus
  autoSelect = false,
  'data-testid': dataTestId,
  label,
  name,
  onChange,
  placeholder,
  readOnly,
  width,
  hidden = false,
  focus = 'false',
}) => {
  const { t } = useTranslation();
  const placeholderValue = t(placeholder);
  // needed for focus management
  const inputFieldRef = React.useRef(null);

  const selectValueOnFocus = () => {
    // Optional chaining is not working with flow.
    if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.inputEl) {
      inputFieldRef.current.inputEl.select();
    }
  };

  return (
    <Field
      className={className}
      id={name}
      label={label}
      name={name}
      inputType="number"
      onChange={onChange}
      width={width}
      readOnly={readOnly}
      hidden={hidden}
    >
      {({ name: fieldName, value, error, ...inputProps }) => (
        <LegacyCurrencyInput
          inputFieldRef={inputFieldRef}
          aria-label={ariaLabel}
          data-testid={dataTestId}
          name={fieldName}
          placeholder={placeholderValue}
          value={value}
          error={error}
          {...inputProps}
          {...(autoSelect ? { onFocus: selectValueOnFocus } : {})}
          focus={focus}
        />
      )}
    </Field>
  );
};

export default LegacyCurrencyInputField;
