import MicroCopy from '@/components/microcopy';

type PropsT = {
  title?: string;
  description?: string;
};

const SelectItemWithDescription = ({ title, description }: PropsT) => (
  <>
    {title && <div>{title}</div>}
    {description && <MicroCopy>{description}</MicroCopy>}
  </>
);

export default SelectItemWithDescription;
