/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

import { trans } from '@/util/i18n';

import Heading from '@/components/Heading';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';
import CustomContainer from '@/components/CustomContainer';
import BigImageList from '@/components/BigImageList';

const ProsItems = [
  {
    key: 'quick-access-funds',
    header: trans('i18n-unplanned:personal-loan-pros.item-1.header'),
    description: trans('i18n-unplanned:personal-loan-pros.item-1.description'),
  },
  {
    key: 'lower-interest-rates',
    header: trans('i18n-unplanned:personal-loan-pros.item-2.header'),
    description: trans('i18n-unplanned:personal-loan-pros.item-2.description'),
  },
  {
    key: 'predictable-payments',
    header: trans('i18n-unplanned:personal-loan-pros.item-3.header'),
    description: trans('i18n-unplanned:personal-loan-pros.item-3.description'),
  },
];

const Pros = () => (
  <>
    <Heading as="h3">{trans('pros')}</Heading>
    <BigImageList testId="HomeEquityPros" items={ProsItems} />
  </>
);

const ConsItems = [
  {
    key: 'high-interest-rates',
    header: trans('i18n-unplanned:personal-loan-cons.item-1.header'),
    description: trans('i18n-unplanned:personal-loan-cons.item-1.description'),
  },
  {
    key: 'higher-for-lower-scores',
    header: trans('i18n-unplanned:personal-loan-cons.item-2.header'),
    description: trans('i18n-unplanned:personal-loan-cons.item-2.description'),
  },
  {
    key: 'defaulting-expensive',
    header: trans('i18n-unplanned:personal-loan-cons.item-3.header'),
    description: trans('i18n-unplanned:personal-loan-cons.item-3.description'),
  },
];

const Cons = () => (
  <>
    <Heading as="h3">{trans('cons')}</Heading>
    <BigImageList testId="HomeEquityPros" items={ConsItems} />
  </>
);

export const ProsAndConsContent = () => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <>
      <Heading as="h1">{t('personal-loan-pros-cons.heading')}</Heading>
      <Grid relaxed stackable columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Pros />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Cons />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

const ProsAndCons = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <CustomContainer noMargin verticallyPadded>
      <ProsAndConsContent />
      {fromAssessment ? null : (
        <QuestionsForm fullWidth backButton={false}>
          <WizardUpNext>{t('personal-loan-pros-cons.wizard')}</WizardUpNext>
        </QuestionsForm>
      )}
    </CustomContainer>
  );
};

export default ProsAndCons;
