import { PAYMENT_PLAN_STATUSES, getPaymentPlanActivities } from 'common/model/paymentPlan';

import type { TAccount } from '@/domain/debts';

import { CREATE_PAYMENT_PLAN } from '@/graphql/mutations';
import { GET_COMBINED_DEBTS_PLAN, LIST_DEBTS } from '@/graphql/queries';

import type { EventObject } from 'xstate';
import type { InitialContextT } from '..';

type CreatePaymentPlanEvent = EventObject & {
  availableFunds?: number;
  userViewedDisclaimer?: boolean;
};

export const createPaymentPlan = (context: InitialContextT, event: CreatePaymentPlanEvent) => {
  const { client, userId, currentDate } = context;
  const { availableFunds, userViewedDisclaimer = false } = event;

  const placeholderPaymentPlanInfo = {
    amountsPaid: [],
    availableFunds: availableFunds || 0,
    status: PAYMENT_PLAN_STATUSES.DRAFT,
    dateCreated: null,
    dateUpdated: null,
    popover_dismissedMarkAsPaid: null,
    popover_dismissedOtherPaybackPlanOption: null,
    popover_dismissedProgress: null,
    task_callAllYourCreditors: null,
    task_getFreeCreditCounseling: null,
    isDisclaimerDisplayed: userViewedDisclaimer,
    hasUpdatedAvailableFunds: false,
  };
  const optimisticResponse = {
    createPaymentPlan: {
      ...placeholderPaymentPlanInfo,
      userId,
      year: currentDate.year,
      month: currentDate.month,
      lastEdited: Date.now(),
      __typename: 'PaymentPlanOutput',
    },
  };

  return client.mutate({
    mutation: CREATE_PAYMENT_PLAN,
    optimisticResponse,
    // @ts-expect-error Not sure why this errors out
    update(cache, { data: { createPaymentPlan: createPlanResponse } }) {
      // @ts-expect-error Not sure why this errors out
      const { debts: cachedDebts } = cache.readQuery<{ debts: TAccount[] }>({
        query: LIST_DEBTS,
        variables: { userId },
      });

      cache.writeQuery({
        query: GET_COMBINED_DEBTS_PLAN,
        variables: { userId },
        data: { debts: cachedDebts, paymentPlans: [createPlanResponse] },
      });
    },
    variables: {
      input: {
        userId,
        paymentPlan: {
          amountsPaid: [],
          availableFunds: availableFunds || 0,
          month: currentDate.month,
          year: currentDate.year,
          status: PAYMENT_PLAN_STATUSES.DRAFT,
          isDisclaimerDisplayed: userViewedDisclaimer,
          hasUpdatedAvailableFunds: false,
          activities: getPaymentPlanActivities(),
        },
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_COMBINED_DEBTS_PLAN,
        variables: { userId },
      },
    ],
  });
};
