import { accidentQ, carRunningQ } from '@/domain/questions/auto';
import { getAnswer } from '@/domain/questions/util';

export const getAccidentAnswer = userState => getAnswer(accidentQ, userState);

export const getCarRunningAnswer = userState => getAnswer(carRunningQ, userState);

export const hasBeenInAccident = userState => getAccidentAnswer(userState) === 1;

export const noAccident = userState => getAccidentAnswer(userState) === 0;

export const carIsRunning = userState => getCarRunningAnswer(userState) === 1;

export const carIsNotRunning = userState => getCarRunningAnswer(userState) === 0;
