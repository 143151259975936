/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';
import { NB_DEBT_PAYMENTS_TYPE_ID } from 'common/model/budget';
import { ACCOUNT_STATUSES } from 'common/model/debt';

import { NB_DEBT_PAYMENTS_LABELS } from '@/domain/budget';
import { compareAccountStatus, compareAccountType, compareDateCreated, mapAccountTypeToLabel } from "@/domain/debts";

import { sortBy } from '@/util/array';

const getDebts = debts => debts;
const getProps = (_, props) => props;

export const userHasAtLeastOnePendingDebt = createSelector(getDebts, debts =>
  debts.some(debt => debt.status === ACCOUNT_STATUSES.PENDING)
);
export const DEBTS_PLAN_ACCOUNT_SORTING_STRATEGY = [compareAccountStatus, compareDateCreated, compareAccountType];

export const getDebtIdFromProps = createSelector(getProps, props => props.debtId);

export const getAllNicknames = createSelector(getDebts, debts => debts.map(debt => debt.nickname));

export const getDebtById = createSelector(getDebts, getDebtIdFromProps, (debts, debtId) => {
  return debts.find(debt => debtId === debt.debtId);
});

export const getDebtDetailsViewModel = createSelector(getDebtById, debt => {
  if (!debt) {
    return null;
  }
  // TODO:: check if should be translated
  const interestRate = `${debt.interestRate}%`;
  const isPrioritized = debt.isPrioritized ? 'Yes' : 'No';
  const isDelinquent = debt.isDelinquent ? 'Yes' : 'No';
  const daysDelinquent = `${debt.daysDelinquent} ${debt.daysDelinquent === 1 ? 'Day' : 'Days'}`;

  return {
    ...debt,
    typeLabel: mapAccountTypeToLabel(debt.type),
    isDelinquent,
    interestRate,
    isPrioritized,
    daysDelinquent,
  };
});

export const getMonthTransitionDebtsViewModels = createSelector(getDebts, debts =>
  debts.map(debt => getDebtDetailsViewModel(debts, { debtId: debt.debtId }))
);

export const getSortedOnboardingDebtViewModels = createSelector(getDebts, debts =>
  sortBy(debts, DEBTS_PLAN_ACCOUNT_SORTING_STRATEGY).map(debt => {
    const { nickname } = debt;

    return {
      ...debt,
      title: nickname,
      amount: 0,
    };
  })
);

const getStateMachineState = ({ state }) => state;

export const inOnboarding = createSelector(getStateMachineState, state =>
  state.matches('loaded.debtManager.onboarding')
);

export const getUserDebtsAndCount = debts =>
  debts.reduce((acc, { type }) => {
    if (acc[[NB_DEBT_PAYMENTS_TYPE_ID[type]]]) {
      return {
        ...acc,
        [NB_DEBT_PAYMENTS_TYPE_ID[type]]: {
          count: acc[NB_DEBT_PAYMENTS_TYPE_ID[type]].count + 1,
          title: NB_DEBT_PAYMENTS_LABELS[type],
        },
      };
    }
    return { ...acc, [NB_DEBT_PAYMENTS_TYPE_ID[type]]: { count: 1, title: NB_DEBT_PAYMENTS_LABELS[type] } };
  }, {});

export const getNumberOfDebtsWithCount = createSelector(getDebts, debts => getUserDebtsAndCount(debts));
