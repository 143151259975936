import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import Field, { TFieldProps } from '@/components/FormField';

import classes from '../styles.module.less';

const getExpectedPlaceholder = placeholder => {
  if (typeof placeholder === 'function') {
    return placeholder();
  }
  if (typeof placeholder === 'string') {
    return placeholder;
  }
  return '';
};

const getExpectedOptions = options => {
  if (typeof options === 'function') {
    return options();
  }

  return options;
};

const getExpectedSubText = (subText, value) => {
  if (typeof subText === 'function') {
    return subText(value);
  }
  if (typeof subText === 'string') {
    return subText;
  }
  return null;
};

type TSelectField = Omit<TFieldProps, 'onChange'> &
  DropdownProps & {
    subText?: string | ((value: string) => string);
  };

const SelectField = ({
  className = '',
  label,
  name,
  options,
  placeholder,
  subText,
  width,
  onChange,
  hideError = false,
  required = false,
  ...rest
}: TSelectField) => (
  <Field
    className={className}
    id={name}
    inputType="select"
    label={label}
    name={name}
    width={width}
    hideError={hideError}
    required={required}
  >
    {({ form, name: fieldName, value, error }) => (
      <>
        <Dropdown
          className={classes.fieldInput}
          placeholder={getExpectedPlaceholder(placeholder)}
          selection
          options={getExpectedOptions(options)}
          name={fieldName}
          id={fieldName}
          aria-label={label}
          value={value}
          error={error}
          onChange={(event, payload) => {
            void form.setFieldValue(fieldName, payload.value);
            if (onChange) {
              onChange(event, payload);
            }
          }}
          onBlur={() => {
            void form.setFieldTouched(fieldName);
          }}
          {...rest}
          searchInput={{
            'aria-label': label,
            name: fieldName,
          }}
        />
        {getExpectedSubText(subText, value)}
      </>
    )}
  </Field>
);

export default SelectField;
