import { createMachine, InterpreterFrom, StateFrom } from 'xstate';

import { ANIMATION_NAMES, TAllocatedPayments, TAmountsPaid, TPaymentPlan } from '@/domain/debtsPlan';
import monthTransitionStates from '@/domain/stateMachines/debtManager/monthTransitionStates';
import { paymentPlanStates, setPaymentPlan } from '@/domain/stateMachines/debtManager/paymentPlanStates';
import onboardingStates from '@/domain/stateMachines/debtManager/onboardingStates';
import { getInitialExpenseFormValues } from '@/domain/budget/expenses';
import type { TDisqualificationReasons } from '@/domain/debtsPlan';
import loadingStates from '@/domain/stateMachines/loadingStates';

import type { TAnalytics } from '@/services/analytics';

import { getCurrentMonthYear, MonthYear } from '@/util/date';
import { noop } from '@/util/function';
import { envOrDefault } from '@/util/env';
import { isUserSignedIn } from '@/util/authentication';

import type { Transactions } from '@/types/generated/Transactions';
import type { Budget, Savings } from '@/types/generated/globalTypes';
import type { TNotify } from '@/types/notification';

import * as CombinedActions from './actions';
import budgetMachineStates from './budget';
import savingsMachineStates from './savings';

import type { ApolloClient } from '@apollo/client';
import type { CustomTsTypes } from './machineTypes';
import type { BudgetStatusT } from '../budget';
import type { TAccount } from '../debts';

const PAYMENT_PLAN_INTRO_DELAY = 7000;

const initialDate = getCurrentMonthYear();
const initialAllocatedPayments = { amountsPaid: [] };

export const isAuthenticatedGuard = async () => {
  const SKIP_AUTHENTICATION = envOrDefault<boolean>('REACT_APP_UNSAFE_NO_AUTH', false);

  if (SKIP_AUTHENTICATION) {
    const token = sessionStorage.getItem('BYPASS_AUTH_TOKEN');
    if (!token) {
      throw new Error('User is not signed in');
    }
  } else if (!(await isUserSignedIn())) {
    throw new Error('User is not signed in');
  }

  return true;
};

export type BudgetContextT = Budget & {
  isCreated: boolean;
  isLoaded: boolean;
  transactions: Transactions[];
  archivedBudgets: Budget[];
  status: BudgetStatusT;
  __typename?: string;
};

type SavingsContextT = {
  list: Savings[];
  isLoaded: boolean;
  lastSavingsId: string;
  path: string;
  savingsType: string;
};

export interface InitialContextT {
  client: ApolloClient<unknown>;
  analytics: TAnalytics | Record<string, never>;
  notify: TNotify | (() => undefined);
  currentDate: MonthYear;
  selectedDate: MonthYear;
  showPaidOffPopover: boolean;
  showMonthTransitionPaidOffPopover: boolean | null;
  showProgressPopover: boolean;
  path: string;
  debts: TAccount[];
  pendingDebtId: string;
  paymentPlan: TPaymentPlan | null;
  draftPaymentPlan: TPaymentPlan | null;
  archivedPaymentPlans: TPaymentPlan[];
  reasonsForDisqualification: TDisqualificationReasons[];
  allocatedPayments: TAllocatedPayments | { amountsPaid?: never[] };
  previousAmountsPaid: TAmountsPaid;
  budget: BudgetContextT;
  highlights: Record<string, Record<string, unknown>>;
  savings: SavingsContextT;
  sendEvent: Function;
  userId?: string;
}

// TODO: Extract and hopefully simplify
const appMachine = createMachine(
  {
    id: 'appMachine',
    tsTypes: {} as import('./index.typegen').Typegen0 & CustomTsTypes, // TODO: Find solution for Typegen not inheriting state matches properly here
    predictableActionArguments: true,
    schema: {
      context: {} as InitialContextT,
    },
    context: {
      client: {} as ApolloClient<unknown>,
      analytics: {},
      notify: noop,
      currentDate: initialDate,
      selectedDate: initialDate,
      showPaidOffPopover: false,
      showMonthTransitionPaidOffPopover: null,
      showProgressPopover: false,
      path: '',
      debts: [],
      pendingDebtId: '',
      paymentPlan: null,
      draftPaymentPlan: null,
      archivedPaymentPlans: [],
      reasonsForDisqualification: [],
      allocatedPayments: initialAllocatedPayments,
      previousAmountsPaid: [],
      budget: {
        expenses: getInitialExpenseFormValues(),
        customExpenses: [],
        // Setting this value to null allows us to know if the user has interacted with the budget before
        netMonthlyIncome: null,
        monthlyModalLastDisplayed: null,
        incompleteDebtExpensesModalDateDisplayed: null,
        updatedFromSavingsFlag: null,
        updatedFromDebtManagerFlag: null,
        isCreated: false,
        isLoaded: false,
        archivedBudgets: [],
        transactions: [],
        status: 'INACTIVE',
        lastEdited: 0,
      },
      highlights: {
        [ANIMATION_NAMES.CHANGE_TAG]: {
          name: ANIMATION_NAMES.CHANGE_TAG,
          className: 'changed',
          animationKey: '',
          shouldHighlight: false,
        },
        [ANIMATION_NAMES.INSUFFICIENT_FUNDS]: {
          name: ANIMATION_NAMES.INSUFFICIENT_FUNDS,
          className: 'highlighted',
          animationKey: '',
          shouldHighlight: false,
        },
      },
      savings: {
        list: [],
        isLoaded: false,
        lastSavingsId: '',
        path: '',
        savingsType: '',
      },
      sendEvent: noop,
    },
    initial: 'initial',
    states: {
      initial: {
        on: {
          INITIALIZED: {
            target: 'authGuard',
            actions: 'initializeContext',
          },
        },
      },
      authGuard: {
        invoke: [
          {
            id: 'authGuard',
            src: isAuthenticatedGuard,
            onDone: {
              target: 'loading',
            },
            onError: {
              target: 'nonAuthenticated',
            },
          },
        ],
      },
      nonAuthenticated: {
        always: {
          target: 'initial',
          actions: 'initializeContext',
        },
      },
      reinitialize: {
        always: [
          {
            target: 'loading',
            actions: 'resetLoadedStates',
          },
        ],
      },
      loading: { ...loadingStates },
      loaded: {
        type: 'parallel',
        states: {
          budget: { ...budgetMachineStates },
          savings: { ...savingsMachineStates },
          debtManager: {
            states: {
              onboarding: { ...onboardingStates },
              monthTransition: { ...monthTransitionStates },
              paymentPlanIntro: {
                after: {
                  // transition to 'paymentPlan' after PAYMENT_PLAN_INTRO_DELAY seconds
                  [PAYMENT_PLAN_INTRO_DELAY]: {
                    target: 'paymentPlan',
                  },
                },
              },
              paymentPlan: { ...paymentPlanStates },
              refresh: {
                invoke: [
                  {
                    id: 'refreshPaymentPlan',
                    src: 'setPaymentPlan',
                    onDone: {
                      target: '#appMachine.loading',
                    },
                    onError: {
                      target: '#appMachine.loading',
                    },
                  },
                ],
              },
            },
          },
        },
      },
    },
    on: {
      RESET_PATH: {
        actions: 'resetPath',
      },
      REINITIALIZE: {
        target: '.reinitialize',
      },
    },
  },
  {
    // TODO: Add guards
    actions: {
      ...CombinedActions,
      resetPath: CombinedActions.resetPath,
      initializeContext: CombinedActions.initializeContext,
      resetLoadedStates: CombinedActions.resetLoadedStates,
    },
    services: {
      setPaymentPlan,
    },
  }
);

export default appMachine;

type AppMachineT = typeof appMachine;
export type AppMachineStateT = StateFrom<AppMachineT>;
export type AppMachineInterpreterT = InterpreterFrom<AppMachineT>;
export type AppMachineSendT = AppMachineInterpreterT['send'];
