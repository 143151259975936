/* eslint-disable */
// @ts-nocheck
import { isQuestionCompound, isQuestionConditionallyRendered } from '@/domain/questions/util';

import { getPropsFromValidationRules } from '@/util/validation';

import { TQuestionConfigInput, TGetAnswer, TQuestionConfig } from '@/types/domain/question';
import { TObject } from '@/types/common';

import type { AnySchema } from 'yup';

type TGetValidationRules = (id: string) => void;

export type TCreateQuestion = {
  groupIdentifier?: string;
  configuration: TQuestionConfigInput;
  getValidationRules?: TGetValidationRules;
};

export type TCloneQuestionParam = {
  groupIdentifier?: string;
  configuration: TQuestionConfigInput;
  $cachedConfiguration: TQuestionConfigInput;
};

const getTestId = (id: string) => `${id}`;
const getQuestionId = (name: string, groupIdentifier: string) => `${groupIdentifier}_${name}`;
const getShortTitle = (configuration: TQuestionConfigInput) =>
  configuration.shortTitle === undefined ? configuration.title : configuration.shortTitle;

/**
 * Create the question object.
 * @param {object} input
 * @param {String} input.groupIdentifier The name of the group this question belongs to.
 * @param {QuestionConfigInputT} input.configuration The configuration of the question.
 * @param {getValidationRulesT} input.getValidationRules callback with yup validation rules
 * @returns {*} The question object.
 */
const createQuestion = ({ groupIdentifier, configuration, getValidationRules }: TCreateQuestion): TQuestionConfig => {
  const {
    info,
    name,
    title = '',
    titleIconSrc,
    component,
    hidden = false,
    microcopy,
    subtitle,
    infoSubtitle,
    defaultProps,
    links,
    tooltip,
    alternativeLayout,
  } = configuration;
  const id =
    typeof groupIdentifier === 'string' && groupIdentifier !== '' ? getQuestionId(name, groupIdentifier) : name;
  let validationRules;

  // Skip validation rules when it has compound questions or is conditionally rendered
  if (getValidationRules && !isQuestionCompound(configuration) && !isQuestionConditionallyRendered(configuration)) {
    validationRules = getValidationRules(id);
  }

  // selector function may be defined, if not question name is used instead
  const answerSelector: { getAnswer?: TGetAnswer } = {};
  if (typeof configuration.getAnswer === 'function') {
    answerSelector.getAnswer = configuration.getAnswer;
  }

  // compound questions are created by adding valid question configs as children
  const questionsWrapper: { questions?: TQuestionConfig[] } = {};
  if (isQuestionCompound(configuration)) {
    questionsWrapper.questions = configuration.questions;
  }

  return {
    id,
    ...answerSelector,
    ...questionsWrapper,
    info,
    title,
    titleIconSrc,
    shortTitle: getShortTitle(configuration),
    hidden,
    microcopy,
    links,
    subtitle,
    infoSubtitle,
    Component: component,
    tooltip,
    validationRules,
    alternativeLayout,
    props: {
      name: id,
      'data-testid': getTestId(name),
      hidden,
      ...getPropsFromValidationRules(validationRules as AnySchema<TObject | string>),
      ...defaultProps,
    },
    // cache the group identifier and original configuration.
    // will be used when cloning a question.
    $cachedConfiguration: {
      configuration,
      groupIdentifier,
      getValidationRules,
    },
  };
};

/**
 * Returns a question config factory when called with a groupIdentifier.
 * Question config factory creates usable configuration objects based on the supplied args.
 * Solves the generic problems like creating a semantically sound identifier.
 * @param {string} groupIdentifier
 * @param {getValidationRulesT} getValidationRules callback with yup validation rules
 */
const createQuestionFactory =
  (groupIdentifier: string, getValidationRules?: TGetValidationRules) => (configuration: TQuestionConfigInput) =>
    createQuestion({ groupIdentifier, configuration, getValidationRules });

/**
 * Clones a question allowing new configuration values to be provided.
 *
 * @param {*} question The question instance to be cloned.
 * @param {*} configuration The new configuration values to be merged into the source configuration.
 * @returns {*} The new question object.
 */
export const cloneQuestion = (
  question: { $cachedConfiguration?: TCreateQuestion },
  configuration: Partial<TQuestionConfigInput>
) => {
  const cache = question.$cachedConfiguration;

  return createQuestion({
    groupIdentifier: cache.groupIdentifier,
    configuration: {
      ...cache.configuration,
      ...configuration,
    },
    getValidationRules: cache.getValidationRules,
  });
};

export default createQuestionFactory;
