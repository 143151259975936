import Big from 'big.js';

import { TTotalMissedSavingsAtRetirementParams } from '@/types/domain/calcs';

/**
 * Sum of "Loan Cost" (Calc 5) and "FV Plan Contributions Forgone During Loan Repayment Period".
 */

const calculateTheTotalMissedSavingsAtRetirement = ({
  savingsGapAtRetirement,
  FVOfContributionsAtRetirement,
}: TTotalMissedSavingsAtRetirementParams): Big | number => {
  // Correct flipped signs of the params
  const totalMissedSavingsAtRetirement = Big(0).minus(savingsGapAtRetirement).minus(FVOfContributionsAtRetirement);

  return totalMissedSavingsAtRetirement;
};

export default calculateTheTotalMissedSavingsAtRetirement;
