/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import { getAnswer } from '@/domain/questions/util';

import { toUSD } from '@/util/currency';

import InfoIcon from '@/pages/common/InfoIcon';
import PartialCoverage from '@/pages/BorrowFromRetirementAccountsFlow/common/PartialCoverage';
import { amountInRetirementQ, loanAmountBFRQ } from '@/pages/BorrowFromRetirementAccountsFlow/questions';
import { calculateLoanAmount, isPartiallyCovered } from '@/pages/BorrowFromRetirementAccountsFlow/selectors';

import { AltModal } from '@/components/modal';
import { Clickable } from '@/components/button';

import classes from './styles.module.less';

const SubHeader = ({ state }) => {
  const { t } = useTranslation('i18n-unplanned');
  const amountNeeded = getAnswer(loanAmountBFRQ, state);

  if (isPartiallyCovered(state)) {
    const retirementAccountBalance = getAnswer(amountInRetirementQ, state);
    const { loanAmount, maxAmountUserCanBorrow } = calculateLoanAmount({
      amountNeeded,
      retirementAccountBalance,
    });

    return (
      <>
        Borrowing {toUSD(loanAmount, 0)} of {toUSD(amountNeeded, 0)}
        <AltModal
          heading={''}
          name="borrowFromRetirementSubHeader"
          renderControl={open => (
            <Clickable className={classes.infoIcon} onClick={open} aria-label="info">
              <InfoIcon />
            </Clickable>
          )}
        >
          <PartialCoverage amountNeeded={amountNeeded} maxAmountUserCanBorrow={maxAmountUserCanBorrow} />
        </AltModal>
      </>
    );
  }

  return t('borrow-retirement.borrowing', { amountNeeded: toUSD(amountNeeded, 0) });
};

export default SubHeader;
