import Big from 'big.js';

import { CALC_PRECISION } from '@/domain/constants';

import type { TRecord } from '@/types/common';

/** Collection of utilities to be used on Big.js objects */

/**
 * Receives an arbitrary number of arguments and returns the smallest number between them.
 * Boxes all received number primitives to Big object so that their method of comparison can be used.
 * Always returns a Big instance.
 */
export const min = (...bigNumbers: (number | Big)[]) => {
  if (!Array.isArray(bigNumbers) || !bigNumbers.length) {
    return null;
  }

  // map all to Big
  const bigArray = bigNumbers.map(big => Big(big));

  const sortedArray = bigArray.sort((a, b) => {
    return a.cmp(b);
  });

  // always returns a Big instance
  return sortedArray[0];
};

/**
 * Receives an arbitrary number of arguments and returns the largest number between them.
 * Boxes all received number primitives to Big object so that their method of comparison can be used.
 * Always returns a Big instance.
 */
export const max = (...bigNumbers: (number | Big)[]) => {
  if (!Array.isArray(bigNumbers) || !bigNumbers.length) {
    return null;
  }

  // map all to Big
  const bigArray = bigNumbers.map(big => Big(big));

  const sortedArray = bigArray.sort((a, b) => {
    return a.cmp(b);
  });

  // always returns a Big instance
  return sortedArray[bigNumbers.length - 1];
};

/**
 * Takes a Big instance or a number primitive, wraps it in a Big if needed and returns
 * a number primitive rounded to `CALC_PRECISION` decimals.
 */
export const getNumberWithSixDecimalPrecision = (bigObject?: Big | number | null) => {
  if (bigObject == null) return null;

  const boxedNumber = Big(bigObject);

  return Number(boxedNumber.toFixed(CALC_PRECISION));
};

/**
 * Makes results suitable as argument to self.postMessage (Big.js cannot be cloned)
 * @param {*} object
 */
export const cleanObjectFromBig = (object: TRecord<TRecord | Big>) => {
  const safeResults = {};

  if (!object) {
    return safeResults;
  }

  Object.keys(object).forEach(key => {
    const sourceValue = object[key];

    if (sourceValue instanceof Big) {
      safeResults[key] = getNumberWithSixDecimalPrecision(sourceValue);
    } else if (typeof sourceValue === 'object') {
      safeResults[key] = cleanObjectFromBig(sourceValue as TRecord<TRecord | Big>);
    } else {
      safeResults[key] = sourceValue;
    }
  });

  return safeResults;
};
