import { ActionFunction, assign, EventObject } from 'xstate';
import { ACCOUNT_STATUSES } from 'common/model/debt';
import { MAX_NUMBER_OF_DEBTS } from 'common/model/paymentPlan';
import { ApolloQueryResult } from '@apollo/client';

import {
  DEBT_PLAN_MONTH_TRANSITION_ALREADY_PAID_ACCOUNTS_PATH,
  DEBT_ONBOARDING_ALREADY_PAID_ACCOUNTS_PATH,
  DEBT_ONBOARDING_ACCOUNTS_CREATE_BASE_PATH,
  DEBT_PLAN_MONTH_TRANSITION_BUDGET_PATH,
  DEBT_ACCOUNTS_CREATE_BASE_PATH,
  DEBT_ONBOARDING_ACCOUNTS_PATH,
  DEBT_ONBOARDING_BUDGET_PATH,
  DEBT_PAYMENT_PLAN_PATH,
  DEBT_ACCOUNTS_PATH,
  DEBT_PLAN_MONTH_TRANSITION_ACCOUNTS_PATH,
  DEBT_PLAN_MONTH_TRANSITION_PATH,
} from '@/domain/paths/debt';
import {
  createAllocatedPayments,
  mergePaymentPlanData as mergePaymentPlanDataFn,
} from '@/domain/stateMachines/debtManager/debtsPlanMachineHelpers';
import {
  setCreatedPlan,
  setLatestPlan,
  setDraftPaymentPlan,
  trackMarkedDebtAsPaid,
  trackCompletedActionableItem,
  trackDisqualification,
  trackPaymentPlan,
  trackUpdateAvailableFunds,
  showProgressPopover,
} from '@/domain/stateMachines/debtManager/paymentPlanStates';
import {
  mergePaymentPlanWithDebts,
  assignReasonsForDisqualification as assignReasonsForDisqualificationFn,
  isAccountPaidOffByDebtId,
  TPaymentPlan,
} from '@/domain/debtsPlan';
import {
  trackAddedAccount,
  trackEditedAccount,
  trackMarkedAsPrioritized,
  trackCreditorAgreement,
  trackCreditorAgreementUpdate,
} from '@/domain/stateMachines/debtManager/debtCrudStates';
import {
  showMonthTransitionPaidOffPopover,
  clearMonthTransitionPaidOffPopover,
  finishMonthTransition,
} from '@/domain/stateMachines/debtManager/monthTransitionStates';
import { notifyChangedTags } from '@/domain/stateMachines/debtManager/changeTags';
import { BUDGET_INITIATOR_NEW_MONTH_RESET_STORAGE_KEY } from '@/domain/budget';
import type { TAccount } from '@/domain/debts';

import history from '@/util/history';
import { trans } from '@/util/i18n';
import { createStorage } from '@/util/storage';

import { LIST_DEBTS } from '@/graphql/queries';

import { InitialContextT } from '..';

const ACCOUNT_DETAILS_TOAST_DELAY = 2000;
const MAX_NUMBER_OF_DEBTS_MESSAGE = trans('max-number-of-debts-message', { MAX_NUMBER_OF_DEBTS });

const storage = createStorage('session');

type UpdatePaymentPlanEventT = EventObject & ApolloQueryResult<{ data: { updatePaymentPlan: TPaymentPlan } }>;
type UpdatePaymentPlanActivityEventT = EventObject &
  ApolloQueryResult<{ data: { updateActivities: TPaymentPlan['activities'] } }>;

const updateDebtsContext = assign<InitialContextT, UpdatePaymentPlanEventT>({
  previousAmountsPaid: context => {
    if (!context.allocatedPayments.amountsPaid) return [];
    return context.allocatedPayments.amountsPaid;
  },
  // TODO: Investigate this method of updating debts...sometimes duplicate debts can appear, unsure why
  debts: context => {
    const { client, userId, debts: existingDebts } = context;
    const { debts }: { debts?: TAccount[] } =
      client.cache.readQuery({
        query: LIST_DEBTS,
        variables: { userId },
      }) ?? {};

    if (!debts) return existingDebts;
    // TODO: extract this filter as a reusable selector?
    const activeDebts = debts.filter(
      debt => debt.status === ACCOUNT_STATUSES.ACTIVE || debt.status === ACCOUNT_STATUSES.PENDING
    );
    return activeDebts;
  },
  paymentPlan: (context, event) => {
    const { updatePaymentPlan: updatePaymentPlanEvent } = event.data.data;

    if (!updatePaymentPlanEvent) return context.paymentPlan;

    return updatePaymentPlanEvent;
  },
});

const clearDraftPaymentPlan = assign<InitialContextT>({
  draftPaymentPlan: undefined,
});

// Navigation Actions.
const navigateToPaymentPlan = () => history.push(DEBT_PAYMENT_PLAN_PATH);
const navigateToPaymentPlanAddAccount = () => history.push(DEBT_ACCOUNTS_CREATE_BASE_PATH);
const navigateAfterMarkingAsCalledFromPaymentPlan = (
  _,
  event: ApolloQueryResult<{ hasContactedCreditor: boolean; debtId: string }>
) => {
  const { hasContactedCreditor, debtId } = event.data;
  const targetPath = hasContactedCreditor ? `${DEBT_ACCOUNTS_PATH}/${debtId}` : DEBT_PAYMENT_PLAN_PATH;

  return history.push(targetPath);
};
const navigateToPaymentPlanAccountDetails = (
  _,
  event: ApolloQueryResult<{
    closeModal?: Function;
    debtId?: string;
    data: { updateDebt?: TAccount };
  }>
) => {
  const { closeModal, debtId = '' } = event.data;
  const fallbackId = event.data.data?.updateDebt?.debtId || '';

  if (typeof closeModal === 'function') {
    return closeModal();
  }

  return history.push(`${DEBT_ACCOUNTS_PATH}/${debtId || fallbackId}`);
};
const navigateToOnboardingAccountCreate = () => history.push(DEBT_ONBOARDING_ACCOUNTS_CREATE_BASE_PATH);
const navigateToOnboardingAccountList = () => history.push(DEBT_ONBOARDING_ACCOUNTS_PATH);
const navigateToOnboardingAccountDetails = (_, event: ApolloQueryResult<{ debtId: string }>) =>
  history.push(`${DEBT_ONBOARDING_ACCOUNTS_PATH}/${event.data.debtId}`);
const navigateToOnboardingAlreadyPaidAccounts = () => history.push(DEBT_ONBOARDING_ALREADY_PAID_ACCOUNTS_PATH);
const navigateToOnboardingBudgetPage = () => history.push(DEBT_ONBOARDING_BUDGET_PATH);
const navigateToMonthTransitionPage = () => history.push(DEBT_PLAN_MONTH_TRANSITION_PATH);
const navigateToMonthTransitionDebtsList = () => history.push(DEBT_PLAN_MONTH_TRANSITION_ACCOUNTS_PATH);
const navigateToMonthTransitionAlreadyPaidAccountsPage = () =>
  history.push(DEBT_PLAN_MONTH_TRANSITION_ALREADY_PAID_ACCOUNTS_PATH);
const navigateToMonthTransitionBudgetPage = () => history.push(DEBT_PLAN_MONTH_TRANSITION_BUDGET_PATH);
const navigateBack: ActionFunction<InitialContextT, EventObject & { data: { navigate: Function } }> = (_, event) => {
  if (event.data.navigate) {
    event.data.navigate();
  } else {
    history.goBack();
  }
};

// Notifications actions.
const notifyRemovingDebt = (context: InitialContextT, event: ApolloQueryResult<{ data: { deleteDebt: TAccount } }>) => {
  const { notify } = context;
  const { nickname } = event.data.data.deleteDebt;

  notify({ content: trans('nickname-deleted', { nickname }) });
};
const notifyReachingMaxNumberOfDebts = ({ notify }, { nativeEvent }: { nativeEvent: Event }) => {
  nativeEvent.preventDefault();
  notify({ content: MAX_NUMBER_OF_DEBTS_MESSAGE, type: 'warn' });
};
const notifyTogglingPrioritizedFromPaymentPlan = (
  context: InitialContextT,
  event: ApolloQueryResult<{ data: { updateDebt: TAccount } }>
) => {
  const { notify } = context;
  const { updateDebt: debt } = event.data.data;

  if (debt.isPrioritized) {
    notify({ content: trans('minimum-payment-prioritized') });
  }
};
const notifyError = context => {
  const { notify } = context;
  notify({
    content: trans('something-went-wrong.try-again'),
    type: 'error',
  });
};
const notifyEditingDebt = ({ notify }) =>
  notify({ content: trans('changes-saved'), delay: ACCOUNT_DETAILS_TOAST_DELAY });
const clearResetBudgetStatus = () => {
  storage.removeItem(BUDGET_INITIATOR_NEW_MONTH_RESET_STORAGE_KEY);
};
const clearPreviousAmountPaid = assign({ previousAmountsPaid: [] });
// eslint-disable-next-line no-restricted-globals
const updateHighlighting = assign<InitialContextT, EventObject & { highlight: { name: string } }>({
  highlights: (context, event) => {
    const { highlights } = context;
    const { highlight } = event;

    if (highlight) {
      return {
        ...highlights,
        [highlight.name]: {
          ...highlights[highlight.name],
          ...highlight,
        },
      };
    }

    return highlights;
  },
});
const updatePaymentPlan = assign<InitialContextT, UpdatePaymentPlanEventT>({
  previousAmountsPaid: (context, event) => {
    // We don't want to record any previous payments right after setting an active plan.
    if (event.type.includes('setActivePaymentPlan')) {
      return [];
    }

    return context.allocatedPayments.amountsPaid || [];
  },
  paymentPlan: (_, event) => {
    const { updatePaymentPlan: updatePaymentPlanEvent } = event.data.data;
    return updatePaymentPlanEvent;
  },
});

const updatePaymentPlanActivities = assign<InitialContextT, UpdatePaymentPlanActivityEventT>({
  paymentPlan: (context, event) => {
    const { updateActivities } = event.data.data;

    if (!context.paymentPlan) return context.paymentPlan;

    // replace the activities in the payment plan with the updated ones
    const updatedActivities = context.paymentPlan.activities.map(activity => {
      const updatedActivity = updateActivities.find(updatedAct => updatedAct.type === activity.type);
      if (updatedActivity) {
        return updatedActivity;
      }
      return activity;
    });

    return {
      ...context.paymentPlan,
      activities: updatedActivities,
    };
  },
});

// @ts-expect-error to be fixed
const mergePaymentPlanData = assign<InitialContextT>(mergePaymentPlanDataFn);
const assignReasonsForDisqualification = assign<InitialContextT>(assignReasonsForDisqualificationFn);
// @ts-expect-error to be fixed
const allocatePayments = assign<InitialContextT>(createAllocatedPayments);
const showPaidOffPopover = assign<InitialContextT, EventObject & { data: { debtId: string } }>({
  showPaidOffPopover: (context, event) => {
    const { allocatedPayments } = context;
    const { debtId } = event.data;
    const debtsPlan = mergePaymentPlanWithDebts(context);
    // Don't show the popover when the accounts is already paid off.
    // @ts-expect-error to be fixed
    if (isAccountPaidOffByDebtId(allocatedPayments.amountsPaid, debtId)) {
      return false;
    }

    return isAccountPaidOffByDebtId(debtsPlan.amountsPaid, debtId);
  },
});
const hidePaidOffPopover = assign<InitialContextT>({ showPaidOffPopover: false });

export {
  updateDebtsContext,
  clearDraftPaymentPlan,
  navigateBack,
  navigateToPaymentPlan,
  navigateToPaymentPlanAddAccount,
  navigateAfterMarkingAsCalledFromPaymentPlan,
  navigateToPaymentPlanAccountDetails,
  navigateToOnboardingAccountCreate,
  navigateToOnboardingAccountList,
  navigateToOnboardingAccountDetails,
  navigateToOnboardingAlreadyPaidAccounts,
  navigateToOnboardingBudgetPage,
  navigateToMonthTransitionPage,
  navigateToMonthTransitionDebtsList,
  navigateToMonthTransitionAlreadyPaidAccountsPage,
  navigateToMonthTransitionBudgetPage,
  notifyRemovingDebt,
  notifyReachingMaxNumberOfDebts,
  notifyTogglingPrioritizedFromPaymentPlan,
  notifyError,
  notifyChangedTags,
  notifyEditingDebt,
  clearResetBudgetStatus,
  clearPreviousAmountPaid,
  updateHighlighting,
  updatePaymentPlan,
  updatePaymentPlanActivities,
  finishMonthTransition,
  setCreatedPlan,
  setLatestPlan,
  setDraftPaymentPlan,
  trackAddedAccount,
  trackEditedAccount,
  trackCreditorAgreement,
  trackCreditorAgreementUpdate,
  trackMarkedAsPrioritized,
  trackUpdateAvailableFunds,
  trackMarkedDebtAsPaid,
  trackCompletedActionableItem,
  trackDisqualification,
  trackPaymentPlan,
  allocatePayments,
  mergePaymentPlanData,
  assignReasonsForDisqualification,
  showPaidOffPopover,
  hidePaidOffPopover,
  showProgressPopover,
  clearMonthTransitionPaidOffPopover,
  showMonthTransitionPaidOffPopover,
};
