/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import Heading from '@/components/Heading';
import NumberedList from '@/components/NumberedList';
import Paragraph from '@/components/Paragraph';
import CustomContainer from '@/components/CustomContainer';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';

const AboutPersonalLoans = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <CustomContainer noMargin verticallyPadded>
      <Heading as="h1">{t('about-personal-loans.heading')}</Heading>
      <Paragraph noHorizontalMargin>{t('about-personal-loans.paragraph')}</Paragraph>
      <NumberedList testId="personalLoansFacts" items={t('about-personal-loans.items')} />
      {fromAssessment ? null : (
        <QuestionsForm fullWidth backButton={false}>
          <WizardUpNext>{t('about-personal-loans.wizard')}</WizardUpNext>
        </QuestionsForm>
      )}
    </CustomContainer>
  );
};

export default AboutPersonalLoans;
