/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveSpacing from '@/components/ResponsiveSpacing';
import { AltModal } from '@/components/modal';
import Paragraph from '@/components/Paragraph';
import Link from '@/components/link';

const WhyDoYouAskModal = ({ children, dataTestId, name }) => {
  const { t } = useTranslation();
  return (
    <AltModal
      heading={t('why-do-you-ask')}
      name={name}
      renderControl={open => (
        <Link data-testid={dataTestId == null ? `${name}Btn` : dataTestId} onClick={open}>
          {t('why-do-you-ask')}
        </Link>
      )}
    >
      <ResponsiveSpacing right>
        <Paragraph size="medium" textAlign="left">
          {children}
        </Paragraph>
      </ResponsiveSpacing>
    </AltModal>
  );
};

export default WhyDoYouAskModal;
