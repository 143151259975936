import { string, number, array, object, boolean } from 'yup';

import activityConfiguration from './activity';

export const MAX_NICKNAME_DIGITS = 2;

const REQUIRED_ERROR_TEMPLATE = 'This field is required.';
const INVALID_AMOUNT = 'Amount must be greater than or equal to 0.';
const INVALID_MONTH = 'You entered an invalid month';
const INVALID_YEAR = 'You entered an invalid year';

export const MILESTONE_TYPES = {
  FIRST_ACTIVITY_COMPLETED: 'firstActivityCompleted',
  FIRST_DEPOSIT_ACTIVITY_COMPLETED: 'firstDepositActivityCompleted',
  SAVINGS_GOAL_PROGRESS: 'savingsGoalProgress',
  CONSISTENT_CONTRIBUTIONS: 'consistentContributions',
  SAVINGS_GOAL_COMPLETED: 'savingsGoalCompleted',
  CONSISTENTLY_SAVED_TOWARDS_GOAL: 'consistentlySavedTowardsGoal',
};

export const SAVINGS_TYPES = {
  RAINY_DAY: 'rainyDayFund',
  LARGE_PURCHASE: 'largePurchase',
};

export const SAVINGS_STATUSES = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  PENDING: 'pending',
};

export const SAVINGS_FREQUENCIES = {
  WEEKLY: 'WEEKLY',
  BI_WEEKLY: 'BI_WEEKLY',
  TWICE_MONTH: 'TWICE_MONTH',
  MONTHLY: 'MONTHLY',
};

export const ALL_SAVINGS_FREQUENCIES = Object.values(SAVINGS_FREQUENCIES);
export const ALL_MILESTONES = Object.values(MILESTONE_TYPES);

export function nicknameDoesNotContainAccountNumber(value) {
  if (!value) {
    return true;
  }
  const digitCount = (value.match(/\d/g) || []).length;
  return digitCount <= MAX_NICKNAME_DIGITS;
}

const milestoneSchema = {
  type: string().oneOf(ALL_MILESTONES).required(REQUIRED_ERROR_TEMPLATE),
  metadata: object().nullable(),
  lastDisplayedAt: number().nullable(),
  shouldBeDisplayed: boolean().required(REQUIRED_ERROR_TEMPLATE),
};

const upsertSavingsValidationRules = {
  savingsId: string(),
  nickname: string().test(
    'nicknameDoesNotContainAccountNumber',
    `Do not enter more than ${MAX_NICKNAME_DIGITS} numeric characters`,
    nicknameDoesNotContainAccountNumber
  ),
  dueDate: number(),
  hasDisplayedSavingsPlanMessage: boolean(),
  year: string().matches(/^\d{4}$/, INVALID_YEAR),
  month: string().matches(/^(0?[1-9]|1[012])$/, INVALID_MONTH),
  type: string().oneOf(Object.values(SAVINGS_TYPES)).required(REQUIRED_ERROR_TEMPLATE),
  status: string().oneOf([SAVINGS_STATUSES.ACTIVE, SAVINGS_STATUSES.PENDING]).required(REQUIRED_ERROR_TEMPLATE),
  amountToSave: number().min(0, INVALID_AMOUNT).required(REQUIRED_ERROR_TEMPLATE),
  amountAlreadySaved: number().min(0, INVALID_AMOUNT).required(REQUIRED_ERROR_TEMPLATE),
  amountToContributeMonthly: number().min(0, INVALID_AMOUNT).required(REQUIRED_ERROR_TEMPLATE),
  frequencyOfContribution: string().oneOf(ALL_SAVINGS_FREQUENCIES).required(REQUIRED_ERROR_TEMPLATE),
  milestones: array().of(object().shape(milestoneSchema)),
  activities: array().of(object().shape(activityConfiguration)),
};

export const getUpsertValidationRules = fieldName => {
  return upsertSavingsValidationRules[fieldName];
};

export const SAVINGS_EDIT_PROPERTIES = [
  'nickname',
  'dueDate',
  'amountToSave',
  'amountAlreadySaved',
  'amountToContributeMonthly',
  'frequencyOfContribution',
];
