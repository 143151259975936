/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { VERTICALS_NAMES } from '@/domain/verticals';
import {
  MY_PLAN_PATH,
  OTHER_VERTICAL_PRIORITIZE_ESSENTIAL_BILLS_X1,
  OTHER_VERTICAL_KNOW_CONSEQUENCES_OF_UNPAID_BILLS_X2,
  OTHER_VERTICAL_TRY_NEGOTIATE_WITH_CREDITORS_X3,
} from '@/domain/paths';

import { trans } from '@/util/i18n';

import knowConsequencesUnpaidBillsX2 from '@/pages/content/other/KnowConsequencesUnpaidBillsX2/images/Icon-My-Plan-Know-Consequences-Unpaid-Bills.svg';
import prioritizeEssentialBillsX1 from '@/pages/content/other/PrioritizeEssentialBillsX1/images/Icon-My-Plan-Prioritize-Essential-Bills.svg';
import tryNegotiateWithCreditorsX3 from '@/pages/content/other/TryNegotiateWithCreditorsX3/images/Icon-My-Plan-Try-Negotiate-With-Creditors.svg';

const KnowConsequencesUnpaidBillsX2 = React.lazy(() => import('@/pages/content/other/KnowConsequencesUnpaidBillsX2'));
const PrioritizeEssentialBillsX1 = React.lazy(() => import('@/pages/content/other/PrioritizeEssentialBillsX1'));
const TryNegotiateWithCreditorsX3 = React.lazy(() => import('@/pages/content/other/TryNegotiateWithCreditorsX3'));

const cards = [
  {
    id: 'PrioritizeEssentialBillsX1',
    image: prioritizeEssentialBillsX1,
    title: trans('i18n-unplanned:prioritize-essential-bills'),
    shortText: trans('i18n-unplanned:prioritize-essential-bills.short-text'),
    text: trans('i18n-unplanned:prioritize-essential-bills.text'),
    infoBox: {
      content: trans('i18n-unplanned:prioritize-essential-bills.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${OTHER_VERTICAL_PRIORITIZE_ESSENTIAL_BILLS_X1}`,
    },
    route: {
      component: PrioritizeEssentialBillsX1,
    },
    verticals: [VERTICALS_NAMES.OTHER],
  },
  {
    id: 'KnowConsequencesUnpaidBillsX2',
    image: knowConsequencesUnpaidBillsX2,
    title: trans('i18n-unplanned:know-consequences-unpaid'),
    shortText: trans('i18n-unplanned:know-consequences-unpaid.short-text'),
    text: trans('i18n-unplanned:know-consequences-unpaid.text'),
    infoBox: {
      content: trans('i18n-unplanned:know-consequences-unpaid.content'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${OTHER_VERTICAL_KNOW_CONSEQUENCES_OF_UNPAID_BILLS_X2}`,
    },
    route: {
      component: KnowConsequencesUnpaidBillsX2,
    },
    verticals: [VERTICALS_NAMES.OTHER],
  },
  {
    id: 'TryNegotiateWithCreditorsX3',
    image: tryNegotiateWithCreditorsX3,
    title: trans('i18n-unplanned:try-negotiate-creditors'),
    shortText: trans('i18n-unplanned:try-negotiate-creditors.short-text'),
    text: trans('i18n-unplanned:try-negotiate-creditors.text'),
    infoBox: {
      content: trans('i18n-unplanned:try-negotiate-creditors.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${OTHER_VERTICAL_TRY_NEGOTIATE_WITH_CREDITORS_X3}`,
    },
    route: {
      component: TryNegotiateWithCreditorsX3,
    },
    verticals: [VERTICALS_NAMES.OTHER],
  },
];

export default cards;
