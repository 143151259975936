export const HOME_CONTACT_YOUR_LANDLORD_AA3_PATH = '/content/home/contact-your-landlord-aa3';
export const HOME_CONTACT_YOUR_LANDLORD_AB3_PATH = '/content/home/contact-your-landlord-ab3';
export const HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_AA2_PATH =
  '/content/home/document-the-issue-and-track-expenses-aa2';
export const HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_AB2_PATH =
  '/content/home/document-the-issue-and-track-expenses-ab2';
export const HOME_FILE_RENTERS_INSURANCE_CLAIM_AA4_PATH = '/content/home/file-a-renters-insurance-claim-aa4';
export const HOME_UNDERSTAND_YOUR_RIGHTS_AA1_PATH = '/content/home/understand-your-rights-aa1';
export const HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_BA1_PATH =
  '/content/home/document-the-issue-and-track-expenses-ba1';
export const HOME_UNDERSTAND_YOUR_INSURANCE_BA2_PATH = '/content/home/understand-your-insurance-ba2';
export const HOME_UNDERSTAND_YOUR_RIGHTS_AB1_PATH = '/content/home/understand-your-rights-ab1';
export const HOME_UNDERSTAND_THE_CLAIMS_PROCESS_BA3_PATH = '/content/home/understand-the-claims-process-ba3';
