import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import invariant from 'invariant';
import cx from 'classnames';

import Image from '@/components/Image';
import Heading from '@/components/Heading';
import MicroCopy from '@/components/microcopy';
import { Clickable } from '@/components/button';

import classes from './InformationBox.module.less';

type TypeWrapperProps = {
  children: React.ReactNode;
  className?: string;
  type: 'info' | 'warning' | 'error' | 'success';
};

export const TypeWrapper = ({ children, className, type }: TypeWrapperProps) => {
  return (
    <div
      className={cx(classes.wrapper, className, {
        [classes.infoBox]: type === 'info',
        [classes.warningBox]: type === 'warning',
        [classes.errorBox]: type === 'error',
        [classes.successBox]: type === 'success',
      })}
    >
      {children}
    </div>
  );
};

type BoxHeadingProps = {
  size: 'big' | 'small' | 'xs';
  content: React.ReactNode;
  className?: string;
  color?: 'light' | 'dark';
};

const BoxHeading = ({ size, content, className, color }: BoxHeadingProps) => {
  switch (size) {
    case 'big':
      return (
        <Heading as="h5" className={className} inverted={color === 'dark'}>
          {content}
        </Heading>
      );
    case 'small':
      return <div className={cx(classes.bodyTextSmall, className)}>{content}</div>;
    case 'xs':
      return null;
    default:
      return (
        <Heading as="h5" className={className}>
          {content}
        </Heading>
      );
  }
};

const BoxContent = ({ size, content }) => {
  switch (size) {
    case 'big':
      return content;
    case 'small':
      return <MicroCopy size="small">{content}</MicroCopy>;
    case 'xs':
      return <MicroCopy size="mini">{content}</MicroCopy>;
    default:
      return content;
  }
};

const ImageContent = ({ size, icon, image, imageSize }) => {
  if (image != null) {
    return (
      <Image
        src={image}
        className={cx({
          [classes.smallImg]: imageSize === 'small',
          [classes.mediumImg]: imageSize === 'medium',
          [classes.bigImg]: imageSize === 'big',
          [classes.largeImg]: imageSize === 'large',
        })}
        role="presentation"
      />
    );
  }
  if (icon != null && image == null) {
    const iconProps = typeof icon === 'string' ? { name: icon } : { ...icon };

    return (
      <Icon
        {...iconProps}
        className={cx(classes.mainIcon, {
          [classes.smallIcon]: size === 'xs',
        })}
      />
    );
  }
  return null;
};

type MessageBoxProps = {
  /** The content of the box */
  content: React.ReactNode;
  /** The heading content that would be presented. */
  heading?: React.ReactNode;
  /** The image url that could be shown. */
  image?: string;
  /** The icon that could be shown from semantic ui icons. */
  icon?: string;
  /** The styling type of the box. */
  type?:
    | 'info'
    | 'warning'
    | 'error'
    | 'success'
    | 'transparent'
    | 'transparentNeutralBox'
    | 'neutralBox'
    | 'offWhiteBox'
    | 'darkBox'
    | 'darkInfoBox'
    | 'darkWarningBox'
    | 'darkErrorBox';
  /** The font size of the component. */
  size?: 'big' | 'small' | 'xs';
  /** The size of the image */
  imageSize?: 'small' | 'medium' | 'big' | 'large';
  /** Hides the component by applying a display none. */
  hidden?: boolean;
  /** Displays an arrow on top or left of the box. */
  arrow?: 'top' | 'left';
  /** A className to override styling if needed. */
  className?: string;
  /** Adds the data-testid if needed. */
  'data-testid'?: string;
  /** Adds a cliclable icon to be able to dismiss/hide the box. */
  dismissable?: boolean;
  /** The animation when it's dismissed. */
  dismissAnimation?: 'slideOut' | 'fadeOut';
  /** A callback function when it's dismissed. */
  onDismiss?: () => void;
  /** Removes the margin of the component. */
  noMargin?: boolean;
  /** Removes the border radius of the component. */
  noBorderRadius?: boolean;
  /** Creates a cliclable icon to be able to collapse/expand the content. */
  collapsible?: boolean;
};

/**
 * This component renders a box with content with different types of stylings and options, useful to display some information.
 */
const MessageBox = React.forwardRef<HTMLElement, MessageBoxProps>(
  (
    {
      content,
      heading,
      image,
      icon,
      type = 'info',
      size = 'big',
      imageSize,
      hidden = false,
      arrow,
      className,
      'data-testid': dataTestId,
      dismissable = false,
      dismissAnimation = 'slideOut',
      onDismiss,
      noMargin,
      noBorderRadius,
      collapsible = false,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [close, setClose] = React.useState(false);
    const [collapsed, setCollapsed] = React.useState(true);
    // It cannot be dismissable and collapsible at the same time.
    const isAbleToCollapse = collapsible && !dismissable;

    /* used to expose the setClose function to the parent component
     so it can be animated when closed.
     this way of closing requires the onDismiss prop */
    React.useImperativeHandle<unknown, unknown>(ref, () => {
      if (typeof onDismiss === 'function' && onDismiss !== null) {
        return {
          setClose(isClosed: boolean) {
            setClose(isClosed);
          },
        };
      }
      return null;
    });

    if (dismissable === true) {
      invariant(collapsible === false, `It cannot be dismissable and collapsible at the same time.`);
    }

    if (image != null) {
      invariant(icon == null, `You can only have image or icon. Not both at the same time`);
      invariant(
        imageSize != null,
        `If you are using image please also set the desired imageSize using one of 'small', 'medium', 'big' `
      );
    }

    // TODO: Fix this eslint error
    // eslint-disable-next-line react/no-unstable-nested-components
    const CloseIcon = () => {
      if (dismissable !== null && dismissable === true) {
        return (
          <Clickable
            data-testid="closeInformationBoxBtn"
            onClick={() => setClose(true)}
            aria-label={t('aria-label.dismiss-information-box')}
            className={classes.closeButton}
          >
            <Icon name="close" className={classes.closeIcon} />
          </Clickable>
        );
      }
      return null;
    };

    // TODO: Fix this eslint error
    // eslint-disable-next-line react/no-unstable-nested-components
    const ExpandCollapseIcon = () => {
      if (!isAbleToCollapse) {
        return null;
      }

      return (
        <Clickable
          aria-checked={collapsed}
          role="switch"
          onClick={() => setCollapsed(!collapsed)}
          data-testid="collapseInformationBoxBtn"
          aria-label="toggle"
        >
          <Icon name={collapsed ? 'caret down' : 'caret up'} color="grey" />
        </Clickable>
      );
    };

    return (
      <div
        data-testid={dataTestId}
        className={cx(classes.box, className, {
          [classes.boxWithBigImage]: imageSize === 'big',
          [classes.hidden]: hidden,
          [classes.infoBox]: type === 'info',
          [classes.warningBox]: type === 'warning',
          [classes.errorBox]: type === 'error',
          [classes.successBox]: type === 'success',
          [classes.transparentNeutralBox]: type === 'transparentNeutralBox',
          [classes.neutralBox]: type === 'neutralBox',
          [classes.offWhiteBox]: type === 'offWhiteBox',
          [classes.darkBox]: type === 'darkBox',
          [classes.darkInfoBox]: type === 'darkInfoBox',
          [classes.darkErrorBox]: type === 'darkErrorBox',
          [classes.darkWarningBox]: type === 'darkWarningBox',
          [classes.arrow_top]: arrow === 'top',
          [classes.arrow_left]: arrow === 'left',
          [classes[dismissAnimation]]: close,
          [classes.noMargin]: noMargin,
          [classes.smallMargin]: size === 'xs',
          [classes.noBorderRadius]: noBorderRadius,
        })}
        onAnimationEnd={() => {
          if (typeof onDismiss === 'function') {
            onDismiss();
          }
        }}
      >
        <ImageContent size={size} icon={icon} image={image} imageSize={imageSize} />
        <div className={classes.contentContainer}>
          {heading != null ? (
            <BoxHeading
              size={size}
              content={heading}
              color={type === 'darkBox' ? 'dark' : 'light'}
              className={cx({ [classes.collapsibleHeader]: isAbleToCollapse })}
            />
          ) : null}
          <div
            className={cx({
              [classes.collapsibleContent]: isAbleToCollapse,
              [classes.collapsed]: isAbleToCollapse && collapsed,
            })}
          >
            <BoxContent size={size} content={content} />
          </div>
        </div>
        <CloseIcon />
        <ExpandCollapseIcon />
      </div>
    );
  }
);

export const InfoBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="info" />;
};

export const WarningBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="warning" />;
};

export const ErrorBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="error" />;
};

export const SuccessBox = React.forwardRef<HTMLElement, MessageBoxProps>((props, ref) => {
  return <MessageBox ref={ref} {...props} type="success" />;
});

export const OffWhiteBox = React.forwardRef<HTMLElement, MessageBoxProps>((props, ref) => {
  return <MessageBox ref={ref} {...props} type="offWhiteBox" />;
});

export const TransparentBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="transparent" />;
};

export const TransparentNeutralBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="transparentNeutralBox" />;
};

export const NeutralBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="neutralBox" />;
};

export const DarkBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="darkBox" />;
};

export const DarkInfoBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="darkInfoBox" />;
};

export const DarkWarningBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="darkWarningBox" />;
};

export const DarkErrorBox = (props: MessageBoxProps) => {
  return <MessageBox {...props} type="darkErrorBox" />;
};

export default MessageBox;
