import Big from 'big.js';

import { TFirstMonthInterestParams } from '@/types/domain/calcs';

/**
 * Determines the first month's interest after a promo period (if one).
 * Look at the screenshot attached in <AB-2334>
 * WARNING: DOES NOT MATCH CALCS SPREADSHEET! It was difficult to do in excel, so a
 * python script was provided.
 */
const calcFirstMonthInterest = ({
  chargeAmount, //                    $-AMT
  monthlyPayment, //                  $-MONTHLY
  interestRate, //                %-INTEREST-RATE
  postPromoInterestRate, //           %-POST-PROMO-RATE
  promoPeriod,
  promoPeriodDuration, //             PROMO_MONTHS
}: TFirstMonthInterestParams): Big => {
  if (promoPeriod) {
    // this part comes from the python script that Bob provided
    let minMonthlyPayment = 0;
    let newMonthlyPayment = monthlyPayment;
    let iteration = 1;

    while (!minMonthlyPayment || monthlyPayment.toFixed(2) !== minMonthlyPayment.toFixed(2)) {
      iteration += 1;
      let remainingAmount;

      // number of iterations is limited to 100 so it doesn't freeze the UI with certain input
      if (iteration > 100) {
        return Big(minMonthlyPayment).round(0, 3);
      }

      if (interestRate === 0) {
        const promoPaid = monthlyPayment * promoPeriodDuration;
        const futureValueAmount = chargeAmount;
        if (promoPaid >= futureValueAmount) {
          remainingAmount = Big(0);
        } else {
          remainingAmount = Big(futureValueAmount - promoPaid);
        }
      } else {
        const promoPeriodDurationDays = Number(promoPeriodDuration * 30);
        const dailyInterestRate = interestRate / 365;
        const dailyPayment = newMonthlyPayment / 30;

        // use vanilla here to be faster (same results are being returned)
        const promoPaid = (dailyPayment * ((1 + dailyInterestRate) ** promoPeriodDurationDays - 1)) / dailyInterestRate;

        const futureValueAmount = chargeAmount * (1 + interestRate / 12) ** promoPeriodDuration;

        // remainingAmount = futureValueAmount.minus(promoPaid);
        remainingAmount = futureValueAmount - promoPaid;
      }

      minMonthlyPayment = remainingAmount * (1 + postPromoInterestRate / 365) ** 30 - remainingAmount;

      // if (Big(newMonthlyPayment).toFixed(2) === minMonthlyPayment.toFixed(2)) {
      if (newMonthlyPayment.toFixed(2) === minMonthlyPayment.toFixed(2)) {
        break;
      } else {
        newMonthlyPayment = minMonthlyPayment;
      }
    }

    return Big(minMonthlyPayment).round(0, 3);
  }

  // no promo period

  // (<$-AMT>*(1+(<%-INTEREST-RATE>/365))^30) - <$-AMT>
  const noPromoResult = chargeAmount * (1 + interestRate / 365) ** 30 - chargeAmount;

  return Big(noPromoResult).round(0, 3);
};

export default calcFirstMonthInterest;
