import { ApolloQueryResult } from '@apollo/client';
import { ActionFunction, EventObject } from 'xstate';
import { Location } from 'history';
import { ACCOUNT_STATUSES } from 'common/model/debt';
import {
  getTotalAmountPaid,
  PAYMENT_PLAN_STATUSES,
  PAYMENT_PLAN_FLAGS,
  sortPlansByMonth,
} from 'common/model/paymentPlan';

import { BUDGET_EDIT_EXPENSES_PATH, DASHBOARD_PAGE_PATH } from '@/domain/paths';
import { runParallelAllocations } from '@/domain/debtsPlan/paymentAllocation';
import { mergePaymentPlanWithDebts, TPaymentPlan } from '@/domain/debtsPlan';
import { DEBT_ACCOUNTS_CREATE_BASE_PATH } from '@/domain/paths/debt';

import { isDateEqual, getCurrentDate } from '@/util/date';
import history from '@/util/history';

import { TAccount } from '../../debts';
import { AppMachineStateT, InitialContextT } from '..';

export const DASHBOARD_DEBT_MANAGER_NAV_HACK = DASHBOARD_PAGE_PATH;
export const DASHBOARD_DEBT_MANAGER_ADD_ACCOUNT_NAV_HACK = DEBT_ACCOUNTS_CREATE_BASE_PATH;

/**
 * Tells if a debt delinquency has changed leveraging state Machine
 * @param {object} - props
 * @param {object} - props.context state machine context
 * @param {object} - props.event the event that triggered this action
 * @param {string} - props.debtId the debt id.
 */
export const isDebtDelinquencyDirty = ({
  context,
  event,
  debtId,
}: {
  context: InitialContextT;
  event: EventObject & ApolloQueryResult<{ data: { updateDebt: TAccount } }>;
  debtId: string;
}) => {
  const { updateDebt } = event.data.data;
  const previousDebt = context.debts.find(debt => debt.debtId === debtId);
  if (previousDebt && previousDebt.daysDelinquent > 0 && !updateDebt.daysDelinquent) {
    return true;
  }
  return false;
};

/**
 * Tells if a payment plan amountPaid has changed leveraging on state Machine
 * @param {object} - props
 * @param {object} - props.context state machine context
 * @param {object} - props.event the event that triggered this action
 * @param {string} - props.debtId the debt id.
 */
export const isAmountPaidDirty = ({
  context,
  event,
  debtId,
}: {
  context: InitialContextT;
  event: EventObject & ApolloQueryResult<{ data: { updatePaymentPlan: TPaymentPlan } }>;
  debtId: string;
}) => {
  const { updatePaymentPlan } = event.data.data;
  const previousAmountPaid = context.paymentPlan?.amountsPaid.find(amountPaid => amountPaid.debtId === debtId);
  const updatedAmountPaid = updatePaymentPlan.amountsPaid.find(amountPaid => amountPaid.debtId === debtId);
  if (previousAmountPaid?.amount === 0 && updatedAmountPaid?.amount && updatedAmountPaid.amount > 0) {
    return true;
  }
  return false;
};

/**
 * Tells if a payment plan hasContactedCreditor has changed leveraging on state Machine
 * @param {object} - props
 * @param {object} - props.context state machine context
 * @param {object} - props.event the event that triggered this action
 * @param {string} - props.debtId the debt id.
 */
export const isHasContactedCreditorDirty = ({
  context,
  event,
  debtId,
}: {
  context: InitialContextT;
  event: EventObject & ApolloQueryResult<{ data: { updatePaymentPlan: TPaymentPlan } }>;
  debtId: string;
}) => {
  const { updatePaymentPlan } = event.data.data;
  const previousAmountPaid = context.paymentPlan?.amountsPaid.find(amountPaid => amountPaid.debtId === debtId);
  const updatedAmountPaid = updatePaymentPlan.amountsPaid.find(amountPaid => amountPaid.debtId === debtId);
  if (!previousAmountPaid?.hasContactedCreditor && updatedAmountPaid?.hasContactedCreditor) {
    return true;
  }
  return false;
};

export const hasNoActiveOrArchivedPlan: ActionFunction<
  InitialContextT,
  EventObject & ApolloQueryResult<{ data: { paymentPlans: TPaymentPlan[] } }>
> = (_, event) => {
  const { paymentPlans } = event.data.data;

  return (
    paymentPlans.filter(paymentPlan =>
      [PAYMENT_PLAN_STATUSES.ACTIVE, PAYMENT_PLAN_STATUSES.ARCHIVED].includes(paymentPlan.status)
    ).length === 0
  );
};

export function hasPaymentPlan(context: InitialContextT) {
  return !!context.paymentPlan;
}

export function hasActivePaymentPlan(context: InitialContextT) {
  return !!context.paymentPlan && context.paymentPlan.status === PAYMENT_PLAN_STATUSES.ACTIVE;
}

export function hasValidActivePaymentPlan(context: InitialContextT) {
  return (
    hasActivePaymentPlan(context) &&
    isDateEqual(context.currentDate, { month: context.paymentPlan?.month || '', year: context.paymentPlan?.year || '' })
  );
}

export function hasDraftPaymentPlan(context: InitialContextT) {
  return context.paymentPlan && context.paymentPlan.status === PAYMENT_PLAN_STATUSES.DRAFT;
}

export function hasValidDraftPaymentPlan(context: InitialContextT) {
  return (
    hasDraftPaymentPlan(context) &&
    isDateEqual(context.currentDate, { month: context.paymentPlan?.month || '', year: context.paymentPlan?.year || '' })
  );
}

export function isDraftPlanMissing(context: InitialContextT) {
  return !context.draftPaymentPlan;
}

export function isDraftAndActivePlanMissing(context: InitialContextT) {
  return !hasDraftPaymentPlan(context) && !hasActivePaymentPlan(context);
}

export function hasAnyPendingDebts(context: InitialContextT) {
  return context.debts.some(debt => debt.status === ACCOUNT_STATUSES.PENDING);
}

export const isThereADraftPlan: ActionFunction<
  InitialContextT,
  EventObject & ApolloQueryResult<{ data: { paymentPlans: TPaymentPlan[] } }>
> = (_, event) => {
  const { paymentPlans } = event.data.data;
  const currentDate = getCurrentDate();
  const draftPaymentPlan = paymentPlans.find(paymentPlan => paymentPlan.status === PAYMENT_PLAN_STATUSES.DRAFT);
  const hasActiveCurrentMonthPaymentPlan = paymentPlans.find(paymentPlan => {
    return (
      paymentPlan.status === PAYMENT_PLAN_STATUSES.ACTIVE &&
      paymentPlan.year === currentDate.getFullYear().toString() &&
      paymentPlan.month === (currentDate.getMonth() + 1).toString()
    );
  });

  if (draftPaymentPlan && !hasActiveCurrentMonthPaymentPlan) {
    return true;
  }

  return false;
};

export const isMonthTransition: ActionFunction<
  InitialContextT,
  EventObject & ApolloQueryResult<{ data: { paymentPlans: TPaymentPlan[] } }>
> = (context, event) => {
  const { paymentPlans } = event.data.data;
  const {
    currentDate: { month, year },
  } = context;

  const currentMonthPaymentPlan = paymentPlans.find(
    paymentPlan =>
      isDateEqual({ month: paymentPlan.month, year: paymentPlan.year }, { month, year }) &&
      paymentPlan.status === PAYMENT_PLAN_STATUSES.ACTIVE
  );

  if (currentMonthPaymentPlan) {
    return false;
  }

  // if there is no current plan, check if there's at least one other plan
  return Boolean(paymentPlans.length);
};

export function mergePaymentPlanData(context: InitialContextT) {
  const debtsPlan = mergePaymentPlanWithDebts(context);
  const totalAmountPaid = getTotalAmountPaid(debtsPlan.amountsPaid);
  const remainingFunds = (debtsPlan.availableFunds || 0) - totalAmountPaid;

  return {
    allocatedPayments: {
      ...debtsPlan,
      totalAmountPaid,
      remainingFunds,
    },
  };
}

export function runPaymentAllocationAlgorithm({ debtsPlan, debts }: { debtsPlan: TPaymentPlan; debts: TAccount[] }) {
  const totalAmountPaid = getTotalAmountPaid(debtsPlan.amountsPaid);
  const remainingFunds = debtsPlan.availableFunds - totalAmountPaid;

  const suggestedAlocations = runParallelAllocations(debtsPlan.availableFunds, debts, debtsPlan.amountsPaid);

  const amountsPaidWithSuggestedAllocations = debtsPlan.amountsPaid.map(debtAmountPaid => {
    const debtAllocationResult = suggestedAlocations.find(({ debtId }) => debtId === debtAmountPaid.debtId);
    if (!debtAllocationResult) {
      debtAmountPaid.suggestedAmount = 0;
    }
    const result = {
      // Provide a safe fallback for suggestedAmount
      suggestedAmount: 0,
      ...debtAmountPaid,
      ...debtAllocationResult,
    };
    return result;
  });

  return {
    allocatedPayments: {
      ...debtsPlan,
      amountsPaid: amountsPaidWithSuggestedAllocations,
      totalAmountPaid,
      remainingFunds,
    },
  };
}

export function createAllocatedPayments(context: InitialContextT) {
  const { debts } = context;

  // TODO update this kind of stuff on other state changes as well
  const debtsPlan = mergePaymentPlanWithDebts(context);
  return runPaymentAllocationAlgorithm({ debtsPlan: debtsPlan as TPaymentPlan, debts });
}

export const isInDisqualifiedState = (appState: AppMachineStateT) =>
  appState.matches('loaded.debtManager.paymentPlan.disqualified');

export const isInViewingPaymentPlanState = (appState: AppMachineStateT) =>
  appState.matches('loaded.debtManager.paymentPlan.displayPaymentPlan');

export const isInPaymentPlan = (appState: AppMachineStateT) => appState.matches('loaded.debtManager.paymentPlan');

// Check if the form is in the submitting state by matching the current state with the list of editing states
export const isFormSubmitting = (appState: AppMachineStateT) => {
  // List of all state machine CRUD child states which should have a loader
  const EDITING_DEBT_STATES = [
    'loaded.debtManager.onboarding.accountsEditing.editingDebt',
    'loaded.debtManager.onboarding.accountsEditing.addingDebt',
    'loaded.debtManager.onboarding.accountsEditing.updatingCreditorAgreement',
    'loaded.debtManager.onboarding.accountsEditing.togglingPrioritizedFromDebtEditing',
    'loaded.debtManager.onboarding.maxAccounts.editingDebt',
    'loaded.debtManager.onboarding.maxAccounts.updatingCreditorAgreement',
    'loaded.debtManager.onboarding.maxAccounts.addingDebt',
    'loaded.debtManager.onboarding.maxAccounts.togglingPrioritizedFromDebtEditing',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.accountsEditing.editingDebt',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.accountsEditing.addingDebt',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.accountsEditing.updatingCreditorAgreement',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.accountsEditing.togglingPrioritizedFromDebtEditing',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.accountsEditing.togglingPrioritizedFromPaymentPlan',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.accountsEditing.editingDebt',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.accountsEditing.addingDebt',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.accountsEditing.updatingCreditorAgreement',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.accountsEditing.togglingPrioritizedFromDebtEditing',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.accountsEditing.togglingPrioritizedFromPaymentPlan',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.accountsEditing.updatingAmountPaidAndUpsertTransactionFromPaymentPlan',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.maxAccounts.editingDebt',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.maxAccounts.addingDebt',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.maxAccounts.updatingCreditorAgreement',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.maxAccounts.togglingPrioritizedFromDebtEditing',
    'loaded.debtManager.paymentPlan.disqualified.accountEditing.maxAccounts.togglingPrioritizedFromPaymentPlan',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.maxAccounts.editingDebt',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.maxAccounts.addingDebt',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.maxAccounts.updatingCreditorAgreement',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.maxAccounts.togglingPrioritizedFromDebtEditing',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.accountEditing.maxAccounts.togglingPrioritizedFromPaymentPlan',
    'loaded.debtManager.paymentPlan.disqualified.paymentPlanEditing.updatingAmountPaidFromPaymentPlan',
    'loaded.debtManager.paymentPlan.disqualified.paymentPlanEditing.updatingAmountPaidFromDebtEditing',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.paymentPlanEditing.updatingAmountPaidFromPaymentPlan',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.paymentPlanEditing.updatingAmountPaidFromDebtEditing',
    'loaded.debtManager.paymentPlan.disqualified.paymentPlanEditing.markingItemAsCalledFromDebtEditing',
    'loaded.debtManager.paymentPlan.disqualified.paymentPlanEditing.markingItemAsCalledFromPaymentPlan',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.paymentPlanEditing.markingItemAsCalledFromDebtEditing',
    'loaded.debtManager.paymentPlan.displayPaymentPlan.paymentPlanEditing.markingItemAsCalledFromPaymentPlan',
    'loaded.debtManager.monthTransition.paymentPlanEditing.updatingAmountPaidFromPaymentPlan',
    'loaded.debtManager.monthTransition.debtEditingStates.editingDebt',
    'loaded.debtManager.monthTransition.debtEditingStates.addingDebt',
    'loaded.debtManager.monthTransition.debtEditingStates.updatingAmountPaidFromDebtsList',
    'loaded.debtManager.monthTransition.debtEditingStates.updatingAmountPaidAndUpsertTransactionFromPaymentPlan',
  ] as const;

  return EDITING_DEBT_STATES.some(match => appState.matches(match));
};

// Account List Utility Functions to check for visibility or overlapping of Popovers
export const isMarkAsPaidPopoverShown = (state: AppMachineStateT) => {
  const { paymentPlan } = state.context;
  const disqualified = isInDisqualifiedState(state);

  return !paymentPlan?.[PAYMENT_PLAN_FLAGS.POPOVER_DISMISSED_MARK_AS_PAID] && !disqualified;
};

export const isOtherPaybackPlanOptionPopoverShown = (state: AppMachineStateT) => {
  const { paymentPlan } = state.context;

  return !paymentPlan?.[PAYMENT_PLAN_FLAGS.POPOVER_DISMISSED_OTHER_PAYBACK_PLAN_OPTION];
};

export const isPaidOffPopoverShown = (state: AppMachineStateT) => {
  return state.context.showPaidOffPopover;
};

export const isComingFromDashBoard = () => {
  const { state } = history.location as Location<{ prevPath: string }>;
  return state ? state.prevPath === DASHBOARD_DEBT_MANAGER_NAV_HACK : false;
};

export const shouldGoToAddAccount = () => {
  const { state } = history.location as Location<{ prevPath: string }>;
  return state ? state.prevPath === DASHBOARD_DEBT_MANAGER_ADD_ACCOUNT_NAV_HACK : false;
};

export const isAnyPopoverShownInAccountList = (state: AppMachineStateT) => {
  if (!isInPaymentPlan(state)) {
    return false;
  }

  return isMarkAsPaidPopoverShown(state) || isPaidOffPopoverShown(state);
};

export const isInMonthTransition: ActionFunction<InitialContextT, EventObject> = (_, __, { state }) =>
  state.matches('loaded.debtManager.monthTransition');
export const isBudgetMachineIdle: ActionFunction<InitialContextT, EventObject> = (_, __, { state }) => {
  const stateValues = state.toStrings();
  return stateValues.some(value => value.includes('loaded.budget.initial'));
};

export const isInEditFlow = () => {
  const { pathname } = history.location;
  return pathname === BUDGET_EDIT_EXPENSES_PATH;
};

export const shouldDisclaimerStatusBeUpdated = (context: InitialContextT) => {
  return hasPaymentPlan(context) && !context.paymentPlan?.isDisclaimerDisplayed;
};

export const checkPlanForInvalidDebts = (debts: TAccount[], paymentPlan: TPaymentPlan) => {
  const { amountsPaid } = paymentPlan;
  const validDebtIds = debts
    .filter(debt => [ACCOUNT_STATUSES.ACTIVE, ACCOUNT_STATUSES.PENDING].includes(debt.status))
    .map(debt => debt.debtId);
  const cleanedAmountsPaid = amountsPaid.filter(
    amountItem => !!amountItem.debtId && validDebtIds.includes(amountItem.debtId)
  );
  return amountsPaid.length !== cleanedAmountsPaid.length;
};

export const hasInvalidDebts = (
  _: InitialContextT,
  event: EventObject & ApolloQueryResult<{ data: { debts: TAccount[]; paymentPlans: TPaymentPlan[] } }>
) => {
  const { paymentPlans, debts } = event.data.data;

  const activePaymentPlans = paymentPlans.filter(paymentPlan => paymentPlan.status === PAYMENT_PLAN_STATUSES.ACTIVE);
  if (!activePaymentPlans.length || !debts.length) return false;

  const latestPaymentPlan = sortPlansByMonth(activePaymentPlans)[activePaymentPlans.length - 1] as TPaymentPlan;
  return checkPlanForInvalidDebts(debts, latestPaymentPlan);
};
