import React from 'react';

import { getAnswerValidationRules } from '@/domain/questions/validation';

import i18n from '@/libs/i18n';

import { trans } from '@/util/i18n';
import { toUSD } from '@/util/currency';

import Field from '@/components/FormField';
import RadioGroup from '@/components/FormField/RadioGroup';
import SelectField from '@/components/FormField/SelectField';

import STATES_I18N from '@/data/states';

import createQuestionFactory from './createQuestionFactory';

const getStatesAsOptions = () =>
  Object.entries(STATES_I18N).map(([stateKey, stateI18nKey]) => ({
    key: stateKey,
    text: i18n.t(stateI18nKey),
    value: stateKey,
  }));

const generalQuestionFactory = createQuestionFactory('general', getAnswerValidationRules);

/**
 * Loan amount should not be limited, but we still need to employ validation
 * to prevent people from entering silly values.
 */
export const loanAmountQ = generalQuestionFactory({
  name: 'loanAmount',
  title: trans('i18n-questions:amount-placeholder'),
  microcopy: trans('i18n-questions:loan-amount.microcopy'),
  component: Field,
  defaultProps: {
    placeholder: () => i18n.t('i18n-questions:amount-placeholder'),
    isCurrencyInput: true,
    inputType: 'number',
    formatAnswer: (value: string) => toUSD(Number(value)),
  },
});
export const expenseNicknameQ = generalQuestionFactory({
  name: 'expenseNickname',
  title: trans('i18n-questions:expense-nickname.title'),
  subtitle: trans('i18n-questions:expense-nickname.subtitle'),
  component: Field,
  defaultProps: {
    trim: true,
    maxLength: 64,
    showCharCount: true,
  },
});
export const healthInsuranceQ = generalQuestionFactory({
  name: 'healthInsurance',
  title: <strong>{trans('i18n-questions:health-insurance.title')}</strong>,
  component: RadioGroup,
  defaultProps: {
    fields: [
      {
        valueName: 'hasPrivateInsurance',
        label: trans('i18n-questions:health-insurance.field-1.label'),
        id: 'a',
        subText: trans('i18n-questions:health-insurance.field-1.subtext'),
      },
      {
        valueName: 'hasMedicare',
        label: trans('i18n-questions:health-insurance.field-2.label'),
        id: 'b',
      },
      {
        valueName: 'hasMedicaid',
        label: trans('i18n-questions:health-insurance.field-3.label'),
        id: 'c',
      },
      {
        valueName: 'uninsured',
        label: trans('i18n-questions:health-insurance.field-4.label'),
        id: 'd',
      },
    ],
  },
});

export const medicalBillQ = generalQuestionFactory({
  name: 'medicallBill',
  title: <strong>{trans('i18n-questions:medical-bill.title')}</strong>,
  component: RadioGroup,
  defaultProps: {
    fields: [
      {
        valueName: 'planningAhead',
        label: trans('i18n-questions:medical-bill.field-1.label'),
        id: 'a',
      },
      {
        valueName: 'receivedBill',
        label: trans('i18n-questions:medical-bill.field-2.label'),
        id: 'b',
      },
      {
        valueName: 'billPastDue',
        label: trans('i18n-questions:medical-bill.field-3.label'),
        id: 'c',
      },
    ],
  },
});

export const cityQ = generalQuestionFactory({
  name: 'city',
  title: trans('i18n-questions:city.title'),
  component: Field,
  defaultProps: {
    width: 12,
    label: trans('i18n-questions:city.title'),
    placeholder: () => i18n.t('i18n-questions:city.placeholder'),
  },
});

export const zipQ = generalQuestionFactory({
  name: 'zip',
  title: trans('i18n-questions:zip.title'),
  component: Field,
  defaultProps: {
    width: 16,
    label: trans('i18n-questions:zip.title'),
    placeholder: () => i18n.t('i18n-questions:zip.placeholder'),
  },
});

export const stateQ = generalQuestionFactory({
  name: 'state',
  title: trans('i18n-questions:state.title'),
  component: SelectField,
  defaultProps: {
    search: true,
    selection: true,
    options: getStatesAsOptions,
    width: 9,
    label: trans('i18n-questions:state.title'),
    placeholder: () => i18n.t('i18n-questions:state.placeholder'),
  },
});

export const locationQ = generalQuestionFactory({
  name: 'location',
  questions: [cityQ, stateQ, zipQ],
});

export const householdIncomeQ = generalQuestionFactory({
  name: 'householdIncome',
  component: Field,
  defaultProps: {
    placeholder: () => i18n.t('i18n-questions:amount-placeholder'),
    isCurrencyInput: true,
    inputType: 'number',
    formatAnswer: value => toUSD(Number(value)),
  },
});

// does not render a form field, used to save the list of current verticals
// at the moment it saves an array with a single item
export const currentVerticalsQ = generalQuestionFactory({
  name: 'currentVerticals',
  component: Field,
  hidden: true,
});
