/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { medicalBillQ, healthInsuranceQ, expenseNicknameQ } from '@/domain/questions';
import {
  BASE_PATH_ONBOARDING_WIZARD,
  BILLS_LANDING_PAGE_PATH,
  DASHBOARD_UNPLANNED_EXPENSES_PATH,
  MY_PLAN_PATH,
  QUESTIONS_FIRST_PATH,
  QUESTIONS_SECOND_PATH,
} from '@/domain/paths';

import { trans } from '@/util/i18n';

import IconLink from '@/components/IconLink';
import AuthControls from '@/components/AuthControls';
import MyPlanButtonText from '@/components/QuestionsWizard/MyPlanButtonText';
import PrerequisiteQuestions from '@/components/PrerequisiteQuestions';
import { useParams } from 'react-router';

const header = {
  renderLeftItem: () => (
    <IconLink href={BILLS_LANDING_PAGE_PATH} icon="home">
      {trans('home')}
    </IconLink>
  ),
  renderCenterItem: step => {
    const progress = step?.progress;
    return progress
      ? trans('step-progress', {
          transProgressCurrent: progress.current,
          transProgressTotal: progress.total,
        })
      : '';
  },
  renderRightItem: () => <AuthControls />,
};

const ConfiguredQuestions = React.memo(() => {
  const { id }: { id: string } = useParams();

  if (id == 'questions-first') {
    return (
      <PrerequisiteQuestions
        heading={trans('about-your-situation')}
        subheader={trans('start-action-plan.medical-expenses.step-2')}
      />
    );
  }

  if (id == 'questions-second') {
    return (
      <PrerequisiteQuestions
        heading={trans('about-your-situation')}
        subheader={trans('start-action-plan.medical-expenses.step-3')}
      />
    );
  }

  return (
    <PrerequisiteQuestions
      heading={trans('start-action-plan')}
      subheader={trans('start-action-plan.medical-expenses')}
    />
  );
});

export const steps = {
  'questions-nickname': {
    id: 'questions-nickname',
    questions: [expenseNicknameQ],
    path: `${BASE_PATH_ONBOARDING_WIZARD}/questions-nickname`,
    component: ConfiguredQuestions,
  },
  'questions-first': {
    id: 'questions-first',
    questions: [medicalBillQ],
    path: QUESTIONS_FIRST_PATH,
    progress: {
      current: 1,
      total: 2,
    },
    component: ConfiguredQuestions,
  },
  'questions-second': {
    id: 'questions-second',
    questions: [healthInsuranceQ],
    path: QUESTIONS_SECOND_PATH,
    progress: {
      current: 2,
      total: 2,
    },
    component: ConfiguredQuestions,
    nextButton: <MyPlanButtonText />,
  },
} as const;

const initialState = {
  stepId: 'questions-nickname',
};

const medicalOnboardingConfig = {
  name: 'medicalOnboardingFlow',
  isFlowEnabled: () => true,
  path: `${BASE_PATH_ONBOARDING_WIZARD}/:id`,
  header,
  steps,
  reducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'questions-nickname':
        return {
          ...state,
          stepId: 'questions-first',
        };
      case 'questions-first':
        return { ...state, stepId: 'questions-second' };
      case 'questions-second':
        return { ...state, nextLocation: { pathname: MY_PLAN_PATH } };
      default:
        return initialState;
    }
  },
  reverseReducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'questions-nickname':
        return {
          ...state,
          nextLocation: { pathname: DASHBOARD_UNPLANNED_EXPENSES_PATH },
        };
      case 'questions-first':
        return { ...state, stepId: 'questions-nickname' };

      case 'questions-second':
        return { ...state, stepId: 'questions-first' };
      default:
        return initialState;
    }
  },
};

export default medicalOnboardingConfig;
