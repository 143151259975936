/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { CREDIT_CARD_FLOW_PERSONAL_ASSESSMENT_PATH } from '@/domain/paths';

import PersonalAssessment from '@/pages/common/PersonalAssessment';
import YourPersonalAssessmentCardContent from '@/pages/common/YourPersonalAssessmentCardContent';

import CustomContainer from '@/components/CustomContainer';
import Divider from '@/components/Divider';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import UpNext from '@/components/UpNext';
import NumberedList from '@/components/NumberedList';
import { WizardContext } from '@/components/QuestionsWizard/StepWrapper';

import allOptionsIcon from '@/images/allOptionsIcon.svg';

const HaveQuestionsCardContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading as="h5">{t('have-questions')}</Heading>
      <Paragraph textAlign="left">{t('i18n-credit:have-questions.content')}</Paragraph>
    </>
  );
};

export const HowToGetContent = ({ hideRightColumn = false }) => {
  const { t } = useTranslation('i18n-credit');
  return (
    <>
      <Heading as="h1">{t('how-to-get.heading')}</Heading>
      <Paragraph noHorizontalMargin>{t('how-to-get.paragraph-1')}</Paragraph>
      <NumberedList testId="howToGetList" items={t('how-to-get.items')} />
      {!hideRightColumn && (
        <PersonalAssessment
          assessmentCard={
            <YourPersonalAssessmentCardContent
              url={CREDIT_CARD_FLOW_PERSONAL_ASSESSMENT_PATH}
              content={t('how-to-get.assessment')}
            />
          }
          questionsCard={<HaveQuestionsCardContent />}
        />
      )}
    </>
  );
};

const HowToGet = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-credit');
  const { stepId, handleBack } = React.useContext(WizardContext);

  return (
    <CustomContainer noMargin verticallyPadded>
      <HowToGetContent hideRightColumn={fromAssessment} />
      {!fromAssessment && (
        <QuestionsForm fullWidth backButton={false}>
          <UpNext
            stepId={stepId}
            onSecondaryClick={() => handleBack()}
            imageSrc={allOptionsIcon}
            primaryButtonText={t('i18n-actions:button.payment-options')}
            primaryButtonProps={{ primary: false, secondary: true }}
          >
            <Paragraph>{t('how-to-get.paragraph-2')}</Paragraph>
          </UpNext>
        </QuestionsForm>
      )}
    </CustomContainer>
  );
};

export default HowToGet;
