import { ActionFunction, assign, EventObject } from 'xstate';

import { DEBT_PLAN_LANDING_PAGE_PATH } from '@/domain/paths/debt';
import {
  createAllocatedPayments,
  mergePaymentPlanData as mergePaymentPlanDataBase,
} from '@/domain/stateMachines/debtManager/debtsPlanMachineHelpers';
import {
  setSelectedDate,
  trackMarkedDebtAsPaid,
  trackCompletedActionableItem,
  trackDisqualification,
  trackPaymentPlan,
  trackUpdateAvailableFunds,
  showProgressPopover,
} from '@/domain/stateMachines/debtManager/paymentPlanStates';
import {
  mergePaymentPlanWithDebts,
  assignReasonsForDisqualification as assignReasonsForDisqualificationBase,
  isAccountPaidOffByDebtId,
} from '@/domain/debtsPlan';
import {
  trackAddedAccount,
  trackEditedAccount,
  trackMarkedAsPrioritized,
  trackCreditorAgreement,
  trackCreditorAgreementUpdate,
} from '@/domain/stateMachines/debtManager/debtCrudStates';
import {
  trackStartBudget,
  trackFinishBudget,
  trackStartSavings,
  trackFinishLargePurchaseOnboarding,
} from '@/domain/stateMachines/budget';
import {
  showMonthTransitionPaidOffPopover,
  clearMonthTransitionPaidOffPopover,
  finishMonthTransition,
} from '@/domain/stateMachines/debtManager/monthTransitionStates';

import type { TAnalytics } from '@/services/analytics';

import history from '@/util/history';
import { trans } from '@/util/i18n';

import { TNotify, ToastType } from '@/types/notification';

import type { ApolloClient } from '@apollo/client';
import type { InitialContextT } from '.';

interface InitializeCtxEvent extends EventObject {
  client: ApolloClient<unknown>;
  analytics: TAnalytics | Record<string, never>;
  notify: TNotify | (() => undefined);
  userId?: string;
  sendEvent: Function;
}

const initializeContext = assign<InitialContextT, InitializeCtxEvent>((_, event) => {
  const { client, analytics, notify, userId, sendEvent } = event;
  return { client, analytics, notify, userId, sendEvent };
});
const resetPath = assign<InitialContextT>({ path: '' });
// Navigation Actions.
const navigateToLandingPage = () => history.push(DEBT_PLAN_LANDING_PAGE_PATH);

// Notifications actions.
const notifyError: ActionFunction<InitialContextT, EventObject> = context => {
  const { notify } = context;
  notify({
    content: trans('something-went-wrong.try-again'),
    type: ToastType.ERROR,
  });
};
// Tracking actions.
const closeModal: ActionFunction<InitialContextT, EventObject & { data: { closeModal?: Function } }> = (_, event) => {
  const { closeModal: eventCloseModal } = event.data;
  if (typeof eventCloseModal === 'function') {
    return eventCloseModal();
  }
  return false;
};
// @ts-expect-error TBD
const mergePaymentPlanData = assign<InitialContextT>(mergePaymentPlanDataBase);
const assignReasonsForDisqualification = assign<InitialContextT>(assignReasonsForDisqualificationBase);
// @ts-expect-error TBD
const allocatePayments = assign<InitialContextT>(createAllocatedPayments);
const showPaidOffPopover = assign<InitialContextT, EventObject & { data: { debtId: string } }>({
  showPaidOffPopover: (context, event) => {
    const { allocatedPayments } = context;
    const { debtId } = event.data;
    const debtsPlan = mergePaymentPlanWithDebts(context);
    // Don't show the popover when the accounts is already paid off.
    if (allocatedPayments.amountsPaid && isAccountPaidOffByDebtId(allocatedPayments.amountsPaid, debtId)) {
      return false;
    }
    return isAccountPaidOffByDebtId(debtsPlan.amountsPaid || [], debtId);
  },
});
const hidePaidOffPopover = assign<InitialContextT>({ showPaidOffPopover: false });

const resetLoadedStates = assign<InitialContextT>({
  budget: (context: InitialContextT) => {
    const { budget } = context;
    return {
      ...budget,
      isLoaded: false,
    };
  },

  savings: (context: InitialContextT) => {
    const { savings } = context;

    return {
      ...savings,
      isLoaded: false,
    };
  },
});
export * from './budget/actions';
export * from './debtManager/actions';
export * from './savings/actions';

export {
  initializeContext,
  resetPath,
  navigateToLandingPage,
  notifyError,
  closeModal,
  mergePaymentPlanData,
  assignReasonsForDisqualification,
  allocatePayments,
  showPaidOffPopover,
  hidePaidOffPopover,
  showMonthTransitionPaidOffPopover,
  clearMonthTransitionPaidOffPopover,
  showProgressPopover,
  setSelectedDate,
  finishMonthTransition,
  trackAddedAccount,
  trackEditedAccount,
  trackCreditorAgreement,
  trackCreditorAgreementUpdate,
  trackMarkedAsPrioritized,
  trackUpdateAvailableFunds,
  trackMarkedDebtAsPaid,
  trackCompletedActionableItem,
  trackDisqualification,
  trackPaymentPlan,
  trackStartBudget,
  trackFinishBudget,
  trackStartSavings,
  trackFinishLargePurchaseOnboarding,
  resetLoadedStates,
};
