/* eslint-disable */
// @ts-nocheck
import { number, string, object, array } from 'yup';
import {
  NB_ESSENTIALS_EXPENSES_TYPE_ID,
  NB_EXPENSES_IDS,
  NB_DEBT_PAYMENTS_TYPE_ID,
  NB_DEBT_PAYMENTS_TYPES,
  NB_ESSENTIALS_TYPES,
  NB_SAVINGS_TYPES,
  NB_SAVINGS_TYPE_ID,
  BUDGET_STATUSES,
  allExpensesIdsFlatList,
  validationExceptions,
} from 'common/model/budget';
import { REQUIRED_ERROR_TEMPLATE } from 'common/model/paymentPlan';
import { SAVINGS_TYPES } from 'common/model/savings';

import i18n from '@/libs/i18n';

import { trans } from '@/util/i18n';
import {
  isDateEqual,
  isDateGreaterThan,
  getMonthsDifferenceBetweenDates,
  createMonthYearFromMonthYearString,
  getCurrentMonthYear,
} from '@/util/date';
import { nicknameDoesNotContainAccountNumber, MAX_NICKNAME_DIGITS, MAX_NICKNAME_CHARACTERS } from '@/util/validation';
import { toTitleCase } from '@/util/string';

import type { CustomExpensesInput, TransactionType } from '@/types/generated/globalTypes';

import { AppMachineStateT, InitialContextT } from '../stateMachines';
import { isPaidOff } from '../debtsPlan';

import { hasCreditorAgreement, type TAccount } from '../debts';
import { getFrequencyValue } from '@/pages/Savings/helpers';

export interface TransactionList {
  date: number | null;
  type: TransactionType | null;
  amount: number | null;
  debtId: string | null;
  savingsLargePurchaseId: string | null;
  savingsRainyDayId: string | null;
  expense: string | null;
  description: string | null;
}

export interface Transaction {
  userId: string;
  year: string | null;
  month: string | null;
  list: (TransactionList | null)[] | null;
  dateUpdated: number | null;
  dateCreated: number | null;
}

export type BudgetStatusT = 'ACTIVE' | 'DRAFT' | 'INACTIVE';

export type TCategoriesIds = keyof typeof NB_EXPENSES_CATEGORIES;

export type ExpenseCategoryT = 'ESSENTIALS' | 'DEBT_PAYMENTS' | 'SAVINGS';

export type TCategory = {
  id: TCategoriesIds;
  i18nLabel: () => string;
  label: string;
  description: () => string;
  color: string;
  className: string;
};

/* STORAGE */
export const BUDGET_INITIATOR_STORAGE_KEY = 'budgetInitiator';
export const BUDGET_INITIATOR_SAVINGS_PLAN_STORAGE_KEY = 'budgetInitiatorSavingsId';
export const BUDGET_INITIATOR_NEW_MONTH_RESET_STORAGE_KEY = 'budgetNewMonthReset';
export const SAVINGS_IDK_FLOW_AS_BUDGET_INITIATOR_STORAGE_VALUE = 'Savings IDK flow';
export const SAVINGS_EDIT_BUDGET_FLOW_AS_BUDGET_INITIATOR_STORAGE_VALUE = 'Savings edit budget flow';
export const SAVINGS_REVIEW_BUDGET_FLOW_AS_BUDGET_INITIATOR_STORAGE_VALUE = 'Savings activity review budget flow';
export const DM_AS_BUDGET_INITIATOR_STORAGE_VALUE = 'DM';
export const TOOLS_AS_BUDGET_INITIATOR_STORAGE_VALUE = 'Tools';
export const DASHBOARD_AS_BUDGET_INITIATOR_STORAGE_VALUE = 'Dashboard';
export const RESEARCH_MODAL = 'ResearchModal';

/* LABELS */
export const NB_DEBT_PAYMENTS_LABELS = {
  [NB_DEBT_PAYMENTS_TYPES.MORTGAGE]: {
    main: 'Mortgage',
    i18nMain: () => i18n.t('i18n-budget:mortgage'),
  },
  [NB_DEBT_PAYMENTS_TYPES.HOME_EQUITY_LOAN]: {
    main: 'Home Equity Loan',
    i18nMain: () => i18n.t('i18n-budget:home-equity-loan'),
  },
  [NB_DEBT_PAYMENTS_TYPES.AUTO_LOAN]: {
    main: 'Auto Loan',
    i18nMain: () => i18n.t('i18n-budget:auto-loan'),
  },
  [NB_DEBT_PAYMENTS_TYPES.CREDIT_CARD]: {
    main: 'Credit Card Debt',
    i18nMain: () => i18n.t('i18n-budget:credit-card-debt'),
  },
  [NB_DEBT_PAYMENTS_TYPES.FEDERAL_STUDENT_LOAN]: {
    main: 'Federal Student Loan',
    i18nMain: () => i18n.t('i18n-budget:federal-student-loan'),
  },
  [NB_DEBT_PAYMENTS_TYPES.PRIVATE_STUDENT_LOAN]: {
    main: 'Private Student Loan',
    i18nMain: () => i18n.t('i18n-budget:private-student-loan'),
  },
  [NB_DEBT_PAYMENTS_TYPES.PERSONAL_LOAN]: {
    main: 'Personal Loan',
    i18nMain: () => i18n.t('i18n-budget:personal-loan'),
  },
  [NB_DEBT_PAYMENTS_TYPES.MEDICAL_BILL]: {
    main: 'Medical Debt',
    i18nMain: () => i18n.t('i18n-budget:medical-debt'),
  },
} as const;

const NB_ESSENTIALS_LABELS = {
  [NB_ESSENTIALS_TYPES.RENT]: {
    main: 'Rent',
    i18nMain: () => i18n.t('i18n-budget:rent'),
  },
  [NB_ESSENTIALS_TYPES.PERSONAL_CARE]: {
    main: 'Personal Care',
    i18nMain: () => i18n.t('i18n-budget:personal-care'),
  },
  [NB_ESSENTIALS_TYPES.UTILITIES]: {
    main: 'Utilities',
    i18nMain: () => i18n.t('i18n-budget:utilities'),
    subtitle: [trans('i18n-budget:water'), trans('i18n-budget:power')],
  },
  [NB_ESSENTIALS_TYPES.TRANSPORTATION]: {
    main: 'Transportation',
    i18nMain: () => i18n.t('i18n-budget:transportation'),
    subtitle: [trans('i18n-budget:gas'), trans('i18n-budget:lease'), trans('i18n-budget:fare')],
  },
  [NB_ESSENTIALS_TYPES.GROCERIES]: {
    main: 'Groceries',
    i18nMain: () => i18n.t('i18n-budget:groceries'),
  },
  [NB_ESSENTIALS_TYPES.PHONE]: {
    main: 'Phone',
    i18nMain: () => i18n.t('i18n-budget:phone'),
  },
  [NB_ESSENTIALS_TYPES.HEALTH_SERVICES]: {
    main: 'Health Services',
    i18nMain: () => i18n.t('i18n-budget:health-services'),
  },
  [NB_ESSENTIALS_TYPES.CABLE_AND_WIFI]: {
    main: 'Cable & Wi-Fi',
    i18nMain: () => i18n.t('i18n-budget:cable-wifi'),
  },
  [NB_ESSENTIALS_TYPES.CHILD_CARE]: {
    main: 'Childcare',
    i18nMain: () => i18n.t('i18n-budget:childcare'),
  },
  [NB_ESSENTIALS_TYPES.ADULT_CARE]: {
    main: 'Adultcare',
    i18nMain: () => i18n.t('i18n-budget:adultcare'),
  },
  [NB_ESSENTIALS_TYPES.AUTO]: {
    main: 'Auto Insurance',
    i18nMain: () => i18n.t('i18n-budget:auto-insurance'),
    subtitle: [trans('i18n-budget:gas'), trans('i18n-budget:lease'), trans('i18n-budget:fare')],
  },
  [NB_ESSENTIALS_TYPES.HOME_OWNER]: {
    main: 'Homeowner Insurance',
    i18nMain: () => i18n.t('i18n-budget:homeowner-insurance'),
  },
  [NB_ESSENTIALS_TYPES.RENTER]: {
    main: 'Renter Insurance',
    i18nMain: () => i18n.t('i18n-budget:renter-insurance'),
  },
  [NB_ESSENTIALS_TYPES.HEALTH]: {
    main: 'Health Insurance',
    i18nMain: () => i18n.t('i18n-budget:health-insurance'),
  },
  [NB_ESSENTIALS_TYPES.LIFE]: {
    main: 'Life Insurance',
    i18nMain: () => i18n.t('i18n-budget:life-insurance'),
  },
  [NB_ESSENTIALS_TYPES.DISABILITY]: {
    main: 'Disability Insurance',
    i18nMain: () => i18n.t('i18n-budget:disability-insurance'),
  },
  [NB_ESSENTIALS_TYPES.OTHER]: {
    main: 'Other Insurance',
    i18nMain: () => i18n.t('i18n-budget:other-insurance'),
  },
  [NB_ESSENTIALS_TYPES.PETS]: {
    main: 'Pets',
    i18nMain: () => i18n.t('i18n-budget:pets'),
  },
  [NB_ESSENTIALS_TYPES.WORK_EXPENSES]: {
    main: 'Work Expenses',
    i18nMain: () => i18n.t('i18n-budget:work-expenses'),
  },
  [NB_ESSENTIALS_TYPES.CHARITY]: {
    main: 'Charity',
    i18nMain: () => i18n.t('i18n-budget:charity'),
  },
  [NB_ESSENTIALS_TYPES.HOME_SERVICES]: {
    main: 'Home Services',
    i18nMain: () => i18n.t('i18n-budget:home-services'),
  },
  [NB_ESSENTIALS_TYPES.FUN]: {
    main: 'Fun',
    i18nMain: () => i18n.t('i18n-budget:fun'),
  },
  [NB_ESSENTIALS_TYPES.SUBSCRIPTIONS]: {
    main: 'Subscriptions',
    i18nMain: () => i18n.t('i18n-budget:subscriptions'),
  },
  [NB_ESSENTIALS_TYPES.CLOTHING_AND_GOODS]: {
    main: 'Clothing & Goods',
    i18nMain: () => i18n.t('i18n-budget:clothing-good'),
  },
  [NB_ESSENTIALS_TYPES.EATING_OUT]: {
    main: 'Eating Out',
    i18nMain: () => i18n.t('i18n-budget:eating-out'),
  },
  [NB_ESSENTIALS_TYPES.TRAVEL]: {
    main: 'Travel',
    i18nMain: () => i18n.t('i18n-budget:travel'),
  },
  [NB_ESSENTIALS_TYPES.OTHER_EXPENSES]: {
    main: 'Other',
    i18nMain: () => i18n.t('i18n-budget:other'),
    subtitle: [trans('i18n-budget:anything-else')],
  },
} as const;

const NB_SAVINGS_LABELS = {
  [NB_SAVINGS_TYPES.EMERGENCY_FUND]: {
    main: 'Rainy Day Fund',
    i18nMain: () => i18n.t('i18n-budget:rainy-day-fund'),
    subtitle: [trans('i18n-budget:emergency-savings')],
  },
  [NB_SAVINGS_TYPES.EDUCATION_SAVINGS]: {
    main: 'Education Savings (529)',
    i18nMain: () => i18n.t('i18n-budget:education-savings-529'),
  },
  [NB_SAVINGS_TYPES.RETIREMENT_400X]: {
    main: '401k, 403b, or 457',
    i18nMain: () => i18n.t('i18n-budget:401k-403b-or-457'),
    subtitle: [trans('i18n-budget:retirement-savings')],
  },
  [NB_SAVINGS_TYPES.RETIREMENT_IRA]: {
    main: 'IRA',
    i18nMain: () => i18n.t('i18n-budget:ira'),
    subtitle: [trans('i18n-budget:retirement-savings')],
  },
  [NB_SAVINGS_TYPES.LARGE_PURCHASE]: {
    main: 'Large Purchase',
    i18nMain: () => i18n.t('i18n-budget:large-purchase'),
  },
  [NB_SAVINGS_TYPES.OTHER_SAVINGS]: {
    main: 'Other Savings',
    i18nMain: () => i18n.t('i18n-budget:other-savings'),
  },
} as const;

export const NB_EXPENSES_LABELS = {
  ESSENTIALS: NB_ESSENTIALS_LABELS,
  DEBT_PAYMENTS: NB_DEBT_PAYMENTS_LABELS,
  SAVINGS: NB_SAVINGS_LABELS,
} as const;

export const NB_EXPENSES_CATEGORIES = {
  ESSENTIALS: {
    id: 'ESSENTIALS',
    i18nLabel: () => i18n.t('i18n-budget:category.essentials.title'),
    label: 'Essentials',
    description: () => i18n.t('i18n-budget:essentials-description'),
    color: '#F5D023',
    className: 'progressBarItemEssentials',
  },
  SAVINGS: {
    id: 'SAVINGS',
    i18nLabel: () => i18n.t('i18n-budget:category.savings.title'),
    label: 'Savings',
    description: () => i18n.t('i18n-budget:savings-description'),
    color: '#07646C',
    className: 'progressBarItemSavings',
  },
  DEBT_PAYMENTS: {
    id: 'DEBT_PAYMENTS',
    i18nLabel: () => i18n.t('i18n-budget:category.debt-payments.title'),
    label: 'Debt Payments',
    description: () => i18n.t('i18n-budget:debt-payments-description'),
    color: '#FD4F5D',
    className: 'progressBarItemDebtPayments',
  },
} as const;

export const NB_ORDERED_EXPENSES = [
  NB_EXPENSES_CATEGORIES.DEBT_PAYMENTS,
  NB_EXPENSES_CATEGORIES.SAVINGS,
  NB_EXPENSES_CATEGORIES.ESSENTIALS,
] as const;

export const DEFAULT_EXPENSES = [
  'rent',
  'personalCare',
  'utilities',
  'emergencyFund',
  'educationSavings',
  'retirementSavings400',
  'autoInsurance',
  'homeInsurance',
  'renter',
  'pets',
  'workExpenses',
  'charity',
] as const;

export const getOrderedExpensesIndex = (category: TCategory) =>
  NB_ORDERED_EXPENSES.findIndex(expense => expense.id === category.id);

export const getCategoryHeader = (id: ExpenseCategoryT) => {
  switch (id) {
    case 'DEBT_PAYMENTS':
      return i18n.t('i18n-budget:category.debt-payments.title');
    case 'SAVINGS':
      return i18n.t('i18n-budget:category.savings.title');
    case 'ESSENTIALS':
    default:
      return i18n.t('i18n-budget:category.essentials.title');
  }
};

export const getCTAText = (id: ExpenseCategoryT) => {
  switch (id) {
    case 'SAVINGS':
      return i18n.t('i18n-budget:cta.savings');
    case 'DEBT_PAYMENTS':
      return i18n.t('i18n-budget:cta.debt');
    case 'ESSENTIALS':
    default:
      return i18n.t('i18n-budget:cta.essentials');
  }
};

export const mapExpenseIdToExpense = (category: ExpenseCategoryT, id: string, customExpenses = []) => {
  // Find the matching key for the expense which has the id provided
  const expenseKey = Object.entries(NB_EXPENSES_IDS[category]).find(([, expenseId]) => expenseId === id)?.[0];
  if (!expenseKey) {
    // look for custom expenses
    const customExpense = customExpenses.find(expense => expense.id === id);
    return {
      id: customExpense?.id,
      bucket: customExpense?.bucket,
      category: customExpense?.category,
      main: customExpense?.main,
      i18nMain: () => customExpense?.main,
      subtitle: null,
      minMonthlyPayment: customExpense?.minMonthlyPayment,
    };
  }
  return NB_EXPENSES_LABELS[category][expenseKey];
};

export const mapExpenseTypeToLabel = (category: ExpenseCategoryT, type: string) =>
  NB_EXPENSES_LABELS[category][type].main;

export const mapExpenseTypeToI18nLabel = (category: ExpenseCategoryT, type: string) =>
  NB_EXPENSES_LABELS[category][type].i18nMain;

export const mapExpenseIdToLabel = (category: ExpenseCategoryT, id: string) => {
  // Find the matching key for the expense which has the id provided
  const expenseKey = Object.entries(NB_EXPENSES_IDS[category]).find(([, expenseId]) => expenseId === id)?.[0];
  if (!expenseKey) return undefined;
  return NB_EXPENSES_LABELS[category][expenseKey].i18nMain();
};

export const mapExpenseIdToCategoryKey = value => {
  for (const categoryKey in NB_EXPENSES_IDS) {
    const category = NB_EXPENSES_IDS[categoryKey];
    for (const key in category) {
      if (category[key] === value) {
        return { category: categoryKey, key };
      }
    }
  }
};

// Allows us to map the expense id to the label directly without having to know the category
export const mapExpenseIdToLabelDirect = (expense: string) => {
  const { category, key } = mapExpenseIdToCategoryKey(expense);
  return NB_EXPENSES_LABELS[category][key].main;
};

export const userHasAtLeastOneDebt = (debts: TAccount[]) => debts.length >= 1;

function getAllowedProperties(source: Record<string, unknown>, allowed: Record<string, unknown>) {
  return Object.keys(source)
    .filter(key => Object.values(allowed).includes(key))
    .reduce((obj, key) => {
      obj[key] = source[key];
      return obj;
    }, {});
}

/**
 * Sum of all monthly expenses.
 * @param expenses - object with the names and values of the expenses.
 */
export const calculateSumOfMonthlyExpenses = (
  expenses: Record<string, number>,
  customExpenses,
  expenseFilter?: Function
) => {
  // sum all default expenses
  let result = Object.values(expenses).reduce((sum, expense) => {
    const sanitizedExpense = Number(expense) || 0;
    if (typeof expenseFilter === 'function') {
      if (expenseFilter(expense) === true) {
        return sum + sanitizedExpense;
      }
      return sum;
    }
    return sum + sanitizedExpense;
  }, 0);

  if (customExpenses) {
    // sum all custom expenses
    result = customExpenses.reduce((sum, expense) => {
      const sanitizedExpense = Number(expense.amount) || 0;
      if (typeof expenseFilter === 'function') {
        if (expenseFilter(expense.value) === true) {
          return sum + sanitizedExpense;
        }
        return sum;
      }
      return sum + sanitizedExpense;
    }, result);
  }

  return result;
};

/**
 * Calculates the remaining funds towards unpaid accounts.
 * @param {Object} props
 * @param {number} props.netMonthlyIncome
 * @param {number} props.sumOfMonthlyExpenses
 * @param {number} props.amountAlreadyPaidThisMonth
 * @param {Object[]} props.selectedExpenses
 * @param {Object[]} props.debtPaymentExpenses
 */
export const calculateRemainingFundsTowardsUnpaidAccounts = ({
  netMonthlyIncome,
  sumOfMonthlyExpenses,
  amountAlreadyPaidThisMonth,
  selectedExpenses,
  availableFunds,
}) => {
  let remainingFundsTowardsUnpaidAccounts = netMonthlyIncome - sumOfMonthlyExpenses;

  if (selectedExpenses) {
    selectedExpenses.forEach(expense => {
      remainingFundsTowardsUnpaidAccounts -= expense.value;
    });
  }

  if (availableFunds) {
    remainingFundsTowardsUnpaidAccounts -= availableFunds;
  } else {
    remainingFundsTowardsUnpaidAccounts += amountAlreadyPaidThisMonth;
  }

  return Number(remainingFundsTowardsUnpaidAccounts.toFixed(2));
};

// Check if the budget form is in the submitting state by matching the current state with the list of editing states
export const isBudgetFormSubmitting = (appState: AppMachineStateT) => {
  // List of all budget state machine CRUD child states which should have a loader
  const EDITING_BUDGET_STATES = [
    'loaded.budget.updatingRemainingFundsTowardsUnpaidAccounts',
    'loaded.debtManager.onboarding.updatingRemainingFundsTowardsUnpaidAccounts',
  ] as const;

  return EDITING_BUDGET_STATES.some(match => appState.matches(match));
};

/**
 * Returns the category name of a given expense
 *
 * TODO: Handle debt types as well
 */
export const mapExpenseToCategory = (expenseId: string, customExpenses: {id: string}[]): string => {
  const category = Object.entries(NB_EXPENSES_IDS).reduce((result, [category, expensesIds]) => {
    const foundExpense = Object.entries(expensesIds).find(([, id]) => id === expenseId);
    if (foundExpense) return category;
    return result;
  }, '');

  if (!category) {
    return customExpenses.find(expense => expense.id === expenseId)?.category ?? ''
  }

  return category;
};

/**
 * Constructs the object of the expenses with amounts and groups them by the category.
 * @param expenses - object with the names and values of the expenses.
 * @param selectedExpenses - collection of the selected expenses ids.
 */
export const mapExpensesWithAmountsToCategories = (expenses, customExpenses, selectedExpenses, savingsExpenses) => {
  const result = Object.entries(NB_EXPENSES_IDS).reduce((combinedResults, [category, expensesIds]) => {
    const expensesWithAmounts = Object.entries(expensesIds)
      .filter(([, expenseId]) => selectedExpenses.some(selectedExpense => selectedExpense.type === expenseId))
      .reduce((total, [, expenseId]) => expenses[expenseId] + total, 0);
    return {
      ...combinedResults,
      [category]: expensesWithAmounts,
    };
  }, {});

  // Add custom expenses
  customExpenses?.forEach(({ id, amount, category }) => {
    if (selectedExpenses.some(selectedExpense => selectedExpense.type === id)) {
      if (result[category]) {
        result[category] += amount;
      } else {
        result[category] = amount;
      }
    }
  });

  // Add Savings expenses similar to custom expenses
  savingsExpenses?.forEach(({ type, amount, category }) => {
    if (selectedExpenses.some(selectedExpense => selectedExpense.type === type)) {
      if (result[category]) {
        result[category] += amount;
      } else {
        result[category] = amount;
      }
    }
  });

  return result;
};

export const mapExpenseSubCategoriesWithAmounts = (expenses, customExpenses, selectedExpenses, savingsExpenses) => {
  const expensesArray = Object.entries(NB_EXPENSES_IDS).map(([category, expensesIds]) => {
    const expensesWithAmounts = {};
    Object.entries(expensesIds)
      .filter(([, expenseId]) => selectedExpenses.some(selectedExpense => selectedExpense.type === expenseId))
      .forEach(([, expenseId]) => {
        expensesWithAmounts[expenseId] = expenses[expenseId];
      });
    return {
      [category]: expensesWithAmounts,
    };
  });

  const expensesObject = {};
  expensesArray.forEach(categoryExpenses =>
    Object.keys(categoryExpenses).forEach(category => {
      expensesObject[category] = categoryExpenses[category];
    })
  );

  // Add custom expenses
  customExpenses?.forEach(({ id, amount, category }) => {
    if (selectedExpenses.some(selectedExpense => selectedExpense.type === id)) {
      if (!expensesObject[category]) {
        expensesObject[category] = {};
      }
      expensesObject[category][id] = amount;
    }
  });

  // Add Savings expenses similar to custom expenses
  savingsExpenses?.forEach(({ type, amount, category }) => {
    if (selectedExpenses.some(selectedExpense => selectedExpense.type === type)) {
      if (!expensesObject[category]) {
        expensesObject[category] = {};
      }
      expensesObject[category][type] = amount;
    }
  });

  return expensesObject;
};

export const isExpenseADebt = (id: string) => {
  const debtIds = Object.values(NB_DEBT_PAYMENTS_TYPE_ID);
  return debtIds.includes(id);
};

// Budget level conditions
export const isBalancePositive = ({ balanceLeft }) => balanceLeft > 0;
export const isBalanceZero = ({ balanceLeft }) => balanceLeft === 0;
export const isBalanceNegative = ({ balanceLeft }) => balanceLeft < 0;
export const hasEmergencyFund = ({ expenses: { emergencyFund } }) => emergencyFund > 0;
export const hasDebtPayments = ({ debts }) => userHasAtLeastOneDebt(debts);
export const hasAtLeastOneExpense = expenses => Object.values(expenses).some(value => value > 0);
export const hasSavings = ({ expenses: { savings } }) => savings > 0;
export const hasAllEssentials = ({ expenses }) => {
  const essentials = getAllowedProperties(expenses, NB_ESSENTIALS_EXPENSES_TYPE_ID);

  return [...Object.values(essentials)].reduce((acc, val) => acc + val, 0) > 0;
};

export const isBudgetLoaded = ({ budget }: InitialContextT) => budget.isLoaded;

// Signifies that the user has started but not completed the budget onboarding
export const isBudgetCreated = ({ budget }: InitialContextT) =>
  budget.netMonthlyIncome != null && (budget.netMonthlyIncome >= 0 || hasAtLeastOneExpense(budget.expenses));

export const isBudgetActive = ({ budget }: InitialContextT) =>
  budget.isLoaded && budget?.status === BUDGET_STATUSES.ACTIVE;

// Signifies a user with a completed budget
export const isBudgetCompleted = ({ budget }: InitialContextT) => {
  return budget.isLoaded && budget?.status === BUDGET_STATUSES.ACTIVE && budget.netMonthlyIncome >= 0;
};

// Signifies a user with a budget up to date
export const isBudgetMonthTransitionCompleted = ({ budget }: InitialContextT) => {
  return (
    budget.isLoaded &&
    getMonthsDifferenceBetweenDates(
      getCurrentMonthYear(),
      createMonthYearFromMonthYearString(budget?.monthlyModalLastDisplayed)
    ) === 0
  );
};

// TODO: Check if nickname is required for Debts listed as Expenses
export const mapDebtsAsExpenses = (debts: TAccount[] = []) =>
  debts.map(debt => {
    const { type, debtId, nickname, debtBudget, minMonthlyPayment } = debt;
    return {
      id: debtId,
      bucket: type,
      value: debtBudget,
      debtId,
      main: nickname,
      isCustom: true,
      i18nMain: () => nickname,
      category: 'DEBT_PAYMENTS',
      hasCreditorAgreement: hasCreditorAgreement(debt),
      minMonthlyPayment,
    };
  });

export const getNotPaidOffDebts = (debts: TAccount[] = []) => debts.filter(debt => !isPaidOff(debt));

export type TSavingsIds =
  | 'savings'
  | 'emergencyFund'
  | 'educationSavings'
  | 'retirementSavings400'
  | 'retirementSavingsIra'
  | 'largePurchase';

export type TSavingsAsExpenses = {
  id: TSavingsIds;
  value: number;
}[];

export type TDebtsAsExpenses = {
  id: string;
  value: number;
  debtId: string;
  nickname: string;
}[];

export const mapSavingsAsExpenses = (list: TListSavingsData = []): TSavingsAsExpenses => {
  const result = list.map(savings => {
    const { amountToContributeMonthly, type, nickname, savingsId, frequencyOfContribution } = savings;

    const frequencyValue = getFrequencyValue(frequencyOfContribution);

    const amount = amountToContributeMonthly * frequencyValue;

    const key =
      type === SAVINGS_TYPES.LARGE_PURCHASE ? NB_SAVINGS_TYPES.LARGE_PURCHASE : NB_SAVINGS_TYPES.EMERGENCY_FUND;

    let main = nickname;

    if (!main) {
      if (type === SAVINGS_TYPES.RAINY_DAY) {
        main = i18n.t('i18n-budget:rainy-day-fund');
      }
      if (type === SAVINGS_TYPES.LARGE_PURCHASE) {
        main = i18n.t('i18n-budget:large-purchase');
      }
    }

    return {
      id: savingsId,
      i18nMain: () => main,
      main,
      value: amount,
      bucket: key,
      isCustom: true,
      category: 'SAVINGS',
    };
  });

  return result;
};

/**
 * Returns the value of `monthlyModalLastDisplayed`
 */
export const getStoredMonthYear = ({ budget }: InitialContextT) => budget?.monthlyModalLastDisplayed;

const EXPENSE_REQUIRED_ERROR = trans('error-message.expense-required');
const FIELD_REQUIRED_ERROR = trans('validation.field-required');
const MAX_CHARACTERS = 30;
const MAX_CHARACTERS_ERROR = trans('validation.max-characters', { MAX_CHARACTERS });

/*
 * this validation is used on the budget 3rd step
 * not allowing the user to proceed with zero value expenses
 */
export const expensesValidationRulesFrontend = (hasMin = true) => {
  const EXPENSE_AMOUNT_MIN = 0;
  const EXPENSE_AMOUNT_MIN_FRONTEND = 0.01;
  const EXPENSE_AMOUNT_MAX = 1000000;
  const EXPENSE_AMOUNT_LIMIT_MESSAGE = trans('error-message.expense-amount-limit', {
    EXPENSE_AMOUNT_MIN,
    EXPENSE_AMOUNT_MAX,
  });

  return number()
    .min(hasMin ? EXPENSE_AMOUNT_MIN_FRONTEND : 0, EXPENSE_AMOUNT_LIMIT_MESSAGE)
    .max(EXPENSE_AMOUNT_MAX, EXPENSE_AMOUNT_LIMIT_MESSAGE)
    .required(EXPENSE_REQUIRED_ERROR);
};

export const nicknameValidationRulesFrontend = (fieldName = 'debts') =>
  object().shape({
    [fieldName]: array().of(
      object().shape({
        nickname: string()
          .max(MAX_NICKNAME_CHARACTERS, trans('validation.nickname-max-characters-2', { MAX_NICKNAME_CHARACTERS }))
          .test(
            'nicknameDoesNotContainAccountNumber',
            trans('validation.more-than-characters', { MAX_NICKNAME_DIGITS }),
            nicknameDoesNotContainAccountNumber
          )
          .trim()
          .required(REQUIRED_ERROR_TEMPLATE),
      })
    ),
  });

export const availableFundsValidationRulesFrontend = () =>
  object().shape({
    availableFunds: number()
      .required(trans('error-message.available-funds-required'))
      .min(0, trans('error-message.available-funds-min')),
  });

export const expensesToCategory = Object.entries(NB_EXPENSES_IDS).reduce(
  (ini, [key, val]) => ({ ...ini, ...Object.values(val).reduce((i, v) => ({ ...i, [v]: key }), {}) }),
  {}
);

export const getExpenseLabelById = (id: string, customExpenses) => {
  let label: string | null = null;
  const foundDefaultExpense = Object.entries(NB_EXPENSES_IDS).some(([category, expensesIds]) => {
    return Object.entries(expensesIds).some(([expenseType, expenseId]) => {
      if (id === expenseId) {
        label = mapExpenseTypeToI18nLabel(category as ExpenseCategoryT, expenseType)();
        return true;
      }
      return false;
    });
  });

  //check if customExpenses is an array
  if (customExpenses?.length) {
    const foundCustomExpense = customExpenses.find(({ id: customExpenseId }) => customExpenseId === id);
    if (foundCustomExpense) {
      label = foundCustomExpense.name;
    }
  } else {
    const flattenCustomExpenses = Object.values(customExpenses || []).reduce((acc, category) => {
      const expenses = category.expenses.map(({ id, label }) => ({ id, label }));
      return [...acc, ...expenses];
    }, []);
    // If the expense is not a default expense, check if it's a custom expense
    if (!foundDefaultExpense && flattenCustomExpenses?.length) {
      const foundCustomExpense = flattenCustomExpenses.find(({ id: customExpenseId }) => customExpenseId === id);
      if (foundCustomExpense) {
        label = foundCustomExpense.label;
      }
    }
  }

  return label;
};

interface TransactionsResponse extends Transaction {
  __typename?: string;
}

// eslint-disable-next-line camelcase
interface TransactionListResponse extends TransactionList {
  __typename?: string;
}

/**
 * Helper function to remove unnecessary __typename fields from the transactions data
 */
export const cleanupTransactions = (transactions: TransactionsResponse[]) => {
  // Avoiding the use of the delete keyword so that the object isn't mutated and is more predictable
  return [...transactions].map(transaction => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...cleanedData } = transaction;
    const cleanList =
      cleanedData.list?.map(item => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename: _, ...cleanItem } = item as TransactionListResponse;
        return cleanItem;
      }) || null;
    cleanedData.list = cleanList;
    return cleanedData;
  });
};

/**
 * Helper function to remove unnecessary __typename fields from the customExpenses list data
 */
export const cleanupCustomExpenses = (customExpenses: CustomExpensesInput[]) => {
  return [...customExpenses].map(customExpense => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...cleanedData } = customExpense;
    return cleanedData;
  });
};

export type PieChartModelT = {
  amount: number;
  label: string;
  i18nLabel: () => string;
  color: string;
};

export const generateBalanceLeftModel = (amount: number): PieChartModelT => ({
  amount,
  label: 'Left',
  i18nLabel: () => i18n.t('available'),
  color: '#E9F2FE',
});

export const getCustomExpensesIdsFromExpensesObject = expenses => {
  const defaultExpenses = [];

  Object.entries(NB_EXPENSES_IDS).forEach(([_, expensesIds]) => {
    defaultExpenses.push(...Object.values(expensesIds));
  });

  // extract an array of keys not in defaultExpenses
  const customExpenses = Object.keys(expenses).filter(key => !defaultExpenses.includes(key));
  return customExpenses;
};

/**
 * Used to update the local transactions state after making an GQL request
 * Will auto-remove the __typename values at the end as well
 */
export const mergeTransactionsResult = (existingTransactions: Transaction[] = [], ...allTransactions) => {
  const updateTransactions = (allTransactions ?? []).flat(1)
  if (!updateTransactions || !updateTransactions.length) return existingTransactions;

  let updatedTransactions = [...existingTransactions];

  updateTransactions.forEach((updateTransaction: TransactionsResponse) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...cleanTransaction } = updateTransaction;
    const { month, year } = cleanTransaction;

    const transactionToUpdateIndex = existingTransactions.findIndex(transaction =>
      isDateEqual(
        { month: month || '', year: year || '' },
        { month: transaction.month || '', year: transaction.year || '' }
      )
    );

    if (transactionToUpdateIndex >= 0) {
      updatedTransactions.splice(transactionToUpdateIndex, 1, cleanTransaction);
    } else {
      updatedTransactions = updatedTransactions.concat(cleanTransaction);
    }
  });

  // Sort the transactions from oldest to newest by comparing their dates
  updatedTransactions = updatedTransactions.sort((a, b) => {
    if (isDateEqual({ month: a.month || '', year: a.year || '' }, { month: b.month || '', year: b.year || '' })) {
      return 0;
    }
    if (isDateGreaterThan({ month: a.month || '', year: a.year || '' }, { month: b.month || '', year: b.year || '' })) {
      return 1;
    }
    return -1;
  });

  return cleanupTransactions(updatedTransactions);
};

/*
 * This function is used to check if the custom expense name is not a duplicate of any other expense
 */
export function customExpenseNameShouldNotBeDuplicate(allExpenses, values, context) {
  const exceptions = validationExceptions();

  const allDefaultExpensesIds = allExpensesIdsFlatList()
    .filter(id => exceptions.includes(id) === false)
    .map(id => mapExpenseIdToLabelDirect(id));

  const allExpensesNames = allExpenses.map(e => e.main);
  const allCustomExpensesIds = allExpenses.map(e => e.id);

  const formExpenseValues = values
    .flatMap(e => e.expense.main)
    .filter(Boolean)
    .map(e => toTitleCase(e));

  const allCustomExpenses = values.flatMap(e => e.expense).filter(e => e.isCustom && e.main);

  // this variable contains all the custom names being added in the current form
  // the current form means the current open expense selector flyout
  const allCustomNames = allCustomExpenses.map(e => toTitleCase(e.main));

  // check if the duplicates are in allExpensesNames
  // this prevents the user from adding an expense like "Custom"
  // if they already have an expense named "Custom" in other categories
  const duplicatesInAllExpensesNames = allExpensesNames.filter(name =>
    allCustomExpenses
      .filter(e => !allCustomExpensesIds.includes(e.id))
      .map(e => toTitleCase(e.main))
      .includes(name)
  );

  // check if the duplicates are in allCustomNames
  // this prevents the user from adding an second with the same name
  const duplicatesInAllCustomNames = allCustomNames.filter(
    name => allCustomNames.indexOf(name) !== allCustomNames.lastIndexOf(name)
  );

  // check if the duplicates are in allDefaultExpensesIds
  // this prevents the user from adding an expense like "Rent"
  const duplicatesInDefaultExpensesIds = allDefaultExpensesIds.filter(id => allCustomNames.includes(id));

  // find the index of the first duplicate
  let duplicatedNameIndex = formExpenseValues.findIndex(name => name === duplicatesInAllExpensesNames[0]);

  if (duplicatedNameIndex === -1) {
    duplicatedNameIndex = formExpenseValues.findIndex(name => name === duplicatesInDefaultExpensesIds[0]);
  }

  if (duplicatedNameIndex === -1) {
    duplicatedNameIndex = formExpenseValues.findIndex(name => name === duplicatesInAllCustomNames[0]);
    // plus 1 is to create the error at the last duplicate name in the form
    // lets say the user enters two expenses named "Custom" and "Custom"
    // the error will be created at the second "Custom" expense
    if (duplicatedNameIndex !== -1) duplicatedNameIndex += 1;
  }

  // if there are no duplicates, return true
  if (duplicatedNameIndex === -1) return true;

  return context.createError({
    path: `expenses[${duplicatedNameIndex}].expense.main`,
    // TODO: Consider making this a more contextual message indicating which expense is duplicated
    message: trans('i18n-unplanned:duplicated-expenses'),
  });
}

export const expensesValidationSchema = hasMin =>
  object().shape({
    id: string().required(EXPENSE_REQUIRED_ERROR),
    value: number().when(['bucket'], {
      is: (bucket: string) => Object.values(NB_DEBT_PAYMENTS_TYPE_ID).includes(bucket),
      then: number().typeError(EXPENSE_REQUIRED_ERROR),
      otherwise: expensesValidationRulesFrontend(hasMin),
    }),
    main: string().when('id', {
      is: (id: string) =>
        // we should only check for the main field if the id is a custom expense
        Object.values({
          ...NB_ESSENTIALS_EXPENSES_TYPE_ID,
          ...NB_DEBT_PAYMENTS_TYPE_ID,
          ...NB_SAVINGS_TYPE_ID,
        }).includes(id),
      then: string(),
      otherwise: string()
        .test(
          'nicknameDoesNotContainAccountNumber',
          `Do not enter more than ${MAX_NICKNAME_DIGITS} numeric characters`,
          nicknameDoesNotContainAccountNumber
        )
        .max(MAX_CHARACTERS, MAX_CHARACTERS_ERROR)
        .required(FIELD_REQUIRED_ERROR),
    }),
  });

export const expensesArraySchema = object().shape({
  expenses: array().of(array().of(expensesValidationSchema())),
});
