import React from 'react';

import { noop } from '@/util/function';

import { StateDispatch, TProvider } from '@/types/common';

export const SESSION_QUICK_GUIDE_CANCELLED = 'SESSION_QUICK_GUIDE_CANCELLED';

type ContextT = {
  showGuideTool: boolean;
  setShowGuideTool: StateDispatch<boolean>;
  sideMenuWidgetRef: HTMLElement | null;
  setSideMenuWidgetRef: StateDispatch<HTMLElement | null>;
  savingsWidgetRef: HTMLElement | null;
  setSavingsWidgetRef: StateDispatch<HTMLElement | null>;

  budgetWidgetRef: HTMLElement | null;
  setBudgetWidgetRef: StateDispatch<HTMLElement | null>;
  headerWidgetRef: HTMLElement | null;
  setHeaderWidgetRef: StateDispatch<HTMLElement | null>;
};

const initialContext: ContextT = {
  showGuideTool: false,
  setShowGuideTool: noop,
  sideMenuWidgetRef: null,
  setSideMenuWidgetRef: noop,
  savingsWidgetRef: null,
  setSavingsWidgetRef: noop,
  budgetWidgetRef: null,
  setBudgetWidgetRef: noop,
  headerWidgetRef: null,
  setHeaderWidgetRef: noop,
};

export const QuickGuideToolContext = React.createContext(initialContext);

const QuickGuideProvider: TProvider = ({ children }) => {
  const [showGuideTool, setShowGuideTool] = React.useState(false);
  const [sideMenuWidgetRef, setSideMenuWidgetRef] = React.useState<HTMLElement | null>(null);
  const [savingsWidgetRef, setSavingsWidgetRef] = React.useState<HTMLElement | null>(null);
  const [budgetWidgetRef, setBudgetWidgetRef] = React.useState<HTMLElement | null>(null);
  const [headerWidgetRef, setHeaderWidgetRef] = React.useState<HTMLElement | null>(null);

  return (
    <QuickGuideToolContext.Provider
      // TODO: Fix this eslint error
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        showGuideTool,
        setShowGuideTool,
        sideMenuWidgetRef,
        setSideMenuWidgetRef,
        savingsWidgetRef,
        setSavingsWidgetRef,
        budgetWidgetRef,
        setBudgetWidgetRef,
        headerWidgetRef,
        setHeaderWidgetRef,
      }}
    >
      {children}
    </QuickGuideToolContext.Provider>
  );
};

export default QuickGuideProvider;
