import { TRecord, TObject } from '@/types/common';

const isUndefined = (item: unknown) => item === undefined;

export const isAnyUndefined = (array: unknown[]) => array.some(isUndefined);

export const hasDuplicateObjectProperties = (array: TObject[], property: string) => {
  const uniqueEntries = new Set(array.map(entry => entry[property]));

  if (uniqueEntries.size < array.length) {
    return true;
  }

  return false;
};

export const sortBy = <T>(array: T[], comparisonFunctions: ((x: T, y: T) => number)[] = []) => {
  return array.sort((elementA, elementB) => {
    let comparedValue;
    comparisonFunctions.some(func => {
      comparedValue = func(elementA, elementB);
      return comparedValue;
    });
    return comparedValue;
  });
};

/**
 * Sorts each array within the array by the comparison function
 * and the concatenates them in the same order they were initially entered.
 */
export const sortEachByAndConcatenate = <T = TRecord>(arrays: T[][], compareFunction: (a: T, b: T) => number) => {
  return arrays.reduce((concatenated, array) => {
    const sortedArray = array.sort(compareFunction);
    return concatenated.concat(sortedArray);
  }, []);
};

/**
 * Returns the count of all items in the array that are passing the selector function.
 */
export const count = <T>(array: T[], selector: (item: T, arr: T[]) => boolean): number =>
  array.reduce((sum, item) => {
    if (selector(item, array)) {
      return sum + 1;
    }

    return sum;
  }, 0);

/**
 * Returns the count of all items in the array that are passing the selector function.
 */
export const removeDuplicateObjectFromArray = <T extends TRecord>(array: T[], key: string): T[] => {
  const check = new Set();
  return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
};

/**
 * Get a specific prop from array items
 */
export const getPropFromArrayItem = <P, T extends TRecord<P> = TRecord<P>>(arr: T[], prop: string) =>
  arr.map(e => e[prop]);

/**
 * Get the number of streak occurrences from an array of numbers
 */
export const getStreakOccurrences = (list: number[] = []) => {
  let streakOccurrences = 0;
  const firstItem = list[0];

  for (let i = 0; i < list.length; i++) {
    const currentItem = list[i];

    const isFirstItem = currentItem === firstItem;

    if (!isFirstItem && firstItem - currentItem === i) {
      streakOccurrences++;
    }

    if (firstItem - currentItem !== i) break;
  }

  return streakOccurrences;
};

/**
 * Finds out the maximum streak in a given list of numbers (ascending by default)
 * * Note: A streak of 1 means that two sequential elements were found
 */
export const getHighestStreakCount = ({
  list = [],
  resetStreakOnFail = false,
  descending = false,
}: {
  list?: number[];
  resetStreakOnFail?: boolean;
  descending?: boolean;
}) => {
  let highestStreak = 0;
  let currentStreak = 0;
  for (let i = 0; list.length > 0 && i < list.length - 1; i++) {
    // If the item after this follows the numeric pattern we increment the streak
    const expectedSequentialValue = list[i] + (descending ? -1 : 1);
    const nextItemValue = list[i + 1];
    if (expectedSequentialValue === nextItemValue) {
      currentStreak++;
    } else {
      currentStreak = 0;
      if (resetStreakOnFail) highestStreak = 0;
    }

    if (currentStreak > highestStreak) {
      highestStreak = currentStreak;
    }
  }

  return highestStreak;
};

/**
 * Sum up a list of array entries
 */
export const sumArrayItems = (list: number[] = []) => {
  return Array.isArray(list) ? list.reduce((sum, value) => sum + value, 0) : 0;
};
