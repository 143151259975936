import { Container } from 'semantic-ui-react';
import cx from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import classes from './Paragraph.module.less';

import type { SemanticTEXTALIGNMENTS } from 'semantic-ui-react';

// Add colors as needed

/**
 * Wraps text content. Controls font size, color and spacing.
 */

export type ParagraphT = {
  children?: ReactNode;
  padded?: boolean | string;
  size?: string;
  noMargin?: boolean;
  noHorizontalMargin?: boolean;
  className?: string;
  textAlign?: SemanticTEXTALIGNMENTS;
  fluid?: boolean;
  bold?: boolean;
  color?: string;
  as?: string;
  extraSpacing?: boolean;
  uppercase?: boolean;
  serif?: boolean;
  text?: boolean;
  dataTestId?: string;
};

const Paragraph: FunctionComponent<ParagraphT> = ({
  children,
  padded = false,
  size = 'medium',
  noMargin = false,
  noHorizontalMargin = false,
  className = '',
  textAlign = 'left',
  fluid = true,
  bold = false,
  color = 'charcoal',
  as = 'p',
  extraSpacing = false,
  uppercase = false,
  serif = false,
  text = false,
  dataTestId,
}) => {
  const styles = cx('paragraph', className, size, color, {
    padded,
    very: padded === 'very',
    noMargin: noHorizontalMargin === true || noMargin === true,
    [classes.noMargin]: noMargin,
    [classes.extraSpacing]: extraSpacing,
    [classes.uppercase]: uppercase,
    [classes.bold]: bold,
    [classes.serif]: serif,
    [classes.noSerif]: !serif,
  });

  return (
    <Container text={text} as={as} fluid={fluid} textAlign={textAlign} className={styles} data-testid={dataTestId}>
      {children}
    </Container>
  );
};

export default Paragraph;
