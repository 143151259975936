import { TObject } from '@/types/common';
import {
  TDefaultUserState,
  TDefaultExpense,
  TDefaultActivity,
  TDefaultGoal,
  TStepMetadata,
} from '@/types/domain/UserStates';

export const defaultGoalsState = [];
export const defaultActivitiesState = [];
export const defaultSavings = null;

export const defaultUserState: TDefaultUserState = {
  flowMetadata: {},
  myPlanCompletedSteps: [],
  answers: {},
  dateUpdated: undefined,
  vertical: '',
  expenseId: null,
  expenses: [],
};

export const defaultExpense: TDefaultExpense = {
  flowMetadata: {},
  myPlanCompletedSteps: [],
  answers: {},
  dateUpdated: undefined,
  vertical: '',
  expenseId: null,
  status: '',
  dateCreated: undefined,
  lastEdited: undefined,
};

export const defaultGoal: TDefaultGoal = {
  flowMetadata: {},
  answers: {},
  status: '',
  type: '',
  goalId: null,
  nickname: '',
  dateUpdated: undefined,
  dateCreated: undefined,
};

export const defaultActivity: TDefaultActivity = {
  flowMetadata: {},
  answers: {},
  status: '',
  type: '',
  activityId: null,
  dateUpdated: undefined,
  dateCreated: undefined,
};

const userStateKeys = Object.keys(defaultUserState);
const expenseKeys = Object.keys(defaultExpense);
const goalKeys = Object.keys(defaultGoal);
const activityKeys = Object.keys(defaultActivity);

/*
 * Backend returns all the state in every request,
 * if some value of the state is not present, then a `null` is
 * returned.
 * This function just iterate over the values returned and set some
 * default/safe values to those that are `null`
 * */
const setSafeValues = (data: TObject, keys: string[], defaultState: TObject) => {
  return keys.reduce(
    (acc, key) => {
      if (data[key] != null) {
        acc[key] = data[key];
      }

      return acc;
    },
    {
      ...defaultState,
    }
  );
};

export const setSafeExpenseValues = (data: TObject) => setSafeValues(data, expenseKeys, defaultExpense);
export const setSafeUserStateValues = (data: TObject) => setSafeValues(data, userStateKeys, defaultUserState);
export const setSafeGoalValues = (data: TObject) => setSafeValues(data, goalKeys, defaultGoal);
export const setSafeActivityValues = (data: TObject) => setSafeValues(data, activityKeys, defaultActivity);

// Domain logic

/**
 * Uses metadata to find out what the next step is. Clears the data for the current step before recursively
 * doing the same thing for all next steps.
 * @param {*} metadata metadata for the current flow
 * @param {*} currentStepId step to start clearing from
 * @returns {FlowMetadata} metadata with cleared future steps (including the one sent as the current one)
 */
export const clearMetadata = (metadata: TObject, currentStepId: string) => {
  // back to college - linked list sorting
  const currentStepMetadata = metadata[currentStepId] as TStepMetadata;

  if (!currentStepMetadata) {
    return metadata;
  }

  const { nextStepId } = currentStepMetadata;

  // remove metadata for the current step
  const updatedMetadata = { ...metadata };
  delete updatedMetadata[currentStepId];

  if (nextStepId === undefined || nextStepId === null) {
    return updatedMetadata;
  }

  return clearMetadata(updatedMetadata, nextStepId);
};

/**
 * Compares the existing values with the payload of the current form.
 * Only compares the values from the given list of form field names.
 * @param {*} state current state
 * @param {*} changes payload from the current form
 * @param {*} formFieldNames list of form field names to compare values from
 * @returns {boolean} indicating if the values have been changed
 */
export const haveFormValuesChanged = (state: TObject, changes: TObject, formFieldNames: string[]) => {
  if (!formFieldNames) {
    return false;
  }

  return formFieldNames.some(fieldName => state[fieldName] !== changes[fieldName]);
};
