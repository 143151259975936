import { AnySchema } from 'yup';
import { ExtraParams } from 'yup/lib/types';

export const MAX_NICKNAME_DIGITS = 2;
export const MAX_NICKNAME_CHARACTERS = 64;

type Test = { name?: string; params?: ExtraParams };

/**
 * Used to extract useful configuration details from a yup test for number types
 */
const getNumberTypePropsFromTest = ({ name, params }: Test) => {
  switch (name) {
    case 'min':
    case 'max':
      return { [name]: params?.[name] };
    default:
      return {};
  }
};

const getAriaRequiredPropFromTest = ({ name }: Test) => {
  if (name === 'required') {
    return { 'aria-required': true };
  }
  return {};
};

/**
 * Transforms yup validation rules into type-specific props which can be passed to an input component
 */
export const getPropsFromValidationRules = (validationRules: AnySchema) => {
  if (validationRules == null) {
    return {};
  }
  const { type, tests } = validationRules.describe();

  switch (type) {
    case 'number':
      return tests.reduce((props, test) => {
        return {
          inputType: 'number',
          ...props,
          ...getNumberTypePropsFromTest(test),
          ...getAriaRequiredPropFromTest(test),
        };
      }, {});
    default:
      return {};
  }
};

export function nicknameDoesNotContainAccountNumber(value?: string) {
  if (!value) {
    return true;
  }
  const digitCount = (value.match(/\d/g) || []).length;
  return digitCount <= MAX_NICKNAME_DIGITS;
}
