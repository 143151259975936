/* eslint-disable */
// @ts-nocheck
import { Savings } from '@/types/generated/globalTypes';
import { ACTIVITY_STATUSES } from 'common/model/activity';
import { SAVINGS_TYPES } from 'common/model/savings';

import {
  BASE_PATH_SAVINGS,
  BASE_PATH_LARGE_PURCHASE_SAVINGS,
  SAVINGS_OVERVIEW_PATH,
  SAVINGS_ACTIVITIES_PATH,
  SAVINGS_DEPOSIT_HISTORY_PATH,
  SAVINGS_RAINY_DAY_FUND_ONBOARDING_PATH,
  SAVINGS_LARGE_PURCHASE_ONBOARDING_PATH,
  SAVINGS_LARGE_PURCHASE_CONFIRM_BUDGET_PATH,
  SAVINGS_RAINY_DAY_FUND_CONFIRM_BUDGET_PATH,
  SAVINGS_RAINY_DAY_FUND_OVERVIEW_ACTIVITIES_PATH,
  SAVINGS_RAINY_DAY_FUND_OVERVIEW_DEPOSIT_HISTORY_PATH,
  SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH,
  SAVINGS_RAINY_DAY_FUND_PENDING_PATH,
  SAVINGS_PENDING_PATH,
} from '../paths';
import { InitialContextT } from '../stateMachines';

export const generalSavingsActivities = [
  {
    type: 'nameYourGoalSavings',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'setupSeparateAccountSavings',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'automateSavings',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'makeFirstDepositSavings',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'makeMonthlyDepositSavings',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'createYourBudgetSavings',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'reviewYourBudgetSavings',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'reviewYourExpensesSavings',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
] as const;

export type generalSavingsActivitiesType = (typeof generalSavingsActivities)[number]['type'];

export const LATEST_CREATED_SAVINGS = 'LATEST_CREATED_SAVINGS';
export const savingsActivities = {
  [SAVINGS_TYPES.RAINY_DAY]: generalSavingsActivities,
  [SAVINGS_TYPES.LARGE_PURCHASE]: generalSavingsActivities,
};

export const hasLoadedSavings = ({ savings }: InitialContextT) => savings.isLoaded === true;
export const hasAnySavingsPlan = ({ savings }: InitialContextT) => savings.list.length > 0;
export const hasSavingsWithTypeCreated = ({ savings }: InitialContextT, savingsType: string) =>
  savings.list.some(savingsItem => savingsItem.type === savingsType && savingsItem.savingsId !== null);

export const getNewSavingsAfterLoading = loadedSavings => {
  const transformedSavings = loadedSavings.map(savingsItem => {
    let milestones = savingsItem?.milestones;
    if (milestones) {
      milestones = milestones?.map(milestone => {
        const { __typename, ...cleanedMilestone } = milestone;
        return cleanedMilestone;
      });
    }
    return {
      ...savingsItem,
      ...(milestones && { milestones }),
    };
  });

  return transformedSavings;
};

export const getNewSavingsAfterUpserting = (currentSavings, updatedSavings) => {
  const modifiedSavings = [...currentSavings];
  const foundItemIndex = modifiedSavings.findIndex(savingsPlan => savingsPlan.savingsId === updatedSavings.savingsId);

  let milestones = updatedSavings?.milestones;
  if (milestones) {
    milestones = milestones?.map(milestone => {
      const { __typename, ...cleanedMilestone } = milestone;
      return cleanedMilestone;
    });
  }
  const cleanedUpdatedSavings = {
    ...updatedSavings,
    ...(milestones && { milestones }),
  };

  if (foundItemIndex >= 0) {
    modifiedSavings[foundItemIndex] = cleanedUpdatedSavings;
  } else {
    modifiedSavings.push(cleanedUpdatedSavings);
  }

  return modifiedSavings;
};

/**
 * Filters out the deleted item from the savings plan list, effectively deleting it
 */
export const getNewSavingsAfterDeleting = (currentSavings: Savings[], deleteSavings: Savings) =>
  currentSavings.filter(savingsPlan => savingsPlan.savingsId !== deleteSavings.savingsId);

export const getDynamicSavingsPaths = (type: string, savingsId?: string = '') => {
  const savingsPaths = {
    onboarding: BASE_PATH_SAVINGS,
    overview: BASE_PATH_SAVINGS,
    activities: BASE_PATH_SAVINGS,
    depositHistory: BASE_PATH_SAVINGS,
    confirmBudget: BASE_PATH_SAVINGS,
    pending: BASE_PATH_SAVINGS,
  };

  switch (type) {
    case SAVINGS_TYPES.LARGE_PURCHASE: {
      const savingsPathWithID = `/${savingsId}`;
      const overviewPath = `${BASE_PATH_LARGE_PURCHASE_SAVINGS}${savingsPathWithID}${SAVINGS_OVERVIEW_PATH}`;
      const pendingPath = `${BASE_PATH_LARGE_PURCHASE_SAVINGS}${savingsPathWithID}${SAVINGS_PENDING_PATH}`;

      savingsPaths.onboarding = SAVINGS_LARGE_PURCHASE_ONBOARDING_PATH;
      savingsPaths.overview = overviewPath;
      savingsPaths.activities = `${overviewPath}${SAVINGS_ACTIVITIES_PATH}`;
      savingsPaths.depositHistory = `${overviewPath}${SAVINGS_DEPOSIT_HISTORY_PATH}`;
      savingsPaths.confirmBudget = SAVINGS_LARGE_PURCHASE_CONFIRM_BUDGET_PATH;
      savingsPaths.pending = pendingPath;
      break;
    }
    case SAVINGS_TYPES.RAINY_DAY:
    default: {
      savingsPaths.onboarding = SAVINGS_RAINY_DAY_FUND_ONBOARDING_PATH;
      savingsPaths.overview = SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH;
      savingsPaths.activities = SAVINGS_RAINY_DAY_FUND_OVERVIEW_ACTIVITIES_PATH;
      savingsPaths.depositHistory = SAVINGS_RAINY_DAY_FUND_OVERVIEW_DEPOSIT_HISTORY_PATH;
      savingsPaths.confirmBudget = SAVINGS_RAINY_DAY_FUND_CONFIRM_BUDGET_PATH;
      savingsPaths.pending = SAVINGS_RAINY_DAY_FUND_PENDING_PATH;
      break;
    }
  }

  return savingsPaths;
};

/**
 * Function to calculate the budget expense amount when the amount to contribute is changed on the savings planner
 * @param modifiedAmount - the recently modified amount
 * @param originalAmount - the amount saved in the database
 */
export function calculateBudgetContribution(modifiedAmount: number, originalAmount: number) {
  const safeOriginalAmount = originalAmount || 0;
  return modifiedAmount - safeOriginalAmount;
}
