/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { ExpensesContext } from '@/services/expenses';

const useSelectExpense = () => {
  const { setCurrentExpense } = React.useContext(ExpensesContext);

  return setCurrentExpense;
};

export default useSelectExpense;
