/* eslint-disable */
// @ts-nocheck
import React, { ReactNode, FunctionComponent } from 'react';
import cx from 'classnames';

import MicroCopy from '@/components/microcopy';

import classes from './WithMicroCopy.module.less';

/**
 * Adds microcopy below the wrapped component and corresponding styles
 * to avoid duplicating the same styles in different components.
 */
export type TWithMicrocopyProps = {
  className?: string;
  microcopy?: ReactNode;
  microcopyAlign?: string;
  size?: string;
  fluid?: boolean;
  fluidOnMobile?: boolean;
  children?: ReactNode;
  dataTestId?: string;
};

const WithMicroCopy: FunctionComponent<TWithMicrocopyProps> = ({
  className,
  microcopy,
  microcopyAlign = '',
  size = 'mini',
  fluid = false,
  fluidOnMobile = false,
  children,
  dataTestId,
}) => {
  // If no microcopy is provided, just render children
  if (microcopy == null) {
    return children;
  }
  return (
    <div
      className={cx(classes.microcopyContainer, className, {
        [classes.fluid]: fluid,
        [classes.fluidOnMobile]: fluidOnMobile,
      })}
    >
      {children}
      <MicroCopy size={size} textAlign={microcopyAlign} className={classes.microcopy} data-testid={dataTestId}>
        {microcopy}
      </MicroCopy>
    </div>
  );
};
export default WithMicroCopy;
