import { gql } from '@apollo/client';

import {
  $Debt$,
  $Budget$,
  $PaymentPlan$,
  $Transactions$,
  $GoalWithActivities$,
  $Savings$,
  $Activity$,
  $Expense$,
  $AdvancePaymentPlan$,
  $BudgetPaymentPlan$,
  $BudgetSnapshot$,
} from './fragments';

export const LOGIN = gql`
  mutation Login($userId: UUID!) {
    login(userId: $userId) {
      success
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation SendEmail($Body: String!, $Captcha: String!, $From: Email, $ReplyTo: Email, $Subject: String!) {
    sendEmail(Body: $Body, Captcha: $Captcha, From: $From, ReplyTo: $ReplyTo, Subject: $Subject) {
      Body
      From
      ReplyTo
      Subject
      To
    }
  }
`;

export const SEND_ASSESSMENT = gql`
  mutation SendAssessment($url: String!, $flow: String!, $to: String!) {
    sendAssessment(url: $url, flow: $flow, to: $to) {
      success
    }
  }
`;

export const CREATE_DEBT = gql`
  ${$Debt$}
  mutation CreateDebt($input: DebtInput!) {
    createDebt(input: $input) {
      ...Debt
    }
  }
`;

export const CREATE_DEBTS = gql`
  ${$Debt$}
  mutation CreateDebts($input: [DebtInput!]!) {
    createDebts(input: $input) {
      ...Debt
    }
  }
`;

export const UPDATE_DEBT = gql`
  ${$Debt$}
  mutation UpdateDebt($input: DebtInput!) {
    updateDebt(input: $input) {
      ...Debt
    }
  }
`;

export const DELETE_DEBT = gql`
  ${$Debt$}
  mutation DeleteDebt($input: RemoveDebtInput!) {
    deleteDebt(input: $input) {
      ...Debt
    }
  }
`;

export const CREATE_PAYMENT_PLAN = gql`
  ${$PaymentPlan$}
  mutation CreatePaymentPlan($input: PaymentPlanMutationInput!) {
    createPaymentPlan(input: $input) {
      ...PaymentPlan
    }
  }
`;

export const UPDATE_PAYMENT_PLAN = gql`
  ${$PaymentPlan$}
  mutation UpdatePaymentPlan($input: PaymentPlanMutationInput!) {
    updatePaymentPlan(input: $input) {
      ...PaymentPlan
    }
  }
`;

export const ADVANCE_MONTH = gql`
  ${$AdvancePaymentPlan$}
  mutation AdvanceMonth($userId: UUID!, $planToAdvanceFrom: PlanToAdvanceFromInput!, $dateToAdvance: Float) {
    advanceToCurrentMonth(userId: $userId, planToAdvanceFrom: $planToAdvanceFrom, dateToAdvance: $dateToAdvance) {
      ...AdvancePaymentPlan
    }
  }
`;

export const UPSERT_BUDGET = gql`
  ${$Budget$}
  mutation UpsertBudget($input: BudgetInput!) {
    upsertBudget(input: $input) {
      ...Budget
    }
  }
`;

// call two mutations one after the other to advance the month and update the budget
export const UPSERT_BUDGET_AND_UPDATE_PAYMENT_PLAN_AVAILABLE_FUNDS_NEW_MONTH = gql`
  ${$AdvancePaymentPlan$}
  ${$BudgetPaymentPlan$}
  mutation UpsertBudgetAndUpdatePaymentPlanAvailableFundsNewMonth(
    $userId: UUID!
    $planToAdvanceFrom: PlanToAdvanceFromInput!
    $dateToAdvance: Float
    $input: BudgetPaymentPlanInput!
  ) {
    advanceToCurrentMonth(userId: $userId, planToAdvanceFrom: $planToAdvanceFrom, dateToAdvance: $dateToAdvance) {
      ...AdvancePaymentPlan
    }
    upsertBudgetAndUpdatePaymentPlanAvailableFunds(input: $input) {
      ...BudgetPaymentPlan
    }
  }
`;

export const UPSERT_BUDGET_AND_UPDATE_PAYMENT_PLAN_AVAILABLE_FUNDS = gql`
  ${$BudgetPaymentPlan$}
  mutation UpsertBudgetAndUpdatePaymentPlanAvailableFunds($input: BudgetPaymentPlanInput!) {
    upsertBudgetAndUpdatePaymentPlanAvailableFunds(input: $input) {
      ...BudgetPaymentPlan
    }
  }
`;

export const DELETE_BUDGET = gql`
  ${$Budget$}
  mutation DeleteBudget($userId: UUID!) {
    deleteBudget(userId: $userId) {
      ...Budget
    }
  }
`;

export const CREATE_EXPENSE = gql`
  ${$Expense$}
  mutation CreateExpense($input: ExpenseInput!) {
    createExpense(input: $input) {
      ...Expense
    }
  }
`;

export const UPDATE_EXPENSE = gql`
  ${$Expense$}
  mutation UpdateExpense($input: ExpenseInput!) {
    updateExpense(input: $input) {
      ...Expense
    }
  }
`;

export const DELETE_EXPENSE = gql`
  ${$Expense$}
  mutation DeleteExpense($input: ExpenseInput!) {
    deleteExpense(input: $input) {
      ...Expense
    }
  }
`;

export const REPLACE_EXPENSE = gql`
  ${$Expense$}
  mutation ReplaceExpense($input: ExpenseInput!) {
    replaceExpense(input: $input) {
      ...Expense
    }
  }
`;

export const CREATE_GOAL = gql`
  ${$GoalWithActivities$}
  mutation CreateGoal($input: GoalInput!) {
    createGoal(input: $input) {
      ...GoalWithActivities
    }
  }
`;

export const CREATE_GOALS = gql`
  ${$GoalWithActivities$}
  mutation CreateGoals($input: [GoalInput!]!) {
    createGoals(input: $input) {
      ...GoalWithActivities
    }
  }
`;

export const UPDATE_GOAL = gql`
  ${$GoalWithActivities$}
  mutation UpdateGoal($input: GoalInput!) {
    updateGoal(input: $input) {
      ...GoalWithActivities
    }
  }
`;

export const UPDATE_GOALS = gql`
  ${$GoalWithActivities$}
  mutation UpdateGoals($input: [GoalInput!]!) {
    updateGoals(input: $input) {
      ...GoalWithActivities
    }
  }
`;

export const DELETE_GOAL = gql`
  ${$GoalWithActivities$}
  mutation DeleteGoal($input: GoalInput!) {
    deleteGoal(input: $input) {
      ...GoalWithActivities
    }
  }
`;

export const DELETE_GOALS = gql`
  ${$GoalWithActivities$}
  mutation DeleteGoals($input: [GoalInput!]!) {
    deleteGoals(input: $input) {
      ...GoalWithActivities
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  ${$Activity$}
  mutation UpdateActivity($input: ActivityInput!) {
    updateActivity(input: $input) {
      ...Activity
    }
  }
`;

export const UPDATE_ACTIVITIES = gql`
  ${$Activity$}
  mutation UpdateActivities($input: [ActivityInput!]!) {
    updateActivities(input: $input) {
      ...Activity
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  ${$Activity$}
  mutation DeleteActivity($input: ActivityInput!) {
    deleteActivity(input: $input) {
      ...Activity
    }
  }
`;

export const CREATE_TRANSACTIONS = gql`
  ${$Transactions$}
  mutation CreateTransactions($input: TransactionsInput!) {
    createTransactions(input: $input) {
      ...Transactions
    }
  }
`;

export const UPDATE_TRANSACTIONS = gql`
  ${$Transactions$}
  mutation UpdateTransactions($input: TransactionsInput!) {
    updateTransactions(input: $input) {
      ...Transactions
    }
  }
`;

export const UPSERT_TRANSACTIONS = gql`
  ${$Transactions$}
  mutation UpsertTransactions($input: TransactionsInput!) {
    upsertTransactions(input: $input) {
      ...Transactions
    }
  }
`;

export const UPSERT_SAVINGS = gql`
  ${$Savings$}
  mutation UpsertSavings($input: SavingsInput!) {
    upsertSavings(input: $input) {
      ...Savings
    }
  }
`;

export const DELETE_SAVINGS = gql`
  ${$Savings$}
  mutation DeleteSavings($input: SavingsInput!) {
    deleteSavings(input: $input) {
      ...Savings
    }
  }
`;

export const UPDATE_PAYMENT_PLAN_AND_UPSERT_TRANSACTIONS_AND_DEBT_BUDGET = gql`
  ${$PaymentPlan$}
  ${$Transactions$}
  ${$Debt$}
  mutation UpdatePaymentPlanAndUpsertTransactions(
    $paymentPlanInput: PaymentPlanMutationInput!
    $transactionsInput: TransactionsInput!
    $debtInput: DebtInput!
  ) {
    updatePaymentPlan(input: $paymentPlanInput) {
      ...PaymentPlan
    }

    upsertTransactions(input: $transactionsInput) {
      ...Transactions
    }

    updateDebt(input: $debtInput) {
      ...Debt
    }
  }
`;

export const CREATE_BUDGET_SNAPSHOT = gql`
  ${$BudgetSnapshot$}
  mutation CREATE_BUDGET_SNAPSHOT($input: BudgetSnapshotMutationInput!) {
    createBudgetSnapshot(input: $input) {
      ...BudgetSnapshot
    }
  }
`;
