import React from 'react';

import { mergePaymentPlanWithDebts } from '@/domain/debtsPlan';
import type { AppMachineSendT, AppMachineStateT, InitialContextT } from '@/domain/stateMachines';
import { notifyChangedTags } from '@/domain/stateMachines/actions';

export type ContextT =
  | Record<string, never>
  | {
      state: AppMachineStateT;
      sendEvent: AppMachineSendT;
      debtsPlan: ReturnType<typeof mergePaymentPlanWithDebts>;
      updateAmountPaid: (...args) => void;
      updatePaymentPlanFlag: (...args) => void;
      previousAmountsPaid: InitialContextT['previousAmountsPaid'];
      updateAmountPaidAndUpsertTransaction: (...args) => void;
      updatePaymentPlanActivities: (...args) => void;
    };

type ProviderPropsT = {
  children: React.ReactNode;
  appState: AppMachineStateT;
  sendEvent: AppMachineSendT;
};

const initialContextValues = {};

export const DebtsPlanContext = React.createContext<ContextT>(initialContextValues);

const DebtsPlanProvider = ({ children, appState, sendEvent }: ProviderPropsT) => {
  const { allocatedPayments, paymentPlan, debts, previousAmountsPaid } = appState.context;

  /*
    This would normally need a ref to prevent it from being repeatedly called,
    but it should always trigger an event which changes the machine state
    so we'll try this for now
  */
  React.useEffect(() => {
    if (appState.event?.type.endsWith('done.invoke.updateAvailableFunds')) {
      notifyChangedTags(appState.context);
    }
  }, [appState.context, appState.event]);

  // TODO improve merge strategy
  const debtsPlan = mergePaymentPlanWithDebts({ paymentPlan, debts, allocatedPayments });

  const updateAmountPaid = React.useCallback(
    (targetDebtId, targetDebtAmount = 0, closeModal) =>
      sendEvent({ type: 'UPDATE_AMOUNT_PAID_FROM_PAYMENT_PLAN', targetDebtId, targetDebtAmount, closeModal }),
    [sendEvent]
  );

  const updateAmountPaidAndUpsertTransaction = React.useCallback(
    (targetDebtId, targetDebtAmount = 0, closeModal, nickname = null) =>
      sendEvent({
        type: 'UPDATE_AMOUNT_PAID_AND_UPSERT_TRANSACTION_FROM_PAYMENT_PLAN',
        targetDebtId,
        targetDebtAmount,
        closeModal,
        nickname,
      }),
    [sendEvent]
  );

  const updatePaymentPlanFlag = React.useCallback(
    (flagId, status) => {
      sendEvent({ type: 'UPDATE_PAYMENT_PLAN_FLAG', flagId, status });
    },
    [sendEvent]
  );

  const updatePaymentPlanActivities = React.useCallback(
    activities => {
      sendEvent({ type: 'UPDATE_PAYMENT_PLAN_ACTIVITIES', activities });
    },
    [sendEvent]
  );

  const contextValue = React.useMemo(
    () => ({
      state: appState,
      sendEvent,
      debtsPlan,
      updateAmountPaid,
      updatePaymentPlanFlag,
      previousAmountsPaid,
      updateAmountPaidAndUpsertTransaction,
      updatePaymentPlanActivities,
    }),
    [
      appState,
      sendEvent,
      debtsPlan,
      updateAmountPaid,
      updatePaymentPlanFlag,
      previousAmountsPaid,
      updateAmountPaidAndUpsertTransaction,
      updatePaymentPlanActivities,
    ]
  );

  return <DebtsPlanContext.Provider value={contextValue}>{children}</DebtsPlanContext.Provider>;
};

export default DebtsPlanProvider;
