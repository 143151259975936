/* eslint-disable */
// @ts-nocheck
import { useTranslation, Trans } from 'react-i18next';
import cx from 'classnames';

import { FUNDING_FLOW_OPTIONS } from '@/domain/paths';

import Currency from '@/components/Currency';
import { WarningBox } from '@/components/InformationBox';
import Link from '@/components/link';
import Paragraph from '@/components/Paragraph';
import Progress from '@/components/progress/Progress';

import classes from './styles.module.less';

const PartialCoverage = ({ amountNeeded, dataTestId, maxAmountUserCanBorrow }) => {
  const { t } = useTranslation('i18n-unplanned');

  return (
    <>
      <WarningBox
        icon="warning sign"
        className={classes.partialHeader}
        content={t('partial-coverage.content')}
        data-testid={dataTestId}
      />
      <Paragraph noHorizontalMargin as="div" className={cx(classes.partialAmountProgress, classes.bottomBorder)}>
        <Currency value={maxAmountUserCanBorrow} /> of <Currency value={amountNeeded} />
        <Progress
          className={classes.partialProgressBar}
          percent={(maxAmountUserCanBorrow / Number(amountNeeded)) * 100}
          size="tiny"
        />
      </Paragraph>
      <Paragraph noHorizontalMargin className={classes.partialByLaw}>
        <Trans ns="i18n-unplanned" i18nKey="partial-coverage.paragraph-1">
          By law, you can borrow a maximum of <Currency value={maxAmountUserCanBorrow} /> of the{' '}
          <Currency value={amountNeeded} /> you need.
        </Trans>
      </Paragraph>
      <Paragraph noHorizontalMargin className={classes.partialDontWorry} size="tiny">
        <Trans ns="i18n-unplanned" i18nKey="partial-coverage.paragraph-2">
          Don't worry, you can combine borrowing from your retirement with other options. Continue below or go back to{' '}
          <Link to={FUNDING_FLOW_OPTIONS}>other funding options</Link>.
        </Trans>
      </Paragraph>
    </>
  );
};

export default PartialCoverage;
