import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import { toUSD } from '@/util/currency';

import classes from './styles.module.less';

export type TCurrencyProps = {
  className?: string;
  precision?: string;
  serifFont?: boolean;
  value: number;
  'data-testid'?: string;
};

const Currency: FunctionComponent<TCurrencyProps> = ({
  className = '',
  precision = 'auto',
  serifFont = false,
  value,
  'data-testid': testId,
}) => (
  <span
    className={cx(className, {
      [classes.serifFont]: serifFont,
    })}
    data-testid={testId}
  >
    {toUSD(value, precision)}
  </span>
);

export default Currency;
