/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

import Heading from '@/components/Heading';
import Button from '@/components/button';
import Link from '@/components/link';
import Paragraph from '@/components/Paragraph';
import VerticalSpacing from '@/components/VerticalSpacing';
import WithMicroCopy from '@/components/WithMicroCopy';
import AuthActionHandlers from '@/components/AuthActionHandlers';

import { AltModal } from '..';

import classes from './styles.module.less';

interface AuthModalProps {
  children?: any;
  onOpen?: any;
  openControlId?: string;
  redirectLocation: string;
  overrideState?: {} | undefined;
  hideLoginLink?: boolean;
  actionBeforeAuth?(): void;
}

const AuthModal = ({
  children,
  onOpen,
  openControlId = '',
  redirectLocation,
  overrideState = {},
  hideLoginLink,
}: AuthModalProps) => {
  const { t } = useTranslation();

  const actionHandlers = React.useCallback(
    ({ onMainButtonClicked, handleLogInClick, handleSignUpClick, loading }) => {
      /* If the login link needs to be hidden */
      if (hideLoginLink) {
        return (
          <Button
            primary
            onClick={() => {
              onMainButtonClicked();
              handleSignUpClick({ redirectLocation });
            }}
            disabled={loading}
            loading={loading}
            data-testid="continueWithAARP"
          >
            {t('i18n-actions:button.sign-up')}
          </Button>
        );
      }

      return (
        <WithMicroCopy
          microcopy={
            <>
              {t('have-account')}{' '}
              <Link
                onClick={() => {
                  onMainButtonClicked();
                  handleLogInClick({ redirectLocation });
                }}
              >
                {t('i18n-actions:button.log-in')}
              </Link>
            </>
          }
          size="small"
        >
          <Button
            primary
            onClick={() => {
              onMainButtonClicked();
              handleSignUpClick({ redirectLocation });
            }}
            disabled={loading}
            loading={loading}
            data-testid="continueWithAARP"
          >
            {t('i18n-actions:button.sign-up')}
          </Button>
        </WithMicroCopy>
      );
    },
    [hideLoginLink]
  );

  return (
    <AltModal
      heading=""
      name="RegisterModal"
      onMount={onOpen}
      openControlId={openControlId}
      renderControl={open => children({ open })}
      aarpHeader
    >
      <>
        {({ onMainButtonClicked }) => (
          <>
            <Heading as="h1">{t('register-account.heading')}</Heading>
            <Paragraph color="grey" noMargin>
              {t('register-account.paragraph-1')}
            </Paragraph>
            <VerticalSpacing size="8" />
            <Paragraph size="medium" color="grey" noMargin className={classes.flex}>
              <Icon name="long arrow alternate right" size="large" color="red" />
              {t('register-account.paragraph-2')}
            </Paragraph>
            <Paragraph size="medium" color="grey" noMargin className={classes.flex}>
              <Icon name="long arrow alternate right" size="large" color="red" />
              {t('register-account.paragraph-3')}
            </Paragraph>
            <Paragraph size="medium" color="grey" noMargin className={classes.flex}>
              <Icon name="long arrow alternate right" size="large" color="red" />
              {t('register-account.paragraph-4')}
            </Paragraph>
            <VerticalSpacing size="8" />
            <AuthActionHandlers overrideState={overrideState}>
              {({ handleLogInClick, handleSignUpClick, loading }) =>
                actionHandlers({
                  onMainButtonClicked,
                  handleLogInClick,
                  handleSignUpClick,
                  loading,
                })
              }
            </AuthActionHandlers>
          </>
        )}
      </>
    </AltModal>
  );
};

export default AuthModal;
