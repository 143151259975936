/* eslint-disable */
// @ts-nocheck
import { Trans } from 'react-i18next';

import createQuestionFactory from '@/domain/questions/createQuestionFactory';
import { getAnswer } from '@/domain/questions/util';
import { getAnswerValidationRules } from '@/domain/questions/validation';

import { trans } from '@/util/i18n';

import Field from '@/components/Field';
import LegacyCurrencyInputField from '@/components/Field/LegacyCurrencyInputField';
import Paragraph from '@/components/Paragraph';
import RadioButtonGroupField from '@/components/RadioButtonGroup';

const creditCardFactory = createQuestionFactory('creditCard', getAnswerValidationRules);

export const monthlyPaymentQ = creditCardFactory({
  name: 'monthlyPayment',
  title: trans('i18n-questions:monthly-payment.title'),
  component: LegacyCurrencyInputField,
  defaultProps: {
    placeholder: 'i18n-questions:amount-placeholder',
    onChange: e => {
      // hack alert - sending a zero here breaks the calcs
      if (e.target.value === '0') {
        e.target.value = 1;
      }
    },
  },
});

export const promoPeriodQ = creditCardFactory({
  name: 'promoPeriod',
  title: trans('i18n-questions:promo-period.title'),
  info: {
    content: trans(
      <>
        <Paragraph>
          <Trans ns="i18n-questions" i18nKey="paragraph.one.credit-card" />
        </Paragraph>
        <Paragraph>
          <Trans ns="i18n-questions" i18nKey="paragraph.two.credit-card" />
        </Paragraph>
      </>
    ),
    name: 'CreditCardPromoPeriod',
  },
  component: RadioButtonGroupField,
  defaultProps: {
    dataTestIdYes: 'promoPeriodYes',
    dataTestIdNo: 'promoPeriodNo',
  },
});

export const interestRateQ = creditCardFactory({
  name: 'CreditCardInterestRate',
  title: trans('i18n-questions:interest-rate-question.title'),
  component: Field,
  microcopy: '1% – 49.9%',
  defaultProps: {
    label: '%',
    labelPosition: 'right',
    onChange: e => {
      // hack alert - we don't want users to be able to enter 0% interest rate
      if (e.target.value === '0') {
        e.target.value = 1;
      } else if (Number(e.target.value) > 49.9) {
        e.target.value = 49.9;
      }
    },
  },
});

export const promoPeriodDurationQ = creditCardFactory({
  name: 'CreditCardPromoPeriodDuration',
  title: trans('i18n-questions:promo-period-duration.title'),
  component: Field,
  microcopy: trans('i18n-questions:promo-period-duration.microcopy'),
  defaultProps: {
    label: trans('i18n-questions:promo-period-duration.label'),
    labelPosition: 'right',
    onChange: e => {
      // hack alert - cap the value on 36 months
      if (Number(e.target.value) > 36) {
        e.target.value = 36;
      } else if (Number(e.target.value) === 0) {
        e.target.value = 1;
      }
    },
  },
});

export const duringPromoInterestRateQ = creditCardFactory({
  name: 'CreditCardPostPromoPeriodRemainingBalance',
  title: trans('i18n-questions:during-promo-interest-rate.title'),
  component: Field,
  defaultProps: {
    label: '%',
    labelPosition: 'right',
  },
});

export const postPromoInterestRateQ = creditCardFactory({
  name: 'CreditCardPostPromoInterestRate',
  title: trans('i18n-questions:post-promo-interest-rate.title'),
  component: Field,
  microcopy: '1% – 49.9%',
  defaultProps: {
    label: '%',
    labelPosition: 'right',
    onChange: e => {
      // hack alert - sending a zero here breaks the universe
      if (e.target.value === '0') {
        e.target.value = 1;
      } else if (Number(e.target.value) > 49.9) {
        e.target.value = 49.9;
      }
    },
  },
});

export const promoPeriodConditionalQ = {
  id: 'borrow from retirement conditional',
  getQuestions: formValues => {
    const promoPeriod = getAnswer(promoPeriodQ, { answers: formValues });

    if (promoPeriod === 0) {
      return [interestRateQ];
    }

    if (promoPeriod === 1) {
      return [promoPeriodDurationQ, duringPromoInterestRateQ, postPromoInterestRateQ];
    }

    return [];
  },
};
