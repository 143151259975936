/* eslint-disable */
// @ts-nocheck
import { Redirect } from 'react-router-dom';

import { LANDING_PAGE_PATH } from '@/domain/paths';

const SignOut = () => {
  return <Redirect to={LANDING_PAGE_PATH} />;
};

export default SignOut;
