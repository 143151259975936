import { array, string, lazy } from 'yup';

import getFlowMetadataValidationSchema from './flowMetadata';

export const READ_ONLY_EXPENSE_FIELD_NAMES = ['questions-expenseType'];

export const EXPENSE_STATUSES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};

export const acceptedMyPlanCompletedSteps = [
  'MakeSureYoureCoveredAA1',
  'EstimateYourOutOfPocketCostsAA2',
  'ExplorePrepaymentAndFinancialAidAA3',
  'MakeSureYoureCoveredAB1',
  'EstimateYourOutOfPocketCostsAB2',
  'MakeSureYoureCoveredAC1',
  'EstimateYourOutOfPocketCostsAC2',
  'ExploreOptionsForGettingInsuredAD1',
  'EstimateYourOutOfPocketCostsAD2',
  'MakeSureYouWereBilledCorrectlyBA1',
  'NegotiateToLowerYourCostsBA2',
  'RequestAffordablePaymentPlanBA3',
  'MakeSureYouWereBilledCorrectlyBB1',
  'RequestAnAffordablePaymentPlanBB2',
  'ContactYourStateMedicaidProgramBC1',
  'MakeSureYouWereBilledCorrectlyBD1',
  'NegotiateToLowerYourCostsBD2',
  'RequestAnAffordablePaymentPlanBD3',
  'UnderstandYourRightsCA1',
  'YouCanStillNegotiateCA2',
  'RequestAffordablePaymentPlanCA3',
  'ConsiderWorkingWithDebtCollectorCA4',
  'UnderstandYourRightsCB1',
  'RequestAnAffordablePaymentPlanCB2',
  'NegotiateWithTheCollectionAgencyCB3',
  'ContactYourStateMedicaidProgramCC1',
  'UnderstandYourRightsCD1',
  'NegotiateToLowerYourCostsCD2',
  'RequestAnAffordablePaymentPlanCD3',
  'ConsiderWorkingWithCollectionsCD4',
  'SafetyFirstMustFixIssuesAA1',
  'UnderstandTheClaimsProcessAA2',
  'SafetyFirstMustFixIssuesAB1',
  'EstimateYourCostsAB2',
  'FindMechanicAB3',
  'LookIntoOtherTransportationOptionsAB4',
  'EstimateYourCostsBB1',
  'FindMechanicBB2',
  'LookIntoOtherTransportationOptionsBB3',
  'HomeUnderstandYourRightsAA1',
  'HomeDocumentTheIssueAndTrackExpensesAA2',
  'HomeContactYourLandlordAA3',
  'HomeFileRentersInsuranceClaimAA4',
  'HomeUnderstandYourRightsAB1',
  'HomeDocumentTheIssueAndTrackExpensesAB2',
  'HomeContactYourLandlordAB3',
  'HomeDocumentTheIssueAndTrackExpensesBA1',
  'HomeUnderstandYourInsuranceBA2',
  'HomeUnderstandTheClaimsProcessBA3',
  'PrioritizeEssentialBillsX1',
  'KnowConsequencesUnpaidBillsX2',
  'TryNegotiateWithCreditorsX3',
  'helpFlow',
  'freeFundsFlow',
  'fundingFlow',
  'paybackStrategy',
];

const expenseConfiguration = {
  expenseId: string(),
  vertical: string(),
  myPlanCompletedSteps: array().of(
    // eslint-disable-next-line no-template-curly-in-string
    string().test('acceptedMyPlanCompletedSteps', '${value} is not valid', value =>
      acceptedMyPlanCompletedSteps.includes(value)
    )
  ),
  flowMetadata: lazy(getFlowMetadataValidationSchema),
};

export function getValidationRules(fieldName) {
  return expenseConfiguration[fieldName];
}

export const EXPENSE_EDIT_PROPERTIES = ['answers'];

export default expenseConfiguration;
