import Big from 'big.js';

import { TMonthlyLoanPaymentParams } from '@/types/domain/calcs';

/**
 * Calculates the monthly loan payments on the Retirement Plan Loan.
 */
const calculateMonthlyLoanPayment = ({
  loanAmount,
  loanInterestRate,
  loanPaybackTerm,
}: TMonthlyLoanPaymentParams): Big => {
  const exponentialOperation = Big(loanInterestRate)
    .div(12)
    .plus(1)
    .pow(loanPaybackTerm * 12);

  const monthlyLoanPayment = Big(loanInterestRate)
    .div(12)
    .times(exponentialOperation)
    .div(+exponentialOperation - 1)
    .times(loanAmount);

  return monthlyLoanPayment;
};

export default calculateMonthlyLoanPayment;
