import * as React from 'react';

import { noop } from '@/util/function';

import { TProvider, StateDispatch } from '@/types/common';

const initialContext: {
  isClosed: boolean;
  setClose: StateDispatch<boolean>;
  externalLink: string | null;
  setExternalLink: StateDispatch<string | null>;
} = {
  isClosed: false,
  setClose: noop,
  externalLink: null,
  setExternalLink: noop,
};

export const ExternalLinkModalContext = React.createContext(initialContext);

const ExternalLinkModalProvider: TProvider = ({ children }) => {
  const [isClosed, setClose] = React.useState(true);
  const [externalLink, setExternalLink] = React.useState<string | null>(null);

  return (
    // TODO: Fix this eslint error
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ExternalLinkModalContext.Provider value={{ isClosed, setClose, externalLink, setExternalLink }}>
      {children}
    </ExternalLinkModalContext.Provider>
  );
};

export default ExternalLinkModalProvider;
