import React from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveSpacing from '@/components/ResponsiveSpacing';
import { AltModal } from '@/components/modal';
import NumberedList from '@/components/NumberedList';
import Link from '@/components/link';

const name = 'amountInRetirementWhereFind';

const AmountInRetirementWhereFind = () => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <AltModal
      heading={t('amount-retirement-fund.paragraph')}
      name={name}
      renderControl={open => (
        <Link data-testid={`${name}Btn`} onClick={open}>
          {t('i18n-default:where-to-find')}
        </Link>
      )}
    >
      <ResponsiveSpacing bottom>
        <NumberedList items={t('amount-retirement-fund.items')} />
      </ResponsiveSpacing>
    </AltModal>
  );
};

export default AmountInRetirementWhereFind;
