import { AnyEventObject, assign, EventObject, StateNodeConfig, StateSchema, StatesConfig } from 'xstate';
import {
  PAYMENT_PLAN_FLAGS,
  sortPlansByMonth,
  isMaxNumberOfDebtsReached,
  PAYMENT_PLAN_STATUSES,
} from 'common/model/paymentPlan';
import { ACCOUNT_STATUSES } from 'common/model/debt';
import { ApolloQueryResult } from '@apollo/client';

import { createDebtCrudStates } from '@/domain/stateMachines/debtManager/debtCrudStates';
import {
  isDebtDelinquencyDirty,
  isAmountPaidDirty,
  isHasContactedCreditorDirty,
} from '@/domain/stateMachines/debtManager/debtsPlanMachineHelpers';
import {
  mergePaymentPlanWithDebts,
  checkIfAnyReasonsForDisqualificationPresent,
  serializeAccounts,
  checkIfAnyDebtsPresent,
  checkIfAnyDebtsPending,
  TPaymentPlan,
} from '@/domain/debtsPlan';
import { type TAccount } from '@/domain/debts';

import { not } from '@/util/function';
import { isDateEqual, MonthYear } from '@/util/date';

import { calculateIfFundsCoverPayments } from '@/pages/DebtPlan/PaymentPlan/selectors';
import { TActivity } from '@/pages/DebtPlan/PaymentPlan/PaymentPlanTabs/PlanTab/activities';

import {
  UPDATE_PAYMENT_PLAN,
  ADVANCE_MONTH,
  UPDATE_PAYMENT_PLAN_AND_UPSERT_TRANSACTIONS_AND_DEBT_BUDGET,
  UPDATE_ACTIVITIES,
} from '@/graphql/mutations';
import { GET_COMBINED_DEBTS_PLAN, GET_PAYMENT_PLAN } from '@/graphql/queries';
import { TransactionType } from '@/types/generated/globalTypes';

import { isDebtPlanMachineIdle } from '../budget/helpers';

import { updatingDebtAccountFunction } from './updateDebtAccount';

import type { InitialContextT } from '..';

type GetLatestPaymentPlanDateEvent = EventObject & {
  data: {
    data: {
      paymentPlans: TPaymentPlan[];
      debts: TAccount[];
    };
  };
};

type SetCreatedPlanEvent = EventObject & {
  data: {
    data: {
      createPaymentPlan: TPaymentPlan;
    };
  };
};

function getLatestPaymentPlanDate(_, event: GetLatestPaymentPlanDateEvent) {
  const { paymentPlans } = event.data.data;

  // payment plans come sorted by ascending date
  const { year, month } = paymentPlans[paymentPlans.length - 1];

  return { year, month };
}

export const setCreatedPlan = assign<InitialContextT, SetCreatedPlanEvent>((_, event) => {
  const { createPaymentPlan: createdPaymentPlan } = event.data.data;
  return {
    paymentPlan: createdPaymentPlan,
    ...(createdPaymentPlan.status === PAYMENT_PLAN_STATUSES.DRAFT ? { draftPaymentPlan: createdPaymentPlan } : {}),
  };
});

/**
 * Helper function to ensure that the `amountsPaid` array on each payment plan:
 * 1. Has valid debt references
 * 2. Removes any references that are not valid
 * 3. Appends any missing debts to the array
 */
const cleanPaymentPlan = (debts: TAccount[], paymentPlan?: TPaymentPlan) => {
  if (!paymentPlan) return null;

  const { amountsPaid } = paymentPlan;
  const validDebtIds = debts
    .filter(debt => [ACCOUNT_STATUSES.ACTIVE, ACCOUNT_STATUSES.PENDING].includes(debt.status))
    .map(debt => debt.debtId);
  const cleanedAmountsPaid = amountsPaid.filter(
    amountItem => !!amountItem.debtId && validDebtIds.includes(amountItem.debtId)
  );
  validDebtIds.forEach(id => {
    if (!cleanedAmountsPaid.some(amountItem => amountItem.debtId === id)) {
      cleanedAmountsPaid.push({
        amount: 0,
        debtId: id,
        hasContactedCreditor: false,
        // @ts-expect-error TODO: fix this
        debtSnapshot: {},
        __typename: 'AmountPaid',
      });
    }
  });

  return {
    ...paymentPlan,
    amountsPaid: cleanedAmountsPaid,
  };
};

export const setLatestPlan = assign<InitialContextT, GetLatestPaymentPlanDateEvent>((_, event) => {
  const { paymentPlans } = event.data.data;
  // TODO at the moment we are only using the active plans but in the future we could require the archived ones.
  const activePaymentPlans = paymentPlans.filter(paymentPlan => paymentPlan.status === PAYMENT_PLAN_STATUSES.ACTIVE);
  const archivedPaymentPlans = paymentPlans.filter(
    paymentPlan => paymentPlan.status === PAYMENT_PLAN_STATUSES.ARCHIVED
  );
  const draftPaymentPlans = paymentPlans.filter(paymentPlan => paymentPlan.status === PAYMENT_PLAN_STATUSES.DRAFT);
  const draftPaymentPlan = draftPaymentPlans[draftPaymentPlans.length - 1];
  const latestPaymentPlan = paymentPlans.length
    ? (sortPlansByMonth(activePaymentPlans)[activePaymentPlans.length - 1] as TPaymentPlan)
    : undefined;

  return {
    paymentPlan: latestPaymentPlan,
    draftPaymentPlan,
    archivedPaymentPlans,
  };
});

export const setDraftPaymentPlan = assign<InitialContextT>(context => {
  const { draftPaymentPlan } = context;

  return { paymentPlan: draftPaymentPlan };
});

// @ts-expect-error Need to check this again
export const setSelectedDate = assign<InitialContextT>({ selectedDate: getLatestPaymentPlanDate });

export async function updateAvailableFunds(context: InitialContextT, event) {
  const { client, selectedDate, userId } = context;

  const { debts }: { debts: TAccount[] } = event;

  // sum all debt budget into availableFunds
  const uniqueDebts = [...debts, ...context.debts].filter(
    (debt, index, self) => index === self.findIndex(d => d.debtId === debt.debtId)
  );

  const totalDebtBudget = uniqueDebts.reduce((acc, debt) => acc + debt.debtBudget, 0);

  const paymentPlan = {
    availableFunds: totalDebtBudget,
    month: selectedDate.month,
    year: selectedDate.year,
    hasUpdatedAvailableFunds: true,
  };

  let res: unknown;
  try {
    res = await client.mutate({ mutation: UPDATE_PAYMENT_PLAN, variables: { input: { userId, paymentPlan } } });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error updating available funds', e);
  }

  return res;
}

export async function updateAmountPaid(context: InitialContextT, event) {
  const { client, userId } = context;
  const { targetDebtId, targetDebtAmount, closeModal } = event;
  const debtsPlan = mergePaymentPlanWithDebts(context);

  const isPaid = targetDebtAmount > 0;

  const amountsPaid = debtsPlan.amountsPaid?.map(({ debtId = '', amount = 0, hasContactedCreditor = false }) => {
    const isTargetDebt = targetDebtId === debtId;

    return {
      debtId,
      amount,
      hasContactedCreditor,
      // set new amount when we find the matching debt
      ...(isTargetDebt ? { amount: Number(targetDebtAmount) } : {}),
    };
  });

  const fields = {
    availableFunds: debtsPlan.availableFunds,
    amountsPaid,
    month: debtsPlan.month,
    year: debtsPlan.year,
    // Automatically dismiss the mark as paid popover when a payment is made
    ...(isPaid ? { [PAYMENT_PLAN_FLAGS.POPOVER_DISMISSED_MARK_AS_PAID]: true } : {}),
  };

  const response = await client.mutate({
    mutation: UPDATE_PAYMENT_PLAN,
    variables: { input: { userId, paymentPlan: fields } },
  });

  return { ...response, debtId: targetDebtId, closeModal };
}

export async function updateAmountPaidAndUpsertTransaction(context: InitialContextT, event) {
  const { client, userId, budget, paymentPlan, debts } = context;
  const { targetDebtId, targetDebtAmount, closeModal, nickname = null, navigate } = event;
  const debtsPlan = mergePaymentPlanWithDebts(context);

  const isPaid = targetDebtAmount > 0;

  const amountsPaid = debtsPlan.amountsPaid?.map(({ debtId = '', amount = 0, hasContactedCreditor = false }) => {
    const isTargetDebt = targetDebtId === debtId;

    return {
      debtId,
      amount,
      hasContactedCreditor,
      // set new amount when we find the matching debt
      ...(isTargetDebt ? { amount: Number(targetDebtAmount) } : {}),
    };
  });

  const fields = {
    amountsPaid,
    month: debtsPlan.month,
    year: debtsPlan.year,
    // Automatically dismiss the mark as paid popover when a payment is made
    ...(isPaid ? { [PAYMENT_PLAN_FLAGS.POPOVER_DISMISSED_MARK_AS_PAID]: true } : {}),
  };

  const placeholderPaymentPlanInfo = {
    ...(paymentPlan || {}),
    ...fields,
  };

  const targetTransaction = budget?.transactions?.find(transaction =>
    isDateEqual(
      { month: debtsPlan.month || '', year: debtsPlan.year || '' },
      { month: transaction.month || '', year: transaction.year || '' }
    )
  );

  const mappedAmountsPaidToTransaction: InitialContextT['budget']['transactions'][0]['list'] =
    amountsPaid?.flatMap(amtPaid => {
      const existingDebt = debts.find(debt => amtPaid.debtId === debt.debtId);
      const { nickname: debtNickname } = existingDebt || {};

      return amtPaid.amount > 0
        ? {
            debtId: amtPaid.debtId,
            date: new Date().getTime(),
            type: 'debit' as TransactionType,
            amount: amtPaid.amount,
            expense: null,
            description: debtNickname || nickname,
            savingsLargePurchaseId: null,
            savingsRainyDayId: null,
          }
        : [];
    }) || null;

  const filteredTransactionList = targetTransaction?.list?.filter(transaction => transaction?.debtId === null) || [];
  const updatedTransactionList = mappedAmountsPaidToTransaction
    ? filteredTransactionList.concat(mappedAmountsPaidToTransaction)
    : filteredTransactionList;

  const transactions = {
    month: debtsPlan.month,
    year: debtsPlan.year,
    list: updatedTransactionList,
  };

  const debtsPlanDebt = debtsPlan.amountsPaid.find(({ debtId }) => debtId === targetDebtId);

  if (!debtsPlanDebt) return null;

  const debt = {
    debtId: targetDebtId,
    debtBudget: Math.max(debtsPlanDebt.debt.debtBudget, +targetDebtAmount),
  };

  const optimisticResponse = {
    upsertTransactions: {
      ...transactions,
      userId,
      dateCreated: targetTransaction?.dateCreated || new Date().getTime(),
      dateUpdated: new Date().getTime(),
      __typename: 'TransactionsOutput',
    },
    updatePaymentPlan: {
      ...placeholderPaymentPlanInfo,
      userId,
      month: debtsPlan.month,
      year: debtsPlan.year,
      __typename: 'PaymentPlanOutput',
    },
  };

  /**
   * Disable updating Debt budget in case of higher payed amount
  // if the amount paid is less than the debt budget, we don't need to update the available funds
  if (+targetDebtAmount > debtsPlanDebt.debt.debtBudget) {
    await updateAvailableFunds(context, { debts: [debt] });
  }
  */
  return client
    .mutate({
      mutation: UPDATE_PAYMENT_PLAN_AND_UPSERT_TRANSACTIONS_AND_DEBT_BUDGET,
      optimisticResponse,
      variables: {
        paymentPlanInput: { userId, paymentPlan: fields },
        transactionsInput: { userId, transactions },
        debtInput: { userId, debt },
      },
    })
    .then(response => ({ ...response, debtId: targetDebtId, closeModal, navigate }));
}

export async function markItemAsCalled(context: InitialContextT, event) {
  const { client, paymentPlan, userId } = context;
  const { targetDebtId, targetCalledStatus = false, closeModal } = event;
  const debtsPlan = mergePaymentPlanWithDebts(context);

  if (!paymentPlan) return Promise.resolve();

  const amountsPaid = debtsPlan.amountsPaid?.map(({ debtId = '', amount = 0, hasContactedCreditor = false }) => {
    const isTargetDebt = targetDebtId === debtId;

    return {
      debtId,
      amount,
      hasContactedCreditor,
      // set new status when we find the matching debt
      ...(isTargetDebt ? { hasContactedCreditor: targetCalledStatus } : {}),
    };
  });

  const fields = {
    availableFunds: paymentPlan.availableFunds,
    amountsPaid,
    month: paymentPlan.month,
    year: paymentPlan.year,
  };

  const response = await client.mutate({
    mutation: UPDATE_PAYMENT_PLAN,
    variables: { input: { userId, paymentPlan: fields } },
  });

  return { ...response, debtId: targetDebtId, hasContactedCreditor: targetCalledStatus, closeModal };
}

export function updatePaymentPlanFlag(context: InitialContextT, event) {
  const { client, paymentPlan, userId } = context;
  const { flagId, status = false } = event;
  if (!paymentPlan) return Promise.resolve();

  const paymentPlanUpdate = { [flagId]: status, month: paymentPlan.month, year: paymentPlan.year };

  return client.mutate({
    mutation: UPDATE_PAYMENT_PLAN,
    variables: { input: { userId, paymentPlan: paymentPlanUpdate } },
  });
}

export function updatePaymentPlanActivities(context: InitialContextT, event) {
  const { client, userId } = context;
  const { activities }: { activities: TActivity[] } = event;

  return client.mutate({
    mutation: UPDATE_ACTIVITIES,
    variables: {
      input: activities.map(activity => ({
        userId,
        activity: {
          ...activity,
        },
      })),
    },
  });
}

export function updatePaymentPlanDisclaimerDisplayed(context: InitialContextT, event) {
  const { client, paymentPlan, userId } = context;
  const { userViewedDisclaimer } = event;

  if (!paymentPlan) return Promise.resolve();

  const paymentPlanUpdate = {
    isDisclaimerDisplayed: userViewedDisclaimer,
    month: paymentPlan.month,
    year: paymentPlan.year,
  };

  return client.mutate({
    mutation: UPDATE_PAYMENT_PLAN,
    variables: { input: { userId, paymentPlan: paymentPlanUpdate } },
  });
}

export const setActivePaymentPlan = (context: InitialContextT, event) => {
  const { remainingFundsTowardsUnpaidAccounts } = event;
  const { client, paymentPlan, userId, allocatedPayments } = context;
  const availableFunds = Number((remainingFundsTowardsUnpaidAccounts as number).toFixed(2));
  if (!paymentPlan) return Promise.resolve();

  const fields = {
    availableFunds,
    amountsPaid: serializeAccounts(allocatedPayments.amountsPaid || []),
    month: paymentPlan.month,
    year: paymentPlan.year,
    status: PAYMENT_PLAN_STATUSES.ACTIVE,
  };

  return client.mutate({
    mutation: UPDATE_PAYMENT_PLAN,
    variables: {
      input: {
        userId,
        paymentPlan: fields,
      },
    },
  });
};

export const setPaymentPlan = (
  context: InitialContextT,
  event: EventObject & ApolloQueryResult<{ data: { paymentPlans: TPaymentPlan[]; debts: TAccount[] } }>
) => {
  const { client, userId } = context;
  const { paymentPlans, debts } = event.data.data;

  // TODO at the moment we are only using the active plans but in the future we could require the archived ones.
  const activePaymentPlans = paymentPlans.filter(paymentPlan => paymentPlan.status === PAYMENT_PLAN_STATUSES.ACTIVE);
  const latestPaymentPlan = paymentPlans.length
    ? (sortPlansByMonth(activePaymentPlans)[activePaymentPlans.length - 1] as TPaymentPlan)
    : undefined;
  const paymentPlan = cleanPaymentPlan(debts, latestPaymentPlan);
  if (!paymentPlan) return Promise.resolve();

  const { amountsPaid, month, year } = paymentPlan;

  const optimisticResponse = {
    updatePaymentPlan: {
      ...paymentPlan,
      amountsPaid,
      userId,
      month,
      year,
      __typename: 'PaymentPlanOutput',
    },
  };

  return client.mutate({
    mutation: UPDATE_PAYMENT_PLAN,
    variables: {
      input: {
        userId,
        paymentPlan: {
          amountsPaid: serializeAccounts(amountsPaid),
          month,
          year,
        },
      },
    },
    optimisticResponse,
    update(cache, { data }) {
      if (!data) return;

      const { updatePaymentPlan: cachedPaymentPlan } = data;

      cache.writeQuery({
        query: GET_PAYMENT_PLAN,
        variables: { userId },
        data: { paymentPlan: { ...cachedPaymentPlan, amountsPaid } },
      });
    },
  });
};

export function advanceToNextMonth(context: InitialContextT) {
  const { client, selectedDate, userId, archivedPaymentPlans } = context;
  const { month, year } = selectedDate;
  const optimisticResponse = {
    advanceToCurrentMonth: {
      archivedPaymentPlan: {
        month,
        year,
        __typename: 'PaymentPlan',
      },
      debts: [],
      paymentPlan: {
        month,
        year,
        __typename: 'PaymentPlan',
        amountsPaid: [],
      },
      __typename: 'AdvanceToCurrentMonthOutput',
    },
  };

  return client.mutate({
    mutation: ADVANCE_MONTH,
    variables: {
      userId,
      planToAdvanceFrom: {
        month,
        year,
      },
    },
    optimisticResponse,
    update(cache, { data }) {
      if (!data?.advanceToCurrentMonth.paymentPlan) return;

      const { archivedPaymentPlan, debts, paymentPlan } = data.advanceToCurrentMonth;
      // We update the cache with previous archived plans and the information that came with the transition.
      cache.writeQuery({
        query: GET_COMBINED_DEBTS_PLAN,
        variables: { userId },
        data: { debts, paymentPlans: [...archivedPaymentPlans, archivedPaymentPlan, paymentPlan] },
      });
    },
  });
}

type TrackMarkedDebtAsPaidEvent = EventObject & {
  targetDebtId: string;
  targetDebtAmount: number;
};

export function trackMarkedDebtAsPaid(context: InitialContextT, event: TrackMarkedDebtAsPaidEvent) {
  const { debts, analytics } = context;
  const { targetDebtId, targetDebtAmount } = event;

  const debt = debts.find(({ debtId }) => targetDebtId === debtId);

  // 0 marks debt as unpaid
  if (targetDebtAmount !== 0 && debt) {
    analytics.trackEvent({
      name: 'markedDebtAsPaid',
      payload: {
        debt,
        value: Math.round(targetDebtAmount),
      },
    });
  }
}

type TrackUpdateAvailableFundsEvent = EventObject & {
  data: {
    data: {
      updatePaymentPlan: TPaymentPlan;
    };
  };
};

export function trackUpdateAvailableFunds({ analytics }: InitialContextT, event: TrackUpdateAvailableFundsEvent) {
  const { availableFunds } = event.data.data.updatePaymentPlan;
  analytics.trackEvent({
    name: 'changedAvailableFunds',
    payload: { availableFunds: Math.round(availableFunds) },
  });
}

type TrackCompletedActionableItemEvent = EventObject & {
  targetCalledStatus: boolean;
  targetDebtId: string;
  flagId: string;
};

export function trackCompletedActionableItem(context: InitialContextT, event: TrackCompletedActionableItemEvent) {
  const { analytics, debts } = context;
  const { type } = event;

  let payload;

  switch (type) {
    case 'MARK_ITEM_AS_CALLED_FROM_PAYMENT_PLAN':
    case 'MARK_ITEM_AS_CALLED_FROM_DEBT_EDITING': {
      if (event.targetCalledStatus !== true) {
        break;
      }
      const debt = debts.find(({ debtId }) => event.targetDebtId === debtId);
      payload = { debt, actionableItemId: 'calledCreditors' };
      break;
    }

    default:
      payload = { actionableItemId: event.flagId };
  }

  analytics.trackEvent({ name: 'completedActionableItem', payload });
}

export function trackDisqualification(context: InitialContextT) {
  const { analytics, reasonsForDisqualification } = context;

  if (reasonsForDisqualification.length > 1) {
    analytics.trackEvent({ name: 'disqualified', payload: { reason: 'multipleReasonsForDisqualification' } });
  } else {
    analytics.trackEvent({ name: 'disqualified', payload: { reason: reasonsForDisqualification[0] } });
  }
}

export function trackPaymentPlan(context: InitialContextT) {
  const { analytics } = context;
  const debtsPlan = mergePaymentPlanWithDebts(context);
  const { sumOfMinMonthlyPayments, availableFunds } = calculateIfFundsCoverPayments({
    debtsPlan: debtsPlan as NonNullable<TPaymentPlan>,
  });

  const sumOfMinMonthlyPaymentsFormatted = Number(sumOfMinMonthlyPayments?.toFixed(2));
  const availableFundsFormatted = Number(availableFunds?.toFixed(2));
  let paymentPlanState;
  if (sumOfMinMonthlyPaymentsFormatted > availableFundsFormatted) {
    paymentPlanState = 'notEnoughFundsPlan';
  } else if (sumOfMinMonthlyPaymentsFormatted === availableFundsFormatted) {
    paymentPlanState = 'coveringYourPaymentsPlan';
  } else {
    paymentPlanState = 'payingExtraPlan';
  }

  analytics.trackEvent({ name: 'allocatedPayments', payload: { state: paymentPlanState } });
}

type ShowProgressPopoverEvent = EventObject &
  ApolloQueryResult<{ data: { updatePaymentPlan: TPaymentPlan; updateDebt: TAccount }; debtId: string }>;

export const showProgressPopover = assign<InitialContextT, ShowProgressPopoverEvent>({
  showProgressPopover: (context, event) => {
    const { debtId } = event.data;
    const { paymentPlan, archivedPaymentPlans } = context;

    if (
      paymentPlan?.[PAYMENT_PLAN_FLAGS.POPOVER_DISMISSED_PROGRESS] ||
      archivedPaymentPlans[archivedPaymentPlans.length - 1]?.[PAYMENT_PLAN_FLAGS.POPOVER_DISMISSED_PROGRESS]
    ) {
      return false;
    }

    switch (event.type) {
      case 'done.invoke.updateAmountPaid':
        return isAmountPaidDirty({ context, event, debtId });
      case 'done.invoke.editDebt':
        return isDebtDelinquencyDirty({ context, event, debtId });
      case 'done.invoke.markItemAsCalled':
        return isHasContactedCreditorDirty({ context, event, debtId });
      default:
        return false;
    }
  },
});

const PAYMENT_PLAN_PARENT_STATE = '#appMachine.loaded.debtManager.paymentPlan.ready';
const debtCrudStatesOptions = {
  navigation: {
    add: 'navigateToPaymentPlan', // Possibly navigate back to accounts page if any debts are pending (might not be needed if we send user back to specialized onboarding flow)
    update: 'navigateToPaymentPlan',
    markingAsCalledFromPaymentPlan: 'navigateAfterMarkingAsCalledFromPaymentPlan',
    markingAsCalledFromDebtEditing: 'navigateToPaymentPlanAccountDetails',
  },
};
const debtCrudStates = createDebtCrudStates(PAYMENT_PLAN_PARENT_STATE, debtCrudStatesOptions);

type PaymentPlanEditingStateSchema = {
  states: {
    initial: StateSchema;
    updatingAvailableFunds: StateSchema;
    markingItemAsCalledFromPaymentPlan: StateSchema;
    markingItemAsCalledFromDebtEditing: StateSchema;
    updatingPaymentPlanFlag: StateSchema;
    updatingAmountPaidFromPaymentPlan: StateSchema;
    updatingAmountPaidFromDebtEditing: StateSchema;
    updatingAmountPaidAndUpsertTransactionFromPaymentPlan: StateSchema;
    updatingAmountPaidAndUpsertTransactionFromDebtEditing: StateSchema;
  };
};

export function createPaymentPlanEditingStates(
  parentState = PAYMENT_PLAN_PARENT_STATE
): StatesConfig<InitialContextT, PaymentPlanEditingStateSchema, AnyEventObject> {
  const onError = {
    target: parentState,
    actions: 'notifyError',
  };

  return {
    initial: {
      on: {
        UPDATE_AVAILABLE_FUNDS: 'updatingAvailableFunds',
        UPDATE_AMOUNT_PAID_FROM_PAYMENT_PLAN: 'updatingAmountPaidFromPaymentPlan',
        UPDATE_AMOUNT_PAID_FROM_DEBT_EDITING: 'updatingAmountPaidFromDebtEditing',
        UPDATE_ACCOUNT_FROM_DEBT_EDITING: 'updatingDebtAccount',
        UPDATE_AMOUNT_PAID_AND_UPSERT_TRANSACTION_FROM_PAYMENT_PLAN:
          'updatingAmountPaidAndUpsertTransactionFromPaymentPlan',
        UPDATE_AMOUNT_PAID_AND_UPSERT_TRANSACTION_FROM_DEBT_EDITING:
          'updatingAmountPaidAndUpsertTransactionFromDebtEditing',
        MARK_ITEM_AS_CALLED_FROM_PAYMENT_PLAN: 'markingItemAsCalledFromPaymentPlan',
        MARK_ITEM_AS_CALLED_FROM_DEBT_EDITING: 'markingItemAsCalledFromDebtEditing',
        UPDATE_PAYMENT_PLAN_FLAG: 'updatingPaymentPlanFlag',
        UPDATE_PAYMENT_PLAN_ACTIVITIES: 'updatingPaymentPlanActivities',
        CLEAR_PREVIOUS_AMOUNT_PAID: {
          actions: 'clearPreviousAmountPaid',
        },
        CLEAR_PAID_OFF_POPOVER: {
          actions: 'hidePaidOffPopover',
        },
        UPDATE_HIGHLIGHTING: {
          actions: 'updateHighlighting',
        },
      },
    },
    updatingAvailableFunds: {
      invoke: {
        id: 'updateAvailableFunds',
        src: updateAvailableFunds,
        onDone: {
          target: parentState,
          actions: ['updatePaymentPlan', 'trackUpdateAvailableFunds'],
        },
        onError,
      },
    },
    markingItemAsCalledFromPaymentPlan: {
      entry: 'trackCompletedActionableItem',
      invoke: {
        id: 'markItemAsCalled',
        src: markItemAsCalled,
        onDone: {
          target: parentState,
          actions: [
            // 'showProgressPopover',
            'updatePaymentPlan',
            'closeModal',
            // debtCrudStatesOptions.navigation.markingAsCalledFromPaymentPlan,
          ],
        },
        onError,
      },
    },
    markingItemAsCalledFromDebtEditing: {
      entry: 'trackCompletedActionableItem',
      invoke: {
        id: 'markItemAsCalled',
        src: markItemAsCalled,
        onDone: {
          target: parentState,
          actions: [
            'showProgressPopover',
            'updatePaymentPlan',
            debtCrudStatesOptions.navigation.markingAsCalledFromDebtEditing,
            'notifyEditingDebt',
          ],
        },
        onError,
      },
    },
    updatingPaymentPlanFlag: {
      entry: 'trackCompletedActionableItem',
      invoke: {
        id: 'updatePaymentPlanFlag',
        src: updatePaymentPlanFlag,
        onDone: {
          target: parentState,
          actions: ['updatePaymentPlan'],
        },
        onError,
      },
    },
    updatingPaymentPlanActivities: {
      entry: 'trackCompletedActionableItem',
      invoke: {
        id: 'updatePaymentPlanActivities',
        src: updatePaymentPlanActivities,
        onDone: {
          target: parentState,
          actions: ['updatePaymentPlanActivities'],
        },
        onError,
      },
    },
    updatingAmountPaidFromPaymentPlan: {
      entry: 'trackMarkedDebtAsPaid',
      invoke: {
        id: 'updateAmountPaid',
        src: updateAmountPaid,
        onDone: {
          target: parentState,
          actions: [
            'showProgressPopover',
            'updatePaymentPlan',
            'updateDebtsContext',
            debtCrudStatesOptions.navigation.update,
            'notifyChangedTags',
            'showPaidOffPopover',
          ],
        },
        onError,
      },
    },
    updatingAmountPaidFromDebtEditing: {
      entry: 'trackMarkedDebtAsPaid',
      invoke: {
        id: 'updateAmountPaidFromDetails',
        src: updateAmountPaid,
        onDone: {
          target: parentState,
          actions: [
            'showProgressPopover',
            'updatePaymentPlan',
            'updateDebtsContext',
            debtCrudStatesOptions.navigation.update,
            'notifyEditingDebt',
            'showPaidOffPopover',
          ],
        },
        onError,
      },
    },
    updatingAmountPaidAndUpsertTransactionFromPaymentPlan: {
      entry: 'trackMarkedDebtAsPaid',
      invoke: {
        id: 'updateAmountPaidAndUpsertTransaction',
        src: updateAmountPaidAndUpsertTransaction,
        onDone: {
          target: parentState,
          actions: [
            'showProgressPopover',
            'setTransactionsAndPaymentPlan',
            'updateDebtsContext',
            debtCrudStatesOptions.navigation.update,
            'notifyChangedTags',
            'showPaidOffPopover',
          ],
        },
        onError,
      },
    },
    updatingDebtAccount: {
      entry: 'trackMarkedDebtAsPaid',
      invoke: {
        id: 'updatingDebtAccount',
        src: updatingDebtAccountFunction,
        onDone: [
          {
            cond: isDebtPlanMachineIdle,
            target: parentState,
            actions: [
              'showProgressPopover',
              'setTransactionsAndPaymentPlan',
              'updateDebtsContext',
              debtCrudStatesOptions.navigation.update,
              'notifyEditingDebt',
              'showPaidOffPopover',
            ],
          },
          {
            target: 'initial',
            actions: [
              'showProgressPopover',
              'setTransactionsAndPaymentPlan',
              'updateDebtsContext',
              debtCrudStatesOptions.navigation.update,
              'showPaidOffPopover',
            ],
          },
        ],
        onError,
      },
    },
    updatingAmountPaidAndUpsertTransactionFromDebtEditing: {
      entry: 'trackMarkedDebtAsPaid',
      // @ts-expect-error invoke types
      invoke: {
        id: 'updateAmountPaidAndUpsertTransactionFromDetails',
        src: updateAmountPaidAndUpsertTransaction,
        onDone: [
          {
            cond: isDebtPlanMachineIdle,
            target: parentState,
            actions: [
              'showProgressPopover',
              'setTransactionsAndPaymentPlan',
              'updateDebtsContext',
              debtCrudStatesOptions.navigation.update,
              'notifyEditingDebt',
              'showPaidOffPopover',
            ],
          },
          {
            target: 'initial',
            actions: [
              'showProgressPopover',
              'setTransactionsAndPaymentPlan',
              'updateDebtsContext',
              debtCrudStatesOptions.navigation.update,
              'showPaidOffPopover',
            ],
          },
        ],
        onError,
      },
    },
  };
}

const planEditingStates: StateNodeConfig<InitialContextT, StateSchema, AnyEventObject> = {
  id: 'planEditingStates',
  type: 'parallel',
  states: {
    accountEditing: {
      initial: 'initial',
      states: {
        initial: {
          always: [
            {
              target: 'maxAccounts',
              cond: isMaxNumberOfDebtsReached,
            },
            { target: 'accountsEditing' },
          ],
        },
        accountsEditing: {
          on: {
            ACCOUNT_ADDING: {
              cond: isMaxNumberOfDebtsReached,
              actions: 'notifyReachingMaxNumberOfDebts',
            },
          },
          ...debtCrudStates,
        },
        maxAccounts: {
          on: {
            ACCOUNT_ADDING: {
              cond: isMaxNumberOfDebtsReached,
              actions: 'notifyReachingMaxNumberOfDebts',
            },
          },
          ...debtCrudStates,
        },
      },
    },
    paymentPlanEditing: {
      initial: 'initial',
      states: createPaymentPlanEditingStates(PAYMENT_PLAN_PARENT_STATE),
    },
  },
};

interface SampleEvent extends EventObject {
  date: MonthYear;
}

type PaymentPlanStateSchema = {
  states: {
    ready: StateSchema;
    disqualified: StateSchema;
    displayPaymentPlan: StateSchema;
    emptyState: StateSchema;
  };
};

export const paymentPlanStates: StateNodeConfig<InitialContextT, PaymentPlanStateSchema, AnyEventObject> = {
  id: 'paymentPlanStates',
  initial: 'ready',
  on: {
    SET_SELECTED_DATE: {
      actions: assign<InitialContextT, SampleEvent>({ selectedDate: (_, event) => event.date }),
    },
    CANCEL_ADD_ACCOUNT: { actions: 'navigateToPaymentPlan' },
  },
  states: {
    ready: {
      entry: ['assignReasonsForDisqualification', 'mergePaymentPlanData'],
      always: [
        {
          target: 'emptyState',
          cond: not(checkIfAnyDebtsPresent),
        },
        { target: '#appMachine.loaded.debtManager.onboarding.accountsEditing', cond: checkIfAnyDebtsPending },
        {
          target: 'disqualified',
          cond: checkIfAnyReasonsForDisqualificationPresent,
          actions: ['allocatePayments'],
        },
        {
          target: 'displayPaymentPlan',
          actions: ['allocatePayments'],
        },
      ],
    },
    disqualified: {
      entry: ['trackDisqualification', 'clearPreviousAmountPaid'],
      ...planEditingStates,
    },
    displayPaymentPlan: {
      entry: ['trackPaymentPlan'],
      ...planEditingStates,
    },
    emptyState: {
      ...planEditingStates,
    },
  },
};
