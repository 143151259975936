/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import { getAnswer } from '@/domain/questions/util';

import useUserState from '@/hooks/useUserState';

import ContainerWithStickyContent from '@/components/ContainerWithStickyContent';
import CustomContainer from '@/components/CustomContainer';
import Divider from '@/components/Divider';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';

import calculatorIcon from '@/images/calculatorIcon.svg';

import { amountInRetirementQ, loanAmountBFRQ } from '../questions';

import Form from './Form';
import Results from './Results';

const BorrowAmountCalc = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-unplanned');
  const { state, loading, error } = useUserState();
  if (loading) return null;
  if (error) return null;

  const amountInRetirement = getAnswer(amountInRetirementQ, state);
  const billAmount = getAnswer(loanAmountBFRQ, state);

  const initialValues = {
    [amountInRetirementQ.id]: amountInRetirement,
    [loanAmountBFRQ.id]: billAmount,
  };

  return (
    <CustomContainer noMargin verticallyPadded>
      <Heading as="h1">{t('borrow-amount-calc.heading')}</Heading>
      <Paragraph noHorizontalMargin>{t('borrow-amount-calc.paragraph')}</Paragraph>
      <Divider tiny />
      <QuestionsForm fullWidth backButton={false} initialValues={initialValues}>
        {({ values }) => {
          const answerWrapper = fromAssessment ? state : { answers: values };

          return (
            <>
              <ContainerWithStickyContent
                disableSticky
                leftSideContent={<Form fromAssessment={fromAssessment} />}
                rightSideContent={<Results answerWrapper={answerWrapper} />}
              />
              {fromAssessment ? null : (
                <WizardUpNext imageSrc={calculatorIcon}>{t('borrow-amount-calc.wizard')}</WizardUpNext>
              )}
            </>
          );
        }}
      </QuestionsForm>
    </CustomContainer>
  );
};

export default BorrowAmountCalc;
