/* eslint-disable */
// @ts-nocheck
import classes from '../styles.module.less';

const Track = ({ length, maxLabel, minLabel, offset }) => {
  const elStyle = {
    left: `${offset}%`,
    width: `${length}%`,
  };

  return (
    <>
      <div className={classes.track} style={elStyle} />
      <div className={classes.minLabel}>{minLabel}</div>
      <div className={classes.maxLabel}>{maxLabel}</div>
    </>
  );
};

export default Track;
