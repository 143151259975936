/* eslint-disable */
// @ts-nocheck

import React, { FunctionComponent, ReactNode } from 'react';
import { Icon } from 'semantic-ui-react';
import cx from 'classnames';
import { Transition } from 'react-transition-group';

import useTimeout from '@/hooks/useTimeout';

import { Clickable } from '@/components/button';

import classes from './styles.module.less';

export const GlobalToastContainer = ({ children }) => <div className={classes.toastContainer}>{children}</div>;

const DEFAULT_DELAY = 5000;

export type TToastProps = {
  handleDismiss: Function;
  children: ReactNode;
  actionContent?: string;
  action?: Function;
  type: ReactNode;
  delay?: number;
  fullWidth?: boolean;
};

const Toast: FunctionComponent<TToastProps> = ({
  handleDismiss,
  children,
  actionContent = '',
  action,
  type,
  delay = DEFAULT_DELAY,
  fullWidth = false,
}) => {
  const [isShown, setShown] = React.useState(true);
  const handleDismissCallback = React.useCallback(() => setShown(false), []);
  const { start, clear } = useTimeout(handleDismissCallback, delay);

  React.useEffect(() => {
    start();
  }, [start]);

  return (
    <Transition in={isShown} timeout={500} onExited={() => handleDismiss()}>
      {state => (
        <div
          className={cx(classes.toastNotification, {
            [classes.shown]: state === 'entered',
            [classes.dismissed]: state === 'exiting',
            [classes.info]: type === 'info',
            [classes.error]: type === 'error',
            [classes.warn]: type === 'warn',
            [classes.fullWidth]: fullWidth,
          })}
        >
          <div className={classes.content} data-testid="notificationsToast">
            {children}
          </div>
          {actionContent && action && (
            <Clickable
              className={classes.clickable}
              onClick={() => {
                clear();
                action();
              }}
            >
              {actionContent}
            </Clickable>
          )}
          <Clickable onClick={handleDismissCallback} aria-label="closeToast">
            <Icon name="close" className={classes.icon} data-testid="closeToast" />
          </Clickable>
        </div>
      )}
    </Transition>
  );
};

export default Toast;
