import React, { FunctionComponent } from 'react';

import AnimatedLogo from '@/components/AnimatedLogo';

import classes from './GlobalLoader.module.less';

const GlobalLoader: FunctionComponent<{ loading?: boolean }> = ({ loading = true }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className={classes.overlay}>
      <AnimatedLogo data-testId="loadingIndicator" />
    </div>
  );
};

export default GlobalLoader;
