import React, { ReactNode, FunctionComponent } from 'react';
import cx from 'classnames';
import { List, SemanticSIZES } from 'semantic-ui-react';

import classes from './BigImageList.module.less';

/**
 *
 * This is a basic list component that render a list with Big image as list item (right side)
 *
 *  `ItemObject` type passed as an array of it to the `items` prop have the following shape
 *
 *  ```js
 *  {
 *    key: string | number,
 *    header: string,
 *    description: React.Node | string,
 *    image: React.ElementProps<typeof Image>,
 *  }
 *  ```
 */

export type TBigImage = {
  src: string;
  size: SemanticSIZES;
};

export type TBigImageListItem = {
  key: string;
  header: string | ReactNode;
  description: string | ReactNode;
  image: TBigImage;
};

export type TBigImageListProps = {
  items: TBigImageListItem[];
  testId: string;
  className: string;
};

const BigImageList: FunctionComponent<TBigImageListProps> = ({ items, testId, className }) => (
  <List bulleted relaxed="very" data-testid={testId} className={cx(classes.list, className)}>
    {items.map(item => (
      <List.Item key={item.key} className={classes.item}>
        <List.Content className={classes.content}>
          <List.Header>{item.header}</List.Header>
          <List.Description className={classes.description}>{item.description}</List.Description>
        </List.Content>
      </List.Item>
    ))}
  </List>
);

export default BigImageList;
