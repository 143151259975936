import { PAYMENT_PLAN_STATUSES } from 'common/model/paymentPlan';
import { ACCOUNT_STATUSES } from 'common/model/debt';
import { ActionFunction, EventObject } from 'xstate';

import {
  isOverAccountDelinquencyThreshold,
  isEssentialAccount,
  areAccountsEqual,
  isCreditCard,
  hasNoMinMonthlyPayment,
  TAccount,
  TAccountType,
} from '@/domain/debts';

import i18n from '@/libs/i18n';

import { or, not } from '@/util/function';

import { TActivity } from '@/pages/DebtPlan/PaymentPlan/PaymentPlanTabs/PlanTab/activities';

import type { InitialContextT } from '../stateMachines';

export type TAllocatedPayments = Partial<TPaymentPlan> & {
  debtId: string;
  suggestedAmount: number;
  optimalSuggestedAmount?: number;
  amount: number;
  remainingFunds?: number;
  totalAmountPaid?: number;
};
export type TPaymentPlanStatus = keyof typeof PAYMENT_PLAN_STATUSES;
export type TPaymentPlan = {
  userId: string;
  month: string;
  year: string;
  amountsPaid: TAmountsPaid;
  availableFunds: number;
  status: TPaymentPlanStatus;
  // eslint-disable-next-line camelcase
  task_getFreeCreditCounseling: boolean;
  // eslint-disable-next-line camelcase
  task_callAllYourCreditors: boolean;
  // eslint-disable-next-line camelcase
  popover_dismissedMarkAsPaid: boolean;
  // eslint-disable-next-line camelcase
  popover_dismissedProgress: boolean;
  // eslint-disable-next-line camelcase
  popover_dismissedOtherPaybackPlanOption: boolean;
  isDisclaimerDisplayed: boolean;
  hasUpdatedAvailableFunds: boolean;
  activities: TActivity[];
  dateCreated: number;
  dateUpdated: number;
};
export type TAmountsPaid = TAmountsPaidItem[];
export type TAmountsPaidItem = {
  debtId?: string;
  debt?: TAccount;
  title?: string;
  changeTag?: number | null;
  checked?: boolean;
  suggestedAmount?: number;
  optimalSuggestedAmount?: number;
  amount?: number;
  hasContactedCreditor?: boolean;
  minMonthlyPayment?: number;
  statementBalance?: number;
};
export type TDisqualificationReasons = (typeof DISQUALIFICATION_REASONS)[keyof typeof DISQUALIFICATION_REASONS];

// Debt level conditions
export const hasPayment = ({ amount = 0 }) => amount > 0;
export const hasSuggestedPayment = ({ suggestedAmount = 0 }) => suggestedAmount > 0;
export const hasOptimalSuggestedPayment = ({ optimalSuggestedAmount = 0 }) => optimalSuggestedAmount > 0;
export const hasMinMonthlyPayment = ({ minMonthlyPayment = 0 }) => minMonthlyPayment > 0;
export const hasStatementBalance = ({ statementBalance = 0 }) => statementBalance > 0;
export const isExactlyOptimalPaid = ({ amount = 0, optimalSuggestedAmount = 0 }) => amount === optimalSuggestedAmount;
export const isLessThanOptimalPaid = ({ amount = 0, optimalSuggestedAmount = 0 }) => amount < optimalSuggestedAmount;
export const isMoreThanOptimalPaid = ({ amount = 0, optimalSuggestedAmount = 0 }) => amount > optimalSuggestedAmount;
export const isSuggestedToPayMoreThanRequired = ({ amount = 0, suggestedAmount = 0, minMonthlyPayment = 0 }) =>
  amount + suggestedAmount > minMonthlyPayment;
export const isRequiredAmountPaid = ({ amount = 0, minMonthlyPayment = 0 }) => amount >= minMonthlyPayment;
export const isSuggestedAmountPaid = ({ amount = 0, suggestedAmount = 0 }) =>
  hasSuggestedPayment({ suggestedAmount }) && amount >= suggestedAmount;
export const isOptimalSuggestedAmountPaid = ({ amount = 0, optimalSuggestedAmount = 0 }) =>
  hasOptimalSuggestedPayment({ optimalSuggestedAmount }) && amount >= optimalSuggestedAmount;
export const isAccountPending = ({ status }) => status === ACCOUNT_STATUSES.PENDING;
export const isSuggestedToPayOff = ({ suggestedAmount = 0, statementBalance = 0 }) =>
  suggestedAmount === statementBalance && hasStatementBalance({ statementBalance });
export const isPaidOff = ({ amount = 0, statementBalance = 0, status }) =>
  !isAccountPending({ status }) && (amount >= statementBalance || !hasStatementBalance({ statementBalance }));
export const isAccountPaidOffByDebtId = (amountsPaid: TAmountsPaid, debtId: string) =>
  amountsPaid.some(amountPaid =>
    amountPaid.debt ? isPaidOff({ ...amountPaid, ...amountPaid.debt }) && amountPaid.debtId === debtId : false
  );
export const isPotentiallyPaidOff = ({
  lastPaymentMade,
  statementBalance,
  type,
}: {
  lastPaymentMade: number;
  statementBalance: number;
  type: TAccountType;
}) => !isCreditCard({ type }) && (lastPaymentMade >= statementBalance || !hasStatementBalance({ statementBalance }));
export const canRequiredAmountBeCovered = or(isRequiredAmountPaid, hasSuggestedPayment);
export const getRemainingMinPaymentForAccount = ({ minMonthlyPayment }, amount = 0) => {
  const remaining = minMonthlyPayment - amount;
  return Math.max(remaining, 0);
};
export const compareEqualAndPaid = (accountA: TAccount, accountB: TAccount) => {
  if (!areAccountsEqual(accountA, accountB)) {
    return 0;
  }

  return accountB.amount - accountA.amount;
};
export const compareSuggestedAmount = (accountA: TAmountsPaidItem, accountB: TAmountsPaidItem) => {
  return (accountB.suggestedAmount ?? 0) - (accountA.suggestedAmount ?? 0);
};
export const compareDifferenceInAmountPaidAndOptimimalSuggestedAmount = (
  accountA: TAmountsPaidItem,
  accountB: TAmountsPaidItem
) => {
  return (
    Math.abs((accountB.amount ?? 0) - (accountB.optimalSuggestedAmount ?? 0)) -
    Math.abs((accountA.amount ?? 0) - (accountA.optimalSuggestedAmount ?? 0))
  );
};

// Grouped accounts types identifiers
export const ACCOUNTS_TYPES = {
  ESSENTIAL_ACCOUNTS: 'essentialAccounts',
  OTHER_ACCOUNTS: 'otherAccounts',
  INSUFFICIENT_FUNDS_ACCOUNTS: 'insufficientFundsAccounts',
  PAID_OFF_ACCOUNTS: 'paidOffAccounts',
} as const;

export type TAccountTypes = keyof typeof ACCOUNTS_TYPES;

export const ACCOUNT_GROUP_LABELS_I18N_KEY = {
  [ACCOUNTS_TYPES.ESSENTIAL_ACCOUNTS]: 'heading.essentials',
  [ACCOUNTS_TYPES.OTHER_ACCOUNTS]: 'heading.debts',
  [ACCOUNTS_TYPES.INSUFFICIENT_FUNDS_ACCOUNTS]: 'heading.insufficient-funds',
  [ACCOUNTS_TYPES.PAID_OFF_ACCOUNTS]: 'heading.paid-off-amount',
};

export const mapCreditorAgreementTypeToLabel = (accountType: TAccountTypes) => {
  return i18n.t(ACCOUNT_GROUP_LABELS_I18N_KEY[accountType]);
};

// Disqualification Reasons
export const DISQUALIFICATION_REASONS = {
  DEBT_DELINQUENT: 'debtDelinquent',
  CREDITORS_CONTACTED_NOT_COVER_MOST_OF_MIN_MONTHLY_PAYMENTS: 'creditorsContactedNotCoverMostOfMinMonthlyPayments',
  NOT_COVER_MIN_MONTHLY_PAYMENTS: 'notCoverMinMonthlyPayments',
};

// Account title animation names
export const ANIMATION_NAMES = {
  CHANGE_TAG: 'changeTag',
  INSUFFICIENT_FUNDS: 'insufficientFunds',
};

/**
 * Calculates the available funds based on the amounts you've paid and the remaining funds.
 * @deprecated Not being used currently, may be removed in future PR
 */
export const calculateAvailableFundsIncludingRemainingFundsTowardsUnpaidAccounts = (
  amountsPaid: TAmountsPaid,
  remainingFundsTowardsUnpaidAccounts: number
) =>
  Number(
    amountsPaid
      .reduce((sum, account) => {
        return sum + (account.amount ?? 0);
      }, remainingFundsTowardsUnpaidAccounts)
      .toFixed(2)
  );

/**
 * Transforms the accounts into  amountsPaid required by the back end.
 * @param {*} accounts
 */
export const serializeAccounts = (accounts: TAmountsPaid) =>
  accounts.map(account => ({
    debtId: account.debtId,
    amount: account.amount || 0,
    hasContactedCreditor: false,
  }));

/**
 * Sum of min monthly payments for all debts.
 */
export function calculateMinMonthlyPayments(debts: TAccount[], debtFilter?: (debt: TAccount) => boolean) {
  return debts.reduce((sum, debt) => {
    if (typeof debtFilter === 'function') {
      if (debtFilter(debt) === true) {
        return sum + debt.minMonthlyPayment;
      }
      return sum;
    }
    return sum + debt.minMonthlyPayment;
  }, 0);
}

/**
 * Sum of statementBalance for all debts.
 */
export function calculateSumOfStatementBalance(debts: TAccount[]) {
  return debts.reduce((total, debt) => total + debt.statementBalance, 0);
}

/**
 * Sum of amounts paid for all debts.
 */
export function calculateSumOfAmountsPaid(debts: TAccount[]) {
  return debts.reduce((total, debt) => total + debt.amount, 0);
}

/**
 * Sum of 100% min monthly payments for essentials and 90% for non essentials.
 */
export function calculateSumOfMostOfMinMonthlyPayments(debts: TAccount[]) {
  const minMonthlyPaymentsForEssentials = calculateMinMonthlyPayments(debts, isEssentialAccount);
  const minMonthlyPaymentsForNonEssentials = calculateMinMonthlyPayments(debts, not(isEssentialAccount));

  return minMonthlyPaymentsForEssentials + minMonthlyPaymentsForNonEssentials * 0.9;
}

export function calculateFundsThatCoverMinMonthlyPayments(availableFunds: number, debts: TAccount[]) {
  const sumOfMinMonthlyPayments = calculateMinMonthlyPayments(debts);
  const amountToCoverMinMonthlyPayments = availableFunds - sumOfMinMonthlyPayments;

  // this means there is enough of available funds
  if (amountToCoverMinMonthlyPayments >= 0) {
    return 0;
  }

  return Math.abs(amountToCoverMinMonthlyPayments);
}

export const hasPreviousMonthsPaymentPlan = ({ archivedPaymentPlans }: { archivedPaymentPlans: TPaymentPlan[] }) => {
  return archivedPaymentPlans?.length > 0;
};

/**
 * Available funds should cover the sum of min monthly payments
 */
export function doAvailableFundsCoverMinMonthlyPayments(availableFunds: number, minMonthlyPayments: number) {
  return availableFunds >= minMonthlyPayments;
}

/**
 * Check if the creditor has been contacted for all accounts
 * that haven't been paid off yet.
 * @param {array} amountsPaid
 */
export function haveAllCreditorsBeenContacted(amountsPaid: TAmountsPaid) {
  return amountsPaid
    .filter(
      ({ debt, amount }) =>
        !isPaidOff({ amount, statementBalance: debt?.statementBalance, status: debt?.status }) &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        !hasNoMinMonthlyPayment(debt!)
    )
    .every(({ hasContactedCreditor }) => hasContactedCreditor === true);
}

/**
 * Checks if any of the debts provided as input contains a debt that has been delinquent for a number of days depending if it's essential or not.
 */
export function isAnyDebtDelinquent(debts: TAccount[]) {
  return debts.some(debt => {
    if (debt.isDelinquent && debt.daysDelinquent) {
      return isOverAccountDelinquencyThreshold(debt);
    }

    return false;
  });
}

/**
 * Checks for delinquent debts, ignores the thresholds.
 */
export function isAnyDebtDelinquentWithoutThreshold(debts: TAccount[]) {
  return debts.some(debt => {
    if (debt.isDelinquent && debt.daysDelinquent) {
      return true;
    }

    return false;
  });
}

export const isPaymentPlanStatesIdle: ActionFunction<InitialContextT, EventObject> = (_, __, { state }) => {
  const stateValues = state.toStrings();
  return stateValues.every(value => !value.includes('updatingAmountPaidAndUpsertTransactionFromDebtEditing'));
};

export const hasUpdatedAvailableFunds = ({ paymentPlan }: { paymentPlan: TPaymentPlan }) =>
  !!paymentPlan?.hasUpdatedAvailableFunds;

export const isDisclaimerDisplayed = ({ paymentPlan }: { paymentPlan: TPaymentPlan }) =>
  !!paymentPlan?.isDisclaimerDisplayed;
export const checkIfAnyDebtsPresent = ({ debts }: { debts: TAccount[] }) => debts && debts.length > 0;
export const checkIfAnyDebtsPending = ({ debts }: { debts: TAccount[] }) =>
  debts.some(debt => debt.status === ACCOUNT_STATUSES.PENDING);
export const checkIfAnyPaymentsMade = ({ paymentPlan }: { paymentPlan: TPaymentPlan | null }) =>
  !!paymentPlan && paymentPlan.amountsPaid.some(payment => (payment.amount ?? 0) > 0);

export const checkIfAnyDelinquenciesPresent = ({ debts }: { debts: TAccount[] }) => isAnyDebtDelinquent(debts);

export const checkIfAvailableFundsCoverMinMonthlyPayments = ({
  paymentPlan,
  debts,
}: {
  paymentPlan: TPaymentPlan;
  debts: TAccount[];
}) => {
  const minMonthlyPayments = calculateMinMonthlyPayments(debts);
  return doAvailableFundsCoverMinMonthlyPayments(paymentPlan.availableFunds, minMonthlyPayments);
};

export const checkIfAvailableFundsCoverMostOfMinMonthlyPayments = ({
  paymentPlan,
  debts,
}: {
  paymentPlan: TPaymentPlan;
  debts: TAccount[];
}) => {
  const sumOfMostOfMinMonthlyPayments = calculateSumOfMostOfMinMonthlyPayments(debts);
  return doAvailableFundsCoverMinMonthlyPayments(paymentPlan.availableFunds, sumOfMostOfMinMonthlyPayments);
};

export const checkIfAllCreditorsHaveBeenContacted = ({ paymentPlan }: { paymentPlan: TPaymentPlan }) => {
  return haveAllCreditorsBeenContacted(paymentPlan.amountsPaid);
};

export function mergePaymentPlanWithDebts({
  paymentPlan,
  debts,
  allocatedPayments,
}: Pick<InitialContextT, 'paymentPlan' | 'debts' | 'allocatedPayments'>) {
  const { totalAmountPaid, remainingFunds } = allocatedPayments as TAllocatedPayments;

  const amountsPaid = debts.map(debt => {
    const amountPaid = paymentPlan ? paymentPlan.amountsPaid.find(item => item.debtId === debt.debtId) : {};
    const allocatedPayment = allocatedPayments?.amountsPaid?.find(item => item.debtId === debt.debtId);

    return {
      // Provide a safe fallback for amount and hasContactedCreditor
      amount: 0,
      ...amountPaid,
      hasContactedCreditor: amountPaid?.hasContactedCreditor || false,
      debt,
      debtId: debt.debtId,
      suggestedAmount: allocatedPayment ? allocatedPayment.suggestedAmount : 0,
      optimalSuggestedAmount: allocatedPayment ? allocatedPayment.optimalSuggestedAmount : 0,
    };
  });

  return { ...paymentPlan, amountsPaid, remainingFunds, totalAmountPaid };
}

export function checkIfAnyReasonsForDisqualificationPresent({
  reasonsForDisqualification,
}: {
  reasonsForDisqualification: TDisqualificationReasons[];
}) {
  return reasonsForDisqualification.length > 0;
}

export function checkIfAnyReasonsForInsufficientFundsPresent(reasons: TDisqualificationReasons[]) {
  return reasons.some(
    reason =>
      reason === DISQUALIFICATION_REASONS.NOT_COVER_MIN_MONTHLY_PAYMENTS ||
      reason === DISQUALIFICATION_REASONS.CREDITORS_CONTACTED_NOT_COVER_MOST_OF_MIN_MONTHLY_PAYMENTS
  );
}

export function getReasonsForDisqualification(debts: TAccount[], paymentPlan: TPaymentPlan) {
  const reasonsForDisqualification: TDisqualificationReasons[] = [];

  if (checkIfAnyDelinquenciesPresent({ debts })) {
    reasonsForDisqualification.push(DISQUALIFICATION_REASONS.DEBT_DELINQUENT);
  }
  // note that we are passing debtsPlan as paymentPlan here, this should be addressed within AB-3246
  if (!checkIfAvailableFundsCoverMinMonthlyPayments({ paymentPlan, debts })) {
    if (checkIfAllCreditorsHaveBeenContacted({ paymentPlan })) {
      if (!checkIfAvailableFundsCoverMostOfMinMonthlyPayments({ paymentPlan, debts })) {
        reasonsForDisqualification.push(
          DISQUALIFICATION_REASONS.CREDITORS_CONTACTED_NOT_COVER_MOST_OF_MIN_MONTHLY_PAYMENTS
        );
      }
    } else {
      reasonsForDisqualification.push(DISQUALIFICATION_REASONS.NOT_COVER_MIN_MONTHLY_PAYMENTS);
    }
  }

  return reasonsForDisqualification;
}

export function assignReasonsForDisqualification(context: InitialContextT) {
  // TODO: AB-3246: We need a safer way to retrieve the combined debts / paymentPlan data
  const debtsPlan = mergePaymentPlanWithDebts(context);
  const { debts } = context;
  // @ts-expect-error Fix debtsPlan type info
  const reasonsForDisqualification = getReasonsForDisqualification(debts, debtsPlan);

  return { reasonsForDisqualification };
}

// Debts level conditions
export const hasMultipleNonEssentialAccounts = (debts: TAccount[]) => debts.filter(not(isEssentialAccount)).length > 1;
export const canAllRequiredAmountsBeCovered = (debts: TAccount[]) => debts.every(canRequiredAmountBeCovered);
export const isAnAccountRequiredAmountNotAbleToBeCovered = (debts: TAccount[]) =>
  debts.some(not(canRequiredAmountBeCovered));
export const areAnyAccountsPaid = (debts: TAccount[]) => debts.some(hasPayment);

export const wasPlanNotVisited = (paymentPlan: TPaymentPlan) =>
  paymentPlan.status === PAYMENT_PLAN_STATUSES.NOT_VISITED;

export const getDebtNicknameById = (debts: TAccount[], id: string) => {
  const debtFound = debts.find(debt => debt.debtId === id);

  return debtFound?.nickname;
};
