/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';

import { getAnswer } from '@/domain/questions/util';

import { WizardContext } from '@/components/QuestionsWizard/StepWrapper';
import BackNext from '@/components/QuestionsWizard/BackNext';
import FormBoilerplate from '@/components/FormBoilerplate';
import { createGetInitialFormValues } from '@/components/FormBoilerplate/util';

import classes from './styles.module.less';

// can't break getAnswer, so we create a wrapper around it
const wrappedGetAnswer = (question, answers) => {
  const answersWrapper = { answers };

  return getAnswer(question, answersWrapper);
};
// provide the selector that knows how to read values from state
export const getInitialFormValues = createGetInitialFormValues(wrappedGetAnswer);

/**
 * Reads WizardContext to auto-wire state related to wizard flows. Used throughout solution 1.
 */

export type WizardFormT = {
  children?: ReactNode;
  backButton?: boolean;
  initialValues?: {
    [key: string | number | symbol]: string | number;
  };
  fullWidth?: boolean;
};
const WizardForm: FunctionComponent<WizardFormT> = ({
  children,
  backButton = true,
  initialValues,
  fullWidth = false,
}) => {
  const { skeleton, stepId, state, handleSubmit } = React.useContext(WizardContext);

  const { questions } = skeleton.steps[stepId];
  const renderQuestions =
    skeleton.steps[stepId].renderQuestions === undefined ? true : skeleton.steps[stepId].renderQuestions;

  return (
    <div className={cx(classes.wizardForm, { [classes.fullWidth]: fullWidth })}>
      <FormBoilerplate
        name={stepId}
        state={state.answers}
        handleSubmit={handleSubmit}
        backButton={backButton && BackNext}
        initialValues={initialValues}
        getInitialFormValues={getInitialFormValues}
        questions={questions}
        renderQuestions={renderQuestions}
      >
        {children}
      </FormBoilerplate>
    </div>
  );
};

export default WizardForm;
