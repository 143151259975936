/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { Card } from 'semantic-ui-react';

import Image from '@/components/Image';
import Heading from '@/components/Heading';

import successImage from '@/images/Success-Icon.svg';

import classes from './YourPersonalAssessmentCardContent.module.less';

const SuccessCard = () => {
  const { t } = useTranslation();
  return (
    <Card fluid className={classes.card}>
      <Image src={successImage} centered size="small" className={classes.successImage} />
      <Card.Content textAlign="left">
        <Heading as="h5">{t('success')}!</Heading>
        <Card.Description textAlign="left">{t('emailed-assessment')}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default SuccessCard;
