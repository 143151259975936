/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { VERTICALS_NAMES } from '@/domain/verticals';
import {
  MY_PLAN_PATH,
  HOME_CONTACT_YOUR_LANDLORD_AA3_PATH,
  HOME_CONTACT_YOUR_LANDLORD_AB3_PATH,
  HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_AA2_PATH,
  HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_AB2_PATH,
  HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_BA1_PATH,
  HOME_FILE_RENTERS_INSURANCE_CLAIM_AA4_PATH,
  HOME_UNDERSTAND_THE_CLAIMS_PROCESS_BA3_PATH,
  HOME_UNDERSTAND_YOUR_INSURANCE_BA2_PATH,
  HOME_UNDERSTAND_YOUR_RIGHTS_AA1_PATH,
  HOME_UNDERSTAND_YOUR_RIGHTS_AB1_PATH,
} from '@/domain/paths';
import { renting, ownHome, isInsured, isUninsured } from '@/domain/questions/home/selectors';

import { trans } from '@/util/i18n';

import contactYourLandlord from '@/pages/content/home/images/contactYourLandlord.svg';
import documentTheIssueAndTrackExpenses from '@/pages/content/home/images/documentTheIssueAndTrackExpenses.svg';
import fileRentersInsuranceClaim from '@/pages/content/home/images/fileRentersInsuranceClaim.svg';
import understandTheClaimsProcess from '@/pages/content/home/images/understandTheClaimsProcess.svg';
import understandYourInsurance from '@/pages/content/home/images/understandYourInsurance.svg';
import understandYourRights from '@/pages/content/home/images/understandYourRights.svg';

const HomeContactYourLandlordAA3 = React.lazy(() => import('@/pages/content/home/HomeContactYourLandlordAA3'));
const HomeContactYourLandlordAB3 = React.lazy(() => import('@/pages/content/home/HomeContactYourLandlordAB3'));
const HomeFileRentersInsuranceClaimAA4 = React.lazy(() =>
  import('@/pages/content/home/HomeFileRentersInsuranceClaimAA4')
);
const HomeDocumentTheIssueAndTrackExpensesAA2 = React.lazy(() =>
  import('@/pages/content/home/HomeDocumentTheIssueAndTrackExpensesAA2')
);
const HomeDocumentTheIssueAndTrackExpensesAB2 = React.lazy(() =>
  import('@/pages/content/home/HomeDocumentTheIssueAndTrackExpensesAB2')
);
const HomeDocumentTheIssueAndTrackExpensesBA1 = React.lazy(() =>
  import('@/pages/content/home/HomeDocumentTheIssueAndTrackExpensesBA1')
);

const HomeUnderstandTheClaimsProcessBA3 = React.lazy(() =>
  import('@/pages/content/home/HomeUnderstandTheClaimsProcessBA3')
);
const HomeUnderstandYourInsuranceBA2 = React.lazy(() => import('@/pages/content/home/HomeUnderstandYourInsuranceBA2'));
const HomeUnderstandYourRightsAA1 = React.lazy(() => import('@/pages/content/home/HomeUnderstandYourRightsAA1'));
const HomeUnderstandYourRightsAB1 = React.lazy(() => import('@/pages/content/home/HomeUnderstandYourRightsAB1'));

const cards = [
  {
    id: 'HomeUnderstandYourRightsAA1',
    image: understandYourRights,
    title: trans('i18n-unplanned:know-your-rights'),
    shortText: trans('i18n-unplanned:know-your-rights.short-text'),
    text: trans('i18n-unplanned:know-your-rights.text'),
    infoBox: {
      content: trans('i18n-unplanned:know-your-rights.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_UNDERSTAND_YOUR_RIGHTS_AA1_PATH}`,
    },
    route: {
      component: HomeUnderstandYourRightsAA1,
    },
    showIf: [renting, isInsured],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeDocumentTheIssueAndTrackExpensesAA2',
    image: documentTheIssueAndTrackExpenses,
    title: trans('i18n-unplanned:document-issue-track'),
    shortText: trans('i18n-unplanned:document-issue-track.short-text'),
    text: trans('i18n-unplanned:document-issue-track.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:document-issue-track.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_AA2_PATH}`,
    },
    route: {
      component: HomeDocumentTheIssueAndTrackExpensesAA2,
    },
    showIf: [renting, isInsured],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeContactYourLandlordAA3',
    image: contactYourLandlord,
    title: trans('i18n-unplanned:contact-your-landlord'),
    shortText: trans('i18n-unplanned:contact-your-landlord.short-text'),
    text: trans('i18n-unplanned:contact-your-landlord.text'),
    infoBox: {
      content: trans('i18n-unplanned:contact-your-landlord.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_CONTACT_YOUR_LANDLORD_AA3_PATH}`,
    },
    route: {
      component: HomeContactYourLandlordAA3,
    },
    showIf: [renting, isInsured],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeFileRentersInsuranceClaimAA4',
    image: fileRentersInsuranceClaim,
    title: trans('i18n-unplanned:file-renters-insurance'),
    shortText: trans('i18n-unplanned:file-renters-insurance.short-text'),
    text: trans('i18n-unplanned:file-renters-insurance.text'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_FILE_RENTERS_INSURANCE_CLAIM_AA4_PATH}`,
    },
    route: {
      component: HomeFileRentersInsuranceClaimAA4,
    },
    showIf: [renting, isInsured],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeUnderstandYourRightsAB1',
    image: understandYourRights,
    title: trans('i18n-unplanned:know-your-rights'),
    shortText: trans('i18n-unplanned:know-your-rights.short-text'),
    text: trans('i18n-unplanned:know-your-rights.text'),
    infoBox: {
      content: trans('i18n-unplanned:know-your-rights.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_UNDERSTAND_YOUR_RIGHTS_AB1_PATH}`,
    },
    route: {
      component: HomeUnderstandYourRightsAB1,
    },
    showIf: [renting, isUninsured],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeDocumentTheIssueAndTrackExpensesAB2',
    image: documentTheIssueAndTrackExpenses,
    title: trans('i18n-unplanned:document-issue-track'),
    shortText: trans('i18n-unplanned:document-issue-track.short-text'),
    text: trans('i18n-unplanned:document-issue-track.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:document-issue-track.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_AB2_PATH}`,
    },
    route: {
      component: HomeDocumentTheIssueAndTrackExpensesAB2,
    },
    showIf: [renting, isUninsured],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeContactYourLandlordAB3',
    image: contactYourLandlord,
    title: trans('i18n-unplanned:contact-your-landlord'),
    shortText: trans('i18n-unplanned:contact-your-landlord.short-text'),
    text: trans('i18n-unplanned:contact-your-landlord.text'),
    infoBox: {
      content: trans('i18n-unplanned:contact-your-landlord.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_CONTACT_YOUR_LANDLORD_AB3_PATH}`,
    },
    route: {
      component: HomeContactYourLandlordAB3,
    },
    showIf: [renting, isUninsured],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeDocumentTheIssueAndTrackExpensesBA1',
    image: documentTheIssueAndTrackExpenses,
    title: trans('i18n-unplanned:document-issue-track'),
    shortText: trans('i18n-unplanned:document-issue-track.short-text'),
    text: trans('i18n-unplanned:document-issue-track.text-2'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_DOCUMENT_THE_ISSUE_AND_TRACK_EXPENSES_BA1_PATH}`,
    },
    route: {
      component: HomeDocumentTheIssueAndTrackExpensesBA1,
    },
    showIf: [ownHome],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeUnderstandYourInsuranceBA2',
    image: understandYourInsurance,
    title: trans('i18n-unplanned:understand-your-insurance'),
    shortText: trans('i18n-unplanned:understand-your-insurance.short-text'),
    text: trans('i18n-unplanned:understand-your-insurance.text'),
    infoBox: {
      content: trans('i18n-unplanned:understand-your-insurance.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_UNDERSTAND_YOUR_INSURANCE_BA2_PATH}`,
    },
    route: {
      component: HomeUnderstandYourInsuranceBA2,
    },
    showIf: [ownHome],
    verticals: [VERTICALS_NAMES.HOME],
  },
  {
    id: 'HomeUnderstandTheClaimsProcessBA3',
    image: understandTheClaimsProcess,
    title: trans('i18n-unplanned:understand-claim-process'),
    shortText: trans('i18n-unplanned:understand-claim-process.short-text-2'),
    text: trans('i18n-unplanned:understand-claim-process.text'),
    infoBox: {
      content: trans('i18n-unplanned:understand-claim-process.info'),
    },
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${HOME_UNDERSTAND_THE_CLAIMS_PROCESS_BA3_PATH}`,
    },
    route: {
      component: HomeUnderstandTheClaimsProcessBA3,
    },
    showIf: [ownHome],
    verticals: [VERTICALS_NAMES.HOME],
  },
];

export default cards;
