import React from 'react';

import { FeatureFlagsContext } from '@/services/featureFlags';

import { noop } from '@/util/function';

import { StateDispatch, TProvider } from '@/types/common';

type ContextT = {
  isClosed: boolean;
  setClose: StateDispatch<boolean>;
  isBannerEnabled: boolean;
};

const initialContext: ContextT = {
  isClosed: false,
  setClose: noop,
  isBannerEnabled: false,
};

export const BannerContext = React.createContext(initialContext);

const BannerProvider: TProvider = ({ children }) => {
  const [isClosed, setClose] = React.useState(false);
  const { isFeatureEnabled: _isFeatureEnabled } = React.useContext(FeatureFlagsContext);
  // const isBannerEnabled = isFeatureEnabled('ABACUS_FLAG_BANNER');
  const isBannerEnabled = false; // TODO: remove this line when the feature flag is properly set up

  // TODO: Fix this eslint error
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <BannerContext.Provider value={{ isClosed, setClose, isBannerEnabled }}>{children}</BannerContext.Provider>;
};

export default BannerProvider;
