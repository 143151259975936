/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import getRedirectParams from '@/domain/redirectPaths';

import useUserState from '@/hooks/useUserState';
import useTimeout from '@/hooks/useTimeout';

import { AnalyticsContext } from '@/services/analytics';

import Image from '@/components/Image';
import Divider from '@/components/Divider';
import VerticalSpacing from '@/components/VerticalSpacing';
import Link from '@/components/link';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import CustomContainer from '@/components/CustomContainer';

import logo from '@/images/logo.svg';

import classes from './styles.module.less';

const RedirectScreen = ({
  match: {
    params: { name },
  },
}) => {
  const { t } = useTranslation();
  const { state, loading, error } = useUserState();
  const { url, timeout } = getRedirectParams(name, state);
  const { trackEvent } = React.useContext(AnalyticsContext);
  function redirectTo() {
    trackEvent({
      name: 'redirectTo',
      payload: {
        url,
      },
    });
    window.location = url;
  }
  const { start } = useTimeout(redirectTo, timeout);
  const hideInterstitial = timeout === 0;
  if (loading) return null;
  if (error) return null;

  if (url !== '' && !hideInterstitial) {
    // Fire up the timeout and redirection only if there is an url and not the default empty value
    start();
  } else if (url !== '' && hideInterstitial) {
    redirectTo();
  } else {
    /* eslint-disable-next-line no-console */
    console.error('Is not an url'); // @TODO handle error
  }

  return hideInterstitial ? null : (
    <CustomContainer size="medium">
      <VerticalSpacing />
      <div className={classes.headerAndLogoWrapper}>
        <Image src={logo} size="medium" />
        <Heading as="h1" textAlign="center">
          {t('youre-off')}
        </Heading>
      </div>
      <Paragraph size="large" textAlign="center" fluid noMargin>
        {t('youre-off.paragraph')} <Link to={url}>{url}</Link>
      </Paragraph>
      <Divider hidden />
    </CustomContainer>
  );
};

export default withRouter(RedirectScreen);
