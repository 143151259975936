/* eslint-disable */
// @ts-nocheck
import createQuestionFactory from '@/domain/questions/createQuestionFactory';
import { getAnswerValidationRules } from '@/domain/questions/validation';

import i18n from '@/libs/i18n';

import { trans } from '@/util/i18n';

import CardCheckboxField from '@/components/FormField/CardCheckboxField';
import SelectField from '@/components/FormField/SelectField';
import SelectItemWithDescription from '@/components/Field/SelectItemWithDescription';

import piggyBankImg from '@/pages/Welcome/images/piggy-bank.png'

const fundingQuestionFactory = createQuestionFactory('funding', getAnswerValidationRules);

export const assetsQ = fundingQuestionFactory({
  name: 'assets',
  title: trans('i18n-questions:assets.microcopy'),
  component: CardCheckboxField,
  defaultProps: {
    alternate: true,
    fields: [
      {
        value: 'hasSavings',
        checked: false,
        image: piggyBankImg,
        header: trans('i18n-questions:assets.field-1.header'),
        description: trans('i18n-questions:assets.field-1.description'),
        'data-testid': 'hasSavings',
      },
      {
        value: 'hasInvestment',
        checked: false,
        image: piggyBankImg,
        header: trans('i18n-questions:assets.field-2.header'),
        description: trans('i18n-questions:assets.field-2.description'),
        'data-testid': 'hasInvestment',
      },
      {
        value: 'hasRetirement',
        checked: false,
        image: piggyBankImg,
        header: trans('i18n-questions:assets.field-3.header'),
        description: trans('i18n-questions:assets.field-3.description'),
        'data-testid': 'hasRetirement',
      },
      {
        value: 'hasInsurance',
        checked: false,
        image: piggyBankImg,
        header: trans('i18n-questions:assets.field-4.header'),
        'data-testid': 'hasInsurance',
      },
      {
        value: 'hasHome',
        checked: false,
        image: piggyBankImg,
        header: trans('i18n-questions:assets.field-5.header'),
        'data-testid': 'hasHome',
      },

      {
        value: 'hasNothing',
        checked: false,
        clearCheckboxes: true,
        image: piggyBankImg,
        header: trans('i18n-questions:assets.field-6.header'),
        'data-testid': 'hasNothing',
      },
    ],
  },
});

export const retirementQ = fundingQuestionFactory({
  name: 'retirement',
  title: trans('i18n-questions:retirement.microcopy'),
  component: CardCheckboxField,
  defaultProps: {
    alternate: true,
    fields: [
      {
        value: 'hasIra',
        checked: false,
        image: piggyBankImg,
        header: trans('i18n-questions:retirement.field-1.header'),
        description: trans('i18n-questions:retirement.field-1.description'),
      },
      {
        value: 'hasCurrentEmployer',
        checked: false,
        image: piggyBankImg,
        header: trans('i18n-questions:retirement.field-2.header'),
        description: trans('i18n-questions:retirement.field-2.description'),
        'data-testid': 'hasCurrentEmployer',
      },
      {
        value: 'hasPastEmployer',
        checked: false,
        image: piggyBankImg,
        header: trans('i18n-questions:retirement.field-3.header'),
        description: trans('i18n-questions:retirement.field-3.description'),
      },
    ],
  },
});

export const creditScoreQ = fundingQuestionFactory({
  name: 'creditScore',
  title: trans('i18n-questions:credit-score.microcopy'),
  component: SelectField,
  defaultProps: {
    search: false,
    options: [
      {
        key: 'excellent',
        text: trans('i18n-questions:credit-score.option-1'),
        value: 'excellent',
      },
      {
        key: 'veryGood',
        text: trans('i18n-questions:credit-score.option-2'),
        value: 'veryGood',
      },
      {
        key: 'good',
        text: trans('i18n-questions:credit-score.option-3'),
        value: 'good',
      },
      {
        key: 'fair',
        text: trans('i18n-questions:credit-score.option-4'),
        value: 'fair',
      },
      {
        key: 'poor',
        text: trans('i18n-questions:credit-score.option-5'),
        value: 'poor',
      },
      {
        key: 'dontKnow',
        text: trans('i18n-questions:credit-score.option-6'),
        value: 'dontKnow',
      },
    ],
    placeholder: () => i18n.t('i18n-questions:credit-score.placeholder'),
  },
});

export const debtManagementQ = fundingQuestionFactory({
  name: 'debtManagement',
  component: SelectField,
  defaultProps: {
    search: false, // disable search for this select input
    options: [
      {
        key: 'dontHaveDebts',
        text: trans('i18n-questions:debt-management.option-1'),
        value: 'dontHaveDebts',
      },
      {
        key: 'manageable',
        text: trans('i18n-questions:debt-management.option-2'),
        value: 'manageable',
        content: (
          <SelectItemWithDescription
            title={trans('i18n-questions:debt-management.option-2')}
            description={trans('i18n-questions:debt-management.option-2.description')}
          />
        ),
      },
      {
        key: 'bitMore',
        text: trans('i18n-questions:debt-management.option-3'),
        value: 'bitMore',
        content: (
          <SelectItemWithDescription
            title={trans('i18n-questions:debt-management.option-3')}
            description={trans('i18n-questions:debt-management.option-3.description')}
          />
        ),
      },
      {
        key: 'farMore',
        text: trans('i18n-questions:debt-management.option-4'),
        value: 'farMore',
        content: (
          <SelectItemWithDescription
            title={trans('i18n-questions:debt-management.option-4')}
            description={trans('i18n-questions:debt-management.option-4.description')}
          />
        ),
      },
    ],
    placeholder: () => i18n.t('i18n-questions:debt-management.placeholder'),
  },
});
