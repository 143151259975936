/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';

import ratioOfMonthlyPaymentToMonthlyIncome from '@/domain/calcs/common/affordability';
import monthlyLoanPaymentCalc from '@/domain/calcs/common/calculateMonthlyLoanPayment';
import totalInterestPaidCalc from '@/domain/calcs/personalLoans/calculateTotalInterestPaid';

const parseValues = values => {
  const newValues = {
    ...values,
  };

  // need to make sure these are numbers since they
  // may be strings when they come from inputs
  if (newValues.loanPaybackTerm) {
    newValues.loanPaybackTerm = Number(newValues.loanPaybackTerm);
  }

  // need to turn percentages that come from form fields as
  // 6.5 into 0.065 for math
  if (newValues.loanInterestRate) {
    newValues.loanInterestRate /= 100;
  }

  return newValues;
};

export const calculateMonthlyLoanPayment = createSelector(
  parseValues,
  ({ loanAmount, loanInterestRate, loanPaybackTerm }) =>
    monthlyLoanPaymentCalc({
      loanAmount,
      loanInterestRate,
      loanPaybackTerm,
    })
);

export const calculateTotalInterestPaid = createSelector(
  parseValues,
  ({ loanAmount, monthlyLoanPayment, loanPaybackTerm }) =>
    totalInterestPaidCalc({
      loanAmount,
      monthlyLoanPayment,
      loanPaybackTerm,
    })
);

export const calculateAffordability = createSelector(parseValues, ({ monthlyLoanPayment, monthlyHouseholdIncome }) =>
  ratioOfMonthlyPaymentToMonthlyIncome({
    monthlyLoanPayment,
    monthlyHouseholdIncome,
  })
);
