import Big from 'big.js';

import { CALC_PRECISION } from '@/domain/constants';

import {
  TPromoPeriodAMTPaidAndRemainingBalanceParams,
  TPromoPeriodAMTPaidAndRemainingBalanceResult,
} from '@/types/domain/calcs';

/**
 * Given a fixed monthly payment, promo interest rate, and promo duration,
 * calculate the amount of the credit card charge that was paid off during the promo period.
 */
const calcPromoPeriodAMTPaidAndRemainingBalance = ({
  chargeAmount,
  duringPromoInterestRate,
  promoPeriodDuration,
  monthlyPayment,
}: TPromoPeriodAMTPaidAndRemainingBalanceParams): TPromoPeriodAMTPaidAndRemainingBalanceResult => {
  /**
   * Inputs mappings
   * CHARGE AMOUNT <$-AMT>
   * PROMO PERIOD INTEREST RATE (% APR) <%-PROMO-RATE>
   * PROMO PERIOD DURATION (MONTHS) <PROMO-MONTHS>
   * POST-PROMO INTEREST RATE (% APR) >%-POST-PROMO-RATE>
   * INTEREST RATE (% APR) <%-INTEREST-RATE>
   * MONTHLY PAYMENT <$-MONTHLY>
   */

  /**
   * PROMO PERIOD AMOUNT PAID	Calc
   * uses:
   * (<$-MONTHLY>/30)*(((1+(<%-PROMO-RATE>/365))^(<PROMO-MONTHS>*30) - 1)/(<%-PROMO-RATE>/365))
   */

  const base = Big(duringPromoInterestRate).div(365).plus(1);

  const exponent = Number(Big(promoPeriodDuration).times(30).toFixed(CALC_PRECISION));

  const promoPeriodAmountPaid =
    duringPromoInterestRate === 0
      ? Big(monthlyPayment).times(Big(promoPeriodDuration))
      : base.pow(exponent).minus(1).div(Big(duringPromoInterestRate).div(365)).times(Big(monthlyPayment).div(30));

  /**
   * FUTURE VALUE OF PROMO PERIOD BALANCE calc.
   * uses:
   * (<$-AMT>*(1+<%-PROMO-RATE>/12)^<PROMO-MONTHS>)
   */

  const futureValuePromoPeriodBalance = Big(duringPromoInterestRate)
    .div(12)
    .plus(1)
    .pow(promoPeriodDuration)
    .times(chargeAmount);

  /**
   * POST PROMO PERIOD REMAINING BALANCE Calc
   */
  const postPromoPeriodRemainingBalance = promoPeriodAmountPaid.gte(futureValuePromoPeriodBalance)
    ? Big(0)
    : futureValuePromoPeriodBalance.minus(promoPeriodAmountPaid);

  /**
   *PROMO MONTHS USED
   *
   * Original code:
   * const promoMonthsUsed = postPromoPeriodRemainingBalance.gt(0)
   * ? promoPeriodDuration
   * : futureValuePromoPeriodBalance.div(monthlyPayment);
   *
   * if we paid more than what the FV of the loan is after the promo period,
   * it means we paid the loan off sometime during the promo period. To get
   * the exact number of months used to do so, we need to normalize the amount paid.
   */
  const promoMonthsUsed = postPromoPeriodRemainingBalance.gt(0)
    ? promoPeriodDuration
    : futureValuePromoPeriodBalance.div(promoPeriodAmountPaid).times(promoPeriodDuration);

  return {
    promoPeriodAmountPaid,
    futureValuePromoPeriodBalance,
    postPromoPeriodRemainingBalance,
    promoMonthsUsed,
  };
};

export default calcPromoPeriodAMTPaidAndRemainingBalance;
