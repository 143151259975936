/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';
import { ACCOUNT_STATUSES, ACCOUNT_TYPES } from 'common/model/debt';

const debtSizeSelectorFactory = (debtTypes, goalType) => {
  return createSelector(
    (state, props) => ({ state, props }),
    ({ state }) => {
      const { debts, goals } = state;
      const userInputDebtSize = goalType && goals[goalType] ? goals[goalType].goalData?.answers?.debtSize : 0;
      const debtSize =
        debts.reduce((totalDebtSizeCC, debt) => {
          if (debt.status === ACCOUNT_STATUSES.ACTIVE && debtTypes.includes(debt.type)) {
            return totalDebtSizeCC + debt.statementBalance;
          }
          return totalDebtSizeCC;
        }, 0) || userInputDebtSize;

      return {
        ...state,
        debtSize,
      };
    }
  );
};
export const creditCardDebtSizeSelector = debtSizeSelectorFactory(
  [ACCOUNT_TYPES.CREDIT_CARD],
  'payOffCreditCardDebtMeasured'
);
export const otherDebtSizeSelector = debtSizeSelectorFactory(
  [
    ACCOUNT_TYPES.MORTGAGE,
    ACCOUNT_TYPES.HOME_EQUITY_LOAN,
    ACCOUNT_TYPES.AUTO_LOAN,
    ACCOUNT_TYPES.PERSONAL_LOAN,
    ACCOUNT_TYPES.MEDICAL_BILL,
  ],
  'payOffOtherDebtMeasured'
);
export const studentDebtSizeSelector = debtSizeSelectorFactory(
  [ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN, ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN],
  'payOffStudentLoanDebtMeasured'
);

export const currentExpensesSize = createSelector(
  (state, props) => ({ state, props }),
  ({ state }) => {
    const { budget } = state;
    const expensesSize = Object.keys(budget?.expenses || {}).reduce((expensesAcc, expense) => {
      if (expense !== '__typename') {
        return expensesAcc + parseFloat(budget.expenses[expense]);
      }

      return expensesAcc;
    }, 0);

    return {
      ...state,
      expensesSize,
    };
  }
);
