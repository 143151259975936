export enum ToastType {
  WARN = 'warn',
  ERROR = 'error',
  DEFAULT = '',
}

export type TNotificationObject = {
  id?: string;
  key?: string;
  type?: ToastType;
  delay?: number;
  action?: () => void;
  content?: JSX.Element | string;
  actionContent?: string;
};

export type TNotify = (notification: TNotificationObject) => void;

export type TNotificationContext = {
  notify: TNotify;
  dismiss: (id?: string | null) => void;
};
