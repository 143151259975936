/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FunctionComponent } from 'react';

import Question from '@/components/Question';

import { TObject } from '@/types/common';
import { TQuestionConfig } from '@/types/domain/question';

const areQuestionsEqual = (oldQuestions: TQuestionConfig[], newQuestions: TQuestionConfig[]) => {
  if (oldQuestions.length !== newQuestions.length) {
    return false;
  }

  return oldQuestions.some(
    (oldQuestion, index) =>
      newQuestions[index].id === oldQuestion.id && newQuestions[index].hidden === oldQuestion.hidden
  );
};

/**
 * Given a function that returns an array of question configs, this component feeds the current form and external state
 * into that function and renders a collection of questions. Used to conditionally render questions based on form & external state.
 * Notifies parent that it needs to reinitialize.
 *
 * @typedef Props
 * @property {function} [updateForm] - callback used to reinitialize the form after rendering new questions
 * @property {function} getQuestions - function that returns an array of question configs based on current form state
 * @property {object} values - current form state
 * @property {object} externalState - external state can be passed in to getQuestions
 * @param {Props} props
 */
export type TConditionalQuestionProps = {
  updateForm: Function;
  getQuestions: Function;
  values: TObject<string | number | boolean>;
  externalState?: TObject<string | number | boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const ConditionalQuestion: FunctionComponent<TConditionalQuestionProps> = ({
  updateForm,
  getQuestions,
  values,
  externalState,
  ...rest
}) => {
  const renderedQuestions = React.useRef(getQuestions(values, externalState) || []);
  const currentQuestions = renderedQuestions.current;
  React.useEffect(() => {
    const questions = getQuestions(values, externalState);

    if (!areQuestionsEqual(currentQuestions as TQuestionConfig[], questions as TQuestionConfig[])) {
      renderedQuestions.current = questions;

      if (updateForm) {
        updateForm(values);
      }
    }
  }, [values, externalState, getQuestions, updateForm, currentQuestions]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return currentQuestions.map(questionConfig => (
    <Question key={questionConfig.id} question={questionConfig} {...rest} />
  ));
};

export default ConditionalQuestion;
