import React from 'react';
import { useMachine } from '@xstate/react';

import { getApolloClient } from '@/domain/apollo';
import appMachine, { AppMachineSendT, AppMachineStateT } from '@/domain/stateMachines';

import { AnalyticsContext } from '@/services/analytics';
import { SessionContext } from '@/services/session';
import DebtsPlanProvider from '@/services/debtsPlan';
import { useNotifications } from '@/services/notifications';
import BudgetProvider from '@/services/budget';
import SavingsProvider from '@/services/savings';

type ContextT =
  | Record<string, never>
  | {
      appState: AppMachineStateT;
      sendEvent: AppMachineSendT;
    };

export const AppContext = React.createContext({} as ContextT);

const AppContextProvider = ({ children }) => {
  const { userId, isAuthenticated, shouldRefreshStateMachine, sessionData } = React.useContext(SessionContext);
  const analytics = React.useContext(AnalyticsContext);
  const { notify } = useNotifications();
  const [appState, sendEvent] = useMachine(appMachine);

  React.useEffect(() => {
    if (userId && analytics) {
      sendEvent({ type: 'INITIALIZED', analytics, notify, userId, client: getApolloClient(), sendEvent });
    }
  }, [analytics, notify, sendEvent, userId]);

  React.useEffect(() => {
    if (shouldRefreshStateMachine && isAuthenticated && Object.keys(sessionData).length > 0) {
      sendEvent({ type: 'REINITIALIZE' });
    }
  }, [shouldRefreshStateMachine, isAuthenticated, sessionData, sendEvent]);

  const contextValue = React.useMemo(() => {
    return { appState, sendEvent };
  }, [appState, sendEvent]);

  return (
    <AppContext.Provider value={contextValue}>
      <DebtsPlanProvider appState={appState} sendEvent={sendEvent}>
        <BudgetProvider appState={appState} sendEvent={sendEvent}>
          <SavingsProvider>{children}</SavingsProvider>
        </BudgetProvider>
      </DebtsPlanProvider>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
