import { Extras } from '@sentry/types';
import * as Sentry from '@sentry/browser';

import { envOrDefault } from '@/util/env';

const environment = String(envOrDefault('REACT_APP_SENTRY_ENV', ''));
const dsn = String(envOrDefault('REACT_APP_SENTRY_DSN', ''));
const release = String(envOrDefault('REACT_APP_SENTRY_RELEASE', ''));
const sentryEnabled = dsn && environment;
const allowUrls = ['moneymap.aarp.org/static'];

if (sentryEnabled) {
  if (environment !== 'prod' && environment !== 'uat') {
    /* eslint-disable-next-line no-console */
    console.log('Sentry init:', { dsn, environment, release, allowUrls });
  }

  Sentry.init({ dsn, environment, release, allowUrls });
}

export const captureException = (error: Error, context?: Extras, callback?: (e: string) => void) => {
  Sentry.withScope(scope => {
    if (context) {
      scope.setExtras(context);
    }
    if (callback) {
      callback(Sentry.captureException(error));
    }
  });
};

export default Sentry;
