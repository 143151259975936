import { Location } from 'history';

import { REDIRECT_PATH } from '@/domain/paths';

import { TObject } from '@/types/common';

const HASH_STRING = /#[^?&]*/;
const EXTERNAL_ADDRESS = /^(https?:\/\/)/;
const EXTERNAL_NON_AARP = /^(https?:\/\/(?!www\.aarp\.org)(?!local\.aarpfoundation\.org)(?!help\.aarp\.org))/;
const DOMAIN_ONLY = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im;

export const isValidToLocation = (to: string | Location | TObject<string> | null | undefined) =>
  typeof to === 'string' ||
  (typeof to === 'object' && (to?.pathname != null || to?.hash != null || to?.search != null));

export const isExternalLink = (link: string | unknown) => typeof link === 'string' && !!EXTERNAL_ADDRESS.test(link);

export const isExternalNonAARPLink = (link: string | unknown) =>
  typeof link === 'string' && !!EXTERNAL_NON_AARP.test(link);

export const isRedirectLink = (link: string | unknown) => typeof link === 'string' && link.startsWith(REDIRECT_PATH);

export const isRedirectOrExternalLink = (link: string | unknown) =>
  typeof link === 'string' && (isRedirectLink(link) || isExternalLink(link));

export const isInternalHashLink = (link: string | unknown) =>
  typeof link === 'string' && HASH_STRING.test(link) && !isExternalLink(link);

export const getDomainOnly = link => {
  if (typeof link === 'string') {
    const match = link.match(DOMAIN_ONLY);
    return match?.[0];
  }
  return false;
};

export const getLastUrlSegment = link => {
  if (typeof link === 'string') {
    return link.substring(link.lastIndexOf('/') + 1);
  }
  return null;
};
