/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/button';

import { WizardContext } from './StepWrapper';

import classes from './BackNext.module.less';

/**
 * Onboarding flow navigation buttons - back and next.
 * It uses browser history to navigate when pressing back button.
 */
const BackNext = () => {
  const { t } = useTranslation('i18n-actions');
  const { skeleton, stepId, handleBack, isSubmitting } = React.useContext(WizardContext);
  const nextButtonText = skeleton.steps[stepId].nextButton || t('button.next');
  return (
    <>
      <Button
        secondary
        type="button"
        onClick={handleBack}
        className={classes.backButton}
        compact
        size="small"
        data-testid={`${stepId}-backBtn`}
      >
        {t('button.back')}
      </Button>
      <Button
        primary
        type="submit"
        size="small"
        data-testid={`${stepId}-nextBtn`}
        loading={isSubmitting}
        disabled={isSubmitting}
      >
        {nextButtonText}
      </Button>
    </>
  );
};

export default BackNext;
