import Big from 'big.js';

import { TCalculateTotalInterestPaidParams } from '@/types/domain/calcs';

/**
 * Calculates total interest paid for a personal loan.
 * @param {Object} inputs - Calc inputs.
 * @param {Number} inputs.loanAmount - The loan amount user will receive.
 * @param {Number} inputs.monthlyLoanPayment - The amount paid back monthly.
 * @param {Number} inputs.loanPaybackTerm - Number of years to payback loan.
 * @return {Number} - Total interest paid.
 */
const calculateTotalInterestPaid = ({
  loanAmount,
  monthlyLoanPayment,
  loanPaybackTerm,
}: TCalculateTotalInterestPaidParams): Big | number => {
  const totalAmountPaid = Big(monthlyLoanPayment).times(12).times(loanPaybackTerm);

  const totalInterestPaid = totalAmountPaid.minus(loanAmount);

  return totalInterestPaid;
};

export default calculateTotalInterestPaid;
