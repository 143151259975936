import React from 'react';

import { noop } from '@/util/function';

import { StateDispatch, TProvider } from '@/types/common';

type ContextT = {
  openAuthModal: boolean;
  setAuthModalOpen: StateDispatch<boolean>;
};

const initialState: ContextT = {
  openAuthModal: false,
  setAuthModalOpen: noop,
};

export const AuthContext = React.createContext(initialState);

const AuthProvider: TProvider = ({ children }) => {
  const [openAuthModal, setAuthModalOpen] = React.useState(false);

  // TODO: Fix this eslint error
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <AuthContext.Provider value={{ openAuthModal, setAuthModalOpen }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
