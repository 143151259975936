/* eslint-disable */
// @ts-nocheck
import { useCallback } from 'react';
import cx from 'classnames';

import ConditionalQuestion from '@/components/ConditionalQuestion';
import Question from '@/components/Question';

import { monthlyPaymentQ, promoPeriodQ, promoPeriodConditionalQ } from '../questions';

import { useCreditCardState } from './State';

import classes from './styles.module.less';

const Form = ({ answerWrapper, readOnly }) => {
  const [state, dispatch] = useCreditCardState();

  const onMonthlyPaymentChange = useCallback(() => {
    if (state.suggestedMonthlyPayment != null) {
      dispatch({
        type: 'use-suggested-monthly-payment',
        suggestedMonthlyPayment: null,
      });
    }
  }, [dispatch, state.suggestedMonthlyPayment]);

  return (
    <div
      className={cx(classes.leftSideWrapper, {
        [classes.leftSideWrapperWithSuccess]: state.showUpdateBox,
      })}
    >
      <Question question={monthlyPaymentQ} readOnly={readOnly} onChange={onMonthlyPaymentChange} />
      <Question question={promoPeriodQ} readOnly={readOnly} />
      <ConditionalQuestion
        getQuestions={promoPeriodConditionalQ.getQuestions}
        values={answerWrapper.answers}
        readOnly={readOnly}
      />
    </div>
  );
};

export default Form;
