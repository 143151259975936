import Big from 'big.js';

import { min, max } from '@/util/Big';

import { TLoanAmountParams, TLoanAmountResult } from '@/types/domain/calcs';

/**
 * Calculates the applicable Loan Amount and determine
 * whether the user has enough in their retirement account to borrow the amount needed
 */

const calculateLoanAmount = ({ amountNeeded, retirementAccountBalance }: TLoanAmountParams): TLoanAmountResult => {
  const normalizedLoanAmount = max(10000, Big(retirementAccountBalance).div(2));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const maxAmountUserCanBorrow = min(Big(normalizedLoanAmount!), 50000, retirementAccountBalance);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const loanAmount = min(Big(maxAmountUserCanBorrow!), amountNeeded);

  return { maxAmountUserCanBorrow, loanAmount };
};

export default calculateLoanAmount;
