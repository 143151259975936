import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import cx from 'classnames';

import Paragraph from '@/components/Paragraph';

import PlaceholderLine from '../PlaceholderLine';

import classes from './styles.module.less';

/**
 * This component is specific to empty state present in calcs screens.
 * Design prototype: https://moduscreate.invisionapp.com/d/main#/console/16259490/379685508/inspect
 */
export type TCalcResultsPlaceholderProps = {
  spinner?: boolean;
  text: ReactNode;
};
const CalcResultsPlaceholder: FunctionComponent<TCalcResultsPlaceholderProps> = ({ spinner = false, text }) => {
  const { t } = useTranslation();
  const exactText = text || t('fill-in-info');

  return (
    <div data-testid="calcResultsPlaceholder">
      <Paragraph as="div" className={cx(classes.section, classes.bottomBorder)}>
        <PlaceholderLine />
      </Paragraph>

      <Paragraph as="div" className={cx(classes.section, classes.bottomBorder)}>
        <PlaceholderLine className={classes.spacedPlaceholder} />
        <PlaceholderLine />
      </Paragraph>

      <Paragraph as="div" className={classes.section}>
        <PlaceholderLine className={classes.spacedPlaceholder} />
        <PlaceholderLine className={classes.spacedPlaceholder} />
        <PlaceholderLine className={classes.spacedPlaceholder} />
        {spinner ? (
          <Loader active>{exactText}</Loader>
        ) : (
          <Paragraph size="mini" textAlign="center" className={classes.resultTip}>
            {exactText}
          </Paragraph>
        )}
      </Paragraph>
    </div>
  );
};

export default CalcResultsPlaceholder;
