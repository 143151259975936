import React from 'react';
import { useTranslation } from 'react-i18next';

import { TObject } from '@/types/common';

// Creates t9n within react component. useful for content outside react's reach
export const trans = (key: string | React.JSX.Element, defaultValue: TObject<string | number> | string = '') => {
  const TComp = ({ render }: { render: Function }) => {
    const { t } = useTranslation();

    return render(t);
  };

  return (
    <TComp
      render={(t: Function) => (typeof key === 'string' ? t(key, defaultValue) : key) as string | React.JSX.Element}
    />
  );
};
