import gql from 'graphql-tag';

import { setSafeExpenseValues } from '@/domain/UserState';
import { getCurrentVertical } from '@/domain/questions/selectors';
import { LANDING_PAGE_PATH, MY_PLAN_PATH } from '@/domain/paths';

import { TObject } from '@/types/common';
import { TDefaultUserState } from '@/types/domain/UserStates';

import { VERTICALS_NAMES } from '../verticals';

export const expenseFragment = gql`
  fragment ExpenseMinor on ExpenseOutput {
    expenseId
    vertical
    flowMetadata
    myPlanCompletedSteps
    answers
    dateUpdated
    dateCreated
  }
`;

type TSidebar = {
  sidebar: {
    id: string;
    title: JSX.Element;
    description?: JSX.Element;
  }[];
};

type THeader = {
  renderLeftItem: () => JSX.Element;
  renderCenterItem: () => JSX.Element;
  renderRightItem: () => JSX.Element;
};

type TReducer = (
  state: { stepId: string },
  action: { stepId: string }
) => {
  nextLocation?: {
    pathname: string;
  };
  stepId: string;
};

type TSkeleton = {
  name: string;
  path: string;
  isFlowEnabled: (state: TObject) => boolean;
  assessment: {
    editLink: string;
    title: JSX.Element;
  };
  steps: TObject;
  sidebar: TSidebar;
  header: THeader;
  reducer: TReducer;
  reverseReducer: TReducer;
};

type TExpenseWizardFallbackConfig = {
  rules: {
    condition: (state: TDefaultUserState, skeleton: TSkeleton) => boolean;
    fallbackLocation: string;
  }[];
};

type TMyPlanCompletedSteps =
  | 'MakeSureYoureCoveredAA1'
  | 'EstimateYourOutOfPocketCostsAA2'
  | 'ExplorePrepaymentAndFinancialAidAA3'
  | 'MakeSureYoureCoveredAB1'
  | 'EstimateYourOutOfPocketCostsAB2'
  | 'MakeSureYoureCoveredAC1'
  | 'EstimateYourOutOfPocketCostsAC2'
  | 'ExploreOptionsForGettingInsuredAD1'
  | 'EstimateYourOutOfPocketCostsAD2'
  | 'MakeSureYouWereBilledCorrectlyBA1'
  | 'NegotiateToLowerYourCostsBA2'
  | 'RequestAffordablePaymentPlanBA3'
  | 'MakeSureYouWereBilledCorrectlyBB1'
  | 'RequestAnAffordablePaymentPlanBB2'
  | 'ContactYourStateMedicaidProgramBC1'
  | 'MakeSureYouWereBilledCorrectlyBD1'
  | 'NegotiateToLowerYourCostsBD2'
  | 'RequestAnAffordablePaymentPlanBD3'
  | 'UnderstandYourRightsCA1'
  | 'YouCanStillNegotiateCA2'
  | 'RequestAffordablePaymentPlanCA3'
  | 'ConsiderWorkingWithDebtCollectorCA4'
  | 'UnderstandYourRightsCB1'
  | 'RequestAnAffordablePaymentPlanCB2'
  | 'NegotiateWithTheCollectionAgencyCB3'
  | 'ContactYourStateMedicaidProgramCC1'
  | 'UnderstandYourRightsCD1'
  | 'NegotiateToLowerYourCostsCD2'
  | 'RequestAnAffordablePaymentPlanCD3'
  | 'ConsiderWorkingWithCollectionsCD4'
  | 'SafetyFirstMustFixIssuesAA1'
  | 'UnderstandTheClaimsProcessAA2'
  | 'SafetyFirstMustFixIssuesAB1'
  | 'EstimateYourCostsAB2'
  | 'FindMechanicAB3'
  | 'LookIntoOtherTransportationOptionsAB4'
  | 'EstimateYourCostsBB1'
  | 'FindMechanicBB2'
  | 'LookIntoOtherTransportationOptionsBB3'
  | 'HomeUnderstandYourRightsAA1'
  | 'HomeDocumentTheIssueAndTrackExpensesAA2'
  | 'HomeContactYourLandlordAA3'
  | 'HomeFileRentersInsuranceClaimAA4'
  | 'HomeUnderstandYourRightsAB1'
  | 'HomeDocumentTheIssueAndTrackExpensesAB2'
  | 'HomeContactYourLandlordAB3'
  | 'HomeDocumentTheIssueAndTrackExpensesBA1'
  | 'HomeUnderstandYourInsuranceBA2'
  | 'HomeUnderstandTheClaimsProcessBA3'
  | 'PrioritizeEssentialBillsX1'
  | 'KnowConsequencesUnpaidBillsX2'
  | 'TryNegotiateWithCreditorsX3'
  | 'helpFlow'
  | 'freeFundsFlow'
  | 'fundingFlow'
  | 'paybackStrategy';

type TVertical = (typeof VERTICALS_NAMES)[keyof typeof VERTICALS_NAMES];

export type TExpense = {
  flowMetadata: TObject<string | number | TObject>;
  myPlanCompletedSteps: TMyPlanCompletedSteps;
  answers: TObject;
  vertical: TVertical;
  expenseId: string;
  status: string;
  dateUpdated: number;
  dateCreated: number;
};

export const expenseWizardFallbackConfig: TExpenseWizardFallbackConfig = {
  rules: [
    { condition: (state: TDefaultUserState) => !getCurrentVertical(state), fallbackLocation: LANDING_PAGE_PATH },
    { condition: (state, skeleton) => !skeleton.isFlowEnabled(state), fallbackLocation: MY_PLAN_PATH },
  ],
};

export function serialize(expense: TExpense) {
  const { flowMetadata, myPlanCompletedSteps, answers, vertical } = setSafeExpenseValues(expense);

  return { flowMetadata, myPlanCompletedSteps, answers, vertical };
}

function getLastModifiedTimestamp(expense: TExpense) {
  const { dateUpdated, dateCreated } = expense;
  return dateUpdated || dateCreated;
}

/**
 * Sorts an array of expenses without mutating it. Last modified date is used as criteria.
 * Order is ascending - last item in the list will be the one that was modified/created most recently.
 * @param {Expense[]} expenses
 * @returns {Expense[]}
 */
export function sortByLastModified(expenses: TExpense[]) {
  const expensesCopy = [...expenses];

  return expensesCopy.sort(
    (expenseA, expenseB) => getLastModifiedTimestamp(expenseA) - getLastModifiedTimestamp(expenseB)
  );
}
