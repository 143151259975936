/* eslint-disable */
// @ts-nocheck
import { ActionFunction, EventObject } from 'xstate';
import { sub } from 'date-fns';
import { PAYMENT_PLAN_STATUSES } from 'common/model/paymentPlan';
import { BUDGET_STATUSES } from 'common/model/budget';

import { cleanupCustomExpenses, isBudgetCreated } from '@/domain/budget';

import { savingsTypes } from '@/hooks/useDashboardState';

import { getCurrentMonthYear, getMonthYearInYYYYMMFormat, isDateEqual, MonthYear } from '@/util/date';

import {
  UPSERT_BUDGET,
  UPSERT_BUDGET_AND_UPDATE_PAYMENT_PLAN_AVAILABLE_FUNDS,
  UPSERT_BUDGET_AND_UPDATE_PAYMENT_PLAN_AVAILABLE_FUNDS_NEW_MONTH,
  DELETE_BUDGET,
  UPSERT_TRANSACTIONS,
  CREATE_BUDGET_SNAPSHOT,
} from '@/graphql/mutations';
import {
  GET_BUDGET,
  GET_BUDGET_AND_LIST_TRANSACTIONS,
  GET_COMBINED_DEBTS_PLAN,
  GET_DASHBOARD_DATA,
  LIST_BUDGET_SNAPSHOTS,
} from '@/graphql/queries';
import type { Budget } from '@/types/generated/globalTypes';

import { createPaymentPlan } from '../common/modelsQueries';

import type { InitialContextT } from '..';
import { ApolloQueryResult } from '@apollo/client';
import { removeTypename } from '@/util/object';

type UpsertBudgetEvent = EventObject & {
  // TODO: Merge this properly
  budget: Budget & {
    dateUpdated?: string;
    status: string;
  };
};

type UpsertBudgetAndUpdatePPAvailableFundsEvent = EventObject & {
  budget: Budget & { status: string };
  unpersistedRemainingFundsTowardsUnpaidAccounts: number;
  shouldSkipAmountsPaid: boolean;
};

type UpsertBudgetAndCreatePPWithAvailableFundsEvent = EventObject & {
  budget: Budget & { status: string };
  availableFunds: number;
};

const idleStates = ['onboarding.noAccounts', 'accountsEditing.initial'];

export const isDebtPlanMachineIdle: ActionFunction<InitialContextT, EventObject> = (_, __, { state }) => {
  const stateValues = state.toStrings();
  return stateValues.some(value => idleStates.some(idleState => value.includes(idleState)));
};

export const loadBudget = (context: InitialContextT) => {
  const { client, userId } = context;

  return client.query({
    query: GET_BUDGET,
    variables: {
      userId,
    },
  });
};

// Loads the budget with a network first policy to ensure we get the latest value from the DB
export const loadBudgetAndTransactions = async (context: InitialContextT) => {
  const { client, userId } = context;

  let res: Promise<ApolloQueryResult<any>>;

  try {
    res = await client.query({
      query: GET_BUDGET_AND_LIST_TRANSACTIONS,
      variables: {
        userId,
      },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    //retry once if the request fails
    res = await client.query({
      query: GET_BUDGET_AND_LIST_TRANSACTIONS,
      variables: {
        userId,
      },
      fetchPolicy: 'network-only',
    });
  }

  return res;
};

export const loadArchivedBudgets = (context: InitialContextT, event: EventObject) => {
  const { client, userId } = context;
  const { month, year } = event.payload;

  return client.query({
    query: LIST_BUDGET_SNAPSHOTS,
    variables: {
      userId,
      month,
      year,
    },
  });
};

export const getBudgetStatus = (budget: Budget & { status?: string }) => {
  if (budget.status === BUDGET_STATUSES.DRAFT) {
    if (budget.netMonthlyIncome != null && budget.netMonthlyIncome >= 0) {
      return BUDGET_STATUSES.ACTIVE;
    }

    return BUDGET_STATUSES.DRAFT;
  }

  return BUDGET_STATUSES.ACTIVE;
};

export const cleanupBudget = (budget: Budget) => {
  if (Object.prototype.hasOwnProperty.call(budget, 'dateUpdated')) delete budget.dateUpdated;
  if (Object.prototype.hasOwnProperty.call(budget, 'archivedBudgets')) delete budget.archivedBudgets;

  return budget;
};

export const upsertBudget = async (context: InitialContextT, event: UpsertBudgetEvent) => {
  const { client, userId } = context;
  let { budget, navigate } = event;

  budget = cleanupBudget(budget);

  budget.status = getBudgetStatus(budget);

  if (budget.customExpenses) budget.customExpenses = cleanupCustomExpenses(budget.customExpenses);

  const optimisticBudgetResponse = {
    ...budget,
    ...(budget.expenses && { expenses: { ...budget.expenses, __typename: 'ExpensesOutput' } }),
    ...(!budget.monthlyModalLastDisplayed && { monthlyModalLastDisplayed: null }),
    ...(!budget.incompleteDebtExpensesModalDateDisplayed && { incompleteDebtExpensesModalDateDisplayed: null }),
    ...(!budget.updatedFromSavingsFlag && { updatedFromSavingsFlag: null }),
    ...(!budget.updatedFromDebtManagerFlag && { updatedFromDebtManagerFlag: null }),
    dateUpdated: Date.now(),
    lastEdited: Date.now(),
    __typename: 'BudgetOutput',
  };

  const response = await client.mutate({
    mutation: UPSERT_BUDGET,
    optimisticResponse: {
      upsertBudget: {
        ...optimisticBudgetResponse,
      },
    },
    variables: {
      input: {
        userId,
        budget,
      },
    },
    awaitRefetchQueries: true,
    // TODO: Move away from refetch queries in the future
    refetchQueries: [
      {
        query: GET_BUDGET,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });

  return { ...response, navigate };
};

export const clearBudget = (context: InitialContextT) => {
  const { client, userId } = context;

  return client.mutate({
    mutation: DELETE_BUDGET,
    optimisticResponse: {
      deleteBudget: {
        budget: null,
        __typename: 'BudgetOutput',
      },
    },
    variables: {
      userId,
    },
    update: cache => {
      cache.writeQuery({
        query: GET_BUDGET,
        variables: { userId },
        data: { budget: null },
      });
    },
  });
};

export const upsertBudgetAndCreatePaymentPlanWithAvailableFunds = (
  context: InitialContextT,
  event: UpsertBudgetAndCreatePPWithAvailableFundsEvent
) => {
  let { budget } = event;
  let { availableFunds } = event;

  budget = cleanupBudget(budget);
  budget.status = getBudgetStatus(budget);

  if (budget.status === BUDGET_STATUSES.DRAFT) availableFunds = 0;

  const createPaymentPlanCall = createPaymentPlan(context, { availableFunds });
  const upsertBudgetCall = upsertBudget(context, { budget });

  return Promise.all([createPaymentPlanCall, upsertBudgetCall]).then(values => {
    const data = {};
    values.forEach(value => {
      const { createPaymentPlan: createPaymentPlanResults, upsertBudget: upsertBudgetResults } = value.data;
      if (createPaymentPlanResults) {
        data.createPaymentPlan = createPaymentPlanResults;
      }
      if (upsertBudgetResults) {
        data.upsertBudget = upsertBudgetResults;
      }
    });

    return { data };
  });
};

export const upsertBudgetAndUpdatePaymentPlanAvailableFunds = (
  context: InitialContextT,
  event: UpsertBudgetAndUpdatePPAvailableFundsEvent
) => {
  const { client, userId, selectedDate, paymentPlan } = context;
  let { budget } = event;
  const {
    unpersistedRemainingFundsTowardsUnpaidAccounts,
    shouldSkipAmountsPaid,
    isMonthTransitionResetFlow = false,
  } = event;

  budget = cleanupBudget(budget);
  budget.status = getBudgetStatus(budget);

  if (!budget.customExpenses && context.budget?.customExpenses) {
    const debts = context.debts;

    // hacky fix to get the debt expenses to show up in the custom expenses list
    if (debts.length) {
      const debtsAsExpenses = debts
        .filter(({ debtBudget }) => !!debtBudget)
        .map(({ type, debtId, nickname, debtBudget }) => ({
          name: nickname,
          bucket: type,
          amount: debtBudget,
          id: debtId,
          category: 'DEBT_PAYMENTS',
        }));

      budget.customExpenses = [...debtsAsExpenses];
    }
  }

  const availableFunds = Number(unpersistedRemainingFundsTowardsUnpaidAccounts.toFixed(2));
  const placeholderPaymentPlanInfo = {
    ...(paymentPlan || {}),
    amountsPaid: paymentPlan?.amountsPaid || [],
    availableFunds,
    status: paymentPlan?.status || PAYMENT_PLAN_STATUSES.DRAFT,
    dateCreated: null,
    dateUpdated: null,
    popover_dismissedMarkAsPaid: null,
    popover_dismissedOtherPaybackPlanOption: null,
    popover_dismissedProgress: null,
    task_callAllYourCreditors: null,
    task_getFreeCreditCounseling: null,
  };

  const optimisticResponse = {
    upsertBudgetAndUpdatePaymentPlanAvailableFunds: {
      budget: {
        ...budget,
        dateUpdated: Date.now(),
        expenses: {
          ...budget.expenses,
          __typename: 'ExpensesOutput',
        },
        lastEdited: Date.now(),
        __typename: 'BudgetOutput',
      },
      paymentPlan: {
        ...placeholderPaymentPlanInfo,
        userId,
        year: selectedDate.year,
        month: selectedDate.month,
        __typename: 'PaymentPlanOutput',
      },
      debts: [],
      __typename: 'BudgetPaymentPlanOutput',
    },
  };

  /**
   * this condition is to handle the month transition flow
   * when the user resets their budget we need to first advance the payment plan
   * to the next month then we can update the budget and payment plan
   * to prevent a situation where the payment plan being updated does not exist
   * so we are calling two mutations here
   *   first - advanceToCurrentMonth
   *   last - upsertBudgetAndUpdatePaymentPlanAvailableFunds
   */
  if (isMonthTransitionResetFlow) {
    return client.mutate({
      mutation: UPSERT_BUDGET_AND_UPDATE_PAYMENT_PLAN_AVAILABLE_FUNDS_NEW_MONTH,
      optimisticResponse: {
        ...optimisticResponse,
        advanceToCurrentMonth: {
          ...placeholderPaymentPlanInfo,
          userId,
          year: selectedDate.year,
          month: selectedDate.month,
          __typename: 'PaymentPlanOutput',
        },
      },
      variables: {
        userId,
        planToAdvanceFrom: {
          month: paymentPlan?.month,
          year: paymentPlan?.year,
        },
        input: {
          userId,
          budget,
          remainingFundsTowardsUnpaidAccounts: availableFunds,
          year: selectedDate.year,
          month: selectedDate.month,
          shouldSkipAmountsPaid,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_BUDGET,
          variables: {
            userId,
          },
        },
      ],
    });
  }

  return client.mutate({
    mutation: UPSERT_BUDGET_AND_UPDATE_PAYMENT_PLAN_AVAILABLE_FUNDS,
    optimisticResponse,
    variables: {
      input: {
        userId,
        budget,
        remainingFundsTowardsUnpaidAccounts: availableFunds,
        year: selectedDate.year,
        month: selectedDate.month,
        shouldSkipAmountsPaid,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_BUDGET,
        variables: {
          userId,
        },
      },
      {
        query: GET_COMBINED_DEBTS_PLAN,
        variables: {
          userId,
        },
      },
    ],
  });
};

export const upsertTransactions = async (context: InitialContextT, event) => {
  const { client, userId, budget } = context;
  const { payload, navigate } = event;

  const { transactions: contextTransactions } = budget;
  const { year, month, list } = payload;
  const [transaction] = list;

  const date = new Date();
  const lastMonthDate = sub(date, { months: 1 });
  const lastMonth = lastMonthDate.getMonth().toString();
  const lastYear = lastMonthDate.getFullYear().toString();

  const foundTransaction = contextTransactions.find(t =>
    isDateEqual({ year: t.year || '', month: t.month || '' }, { year, month })
  );

  const currentTransactionList = foundTransaction?.list || list;

  const currentTransactionInput = {
    year,
    month,
    list: foundTransaction ? [...currentTransactionList, transaction] : currentTransactionList,
  };

  const response = await client.mutate({
    mutation: UPSERT_TRANSACTIONS,
    optimisticResponse: {
      upsertTransactions: {
        userId,
        dateCreated: foundTransaction?.dateCreated || new Date().getTime(),
        dateUpdated: new Date().getTime(),
        list,
        year,
        month,
        __typename: 'TransactionsOutput',
      },
    },
    variables: {
      input: {
        userId,
        transactions: currentTransactionInput,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_BUDGET_AND_LIST_TRANSACTIONS,
        variables: {
          userId,
        },
      },
      {
        query: GET_DASHBOARD_DATA,
        variables: {
          userId,
          lastMonth,
          lastYear,
          expenses: savingsTypes,
        },
      },
    ],
  });

  return { ...response, navigate: navigate };
};

export const editTransactions = async (
  context: InitialContextT,
  event: EventObject & { payload: MonthYear & { transaction: Transaction } }
) => {
  const { client, userId, budget } = context;
  const { transactions: contextTransactions } = budget;
  const { payload } = event;
  const combineMutationCalls = [];

  const { year, month, transaction, selectedTransactionMonth, selectedTransactionYear, transactionIdx } = payload;

  const selectedMonthTransactions = contextTransactions.find(t =>
    isDateEqual(
      { year: t.year || '', month: t.month || '' },
      { year: selectedTransactionYear, month: selectedTransactionMonth }
    )
  );

  if (selectedMonthTransactions == null) return;

  // sort transactions
  selectedMonthTransactions.list.sort((a, b) => b.date - a.date);

  const currentTransactionInput = {
    year: selectedTransactionYear,
    month: selectedTransactionMonth,
  } as Transactions;

  if (selectedTransactionYear === year && selectedTransactionMonth === month) {
    currentTransactionInput.list =
      selectedMonthTransactions.list?.map((currentTransaction, index) =>
        index === transactionIdx ? transaction : currentTransaction
      ) || [];
  } else {
    currentTransactionInput.list = selectedMonthTransactions.list?.filter((_, index) => index !== transactionIdx) || [];

    const pastMonthTransactions = contextTransactions.find(t =>
      isDateEqual({ year: t.year || '', month: t.month || '' }, { year, month })
    );

    combineMutationCalls.unshift(
      client.mutate({
        mutation: UPSERT_TRANSACTIONS,
        optimisticResponse: {
          upsertTransactions: {
            userId,
            dateCreated: budget?.transactions?.dateCreated || new Date().getTime(),
            dateUpdated: new Date().getTime(),
            list: [...(pastMonthTransactions?.list || []), transaction],
            year: payload.year,
            month: payload.month,
            __typename: 'TransactionsOutput',
          },
        },
        variables: {
          input: {
            userId,
            transactions: {
              year,
              month,
              list: [...(pastMonthTransactions?.list || []), transaction],
            },
          },
        },
      })
    );
  }

  combineMutationCalls.unshift(
    client.mutate({
      mutation: UPSERT_TRANSACTIONS,
      optimisticResponse: {
        upsertTransactions: {
          userId,
          dateCreated: budget?.transactions?.dateCreated || new Date().getTime(),
          dateUpdated: new Date().getTime(),
          list: currentTransactionInput.list,
          year: currentTransactionInput.year,
          month: currentTransactionInput.month,
          __typename: 'TransactionsOutput',
        },
      },
      variables: {
        input: {
          userId,
          transactions: currentTransactionInput,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_BUDGET_AND_LIST_TRANSACTIONS,
          variables: {
            userId,
          },
        },
      ],
    })
  );

  const response = await Promise.all(combineMutationCalls);
  return { data: { upsertTransactions: response.map(mutationResult => mutationResult.data.upsertTransactions) } };
};

export const removeTransaction = (context: InitialContextT, event) => {
  const { client, userId, budget } = context;
  const { payload } = event;

  const { year, month, list } = payload;

  const currentTransactionInput = {
    year,
    month,
    list,
  };

  return client.mutate({
    mutation: UPSERT_TRANSACTIONS,
    optimisticResponse: {
      upsertTransactions: {
        userId,
        dateCreated: budget?.transactions?.dateCreated || new Date().getTime(),
        dateUpdated: new Date().getTime(),
        list,
        year,
        month,
        __typename: 'TransactionsOutput',
      },
    },
    variables: {
      input: {
        userId,
        transactions: currentTransactionInput,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_BUDGET_AND_LIST_TRANSACTIONS,
        variables: {
          userId,
        },
      },
    ],
  });
};

export const createBudgetSnapshot = async (context: InitialContextT) => {
  const { client, userId } = context;

  const res = await client.mutate({
    mutation: CREATE_BUDGET_SNAPSHOT,
    variables: {
      input: {
        userId,
      },
    },
  });

  return res;
};

export const upsertBudgetIntegrationFlag = async (context: InitialContextT, event) => {
  const { client, userId, budget } = context;
  const { payload } = event;

  // Resolve early if budget hasn't yet been created
  if (!isBudgetCreated(context)) return Promise.resolve();

  let updatedFromSavingsFlag = payload?.updatedFromSavingsFlag;
  let updatedFromDebtManagerFlag = payload?.updatedFromDebtManagerFlag;

  if (!payload) {
    updatedFromDebtManagerFlag = shouldEnableFlag(context, event).updatedFromDebtManagerFlag;
  }

  // Intentionally extracting these variables from the object
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let { isLoaded, isCreated, __typename, transactions, ...budgetPayload } = budget;

  budgetPayload = cleanupBudget(budgetPayload);

  // creates a new object called budgetPayload without the __typename property for every item in the budget object
  budgetPayload = removeTypename(budgetPayload);

  const res = await client.mutate({
    mutation: UPSERT_BUDGET,
    variables: {
      input: {
        userId,
        budget: {
          status: getBudgetStatus(budgetPayload),
          updatedFromSavingsFlag: updatedFromSavingsFlag ?? false,
          updatedFromDebtManagerFlag,
        },
      },
    },
  });

  return res;
};

/**
 * TODO: Update this to handle savings as well
 */
export const shouldEnableFlag = (context: InitialContextT, event: EventObject & { debt: TAccount }) => {
  const { debts } = context;
  const { debt } = event;

  const result = {
    updatedFromDebtManagerFlag: false,
  };

  const storedDebt = debts.find(d => d.debtId === debt.debtId);

  /**
   * We'll update the budget if
   * 1. A new debt has been added
   * 2. The nickname has changed
   * 3. The minMonthlyPayment has changed
   * 4. The statementBalance has changed
   */
  if (
    !storedDebt ||
    storedDebt?.nickname !== debt.nickname ||
    storedDebt?.minMonthlyPayment !== debt.minMonthlyPayment ||
    storedDebt?.statementBalance !== debt.statementBalance
  ) {
    result.updatedFromDebtManagerFlag = true;
  }

  return result;
};

export const automatedBudgetTransition = async (context: InitialContextT) => {
  const { budget } = context;

  const { isLoaded, isCreated, __typename, transactions, ...safeBudget } = budget;

  const updatedMonthYear = getMonthYearInYYYYMMFormat(getCurrentMonthYear(), '-');

  const month = updatedMonthYear.split('-')[1];
  const year = updatedMonthYear.split('-')[0];

  const payload = {
    ...safeBudget,
    monthlyModalLastDisplayed: updatedMonthYear,
  };

  const res = await upsertBudget(context, { budget: payload });

  if (!res.errors) {
    console.log(`Budget successfully transitioned to ${month} ${year}`);
  }

  return res;
};

// TODO: finish updating the payment plan for previous months (when debt payments are enabled in add transactions again)
/* const upsertTransactionsAndUpdatePaymentPlanAmountsPaid = async (context, event) => {
    const {
      client,
      userId,
      paymentPlan: contextPaymentPlan,
      archivedPaymentPlans: contextArchivedPaymentPlans,
    } = context;
    const { payload } = event;

    const { year, month, list } = payload;
    const [transaction] = list;

    const isCurrentMonth = isDateEqual(
      { year, month },
      { year: contextPaymentPlan.year, month: contextPaymentPlan.month }
    );

    const paymentPlanSource = isCurrentMonth
      ? contextPaymentPlan
      : contextArchivedPaymentPlans.find(i => i.year === year && i.month === month);

    const matchedExistingDebtAmountsPaidEntry = paymentPlanSource.amountsPaid.find(a => a.debtId === transaction.debtId);

    const updatedAmountsPaidWithExistingEntry = paymentPlanSource.amountsPaid.map(({ __typename, ...item }) =>
      item.debtId === transaction.debtId ? { ...item, amount: item.amount + transaction.amount } : item
    );

    const updatedAmountsPaidWithNewEntry = [
      ...paymentPlanSource.amountsPaid.map(({ __typename, ...item }) => item),
      {
        debtId: transaction.debtId,
        amount: transaction.amount,
        hasContactedCreditor: false,
      },
    ];

    const updatedAmountsPaid = matchedExistingDebtAmountsPaidEntry
      ? updatedAmountsPaidWithExistingEntry
      : updatedAmountsPaidWithNewEntry;

    const updatedPaymentPlan = {
      ...contextPaymentPlan,
      amountsPaid: updatedAmountsPaid,
    };

    const paymentPlanInput = {
      year,
      month,
      amountsPaid: updatedPaymentPlan.amountsPaid,
      availableFunds: paymentPlanSource.availableFunds,
      status: paymentPlanSource.status,
    };

    const upsertTransactionsResult = upsertTransactions(context, event);

    // TODO: do not update the payment plan when there is no archived payment plan entry for that month
    // const matchedArchivedPaymentPlanEntry = contextArchivedPaymentPlans.find(i => i.year === year && i.month === month);

    const updatePaymentPlanResult = client.mutate({
      mutation: UPDATE_PAYMENT_PLAN,
      variables: {
        input: {
          userId,
          paymentPlan: paymentPlanInput,
        },
      },
    });

    return Promise.all(upsertTransactionsResult, updatePaymentPlanResult);
  }; */
