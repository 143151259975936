import ReactDOM from 'react-dom';
import 'core-js/stable';

// We want the application styles to be loaded after the semantic-ui styles
import '@/styling/semantic.less';
import '@/index.css';
import '@/libs/i18n';
import '@/util/Sentry';

import App from './App';
import 'react-circular-progressbar/dist/styles.css';
import reportWebVitals from './analytics/reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
