/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';

import { filterArrayByVerticals } from '@/domain/verticals';
import { getCurrentVertical } from '@/domain/questions/selectors';

import { feedbackStep } from '../FeedbackForm/card';

import situationalSteps from './situationalSteps';
import { coreSteps, authenticatedSuccessMessages } from './content';

const getUserState = state => state;

/** Parametrized selector that defaults to the active vertical. It can be overriden by passing the vertical through props */
const getActiveVertical = (state, props = {}) => (props.vertical ? props.vertical : getCurrentVertical(state));

/**
 * By default returns the situational steps for the currently active vertical. Active vertical can be overriden by passing in
 * a prop like this: getSituationalSteps(state, { vertical: 'NAME_OF_THE_VERTICAL' });
 */
export const getSituationalSteps = createSelector(getUserState, getActiveVertical, (state, vertical) => {
  const cards = filterArrayByVerticals(situationalSteps, item => item.verticals, state).filter(card =>
    card.verticals.includes(vertical)
  );

  return cards.filter(({ showIf }) => !showIf || showIf.every(condition => condition(state) === true));
});

export const getContentRouteConfigs = createSelector(getSituationalSteps, processedSituationalSteps => {
  const cardSteps = [...processedSituationalSteps, ...coreSteps];

  return cardSteps.reduce((routes, card) => {
    const { button, id, route, title } = card;

    if (route) {
      const path = route.path || (button && button.url);

      if (path) {
        routes.push({ id, component: route.component, title, path });
      }
    }

    return routes;
  }, []);
});

const getInitialMyPlanCompletedSteps = state => state.myPlanCompletedSteps;

export const getInitialCompletedSteps = createSelector(getInitialMyPlanCompletedSteps, steps =>
  steps.reduce((completedSteps, step) => {
    if (!completedSteps[step]) {
      completedSteps[step] = {};
    }

    completedSteps[step] = {
      completed: true,
    };

    return completedSteps;
  }, {})
);

const getSteps = state => Object.entries(state.steps);

export const getCompletedSteps = createSelector(getSteps, steps =>
  steps.reduce((completedSteps, step) => {
    const [stepIndex, stepState] = step;
    if (stepState.completed) {
      completedSteps.push(stepIndex);
    }
    return completedSteps;
  }, [])
);

export const getNextSiblingId = state => {
  const { lastCompletedStepId } = state;
  const applicableSituationalSteps = getSituationalSteps(state.userState);
  const cardIds = [...applicableSituationalSteps, ...coreSteps, feedbackStep].map(({ id }) => id);
  const lastCompletedStepIndex = cardIds.indexOf(lastCompletedStepId);
  const nextIndex = lastCompletedStepIndex + 1;

  if (nextIndex <= cardIds.length) {
    return cardIds[nextIndex];
  }

  return null;
};

export const getSuccessMessages = () => {
  return authenticatedSuccessMessages;
};

export const getIsPlanCompleted = createSelector(
  getCompletedSteps,
  state => getSituationalSteps(state.userState),
  (completedSteps, currentPlanSituationalSteps) => {
    const renderedSteps = [...currentPlanSituationalSteps, ...coreSteps];
    const isPlanCompleted = renderedSteps.reduce((planCompletedAcc, situationalStep) => {
      return planCompletedAcc && completedSteps.includes(situationalStep.id);
    }, true);
    return isPlanCompleted;
  }
);
