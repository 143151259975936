export const CONSIDER_WORKING_WITH_DEBT_COLLECTOR = '/content/medical/consider-working-with-collections-ca5';
export const CONSIDER_WORKING_WITH_DEBT_COLLECTOR_CA4_PATH = '/content/medical/consider-working-with-collections-ca4';
export const CONSIDER_WORKING_WITH_COLLECTIONS_CD4_PATH = '/content/medical/consider-working-with-collections-cd4';
export const CONTACT_YOUR_STATE_MEDICAID_PROGRAM_BC1_PATH = '/content/medical/contact-your-states-medicaid-program-bc1';
export const CONTACT_YOUR_STATE_MEDICAID_PROGRAM_CC1_PATH = '/content/medical/contact-your-states-medicaid-program-cc1';
export const ESTIMATE_YOUR_OUT_OF_POCKET_COSTS_AD2_PATH = '/content/medical/estimate-your-out-of-pocket-costs-ad2';
export const ESTIMATE_YOUR_OUT_OF_POCKET_COSTS_AB2_PATH = '/content/medical/estimate-your-out-of-pocket-costs-ab2';
export const ESTIMATE_OUT_OF_POCKET_COSTS_PAGE = '/content/medical/estimate-your-out-of-pocket-costs-aa2';
export const ESTIMATE_OUT_OF_POCKET_COSTS_AA2_PATH = '/content/medical/estimate-your-out-of-pocket-costs-aa2';
export const ESTIMATE_OUT_OF_POCKET_COSTS_AC2_PATH = '/content/medical/estimate-your-out-of-pocket-costs-ac2';
export const EXPLORE_PREPAYMENT_PAGE = '/content/medical/explore-prepayment-and-financial-aid-aa3';
export const EXPLORE_OPTIONS_FOR_GETTING_INSURED_AD1_PATH = '/content/medical/explore-options-for-getting-insured-ad1';
export const MAKE_SURE_YOURE_COVERED_AB1_PATH = '/content/medical/make-sure-youre-covered-ab1';
export const MAKE_SURE_YOURE_COVERED_AA1_PATH = '/content/medical/make-sure-youre-covered-aa1';
export const MAKE_SURE_YOURE_COVERED_AC1_PATH = '/content/medical/make-sure-youre-covered-ac1';
export const MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BA1_PATH = '/content/medical/make-sure-you-were-billed-correctly-ba1';
export const MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BB1_PATH = '/content/medical/make-sure-you-were-billed-correctly-bb1';
export const MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BD1_PATH = '/content/medical/make-sure-you-were-billed-correctly-bd1';
export const NEGOTIATE_TO_LOWER_YOUR_COSTS_BA2_PATH = '/content/medical/negotiate-to-lower-your-costs-ba2';
export const NEGOTIATE_TO_LOWER_YOUR_COSTS_BD2_PATH = '/content/medical/negotiate-to-lower-your-costs-bd2';
export const NEGOTIATE_TO_LOWER_YOUR_COSTS_CD2_PATH = '/content/medical/negotiate-to-lower-your-costs-cd2';
export const REQUEST_AFFORDABLE_PAYMENT_PLAN_BA3_PATH = '/content/medical/request-an-affordable-payment-plan-ba3';
export const REQUEST_AFFORDABLE_PAYMENT_PLAN_CA3_PATH = '/content/medical/request-an-affordable-payment-plan-ca3';
export const REQUEST_AFFORDABLE_PAYMENT_PLAN_BB2_PATH = '/content/medical/request-an-affordable-payment-plan-bb2';
export const REQUEST_AFFORDABLE_PAYMENT_PLAN_BD3_PATH = '/content/medical/request-an-affordable-payment-plan-bd3';
export const REQUEST_AFFORDABLE_PAYMENT_PLAN_CB2_PATH = '/content/medical/request-an-affordable-payment-plan-cb2';
export const REQUEST_AFFORDABLE_PAYMENT_PLAN_CD3_PATH = '/content/medical/request-an-affordable-payment-plan-cd3';
export const UNDERSTAND_YOUR_RIGHTS_CA1_PATH = '/content/medical/understand-your-rights-ca1';
export const UNDERSTAND_YOUR_RIGHTS_CB1_PATH = '/content/medical/understand-your-rights-cb1';
export const UNDERSTAND_YOUR_RIGHTS_CD1_PATH = '/content/medical/understand-your-rights-cd1';
export const UNDERSTAND_THE_CLAIMS_PROCESS_AA2_PATH = '/content/medical/understand-the-claims-process-aa2';
export const YOU_CAN_STILL_NEGOTIATE_CA2_PATH = '/content/medical/you-can-still-negotiate-ca2';
export const NEGOTIATE_WITH_THE_COLLECTION_AGENCY_CB3_PATH =
  '/content/medical/negotiate-with-the-collection-agency-cb3';
