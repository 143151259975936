import Big from 'big.js';

import { TFutureValueOfTheBorrowedFundsIfNotBorrowedParams } from '@/types/domain/calcs';

/**
 * Calculates the future amount in lost savings at time of retirement,
 * from borrowing from a retirement plan
 * (this determines the value of the would-be-borrowed funds at retirement, if not borrowed).
 */
const calculateFVOfTheBorrowedFundsIfNotBorrowed = ({
  loanAmount,
  yearsToRetirement,
  retirementAccountExpectedRateOfReturn,
}: TFutureValueOfTheBorrowedFundsIfNotBorrowedParams): Big | number => {
  // calculate the future value of the LOAN AMOUNT at retirement:
  const exponentialOperation = Big(retirementAccountExpectedRateOfReturn)
    .div(12)
    .plus(1)
    .pow(yearsToRetirement * 12);

  const FVOfTheBorrowedFundsIfNotBorrowed = Big(loanAmount).times(exponentialOperation);

  return FVOfTheBorrowedFundsIfNotBorrowed;
};

export default calculateFVOfTheBorrowedFundsIfNotBorrowed;
