/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { ExpensesContext } from '@/services/expenses';

const useCreateExpense = () => {
  const {
    createExpense,
    expense: { vertical },
  } = React.useContext(ExpensesContext);

  return expense => createExpense({ ...expense, vertical });
};

export default useCreateExpense;
