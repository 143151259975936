/* eslint-disable */
// @ts-nocheck
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'semantic-ui-react';

import { toUSD } from '@/util/currency';

import Button from '@/components/button';
import Currency from '@/components/Currency';
import Paragraph from '@/components/Paragraph';
import { ErrorBox } from '@/components/InformationBox';

import classes from './styles.module.less';

const YesPeriodMonthlyPaymentSuggestions = ({ calculations, onTry }) => {
  const { t } = useTranslation('i18n-credit');
  const yesPromoCTAHandler = useCallback(
    () => onTry && onTry(calculations.suggested.monthlyPayment),
    [calculations.suggested.monthlyPayment, onTry]
  );

  const totalInterestSavings = calculations.totalInterestCost - calculations.suggested.totalInterestCost;

  return (
    <Card className={classes.card}>
      <ErrorBox
        noMargin
        icon="warning sign"
        content={<strong>{t('yes-period-monthly-payment.error-content')}</strong>}
      />
      <Card.Content>
        <Paragraph textAlign="center">
          {t('yes-period-monthly-payment.paragraph', { totalInterestSavings: toUSD(totalInterestSavings, 2) })}
        </Paragraph>
        <Paragraph size="massive" textAlign="center" className={classes.suggestedMonthlyPayment}>
          <Currency serifFont value={calculations.suggested.monthlyPayment} />
        </Paragraph>
        <Paragraph size="tiny" textAlign="center">
          {t('per-month')}
        </Paragraph>
        <Button secondary fluid size="small" type="button" data-testid="yesPromoCTABtn" onClick={yesPromoCTAHandler}>
          {t('try-it')}
        </Button>
      </Card.Content>
    </Card>
  );
};

export default YesPeriodMonthlyPaymentSuggestions;
