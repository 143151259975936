import cx from 'classnames';
import { ErrorMessage } from 'formik';
import React from 'react';

import classes from './styles.module.less';

type FieldErrorMessageProps = {
  className?: string;
  error?: boolean;
  name: string;
  dataTestId?: string;
};

const FieldErrorMessage = ({ className, error, name, dataTestId }: FieldErrorMessageProps) => {
  return error ? (
    <div aria-live="assertive" aria-relevant="additions removals" role="alert">
      <ErrorMessage name={name}>
        {message => {
          return (
            <div
              className={cx(className, classes.errorMessage, classes.animated)}
              data-testid={dataTestId ? `${dataTestId}-errorMessage` : 'errorMessage'}
            >
              {message}
            </div>
          );
        }}
      </ErrorMessage>
    </div>
  ) : null;
};

export default FieldErrorMessage;
