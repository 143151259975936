import React from 'react';
import cx from 'classnames';
import { Image as DefaultImage } from 'semantic-ui-react';

import type { ImageProps } from 'semantic-ui-react';

type TImageProps = ImageProps &
  Pick<HTMLImageElement, 'src'> & {
    onMouseOver?: React.MouseEventHandler<HTMLImageElement>;
    onMouseOut?: React.MouseEventHandler<HTMLImageElement>;
  };

const Image = ({
  alt,
  'aria-hidden': ariaHidden = true,
  centered,
  className,
  'data-testid': dataTestId,
  role = 'presentation',
  rounded,
  size,
  spaced,
  src,
  onMouseOver,
  onMouseOut,
  ...rest
}: TImageProps) => (
  <DefaultImage
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    alt={alt}
    aria-hidden={ariaHidden}
    centered={centered}
    className={cx(className)}
    data-testid={dataTestId}
    role={role}
    rounded={rounded}
    size={size}
    spaced={spaced}
    src={src}
    {...rest}
  />
);

export default Image;
