/* eslint-disable */
// @ts-nocheck

import React, { FunctionComponent, ReactNode } from 'react';
import { Card } from 'semantic-ui-react';

import Image from '@/components/Image';

import classes from './OffsetImageCard.module.less';

const OffsetImageCard: FunctionComponent<{ image?: string; content?: ReactNode }> = ({ image, content }) => {
  return (
    <Card fluid className={classes.card}>
      <Image src={image} className={classes.image} />
      <Card.Content>{content}</Card.Content>
    </Card>
  );
};

export default OffsetImageCard;
