/* eslint-disable */
// @ts-nocheck
import { Card } from 'semantic-ui-react';

import OffsetImageCard from '@/components/OffsetImageCard';

import haveQuestions from './Illustration-Have-Questions.svg';

import classes from './PersonalAssessment.module.less';

/**
 * Component reused across how-to-borrow pages.
 */
const PersonalAssessment = ({ assessmentCard, questionsCard }) => {
  return (
    <div className={classes.cardGroup}>
      {assessmentCard}
      <OffsetImageCard image={haveQuestions} content={questionsCard} />
    </div>
  );
};

export default PersonalAssessment;
