/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { VERTICALS_NAMES } from '@/domain/verticals';
import {
  CONSIDER_WORKING_WITH_DEBT_COLLECTOR_CA4_PATH,
  CONSIDER_WORKING_WITH_COLLECTIONS_CD4_PATH,
  CONTACT_YOUR_STATE_MEDICAID_PROGRAM_BC1_PATH,
  CONTACT_YOUR_STATE_MEDICAID_PROGRAM_CC1_PATH,
  ESTIMATE_OUT_OF_POCKET_COSTS_AA2_PATH,
  ESTIMATE_OUT_OF_POCKET_COSTS_AC2_PATH,
  ESTIMATE_YOUR_OUT_OF_POCKET_COSTS_AB2_PATH,
  ESTIMATE_YOUR_OUT_OF_POCKET_COSTS_AD2_PATH,
  EXPLORE_PREPAYMENT_PAGE,
  EXPLORE_OPTIONS_FOR_GETTING_INSURED_AD1_PATH,
  MAKE_SURE_YOURE_COVERED_AB1_PATH,
  MAKE_SURE_YOURE_COVERED_AA1_PATH,
  MAKE_SURE_YOURE_COVERED_AC1_PATH,
  MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BA1_PATH,
  MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BB1_PATH,
  MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BD1_PATH,
  MY_PLAN_PATH,
  NEGOTIATE_TO_LOWER_YOUR_COSTS_BA2_PATH,
  NEGOTIATE_TO_LOWER_YOUR_COSTS_BD2_PATH,
  NEGOTIATE_TO_LOWER_YOUR_COSTS_CD2_PATH,
  REQUEST_AFFORDABLE_PAYMENT_PLAN_BA3_PATH,
  REQUEST_AFFORDABLE_PAYMENT_PLAN_BB2_PATH,
  REQUEST_AFFORDABLE_PAYMENT_PLAN_BD3_PATH,
  REQUEST_AFFORDABLE_PAYMENT_PLAN_CA3_PATH,
  REQUEST_AFFORDABLE_PAYMENT_PLAN_CB2_PATH,
  REQUEST_AFFORDABLE_PAYMENT_PLAN_CD3_PATH,
  UNDERSTAND_YOUR_RIGHTS_CA1_PATH,
  UNDERSTAND_YOUR_RIGHTS_CB1_PATH,
  UNDERSTAND_YOUR_RIGHTS_CD1_PATH,
  YOU_CAN_STILL_NEGOTIATE_CA2_PATH,
  NEGOTIATE_WITH_THE_COLLECTION_AGENCY_CB3_PATH,
} from '@/domain/paths';
import {
  isPlanningAhead,
  haveReceivedBill,
  billPastDue,
  hasPrivateInsurance,
  hasMedicare,
  hasMedicaid,
  isUninsured,
} from '@/domain/questions/selectors';

import { trans } from '@/util/i18n';

import considerWorkingWithCollections from '@/pages/MyPlan/images/considerWorkingWithCollections.svg';
import contactYourStateMedicaidProgram from '@/pages/MyPlan/images/contactYourStateMedicaidProgram.svg';
import estimateYourOutOfPocketCosts from '@/pages/MyPlan/images/estimateYourOutOfPocketCosts.svg';
import exploreOptionsForGettingInsured from '@/pages/MyPlan/images/exploreOptionsForGettingInsured.svg';
import explorePrepaymentAndFinancialAid from '@/pages/MyPlan/images/explorePrepaymentAndFinancialAid.svg';
import makeSureYouAreCovered from '@/pages/MyPlan/images/makeSureYouAreCovered.svg';
import makeSureYouWereBilledCorrectly from '@/pages/MyPlan/images/makeSureYouWereBilledCorrectly.svg';
import negotiateToLowerYourCosts from '@/pages/MyPlan/images/negotiateToLowerYourCosts.svg';
import requestAnAffordablePaymentPlan from '@/pages/MyPlan/images/requestAnAffordablePaymentPlan.svg';
import understandYourRights from '@/pages/MyPlan/images/understandYourRights.svg';

const ConsiderWorkingWithDebtCollectorCA4 = React.lazy(() =>
  import('@/pages/content/medical/ConsiderWorkingWithDebtCollectorCA4')
);
const ConsiderWorkingWithCollectionsCD4 = React.lazy(() =>
  import('@/pages/content/medical/ConsiderWorkingWithCollectionsCD4')
);
const ContactYourStateMedicaidProgramBC1 = React.lazy(() =>
  import('@/pages/content/medical/ContactYourStateMedicaidProgramBC1')
);
const ContactYourStateMedicaidProgramCC1 = React.lazy(() =>
  import('@/pages/content/medical/ContactYourStateMedicaidProgramCC1')
);
const EstimateYourOutOfPocketCostsAA2 = React.lazy(() =>
  import('@/pages/content/medical/EstimateYourOutOfPocketCostsAA2')
);
const EstimateYourOutOfPocketCostsAB2 = React.lazy(() =>
  import('@/pages/content/medical/EstimateYourOutOfPocketCostsAB2')
);
const EstimateYourOutOfPocketCostsAC2 = React.lazy(() =>
  import('@/pages/content/medical/EstimateYourOutOfPocketCostsAC2')
);
const EstimateYourOutOfPocketCostsAD2 = React.lazy(() =>
  import('@/pages/content/medical/EstimateYourOutOfPocketCostsAD2')
);
const ExploreOptionsForGettingInsuredAD1 = React.lazy(() =>
  import('@/pages/content/medical/ExploreOptionsForGettingInsuredAD1')
);
const ExplorePrepaymentAndFinancialAid = React.lazy(() =>
  import('@/pages/content/medical/ExplorePrepaymentAndFinancialAid')
);
const MakeSureYoureCoveredAA1 = React.lazy(() => import('@/pages/content/medical/MakeSureYoureCoveredAA1'));
const MakeSureYoureCoveredAB1 = React.lazy(() => import('@/pages/content/medical/MakeSureYoureCoveredAB1'));
const MakeSureYoureCoveredAC1 = React.lazy(() => import('@/pages/content/medical/MakeSureYoureCoveredAC1'));
const MakeSureYouWereBilledCorrectlyBA1 = React.lazy(() =>
  import('@/pages/content/medical/MakeSureYouWereBilledCorrectlyBA1')
);
const MakeSureYouWereBilledCorrectlyBD1 = React.lazy(() =>
  import('@/pages/content/medical/MakeSureYouWereBilledCorrectlyBD1')
);
const MakeSureYouWereBilledCorrectlyBB1 = React.lazy(() =>
  import('@/pages/content/medical/MakeSureYouWereBilledCorrectlyBB1')
);
const NegotiateToLowerYourCostsBA2 = React.lazy(() => import('@/pages/content/medical/NegotiateToLowerYourCostsBA2'));
const NegotiateToLowerYourCostsBD2 = React.lazy(() => import('@/pages/content/medical/NegotiateToLowerYourCostsBD2'));
const NegotiateToLowerYourCostsCD2 = React.lazy(() => import('@/pages/content/medical/NegotiateToLowerYourCostsCD2'));
const RequestAffordablePaymentPlanBA3 = React.lazy(() =>
  import('@/pages/content/medical/RequestAffordablePaymentPlanBA3')
);
const RequestAnAffordablePaymentPlanBB2 = React.lazy(() =>
  import('@/pages/content/medical/RequestAnAffordablePaymentPlanBB2')
);
const RequestAnAffordablePaymentPlanBD3 = React.lazy(() =>
  import('@/pages/content/medical/RequestAnAffordablePaymentPlanBD3')
);
const RequestAffordablePaymentPlanCA3 = React.lazy(() =>
  import('@/pages/content/medical/RequestAffordablePaymentPlanCA3')
);
const RequestAnAffordablePaymentPlanCB2 = React.lazy(() =>
  import('@/pages/content/medical/RequestAnAffordablePaymentPlanCB2')
);
const RequestAnAffordablePaymentPlanCD3 = React.lazy(() =>
  import('@/pages/content/medical/RequestAnAffordablePaymentPlanCD3')
);
const UnderstandYourRightsCA1 = React.lazy(() => import('@/pages/content/medical/UnderstandYourRightsCA1'));
const UnderstandYourRightsCB1 = React.lazy(() => import('@/pages/content/medical/UnderstandYourRightsCB1'));
const UnderstandYourRightsCD1 = React.lazy(() => import('@/pages/content/medical/UnderstandYourRightsCD1'));
const YouCanStillNegotiateCA2 = React.lazy(() => import('@/pages/content/medical/YouCanStillNegotiateCA2'));
const NegotiateWithTheCollectionAgencyCB3 = React.lazy(() =>
  import('@/pages/content/medical/NegotiateWithTheCollectionAgencyCB3')
);

const cards = [
  {
    id: 'MakeSureYoureCoveredAA1',
    image: makeSureYouAreCovered,
    title: trans('i18n-unplanned:make-sure-covered.title'),
    shortText: trans('i18n-unplanned:make-sure-covered.short-text'),
    text: trans('i18n-unplanned:make-sure-covered.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:make-sure-covered.info-1'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${MAKE_SURE_YOURE_COVERED_AA1_PATH}`,
    },
    route: {
      component: MakeSureYoureCoveredAA1,
    },
    showIf: [isPlanningAhead, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'EstimateYourOutOfPocketCostsAA2',
    image: estimateYourOutOfPocketCosts,
    title: trans('i18n-unplanned:estimate-pocket-cost.title'),
    shortText: trans('i18n-unplanned:estimate-pocket-cost.short-text-1'),
    text: trans('i18n-unplanned:estimate-pocket-cost.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:estimate-pocket-cost.info-1'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${ESTIMATE_OUT_OF_POCKET_COSTS_AA2_PATH}`,
    },
    route: {
      component: EstimateYourOutOfPocketCostsAA2,
    },
    showIf: [isPlanningAhead, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'ExplorePrepaymentAndFinancialAidAA3',
    image: explorePrepaymentAndFinancialAid,
    title: trans('i18n-unplanned:explore-prepayment.title'),
    shortText: trans('i18n-unplanned:explore-prepayment.short-text'),
    text: trans('i18n-unplanned:explore-prepayment.text'),
    infoBox: {
      content: trans('i18n-unplanned:explore-prepayment.info'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${EXPLORE_PREPAYMENT_PAGE}`,
    },
    route: {
      component: ExplorePrepaymentAndFinancialAid,
    },
    showIf: [isPlanningAhead, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'MakeSureYoureCoveredAB1',
    image: makeSureYouAreCovered,
    title: trans('i18n-unplanned:make-sure-covered.title'),
    shortText: trans('i18n-unplanned:make-sure-covered.short-text'),
    text: trans('i18n-unplanned:make-sure-covered.text-2'),
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${MAKE_SURE_YOURE_COVERED_AB1_PATH}`,
    },
    route: {
      component: MakeSureYoureCoveredAB1,
    },
    showIf: [isPlanningAhead, hasMedicare],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'EstimateYourOutOfPocketCostsAB2',
    image: estimateYourOutOfPocketCosts,
    title: trans('i18n-unplanned:estimate-pocket-cost.title'),
    shortText: trans('i18n-unplanned:estimate-pocket-cost.short-text-1'),
    text: trans('i18n-unplanned:estimate-pocket-cost.text-2'),
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${ESTIMATE_YOUR_OUT_OF_POCKET_COSTS_AB2_PATH}`,
    },
    route: {
      component: EstimateYourOutOfPocketCostsAB2,
    },
    showIf: [isPlanningAhead, hasMedicare],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'MakeSureYoureCoveredAC1',
    image: makeSureYouAreCovered,
    title: trans('i18n-unplanned:make-sure-covered.title'),
    shortText: trans('i18n-unplanned:make-sure-covered.short-text'),
    text: trans('i18n-unplanned:make-sure-covered.text-3'),
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${MAKE_SURE_YOURE_COVERED_AC1_PATH}`,
    },
    route: {
      component: MakeSureYoureCoveredAC1,
    },
    showIf: [isPlanningAhead, hasMedicaid],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'EstimateYourOutOfPocketCostsAC2',
    image: estimateYourOutOfPocketCosts,
    title: trans('i18n-unplanned:estimate-pocket-cost.title'),
    shortText: trans('i18n-unplanned:estimate-pocket-cost.short-text-2'),
    text: trans('i18n-unplanned:estimate-pocket-cost.text-3'),
    infoBox: {
      content: trans('i18n-unplanned:estimate-pocket-cost.info-2'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${ESTIMATE_OUT_OF_POCKET_COSTS_AC2_PATH}`,
    },
    route: {
      component: EstimateYourOutOfPocketCostsAC2,
    },
    showIf: [isPlanningAhead, hasMedicaid],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'ExploreOptionsForGettingInsuredAD1',
    image: exploreOptionsForGettingInsured,
    title: trans('i18n-unplanned:explore-options.title'),
    shortText: trans('i18n-unplanned:explore-options.short-text'),
    text: trans('i18n-unplanned:explore-options.text'),
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${EXPLORE_OPTIONS_FOR_GETTING_INSURED_AD1_PATH}`,
    },
    route: {
      component: ExploreOptionsForGettingInsuredAD1,
    },
    showIf: [isPlanningAhead, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'EstimateYourOutOfPocketCostsAD2',
    image: estimateYourOutOfPocketCosts,
    title: trans('i18n-unplanned:estimate-pocket-cost.title'),
    shortText: trans('i18n-unplanned:estimate-pocket-cost.short-text-3'),
    text: trans('i18n-unplanned:estimate-pocket-cost.text-4'),
    infoBox: {
      content: trans('i18n-unplanned:estimate-pocket-cost.info-3'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${ESTIMATE_YOUR_OUT_OF_POCKET_COSTS_AD2_PATH}`,
    },
    route: {
      component: EstimateYourOutOfPocketCostsAD2,
    },
    showIf: [isPlanningAhead, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'MakeSureYouWereBilledCorrectlyBA1',
    image: makeSureYouWereBilledCorrectly,
    title: trans('i18n-unplanned:billed-correctly.title'),
    shortText: trans('i18n-unplanned:billed-correctly.short-text'),
    text: trans('i18n-unplanned:billed-correctly.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:billed-correctly.info'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BA1_PATH}`,
    },
    route: {
      component: MakeSureYouWereBilledCorrectlyBA1,
    },
    showIf: [haveReceivedBill, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'NegotiateToLowerYourCostsBA2',
    image: negotiateToLowerYourCosts,
    title: trans('i18n-unplanned:negotiate-costs.title-1'),
    shortText: trans('i18n-unplanned:negotiate-costs.short-text-1'),
    text: trans('i18n-unplanned:negotiate-costs.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:negotiate-costs.info-1'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${NEGOTIATE_TO_LOWER_YOUR_COSTS_BA2_PATH}`,
    },
    route: {
      component: NegotiateToLowerYourCostsBA2,
    },
    showIf: [haveReceivedBill, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'RequestAffordablePaymentPlanBA3',
    image: requestAnAffordablePaymentPlan,
    title: trans('i18n-unplanned:request-affordable-payment.title'),
    shortText: trans('i18n-unplanned:request-affordable-payment.short-text-1'),
    text: trans('i18n-unplanned:request-affordable-payment.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:request-affordable-payment.info-1'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${REQUEST_AFFORDABLE_PAYMENT_PLAN_BA3_PATH}`,
    },
    route: {
      component: RequestAffordablePaymentPlanBA3,
    },
    showIf: [haveReceivedBill, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'MakeSureYouWereBilledCorrectlyBB1',
    image: makeSureYouWereBilledCorrectly,
    title: trans('i18n-unplanned:billed-correctly.title'),
    shortText: trans('i18n-unplanned:billed-correctly.short-text'),
    text: trans('i18n-unplanned:billed-correctly.text-2'),
    infoBox: {
      content: trans('i18n-unplanned:billed-correctly.info'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BB1_PATH}`,
    },
    route: {
      component: MakeSureYouWereBilledCorrectlyBB1,
    },
    showIf: [haveReceivedBill, hasMedicare],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'RequestAnAffordablePaymentPlanBB2',
    image: requestAnAffordablePaymentPlan,
    title: trans('i18n-unplanned:request-affordable-payment.title'),
    shortText: trans('i18n-unplanned:request-affordable-payment.short-text-2'),
    text: trans('i18n-unplanned:request-affordable-payment.text-2'),
    infoBox: {
      content: trans('i18n-unplanned:request-affordable-payment.info-2'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${REQUEST_AFFORDABLE_PAYMENT_PLAN_BB2_PATH}`,
    },
    route: {
      component: RequestAnAffordablePaymentPlanBB2,
    },
    showIf: [haveReceivedBill, hasMedicare],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'ContactYourStateMedicaidProgramBC1',
    image: contactYourStateMedicaidProgram,
    title: trans('i18n-unplanned:contact-state-medical.title'),
    shortText: trans('i18n-unplanned:contact-state-medical.short-text-1'),
    text: trans('i18n-unplanned:contact-state-medical.text'),
    infoBox: {
      content: trans('i18n-unplanned:contact-state-medical.info'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${CONTACT_YOUR_STATE_MEDICAID_PROGRAM_BC1_PATH}`,
    },
    route: {
      component: ContactYourStateMedicaidProgramBC1,
    },
    showIf: [haveReceivedBill, hasMedicaid],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'MakeSureYouWereBilledCorrectlyBD1',
    image: makeSureYouWereBilledCorrectly,
    title: trans('i18n-unplanned:billed-correctly.title'),
    shortText: trans('i18n-unplanned:billed-correctly.short-text'),
    text: trans('i18n-unplanned:billed-correctly.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:billed-correctly.info'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${MAKE_SURE_YOU_WERE_BILLED_CORRECTLY_BD1_PATH}`,
    },
    route: {
      component: MakeSureYouWereBilledCorrectlyBD1,
    },
    showIf: [haveReceivedBill, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'NegotiateToLowerYourCostsBD2',
    image: negotiateToLowerYourCosts,
    title: trans('i18n-unplanned:negotiate-costs.title-1'),
    shortText: trans('i18n-unplanned:negotiate-costs.short-text-1'),
    text: trans('i18n-unplanned:negotiate-costs.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:negotiate-costs.info-1'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${NEGOTIATE_TO_LOWER_YOUR_COSTS_BD2_PATH}`,
    },
    route: {
      component: NegotiateToLowerYourCostsBD2,
    },
    showIf: [haveReceivedBill, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'RequestAnAffordablePaymentPlanBD3',
    image: requestAnAffordablePaymentPlan,
    title: trans('i18n-unplanned:request-affordable-payment.title'),
    shortText: trans('i18n-unplanned:request-affordable-payment.short-text-1'),
    text: trans('i18n-unplanned:request-affordable-payment.text-1'),
    infoBox: {
      content: trans('i18n-unplanned:request-affordable-payment.info-1'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${REQUEST_AFFORDABLE_PAYMENT_PLAN_BD3_PATH}`,
    },
    route: {
      component: RequestAnAffordablePaymentPlanBD3,
    },
    showIf: [haveReceivedBill, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'UnderstandYourRightsCA1',
    image: understandYourRights,
    title: trans('i18n-unplanned:understand-rights.title'),
    shortText: trans('i18n-unplanned:understand-rights.short-text'),
    text: trans('i18n-unplanned:understand-rights.text'),
    infoBox: {
      content: trans('i18n-unplanned:understand-rights.info'),
    },
    button: {
      text: trans('learn-more'),
      url: `${MY_PLAN_PATH}${UNDERSTAND_YOUR_RIGHTS_CA1_PATH}`,
    },
    route: {
      component: UnderstandYourRightsCA1,
    },
    showIf: [billPastDue, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'YouCanStillNegotiateCA2',
    image: negotiateToLowerYourCosts,
    title: trans('i18n-unplanned:negotiate-costs.title-2'),
    shortText: trans('i18n-unplanned:negotiate-costs.short-text-2'),
    text: trans('i18n-unplanned:negotiate-costs.text-2'),
    infoBox: {
      content: trans('i18n-unplanned:negotiate-costs.info-2'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${YOU_CAN_STILL_NEGOTIATE_CA2_PATH}`,
    },
    route: {
      component: YouCanStillNegotiateCA2,
    },
    showIf: [billPastDue, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'RequestAffordablePaymentPlanCA3',
    image: requestAnAffordablePaymentPlan,
    title: trans('i18n-unplanned:request-affordable-payment.title'),
    shortText: trans('i18n-unplanned:request-affordable-payment.short-text-1'),
    text: trans('i18n-unplanned:request-affordable-payment.text-3'),
    infoBox: {
      content: trans('i18n-unplanned:request-affordable-payment.info-3'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${REQUEST_AFFORDABLE_PAYMENT_PLAN_CA3_PATH}`,
    },
    route: {
      component: RequestAffordablePaymentPlanCA3,
    },
    showIf: [billPastDue, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'ConsiderWorkingWithDebtCollectorCA4',
    image: considerWorkingWithCollections,
    title: trans('i18n-unplanned:working-collections.title'),
    shortText: trans('i18n-unplanned:working-collections.short-text'),
    text: trans('i18n-unplanned:working-collections.text'),
    infoBox: {
      content: trans('i18n-unplanned:working-collections.info'),
    },
    button: {
      text: trans('learn-more'),
      url: `${MY_PLAN_PATH}${CONSIDER_WORKING_WITH_DEBT_COLLECTOR_CA4_PATH}`,
    },
    route: {
      component: ConsiderWorkingWithDebtCollectorCA4,
    },
    showIf: [billPastDue, hasPrivateInsurance],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'UnderstandYourRightsCB1',
    image: understandYourRights,
    title: trans('i18n-unplanned:understand-rights.title'),
    shortText: trans('i18n-unplanned:understand-rights.short-text'),
    text: trans('i18n-unplanned:understand-rights.text'),
    infoBox: {
      content: trans('i18n-unplanned:understand-rights.info'),
    },
    button: {
      text: trans('learn-more'),
      url: `${MY_PLAN_PATH}${UNDERSTAND_YOUR_RIGHTS_CB1_PATH}`,
    },
    route: {
      component: UnderstandYourRightsCB1,
    },
    showIf: [billPastDue, hasMedicare],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'RequestAnAffordablePaymentPlanCB2',
    image: requestAnAffordablePaymentPlan,
    title: trans('i18n-unplanned:request-affordable-payment.title'),
    shortText: trans('i18n-unplanned:request-affordable-payment.short-text-1'),
    text: trans('i18n-unplanned:request-affordable-payment.text-3'),
    infoBox: {
      content: trans('i18n-unplanned:request-affordable-payment.info-3'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${REQUEST_AFFORDABLE_PAYMENT_PLAN_CB2_PATH}`,
    },
    route: {
      component: RequestAnAffordablePaymentPlanCB2,
    },
    showIf: [billPastDue, hasMedicare],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'NegotiateWithTheCollectionAgencyCB3',
    image: considerWorkingWithCollections,
    title: trans('i18n-unplanned:working-collections.title'),
    shortText: trans('i18n-unplanned:working-collections.short-text'),
    text: trans('i18n-unplanned:working-collections.text'),
    infoBox: {
      content: trans('i18n-unplanned:working-collections.info'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${NEGOTIATE_WITH_THE_COLLECTION_AGENCY_CB3_PATH}`,
    },
    route: {
      component: NegotiateWithTheCollectionAgencyCB3,
    },
    showIf: [billPastDue, hasMedicare],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'ContactYourStateMedicaidProgramCC1',
    image: contactYourStateMedicaidProgram,
    title: trans('i18n-unplanned:contact-state-medical.title'),
    shortText: trans('i18n-unplanned:contact-state-medical.short-text-2'),
    text: trans('i18n-unplanned:contact-state-medical.text'),
    infoBox: {
      content: trans('i18n-unplanned:contact-state-medical.info'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${CONTACT_YOUR_STATE_MEDICAID_PROGRAM_CC1_PATH}`,
    },
    route: {
      component: ContactYourStateMedicaidProgramCC1,
    },
    showIf: [billPastDue, hasMedicaid],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'UnderstandYourRightsCD1',
    image: understandYourRights,
    title: trans('i18n-unplanned:understand-rights.title'),
    shortText: trans('i18n-unplanned:understand-rights.short-text'),
    text: trans('i18n-unplanned:understand-rights.text'),
    infoBox: {
      content: trans('i18n-unplanned:understand-rights.info'),
    },
    button: {
      text: trans('learn-more'),
      url: `${MY_PLAN_PATH}${UNDERSTAND_YOUR_RIGHTS_CD1_PATH}`,
    },
    route: {
      component: UnderstandYourRightsCD1,
    },
    showIf: [billPastDue, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'NegotiateToLowerYourCostsCD2',
    image: negotiateToLowerYourCosts,
    title: trans('i18n-unplanned:negotiate-costs.title-2'),
    shortText: trans('i18n-unplanned:negotiate-costs.short-text-2'),
    text: trans('i18n-unplanned:negotiate-costs.text-2'),
    infoBox: {
      content: trans('i18n-unplanned:negotiate-costs.info-2'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${NEGOTIATE_TO_LOWER_YOUR_COSTS_CD2_PATH}`,
    },
    route: {
      component: NegotiateToLowerYourCostsCD2,
    },
    showIf: [billPastDue, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'RequestAnAffordablePaymentPlanCD3',
    image: requestAnAffordablePaymentPlan,
    title: trans('i18n-unplanned:request-affordable-payment.title'),
    shortText: trans('i18n-unplanned:request-affordable-payment.short-text-1'),
    text: trans('i18n-unplanned:request-affordable-payment.text-3'),
    infoBox: {
      content: trans('i18n-unplanned:request-affordable-payment.info-3'),
    },
    button: {
      text: trans('learn-how'),
      url: `${MY_PLAN_PATH}${REQUEST_AFFORDABLE_PAYMENT_PLAN_CD3_PATH}`,
    },
    route: {
      component: RequestAnAffordablePaymentPlanCD3,
    },
    showIf: [billPastDue, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
  {
    id: 'ConsiderWorkingWithCollectionsCD4',
    image: considerWorkingWithCollections,
    title: trans('i18n-unplanned:working-collections.title'),
    shortText: trans('i18n-unplanned:working-collections.short-text'),
    text: trans('i18n-unplanned:working-collections.text'),
    infoBox: {
      content: trans('i18n-unplanned:working-collections.info'),
    },
    button: {
      text: trans('learn-more'),
      url: `${MY_PLAN_PATH}${CONSIDER_WORKING_WITH_COLLECTIONS_CD4_PATH}`,
    },
    route: {
      component: ConsiderWorkingWithCollectionsCD4,
    },
    showIf: [billPastDue, isUninsured],
    verticals: [VERTICALS_NAMES.MEDICAL],
  },
];

export default cards;
