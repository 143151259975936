import Big from 'big.js';

import { TMinMonthlyPaymentPayOffPromoParams } from '@/types/domain/calcs';

const calcMinMonthlyPaymentPayOffPromo = ({
  chargeAmount,
  promoPeriodDuration,
  promoInterestRate,
}: TMinMonthlyPaymentPayOffPromoParams): Big => {
  let minMonthlyPaymentToPayOffDuringPromo;

  if (Big(promoInterestRate).eq(0)) {
    if (Big(promoPeriodDuration).eq(0)) {
      minMonthlyPaymentToPayOffDuringPromo = Big(chargeAmount);
    } else {
      minMonthlyPaymentToPayOffDuringPromo = Big(chargeAmount).div(promoPeriodDuration);
    }
  } else if (Big(promoPeriodDuration).eq(0)) {
    minMonthlyPaymentToPayOffDuringPromo = Big(chargeAmount);
  } else {
    minMonthlyPaymentToPayOffDuringPromo = Big(chargeAmount)
      .times(Big(1).plus(Big(promoInterestRate).div(365)).pow(30))
      .div(promoPeriodDuration);
  }

  return minMonthlyPaymentToPayOffDuringPromo;
};

export default calcMinMonthlyPaymentPayOffPromo;
