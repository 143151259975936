import React from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

import { FeatureFlagsContext } from '@/services/featureFlags';

import { noop } from '@/util/function';

import Toast, { GlobalToastContainer } from '@/components/Toast';

import { TProvider } from '@/types/common';
import { TNotificationContext, TNotificationObject, ToastType } from '@/types/notification';

const initialNotificationsProviderValue: TNotificationContext = {
  notify: noop,
  dismiss: noop,
};

export const NotificationsContext = React.createContext(initialNotificationsProviderValue);

const NotificationsProvider: TProvider = ({ children }) => {
  const { isFeatureEnabled } = React.useContext(FeatureFlagsContext);
  const toastNotificationsEnabled = isFeatureEnabled('ABACUS_FLAG_TOAST_NOTIFICATIONS');

  // Toast Notifications
  const [notifications, setNotifications] = React.useState<TNotificationObject[]>([]);
  const notify = React.useCallback(props => {
    const { content, type = ToastType.DEFAULT, actionContent = '', delay, action } = props;
    const key = uuidv4();
    let { id = '' } = props;

    if (!id) {
      id = uuidv4();
    }

    setNotifications(previousNotifications => {
      previousNotifications = previousNotifications.filter(notification => notification.id !== id);
      return [...previousNotifications, { id, content, actionContent, action, type, delay, key }];
    });
  }, []);
  const dismiss = React.useCallback(
    (id: string | null | undefined = null) =>
      setNotifications(previousNotifications => previousNotifications.filter(notification => notification.id !== id)),
    []
  );

  // Replace noops with real functions if feature flag is enabled
  // TODO: Fix this eslint error
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const providerValue = {
    ...initialNotificationsProviderValue,
    // Only set notify and dismiss if toasts are enabled
    ...(toastNotificationsEnabled ? { notify, dismiss } : {}),
  };

  return (
    <NotificationsContext.Provider value={providerValue}>
      {toastNotificationsEnabled &&
        createPortal(
          <GlobalToastContainer>
            {notifications.map(({ id, content, actionContent, action, type, delay, key }) => (
              /* Key needs to be unique so it displays the slide down animation */
              <Toast
                key={key}
                handleDismiss={() => dismiss(id)}
                type={type}
                actionContent={actionContent}
                action={action}
                delay={delay}
              >
                {content}
              </Toast>
            ))}
          </GlobalToastContainer>,
          window.document.body
        )}
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const { notify, dismiss } = React.useContext(NotificationsContext);

  return { notify, dismiss };
};

export default NotificationsProvider;
