/* eslint-disable */
// @ts-nocheck
import { SAVINGS_FREQUENCIES, SAVINGS_STATUSES } from 'common/model/savings';

import { UPSERT_SAVINGS, DELETE_SAVINGS } from '@/graphql/mutations';
import { LIST_SAVINGS, LIST_ACTIVITIES } from '@/graphql/queries';
import { Savings } from '@/types/generated/globalTypes';

import { upsertBudget, upsertTransactions } from '../budget/helpers';

import type { InitialContextT } from '..';
import { RETRY_ATTEMPTS, RETRY_DELAY, retryPromise } from '@/util/function';

// Loads the savings with a network first policy to ensure we get the latest value from the DB
export const loadSavings = (context: InitialContextT) => {
  const { client, userId } = context;

  return retryPromise(
    () =>
      client.query<{ listSavings: Savings[] }>({
        query: LIST_SAVINGS,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
      }),
    RETRY_DELAY,
    RETRY_ATTEMPTS
  );
};

const savingsBudgetIntegration = async (context: InitialContextT, event) => {
  const { budget } = context;
  const { savings } = event;

  let updatedFromSavingsFlag = false;

  const { isCreated } = budget;

  // check if budget exists
  if (!isCreated) {
    return null;
  }

  const storedSavingsList = await loadSavings(context);
  const storedSavings = storedSavingsList.data.listSavings.find(s => s.savingsId === savings.savingsId);

  // if the nickname or savings.amountToContributeMonthly has changed, we need to update the budget
  if (
    storedSavings?.nickname !== savings.nickname ||
    storedSavings?.amountToContributeMonthly !== savings.amountToContributeMonthly ||
    storedSavings?.frequencyOfContribution !== savings.frequencyOfContribution
  ) {
    updatedFromSavingsFlag = true;
  }

  return upsertBudget(context, { budget: { updatedFromSavingsFlag } });
};

export const upsertSavings = async (context: InitialContextT, event) => {
  const { client, userId } = context;
  const { savings, navigate } = event;

  try {
    // set the default empty values for incomplete savings
    if (savings.status === SAVINGS_STATUSES.PENDING) {
      savings.amountToSave = 0;
      savings.dueDate = 0;
      savings.amountAlreadySaved = 0;
      savings.hasDisplayedSavingsPlanMessage = false;
      savings.frequencyOfContribution = SAVINGS_FREQUENCIES.MONTHLY;
    }

    const upsertBudgetResult = await savingsBudgetIntegration(context, event);

    const savingsResult = await client.mutate({
      mutation: UPSERT_SAVINGS,
      variables: {
        input: {
          userId,
          savings,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: LIST_ACTIVITIES,
          variables: {
            userId,
          },
        },
        {
          query: LIST_SAVINGS,
          variables: {
            userId,
          },
        },
      ],
    });

    /* savings results is read by setSavings as
     * const { upsertSavings: savings } = event.data.result.data;
     */
    const savingsResultWrapper = {
      data: {
        upsertSavings: savingsResult.data.upsertSavings,
      },
    };

    /* budget results is read by setBudget as
     * const { upsertBudget } = event.data.data;
     */
    if (upsertBudgetResult?.data.upsertBudget.dateUpdated) {
      delete upsertBudgetResult.data.upsertBudget.dateUpdated;
    }

    const budgetResultWrapper = {
      upsertBudget: upsertBudgetResult?.data?.upsertBudget || null,
    };

    return {
      result: savingsResultWrapper,
      data: budgetResultWrapper,
      savingsType: savings.type,
      navigate,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('upsertSavings', error);
    throw error;
  }
};

export const upsertSavingsBatch = async (context: InitialContextT, event) => {
  const { savings } = event;

  // for each savings, we need call upsertSavings
  const promises = savings.map(s => upsertSavings(context, { savings: s, skipBudgetUpdate: true }));

  try {
    const results = await Promise.all(promises);

    const savingsResult = results.map(r => r.result);

    const savingsResultWrapper = {
      data: {
        upsertSavingsBatch: savingsResult,
      },
    };

    return {
      result: savingsResultWrapper,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('upsertSavingsBatch', error);
    throw error;
  }
};

export const upsertSavingsAndAddDeposit = async (context: InitialContextT, event) => {
  const { savings, deposit, navigate } = event;

  try {
    let depositResult = null;

    if (deposit) {
      depositResult = await upsertTransactions(context, { payload: deposit });
    }

    const { result: savingsResult } = await upsertSavings(context, { savings });

    /* savings results is read by setSavings as
     * const { upsertSavings: savings } = event.data.result.data;
     */
    const savingsResultWrapper = {
      data: {
        upsertSavings: savingsResult.data.upsertSavings,
      },
    };

    /* deposit results is read by setTransactions as
     * const { upsertTransactions } = event.data.data;
     */
    const depositResultWrapper = {
      upsertTransactions: depositResult?.data.upsertTransactions,
    };

    return {
      result: savingsResultWrapper,
      data: depositResultWrapper,
      savingsType: savings.type,
      navigate,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('upsertSavings', error);
    throw error;
  }
};

export const removeSavings = async (context: InitialContextT, event) => {
  const { client, userId } = context;
  const { savings, navigate } = event;

  const result = await client.mutate({
    mutation: DELETE_SAVINGS,
    variables: {
      input: {
        userId,
        savings,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_ACTIVITIES,
        variables: {
          userId,
        },
      },
      {
        query: LIST_SAVINGS,
        variables: {
          userId,
        },
      },
    ],
  });

  return {
    result,
    navigate,
  };
};

export const isSavingsOnboardingFlow = (context: InitialContextT, event) => {
  const { upsertSavings: savings = {} } = event.data.result.data;
  const { savingsId } = savings;
  return context.savings.list.length === 0 || context.savings.list.every(plan => plan.savingsId !== savingsId);
};
