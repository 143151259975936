import { Location } from 'history';

/**
 * Returns an boolean value if the provided LocationDescriptor matches the provded exclusions.
 */
export const isExcludedPath = (location: string | Location, excludedPaths: string[]) => {
  if (typeof location === 'string') {
    return excludedPaths.includes(location);
  }
  return excludedPaths.includes(location.pathname);
};

/**
 * Returns an boolean value if the provided LocationDescriptor matches the provided exclusions.
 */
export const replaceExcludedPath = (
  location: string | Location,
  excludedPaths: string[],
  fallbackLocation: string | Location
) => {
  return isExcludedPath(location, excludedPaths) ? fallbackLocation : location;
};
