/* eslint-disable */
// @ts-nocheck
import React, { PropsWithChildren } from 'react';

import { captureException } from '@/util/Sentry';

export const ERROR_TYPE = {
  SERVER_ERROR: 'SERVER_ERROR',
  UNCAUGHT_EXCEPTION: 'UNCAUGHT_EXCEPTION',
};

class ErrorBoundary extends React.Component<PropsWithChildren<{ FallbackComponent: React.FC<any> }>> {
  constructor() {
    super();
    this.state = { error: null };
    this.clearError = this.clearError.bind(this);
    this.setError = this.setError.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  setError(error) {
    this.setState({ error });
  }

  clearError() {
    this.setState({ error: null });
  }

  handleError(newError, context = {}) {
    if (Array.isArray(newError)) {
      newError.some(err => {
        const serverError = err?.extensions?.code === 'INTERNAL_SERVER_ERROR';

        if (serverError) {
          captureException(newError, context, errorId =>
            this.setError({ ...err, type: ERROR_TYPE.SERVER_ERROR, errorId })
          );
        }

        return serverError;
      });
    }

    // handles random app crashes
    if (newError.type === ERROR_TYPE.UNCAUGHT_EXCEPTION) {
      captureException(newError, context, errorId =>
        this.setError({ ...newError, type: ERROR_TYPE.SERVER_ERROR, errorId })
      );
    }
  }

  componentDidCatch(error, info) {
    error = error instanceof Error ? error : new Error(error);
    error.type = ERROR_TYPE.UNCAUGHT_EXCEPTION;
    this.handleError(error, info);
  }

  render() {
    if (this.state.error && this.props.FallbackComponent !== undefined) {
      return <this.props.FallbackComponent clearError={this.clearError.bind(this)} />;
    }
    return this.props.children as JSX.Element;
  }
}

export default ErrorBoundary;
