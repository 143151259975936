import React from 'react';

import Providers from '@/services/Providers';

import { envOrDefault, onlyInDevelopment } from '@/util/env';

import FeatureFlag from '@/components/FeatureFlag';
import Router from '@/components/Router';
import GlobalLoader from '@/components/GlobalLoader';
import DevConsole from '@/components/DevConsole';

// typeform SDK should be lazy loaded due to size
const CopingSurvey = React.lazy(() => import('@/components/CopingSurvey'));

// make lazy so react-meta-tags module isn't in a bundle that would
// be used in a disabled environment
const MetaTags = React.lazy(() => import('@/pages/common/MetaTags'));

// eslint-disable-next-line @typescript-eslint/require-await
onlyInDevelopment(async () => {
  // To enable, set ENABLE_WDYR environment variable or change default to true
  const ENABLE_WDYR = Boolean(envOrDefault('ENABLE_WDYR', false));
  if (ENABLE_WDYR) {
    // why-did-you-render does not support dynamic imports
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
      trackHooks: true,
    });
  }
});

const App = () => (
  <React.Suspense fallback={<GlobalLoader loading />}>
    <Providers>
      <CopingSurvey />
      <FeatureFlag noMessage feature="ABACUS_FLAG_DISPLAY_GIT_COMMIT">
        <MetaTags />
      </FeatureFlag>
      <FeatureFlag noMessage feature="ABACUS_FLAG_CONSOLE">
        <DevConsole />
      </FeatureFlag>
      <Router />
    </Providers>
  </React.Suspense>
);

export default App;
