/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import localStorage from '@/util/storage';

/**
 * Hook used to sync up state to a persistent item in session/local storage.
 * API is similar to React.useState, although you cannot pass functions in when setting state.
 * @param {Object} params
 * @param {Object} params.storage - storage object, DEFAULT is local storage
 * @param {Object} params.key - storage key for the item we want to sync the state to
 * @param {Object} params.defaultValue - value we return if there is no value stored for the given key
 */
function useStorageState({ storage = localStorage, key, defaultValue }) {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return defaultValue;
    }
  });

  const setValue = value => {
    try {
      setStoredValue(value);
      storage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // what to do here?
    }
  };

  return [storedValue, setValue];
}

export default useStorageState;
