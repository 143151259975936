import {
  format as dateFnsFormat,
  startOfMonth,
  addDays,
  intervalToDuration,
  formatDuration,
  getDaysInMonth as dateFnsDaysInMonth,
} from 'date-fns';
import { es, enUS } from 'date-fns/locale';

import i18n from '@/libs/i18n';

import { createStorage } from '@/util/storage';

import { capitalize } from './string';

export const MONTHS_OF_THE_YEAR = 12;
export const FIRST_MONTH_OF_THE_YEAR = 1;

export const getIndexedMonths = () => ({
  1: i18n.t('january'),
  2: i18n.t('february'),
  3: i18n.t('march'),
  4: i18n.t('april'),
  5: i18n.t('may'),
  6: i18n.t('june'),
  7: i18n.t('july'),
  8: i18n.t('august'),
  9: i18n.t('september'),
  10: i18n.t('october'),
  11: i18n.t('november'),
  12: i18n.t('december'),
});

export const getIndexedShortMonths = () => ({
  1: i18n.t('jan'),
  2: i18n.t('feb'),
  3: i18n.t('mar'),
  4: i18n.t('apr'),
  5: i18n.t('may'),
  6: i18n.t('jun'),
  7: i18n.t('jul'),
  8: i18n.t('aug'),
  9: i18n.t('sep'),
  10: i18n.t('oct'),
  11: i18n.t('nov'),
  12: i18n.t('dec'),
});

export type MonthYear = {
  month: string;
  year: string;
};

/**
 * Returns the full month ex: January.
 */
export const getFullMonth = (date: Date) => date.toLocaleDateString('en-US', { month: 'long' });

/**
 * Formats a date into: MM-DD-YYYY
 */
export const getFormattedDate = (date: Date) => {
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);

  return `${month}-${day}-${date.getFullYear()}`;
};

/**
 * Gets the total number of days within the specified month
 */
export const getDaysInMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

/** Returns a new MonthYear object. Months are 1-based (1-12). */
export function createMonthYear(date = new Date()) {
  return {
    year: date.getFullYear().toString(),
    month: (date.getMonth() + 1).toString(),
  };
}

/**
 * Returns a new `MonthYear` object from a `MonthYear` string.
 * * Months are 1-based (1-12).
 * * String must follow the `YYYYMM` order
 * * String can contain a specified delimiter
 */
export function createMonthYearFromMonthYearString(date = '', delimiter = '-') {
  if (!date) return null;

  const [year, month] = date.split(delimiter);
  if (!year || !month) return null;

  return {
    year,
    month,
  };
}

/** Get month index to use the date constructor function */
export const getMonthIndex = (month: string | number) => Number(month) - 1;

export const TEST_CURRENT_DATE_OBJECT_STORAGE_KEY = 'TEST_CURRENT_DATE_OBJECT';

/**
 * This allows us to set up a date different to the actual current date.
 * Useful for testing month transitions.
 */
export function getCurrentMonthYear(): MonthYear {
  const storedTestDate = createStorage('session').getItem(TEST_CURRENT_DATE_OBJECT_STORAGE_KEY);

  if (!storedTestDate) {
    return createMonthYear();
  }

  // eslint-disable-next-line no-console
  // console.info('Using a mocked date!');
  return JSON.parse(storedTestDate) as MonthYear;
}

/**
 * Creates a new `Date` based on the mockable `MonthYear`
 * in `getCurrentMonthYear` and your local time settings.
 */
export function getCurrentDate() {
  const { year, month } = getCurrentMonthYear();
  const daysInCurrentMonth = dateFnsDaysInMonth(new Date(Number(year), getMonthIndex(month)));
  const currentDate = new Date();
  const validDate = currentDate.getDate();
  const finalDate = new Date(
    Number(year),
    getMonthIndex(month),
    daysInCurrentMonth < validDate ? daysInCurrentMonth : validDate,
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
    currentDate.getMilliseconds()
  );
  return finalDate;
}

/**
 * Creates a new mocked `Date` based on the mockable `MonthYear`
 * in `getCurrentMonthYear` and your local time settings.
 */
export function createMockedDate(
  date = getCurrentMonthYear(),
  day = 0,
  hour = 0,
  minute = 0,
  second = 0,
  millisecond = 0
) {
  const { year, month } = date;
  const currentDate = new Date();
  const finalDate = new Date(
    Number(year),
    getMonthIndex(month),
    day || currentDate.getDate(),
    hour || currentDate.getHours(),
    minute || currentDate.getMinutes(),
    second || currentDate.getSeconds(),
    millisecond || currentDate.getMilliseconds()
  );
  return finalDate;
}

/**
 * Returns the month name from the index ex: January.
 * Month index is 1-based (January is 1, December is 12).
 *
 * @param {number} monthIndex - 1-based month index
 *
 * @returns {String}
 */
export function mapMonthIndexToName(monthIndex: number | string) {
  const indexedMonths = getIndexedMonths();

  return indexedMonths[String(monthIndex) as unknown as keyof typeof indexedMonths];
}

/**
 * Returns the short month name from the index ex: Jan.
 * Month index is 1-based (i.e. January is 1, December is 12).
 */
export function mapMonthIndexToShortName(monthIndex: number | string): string {
  const SHORT_MONTH_INDEX = getIndexedShortMonths();

  return SHORT_MONTH_INDEX[monthIndex];
}

/**
 * Gets the next valid month from a provided MonthYear value
 */
export function getNextMonthYear(monthYear: MonthYear) {
  const { month, year } = monthYear;
  const nextYear = Number(month) === 12 ? (Number(year) + 1).toString() : year;
  const nextMonth = Number(month) === 12 ? '1' : (Number(month) + 1).toString();
  return { month: nextMonth, year: nextYear };
}

/**
 * Gets the previous valid month from a provided MonthYear value
 */
export function getPreviousMonthYear(monthYear: MonthYear) {
  const { month, year } = monthYear;
  const nextYear = Number(month) === 1 ? (Number(year) - 1).toString() : year;
  const nextMonth = Number(month) === 1 ? '12' : (Number(month) - 1).toString();
  return { month: nextMonth, year: nextYear };
}

/**
 * Returns an iterable that can be used to iterate over a range of MonthYear objects.
 * @param start - included only if range has at least one month
 * @param end - not included by default
 * @param includeEnd - if true, includes end of the range in results
 */
export function createMonthYearIterable(start: MonthYear, end: MonthYear, includeEnd = false) {
  if (
    Number(end.year) < Number(start.year) ||
    (Number(end.year) === Number(start.year) && Number(end.month) < Number(start.month))
  ) {
    throw new Error(`Invalid date range! ${JSON.stringify(start)} - ${JSON.stringify(end)}`);
  }

  let nextMonthYear = start;
  const endMonthYear = includeEnd ? getNextMonthYear(end) : end;

  // Satisfies both the Iterator Protocol and Iterable
  const monthYearIterable = {
    next() {
      if (nextMonthYear.year === endMonthYear.year && nextMonthYear.month === endMonthYear.month) {
        return { value: nextMonthYear, done: true };
      }
      const value = { ...nextMonthYear };
      nextMonthYear = getNextMonthYear(nextMonthYear);
      return { value, done: false };
    },
    [Symbol.iterator]() {
      return this;
    },
  };

  return monthYearIterable;
}

export function createFutureYearsIterable(startYear: number, yearsToAdd: number) {
  const years = [startYear];
  for (let i = 1; i <= yearsToAdd; i++) {
    years.push(startYear + i);
  }

  return years;
}

/**
 * Substracts months from date.
 * @param date - the date we want to change
 * @param subtract - the number of months we want to substract
 */
export const subtractMonthsFromDate = ({ year, month }: MonthYear, subtract: number) => {
  let newMonth = Number(month) - subtract;
  let newYear = Number(year);

  while (newMonth <= 0) {
    newMonth += MONTHS_OF_THE_YEAR;
    newYear--;
  }

  return {
    month: newMonth.toString(),
    year: newYear.toString(),
  };
};

/**
 * Adds months to date.
 * @param date - the date we want to change
 * @param add - the number of months we want to add
 */
export const addMonthsToDate = ({ year, month }: MonthYear, add: number) => {
  let newMonth = Number(month) + add;
  let newYear = Number(year);

  while (newMonth > MONTHS_OF_THE_YEAR) {
    newMonth -= MONTHS_OF_THE_YEAR;
    newYear++;
  }

  return {
    month: newMonth.toString(),
    year: newYear.toString(),
  };
};

/**
 * Compares if 2 dates are equal
 * @param dateA - date
 * @param dateB - date
 */
export const isDateEqual = (dateA: MonthYear, dateB: MonthYear) => {
  return Number(dateA.month) === Number(dateB.month) && Number(dateA.year) === Number(dateB.year);
};

/**
 * Compares if dateA is greater than (more recent) dateB
 * @param dateA - date
 * @param dateB - date
 */
export const isDateGreaterThan = (dateA: MonthYear, dateB: MonthYear) => {
  return (
    (Number(dateA.month) > Number(dateB.month) && Number(dateA.year) >= Number(dateB.year)) ||
    Number(dateA.year) > Number(dateB.year)
  );
};

/**
 * Compute absolute difference in months between dateA and dateB
 * @param dateA - date
 * @param dateB - date
 */
export const getMonthsDifferenceBetweenDates = (dateA: MonthYear, dateB: MonthYear) => {
  const yearsDiff = Number(dateA.year) - Number(dateB.year);
  return Math.abs(
    yearsDiff * 12 + Math.sign(yearsDiff || 1) * Number(dateA.month) - Math.sign(yearsDiff || 1) * Number(dateB.month)
  );
};

/**
 * Transforms a number of months into years and months
 * @param months - number of months
 */
export const transformMonthsToYearsAndMonths = (months: number) => {
  let years = 0;

  if (months < MONTHS_OF_THE_YEAR) {
    return i18n.t('count-month', { count: months });
  }

  while (months >= MONTHS_OF_THE_YEAR) {
    months -= MONTHS_OF_THE_YEAR;
    years++;
  }

  const yearsString = i18n.t('count-year', { count: years });
  const monthsString = months === 0 ? '' : i18n.t('count-month', { count: months });

  return `${yearsString}${monthsString ? `, ${monthsString}` : ''}`.trim();
};

/**
 * Get date in YYYYMM format: '202107' with an optional delimiter between
 * @param date - date
 * @param delimiter - inserts some text between the two values
 */
export const getDateInYYYYMMFormat = (date = new Date(), delimiter = '') => {
  const year = date.getFullYear().toString();

  const month = (date.getMonth() + 1).toString();
  const twoDecimalsMonth = month.padStart(2, '0');

  return `${year}${delimiter}${twoDecimalsMonth}`;
};

/**
 * Get MonthYear object in YYYYMM format: '202107' with an optional delimiter between
 * @param delimiter - inserts some text between the two values
 */
export const getMonthYearInYYYYMMFormat = (date = {} as MonthYear, delimiter = '') => {
  if (Object.keys(date).length === 0) {
    const monthYear = createMonthYear(new Date());
    date = monthYear;
  }

  // TODO: Normalize year lengths both mocked and not so we don't need this conditional logic
  const year = date.year.length === 2 ? `20${date.year}` : date.year;
  const month = date.month.toString().padStart(2, '0');

  return `${year}${delimiter}${month}`;
};

/**
 * Converts unix timestamp to MonthYear object.
 * @param timestamp - Unix timestamp
 */
export function getMonthYearFromTimestamp(timestamp: number) {
  const { month, year } = createMonthYear(new Date(timestamp));
  return { month, year };
}

/**
 * @deprecated
 * To be removed later!
 *
 * Parses existing yearMonth values in localStorage in order to reduce possible situations
 * where the user sees the budget monthly modal by converting it to a regular `MonthYear`
 */
export const createMonthYearFromLegacyYearMonthString = (legacyYearMonth: string): MonthYear => {
  const [year, month] = legacyYearMonth.match(/.{1,4}/g) || [];
  return {
    year,
    month,
  };
};

export const getDateUtilsLocale = (language: string) => {
  const lng = language.split('-')[0];
  switch (lng) {
    case 'es':
      return es;
    default:
      return enUS;
  }
};

/**
 * Wrapper around date-fns format method to include internalization.
 * Please use this function in-place of `format` from date-fns
 */
export const format = (date: Date, formatString: string, options?: Record<string, unknown>) => {
  const locale = getDateUtilsLocale(i18n.language);
  return dateFnsFormat(date, formatString, { locale, ...options });
};

/**
 * Generate days list for a given month
 * @param date - date
 * @param dayNameFormat - format used for the days name (Sun, Mon, Tue)
 */
export const generateDaysListForAGivenMonth = (date: Date, dayNameFormat = 'E') => {
  const firstDayOfTheMonth = startOfMonth(date);
  const numberOfDaysInTheMonth = getDaysInMonth(date);

  const daysArray = [...Array(numberOfDaysInTheMonth).keys()];
  const normalizedDaysList = daysArray.map((dayIndexNumber, index) => ({
    dayName: capitalize(format(addDays(firstDayOfTheMonth, index), dayNameFormat)),
    dayNumber: dayIndexNumber + 1,
  }));

  return normalizedDaysList;
};

/**
 * Get time remaining to a given date
 * @returns time (e.g. '1 year, 7 months remaining')
 */

export const getTimeRemaining = (futureDate: string) => {
  const duration = intervalToDuration({
    start: new Date(futureDate),
    end: new Date(),
  });

  return formatDuration(
    {
      years: duration.years,
      months: duration.months,
    },
    {
      delimiter: ', ',
      locale: getDateUtilsLocale(i18n.language),
    }
  );
};

export const getLogMonthFromShortMonth = (shortMonth: string) => {
  const monthIndex = Object.values(getIndexedShortMonths()).indexOf(shortMonth);
  return getIndexedMonths()[monthIndex + 1];
};

export const getLastXShortMonthNames = (range: number) => {
  const { month } = getCurrentMonthYear();
  const numMonth = Number(month);
  const months = Object.values(getIndexedShortMonths());
  return months
    .slice(numMonth - range)
    .concat(months.slice(0, numMonth))
    .slice(0, range);
};
