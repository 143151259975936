/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';

import { isFlowCompleted, findFirstReachableStepFactory } from '@/domain/WizardConfig';
import { expenseNicknameQ } from '@/domain/questions';
import { BASE_PATH_ONBOARDING_WIZARD, MY_PLAN_PATH } from '@/domain/paths';
import { getAnswer } from '@/domain/questions/util';

import { count } from '@/util/array';
import { trans } from '@/util/i18n';

import { VERTICALS } from '@/pages/Dashboard/UnplannedExpenses/config';
import { getSituationalSteps } from '@/pages/MyPlan/state/selectors';
import { coreSteps } from '@/pages/MyPlan/state/content';

export const getExpenseNickname = expense => {
  return getAnswer(expenseNicknameQ, expense) || VERTICALS[expense.vertical].title;
};

export const getExpenseStatus = expense => {
  const onboardingComplete = isFlowCompleted(
    expense.flowMetadata[VERTICALS[expense.vertical].config.name],
    VERTICALS[expense.vertical].config
  );
  if (onboardingComplete) {
    const situationalSteps = getSituationalSteps(expense, { vertical: expense.vertical });
    const allSteps = [...coreSteps, ...situationalSteps];
    const completedSteps = count(allSteps, step => expense.myPlanCompletedSteps.includes(step.id));

    if (completedSteps === 0) {
      return 'planNotStarted';
    }
    if (completedSteps === allSteps.length) {
      return 'planCompleted';
    }
    return 'planInProgress';
  }
  return 'onboardingNotFinished';
};

export const getExpenseCompletedPercentage = expense => {
  const onboardingComplete = isFlowCompleted(
    expense.flowMetadata[VERTICALS[expense.vertical].config.name],
    VERTICALS[expense.vertical].config
  );
  if (onboardingComplete) {
    const situationalSteps = getSituationalSteps(expense, { vertical: expense.vertical });
    const allSteps = [...coreSteps, ...situationalSteps];
    const completedSteps = count(allSteps, step => expense.myPlanCompletedSteps.includes(step.id));

    return Math.ceil((completedSteps * 100) / allSteps.length);
  }
  return 0;
};

export const getMyPlanExpenseStats = expense => {
  const situationalSteps = getSituationalSteps(expense, { vertical: expense.vertical });
  const allSteps = [...coreSteps, ...situationalSteps];
  const completedSteps = count(allSteps, step => expense.myPlanCompletedSteps.includes(step.id));

  return {
    completedSteps,
    allSteps: allSteps.length,
  };
};

const getExpenseSubtitle = (expense, status) => {
  if (status === 'onboardingNotFinished') {
    return { text: trans('i18n-unplanned:resume-set-up') };
  }
  const myPlanExpenseStats = getMyPlanExpenseStats(expense);

  return {
    text: trans('i18n-unplanned:steps-completed'),
    ...myPlanExpenseStats,
  };
};

export const getExpenseAccessLink = (expense, status) => {
  if (status === 'onboardingNotFinished') {
    const findCurrentLink = findFirstReachableStepFactory(
      expense.flowMetadata[VERTICALS[expense.vertical].config.name] || {},
      VERTICALS[expense.vertical].config.reverseReducer,
      expense
    );
    const verticalSteps = Object.keys(VERTICALS[expense.vertical].config.steps);
    const lastStepId = VERTICALS[expense.vertical].config.steps[verticalSteps[verticalSteps.length - 1]].id;
    return `${BASE_PATH_ONBOARDING_WIZARD}/${findCurrentLink(lastStepId)}`;
  }

  return MY_PLAN_PATH;
};

const getExpenses = expenses => expenses;

export const getDistributedExpenses = createSelector(getExpenses, expenses => {
  return expenses.reduce(
    (acc, expense) => {
      const status = getExpenseStatus(expense);
      const subtitle = getExpenseSubtitle(expense, status);
      const redirectTo = getExpenseAccessLink(expense, status);
      const title = getExpenseNickname(expense);

      const cardDetails = { key: expense.expenseId, title, subtitle, redirectTo, expense };

      acc[status].push(cardDetails);
      return acc;
    },
    { onboardingNotFinished: [], planNotStarted: [], planInProgress: [], planCompleted: [] }
  );
});
