/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import { getAnswer } from '@/domain/questions/util';

import ConditionalQuestion from '@/components/ConditionalQuestion';
import Divider from '@/components/Divider';
import FormEffect from '@/components/Form/FormEffect';
import { InfoBox } from '@/components/InformationBox';
import Question from '@/components/Question';

import {
  contributingConditionalQ,
  expectedReturnRateQ,
  loanInterestRateQ,
  loanRepayPeriodQ,
  willContinueContributingQ,
  yearsToRetirementQ,
} from '../questions';

const Form = ({ answerWrapper, fromAssessment, updateForm }) => {
  const { t } = useTranslation('i18n-unplanned');
  const willContinueContributing = getAnswer(willContinueContributingQ, answerWrapper);

  return (
    <>
      <FormEffect onChange={updateForm} />
      <Question question={loanRepayPeriodQ} readOnly={fromAssessment} />
      <Question question={loanInterestRateQ} readOnly={fromAssessment} />

      <Divider />

      <Question question={yearsToRetirementQ} readOnly={fromAssessment} />
      <Question question={expectedReturnRateQ} readOnly={fromAssessment} />

      <Divider />

      <Question question={willContinueContributingQ} readOnly={fromAssessment} />

      {willContinueContributing === 1 ? <InfoBox size="small" content={t('cost-calc.form.tip')} /> : null}

      <ConditionalQuestion
        getQuestions={contributingConditionalQ.getQuestions}
        readOnly={fromAssessment}
        values={answerWrapper.answers}
      />
    </>
  );
};

export default Form;
