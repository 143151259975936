/* eslint-disable */
// @ts-nocheck
import isIos from 'is-ios';

import { keyFilter, numberKeys, decimal, submitKeys } from '@/util/input';

import Input from '@/components/input';

const LegacyCurrencyInput = ({
  'aria-label': ariaLabel = '',
  'aria-required': ariaRequired = null,
  children,
  'data-testid': dataTestId,
  error = null,
  id = '',
  name = '',
  onBlur = null,
  onChange = null,
  placeholder = '',
  readOnly = false,
  value = null,
  className = '',
  focus = null,
  onFocus = null,
  inputFieldRef = null,
  ...restProps
}) => (
  <Input
    className={className}
    ref={inputFieldRef}
    aria-label={ariaLabel}
    aria-required={ariaRequired}
    autoComplete="off"
    data-testid={dataTestId}
    error={error}
    id={id}
    label={children === undefined ? '$' : undefined}
    min={0}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    onKeyPress={event => keyFilter(event, [...numberKeys, ...decimal, ...submitKeys])}
    pattern={isIos ? '' : '[0-9]*'}
    type="number"
    placeholder={placeholder}
    readOnly={readOnly}
    value={value}
    focus={focus}
    onFocus={onFocus}
    {...restProps}
  >
    {children}
  </Input>
);

export default LegacyCurrencyInput;
