import { homeRentOwnQ, rentersInsuranceQ } from '@/domain/questions/home';
import { getAnswer } from '@/domain/questions/util';

export const getHomeRentOwnAnswer = userState => getAnswer(homeRentOwnQ, userState);

export const getRentersInsuranceAnswer = userState => getAnswer(rentersInsuranceQ, userState);

export const renting = userState => getHomeRentOwnAnswer(userState) === 'rent';

export const ownHome = userState => getHomeRentOwnAnswer(userState) === 'own';

export const isInsured = userState => getRentersInsuranceAnswer(userState) === 1;

export const isUninsured = userState => getRentersInsuranceAnswer(userState) === 0;
