/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import WhyDoYouAskModal from '@/components/modal/WhyDoYouAskModal';

const AmountInRetirementWhyAsk = () => {
  const { t } = useTranslation('i18n-unplanned');
  return <WhyDoYouAskModal name="amountInRetirementWhyAsk">{t('amount-retirement-why-ask')}</WhyDoYouAskModal>;
};

export default AmountInRetirementWhyAsk;
