/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Card, Grid, Segment } from 'semantic-ui-react';

import { getAnswer } from '@/domain/questions/util';

import useUserState from '@/hooks/useUserState';

import { trans } from '@/util/i18n';

import Currency from '@/components/Currency';
import { ErrorBox } from '@/components/InformationBox';
import Paragraph from '@/components/Paragraph';
import Placeholder from '@/components/Placeholder';
import CalcResultsPlaceholder from '@/components/Placeholder/CalcResultsPlaceholder';

import {
  amountInRetirementQ,
  averageMonthlyPayQ,
  expectedReturnRateQ,
  loanAmountBFRQ,
  loanInterestRateQ,
  loanRepayPeriodQ,
  retirementContributionQ,
  retirementEmployerMatchQ,
  willContinueContributingQ,
  yearsToRetirementQ,
} from '../questions';

import classes from './Estimate.module.less';

const UnableToPay = ({ maxPossibleLoss }) => {
  const { t } = useTranslation('i18n-unplanned');

  if (maxPossibleLoss == null) {
    return null;
  }

  return (
    <Card raised={false} className={classes.estimateCard}>
      <ErrorBox icon="warning sign" className={classes.repayWarningHeader} content={t('unable-to-pay.content')} />
      <Card.Content>
        <Paragraph noHorizontalMargin>{t('unable-to-pay.paragraph')}</Paragraph>
      </Card.Content>
    </Card>
  );
};

export const renderTrueLoanCost = trueLoanCost => {
  if (trueLoanCost == null) {
    return <Placeholder />;
  }

  const value = Number(trueLoanCost.toFixed(0));
  let label = '';

  if (value > 0) {
    label = trans('more');
  } else if (value < 0) {
    label = trans('less');
  }

  return (
    <>
      <Currency className={classes.currency} value={Math.abs(value)} />
      <div
        className={cx({
          [classes.lessLabel]: value < 0,
          [classes.moreLabel]: value > 0,
        })}
      >
        {label}
      </div>
    </>
  );
};

const Estimate = ({ answerWrapper }) => {
  const { t } = useTranslation();
  const [results, setResults] = React.useState(null);
  const [calculating, setCalculating] = React.useState(false);
  const calcsWorkerRef = React.useRef();
  const { state, loading, error } = useUserState();

  const createWorker = () => {
    if (calcsWorkerRef.current != null) {
      calcsWorkerRef.current.terminate();
    }
    calcsWorkerRef.current = new Worker(new URL('./calcs.worker', import.meta.url), { type: 'module' });

    calcsWorkerRef.current.onmessage = event => {
      const { data } = event;
      setCalculating(false);
      if (data.results) {
        setResults(data.results);
      }
    };
  };

  // values from previous steps
  const retirementAccountBalance = getAnswer(amountInRetirementQ, state);
  const amountNeeded = getAnswer(loanAmountBFRQ, state);

  // transient values coming from form instead of state
  const loanInterestRate = getAnswer(loanInterestRateQ, answerWrapper);
  const loanPaybackTerm = getAnswer(loanRepayPeriodQ, answerWrapper);
  const willContinueContributing = getAnswer(willContinueContributingQ, answerWrapper);
  const retirementAccountExpectedRateOfReturn = getAnswer(expectedReturnRateQ, answerWrapper);
  const yearsToRetirement = getAnswer(yearsToRetirementQ, answerWrapper);
  const earnings = getAnswer(averageMonthlyPayQ, answerWrapper);
  const retirementPlanContribution = getAnswer(retirementContributionQ, answerWrapper);
  const retirementPlanEmployerMatch = getAnswer(retirementEmployerMatchQ, answerWrapper);

  React.useEffect(() => {
    createWorker();

    return () => calcsWorkerRef.current.terminate();
  }, []);

  React.useEffect(() => {
    if (
      willContinueContributing == null ||
      !loanInterestRate ||
      !loanPaybackTerm ||
      !retirementAccountExpectedRateOfReturn ||
      !yearsToRetirement
    ) {
      return;
    }

    const willContribute = willContinueContributing === 1;

    setCalculating(true);

    // use timeout instead of debounce - minimal time to show the placeholder
    setTimeout(() => {
      createWorker();
      calcsWorkerRef.current.postMessage({
        calc: 'bfrCalcs',
        params: {
          amountNeeded,
          loanInterestRate,
          loanPaybackTerm,
          retirementAccountBalance,
          retirementAccountExpectedRateOfReturn,
          yearsToRetirement,
          earnings,
          willContribute,
          retirementPlanContribution,
          retirementPlanEmployerMatch,
        },
      });
    }, 200);
  }, [
    amountNeeded,
    earnings,
    loanInterestRate,
    loanPaybackTerm,
    retirementAccountBalance,
    retirementAccountExpectedRateOfReturn,
    retirementPlanContribution,
    retirementPlanEmployerMatch,
    willContinueContributing,
    yearsToRetirement,
  ]);

  if (loading) return null;
  if (error) return null;

  if (calculating) {
    return (
      <Segment data-testid="calcSpinner">
        <CalcResultsPlaceholder spinner text={t('calculating-results')} />
      </Segment>
    );
  }

  return (
    <>
      <Card raised={false} className={classes.estimateCard}>
        <Card.Content>
          <Grid verticalAlign="middle">
            <Grid.Row columns={2} className={classes.rowWithBorder}>
              <Grid.Column width={10}>{t('monthly-payment')}</Grid.Column>
              <Grid.Column width={6}>
                {results == null ? (
                  <Placeholder />
                ) : (
                  <Currency className={classes.currency} value={Number(results.monthlyPayment.toFixed(0))} />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className={classes.forgoneEarnings}>
              <Grid.Column width={10}>{t('foregone-earnings')}</Grid.Column>
              <Grid.Column width={6}>
                {results == null ? (
                  <Placeholder />
                ) : (
                  <Currency className={classes.currency} value={results.forgoneEarnings.trueLoanCost} />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className={classes.planBalanceAtRetirement}>
              <Grid.Column width={10}>{t('impact-account-balance')}</Grid.Column>
              <Grid.Column width={6}>{renderTrueLoanCost(results?.accountBalanceImpact)}</Grid.Column>
            </Grid.Row>
            {results == null ? (
              <Grid.Row columns={1} className={classes.noTopPadding}>
                <Grid.Column className={classes.estimateTip}>{t('fill-info-see-results')}</Grid.Column>
              </Grid.Row>
            ) : null}
          </Grid>
        </Card.Content>
      </Card>
      <UnableToPay maxPossibleLoss={results?.maxPossibleLoss} />
    </>
  );
};

export default Estimate;
