import Big from 'big.js';

import { TRatioOfMonthlyPaymentToMonthlyIncomeParams } from '@/types/domain/calcs';

/**
 * Calculates ratio of monthly payment to monthly household income.
 * Flow agnostic.
 */
const calculateRatioOfMonthlyPaymentToMonthlyIncome = ({
  monthlyLoanPayment,
  monthlyHouseholdIncome,
}: TRatioOfMonthlyPaymentToMonthlyIncomeParams): Big | number | null => {
  if (monthlyHouseholdIncome === 0) {
    return null;
  }

  return Big(monthlyLoanPayment).div(monthlyHouseholdIncome);
};

export default calculateRatioOfMonthlyPaymentToMonthlyIncome;
