/**
 * Generates a unique ID for an object by hashing its properties.
 * @param object The object to generate an ID for.
 * @returns A unique ID as a hexadecimal string.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateUniqueId<T extends Record<string, any>>(object: T): string {
  // Concatenate the object's properties into a string
  const stringToHash = Object.values(object).join(',');

  // Generate a hash by XORing each character in the string
  let hash = 0;
  for (let i = 0; i < stringToHash.length; i++) {
    /* eslint-disable no-bitwise */
    hash = (hash << 5) - hash + stringToHash.charCodeAt(i);
    hash |= 0;
  }

  // Convert the hash to a hexadecimal string to create a unique ID
  const id = hash.toString(16);

  return id;
}

/**
 * Checks if an object matches a given unique ID.
 * @param object The object to check.
 * @param id The unique ID to check against.
 * @returns `true` if the object matches the ID, `false` otherwise.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function objectMatchesId<T extends Record<string, any>>(object: T, id: string): boolean {
  // Generate a unique ID for the object
  const objectId = generateUniqueId(object);

  // Check if the generated ID matches the given ID
  return objectId === id;
}
// Define a type for an object with unknown properties
type UnknownObject = Record<string, unknown>;

// Define a type for an array with unknown elements
type UnknownArray = unknown[];

/**
 * Recursively removes the `__typename` property from an object or array.
 *
 * @param {T} obj - The object or array to remove `__typename` from.
 * @returns {T} A new object or array that is a deep copy of the input, but without any `__typename` properties.
 * @template T The type of the input object or array. Must extend `UnknownObject` or `UnknownArray`.
 */
function removeTypename<T extends UnknownObject | UnknownArray>(obj: T): T {
  // If the input is null, undefined, or not an object, return it as is
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return obj;
  }

  // Create a new object or array to hold the result, depending on the input type
  const newObj: T = Array.isArray(obj) ? ([] as unknown as T) : ({} as T);

  // Iterate over the keys of the input object/array
  Object.keys(obj).forEach(key => {
    // If the key is not '__typename', copy the property to the new object/array
    if (key !== '__typename') {
      // Call removeTypename recursively to handle nested objects/arrays
      (newObj as UnknownObject)[key] = removeTypename(obj[key]);
    }
  });

  // Return the new object/array
  return newObj;
}

export { generateUniqueId, objectMatchesId, removeTypename };
