import React, { ReactNode, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureFlagsContext } from '@/services/featureFlags';

import Paragraph from '@/components/Paragraph';

/**
 * Given a `feature` name, controls if the named feature is enabled or not.
 * Renders an informative message in case the feature is disabled.
 * A prefix is required: `ABACUS_FLAG_*`
 * Example: `ABACUS_FLAG_EMAIL_ASSESSMENT`
 */
const FeatureFlag: FunctionComponent<{ feature: string; children: ReactNode | Function; noMessage?: boolean }> = ({
  feature,
  children,
  noMessage = false,
}) => {
  const { t } = useTranslation();
  const { isFeatureEnabled, loading } = React.useContext(FeatureFlagsContext);

  // feature flags not yet loaded, don't render
  if (loading) {
    return null;
  }

  if (!isFeatureEnabled(feature)) {
    if (noMessage) {
      return null;
    }

    return (
      <Paragraph color="charcoal" padded size="large" textAlign="center">
        {t('page-not-available')}
      </Paragraph>
    );
  }

  if (typeof children === 'function') {
    return children(feature);
  }

  return children;
};

export default React.memo(FeatureFlag);
