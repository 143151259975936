import { ACCOUNT_TYPES } from 'common/model/debt';

export const buildAccountTypeContentPagePath = (accountType: string) =>
  `${accountType.toLowerCase().replace(/_/g, '-')}`;

/* Debt Plan */
export const BASE_PATH_DEBT_SOLUTION = '/debt';
export const DEBT_PLAN_LANDING_PAGE_PATH = BASE_PATH_DEBT_SOLUTION;

/* Month Transition */
export const DEBT_PLAN_MONTH_TRANSITION_PATH = `${BASE_PATH_DEBT_SOLUTION}/new-month`;
export const DEBT_PLAN_MONTH_TRANSITION_BUDGET_PATH = `${DEBT_PLAN_MONTH_TRANSITION_PATH}/budget`;
export const DEBT_PLAN_MONTH_TRANSITION_ACCOUNTS_PATH = `${DEBT_PLAN_MONTH_TRANSITION_PATH}/accounts`;
export const DEBT_PLAN_MONTH_TRANSITION_ALREADY_PAID_ACCOUNTS_PATH = `${DEBT_PLAN_MONTH_TRANSITION_PATH}/alreadyPaid`;
export const DEBT_MONTH_TRANSITION_CREATE_DEBT_BASE_PATH = `${DEBT_PLAN_MONTH_TRANSITION_PATH}/create`;
export const DEBT_MONTH_TRANSITION_CREATE_DEBT_PATH = `${DEBT_MONTH_TRANSITION_CREATE_DEBT_BASE_PATH}/:stepId`;
export const DEBT_MONTH_TRANSITION_BASE_EDIT_DEBT_PATH = `${DEBT_PLAN_MONTH_TRANSITION_PATH}/:debtId`;
export const DEBT_MONTH_TRANSITION_EDIT_DEBT_PATH = `${DEBT_PLAN_MONTH_TRANSITION_PATH}/:debtId/edit/:fieldName`;
export const DEBT_MONTH_TRANSITION_ACCOUNTS_EDIT_DEBT_PATH = `${DEBT_PLAN_MONTH_TRANSITION_ACCOUNTS_PATH}/:debtId/edit/:fieldName`;

/* Payment Plan Intro splash screen */
export const DEBT_PAYMENT_PLAN_INTRO_PATH = `${BASE_PATH_DEBT_SOLUTION}/payment-plan-intro`;

/* Payment Plan */
export const DEBT_PAYMENT_PLAN_PATH = `${BASE_PATH_DEBT_SOLUTION}/payment-plan`;
export const DEBT_PAYMENT_PLAN_VIEW_PATH = `${DEBT_PAYMENT_PLAN_PATH}/:year/:month`;
export const DEBT_PAYMENT_PLAN_HISTORICAL_TASK_BASE_PATH = `${DEBT_PAYMENT_PLAN_PATH}/tasks`;
export const DEBT_PAYMENT_PLAN_HISTORICAL_TASK_STATUS_PATH = `${DEBT_PAYMENT_PLAN_HISTORICAL_TASK_BASE_PATH}/:year/:month`;

/* Accounts */
export const DEBT_ACCOUNTS_PATH = `${BASE_PATH_DEBT_SOLUTION}/accounts`;
export const DEBT_ACCOUNTS_CREATE_BASE_PATH = `${DEBT_ACCOUNTS_PATH}/create`;
export const DEBT_ACCOUNTS_CREATE_PATH = `${DEBT_ACCOUNTS_CREATE_BASE_PATH}/:stepId`;
export const DEBT_ACCOUNTS_VIEW_PATH = `${DEBT_ACCOUNTS_PATH}/:debtId`;
export const DEBT_ACCOUNTS_EDIT_PATH = `${DEBT_ACCOUNTS_PATH}/:debtId/edit/:fieldName`;

/* Onboarding */
export const DEBT_ONBOARDING_PATH = `${BASE_PATH_DEBT_SOLUTION}/onboarding`;
export const DEBT_ONBOARDING_BUDGET_PATH = `${DEBT_ONBOARDING_PATH}/budget`;
export const DEBT_ONBOARDING_DISCLAIMER_PATH = `${DEBT_ONBOARDING_PATH}/disclaimer`;
export const DEBT_ONBOARDING_ACCOUNTS_PATH = `${DEBT_ONBOARDING_PATH}/accounts`;
export const DEBT_ONBOARDING_ACCOUNTS_CREATE_BASE_PATH = `${DEBT_ONBOARDING_ACCOUNTS_PATH}/create`;
export const DEBT_ONBOARDING_ACCOUNTS_CREATE_PATH = `${DEBT_ONBOARDING_ACCOUNTS_CREATE_BASE_PATH}/:stepId`;
export const DEBT_ONBOARDING_ACCOUNTS_VIEW_PATH = `${DEBT_ONBOARDING_ACCOUNTS_PATH}/:debtId`;
export const DEBT_ONBOARDING_ACCOUNTS_EDIT_PATH = `${DEBT_ONBOARDING_ACCOUNTS_PATH}/:debtId/edit/:fieldName`;
export const DEBT_ONBOARDING_ALREADY_PAID_ACCOUNTS_PATH = `${DEBT_ONBOARDING_ACCOUNTS_PATH}/alreadyPaid`;

/* Content pages */
export const DEBT_CONTENT_BASE_PATH = `${BASE_PATH_DEBT_SOLUTION}/content`;
export const TIPS_FOR_CALLING_CREDITORS_PATH = `${DEBT_CONTENT_BASE_PATH}/tips-for-calling-creditors`;

/* Explanation Cards Content pages */
export const RESOLVING_DELINQUENT_DEBT_HELP_PATH = `${DEBT_CONTENT_BASE_PATH}/resolving-delinquent-debts`;
export const PRIVATE_STUDENT_LOAN_HELP_PATH = `${DEBT_CONTENT_BASE_PATH}/${buildAccountTypeContentPagePath(
  ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN
)}`;
export const CREDIT_CARD_HELP_PATH = `${DEBT_CONTENT_BASE_PATH}/${buildAccountTypeContentPagePath(
  ACCOUNT_TYPES.CREDIT_CARD
)}`;
export const PERSONAL_LOAN_HELP_PATH = `${DEBT_CONTENT_BASE_PATH}/${buildAccountTypeContentPagePath(
  ACCOUNT_TYPES.PERSONAL_LOAN
)}`;
export const MEDICAL_BILL_HELP_PATH = `${DEBT_CONTENT_BASE_PATH}/${buildAccountTypeContentPagePath(
  ACCOUNT_TYPES.MEDICAL_BILL
)}`;
export const FEDERAL_STUDENT_LOAN_HELP_PATH = `${DEBT_CONTENT_BASE_PATH}/${buildAccountTypeContentPagePath(
  ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN
)}`;
