import { number, string, object } from 'yup';

const REQUIRED_ERROR_TEMPLATE = `This field is required.`;

const getQuestionsRules = schemaConstructor => {
  return {
    is: exist => exist,
    then: schemaConstructor().required(REQUIRED_ERROR_TEMPLATE),
    otherwise: schemaConstructor(),
  };
};

const questionsConfiguration = {
  previousStepId: string().when('$exist', getQuestionsRules(string)),
  nextStepId: string().when('$exist', getQuestionsRules(string)),
  dateVisited: number().when('$exist', getQuestionsRules(number)),
  dateCompleted: number().when('$exist', getQuestionsRules(number)),
};

/**
 * build a dynamic validation schema based on flowMetadata input
 * @param {Object} flowMetadata flowMetadata input
 */
const getFlowMetadataValidationSchema = flowMetadata => {
  return object()
    .shape(
      // Creates an object().shape() for each flow (medicalOnboardingFlow, autoOnboardingFlow)
      Object.keys(flowMetadata).reduce((flowSchema, flow) => {
        flowSchema[flow] = object().shape(
          // Creates an object().shape() for each step inside the flow (questions-first, questions-second)
          Object.keys(flowMetadata[flow]).reduce((stepSchema, step) => {
            stepSchema[step] = object().shape(questionsConfiguration);
            return stepSchema;
          }, {})
        );
        return flowSchema;
      }, {})
    )
    .noUnknown();
};

export default getFlowMetadataValidationSchema;
