/* eslint-disable */
// @ts-nocheck
import { Trans } from 'react-i18next';

import createQuestionFactory, { cloneQuestion } from '@/domain/questions/createQuestionFactory';
import { householdIncomeQ, loanAmountQ } from '@/domain/questions';
import { getAnswer } from '@/domain/questions/util';
import { getAnswerValidationRules } from '@/domain/questions/validation';

import i18n from '@/libs/i18n';

import { keyFilter, numberKeys, submitKeys } from '@/util/input';
import { trans } from '@/util/i18n';

import LegacyCurrencyInputField from '@/components/Field/LegacyCurrencyInputField';
import Link from '@/components/link';
import Paragraph from '@/components/Paragraph';
import RadioButtonGroupField from '@/components/RadioButtonGroup';
import SliderWithInput from '@/components/Slider/SliderWithInput';

import AmountInRetirementWhereFind from './modal/AmountInRetirementWhereFind';
import AmountInRetirementWhyAsk from './modal/AmountInRetirementWhyAsk';
import HouseholdIncomeWhyAsk from './modal/HouseholdIncomeWhyAsk';

const borrowRetirementFactory = createQuestionFactory('borrowRetirement', getAnswerValidationRules);

const allowedKeysForYearInputs = [...numberKeys, ...submitKeys];

export const amountInRetirementQ = borrowRetirementFactory({
  name: 'amountInRetirement',
  title: trans('i18n-questions:amount-retirement.title'),
  component: LegacyCurrencyInputField,
  links: [AmountInRetirementWhyAsk, AmountInRetirementWhereFind],
  defaultProps: {
    placeholder: 'i18n-questions:amount-placeholder',
  },
});

export const householdIncomeBFRQ = cloneQuestion(householdIncomeQ, {
  links: [HouseholdIncomeWhyAsk],
});

export const loanAmountBFRQ = cloneQuestion(loanAmountQ, {
  links: [AmountInRetirementWhyAsk],
});

export const averageMonthlyPayQ = borrowRetirementFactory({
  info: {
    content: (
      <>
        {trans(
          <Trans ns="i18n-questions" i18nKey="average-monthly-pay.info">
            Use your monthly pay from your current employer before taxes are taken out of your paycheck. If you are paid
            hourly, you can use this{' '}
            <Link icon={false} to="https://www.omnicalculator.com/finance/salary-to-hourly">
              calculator
            </Link>{' '}
            to get your monthly pay.
          </Trans>
        )}
      </>
    ),
    name: 'BorrowFromRetirementAverageMonthlyPay',
  },
  name: 'averageMonthlyPay',
  title: trans('i18n-questions:average-monthly-pay.title'),
  component: LegacyCurrencyInputField,
  defaultProps: {
    placeholder: 'i18n-questions:amount-placeholder',
  },
});

export const expectedReturnRateQ = borrowRetirementFactory({
  info: {
    content: (
      <>
        <Paragraph>{trans('i18n-questions:expected-return-rate.paragraph-1')}</Paragraph>
        <Paragraph>{trans('i18n-questions:expected-return-rate.paragraph-2')}</Paragraph>
      </>
    ),
    name: 'BorrowFromRetirementExpectedReturnRate',
  },
  name: 'expectedReturnRate',
  title: trans('i18n-questions:expected-return-rate.title'),
  subtitle: trans('i18n-questions:expected-return-rate.subtitle'),
  component: SliderWithInput,
  defaultProps: {
    inputSize: 'medium',
    label: '%',
    pattern: '[0-9.]*',
    step: 0.25,
    fast: true,
  },
});

export const loanInterestRateQ = borrowRetirementFactory({
  info: {
    content: (
      <>
        <Paragraph>{trans('i18n-questions:loan-interest-rate.paragraph-1')}</Paragraph>
        <Paragraph>{trans('i18n-questions:loan-interest-rate.paragraph-2')}</Paragraph>
      </>
    ),
    name: 'BorrowFromRetirementLoanInterestRate',
  },
  name: 'loanInterestRate',
  title: trans('i18n-questions:loan-interest-rate.title'),
  subtitle: trans('i18n-questions:loan-interest-rate.subtitle'),
  component: SliderWithInput,
  defaultProps: {
    inputSize: 'medium',
    label: '%',
    pattern: '[0-9.]*',
    step: 0.25,
    fast: true,
  },
});

export const loanRepayPeriodQ = borrowRetirementFactory({
  name: 'loanRepayPeriod',
  title: trans('i18n-questions:loan-repay-period.title'),
  component: SliderWithInput,
  defaultProps: {
    inputSize: 'medium',
    label: trans('i18n-default:years'),
    step: 1,
    pattern: '[0-9]*',
    onKeyPress: event => keyFilter(event, allowedKeysForYearInputs),
    fast: true,
  },
});

export const retirementContributionQ = borrowRetirementFactory({
  name: 'retirementContribution',
  title: trans('i18n-questions:retirement-contribution.title'),
  component: SliderWithInput,
  defaultProps: {
    inputSize: 'medium',
    label: '%',
    pattern: '[0-9.]*',
    step: 0.5,
    fast: true,
  },
});

export const retirementEmployerMatchQ = borrowRetirementFactory({
  name: 'retirementEmployerMatch',
  title: trans('i18n-questions:employer-match.title'),
  component: SliderWithInput,
  defaultProps: {
    inputSize: 'medium',
    label: '%',
    pattern: '[0-9.]*',
    step: 0.25,
    fast: true,
  },
});

export const willContinueContributingQ = borrowRetirementFactory({
  name: 'willContinueContributing',
  title: trans('i18n-questions:continue-contributing.title'),
  component: RadioButtonGroupField,
  defaultProps: {
    dataTestIdYes: 'willContinueContributing-Yes',
    dataTestIdNo: 'willContinueContributing-No',
  },
});

export const yearsToRetirementQ = borrowRetirementFactory({
  name: 'yearsToRetirement',
  title: trans('i18n-questions:years-retirement.title'),
  component: SliderWithInput,
  defaultProps: {
    inputSize: 'medium',
    label: trans('i18n-default:years'),
    step: 1,
    pattern: '[0-9]*',
    onKeyPress: event => keyFilter(event, allowedKeysForYearInputs),
    fast: true,
  },
});

export const contributingConditionalQ = {
  id: 'borrow from retirement conditional',
  getQuestions: formValues => {
    const willContinueContributing = getAnswer(willContinueContributingQ, { answers: formValues });

    if (willContinueContributing === 0) {
      return [averageMonthlyPayQ, retirementContributionQ, retirementEmployerMatchQ];
    }

    return [];
  },
};
