import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import { useEffect } from 'react';

/**
 * Reference to react internal component properties
 * this is used to clean up the dot notation after we exhaust the object recursively
 * ie: expenses.0.1.value.$$typeof
 *     expenses.0.1.value.type
 *     expenses.0.1.value.props.render
 * all of the above are react internal properties and we want to remove them
 */
const $$TYPEOF = '$$typeof';

export const getFieldErrorNames = (formikErrors: FormikErrors<FormikValues>): string | null => {
  /**
   * Transforms an object's keys to dot notation and returns an array of the transformed keys.
   * @param obj - The object to transform.
   * @param prefix - prefix for the transformed keys.
   * @param result - array to store the transformed keys.
   * @returns An array of transformed keys in dot notation.
   */
  const transformObjectToDotNotation = (obj: Record<string, string>, prefix = '', result: string[] = []): string[] => {
    Object.keys(obj).every(key => {
      let modifiedKey = key;
      const value = obj[key];

      // Skip if the value is falsy (null, undefined, empty string, etc.)
      if (!value) return true;

      // If the key is a number, wrap it with brackets for array-like notation
      if (Number.isInteger(+key)) {
        modifiedKey = `[${key}]`;
      }

      // Create the next key by combining the prefix and modified key
      const nextKey = prefix ? `${prefix}.${modifiedKey}` : modifiedKey;

      if (typeof value === 'object') {
        // Recursively transform nested objects
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        // Add the transformed key to the result array
        result.push(nextKey);
      }

      // Skip if the key is a react internal component property
      if (key === $$TYPEOF) return false;

      return true;
    });

    return result;
  };

  if (!formikErrors) return null;

  /* This logic does the following:
   * 1. Transforms the formikErrors object to an array of dot notation keys
   * 2. wrap the array like properties with brackets IE: expenses.0.1.value' => 'expenses.[0][1].value'
   * 2.1 wrap the array like properties with brackets IE: expenses.[0].expense.value => 'expenses[0].expense.value'
   * 3. get the last item in the array which represents the first error
   */

  const result = transformObjectToDotNotation(formikErrors as Record<string, string>).map(item => {
    const safeItem = item.replace(`.${$$TYPEOF}`, '');

    if (safeItem.includes('].[')) {
      return safeItem.replace('].[', '][');
    }

    if (safeItem.includes('.[')) {
      return safeItem.replace('.[', '[');
    }

    return safeItem;
  });

  return result.length ? result[0] : null;
};

export const scrollToError = (isValid: boolean, errors: FormikErrors<FormikValues>) => {
  if (isValid) return;

  const fieldErrorName = getFieldErrorNames(errors);

  if (!fieldErrorName) return;

  const element = document.querySelector<HTMLInputElement>(`input[name='${fieldErrorName}']`);

  if (!element) return;

  element.focus({ preventScroll: true });
  element.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
};

const ScrollToError = () => {
  const { submitCount, isValid, errors } = useFormikContext<FormikValues>();

  useEffect(() => {
    scrollToError(isValid, errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);

  return null;
};

export default ScrollToError;
