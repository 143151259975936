import Big from 'big.js';

import { TMonthsRemainingParams } from '@/types/domain/calcs';

/**
 * Determines the number of months remaining after the promo period.
 * CCARD-CALCS calc 2
 */
const calcMonthsRemaining = ({
  monthlyPayment, //                  $-MONTHLY
  postPromoInterestRate, //           %-POST-PROMO-RATE
  postPromoPeriodRemainingBalance, // $-REMAINING-AMT
  promoPeriod,
}: TMonthsRemainingParams) => {
  if (promoPeriod) {
    if (Big(postPromoInterestRate).eq(0)) {
      return Big(postPromoPeriodRemainingBalance).div(monthlyPayment);
    }

    // - (1/30)*(LN(1+(<$-REMAINING-AMT>/<$-MONTHLY>)*(1-(1+(<%-POST-PROMO-RATE>/365))^30)))/(LN(1+<%-POST-PROMO-RATE>/365))
    return Big(1)
      .div(30)
      .times(
        Math.log(
          Number(
            Big(1)
              .plus(
                Big(postPromoPeriodRemainingBalance)
                  .div(monthlyPayment)
                  .times(Big(1).minus(Big(1).plus(Big(postPromoInterestRate).div(365)).pow(30)))
              )
              .toFixed(20)
          )
        )
      )
      .div(Math.log(Number(Big(1).plus(Big(postPromoInterestRate).div(365)))))
      .times(-1);
  }

  return undefined;
};

export default calcMonthsRemaining;
