/* eslint-disable */
// @ts-nocheck
import Question from '@/components/Question';

import { loanInterestRateQ, loanRepayPeriodQ } from '../questions';

import classes from './Form.module.less';

const Form = ({ fromAssessment }) => (
  <div className={classes.leftSideWrapper}>
    <Question question={loanInterestRateQ} readOnly={fromAssessment} />
    <Question question={loanRepayPeriodQ} readOnly={fromAssessment} />
  </div>
);

export default Form;
