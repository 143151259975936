/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';

import monthlyLoanPaymentCalc from '@/domain/calcs/common/calculateMonthlyLoanPayment';
import loanAmountCalc from '@/domain/calcs/borrowFromRetirementAccounts/calculateLoanAmount';
import fVOfMonthlyLoanPaymentsCalc from '@/domain/calcs/borrowFromRetirementAccounts/calculateFVOfMonthlyLoanPayments';
import fVOfTheBorrowedFundsIfNotBorrowedCalc from '@/domain/calcs/borrowFromRetirementAccounts/calculateFVOfTheBorrowedFundsIfNotBorrowed';
import missedSavingsOfTheLoanCalc from '@/domain/calcs/borrowFromRetirementAccounts/calculateTheMissedSavingsOfTheLoan';
import maximumPossibleLossCalc from '@/domain/calcs/borrowFromRetirementAccounts/calculateMaximumPossibleLoss';
import fVOfPlanContributionsForgoneDuringLoanRepaymentPeriodCalc from '@/domain/calcs/borrowFromRetirementAccounts/calculateFVOfPlanContributionsForgoneDuringLoanRepaymentPeriod';
import totalMissedSavingsAtRetirementCalc from '@/domain/calcs/borrowFromRetirementAccounts/calculateTheTotalMissedSavingsAtRetirement';

import { parseValues } from './util';

export const calculateLoanAmount = createSelector(
  parseValues,
  // calc 1
  loanAmountCalc
);

export const calculateMonthlyLoanPayment = createSelector(
  parseValues,
  calculateLoanAmount,
  ({ loanInterestRate, loanPaybackTerm }, { loanAmount }) =>
    // calc 2
    monthlyLoanPaymentCalc({
      loanAmount,
      loanInterestRate,
      loanPaybackTerm,
    })
);

const calculateFutureValueMonthlyLoanPayments = createSelector(
  parseValues,
  calculateMonthlyLoanPayment,
  ({ loanPaybackTerm, yearsToRetirement, retirementAccountExpectedRateOfReturn }, monthlyLoanPayment) =>
    // calc 3
    fVOfMonthlyLoanPaymentsCalc({
      loanPaybackTerm,
      yearsToRetirement,
      retirementAccountExpectedRateOfReturn,
      monthlyLoanPayment,
    })
);

export const calculateFutureValueIfNotBorrowed = createSelector(
  parseValues,
  calculateLoanAmount,
  ({ yearsToRetirement, retirementAccountExpectedRateOfReturn }, { loanAmount }) =>
    // calc 4
    fVOfTheBorrowedFundsIfNotBorrowedCalc({
      loanAmount,
      retirementAccountExpectedRateOfReturn,
      yearsToRetirement,
    })
);

export const calculateForgoneEarnings = createSelector(
  parseValues,
  calculateLoanAmount,
  calculateFutureValueIfNotBorrowed,
  calculateFutureValueMonthlyLoanPayments,
  calculateMonthlyLoanPayment,
  ({ loanPaybackTerm }, { loanAmount }, loanAmountAtRetirement, { futureValueLoanRetirement }, monthlyLoanPayment) =>
    // calc 5
    missedSavingsOfTheLoanCalc({
      futureValueLoanRetirement,
      loanAmount,
      loanAmountAtRetirement,
      loanPaybackTerm,
      monthlyLoanPayment,
    })
);

export const calculateMaxPossibleLoss = createSelector(parseValues, maximumPossibleLossCalc);

const calculateFutureValueForgoneDuringRepayment = createSelector(
  parseValues,
  ({
    earnings,
    loanPaybackTerm,
    retirementAccountExpectedRateOfReturn,
    retirementPlanContribution,
    retirementPlanEmployerMatch,
    yearsToRetirement,
  }) =>
    // calc 6
    fVOfPlanContributionsForgoneDuringLoanRepaymentPeriodCalc({
      earnings,
      loanPaybackTerm,
      retirementAccountExpectedRateOfReturn,
      retirementPlanContribution,
      retirementPlanEmployerMatch,
      yearsToRetirement,
    })
);

export const calculateAccountBalanceImpact = createSelector(
  parseValues,
  calculateFutureValueForgoneDuringRepayment,
  calculateForgoneEarnings,
  (values, { futureValueOfContributionsAtRetirement }, { savingsShortfallAtRetirement }) =>
    // calc 7
    totalMissedSavingsAtRetirementCalc({
      FVOfContributionsAtRetirement: futureValueOfContributionsAtRetirement,
      savingsGapAtRetirement: savingsShortfallAtRetirement,
    })
);
