import React from 'react';

import Heading from '../Heading';
import Paragraph from '../Paragraph';

import classes from './styles.module.less';

const SectionHeading = ({ title, subtitle }) => {
  return (
    <div className={classes.sectionHeading}>
      {title && <Heading className={classes.title}>{title}</Heading>}
      {subtitle && <Paragraph noMargin>{subtitle}</Paragraph>}
    </div>
  );
};

export default SectionHeading;
