/*
 * This file contains a map that associate a key with an external
 * url used by the RedirectScreen and ExternalLink component
 */

type TTarget = {
  url: Function | string;
};

type TRedirectionTarget = Function | TTarget;

/**
 * Each entry in the map can be just a string representing the url
 * or an object with url and timeout attributes
 */
const REDIRECT_MAP_PATHS = {
  modus: 'http://moduscreate.com',
  google: {
    url: 'http://google.com',
    timeout: 3000,
  },
};

/**
 * a getter for the Map
 * return a RedirectMapEntryT, if there is no entry for the given name
 * return an empty RedirectMapEntryT with 2000ms as default timeout
 */
const getRedirectParams = (url: string, state) => {
  const defaultEntry = { url: '', timeout: 2000 };
  const name = url?.split('/').pop(); // get the last part of the string that contains the name
  const targetEntry = (name && REDIRECT_MAP_PATHS[name]) as TRedirectionTarget;

  if (targetEntry) {
    if (typeof targetEntry === 'string') {
      return { ...defaultEntry, url: targetEntry };
    }
    if (typeof targetEntry === 'function') {
      return { ...defaultEntry, url: targetEntry(state) };
    }
    if (typeof targetEntry === 'object' && typeof targetEntry.url === 'function') {
      return {
        ...defaultEntry,
        ...targetEntry,
        url: targetEntry.url(state),
      };
    }
    return targetEntry;
  }
  return defaultEntry;
};

export default getRedirectParams;
