/* eslint-disable */
// @ts-nocheck
import { FieldArray, connect, getIn } from 'formik';
import { Card } from 'semantic-ui-react';

import CardCheckbox from '@/components/CardCheckbox';
import FieldErrorMessage from '@/components/Field/FieldErrorMessage';

const CardCheckboxField = connect(({ fields, formik, name, alternate }) => {
  const error = getIn(formik.errors, name);
  const values = getIn(formik.values, name);

  return (
    <>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <Card.Group itemsPerRow={3}>
            {fields.map(item => (
              <CardCheckbox
                key={item.value}
                name={name}
                value={item.value}
                image={item.image}
                description={item.description}
                header={item.header}
                checked={values.includes(item.value)}
                alternate={alternate}
                onChange={e => {
                  if (item.clearCheckboxes && e.target.checked) {
                    formik.values[name] = [];
                    arrayHelpers.push(item.value);
                    return;
                  }

                  if (e.target.checked) {
                    // get the value of the item resetting the other checkboxes
                    const fieldWithClearValue = fields.find(field => field.clearCheckboxes);

                    // TODO improve on this, it might be possible that there's more than one reset button
                    if (fieldWithClearValue && values.includes(fieldWithClearValue.value)) {
                      arrayHelpers.remove(values.indexOf(fieldWithClearValue.value));
                    }

                    arrayHelpers.push(item.value);
                  } else {
                    if (!values.length) {
                      return;
                    }

                    const index = values.indexOf(item.value);
                    arrayHelpers.remove(index);
                  }
                }}
                data-testid={item['data-testid']}
              />
            ))}
          </Card.Group>
        )}
      />
      <FieldErrorMessage error={error} name={name} />
    </>
  );
});

export default CardCheckboxField;
