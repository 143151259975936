/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import { getAnswer } from '@/domain/questions/util';

import useUserState from '@/hooks/useUserState';

import ContainerWithStickyContent from '@/components/ContainerWithStickyContent';
import CustomContainer from '@/components/CustomContainer';
import Divider from '@/components/Divider';
import Heading from '@/components/Heading';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';

import calculatorIcon from '@/images/calculatorIcon.svg';

import {
  averageMonthlyPayQ,
  expectedReturnRateQ,
  loanInterestRateQ,
  loanRepayPeriodQ,
  retirementContributionQ,
  retirementEmployerMatchQ,
  willContinueContributingQ,
  yearsToRetirementQ,
} from '../questions';

import Estimate from './Estimate';
import Form from './Form';

const initialValues = {
  [averageMonthlyPayQ.id]: 0,
  [expectedReturnRateQ.id]: 7.5,
  [loanInterestRateQ.id]: 6.75,
  [loanRepayPeriodQ.id]: 3,
  [retirementContributionQ.id]: 0,
  [retirementEmployerMatchQ.id]: 0,
  [willContinueContributingQ.id]: null,
  [yearsToRetirementQ.id]: 20,
};

const CostCalc = ({ fromAssessment = false, size = 'wide' }) => {
  const { t } = useTranslation('i18n-unplanned');
  const { state, loading, error } = useUserState();
  if (loading) return null;
  if (error) return null;

  const formValues = {
    [averageMonthlyPayQ.id]: getAnswer(averageMonthlyPayQ, state),
    [expectedReturnRateQ.id]: getAnswer(expectedReturnRateQ, state),
    [loanInterestRateQ.id]: getAnswer(loanInterestRateQ, state),
    [loanRepayPeriodQ.id]: getAnswer(loanRepayPeriodQ, state),
    [retirementContributionQ.id]: getAnswer(retirementContributionQ, state),
    [retirementEmployerMatchQ.id]: getAnswer(retirementEmployerMatchQ, state),
    [willContinueContributingQ.id]: getAnswer(willContinueContributingQ, state),
    [yearsToRetirementQ.id]: getAnswer(yearsToRetirementQ, state),
  };

  // start off with state values, then merge initial values onto
  Object.keys(initialValues).forEach(key => {
    if (formValues[key] === undefined) {
      formValues[key] = initialValues[key];
    }
  });

  return (
    <CustomContainer noMargin verticallyPadded>
      <Heading as="h1">{t('cost-calc.heading')}</Heading>
      <Divider tiny />
      <QuestionsForm fullWidth backButton={false} initialValues={formValues}>
        {({ updateForm, values }) => {
          const answerWrapper = fromAssessment ? state : { answers: values };

          return (
            <>
              <ContainerWithStickyContent
                disableSticky={fromAssessment}
                leftSideContent={
                  <Form answerWrapper={answerWrapper} fromAssessment={fromAssessment} updateForm={updateForm} />
                }
                rightSideContent={<Estimate answerWrapper={answerWrapper} />}
              />
              {fromAssessment ? null : <WizardUpNext imageSrc={calculatorIcon}>{t('cost-calc.wizard')}</WizardUpNext>}
            </>
          );
        }}
      </QuestionsForm>
    </CustomContainer>
  );
};

export default CostCalc;
