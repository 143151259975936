import { AnyEventObject, StateNodeConfig } from 'xstate';
import { QueryOptions } from '@apollo/client';

import {
  hasNoActiveOrArchivedPlan,
  isMonthTransition,
  isThereADraftPlan,
  isComingFromDashBoard,
  shouldGoToAddAccount,
  hasInvalidDebts,
} from '@/domain/stateMachines/debtManager/debtsPlanMachineHelpers';
import { checkIfAnyDebtsPending } from '@/domain/debtsPlan';

import { RETRY_ATTEMPTS, RETRY_DELAY, retryPromise } from '@/util/function';

import { GET_COMBINED_DEBTS_PLAN } from '@/graphql/queries';

import type { InitialContextT } from '.';

function loadCombinedPlansAndDebts(context: InitialContextT) {
  const { userId, client } = context;

  const query: QueryOptions = {
    query: GET_COMBINED_DEBTS_PLAN,
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
  };

  return retryPromise(() => client.query(query), RETRY_DELAY, RETRY_ATTEMPTS);
}

/**
 * Loads required data and determines the next state
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadingStates: StateNodeConfig<InitialContextT, any, AnyEventObject> = {
  id: 'loading',
  // @ts-expect-error To fix
  invoke: {
    id: 'loadCombinedPlansAndDebts',
    src: loadCombinedPlansAndDebts,
    onDone: [
      {
        target: 'loaded.debtManager.refresh',
        cond: hasInvalidDebts,
      },
      {
        target: 'loaded.debtManager.onboarding',
        cond: hasNoActiveOrArchivedPlan,
        actions: ['updateDebtsContext', 'setLatestPlan', 'setDraftPaymentPlan'],
      },
      {
        target: 'loaded.debtManager.onboarding',
        cond: checkIfAnyDebtsPending,
        actions: ['updateDebtsContext', 'setLatestPlan', 'setDraftPaymentPlan'],
      },
      {
        target: 'loaded.debtManager.monthTransition.paymentPlanEditing.debtsList',
        cond: isThereADraftPlan,
        actions: ['updateDebtsContext', 'setLatestPlan', 'setDraftPaymentPlan'],
      },
      {
        target: 'loaded.debtManager.monthTransition',
        cond: isMonthTransition,
        actions: ['updateDebtsContext', 'setLatestPlan', 'setSelectedDate'],
      },
      // alternative state without the navigation action in order to access screen from DM directly (TODO: find a better solution)
      {
        target: 'loaded.debtManager.paymentPlan',
        cond: isComingFromDashBoard,
        actions: ['updateDebtsContext', 'setLatestPlan'],
      },
      // alternative state that takes us straight to the add account page when coming from the dashboard
      {
        target: 'loaded.debtManager.paymentPlan',
        cond: shouldGoToAddAccount,
        actions: ['updateDebtsContext', 'setLatestPlan', 'navigateToPaymentPlanAddAccount'],
      },
      {
        target: 'loaded.debtManager.paymentPlan',
        // TODO: we removed 'navigateToPaymentPlan' as the last action here, cause it redirects after
        // the budget url was entered. It's temporal solution. Note: it also affects the current DM navigation,
        // example: when user(with the payment plan) clicks on DM link it navigates to /debt instead of /debt/payment-plan.
        actions: ['updateDebtsContext', 'setLatestPlan'],
      },
    ],
    onError: {
      target: 'initial',
      actions: 'notifyError',
    },
  },
};

export default loadingStates;
