import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import classes from './styles.module.less';

/**
 * Simple placeholder component with no animation.
 * @param {String} [className]
 */
const Placeholder: FunctionComponent<{ className: string }> = ({ className }) => (
  <div className={cx(className, classes.placeholder)} />
);

export default Placeholder;
