/* eslint-disable */
// @ts-nocheck
import isIos from 'is-ios';
import {
  NB_EXPENSES_TYPES,
  mapExpenseTypeToId,
  NB_ESSENTIALS_TYPES,
  NB_SAVINGS_TYPES,
  NB_SAVINGS_TYPE_ID,
  NB_ESSENTIALS_EXPENSES_TYPE_ID,
  NB_DEBT_PAYMENTS_TYPES,
} from 'common/model/budget';

import { mapExpenseTypeToLabel, mapExpenseTypeToI18nLabel, expensesValidationRulesFrontend } from '@/domain/budget';

import { getPropsFromValidationRules } from '@/util/validation';

import CurrencyInputField from '@/components/Field/CurrencyInputField';
import LegacyCurrencyInputField from '@/components/Field/LegacyCurrencyInputField';
import { Budget } from '@/types/generated/globalTypes';

const getTestId = (id: string) => `${id}_Expense_Field`;

/**
 * @param {Object} expenses
 * @returns {Object}
 */
export const validateEmptyExpenses = expenses => {
  const newExpenses = { ...expenses };
  delete newExpenses.__typename; // Delete typename if it exists in some requests
  Object.entries(newExpenses).forEach(([id, amount]) => {
    if (typeof amount !== 'number') {
      newExpenses[id] = 0;
    }
  });

  return newExpenses;
};

/**
 * @description This function is used to validate the custom expenses
 */
export const validateCustomExpenses = (expenses: Budget['customExpenses']): Budget['customExpenses'] => {
  if (!expenses) return {};

  return expenses.map(expense => {
    const newExpense = { ...expense };
    delete newExpense.__typename; // Delete typename if it exists in some requests

    if (newExpense.category === 'ADDITIONAL_EXPENSES') {
      newExpense.category = 'ESSENTIALS';
    }

    if (
      newExpense.category !== 'DEBT_PAYMENTS' &&
      newExpense.category !== 'SAVINGS' &&
      newExpense.category !== 'ESSENTIALS'
    ) {
      newExpense.category = 'ESSENTIALS';
    }

    if (typeof newExpense.amount !== 'number') {
      newExpense.amount = 0;
    }

    return newExpense;
  });
};

export const getInitialExpenseFormValues = () =>
  Object.assign(
    {},
    ...Object.keys(NB_EXPENSES_TYPES)
      .filter(category => category !== 'DEBT_PAYMENTS')
      .flatMap(category =>
        Object.values(NB_EXPENSES_TYPES[category]).flatMap(type => {
          const id = mapExpenseTypeToId(category, type);
          return { [id]: 0 };
        })
      )
  );

export const createExpenseField = (
  expenseCategory: 'ESSENTIALS' | 'DEBT_PAYMENTS' | 'SAVINGS',
  expenseType: string
) => {
  const id = mapExpenseTypeToId(expenseCategory, expenseType);
  return {
    id,
    label: mapExpenseTypeToLabel(expenseCategory, expenseType),
    i18nLabel: mapExpenseTypeToI18nLabel(expenseCategory, expenseType),
    Component: isIos ? LegacyCurrencyInputField : CurrencyInputField,
    validationRules: expensesValidationRulesFrontend(),
    props: {
      name: id,
      'data-testid': getTestId(id),
      autoSelect: true,
      ...getPropsFromValidationRules(expensesValidationRulesFrontend()),
    },
  };
};

// ESSENTIALS
export const rentExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.RENT);
export const personalCareExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.PERSONAL_CARE);
export const childcareExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.CHILD_CARE);
export const utilitiesExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.UTILITIES);
export const transportationExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.TRANSPORTATION);
export const groceriesExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.GROCERIES);
export const phoneExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.PHONE);
export const healthServicesExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.HEALTH_SERVICES);
export const cableAndWifiExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.CABLE_AND_WIFI);
export const adultcareExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.ADULT_CARE);
export const autoInsuranceExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.AUTO);
const homeOwnerInsuranceExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.HOME_OWNER);
const renterInsuranceExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.RENTER);
const healthInsuranceExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.HEALTH);
export const lifeInsuranceExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.LIFE);
const disabilityInsuranceExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.DISABILITY);
export const otherInsuranceExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.OTHER);
export const petsExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.PETS);
const workExpensesField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.WORK_EXPENSES);
const charityExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.CHARITY);
const homeServicesExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.HOME_SERVICES);
const funExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.FUN);
const subscriptionsExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.SUBSCRIPTIONS);
export const clothingAndGoodExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.CLOTHING_AND_GOODS);
const eatingOutExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.EATING_OUT);
const travelExpenseField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.TRAVEL);
const otherExpensesField = createExpenseField('ESSENTIALS', NB_ESSENTIALS_TYPES.OTHER_EXPENSES);

// DEBT_PAYMENTS
export const mortgageExpenseField = createExpenseField('DEBT_PAYMENTS', NB_DEBT_PAYMENTS_TYPES.MORTGAGE);
export const homeEquityLoanExpenseField = createExpenseField('DEBT_PAYMENTS', NB_DEBT_PAYMENTS_TYPES.HOME_EQUITY_LOAN);
const autoLoanExpenseField = createExpenseField('DEBT_PAYMENTS', NB_DEBT_PAYMENTS_TYPES.AUTO_LOAN);
const creditCardExpenseField = createExpenseField('DEBT_PAYMENTS', NB_DEBT_PAYMENTS_TYPES.CREDIT_CARD);
const federalStudentLoanDebtExpenseField = createExpenseField(
  'DEBT_PAYMENTS',
  NB_DEBT_PAYMENTS_TYPES.FEDERAL_STUDENT_LOAN
);
const privateStudentLoanDebtExpenseField = createExpenseField(
  'DEBT_PAYMENTS',
  NB_DEBT_PAYMENTS_TYPES.PRIVATE_STUDENT_LOAN
);
const personalLoanDebtExpenseField = createExpenseField('DEBT_PAYMENTS', NB_DEBT_PAYMENTS_TYPES.PERSONAL_LOAN);
const medicalBillExpenseField = createExpenseField('DEBT_PAYMENTS', NB_DEBT_PAYMENTS_TYPES.MEDICAL_BILL);
// SAVINGS
export const emergencyFundExpenseField = createExpenseField('SAVINGS', NB_SAVINGS_TYPES.EMERGENCY_FUND);
const educationSavingsExpenseField = createExpenseField('SAVINGS', NB_SAVINGS_TYPES.EDUCATION_SAVINGS);
const retirement400XExpenseField = createExpenseField('SAVINGS', NB_SAVINGS_TYPES.RETIREMENT_400X);
const retirementIRAExpenseField = createExpenseField('SAVINGS', NB_SAVINGS_TYPES.RETIREMENT_IRA);
export const otherSavingsExpenseField = createExpenseField('SAVINGS', NB_SAVINGS_TYPES.OTHER_SAVINGS);
export const largePurchaseExpenseField = createExpenseField('SAVINGS', NB_SAVINGS_TYPES.LARGE_PURCHASE);

// The expenses fields keys are the expenses ids.
const NB_EXPENSES_FIELDS = {
  // ESSENTIALS
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.RENT]]: rentExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.PERSONAL_CARE]]: personalCareExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.UTILITIES]]: utilitiesExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.TRANSPORTATION]]: transportationExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.GROCERIES]]: groceriesExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.PHONE]]: phoneExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.HEALTH_SERVICES]]: healthServicesExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.CABLE_AND_WIFI]]: cableAndWifiExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.CHILD_CARE]]: childcareExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.ADULT_CARE]]: adultcareExpenseField,

  // DEBT_PAYMENTS
  [NB_DEBT_PAYMENTS_TYPES.MORTGAGE]: mortgageExpenseField,
  [NB_DEBT_PAYMENTS_TYPES.HOME_EQUITY_LOAN]: homeEquityLoanExpenseField,
  [NB_DEBT_PAYMENTS_TYPES.AUTO_LOAN]: autoLoanExpenseField,
  [NB_DEBT_PAYMENTS_TYPES.CREDIT_CARD]: creditCardExpenseField,
  [NB_DEBT_PAYMENTS_TYPES.FEDERAL_STUDENT_LOAN]: federalStudentLoanDebtExpenseField,
  [NB_DEBT_PAYMENTS_TYPES.PRIVATE_STUDENT_LOAN]: privateStudentLoanDebtExpenseField,
  [NB_DEBT_PAYMENTS_TYPES.PERSONAL_LOAN]: personalLoanDebtExpenseField,
  [NB_DEBT_PAYMENTS_TYPES.MEDICAL_BILL]: medicalBillExpenseField,
  // SAVINGS
  [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EMERGENCY_FUND]]: emergencyFundExpenseField,
  [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EDUCATION_SAVINGS]]: educationSavingsExpenseField,
  [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_400X]]: retirement400XExpenseField,
  [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_IRA]]: retirementIRAExpenseField,
  [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.OTHER_SAVINGS]]: otherSavingsExpenseField,
  [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.LARGE_PURCHASE]]: largePurchaseExpenseField,

  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.AUTO]]: autoInsuranceExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.HOME_OWNER]]: homeOwnerInsuranceExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.RENTER]]: renterInsuranceExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.HEALTH]]: healthInsuranceExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.LIFE]]: lifeInsuranceExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.DISABILITY]]: disabilityInsuranceExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.OTHER]]: otherInsuranceExpenseField,

  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.PETS]]: petsExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.WORK_EXPENSES]]: workExpensesField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.CHARITY]]: charityExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.HOME_SERVICES]]: homeServicesExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.FUN]]: funExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.SUBSCRIPTIONS]]: subscriptionsExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.CLOTHING_AND_GOODS]]: clothingAndGoodExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.EATING_OUT]]: eatingOutExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.TRAVEL]]: travelExpenseField,
  [NB_ESSENTIALS_EXPENSES_TYPE_ID[NB_ESSENTIALS_TYPES.OTHER_EXPENSES]]: otherExpensesField,
};

export const getSelectedExpensesFields = (selectedExpenses: { type: string; value: number; id: string }[]) =>
  selectedExpenses.map(expense => NB_EXPENSES_FIELDS[expense.type || expense.id]);
