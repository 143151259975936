import Big from 'big.js';

import { TRecord } from '@/types/common';

type NumberFormat = Intl.NumberFormat | { format: (amount: string | number) => string | number };
const numberFormatterCache: TRecord<NumberFormat> = {};

export const getVanillaFormatter = (decimalCount: string | number = 2, decimal = '.', thousands = ',') => {
  const format = (amount: string | number) => {
    try {
      decimalCount = Math.abs(decimalCount as number);
      // eslint-disable-next-line no-restricted-globals
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      // eslint-disable-next-line radix
      const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      return `$${
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
        (decimalCount
          ? decimal +
            Math.abs(Number(amount) - Number(i))
              .toFixed(decimalCount)
              .slice(2)
          : '')
      }`;
    } catch (e) {
      return 0;
    }
  };

  return { format };
};

export const getNumberFormatter = (
  fractionDigits: number,
  notation: Intl.NumberFormatOptions['notation'] = 'standard'
) => {
  const instance = numberFormatterCache[fractionDigits];

  if (instance) {
    return instance;
  }

  let newInstance: NumberFormat;

  // not cached, let's create and cache a suitable formatter
  if (!window.Intl?.NumberFormat) {
    // older safari
    newInstance = getVanillaFormatter(fractionDigits);
  } else {
    // every normal browser
    newInstance = new window.Intl.NumberFormat('en-US', {
      currency: 'USD',
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
      style: 'currency',
      notation,
      ...(notation === 'compact' && { maximumSignificantDigits: 2 }),
    });
  }

  numberFormatterCache[fractionDigits] = newInstance;

  return newInstance;
};

export const toUSD = (
  value: Big | number,
  fractionDigits: number | string = 'auto',
  notation: Intl.NumberFormatOptions['notation'] = 'standard'
) => {
  let decimalDigits = 2;

  // By default, set decimalDigits based on wether value is divisible by 1
  if (fractionDigits === 'auto') {
    if (value instanceof Big && (value.mod(1) as unknown) === 0) {
      decimalDigits = 0;
    }
    if ((value as number) % 1 === 0) {
      decimalDigits = 0;
    }
  } else {
    decimalDigits = fractionDigits as number;
  }

  if (value instanceof Big) {
    value = Number(value.toFixed(decimalDigits));
  }

  // return zero if value cannot be converted to a number
  if (!Number.isFinite(value)) {
    return 0;
  }

  const formatter = getNumberFormatter(decimalDigits, notation);

  return formatter.format(value);
};

export const currencyInputFormatter = (value: string) => {
  if (Number.isNaN(value)) return '';

  const amount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  }).format(Number(value) / 100);

  return `${amount}`;
};
