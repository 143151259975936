/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Card } from 'semantic-ui-react';

import { getAnswer } from '@/domain/questions/util';

import PartialCoverage from '@/pages/BorrowFromRetirementAccountsFlow/common/PartialCoverage';

import { SuccessBox } from '@/components/InformationBox';
import Paragraph from '@/components/Paragraph';
import Placeholder from '@/components/Placeholder';
import withDebouncedRender from '@/components/withDebouncedRender';

import { amountInRetirementQ, loanAmountBFRQ } from '../questions';
import { calculateLoanAmount, isPartiallyCovered } from '../selectors';

import classes from './Results.module.less';

const Empty = () => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <>
      <Paragraph as="div" className={cx(classes.section, classes.bottomBorder)}>
        <Placeholder />
      </Paragraph>
      <Paragraph as="div" className={cx(classes.section, classes.bottomBorder)}>
        <Placeholder className={classes.spacedPlaceholder} />
        <Placeholder />
      </Paragraph>
      <Paragraph as="div" className={classes.section}>
        <Placeholder className={classes.spacedPlaceholder} />
        <Placeholder className={classes.spacedPlaceholder} />
        <Placeholder className={classes.spacedPlaceholder} />
        <Paragraph size="mini" textAlign="center" className={classes.resultTip}>
          {t('results.paragraph-1')}
        </Paragraph>
      </Paragraph>
    </>
  );
};

const CalcResults = ({ answerWrapper, calculations }) => {
  const { t } = useTranslation('i18n-unplanned');
  if (isPartiallyCovered(answerWrapper)) {
    const amountNeeded = Number(getAnswer(loanAmountBFRQ, answerWrapper));

    return (
      <PartialCoverage
        amountNeeded={amountNeeded}
        dataTestId="partialCoverageInfoBox"
        maxAmountUserCanBorrow={calculations.maxAmountUserCanBorrow}
      />
    );
  }

  return (
    <>
      <SuccessBox
        icon="check circle"
        className={classes.coversHeader}
        content={t('results.content')}
        data-testid="fullCoverageInfoBox"
      />
      <Paragraph noHorizontalMargin className={classes.coversInfo}>
        {t('results.paragraph-2')}
      </Paragraph>
    </>
  );
};

const Results = ({ answerWrapper }) => {
  const retirementAccountBalance = getAnswer(amountInRetirementQ, answerWrapper);
  const amountNeeded = getAnswer(loanAmountBFRQ, answerWrapper);
  const calculations =
    !retirementAccountBalance || !amountNeeded
      ? null
      : calculateLoanAmount({
          amountNeeded,
          retirementAccountBalance,
        });

  return (
    <Card raised={false} className={classes.resultCard}>
      {calculations == null ? <Empty /> : <CalcResults answerWrapper={answerWrapper} calculations={calculations} />}
    </Card>
  );
};

export default withDebouncedRender(Results);
