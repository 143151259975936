/* eslint-disable */
// @ts-nocheck

import { getStepTitle } from '@/domain/WizardConfig';
import { householdIncomeQ, locationQ } from '@/domain/questions';
import {
  BASE_PATH_FREE_FLOW,
  FREE_FLOW_QUESTIONS_FIRST,
  FREE_FLOW_QUESTIONS_SECOND,
  FREE_FLOW_OPTIONS,
  MY_PLAN_PATH,
} from '@/domain/paths';

import { trans } from '@/util/i18n';

import IconLink from '@/components/IconLink';
import AuthControls from '@/components/AuthControls';
import PrerequisiteQuestions from '@/components/PrerequisiteQuestions';

const header = {
  renderLeftItem: () => (
    <IconLink href={MY_PLAN_PATH} icon="clipboard outline">
      {trans('my-plan')}
    </IconLink>
  ),
  renderCenterItem: getStepTitle,
  renderRightItem: () => <AuthControls />,
};

const steps = {
  'questions-first': {
    id: 'questions-first',
    questions: [householdIncomeQ],
    path: FREE_FLOW_QUESTIONS_FIRST,
    progress: {
      current: 1,
      total: 2,
    },
    component: () => (
      <PrerequisiteQuestions
        heading={trans('free-funds-flow.first-question.heading')}
        subheader={
          <>
            <strong>{trans('i18n-questions:household-income.title')}</strong>
            <br />
            {trans('i18n-default:why-do-you-ask-income.paragraph')}
          </>
        }
      />
    ),
  },
  'questions-second': {
    id: 'questions-second',
    questions: [locationQ],
    path: FREE_FLOW_QUESTIONS_SECOND,
    progress: {
      current: 2,
      total: 2,
    },
    component: () => (
      <PrerequisiteQuestions
        heading={trans('free-funds-flow.second-question.heading')}
        subheader={
          <>
            <strong>{trans('i18n-questions:location.title')}</strong>
            <br />
            {trans('i18n-default:why-do-you-ask-location.paragraph')}
          </>
        }
      />
    ),
  },
};

const initialState = {
  stepId: 'questions-first',
};

const freeFundsFlowConfig = {
  name: 'freeFundsFlow',
  isFlowEnabled: () => true,
  path: `${BASE_PATH_FREE_FLOW}/:id`,
  header,
  steps,
  reducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'questions-first':
        return { ...state, stepId: 'questions-second' };
      case 'questions-second':
        return { ...state, nextLocation: { pathname: FREE_FLOW_OPTIONS } };
      default:
        return initialState;
    }
  },
  reverseReducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'questions-first':
        return { ...state, nextLocation: { pathname: MY_PLAN_PATH } };
      case 'questions-second':
        return { ...state, stepId: 'questions-first' };
      default:
        return initialState;
    }
  },
};

export default freeFundsFlowConfig;
