import React from 'react';
import { Divider as SemanticDivider } from 'semantic-ui-react';
import cx from 'classnames';

import classes from './Divider.module.less';

/**
 * Semantic UI divider customizable with props
 * tiny - produces a tiny yellow divider
 * centered - centers the divider horizontally in the given line
 * hidden - adds spacing but does not render any visible item
 * noMargin - removes spacing
 */
const Divider = ({
  tiny = false,
  smallMargin = false,
  centered = false,
  hidden = false,
  vertical = false,
  noMargin = false,
  noMarginTop = false,
  noMarginBottom = false,
  className = '',
}) => (
  <SemanticDivider
    hidden={hidden}
    vertical={vertical}
    className={cx(classes.divider, className, {
      [classes.tiny]: tiny,
      [classes.centered]: centered,
      [classes.smallMargin]: smallMargin,
      [classes.noMargin]: noMargin,
      [classes.noMarginTop]: noMarginTop,
      [classes.noMarginBottom]: noMarginBottom,
    })}
  />
);

export default Divider;
