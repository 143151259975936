import { gql } from '@apollo/client';

import {
  $Goal$,
  $Debt$,
  $Budget$,
  $PaymentPlan$,
  $Transactions$,
  $Savings$,
  $Activity$,
  $Expense$,
  $BudgetSnapshot$,
} from './fragments';

export const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    getFeatureFlags
  }
`;

export const LIST_DEBTS = gql`
  ${$Debt$}
  query ListDebts($userId: UUID) {
    debts(userId: $userId) {
      ...Debt
    }
  }
`;

export const GET_PAYMENT_PLAN = gql`
  ${$PaymentPlan$}
  query GetPaymentPlan($userId: UUID!, $month: String!, $year: String!) {
    paymentPlan(userId: $userId, month: $month, year: $year) {
      ...PaymentPlan
    }
  }
`;

export const GET_COMBINED_DEBTS_PLAN = gql`
  ${$Debt$}
  ${$PaymentPlan$}
  query GetCombinedDebtsPlan($userId: UUID!, $month: String, $year: String) {
    debts(userId: $userId) {
      ...Debt
    }
    paymentPlans(userId: $userId, month: $month, year: $year) {
      ...PaymentPlan
    }
  }
`;

export const GET_BUDGET = gql`
  ${$Budget$}
  query GetBudget($userId: UUID!) {
    budget(userId: $userId) {
      ...Budget
    }
  }
`;

export const LIST_EXPENSES = gql`
  ${$Expense$}
  query ListExpenses($userId: UUID) {
    expenses(userId: $userId) {
      ...Expense
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  ${$Debt$}
  ${$Goal$}
  ${$Budget$}
  ${$Expense$}
  ${$PaymentPlan$}
  ${$Transactions$}
  query GetDashboardData(
    $userId: UUID!
    $lastMonth: String
    $lastYear: String
    $month: String
    $year: String
    $startingYear: String
    $startingMonth: String
    $expenses: [String]
  ) {
    budget(userId: $userId) {
      ...Budget
    }

    debts(userId: $userId) {
      ...Debt
    }

    paymentPlans(userId: $userId, month: $month, year: $year) {
      ...PaymentPlan
    }

    expenses(userId: $userId) {
      ...Expense
    }

    goals(userId: $userId) {
      ...Goal
    }

    savingsTransactions: transactions(
      userId: $userId
      year: $year
      month: $month
      startingYear: $startingYear
      startingMonth: $startingMonth
      expenses: $expenses
    ) {
      ...Transactions
    }

    lastMonthTransactions: transactions(userId: $userId, year: $lastYear, month: $lastMonth) {
      ...Transactions
    }
  }
`;

export const LIST_GOALS = gql`
  ${$Goal$}
  query ListGoals($userId: UUID) {
    goals(userId: $userId) {
      ...Goal
    }
  }
`;

export const LIST_ACTIVITIES = gql`
  ${$Activity$}
  query ListActivities($userId: UUID, $savingsLargePurchaseId: UUID) {
    activities(userId: $userId, savingsLargePurchaseId: $savingsLargePurchaseId) {
      ...Activity
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  ${$Transactions$}
  query ListTransactions($userId: UUID!, $month: String, $year: String) {
    transactions(userId: $userId, year: $year, month: $month) {
      ...Transactions
    }
  }
`;

export const GET_BUDGET_AND_LIST_TRANSACTIONS = gql`
  ${$Budget$}
  ${$Transactions$}
  query GetBudgetAndListTransactions($userId: UUID!, $month: String, $year: String) {
    budget(userId: $userId) {
      ...Budget
    }

    transactions(userId: $userId, year: $year, month: $month) {
      ...Transactions
    }
  }
`;

export const GET_SAVINGS = gql`
  ${$Savings$}
  query GetSavings($userId: UUID!, $savingsId: UUID!) {
    savings(userId: $userId, savingsId: $savingsId) {
      ...Savings
    }
  }
`;

export const LIST_SAVINGS = gql`
  ${$Savings$}
  query ListSavings($userId: UUID!) {
    listSavings(userId: $userId) {
      ...Savings
    }
  }
`;
export const LIST_BUDGET_SNAPSHOTS = gql`
  ${$BudgetSnapshot$}
  query listBudgetSnapshots($userId: UUID!, $year: String, $month: String) {
    listBudgetSnapshots(userId: $userId, year: $year, month: $month) {
      ...BudgetSnapshot
    }
  }
`;
