/* eslint-disable */
// @ts-nocheck
import { EventObject, StateNodeConfig } from 'xstate';
import { SAVINGS_TYPES } from 'common/model/savings';

import { isBudgetCreated, isBudgetLoaded, isBudgetCompleted } from '@/domain/budget';

import { TAnalytics } from '@/services/analytics';

import { not } from '@/util/function';

import { Budget } from '@/types/generated/globalTypes';

import { hasPaymentPlan, isInEditFlow } from '../debtManager/debtsPlanMachineHelpers';
import { InitialContextT, isAuthenticated } from '..';

import {
  clearBudget,
  editTransactions,
  loadBudget,
  loadBudgetAndTransactions,
  removeTransaction,
  upsertBudget,
  upsertBudgetAndCreatePaymentPlanWithAvailableFunds,
  upsertBudgetAndUpdatePaymentPlanAvailableFunds,
  upsertTransactions,
  isDebtPlanMachineIdle,
  createBudgetSnapshot,
  loadArchivedBudgets,
  upsertBudgetIntegrationFlag,
  automatedBudgetTransition,
} from './helpers';

type TrackFinishLargePurchaseOnboardingEvent = EventObject & {
  data: {
    savingsType: (typeof SAVINGS_TYPES)[keyof typeof SAVINGS_TYPES];
  };
};

export const trackStartBudget = (context: InitialContextT) => {
  const { analytics } = context;
  (analytics as TAnalytics).trackEvent({ name: 'startSetBudgetFlow' });
};

export const trackFinishBudget = (context: InitialContextT) => {
  const { analytics } = context;
  (analytics as TAnalytics).trackEvent({ name: 'finishSetBudgetFlow' });
};

export const trackStartSavings = (context: InitialContextT) => {
  const { analytics } = context;
  (analytics as TAnalytics).trackEvent({ name: 'startSetSavingsFlow' });
};

export const trackFinishLargePurchaseOnboarding = (
  context: InitialContextT,
  event: TrackFinishLargePurchaseOnboardingEvent
) => {
  const { analytics } = context;
  const { savingsType } = event.data;
  if (savingsType === SAVINGS_TYPES.LARGE_PURCHASE) {
    (analytics as TAnalytics).trackEvent({ name: 'finishLargePurchaseOnboarding' });
  }
};

const onError = {
  target: 'initial',
  actions: ['notifyError'],
};

// 'initial',
// options = {
//   navigation: { income: '', selectExpenses: '', enterExpensesAmounts: '', overview: '', edit: '', estimates: '' },
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const budgetMachineStates: StateNodeConfig<InitialContextT, any, EventObject & { budget?: Budget }> = {
  id: 'budgetMachineStates',
  initial: 'initial',
  states: {
    initial: {
      // Loading the budget & savings whenever budgetMachineStates is triggered
      always: [{ target: 'loadingBudgetOnly', cond: not(isBudgetLoaded) }],
      on: {
        BACK_TO_MONTHLY_INCOME: {
          actions: 'navigateToBudgetMonthlyIncome',
        },
        SKIP_BUDGET: {
          actions: 'navigateToPaymentPlan',
        },
        REVIEW_BUDGET: [
          {
            actions: 'navigateToBudgetToEnterMonthlyExpensesAmounts',
            cond: isBudgetCompleted,
          },
          {
            actions: 'navigateToBudgetMonthlyIncome',
          },
        ],
        LOAD_BUDGET: 'loadingBudget',
        UPSERT_BUDGET: 'upsertingBudget',
        EDIT_FLOW_BUDGET_INCOME: 'editFlowBudgetIncome',
        EDIT_FLOW_SELECT_EXPENSES: {
          actions: 'navigateToEditBudgetToEnterMonthlyExpensesAmounts',
        },
        EDIT_FLOW_BUDGET_AND_CREATE_PAYMENT_PLAN_WITH_AVAILABLE_FUNDS:
          'editFlowBudgetAndCreatingPaymentPlanWithAvailableFunds',
        EDIT_FLOW_BUDGET_AND_PAYMENT_PLAN_AVAILABLE_FUNDS: 'editFlowBudgetAndPaymentPlanAvailableFunds',
        SELECT_EXPENSES: {
          actions: 'navigateToBudgetToEnterMonthlyExpensesAmounts',
        },
        UPSERT_BUDGET_AND_CREATE_PAYMENT_PLAN_WITH_AVAILABLE_FUNDS:
          'upsertingBudgetAndCreatingPaymentPlanWithAvailableFunds',
        UPSERT_BUDGET_AND_CREATE_PAYMENT_PLAN_WITH_AVAILABLE_FUNDS_SAVINGS:
          'upsertingBudgetAndCreatingPaymentPlanWithAvailableFundsSavings',
        UPSERT_BUDGET_AND_CREATE_PAYMENT_PLAN_WITH_AVAILABLE_FUNDS_NEW_MONTH:
          'upsertingBudgetAndCreatingPaymentPlanWithAvailableFundsNewMonth',
        UPSERT_BUDGET_ONLY_NEW_MONTH: 'upsertingBudgetOnlyNewMonth',
        UPDATE_BUDGET_AND_PAYMENT_PLAN_AVAILABLE_FUNDS: 'updatingBudgetAndPaymentPlanAvailableFunds',
        UPDATE_BUDGET_AND_PAYMENT_PLAN_AVAILABLE_FUNDS_DM: 'updatingBudgetAndPaymentPlanAvailableFundsDM',
        UPDATE_BUDGET_AND_PAYMENT_PLAN_AVAILABLE_FUNDS_SAVINGS: 'updatingBudgetAndPaymentPlanAvailableFundsSavings',
        UPDATE_BUDGET_AND_PAYMENT_PLAN_AVAILABLE_FUNDS_NEW_MONTH: 'updatingBudgetAndPaymentPlanAvailableFundsNewMonth',
        UPSERT_BUDGET_ONLY: 'upsertingBudgetOnly',
        UPSERT_BUDGET_AND_GO_TO_EDIT: 'upsertingBudgetAndGoToEdit',
        UPSERT_BUDGET_AND_GO_TO_ENTER_EXPENSES: 'upsertingBudgetAndGoToEnterExpenses',
        UPDATE_BUDGET_INTEGRATION_FLAG: 'updatingBudgetIntegrationFlag',
        MT_RESET_FLOW_SUBMIT_INCOME: 'monthTransitionResetFlowSubmitIncome',
        CLEAR_BUDGET: 'clearBudget',
        CLEAR_BUDGET_ONLY: 'clearBudgetOnly',
        CLEAR_BUDGET_NEW_MONTH: 'clearBudgetNewMonth',
        ADD_TRANSACTION: 'addingTransaction',
        EDIT_TRANSACTION: 'editingTransaction',
        REMOVE_TRANSACTION: 'removingTransaction',
        ADD_DEPOSIT: 'addDeposit',
        CREATE_BUDGET_SNAPSHOT: 'creatingBudgetSnapshot',
        LOAD_ARCHIVED_BUDGETS: 'loadingArchivedBudgets',
        AUTOMATED_BUDGET_TRANSITION: 'automatedBudgetTransition',
        /* UPSERT_TRANSACTIONS_AND_UPDATE_PAYMENT_PLAN_AMOUNTS_PAID:
            'upsertingTransactionsAndUpdatingPaymentPlanAmountsPaid', */
      },
    },
    loadingBudgetOnly: {
      invoke: [
        {
          id: 'loadingBudgetOnly',
          src: loadBudgetAndTransactions,
          onDone: {
            target: 'initial',
            actions: ['setLoadedBudget'],
          },
          onError: {
            target: 'initial',
            actions: ['setLoadedBudget'],
          },
        },
      ],
    },
    loadingArchivedBudgets: {
      invoke: [
        {
          id: 'loadingArchivedBudgets',
          src: loadArchivedBudgets,
          onDone: {
            target: 'initial',
            actions: ['setLoadedArchivedBudget'],
          },
          onError: {
            target: 'initial',
            actions: ['setLoadedArchivedBudget'],
          },
        },
      ],
    },
    loadingBudget: {
      invoke: [
        {
          id: 'loadingBudget',
          src: loadBudgetAndTransactions,
          onDone: [
            {
              cond: isBudgetCreated,
              target: 'initial',
              actions: ['setLoadedBudget', 'navigateToBudgetEdit'],
            },
            {
              target: 'initial',
              actions: ['trackStartBudget', 'setLoadedBudget', 'navigateToBudgetMonthlyIncome'],
            },
          ],
          onError,
        },
      ],
    },
    upsertingBudget: {
      invoke: {
        id: 'upsertingBudget',
        src: upsertBudget,
        onDone: {
          target: 'initial',
          actions: ['trackStartBudget', 'setBudget', 'navigateToBudgetToSelectMonthlyExpenses'],
        },
      },
    },
    upsertingBudgetOnly: {
      invoke: {
        id: 'upsertingBudgetOnly',
        src: upsertBudget,
        onDone: {
          target: 'initial',
          actions: ['setBudget'],
        },
      },
    },
    upsertingBudgetAndCreatingPaymentPlanWithAvailableFunds: {
      invoke: {
        id: 'updatingBudgetAndCreatingPaymentPlan',
        src: upsertBudgetAndCreatePaymentPlanWithAvailableFunds,
        onDone: [
          {
            target: 'initial',
            actions: ['trackFinishBudget', 'setCreatedPlan', 'setBudget', 'navigateToBudgetStatistics'],
          },
        ],
        onError,
      },
    },
    upsertingBudgetAndCreatingPaymentPlanWithAvailableFundsSavings: {
      invoke: {
        id: 'updatingBudgetAndCreatingPaymentPlanSavings',
        src: upsertBudgetAndCreatePaymentPlanWithAvailableFunds,
        onDone: [
          {
            target: 'initial',
            actions: ['trackFinishBudget', 'setCreatedPlan', 'setBudget', 'navigateToSavingsConfirmBudget'],
          },
        ],
        onError,
      },
    },
    upsertingBudgetAndCreatingPaymentPlanWithAvailableFundsNewMonth: {
      invoke: {
        id: 'updatingBudgetAndCreatingPaymentPlanNewMonth',
        src: upsertBudgetAndCreatePaymentPlanWithAvailableFunds,
        onDone: [
          {
            target: 'initial',
            actions: ['setCreatedPlan', 'setBudget', 'clearResetBudgetStatus'],
          },
        ],
        onError,
      },
    },
    updatingBudgetAndPaymentPlanAvailableFunds: {
      invoke: {
        id: 'updatingBudgetAndPaymentPlan',
        src: upsertBudgetAndUpdatePaymentPlanAvailableFunds,
        onDone: [
          {
            target: 'initial',
            actions: ['trackFinishBudget', 'setBudgetAndPaymentPlan', 'navigateToBudgetStatistics'],
          },
        ],
        onError,
      },
    },
    updatingBudgetAndPaymentPlanAvailableFundsDM: {
      invoke: {
        id: 'updatingBudgetAndPaymentPlanDM',
        src: upsertBudgetAndUpdatePaymentPlanAvailableFunds,
        onDone: [
          {
            cond: isDebtPlanMachineIdle,
            target: 'initial',
            actions: ['trackFinishBudget', 'setBudgetAndPaymentPlan', 'navigateToOnboardingBudgetPage'],
          },
          {
            cond: hasPaymentPlan,
            target: 'initial',
            actions: ['trackFinishBudget', 'setBudgetAndPaymentPlan'],
          },
          {
            target: 'initial',
            actions: ['trackFinishBudget', 'setBudgetAndPaymentPlan', 'setConfirmedBudget'], // TODO: Remove hack
          },
        ],
        onError,
      },
    },
    updatingBudgetAndPaymentPlanAvailableFundsSavings: {
      invoke: {
        id: 'updatingBudgetAndPaymentPlanSavings',
        src: upsertBudgetAndUpdatePaymentPlanAvailableFunds,
        onDone: [
          {
            target: 'initial',
            actions: ['trackFinishBudget', 'setBudgetAndPaymentPlan', 'navigateToSavingsConfirmBudget'],
          },
        ],
        onError,
      },
    },
    monthTransitionResetFlowSubmitIncome: {
      invoke: {
        id: 'monthTransitionResetFlowSubmitIncome',
        src: upsertBudget,
        onDone: [
          {
            target: 'initial',
            actions: ['setBudget'],
          },
        ],
        onError,
      },
    },
    updatingBudgetAndPaymentPlanAvailableFundsNewMonth: {
      invoke: {
        id: 'updatingBudgetAndPaymentPlanNewMonth',
        src: upsertBudgetAndUpdatePaymentPlanAvailableFunds,
        onDone: [
          {
            target: 'initial',
            actions: ['setBudgetAndPaymentPlan'],
          },
        ],
        onError,
      },
    },
    upsertingBudgetOnlyNewMonth: {
      invoke: {
        id: 'upsertingBudgetOnlyNewMonth',
        src: upsertBudget,
        onDone: [
          {
            target: 'initial',
            actions: ['setBudget'],
          },
        ],
        onError,
      },
    },
    clearBudget: {
      invoke: {
        id: 'clearingBudget',
        src: clearBudget,
        onDone: {
          target: 'initial',
          actions: ['clearBudget', 'navigateToBudgetMonthlyIncome'],
        },
        onError,
      },
    },
    clearBudgetOnly: {
      invoke: {
        id: 'clearingBudgetOnly',
        src: clearBudget,
        onDone: {
          target: 'initial',
          actions: ['clearBudget'],
        },
        onError,
      },
    },
    clearBudgetNewMonth: {
      invoke: {
        id: 'clearingBudgetNewMonth',
        src: clearBudget,
        onDone: {
          target: 'initial',
          actions: ['clearBudget'],
        },
        onError,
      },
    },
    editFlowBudgetIncome: {
      invoke: {
        id: 'editFlowBudgetIncome',
        src: upsertBudget,
        onDone: {
          target: 'initial',
          actions: ['setBudget', 'navigateFromUEditFlowBudgetIncome'],
        },
        onError,
      },
    },
    editFlowBudgetAndCreatingPaymentPlanWithAvailableFunds: {
      invoke: {
        id: 'editFlowBudgetAndCreatingPaymentPlanWithAvailableFunds',
        src: upsertBudgetAndCreatePaymentPlanWithAvailableFunds,
        onDone: [
          {
            target: 'initial',
            actions: ['setCreatedPlan', 'setBudget', 'navigateToBudgetEdit'],
          },
        ],
        onError,
      },
    },
    editFlowBudgetAndPaymentPlanAvailableFunds: {
      invoke: {
        id: 'editFlowBudgetAndPaymentPlanAvailableFunds',
        src: upsertBudgetAndUpdatePaymentPlanAvailableFunds,
        onDone: [
          {
            cond: isInEditFlow,
            target: 'initial',
            actions: ['setBudgetAndPaymentPlan', 'navigateToBudgetEdit'],
          },
          {
            target: 'initial',
            actions: ['setBudgetAndPaymentPlan'],
          },
        ],
        onError,
      },
    },
    upsertingBudgetAndGoToEdit: {
      invoke: {
        id: 'upsertingBudgetAndGoToEdit',
        src: upsertBudget,
        onDone: {
          target: 'initial',
          actions: ['setBudget', 'navigateToBudgetEdit'],
        },
        onError,
      },
    },
    upsertingBudgetAndGoToEnterExpenses: {
      invoke: {
        id: 'upsertingBudgetAndGoToEnterExpenses',
        src: upsertBudget,
        onDone: {
          target: 'initial',
          actions: ['setBudget', 'navigateToEditBudgetToEnterMonthlyExpensesAmounts'],
        },
        onError,
      },
    },
    removingTransaction: {
      invoke: {
        id: 'removingTransaction',
        src: removeTransaction,
        onDone: {
          target: 'initial',
          actions: ['setTransactions'],
        },
        onError,
      },
    },
    addingTransaction: {
      invoke: {
        id: 'addingTransaction',
        src: upsertTransactions,
        onDone: [
          {
            target: 'initial',
            cond: (_, event) => !!event.data.navigate,
            actions: ['setTransactions', 'navigateBackFromDeposit'],
          },
          {
            target: 'initial',
            actions: ['setTransactions', 'navigateToBudgetOverview'],
          },
        ],
        onError,
      },
    },
    /* upsertingTransactionsAndUpdatingPaymentPlanAmountsPaid: {
        invoke: {
          id: 'upsertingTransactionsAndUpdatingPaymentPlanAmountsPaid',
          src: upsertTransactionsAndUpdatePaymentPlanAmountsPaid,
          onDone: {
            target: 'initial',
            actions: ['setTransactionsAndPaymentPlan', 'navigateToBudgetOverview'],
          },
          onError,
        },
      }, */
    editingTransaction: {
      invoke: {
        id: 'editingTransaction',
        src: editTransactions,
        onDone: {
          target: 'initial',
          actions: ['setTransactions', 'navigateToBudgetOverviewTransactionTab'],
        },
        onError,
      },
    },
    addDeposit: {
      invoke: {
        id: 'addDeposit',
        src: upsertTransactions,
        onDone: {
          target: 'initial',
          actions: ['setTransactions', 'navigateBackFromDeposit'],
        },
        onError,
      },
    },
    creatingBudgetSnapshot: {
      invoke: {
        id: 'creatingBudgetSnapshot',
        src: createBudgetSnapshot,
        onDone: {
          target: 'initial',
          actions: ['setTransactions'],
        },
        onError,
      },
    },
    updatingBudgetIntegrationFlag: {
      invoke: {
        id: 'updatingBudgetIntegrationFlag',
        src: upsertBudgetIntegrationFlag,
        onDone: {
          target: 'initial',
          actions: ['setBudget'],
        },
        onError,
      },
    },
    automatedBudgetTransition: {
      invoke: {
        id: 'automatedBudgetTransition',
        src: automatedBudgetTransition,
        onDone: {
          target: 'creatingBudgetSnapshot',
          actions: ['setBudget'],
        },
        onError,
      },
    },
  },
};

export default budgetMachineStates;
