/* eslint-disable */
// @ts-nocheck

import React from 'react';
import { createSelector } from 'reselect';

import { currentVerticalsQ } from '@/domain/questions';
import { sortByLastModified, TExpense } from '@/domain/expense';
import {
  BILLS_LANDING_PAGE_PATH,
  BUDGET_LANDING_PAGE_PATH,
  GOALS_LANDING_PAGE_PATH,
  QUESTIONS_NICKNAME,
  SAVINGS_LANDING_PAGE_PATH,
} from '@/domain/paths';
import { BASE_PATH_DEBT_SOLUTION } from '@/domain/paths/debt';

import useUserState from '@/hooks/useUserState';
import useSelectExpense from '@/hooks/useSelectExpense';
import useCreateExpense from '@/hooks/useCreateExpense';

import { AnalyticsContext } from '@/services/analytics';

import history from '@/util/history';

import { getExpenseStatus, getExpenseAccessLink } from '@/pages/Dashboard/UnplannedExpenses/selectors';

import Question from '@/components/Question';
import Form from '@/components/Form';

import { TVertical } from '@/types/domain/UserStates';

export function filterExpensesByVertical(expenses: TExpense[], vertical: TVertical) {
  return expenses.filter(expense => expense.vertical === vertical);
}

export function getLastModifiedExpense(expenses: TExpense[]) {
  return sortByLastModified(expenses).pop();
}

export function getFirstAccessibleLinkOfExpense(expense, status) {
  return expense ? getExpenseAccessLink(expense, status) : QUESTIONS_NICKNAME;
}

export const lastModifiedExpenseOfVerticalSelector = createSelector(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  (state, vertical) => filterExpensesByVertical(state.expenses, vertical),
  getLastModifiedExpense
);

export const firstAccessibleLinkOfExpenseSelector = createSelector(
  expense => expense,
  expense => (expense ? getExpenseStatus(expense) : null),
  getFirstAccessibleLinkOfExpense
);

function sendAARPanalytics(redirectTo) {
  switch (redirectTo) {
    case BILLS_LANDING_PAGE_PATH:
      window?.gtag?.('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-4652332/dmpna0/money002+standard',
      });
      break;
    case BASE_PATH_DEBT_SOLUTION:
      window?.gtag?.('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-4652332/dmpna0/money00+standard',
      });
      break;
    case BUDGET_LANDING_PAGE_PATH:
      window?.gtag?.('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-4652332/dmpna0/money000+standard',
      });
      break;
    case GOALS_LANDING_PAGE_PATH:
      window?.gtag?.('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-4652332/dmpna0/money001+standard',
      });
      break;
    case SAVINGS_LANDING_PAGE_PATH:
      window?.gtag?.('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-4652332/dmpna0/money0+standard',
      });
      break;
    default:
      break;
  }
}

/**
 * Wraps a button used to submit a form. Saves the current vertical name to user state.
 * Current vertical can be accessed as a regular question answer. Recorded value is an array of vertical IDs.
 * @typedef Props
 * @property {UserState} - user state object
 * @property {string} formClassName - CSS class name applied to the inner form
 * @property {string} redirectTo - path to be redirected to after a successful submit
 * @property {string} vertical - vertical name to be set on submit
 * @property {string} inputId - id to be passed to underlying input component
 * @property {React.Node} children - button component with type="submit"
 * @param {Props} props
 */
const VerticalSelector = ({ formClassName = '', redirectTo, vertical, children, inputId }) => {
  // since we're passing the selector as argument on useUserState , we're going to get only one expense in the state or undefined
  const { state: expenseToBeSelected, loading, error } = useUserState(lastModifiedExpenseOfVerticalSelector, vertical);

  const analytics = React.useContext(AnalyticsContext);
  const createExpense = useCreateExpense();
  const selectExpense = useSelectExpense();

  if (loading || error) return null;

  const redirectToExpenseAvailableLocation = firstAccessibleLinkOfExpenseSelector(expenseToBeSelected);

  const expenseHandler = expenseToBeSelected
    ? () => selectExpense(expenseToBeSelected)
    : async () => {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await createExpense({ vertical });
    };

  /*
    Exception - this component is rendered multiple times on the landing pages,
    so we need to be able to use different inputIds to satisfy a11y requirements
    and not have multiple items with the same ID on one page
  */
  const inputProps = { ...currentVerticalsQ.props, id: inputId };
  const questionConfig = { ...currentVerticalsQ, props: inputProps };

  return (
    <Form
      className={formClassName}
      onSubmit={async (values, { setSubmitting }) => {
        sendAARPanalytics(redirectTo);

        if (!vertical && redirectTo) {
          return history.push(redirectTo as string);
        }

        setSubmitting(true);

        try {
          // eslint-disable-next-line @typescript-eslint/await-thenable
          await expenseHandler();

          setSubmitting(false);
          analytics.trackEvent({
            name: 'enteredVertical',
            payload: {
              vertical,
            },
          });
          history.push(redirectToExpenseAvailableLocation);
        } catch (e) {
          setSubmitting(false);
          /* eslint-disable-next-line no-console */
          console.error(e);
        }

        return true;
      }}
      // saved as array so we can support multiple verticals later
      initialValues={{ [currentVerticalsQ.id]: [vertical] }}
    >
      {() => (
        <>
          <Question question={questionConfig} />
          {children}
        </>
      )}
    </Form>
  );
};

export default VerticalSelector;
