/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { useParams } from 'react-router';
import { conditionalHomeQ } from '@/domain/questions/home';
import {
  BILLS_LANDING_PAGE_PATH,
  BASE_PATH_ONBOARDING_WIZARD,
  MY_PLAN_PATH,
  DASHBOARD_UNPLANNED_EXPENSES_PATH,
} from '@/domain/paths';
import { expenseNicknameQ } from '@/domain/questions';

import { trans } from '@/util/i18n';

import IconLink from '@/components/IconLink';
import AuthControls from '@/components/AuthControls';
import PrerequisiteQuestions from '@/components/PrerequisiteQuestions';
import MyPlanButtonText from '@/components/QuestionsWizard/MyPlanButtonText';

const header = {
  renderLeftItem: () => (
    <IconLink href={BILLS_LANDING_PAGE_PATH} icon="home">
      {trans('home')}
    </IconLink>
  ),
  renderCenterItem: step => {
    const progress = step?.progress;
    return progress
      ? trans('step-progress', {
          transProgressCurrent: progress.current,
          transProgressTotal: progress.total,
        })
      : '';
  },
  renderRightItem: () => <AuthControls />,
};

const ConfiguredQuestions = React.memo(() => {
  const { id }: { id: string } = useParams();

  if (id == 'questions-nickname') {
    return (
      <PrerequisiteQuestions
        heading={trans('start-action-plan')}
        subheader={trans('start-action-plan.home-expenses')}
      />
    );
  }

  return (
    <PrerequisiteQuestions
      heading={trans('about-your-situation')}
      subheader={trans('start-action-plan.home-expenses.step-2')}
    />
  );
});

export const steps = {
  'questions-nickname': {
    id: 'questions-nickname',
    questions: [expenseNicknameQ],
    path: `${BASE_PATH_ONBOARDING_WIZARD}/questions-nickname`,
    component: ConfiguredQuestions,
  },
  'questions-first': {
    id: 'questions-first',
    questions: [conditionalHomeQ],
    path: `${BASE_PATH_ONBOARDING_WIZARD}/questions-first`,
    progress: {
      current: 1,
      total: 1,
    },
    component: ConfiguredQuestions,
    nextButton: <MyPlanButtonText />,
  },
};

const initialState = {
  answers: {},
  stepId: 'questions-nickname',
};

const homeOnboardingConfig = {
  name: 'homeOnboarding',
  isFlowEnabled: () => true,
  path: `${BASE_PATH_ONBOARDING_WIZARD}/:id`,
  header,
  steps,
  reducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'questions-nickname':
        return {
          ...state,
          stepId: 'questions-first',
        };
      case 'questions-first':
        return {
          ...state,
          nextLocation: { pathname: MY_PLAN_PATH },
        };
      default:
        return initialState;
    }
  },
  reverseReducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'questions-nickname':
        return {
          ...state,
          nextLocation: { pathname: DASHBOARD_UNPLANNED_EXPENSES_PATH },
        };
      case 'questions-first':
        return { ...state, stepId: 'questions-nickname' };
      default:
        return initialState;
    }
  },
};

export default homeOnboardingConfig;
