/* eslint-disable */
// @ts-nocheck
import { loanAmountQ } from '@/domain/questions';
import { getAnswer } from '@/domain/questions/util';
import { isFlowCompleted } from '@/domain/WizardConfig';

import { assetsQ, creditScoreQ, retirementQ } from '@/pages/FundingFlow/questions';

export const isPersonalLoansFlowCompleted = ({ flowMetadata }, flowConfig) =>
  isFlowCompleted(flowMetadata[flowConfig.name], flowConfig);

export const getHomeEquityFlowCompleted = ({ flowMetadata }, flowConfig) =>
  isFlowCompleted(flowMetadata.homeEquityFlow, flowConfig);

export const isHomeEquityHidden = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  const ownsHome = assetsAnswer && !assetsAnswer.includes('hasHome'); // hide

  return ownsHome;
};

export const isHomeEquityAvailable = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  const loanAmountAnswer = getAnswer(loanAmountQ, userState);
  const creditScoreAnswer = getAnswer(creditScoreQ, userState);

  const ownsHome = assetsAnswer && assetsAnswer.includes('hasHome'); // hide
  const loanAmountAbove10k = loanAmountAnswer && loanAmountAnswer >= 10000; // is applicable
  const creditScoreBetterThanFair = () => {
    switch (creditScoreAnswer) {
      case 'poor':
        return false;
      case 'dontKnow':
        return false;
      default:
        return true;
    }
  };
  return ownsHome && loanAmountAbove10k && creditScoreBetterThanFair();
};

export const isTapYourSavingsAvailable = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  return assetsAnswer.includes('hasSavings');
};

export const isTapYourInvestmentAccountsAvailable = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  return assetsAnswer.includes('hasInvestment');
};

export const isGetPersonalLoanAvailable = userState => {
  const creditScoreAnswer = getAnswer(creditScoreQ, userState);
  const creditScoreBetterThanGood = () => {
    switch (creditScoreAnswer) {
      case 'good':
      case 'veryGood':
      case 'excellent':
        return true;
      default:
        return false;
    }
  };
  return creditScoreBetterThanGood();
};

export const isUseCreditCardAvailable = userState => {
  const loanAmountAnswer = getAnswer(loanAmountQ, userState);
  const creditScoreAnswer = getAnswer(creditScoreQ, userState);
  const loanAmountLessThan = loanAmountAnswer && loanAmountAnswer <= 7500;
  const creditScoreBetterThanVeryGood = () => {
    switch (creditScoreAnswer) {
      case 'veryGood':
      case 'excellent':
        return true;
      default:
        return false;
    }
  };
  return loanAmountLessThan && creditScoreBetterThanVeryGood();
};

export const isBorrowFromRetirementHidden = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);

  const doesNotHaveRetirement = assetsAnswer && !assetsAnswer.includes('hasRetirement');
  return doesNotHaveRetirement;
};

export const isBorrowFromRetirementAvailable = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  const retirementAnswer = getAnswer(retirementQ, userState);

  const hasRetirement = assetsAnswer && assetsAnswer.includes('hasRetirement');
  const hasFromCurrentEmployer = retirementAnswer && retirementAnswer.includes('hasCurrentEmployer');
  return hasRetirement && hasFromCurrentEmployer;
};

export const isTapYourLifeInsurancePolicyAvailable = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  const hasLifeInsurance = assetsAnswer && assetsAnswer.includes('hasInsurance');
  return hasLifeInsurance;
};

export const isWithdrawFromRetirementPlanAvailable = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  const retirementAnswer = getAnswer(retirementQ, userState);
  const hasRetirement = assetsAnswer && assetsAnswer.includes('hasRetirement');
  const hasFromCurrentEmployer = retirementAnswer && retirementAnswer.includes('hasPastEmployer');
  return hasRetirement && hasFromCurrentEmployer;
};

export const isWithdrawFromYourIRAAvailable = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  const retirementAnswer = getAnswer(retirementQ, userState);
  const hasRetirement = assetsAnswer && assetsAnswer.includes('hasRetirement');
  const hasIRA = retirementAnswer ? retirementAnswer.includes('hasIra') : false;
  return hasRetirement && hasIRA;
};

export const isRefinanceYourMortgageAvailable = userState => {
  const assetsAnswer = getAnswer(assetsQ, userState);
  const loanAmountAnswer = getAnswer(loanAmountQ, userState);

  const ownsHome = assetsAnswer && assetsAnswer.includes('hasHome');
  const loanAmountAbove10k = loanAmountAnswer && loanAmountAnswer >= 10000;
  return ownsHome && loanAmountAbove10k;
};
