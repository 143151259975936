import Big from 'big.js';

import { TMonthsPaybackParams } from '@/types/domain/calcs';

/**
 * Calculate the number of months to payback.
 * CCARD-CALCS calc 2
 */
const calcMonthsPayback = ({
  chargeAmount,
  interestRate,
  monthlyPayment,
  monthsRemaining,
  promoMonthsUsed,
}: TMonthsPaybackParams): Big => {
  if (monthsRemaining == null) {
    // no to promo

    // - (1/30) * (LN(1+(<$-AMT>/<$-MONTHLY>)*(1-(1+(<%-INTEREST-RATE>/365))^30)))/(LN(1+<%-INTEREST-RATE>/365))
    return Big(1)
      .div(30)
      .times(
        Math.log(
          Number(
            Big(1)
              .plus(
                Big(chargeAmount)
                  .div(monthlyPayment)
                  .times(Big(1).minus(Big(1).plus(Big(interestRate).div(365)).pow(30)))
              )
              .toFixed(20)
          )
        )
      )
      .div(Math.log(Number(Big(1).plus(Big(interestRate).div(365)).toFixed(20))))
      .times(-1);
  }

  // yes to promo

  // <PROMO-MONTHS> + <MONTHS-REMAINING>
  return Big(promoMonthsUsed).plus(monthsRemaining);
};

export default calcMonthsPayback;
