/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { VERTICALS_NAMES } from '@/domain/verticals';
import {
  ESTIMATE_YOUR_COSTS_AB2_PATH,
  ESTIMATE_YOUR_COSTS_BB1_PATH,
  FIND_A_MECHANIC_AB3_PATH,
  FIND_A_MECHANIC_BB2_PATH,
  LOOK_INTO_OTHER_TRANSPORTATION_OPTIONS_AB4_PATH,
  LOOK_INTO_OTHER_TRANSPORTATION_OPTIONS_BB3_PATH,
  MY_PLAN_PATH,
  SAFETY_FIRST_MUST_FIX_ISSUES_AA1_PATH,
  SAFETY_FIRST_MUST_FIX_ISSUES_AB1_PATH,
  UNDERSTAND_THE_CLAIMS_PROCESS_AA2_PATH,
} from '@/domain/paths';
import { hasBeenInAccident, noAccident, carIsRunning, carIsNotRunning } from '@/domain/questions/auto/selectors';

import { trans } from '@/util/i18n';

import estimateYourCosts from '@/pages/content/auto/images/estimateYourCosts.svg';
import findMechanic from '@/pages/content/auto/images/findMechanic.svg';
import lookIntoOtherTransportationOptions from '@/pages/content/auto/images/lookIntoOtherTransportationOptions.svg';
import safetyFirstMustFixIssues from '@/pages/content/auto/images/safetyFirstMustFixIssues.svg';
import understandTheClaimsProcess from '@/pages/content/auto/images/understandTheClaimsProcess.svg';

const EstimateYourCostsAB2 = React.lazy(() => import('@/pages/content/auto/EstimateYourCostsAB2'));
const EstimateYourCostsBB1 = React.lazy(() => import('@/pages/content/auto/EstimateYourCostsBB1'));
const FindMechanicAB3 = React.lazy(() => import('@/pages/content/auto/FindMechanicAB3'));
const FindMechanicBB2 = React.lazy(() => import('@/pages/content/auto/FindMechanicBB2'));
const LookIntoOtherTransportationOptionsAB4 = React.lazy(() =>
  import('@/pages/content/auto/LookIntoOtherTransportationOptionsAB4')
);
const LookIntoOtherTransportationOptionsBB3 = React.lazy(() =>
  import('@/pages/content/auto/LookIntoOtherTransportationOptionsBB3')
);
const SafetyFirstMustFixIssuesAA1 = React.lazy(() => import('@/pages/content/auto/SafetyFirstMustFixIssuesAA1'));
const SafetyFirstMustFixIssuesAB1 = React.lazy(() => import('@/pages/content/auto/SafetyFirstMustFixIssuesAB1'));
const UnderstandTheClaimsProcessAA2 = React.lazy(() => import('@/pages/content/auto/UnderstandTheClaimsProcessAA2'));

const cards = [
  {
    id: 'SafetyFirstMustFixIssuesAA1',
    image: safetyFirstMustFixIssues,
    title: trans('i18n-unplanned:safety-first-issues'),
    shortText: trans('safety-first-issues.short-text-1'),
    text: trans('safety-first-issues.text-1'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${SAFETY_FIRST_MUST_FIX_ISSUES_AA1_PATH}`,
    },
    route: {
      component: SafetyFirstMustFixIssuesAA1,
    },
    showIf: [hasBeenInAccident],
    verticals: [VERTICALS_NAMES.AUTO],
  },
  {
    id: 'UnderstandTheClaimsProcessAA2',
    image: understandTheClaimsProcess,
    title: trans('i18n-unplanned:understand-claim-process'),
    shortText: trans('i18n-unplanned:understand-claim-process.short-text'),
    text: trans('understand-claim-process.text'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${UNDERSTAND_THE_CLAIMS_PROCESS_AA2_PATH}`,
    },
    route: {
      component: UnderstandTheClaimsProcessAA2,
    },
    showIf: [hasBeenInAccident],
    verticals: [VERTICALS_NAMES.AUTO],
  },
  {
    id: 'SafetyFirstMustFixIssuesAB1',
    image: safetyFirstMustFixIssues,
    title: trans('i18n-unplanned:safety-first-issues'),
    shortText: trans('i18n-unplanned:safety-first-issues.short-text-2'),
    text: trans('safety-first-issues.text-2'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${SAFETY_FIRST_MUST_FIX_ISSUES_AB1_PATH}`,
    },
    route: {
      component: SafetyFirstMustFixIssuesAB1,
    },
    showIf: [noAccident, carIsRunning],
    verticals: [VERTICALS_NAMES.AUTO],
  },
  {
    id: 'EstimateYourCostsAB2',
    image: estimateYourCosts,
    title: trans('i18n-unplanned:estimate-your-costs'),
    shortText: trans('i18n-unplanned:estimate-your-costs.short-text'),
    text: trans('i18n-unplanned:estimate-your-costs.text'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${ESTIMATE_YOUR_COSTS_AB2_PATH}`,
    },
    route: {
      component: EstimateYourCostsAB2,
    },
    showIf: [noAccident, carIsRunning],
    verticals: [VERTICALS_NAMES.AUTO],
  },
  {
    id: 'FindMechanicAB3',
    image: findMechanic,
    title: trans('i18n-unplanned:find-mechanic'),
    shortText: trans('i18n-unplanned:find-mechanic.short-text'),
    text: trans('i18n-unplanned:find-mechanic.text'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${FIND_A_MECHANIC_AB3_PATH}`,
    },
    route: {
      component: FindMechanicAB3,
    },
    showIf: [noAccident, carIsRunning],
    verticals: [VERTICALS_NAMES.AUTO],
  },
  {
    id: 'LookIntoOtherTransportationOptionsAB4',
    image: lookIntoOtherTransportationOptions,
    title: trans('i18n-unplanned:other-transportation'),
    shortText: trans('i18n-unplanned:other-transportation.short-text'),
    text: trans('i18n-unplanned:other-transportation.text'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${LOOK_INTO_OTHER_TRANSPORTATION_OPTIONS_AB4_PATH}`,
    },
    route: {
      component: LookIntoOtherTransportationOptionsAB4,
    },
    showIf: [noAccident, carIsRunning],
    verticals: [VERTICALS_NAMES.AUTO],
  },
  {
    id: 'EstimateYourCostsBB1',
    image: estimateYourCosts,
    title: trans('i18n-unplanned:estimate-your-costs'),
    shortText: trans('i18n-unplanned:estimate-your-costs.short-text'),
    text: trans('i18n-unplanned:estimate-your-costs.text'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${ESTIMATE_YOUR_COSTS_BB1_PATH}`,
    },
    route: {
      component: EstimateYourCostsBB1,
    },
    showIf: [noAccident, carIsNotRunning],
    verticals: [VERTICALS_NAMES.AUTO],
  },
  {
    id: 'FindMechanicBB2',
    image: findMechanic,
    title: trans('i18n-unplanned:find-mechanic'),
    shortText: trans('i18n-unplanned:find-mechanic.short-text'),
    text: trans('i18n-unplanned:find-mechanic.text'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${FIND_A_MECHANIC_BB2_PATH}`,
    },
    route: {
      component: FindMechanicBB2,
    },
    showIf: [noAccident, carIsNotRunning],
    verticals: [VERTICALS_NAMES.AUTO],
  },
  {
    id: 'LookIntoOtherTransportationOptionsBB3',
    image: lookIntoOtherTransportationOptions,
    title: trans('i18n-unplanned:other-transportation'),
    shortText: trans('i18n-unplanned:other-transportation.short-text'),
    text: trans('i18n-unplanned:other-transportation.text'),
    button: {
      text: trans('i18n-actions:button.learn-more'),
      url: `${MY_PLAN_PATH}${LOOK_INTO_OTHER_TRANSPORTATION_OPTIONS_BB3_PATH}`,
    },
    route: {
      component: LookIntoOtherTransportationOptionsBB3,
    },
    showIf: [noAccident, carIsNotRunning],
    verticals: [VERTICALS_NAMES.AUTO],
  },
];

export default cards;
