/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { FeatureFlagsContext } from '@/services/featureFlags';

/**
 * Given a `feature` name, controls if the named feature is enabled or not.
 * If feature is not enabled, fallback component will be rendered.
 */
const FeatureSwitch = ({ feature, children, fallback }) => {
  const { isFeatureEnabled, loading } = React.useContext(FeatureFlagsContext);

  // feature flags not yet loaded, don't render
  if (loading) {
    return null;
  }

  if (!isFeatureEnabled(feature)) {
    return fallback;
  }

  return children;
};

export default React.memo(FeatureSwitch);
