// TODO: Migrate to v6 of aws-amplify
// https://docs.amplify.aws/react/build-a-backend/troubleshooting/migrate-from-javascript-v5-to-v6/
import { Amplify, Auth } from 'aws-amplify';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import { AUTHENTICATION_CALLBACK_PATH_WITHOUT_LEADING_SLASH, LANDING_PAGE_PATH } from '@/domain/paths';

import { ALLOWED_LANGUAGES, FALLBACK_LANGUAGE, TAllowedLanguages } from '@/libs/i18n';

import { getWasRegisterProviderUsed } from '@/services/session';

import { envOrDefault, onlyInDevelopment } from '@/util/env';
import storage, { createStorage } from '@/util/storage';
import { deleteAARPSessionCookie, isValidAARPSessionCookie } from '@/util/cookie';

import { captureException } from './Sentry';

import type { Location } from 'history';

export type RedirectLocation = { redirectLocation: string | Location<unknown> };
export type SessionHandler = (({ redirectLocation }: RedirectLocation) => Promise<void>) | (() => void);

const sessionStorage = createStorage('session');
const REGION = String(envOrDefault('REACT_APP_AWS_AUTH_REGION', ''));
const IDENTITY_POOL_ID = String(envOrDefault('REACT_APP_AWS_AUTH_IDENTITY_POOL_ID', ''));
const POOL_ID = String(envOrDefault('REACT_APP_AWS_AUTH_POOL_ID', ''));
const LOCAL_STORAGE_KEY = `CognitoIdentityId-.${IDENTITY_POOL_ID}`;
const APP_DOMAIN = envOrDefault('REACT_APP_AWS_AUTH_APP_DOMAIN', '');
const REDIRECT_URI_SIGN_IN = `${String(
  envOrDefault(
    'REACT_APP_AWS_AUTH_REDIRECT_URI_SIGN_IN',
    `${String(envOrDefault('REACT_APP_LOCATION', ''))}${AUTHENTICATION_CALLBACK_PATH_WITHOUT_LEADING_SLASH}`
  )
)}`;
const REDIRECT_URI_SIGN_OUT = `${String(
  envOrDefault('REACT_APP_AWS_AUTH_REDIRECT_URI_SIGN_OUT', `${String(envOrDefault('REACT_APP_LOCATION', ''))}signout`)
)}`;
const CLIENT_ID = envOrDefault('REACT_APP_AWS_AUTH_CLIENT_ID', '');
const CLIENT_SCOPES = ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'];

// The following are used as keys for localStorage
const SESSION_IDENTITY_PROVIDER_KEY = 'sessionIdentityProvider';
const REDIRECT_LOCAL_STORAGE_KEY = 'sessionRedirectLocation';
const SESSION_LOCAL_STORAGE_KEY = 'session_token';
const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'access_token';

export const LOGIN_PROVIDER_NAME = envOrDefault('REACT_APP_AWS_AUTH_LOGIN_PROVIDER_NAME', 'AscendLogin');
export const REGISTER_PROVIDER_NAME = envOrDefault('REACT_APP_AWS_AUTH_REGISTER_PROVIDER_NAME', 'AscendRegister');
export const LOGIN_ES_PROVIDER_NAME = envOrDefault('REACT_APP_AWS_AUTH_LOGIN_ES_PROVIDER_NAME', 'AscendLoginES');
export const REGISTER_ES_PROVIDER_NAME = envOrDefault(
  'REACT_APP_AWS_AUTH_REGISTER_ES_PROVIDER_NAME',
  'AscendRegisterES'
);

// Used for correctly handling ending a session on the AARP side for local development
const FALLBACK_SIGN_OUT_URI = `${envOrDefault<string>(
  'REACT_APP_FALLBACK_URI_SIGN_OUT',
  'https://secure-pi.aarp.org/applications/user/logout/logout'
)}`;

Amplify.configure({
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: REGION,
    userPoolId: POOL_ID,
    userPoolWebClientId: CLIENT_ID,
  },
  oauth: {
    domain: APP_DOMAIN,
    scope: CLIENT_SCOPES,
    redirectSignIn: REDIRECT_URI_SIGN_IN,
    redirectSignOut: REDIRECT_URI_SIGN_OUT,
    responseType: 'code',
  },
});

type TCognitoUser =
  | (CognitoUser & {
      username: string | null;
    })
  | null;

export const isUserSignedIn = async (): Promise<boolean> => {
  try {
    const user: TCognitoUser = await Auth.currentAuthenticatedUser();
    if (!user) return false;

    /**
     * A user is signed in via register provider is not considered as signed in.
     * by returning false we can proceed to switch providers later in the process.
     * more specifically, we need switchToLoginProviderHandler() to be called in session.tsx
     */
    return !getWasRegisterProviderUsed(user);
  } catch (e) {
    return false;
  }
};

/**
 * Creates a sign-out handler function.
 * When invoked, the handler clears session storage and removes specific items from storage.
 * It also performs additional steps to end a session on the AARP side for local development.
 * Finally, it attempts to sign out user from all of their devices using the Auth.signOut({ global: true }) method.
 * @returns An asynchronous function that handles user sign-out.
 */
export const createSignOutHandler = () => async () => {
  // We need to remove the persisted session storage items to reset the tabs when signing out.
  sessionStorage.clear();
  storage.removeItem(SESSION_LOCAL_STORAGE_KEY);
  storage.removeItem(LOCAL_STORAGE_KEY);
  storage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  // Used to correctly end a session on the AARP side for local development
  onlyInDevelopment(() => {
    window.open(FALLBACK_SIGN_OUT_URI, '_blank');
  }, 'Using fallback signout process (for local development)');

  try {
    await Auth.signOut({ global: true }).then(() => {
      deleteAARPSessionCookie();
      // delete the user session
      storage.clear();
      localStorage.clear();
      sessionStorage.clear();
    });
  } catch (e) {
    captureException(e as Error);
  }
};

export const getAccessToken = () => {
  return storage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
};

export const hasAccessTokenExpired = (token?: string) => {
  const accessToken = token || getAccessToken();
  // No access token generally means the user has not logged in
  // therefore it is considered as not expired case
  if (!accessToken) {
    return false;
  }

  try {
    const decodedAccessToken = jwtDecode<JwtPayload>(accessToken);
    const ts = Number((new Date().getTime() / 1000).toFixed(0));
    return decodedAccessToken.exp ? ts >= decodedAccessToken.exp : false;
  } catch (err) {
    // the token is probably expired or not well-formed
    // this is considered as token expiry
    // The console log statement remains for some time to identify the underlying issue.
    // eslint-disable-next-line no-console
    console.log('hasAccessTokenExpired::Error decoding access token', accessToken, err);
    return true;
  }
};

export const persistAccessToken = (token: string) => {
  storage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
};

export const clearAccessToken = () => {
  storage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
};

export const persistSessionToken = (token: string) => {
  storage.setItem(SESSION_LOCAL_STORAGE_KEY, token);
};

export const retrieveSessionToken = () => {
  return storage.getItem(SESSION_LOCAL_STORAGE_KEY);
};

const persistRedirectLocation = (redirectLocation: string) => {
  storage.setItem(REDIRECT_LOCAL_STORAGE_KEY, JSON.stringify(redirectLocation));
};

const persistSessionIdentityProvider = (identityProvider: string) => {
  storage.setItem(SESSION_IDENTITY_PROVIDER_KEY, identityProvider);
};

/**
 * Creates a session handler function for a specific identity provider.
 * When invoked, the handler clears session storage, persists the redirect location,
 * and initiates federated sign-in using the provided identity provider.
 * @param identityProvider - The identity provider for federated sign-in.
 */
const createSessionHandler =
  (identityProvider: string): SessionHandler =>
  async ({ redirectLocation }: RedirectLocation) => {
    // We need to remove the persisted session storage items to reset the tabs on a new session.
    sessionStorage.clear();

    persistRedirectLocation(redirectLocation as string);
    persistSessionIdentityProvider(identityProvider);

    await Auth.federatedSignIn({
      customProvider: identityProvider,
    });
  };

export const createRegisterHandler = (lang: TAllowedLanguages = FALLBACK_LANGUAGE) =>
  lang === ALLOWED_LANGUAGES.es
    ? createSessionHandler(REGISTER_ES_PROVIDER_NAME)
    : createSessionHandler(REGISTER_PROVIDER_NAME);
export const createSignInHandler = (lang: TAllowedLanguages = FALLBACK_LANGUAGE) =>
  lang === ALLOWED_LANGUAGES.es
    ? createSessionHandler(LOGIN_ES_PROVIDER_NAME)
    : createSessionHandler(LOGIN_PROVIDER_NAME);

export const retrieveRedirectLocation = () => {
  const rawRedirectLocation = storage.getItem(REDIRECT_LOCAL_STORAGE_KEY);
  let redirectLocation = LANDING_PAGE_PATH;

  if (rawRedirectLocation != null) {
    try {
      redirectLocation = JSON.parse(rawRedirectLocation);
    } catch (e) {
      redirectLocation = rawRedirectLocation;
    }
  }
  return redirectLocation;
};

export const createSwitchToSignInProviderHandler = () => async () => {
  storage.removeItem(SESSION_LOCAL_STORAGE_KEY);

  const newSignInHandler = createSignInHandler();
  const redirectLocation = retrieveRedirectLocation();
  await newSignInHandler({ redirectLocation });
};

export const retrieveSessionIdentityProvider = () => {
  return storage.getItem(SESSION_IDENTITY_PROVIDER_KEY);
};

export const isSessionFromRegisterProvider = () => retrieveSessionIdentityProvider() === REGISTER_PROVIDER_NAME;
export const isSessionFromLoginProvider = () => retrieveSessionIdentityProvider() === LOGIN_PROVIDER_NAME;

/**
 * Creates a browser identity. This should always happen when application starts up.
 *
 * @returns Promise<CognitoIdentityCredentials>
 */
export const createBrowserId = async () => {
  try {
    // Attempt to retrieve the current session token.
    const session: CognitoUserSession = await Auth.currentSession();
    return session.getIdToken().payload.sub;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } catch (error: never) {
    // Handle the case where the user is not authenticated.
    if (error === 'No current user') {
      // If there's a valid AARP session cookie, initiate the sign-in process.
      if (isValidAARPSessionCookie()) {
        const signInHandler = createSignInHandler();
        const redirectLocation = retrieveRedirectLocation();
        await signInHandler({ redirectLocation });
        return ''; // Return an empty string indicating that the process is incomplete.
      }
    } else {
      // Log the error for debugging purposes.
      // eslint-disable-next-line no-console
      console.error('Error creating browser ID:', error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      captureException(error);
    }

    // If the user is not logged in or another error occurred, fetch guest credentials.
    try {
      const credentials = await Auth.currentUserCredentials();
      // Extract the identity ID from the credentials.
      return credentials.identityId ? credentials.identityId.split(':')[1] : '';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (guestCredentialsError: never) {
      // Log the error if unable to retrieve guest credentials.
      // eslint-disable-next-line no-console
      console.error('Error retrieving guest credentials:', guestCredentialsError);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      captureException(guestCredentialsError);
      return ''; // Return an empty string as a fallback.
    }
  }
};

// You can get the current config object
const AuthConfig = Auth.configure();

export { Auth, AuthConfig };
