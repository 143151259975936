/* eslint-disable */
// @ts-nocheck
import { createContext } from 'react';
import Sentry, { captureException } from '@/util/Sentry';
import SentryCategories from '@/util/SentryCategories';

export const initialState = {
  emailSent: false,
  loading: false,
  error: null,
  url: '',
  initialize: false,
  setLoading: () => {
    throw new Error('Initialization is required');
  },
  setEmailSent: () => {
    throw new Error('Initialization is required');
  },
  setEmailFailure: () => {
    throw new Error('Initialization is required');
  },
};

const createSetLoadingHandler = dispatch => state => {
  dispatch({ type: 'set_loading', payload: state });
};

const createSetEmailSentHandler = dispatch => () => {
  dispatch({ type: 'email_sent' });
};

const createSetEmailFailureHandler =
  dispatch =>
  (error = '') => {
    /* eslint-disable-next-line no-console */
    Sentry.addBreadcrumb({
      category: SentryCategories.EMAIL,
      message: 'Email failure.',
      level: 'fatal',
    });
    captureException(error);
    console.error(error); // @TODO this should be logged to some log service later
    dispatch({ type: 'email_failure', payload: { error } });
  };

export const AssessmentContext = createContext(initialState);

export const reducer = (state, action) => {
  switch (action.type) {
    case 'email_sent':
      return { ...state, emailSent: true, error: null };
    case 'email_failure':
      return { ...state, error: action.payload.error };
    case 'set_loading':
      return { ...state, loading: action.payload };
    case 'initialize':
      return {
        ...state,
        initialized: true,
        url: action.payload.url,
        setLoading: createSetLoadingHandler(action.payload.dispatch),
        setEmailSent: createSetEmailSentHandler(action.payload.dispatch),
        setEmailFailure: createSetEmailFailureHandler(action.payload.dispatch),
      };
    default:
      return state;
  }
};
