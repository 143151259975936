export const ESTIMATE_YOUR_COSTS_AB2_PATH = '/content/auto/estimate-your-costs-ab2';
export const ESTIMATE_YOUR_COSTS_BB1_PATH = '/content/auto/estimate-your-costs-bb1';
export const FIND_A_MECHANIC_AB3_PATH = '/content/auto/find-a-mechanic-ab3';
export const FIND_A_MECHANIC_BB2_PATH = '/content/auto/find-a-mechanic-bb2';
export const LOOK_INTO_OTHER_TRANSPORTATION_OPTIONS_AB4_PATH =
  '/content/auto/look-into-other-transportation-options-ab4';
export const LOOK_INTO_OTHER_TRANSPORTATION_OPTIONS_BB3_PATH =
  '/content/auto/look-into-other-transportation-options-bb3';
export const SAFETY_FIRST_MUST_FIX_ISSUES_AB1_PATH = '/content/auto/safety-first-must-fix-issues-ab1';
export const SAFETY_FIRST_MUST_FIX_ISSUES_AA1_PATH = '/content/auto/safety-first-must-fix-issues-aa1';
