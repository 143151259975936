/* eslint-disable */
// @ts-nocheck
import React from 'react';

import { getAnswer } from '@/domain/questions/util';
import { loanAmountQ } from '@/domain/questions';

import useUserState from '@/hooks/useUserState';

import { isAnyUndefined } from '@/util/array';

import withDebouncedRender from '@/components/withDebouncedRender';

import {
  interestRateQ,
  monthlyPaymentQ,
  postPromoInterestRateQ,
  duringPromoInterestRateQ,
  promoPeriodQ,
  promoPeriodDurationQ,
} from '../../questions';

const Calculations = ({ answerWrapper, children, flowState }) => {
  const [calculating, setCalculating] = React.useState(false);
  const [results, setResults] = React.useState({});
  const calcsWorkerRef = React.useRef(null);
  const { state, loading, error } = useUserState();

  const createWorker = () => {
    if (calcsWorkerRef.current != null) {
      calcsWorkerRef.current.terminate();
    }
    calcsWorkerRef.current = new Worker(new URL('./calcs.worker', import.meta.url));

    calcsWorkerRef.current.onmessage = event => {
      const { data } = event;
      setCalculating(false);
      if (data.results) {
        setResults(data.results);
      }
    };
  };

  const chargeAmount = getAnswer(loanAmountQ, state || answerWrapper);
  const interestRate = getAnswer(interestRateQ, answerWrapper);
  const monthlyPayment = getAnswer(monthlyPaymentQ, answerWrapper);
  const postPromoInterestRate = getAnswer(postPromoInterestRateQ, answerWrapper);
  const duringPromoInterestRate = getAnswer(duringPromoInterestRateQ, answerWrapper);
  const promoPeriodDuration = getAnswer(promoPeriodDurationQ, answerWrapper);
  const promoPeriod = getAnswer(promoPeriodQ, answerWrapper);

  const yesPromoPeriod =
    promoPeriod === 1 &&
    !isAnyUndefined([
      chargeAmount,
      duringPromoInterestRate,
      monthlyPayment,
      postPromoInterestRate,
      promoPeriodDuration,
    ]) &&
    monthlyPayment > 0;

  const noPromoPeriod =
    promoPeriod === 0 && !isAnyUndefined([interestRate, monthlyPayment]) && monthlyPayment > 0 && interestRate > 0;

  // establish communication with web worker and terminate it on unmount
  React.useEffect(() => {
    createWorker();

    return () => calcsWorkerRef.current.terminate();
  }, []);

  // send stuff to webworker to calculate
  React.useEffect(() => {
    createWorker();
    setCalculating(true);
    if (yesPromoPeriod) {
      calcsWorkerRef.current.postMessage({
        calc: 'promoPeriodCalculations',
        params: {
          chargeAmount,
          duringPromoInterestRate,
          monthlyPayment,
          postPromoInterestRate,
          promoPeriod,
          promoPeriodDuration,
        },
      });
    }

    if (noPromoPeriod) {
      calcsWorkerRef.current.postMessage({
        calc: 'noPromoPeriodCalculations',
        params: {
          cachedMonthlyPayment: flowState?.cachedMonthlyPayment || monthlyPayment,
          chargeAmount,
          interestRate,
          monthlyPayment,
        },
      });
    }
  }, [
    chargeAmount,
    duringPromoInterestRate,
    flowState,
    interestRate,
    monthlyPayment,
    noPromoPeriod,
    postPromoInterestRate,
    promoPeriod,
    promoPeriodDuration,
    yesPromoPeriod,
  ]);

  if (loading) return null;
  if (error) return null;

  return children({
    calculating,
    calculations: results,
    chargeAmount,
    interestRate,
    monthlyPayment,
    noPromoPeriod,
    yesPromoPeriod,
    promoPeriod,
  });
};

export default withDebouncedRender(Calculations);
