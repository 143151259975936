/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { useQuery } from '@apollo/client';
import { sub } from 'date-fns';
import { NB_SAVINGS_TYPE_ID, NB_SAVINGS_TYPES } from 'common/model/budget';

import { setSafeExpenseValues } from '@/domain/UserState';

import { SessionContext } from '@/services/session';

import { getCurrentDate } from '@/util/date';

import { goalsAndActivitiesByKeys as measureGoalsAndActivitiesByKeys } from '@/pages/Goals/MeasureGoals/config';

import { GET_DASHBOARD_DATA } from '@/graphql/queries';

export const savingsTypes = [
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.OTHER_SAVINGS],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EMERGENCY_FUND],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EDUCATION_SAVINGS],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_400X],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_IRA],
  NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.LARGE_PURCHASE],
];

/**
 * Loads all the data required for the dashboard to work.
 * It will also set safe defaults for values that are expected but not currently present.
 */
const useDashboardState = () => {
  const { userId, isAuthenticated } = React.useContext(SessionContext);

  const date = getCurrentDate();
  const lastMonthDate = sub(date, { month: 1 });
  const lastMonth = lastMonthDate.getMonth().toString();
  const lastYear = lastMonthDate.getFullYear().toString();

  const { loading, data, error } = useQuery(GET_DASHBOARD_DATA, {
    variables: {
      userId,
      lastMonth,
      lastYear,
      expenses: savingsTypes,
    },
    skip: !isAuthenticated,
  });

  const loadingInProgress = userId == null || loading;

  if (loadingInProgress) {
    return { loading, data, error };
  }

  const finishedLoadingButNoUnplannedExpenseData = !loading && (!data || !data.expenses);
  const expenses = finishedLoadingButNoUnplannedExpenseData ? [] : data.expenses.map(e => setSafeExpenseValues(e));
  const goals = data.goals.filter(goal => Object.keys(measureGoalsAndActivitiesByKeys).includes(goal.type));

  return { loading, data: { ...data, expenses, goals }, error };
};

export default useDashboardState;
