/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import { loanAmountQ } from '@/domain/questions';
import { getAnswer } from '@/domain/questions/util';

import useUserState from '@/hooks/useUserState';

import ContainerWithStickyContent from '@/components/ContainerWithStickyContent';
import CustomContainer from '@/components/CustomContainer';
import Divider from '@/components/Divider';
import Heading from '@/components/Heading';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';

import calculatorImage from '@/images/calculatorIcon.svg';

import { loanInterestRateQ, loanRepayPeriodQ, householdIncomePLQ } from '../questions';
import { calculateMonthlyLoanPayment } from '../selectors';

import Form from './Form';
import Results from './Results';

const Affordability = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-unplanned');
  const { state, loading, error } = useUserState();
  if (loading) return null;
  if (error) return null;

  const loanAmount = getAnswer(loanAmountQ, state);
  const loanInterestRate = getAnswer(loanInterestRateQ, state);
  const loanPaybackTerm = getAnswer(loanRepayPeriodQ, state);

  const monthlyLoanPayment = calculateMonthlyLoanPayment({
    loanAmount,
    loanInterestRate,
    loanPaybackTerm,
  });

  const initialValues = {
    [householdIncomePLQ.id]: getAnswer(householdIncomePLQ, state),
  };

  return (
    <CustomContainer noMargin verticallyPadded>
      <Heading as="h1">{t('affordability-check.heading')}</Heading>
      <QuestionsForm fullWidth backButton={false} initialValues={initialValues}>
        {({ values }) => {
          const answerWrapper = fromAssessment ? state : { answers: values };

          return (
            <>
              <Form fromAssessment={fromAssessment} monthlyLoanPayment={monthlyLoanPayment} />
              <Results answerWrapper={answerWrapper} monthlyLoanPayment={monthlyLoanPayment} />
              {fromAssessment ? null : (
                <WizardUpNext imageSrc={calculatorImage}>{t('affordability-check.personal-loan.wizard')}</WizardUpNext>
              )}
            </>
          );
        }}
      </QuestionsForm>
    </CustomContainer>
  );
};

export default Affordability;
