import * as React from 'react';
import { Router as ReactRouter, Switch, Route as ReactRoute, Redirect } from 'react-router-dom';

import {
  AUTHENTICATION_CALLBACK_PATH,
  AUTHENTICATION_SIGNOUT_PATH,
  AUTO_VERTICAL_LANDING_PAGE_PATH,
  LEGACY_AUTO_VERTICAL_LANDING_PAGE_PATH,
  BASE_PATH_FREE_FLOW,
  BASE_PATH_FUNDING_FLOW,
  BASE_PATH_HELP_FLOW,
  RETIREMENT_ACCOUNT_FLOW,
  BASE_PATH_ONBOARDING_WIZARD,
  EMAIL_CONFIRMATION_PAGE_PATH,
  HELP_FLOW_CHARITY_CARE_PATH,
  HOME_VERTICAL_LANDING_PAGE_PATH,
  LEGACY_HOME_VERTICAL_LANDING_PAGE_PATH,
  LANDING_PAGE_PATH,
  BILLS_LANDING_PAGE_PATH,
  MEDICAL_VERTICAL_LANDING_PAGE_PATH,
  LEGACY_MEDICAL_VERTICAL_LANDING_PAGE_PATH,
  MY_PLAN_PATH,
  PERSONAL_ASSESSMENT_GATE_PATH,
  REDIRECT_PATH,
  DEEP_LINK_PATH,
  DASHBOARD_PAGE_PATH,
  BASE_PATH_BUDGET,
  BASE_PATH_ONBOARDING_GOALS,
  STUDENT_LOAN_FLOW,
  BASE_PATH_SAVINGS,
  BASE_PATH_RAINY_DAY_FUND_SAVINGS,
  BASE_PATH_LARGE_PURCHASE_SAVINGS,
  HOW_IT_WORKS_PAGE,
  WELCOME_PAGE,
  PUBLIC_PERSONAL_LOAN_FLOW_BASE,
  PUBLIC_CREDIT_CARD_FLOW_BASE,
  PUBLIC_RETIREMENT_PLAN_FLOW_BASE,
} from '@/domain/paths';
import {
  BASE_PATH_DEBT_SOLUTION,
  CREDIT_CARD_HELP_PATH,
  PRIVATE_STUDENT_LOAN_HELP_PATH,
  TIPS_FOR_CALLING_CREDITORS_PATH,
} from '@/domain/paths/debt';
import {
  GOALS_LANDING_PAGE_PATH,
  ADD_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS,
  ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS,
  ADD_CREDIT_CARD_DEBT_GOAL_DETAILS,
  ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS,
  ADD_OTHER_DEBT_GOAL_DETAILS,
  ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS,
  EDIT_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS,
  EDIT_REDUCE_MY_EXPENSES_GOAL_DETAILS,
  EDIT_CREDIT_CARD_DEBT_GOAL_DETAILS,
  EDIT_STUDENT_LOAN_DEBT_GOAL_DETAILS,
  EDIT_OTHER_DEBT_GOAL_DETAILS,
  EDIT_SAVE_FOR_RETIREMENT_GOAL_DETAILS,
  ADD_SAVE_FOR_RAINY_DAY_GOAL_DEPOSIT,
  ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DEPOSIT,
  ADD_SAVE_FOR_RETIREMENT_GOAL_DEPOSIT,
  FINANCIAL_GOAL_TRACKER,
  FINANCIAL_GOAL_TRACKER_ACTIVITIES,
  FINANCIAL_GOAL_TRACKER_GOALS,
  FINANCIAL_GOAL_DETAIL,
  FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL,
  EDIT_GOALS,
  CREATE_NEW_GOAL,
  FINANCIAL_GOAL_DETAIL_ACTIVITIES,
  FINANCIAL_GOAL_DETAIL_DEPOSITS,
  FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL_ACTIVITIES,
  FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL_DEPOSITS,
} from '@/domain/paths/goals';

import { SessionContext } from '@/services/session';
import { FeatureFlagsContext } from '@/services/featureFlags';

import history from '@/util/history';

import NotFound from '@/pages/NotFound';
import RedirectScreen from '@/pages/redirect';
import Authentication from '@/pages/Authentication';
import SignOut from '@/pages/SignOut';

import ScrollToTop from '@/components/ScrollToTop';
import SignupModal from '@/components/SignupModal';

import GlobalLoader from '../GlobalLoader';

import Route from './Route';

const MainLandingPage = React.lazy(() => import('@/pages/landingPages/MainLandingPage'));
const UnplannedExpenses = React.lazy(() => import('@/pages/landingPages/UnplannedExpenses'));
const MedicalVerticalLandingPage = React.lazy(
  () => import('@/pages/landingPages/UnplannedExpenses/MedicalVerticalLandingPage')
);
const AutoVerticalLandingPage = React.lazy(
  () => import('@/pages/landingPages/UnplannedExpenses/AutoVerticalLandingPage')
);
const HomeVerticalLandingPage = React.lazy(
  () => import('@/pages/landingPages/UnplannedExpenses/HomeVerticalLandingPage')
);
const SavingsVerticalLandingPage = React.lazy(() => import('@/pages/landingPages/SavingsPlanner'));
const LargePurchaseLandingPage = React.lazy(() => import('@/pages/landingPages/SavingsPlanner/LargePurchase'));
const RainyDayFundLandingPage = React.lazy(() => import('@/pages/landingPages/SavingsPlanner/RainyDayFund'));
const OnboardingWizard = React.lazy(() => import('@/pages/new-onboarding'));
const PersonalAssessmentGate = React.lazy(() => import('@/pages/PersonalAssessmentGate'));
const EmailConfirmation = React.lazy(() => import('@/pages/EmailConfirmation'));
const DeepLink = React.lazy(() => import('@/pages/DeepLink'));
const MyPlan = React.lazy(() => import('@/pages/MyPlan'));
const HelpFlow = React.lazy(() => import('@/pages/HelpFlow'));
const HelpFlowCharityCare = React.lazy(() => import('@/pages/HelpFlow/CharityCare'));
const FreeFundsFlow = React.lazy(() => import('@/pages/FreeFundsFlow'));
const FundingFlow = React.lazy(() => import('@/pages/FundingFlow'));
const RetirementAccountFlow = React.lazy(() => import('@/pages/RetirementAccountFlow'));
const StudentLoanFlow = React.lazy(() => import('@/pages/StudentLoanFlow'));
const DebtPlan = React.lazy(() => import('@/pages/DebtPlan'));
const DebtLandingPage = React.lazy(() => import('@/pages/landingPages/DebtPlan'));
const Budget = React.lazy(() => import('@/pages/Budget'));
const BudgetLandingPage = React.lazy(() => import('@/pages/landingPages/Budget'));
const Savings = React.lazy(() => import('@/pages/Savings'));
const GoalsOnboardingWizard = React.lazy(() => import('@/pages/Goals/GoalsOnboarding'));
const GoalsLandingPage = React.lazy(() => import('@/pages/landingPages/Goals'));

const CreateManageUnplannedExpense = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/CreateManageUnplannedExpense')
);
const CreateReduceMyExpenses = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/CreateReduceMyExpenses')
);
const CreatePayOffCreditCardDebt = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/CreatePayOffCreditCardDebt')
);
const CreatePayOffStudentLoan = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/CreatePayOffStudentLoan')
);
const CreatePayOffOtherDebt = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/CreatePayOffOtherDebt')
);
const CreateSaveForRetirement = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/CreateSaveForRetirement')
);
const EditManageUnplannedExpense = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/EditManageUnplannedExpense')
);
const EditReduceMyExpenses = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/EditReduceMyExpenses')
);
const EditPayOffCreditCardDebt = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/EditPayOffCreditCardDebt')
);
const EditPayOffStudentLoan = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/EditPayOffStudentLoan')
);
const EditPayOffOtherDebt = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/EditPayOffOtherDebt')
);
const EditSaveForRetirement = React.lazy(
  () => import('@/pages/Goals/MeasureGoals/GoalsTimeline/views/EditSaveForRetirement')
);
const FinancialGoalTracker = React.lazy(() => import('@/pages/Goals/MeasureGoals/FinancialGoalTracker'));
const EditGoals = React.lazy(() => import('@/pages/Goals/MeasureGoals/EditGoals'));
const CreateNewGoal = React.lazy(() => import('@/pages/Goals/MeasureGoals/CreateNewGoal'));
const GoalDetailPage = React.lazy(() => import('@/pages/Goals/MeasureGoals/GoalDetails'));
const AddDepositWrapper = React.lazy(() => import('@/pages/Goals/MeasureGoals/FinancialGoalTracker/AddDepositWrapper'));
const DashboardOverhaul = React.lazy(() => import('@/pages/DashboardOverhaul'));
const DashboardWithGoals = React.lazy(() => import('@/pages/DashboardWithGoals'));
const CreditCardCalculator = React.lazy(() => import('@/pages/PublicCalculators/CreditCardFlow'));
const PersonalLoanCalculator = React.lazy(() => import('@/pages/PublicCalculators/PersonalLoansFlow'));
const BorrowFromRetirementAccountsFlow = React.lazy(
  () => import('@/pages/PublicCalculators/BorrowFromRetirementAccountsFlow')
);
const HowItWorks = React.lazy(() => import('@/pages/landingPages/HowItWorks'));
const Welcome = React.lazy(() => import('@/pages/Welcome'));

/* content pages */
const CreditCardHelp = React.lazy(() => import('@/pages/content/debtPlan/CreditCardHelp'));
const TipsForCallingCreditorsPage = React.lazy(() => import('@/pages/content/debtPlan/TipsForCallingCreditors'));
const PrivateStudentLoanHelp = React.lazy(() => import('@/pages/content/debtPlan/PrivateStudentLoanHelp'));

const Router = () => {
  const { isAuthenticated, authenticationComplete } = React.useContext(SessionContext);
  const { isFeatureEnabled } = React.useContext(FeatureFlagsContext);
  const financialGoalsTrackerEnabled = isFeatureEnabled('ABACUS_FLAG_FINANCIAL_GOALS_TRACKER');

  if (!authenticationComplete) {
    return <GlobalLoader />;
  }

  if (!isAuthenticated) {
    return (
      <ReactRouter history={history}>
        <ScrollToTop />
        <SignupModal />
        <Switch>
          {/* Required for SEO redirection and prerender.io */}
          <Redirect from="/index.html" to="/" />
          <Redirect from="/index" to="" />
          <Route component={Authentication} exact path={AUTHENTICATION_CALLBACK_PATH} />
          <Route component={SignOut} exact path={AUTHENTICATION_SIGNOUT_PATH} />
          <Route component={RedirectScreen} exact path={`${REDIRECT_PATH}/:name`} />
          <Route component={MainLandingPage} exact path={LANDING_PAGE_PATH} />
          <Route component={UnplannedExpenses} exact path={BILLS_LANDING_PAGE_PATH} />
          <Redirect from={LEGACY_AUTO_VERTICAL_LANDING_PAGE_PATH} to={AUTO_VERTICAL_LANDING_PAGE_PATH} />;
          <Route component={AutoVerticalLandingPage} exact={false} path={AUTO_VERTICAL_LANDING_PAGE_PATH} />
          <Redirect from={LEGACY_HOME_VERTICAL_LANDING_PAGE_PATH} to={HOME_VERTICAL_LANDING_PAGE_PATH} />;
          <Route component={HomeVerticalLandingPage} exact={false} path={HOME_VERTICAL_LANDING_PAGE_PATH} />
          <Redirect from={LEGACY_MEDICAL_VERTICAL_LANDING_PAGE_PATH} to={MEDICAL_VERTICAL_LANDING_PAGE_PATH} />;
          <Route component={MedicalVerticalLandingPage} exact={false} path={MEDICAL_VERTICAL_LANDING_PAGE_PATH} />
          <Route component={BudgetLandingPage} exact path={BASE_PATH_BUDGET} />
          <Route component={DebtLandingPage} exact path={BASE_PATH_DEBT_SOLUTION} />
          {!financialGoalsTrackerEnabled && <Redirect from={GOALS_LANDING_PAGE_PATH} to={LANDING_PAGE_PATH} />}
          <Route
            component={GoalsLandingPage}
            feature="ABACUS_FLAG_FINANCIAL_GOALS_TRACKER"
            exact
            path={GOALS_LANDING_PAGE_PATH}
          />
          <Route component={SavingsVerticalLandingPage} exact path={BASE_PATH_SAVINGS} />
          <Route component={LargePurchaseLandingPage} exact path={BASE_PATH_LARGE_PURCHASE_SAVINGS} />
          <Route component={RainyDayFundLandingPage} exact path={BASE_PATH_RAINY_DAY_FUND_SAVINGS} />
          <Route component={PersonalLoanCalculator} exact={false} path={PUBLIC_PERSONAL_LOAN_FLOW_BASE} />
          <Route component={CreditCardCalculator} exact={false} path={PUBLIC_CREDIT_CARD_FLOW_BASE} />
          <Route component={BorrowFromRetirementAccountsFlow} exact={false} path={PUBLIC_RETIREMENT_PLAN_FLOW_BASE} />
          <Route component={HowItWorks} exact path={HOW_IT_WORKS_PAGE} />
          <Redirect to={LANDING_PAGE_PATH} />
          <ReactRoute component={NotFound} />
        </Switch>
      </ReactRouter>
    );
  }

  return (
    <ReactRouter history={history}>
      <ScrollToTop />
      <SignupModal />
      <Switch>
        <Route component={CreditCardHelp} exact path={CREDIT_CARD_HELP_PATH} />
        <Route component={TipsForCallingCreditorsPage} exact path={TIPS_FOR_CALLING_CREDITORS_PATH} />
        <Route component={PrivateStudentLoanHelp} exact path={PRIVATE_STUDENT_LOAN_HELP_PATH} />
        <Route component={Authentication} exact path={AUTHENTICATION_CALLBACK_PATH} />
        <Route component={SignOut} exact path={AUTHENTICATION_SIGNOUT_PATH} />
        <Route component={RedirectScreen} exact path={`${REDIRECT_PATH}/:name`} />
        <Route component={HelpFlowCharityCare} exact path={HELP_FLOW_CHARITY_CARE_PATH} />
        <Route component={OnboardingWizard} exact path={`${BASE_PATH_ONBOARDING_WIZARD}/:id`} />
        <Route component={PersonalAssessmentGate} exact path={`${PERSONAL_ASSESSMENT_GATE_PATH}/:id`} />
        <Route component={MainLandingPage} exact path={LANDING_PAGE_PATH} />
        <Route component={UnplannedExpenses} exact path={BILLS_LANDING_PAGE_PATH} />
        <Redirect from={LEGACY_AUTO_VERTICAL_LANDING_PAGE_PATH} to={AUTO_VERTICAL_LANDING_PAGE_PATH} />;
        <Route component={AutoVerticalLandingPage} exact={false} path={AUTO_VERTICAL_LANDING_PAGE_PATH} />
        <Redirect from={LEGACY_HOME_VERTICAL_LANDING_PAGE_PATH} to={HOME_VERTICAL_LANDING_PAGE_PATH} />;
        <Route component={HomeVerticalLandingPage} exact={false} path={HOME_VERTICAL_LANDING_PAGE_PATH} />
        <Redirect from={LEGACY_MEDICAL_VERTICAL_LANDING_PAGE_PATH} to={MEDICAL_VERTICAL_LANDING_PAGE_PATH} />;
        <Route component={MedicalVerticalLandingPage} exact={false} path={MEDICAL_VERTICAL_LANDING_PAGE_PATH} />
        <Route component={SavingsVerticalLandingPage} exact path={BASE_PATH_SAVINGS} />
        <Route component={LargePurchaseLandingPage} exact path={BASE_PATH_LARGE_PURCHASE_SAVINGS} />
        <Route component={RainyDayFundLandingPage} exact path={BASE_PATH_RAINY_DAY_FUND_SAVINGS} />
        <Route component={FreeFundsFlow} exact={false} path={BASE_PATH_FREE_FLOW} />
        <Route component={FundingFlow} exact={false} path={BASE_PATH_FUNDING_FLOW} />
        <Route component={RetirementAccountFlow} exact={false} path={`${RETIREMENT_ACCOUNT_FLOW}/:id`} />
        <Route component={StudentLoanFlow} exact={false} path={`${STUDENT_LOAN_FLOW}/:id`} />
        <Route component={MyPlan} exact={false} path={MY_PLAN_PATH} />
        <Route component={HelpFlow} exact={false} path={`${BASE_PATH_HELP_FLOW}/:id`} />
        <Route
          component={EmailConfirmation}
          exact={false}
          feature="ABACUS_FLAG_EMAIL_CONFIRMATION_PAGE"
          path={EMAIL_CONFIRMATION_PAGE_PATH}
        />
        <Route component={DeepLink} exact={false} feature="ABACUS_FLAG_DEEP_LINKS" path={DEEP_LINK_PATH} />
        <Route component={DebtPlan} feature="ABACUS_FLAG_DEBT_SOLUTION" exact={false} path={BASE_PATH_DEBT_SOLUTION} />
        {!financialGoalsTrackerEnabled && <Redirect from={GOALS_LANDING_PAGE_PATH} to={LANDING_PAGE_PATH} />}
        {financialGoalsTrackerEnabled ? (
          React.Children.toArray([
            <Route component={GoalsLandingPage} exact path={GOALS_LANDING_PAGE_PATH} key={GOALS_LANDING_PAGE_PATH} />,
            <Route
              component={GoalsOnboardingWizard}
              exact
              path={`${BASE_PATH_ONBOARDING_GOALS}/:id`}
              key={`${BASE_PATH_ONBOARDING_GOALS}/:id`}
            />,
            <Route
              component={CreateManageUnplannedExpense}
              exact
              path={`${ADD_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS}/:goalId`}
              key={`${ADD_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={CreateReduceMyExpenses}
              exact
              path={`${ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS}/:goalId`}
              key={`${ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={CreatePayOffCreditCardDebt}
              exact
              path={`${ADD_CREDIT_CARD_DEBT_GOAL_DETAILS}/:goalId`}
              key={`${ADD_CREDIT_CARD_DEBT_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={CreatePayOffStudentLoan}
              exact
              path={`${ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS}/:goalId`}
              key={`${ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={CreatePayOffOtherDebt}
              exact
              path={`${ADD_OTHER_DEBT_GOAL_DETAILS}/:goalId`}
              key={`${ADD_OTHER_DEBT_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={CreateSaveForRetirement}
              exact
              path={`${ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS}/:goalId`}
              key={`${ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={AddDepositWrapper}
              exact
              path={`${ADD_SAVE_FOR_RAINY_DAY_GOAL_DEPOSIT}/:goalId`}
              key={`${ADD_SAVE_FOR_RAINY_DAY_GOAL_DEPOSIT}/:goalId`}
            />,
            <Route
              component={AddDepositWrapper}
              exact
              path={`${ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DEPOSIT}/:goalId`}
              key={`${ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DEPOSIT}/:goalId`}
            />,
            <Route
              component={AddDepositWrapper}
              exact
              path={`${ADD_SAVE_FOR_RETIREMENT_GOAL_DEPOSIT}/:goalId`}
              key={`${ADD_SAVE_FOR_RETIREMENT_GOAL_DEPOSIT}/:goalId`}
            />,
            <Route
              component={EditManageUnplannedExpense}
              exact
              path={`${EDIT_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS}/:goalId`}
              key={`${EDIT_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={EditReduceMyExpenses}
              exact
              path={`${EDIT_REDUCE_MY_EXPENSES_GOAL_DETAILS}/:goalId`}
              key={`${EDIT_REDUCE_MY_EXPENSES_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={EditPayOffCreditCardDebt}
              exact
              path={`${EDIT_CREDIT_CARD_DEBT_GOAL_DETAILS}/:goalId`}
              key={`${EDIT_CREDIT_CARD_DEBT_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={EditPayOffStudentLoan}
              exact
              path={`${EDIT_STUDENT_LOAN_DEBT_GOAL_DETAILS}/:goalId`}
              key={`${EDIT_STUDENT_LOAN_DEBT_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={EditPayOffOtherDebt}
              exact
              path={`${EDIT_OTHER_DEBT_GOAL_DETAILS}/:goalId`}
              key={`${EDIT_OTHER_DEBT_GOAL_DETAILS}/:goalId`}
            />,
            <Route
              component={EditSaveForRetirement}
              exact
              path={`${EDIT_SAVE_FOR_RETIREMENT_GOAL_DETAILS}/:goalId`}
              key={`${EDIT_SAVE_FOR_RETIREMENT_GOAL_DETAILS}/:goalId`}
            />,
            <Route component={FinancialGoalTracker} exact path={FINANCIAL_GOAL_TRACKER} key={FINANCIAL_GOAL_TRACKER} />,
            <Route
              component={FinancialGoalTracker}
              exact
              path={FINANCIAL_GOAL_TRACKER_ACTIVITIES}
              key={FINANCIAL_GOAL_TRACKER_ACTIVITIES}
            />,
            <Route
              component={FinancialGoalTracker}
              exact
              path={FINANCIAL_GOAL_TRACKER_GOALS}
              key={FINANCIAL_GOAL_TRACKER_GOALS}
            />,
            <Route component={GoalDetailPage} exact path={FINANCIAL_GOAL_DETAIL} key={FINANCIAL_GOAL_DETAIL} />,
            <Route
              component={GoalDetailPage}
              exact
              path={FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL}
              key={FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL}
            />,
            <Route
              component={GoalDetailPage}
              exact={false}
              path={FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL_ACTIVITIES}
              key={FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL_ACTIVITIES}
            />,
            <Route
              component={GoalDetailPage}
              exact={false}
              path={FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL_DEPOSITS}
              key={FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL_DEPOSITS}
            />,
            <Route
              component={GoalDetailPage}
              exact={false}
              path={FINANCIAL_GOAL_DETAIL_ACTIVITIES}
              key={FINANCIAL_GOAL_DETAIL_ACTIVITIES}
            />,
            <Route
              component={GoalDetailPage}
              exact={false}
              path={FINANCIAL_GOAL_DETAIL_DEPOSITS}
              key={FINANCIAL_GOAL_DETAIL_DEPOSITS}
            />,
            <Route component={EditGoals} exact={false} path={EDIT_GOALS} key={EDIT_GOALS} />,
            <Route component={CreateNewGoal} exact={false} path={CREATE_NEW_GOAL} key={CREATE_NEW_GOAL} />,
            <Route
              component={DashboardWithGoals}
              feature="ABACUS_FLAG_DASHBOARD"
              exact={false}
              path={DASHBOARD_PAGE_PATH}
              key={DASHBOARD_PAGE_PATH}
            />,
          ])
        ) : (
          <Route
            component={DashboardOverhaul}
            feature="ABACUS_FLAG_DASHBOARD"
            exact={false}
            path={DASHBOARD_PAGE_PATH}
          />
        )}
        <Route component={Budget} exact={false} path={BASE_PATH_BUDGET} />
        <Route component={Savings} exact={false} path={BASE_PATH_SAVINGS} />
        <Route component={HowItWorks} exact path={HOW_IT_WORKS_PAGE} />
        <Route component={Welcome} exact path={WELCOME_PAGE} />
        <Route component={PersonalLoanCalculator} exact={false} path={PUBLIC_PERSONAL_LOAN_FLOW_BASE} />
        <Route component={CreditCardCalculator} exact={false} path={PUBLIC_CREDIT_CARD_FLOW_BASE} />
        <Route component={BorrowFromRetirementAccountsFlow} exact={false} path={PUBLIC_RETIREMENT_PLAN_FLOW_BASE} />
        <Redirect to={DASHBOARD_PAGE_PATH} />
        <ReactRoute component={NotFound} />
      </Switch>
    </ReactRouter>
  );
};

export default Router;
