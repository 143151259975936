/* eslint-disable */
// @ts-nocheck
import { useState, useEffect } from 'react';

/**
 * These breakpoints need to match the ones available in semantic-ui.
 * Semantic-ui defined breakpoints are located in `globals/site.variables`
 */
const breakpoints = {
  SMALL_PHONE: 360,
  TABLET: 768,
  COMPUTER: 992,
  LARGE_MONITOR: 1200,
  DESKTOP_WITH_SIDENAV: 1332, // considering side navigation layout
  WIDESCREEN_MONITOR: 1920,
};

/**
 * Uses slightly offset values to account for differences in browser
 * float-point calculations in media queries
 * https://stackoverflow.com/questions/63637584/bug-with-browsers-interpretation-of-media-queries
 */
export const mediaQuery = {
  MOBILE: `(max-width: ${breakpoints.TABLET - 0.1}px)`,
  TABLET: `(min-width: ${breakpoints.TABLET}px) and (max-width: ${breakpoints.COMPUTER - 0.1}px)`,
  LARGE_TABLET: `(min-width: ${breakpoints.TABLET}px) and (max-width: ${breakpoints.DESKTOP_WITH_SIDENAV - 0.1}px)`,
  MOBILE_AND_TABLET: `(max-width: ${breakpoints.COMPUTER - 0.1}px)`,
  DESKTOP: `(min-width: ${breakpoints.COMPUTER}px)`,
  DESKTOP_WITH_SIDENAV: `(min-width: ${breakpoints.DESKTOP_WITH_SIDENAV}px)`,
  SMALL_DESKTOP: `(max-width: ${breakpoints.DESKTOP_WITH_SIDENAV}px)`,
  LARGE_DESKTOP: `(min-width: ${breakpoints.LARGE_MONITOR}px)`,
};

/**
 * Causes a rerender when screen resizes to another screen size category.
 * Returns a boolean stating if the provided media query is satisfied.
 * Takes mediaQuery string as argument.
 */
const useMediaQuery = media => {
  const [currentMedia, setCurrentMedia] = useState(false);
  useEffect(() => {
    const mediaCond = window.matchMedia(media);
    const resize = val => setCurrentMedia(val.matches);
    setCurrentMedia(mediaCond.matches);
    // First check for addEventListener and as a fallback check addListener
    if (mediaCond?.addEventListener) {
      mediaCond.addEventListener('change', resize);
    } else if (mediaCond?.addListener) {
      mediaCond.addListener(resize);
    }

    return () => {
      // First check for removeEventListener and as a fallback check removeListener
      if (mediaCond?.removeEventListener) {
        mediaCond.removeEventListener('change', resize);
      } else if (mediaCond?.removeListener) {
        mediaCond.removeListener('change', resize);
      }
    };
  }, [media]);

  return currentMedia;
};

export default useMediaQuery;
