/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

/**
 * A hook that returns errorHandled and a clearError functions which will trigger errors that are outside the react context.
 * React app is wrapped by an errorBoundary, but when we have code that runs outside react ( like the apollo client service ),
 * thrown errors will not be caught by it, so using this hook allows us to handle errors from outside and still be cought
 * by the error boundary.
 */
const useErrorHandler = () => {
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    if (error) throw new Error(error);
  }, [error]);

  const errorHandler = err => setError(err);
  const clearError = () => setError(null);

  return { errorHandler, clearError };
};

export default useErrorHandler;
