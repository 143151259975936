/* eslint-disable */
// @ts-nocheck
import { useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'formik';
import { Card, Grid, Segment } from 'semantic-ui-react';
import Big from 'big.js';

import { FUNDING_FLOW_OPTIONS } from '@/domain/paths';
import { getAnswer } from '@/domain/questions/util';

import Link from '@/components/link';
import Paragraph from '@/components/Paragraph';
import Currency from '@/components/Currency';
import { ErrorBox, SuccessBox } from '@/components/InformationBox';
import Placeholder from '@/components/Placeholder';
import VerticalSpacing from '@/components/VerticalSpacing';
import CalcResultsPlaceholder from '@/components/Placeholder/CalcResultsPlaceholder';

import { monthlyPaymentQ } from '../questions';

import Calculations from './Calculations';
import YesPeriodMonthlyPaymentSuggestions from './YesPeriodMonthlyPaymentSuggestions';
import NoPeriodMonthlyPaymentSuggestions from './NoPeriodMonthlyPaymentSuggestions';
import { useCreditCardState } from './State';

import classes from './styles.module.less';

const LoanCannotBePaidOff = () => {
  const { t } = useTranslation('i18n-credit');
  return (
    <Card className={classes.card}>
      <ErrorBox noMargin icon="warning sign" content={<strong>{t('loan-cannot-be-paidoff.error-content')}</strong>} />
      <Card.Content>
        <Paragraph noHorizontalMargin>
          <Trans ns="i18n-credit" i18nKey="loan-cannot-be-paidoff.paragraph">
            Your monthly payment isn’t enough to cover the monthly interest charge. Try increasing your payment or
            consider{' '}
            <Link icon={false} to={FUNDING_FLOW_OPTIONS}>
              other funding options
            </Link>
            .
          </Trans>
        </Paragraph>
      </Card.Content>
    </Card>
  );
};

const Results = ({ answerWrapper, formik, readOnly }) => {
  const [state, dispatch] = useCreditCardState();
  const baseMonthlyPayment = state.cachedMonthlyPayment;

  const toggleUpdateBox = useCallback(
    show =>
      dispatch({
        type: 'toggle-update-box',
        show,
      }),
    [dispatch]
  );

  const onNewMonthlyCallback = useCallback(
    newMonthlyPayment => {
      formik.setFieldValue(monthlyPaymentQ.id, newMonthlyPayment);

      toggleUpdateBox(true);

      dispatch({
        type: 'use-suggested-monthly-payment',
        cachedMonthlyPayment: state.cachedMonthlyPayment || getAnswer(monthlyPaymentQ, answerWrapper),
        suggestedMonthlyPayment: newMonthlyPayment,
      });
    },
    [answerWrapper, dispatch, formik, state.cachedMonthlyPayment, toggleUpdateBox]
  );

  return (
    <Calculations answerWrapper={answerWrapper} flowState={state}>
      {({ calculations, calculating, monthlyPayment, noPromoPeriod, yesPromoPeriod, promoPeriod }) => {
        return (
          <InnerResults
            calculations={calculations}
            calculating={calculating}
            monthlyPayment={monthlyPayment}
            noPromoPeriod={noPromoPeriod}
            yesPromoPeriod={yesPromoPeriod}
            promoPeriod={promoPeriod}
            readOnly={readOnly}
            state={state}
            dispatch={dispatch}
            onNewMonthlyCallback={onNewMonthlyCallback}
            baseMonthlyPayment={baseMonthlyPayment}
            toggleUpdateBox={toggleUpdateBox}
          />
        );
      }}
    </Calculations>
  );
};

const InnerResults = ({
  calculations,
  calculating,
  monthlyPayment,
  noPromoPeriod,
  yesPromoPeriod,
  promoPeriod,
  readOnly,
  state,
  dispatch,
  onNewMonthlyCallback,
  baseMonthlyPayment,
  toggleUpdateBox,
}) => {
  const { t } = useTranslation('i18n-credit');
  useEffect(() => {
    return () => {
      if (promoPeriod === 1 && state.suggestedMonthlyPayment) {
        // clear the suggested value when user toggles to no promo after trying a higher monthly payment
        dispatch({ type: 'clear-suggested-monthly-payment' });
      }
    };
  }, [dispatch, promoPeriod, state.suggestedMonthlyPayment]);

  const monthsPaybackRoundedUp = calculations?.monthsPaybackRoundedUp;

  if (calculating) {
    return (
      <Segment data-testid="calcSpinner">
        <CalcResultsPlaceholder spinner text={t('i18n-default:calculating-results')} />
      </Segment>
    );
  }

  if (calculations?.loanCannotBePaidOff) {
    return <LoanCannotBePaidOff />;
  }

  return (
    <>
      <Card className={classes.card}>
        <Card.Content>
          <Grid verticalAlign="middle">
            <Grid.Row columns={2} className={classes.rowWithBorder}>
              <Grid.Column width={8}>{t('time-pay-card-off')}</Grid.Column>
              <Grid.Column width={8}>
                {calculations == null ? (
                  <Placeholder />
                ) : (
                  <Paragraph size="large" bold textAlign="left">
                    {t('months-rounded-up', { monthsRoundedUp: monthsPaybackRoundedUp })}
                  </Paragraph>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={8}>{t('i18n-default:total-interest-paid')}</Grid.Column>
              <Grid.Column width={8}>
                {calculations == null ? (
                  <Placeholder />
                ) : (
                  <Currency className={classes.currency} value={calculations?.totalInterestCost} />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>

      {state.showUpdateBox && (
        <SuccessBox
          dismissable
          noMargin
          arrow="top"
          icon="thumbs up outline"
          onDismiss={() => toggleUpdateBox(false)}
          content={
            <>
              <Paragraph bold noMargin size="tiny">
                {t('we-updated-your-payment')}
              </Paragraph>
              <Paragraph noMargin size="mini">
                {t('check-how-much-save')}
              </Paragraph>
            </>
          }
        />
      )}

      <VerticalSpacing size="4" />

      {yesPromoPeriod &&
        calculations != null &&
        !readOnly &&
        // if calculations do not provide a suggested calculations, do not show component
        calculations.suggested != null &&
        // if a suggested monthly payment is set, don't show this component as it came from this component
        !state.suggestedMonthlyPayment &&
        // only show if time to pay card off is greater than promo period pay off
        Big(calculations.monthsPayback).gt(calculations.suggested?.monthsPayback) && (
          <YesPeriodMonthlyPaymentSuggestions
            calculations={calculations}
            onTry={onNewMonthlyCallback}
            promoPeriod={promoPeriod}
            clearSuggestion={() => dispatch({ type: 'clear-suggested-monthly-payment' })}
          />
        )}

      {!readOnly && noPromoPeriod && (
        <NoPeriodMonthlyPaymentSuggestions
          calculations={calculations}
          monthlyPayment={baseMonthlyPayment || monthlyPayment}
          onFastTrack={onNewMonthlyCallback}
        />
      )}
    </>
  );
};

export default connect(Results);
