import React from 'react';
import cx from 'classnames';
import { Checkbox as DefaultCheckbox, CheckboxProps } from 'semantic-ui-react';

import classes from './styles.module.less';

export type TCheckboxProps = CheckboxProps & {
  color?: string;
  'aria-label'?: string;
  'data-testid'?: string;
};

const Checkbox = ({
  'aria-label': ariaLabel,
  checked = false,
  className,
  color = '',
  'data-testid': dataTestId,
  label,
  onChange,
  onClick,
  tabIndex,
  ...props
}: TCheckboxProps) => (
  <DefaultCheckbox
    {...props}
    label={label}
    aria-label={ariaLabel}
    checked={checked}
    aria-checked={checked}
    onChange={onChange}
    onClick={onClick}
    className={cx(className, classes[color])}
    tabIndex={tabIndex}
    data-testid={dataTestId}
  />
);

export default Checkbox;
