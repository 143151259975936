import * as React from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import i18next from 'i18next';

import { LANDING_PAGE_PATH } from '@/domain/paths';

import useTimeout from '@/hooks/useTimeout';

import { TAllowedLanguages } from '@/libs/i18n';

import { AnalyticsContext } from '@/services/analytics';

import history from '@/util/history';
import { envOrDefault } from '@/util/env';
import { captureException } from '@/util/Sentry';
import { emitDOMContentLoaded, refreshRewardsStore, shouldForceLogin } from '@/util/rewards';
import {
  Auth,
  REGISTER_PROVIDER_NAME,
  createBrowserId,
  createRegisterHandler,
  createSignInHandler,
  createSignOutHandler,
  isUserSignedIn,
  persistAccessToken,
  persistSessionToken,
  createSwitchToSignInProviderHandler,
  SessionHandler,
  LOGIN_ES_PROVIDER_NAME,
} from '@/util/authentication';
import { createStorage } from '@/util/storage';
import { deleteAARPSessionCookie } from '@/util/cookie';

import { LOGIN } from '@/graphql/mutations';

type SessionState = {
  loading: boolean;
  initialized: boolean;
  userId: string | null;
  username: string | null;
  name: string | null;
  email: string | null;
  sessionData: {
    idToken: string | null;
    accessToken: string | null;
    refreshToken: string | null;
  };
  shouldRefreshStateMachine: boolean;
  authenticationComplete: boolean;
  isAuthenticated: boolean;
  signInHandler: SessionHandler;
  registerHandler: SessionHandler;
  switchToLoginProviderHandler: () => Promise<void>;
  signOutHandler: () => void | (() => Promise<void>);
};

type SessionAction = {
  type: 'initialize' | 'get_session_id_success' | 'parse_cognito_response_success' | 'set_authenticated_state';
  payload: {
    dispatch?: React.Dispatch<SessionAction>;
    client?: ApolloClient<object>;
    sessionId?: string;
    authenticated?: boolean;
    shouldRefreshStateMachine?: boolean;
    cognitoUserSession?: Awaited<ReturnType<typeof Auth.currentSession>>;
  };
};

const storage = createStorage('session');

// AARP policy required 15 minutes of inactivity to log out
const SESSION_TIMEOUT = Number(envOrDefault<number>('REACT_APP_SESSION_TIMEOUT', 15 * 60 * 1000)); // 15 minutes
const SKIP_AUTHENTICATION = envOrDefault<boolean>('REACT_APP_UNSAFE_NO_AUTH', false);

export const getWasRegisterProviderUsed = ({ username }: { username: string | null }) =>
  username?.startsWith(REGISTER_PROVIDER_NAME);

export const getWasSpanishLoginProviderUsed = ({ username }: { username: string | null }) =>
  username?.startsWith(LOGIN_ES_PROVIDER_NAME);

const initialState: SessionState = {
  loading: true,
  initialized: false,
  userId: null,
  username: null,
  name: null,
  email: null,
  sessionData: {
    idToken: null,
    accessToken: null,
    refreshToken: null,
  },
  authenticationComplete: false,
  shouldRefreshStateMachine: false,
  isAuthenticated: false,
  signInHandler: () => {
    throw new Error('Initialization must be completed before sign in handler may be used.');
  },
  registerHandler: () => {
    throw new Error('Initialization must be completed before register handler may be used.');
  },
  switchToLoginProviderHandler: () => {
    throw new Error('Initialization must be completed before switch to sign in provider handler may be used.');
  },
  signOutHandler: () => {
    throw new Error('Initialization must be completed before sign out handler may be used.');
  },
};

const mockSignInHandler =
  (dispatch: React.Dispatch<SessionAction>, client: ApolloClient<object>, userId: string) => async () => {
    await client.mutate({
      mutation: LOGIN,
      variables: { userId },
    });
    dispatch({ type: 'set_authenticated_state', payload: { authenticated: true } });
    storage.setItem('BYPASS_AUTH_TOKEN', '12345678');
  };

const mockSignOutHandler = (dispatch: React.Dispatch<SessionAction>) => () => {
  // eslint-disable-next-line no-console
  console.log('Mock sign out handler called');
  dispatch({ type: 'set_authenticated_state', payload: { authenticated: false } });
  storage.removeItem('BYPASS_AUTH_TOKEN');
  deleteAARPSessionCookie();
  // delete the user session
  storage.clear();
  localStorage.clear();
  sessionStorage.clear();
};

export const SessionContext = React.createContext(initialState);

const mockEmptyFunction = () => {
  // this is a mock function used when SKIP_AUTHENTICATION is true
};

const sessionReducer = (state: SessionState, action: SessionAction): SessionState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        initialized: true,
        signInHandler: SKIP_AUTHENTICATION
          ? mockEmptyFunction
          : createSignInHandler(i18next.language as TAllowedLanguages),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        signOutHandler: SKIP_AUTHENTICATION ? mockSignOutHandler(action.payload.dispatch!) : createSignOutHandler(),
        registerHandler: SKIP_AUTHENTICATION
          ? mockEmptyFunction
          : createRegisterHandler(i18next.language as TAllowedLanguages),
        switchToLoginProviderHandler: createSwitchToSignInProviderHandler(),
      };
    case 'get_session_id_success':
      if (!action.payload.dispatch || !action.payload.client || !action.payload.sessionId) return state;

      return {
        ...state,
        loading: false,
        userId: action.payload.sessionId ?? null,
        ...(SKIP_AUTHENTICATION && {
          signInHandler: mockSignInHandler(action.payload.dispatch, action.payload.client, action.payload.sessionId),
        }),
      };
    case 'parse_cognito_response_success':
      if (!action.payload.cognitoUserSession) {
        return state;
      }

      persistSessionToken(action.payload.cognitoUserSession.getAccessToken().getJwtToken());

      // Persist the access token received from the AARP Identity provider into
      // the local storage. This is required for rewards to function properly

      persistAccessToken(action.payload.cognitoUserSession.getIdToken().payload['custom:access_token'] as string);

      // Emit DOM Content loaded after access token is set
      emitDOMContentLoaded();

      // Refresh the AARP rewards store, to ensure access token is picked up by the rewards component
      refreshRewardsStore();

      return {
        ...state,
        email: action.payload.cognitoUserSession.getIdToken().payload.email,
        name: action.payload.cognitoUserSession.getIdToken().payload.name,
        username: action.payload.cognitoUserSession.getIdToken().payload['cognito:username'],
        sessionData: {
          idToken: action.payload.cognitoUserSession.getIdToken().getJwtToken(),
          accessToken: action.payload.cognitoUserSession.getAccessToken().getJwtToken(),
          refreshToken: action.payload.cognitoUserSession.getRefreshToken().getToken(),
        },
      };
    case 'set_authenticated_state':
      return {
        ...state,
        authenticationComplete: true,
        isAuthenticated: action.payload.authenticated || false,
        shouldRefreshStateMachine: action.payload.shouldRefreshStateMachine || false,
      };
    default:
      throw new Error('Unhandled action type in sessionReducer.');
  }
};

const clearSession = signOut => {
  signOut();
  history.push(LANDING_PAGE_PATH);
};

/**
 * Provides session metadata to the rest of the application
 */
const SessionProvider = ({ children }) => {
  const client = useApolloClient();
  const [state, dispatch] = React.useReducer<React.Reducer<SessionState, SessionAction>>(sessionReducer, initialState);
  const { start: startTimeout } = useTimeout(() => clearSession(state.signOutHandler), SESSION_TIMEOUT);
  const analytics = React.useContext(AnalyticsContext);

  React.useEffect(() => {
    if (!Auth) return;

    Auth.currentSession()
      .then(async result => {
        // Let us check if the rewards access_token has expired? If the token has expired let us force
        // a re-login
        if (shouldForceLogin()) {
          // The createSignOutHandler call is required here since the handler may have not been
          // initialized properly
          const signOut = createSignOutHandler();
          await signOut();
        } else {
          dispatch({
            type: 'parse_cognito_response_success',
            payload: { cognitoUserSession: result },
          });
        }
      })
      .catch(e => captureException(e as Error));

    if (!state.initialized) {
      dispatch({
        type: 'initialize',
        payload: {
          dispatch,
          client,
        },
      });
    }

    const getSessionId = async () => {
      // Load session ID from localStorage or create a new one if it does not exist.
      const sessionId = await createBrowserId();

      dispatch({ type: 'get_session_id_success', payload: { sessionId, dispatch, client } });

      // handle restoration of existing session
      if (!SKIP_AUTHENTICATION && (await isUserSignedIn())) {
        dispatch({ type: 'set_authenticated_state', payload: { authenticated: true } });
      } else if (SKIP_AUTHENTICATION) {
        const tokenPresent = storage.getItem('BYPASS_AUTH_TOKEN');
        dispatch({ type: 'set_authenticated_state', payload: { authenticated: !!tokenPresent } });
      } else {
        dispatch({ type: 'set_authenticated_state', payload: { authenticated: false } });
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getSessionId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { sessionData, isAuthenticated, userId, loading, switchToLoginProviderHandler } = state;

    const runLoginMutation = async () => {
      if (userId == null) {
        return;
      }

      try {
        await client.mutate({
          mutation: LOGIN,
          variables: { userId },
          update: (_, { errors }) => {
            if (!errors) {
              // do not update cache if there is no payload (user clicked register without previously submitting anything)
              // clears the cache re-fetches all the previously active queries
              // and reinitializes the state machine to refecth queries handled by the state machine
              if (!SKIP_AUTHENTICATION) {
                client
                  .resetStore()
                  .then(() =>
                    dispatch({
                      type: 'set_authenticated_state',
                      payload: { authenticated: true, shouldRefreshStateMachine: true },
                    })
                  )
                  .catch(e => captureException(e as Error));
              }
            }
          },
        });
      } catch (e) {
        captureException(e as Error);
      }
    };

    // TODO: Create Selector?
    if (sessionData.accessToken != null && !isAuthenticated && !loading) {
      // If the user logged in with a Register identity provider, send them to the login flow.
      if (getWasRegisterProviderUsed(state)) {
        // this means there was a successful registration, so track it
        analytics.trackEvent({ name: 'registrationCompleted' });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        switchToLoginProviderHandler();
        return;
      }
      // If the user logged in with a Spanish identity provider, send them to the login flow.
      // this is necessary because the Spanish provider does not the same sub
      if (getWasSpanishLoginProviderUsed(state)) {
        // this means there was a successful login with the Spanish provider, so track it
        analytics.trackEvent({ name: 'spanishLoginCompleted' });

        void switchToLoginProviderHandler();
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      runLoginMutation();
      return;
    }

    // TODO: Create Selector?
    if (!SKIP_AUTHENTICATION && sessionData.accessToken != null && isAuthenticated) {
      startTimeout();
    }
  }, [state, startTimeout, client, analytics]);

  return <SessionContext.Provider value={state}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
