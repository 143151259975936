/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';

import { isFlowCompleted } from '@/domain/WizardConfig';
import totalAmountOfInterestPaidCalc from '@/domain/calcs/creditCard/calcTotalAmountOfInterestPaid';
import promoPeriodAMTPaidAndRemainingBalanceCalc from '@/domain/calcs/creditCard/calcPromoPeriodAMTPaidAndRemainingBalance';
import monthlyPaymentsPaybackDuringPromoPeriodCalc from '@/domain/calcs/creditCard/calcMonthlyPaymentsPaybackDuringPromoPeriod';
import canLoanBePaidOffCalc from '@/domain/calcs/creditCard/calcCanLoanBePaidOff';
import calcMonthsPayback from '@/domain/calcs/creditCard/calcCanLoanBePaidOff/calcMonthsPayback';

// the amount per month more than the provided monthly payment
export const fastTrackBoostAmount = 20;
export const fastTrackPlusBoostAmount = 25;

const numericFields = ['cachedMonthlyPayment', 'earnings', 'loanPaybackTerm', 'yearsToRetirement'];

const percentageFields = ['interestRate', 'postPromoInterestRate', 'duringPromoInterestRate'];

const parseValues = values => {
  const newValues = {
    ...values,
  };

  // need to make sure these are numbers since they
  // may be strings when they come from inputs
  numericFields.forEach(field => {
    if (newValues[field]) {
      newValues[field] = Number(newValues[field]);
    }
  });

  // need to turn percentages that come from form fields as
  // 6.5 into 0.065 for math
  percentageFields.forEach(field => {
    if (newValues[field]) {
      newValues[field] /= 100;
    }
  });

  return newValues;
};

export const isCreditCardFlowCompleted = ({ flowMetadata }, flowConfig) =>
  isFlowCompleted(flowMetadata[flowConfig.name], flowConfig);

export const calcPromoPeriodAMTPaidAndRemainingBalance = createSelector(
  parseValues,
  // calc 1
  ({ chargeAmount, duringPromoInterestRate, promoPeriodDuration, monthlyPayment }) => {
    const { postPromoPeriodRemainingBalance, promoMonthsUsed } = promoPeriodAMTPaidAndRemainingBalanceCalc({
      chargeAmount,
      duringPromoInterestRate,
      promoPeriodDuration,
      monthlyPayment,
    });

    return {
      postPromoPeriodRemainingBalance,
      promoMonthsUsed,
    };
  }
);

export const calcTotalAmountOfInterestPaid = createSelector(
  parseValues,
  // calc 3
  ({ chargeAmount, totalMonthsToPayOff, monthlyPayment }) =>
    totalAmountOfInterestPaidCalc({
      chargeAmount,
      totalMonthsToPayOff,
      monthlyPayment,
    })
);

export const calcMonthlyPaymentsPaybackDuringPromoPeriod = createSelector(
  parseValues,
  ({ chargeAmount, duringPromoInterestRate, promoPeriodDuration }) => {
    const monthlyPaymentToPayOffDuringPromoPeriod = monthlyPaymentsPaybackDuringPromoPeriodCalc({
      chargeAmount,
      duringPromoInterestRate,
      promoPeriodDuration,
    });

    return Number(monthlyPaymentToPayOffDuringPromoPeriod.round(0, 3));
  }
);

const calcIfLoanCanBePaidOff = createSelector(
  parseValues,
  ({
    promoPeriod,
    chargeAmount,
    monthlyPayment,
    interestRate,
    postPromoPeriodRemainingBalance,
    postPromoInterestRate,
    promoPeriodDuration,
    promoMonthsUsed,
  }) =>
    canLoanBePaidOffCalc({
      promoPeriod,
      chargeAmount,
      monthlyPayment,
      interestRate,
      postPromoPeriodRemainingBalance,
      postPromoInterestRate,
      promoPeriodDuration,
      promoMonthsUsed,
    })
);

const calcFastTrackMonths = createSelector(
  parseValues,
  calcIfLoanCanBePaidOff,
  ({ cachedMonthlyPayment, chargeAmount, interestRate, monthlyPayment, promoPeriod }, { loanCannotBePaidOff }) => {
    if (!loanCannotBePaidOff && !promoPeriod) {
      return calcMonthsPayback({
        chargeAmount,
        interestRate,
        monthlyPayment: (cachedMonthlyPayment == null ? monthlyPayment : cachedMonthlyPayment) + fastTrackBoostAmount,
      });
    }

    return null;
  }
);

const calcFastTrackInterest = createSelector(
  parseValues,
  calcFastTrackMonths,
  ({ cachedMonthlyPayment, chargeAmount, monthlyPayment, promoPeriod }, totalMonthsToPayOff) => {
    if (totalMonthsToPayOff && !promoPeriod) {
      return calcTotalAmountOfInterestPaid({
        chargeAmount,
        monthlyPayment: (cachedMonthlyPayment == null ? monthlyPayment : cachedMonthlyPayment) + fastTrackBoostAmount,
        totalMonthsToPayOff,
      });
    }

    return null;
  }
);

const calcFastTrackPlusMonths = createSelector(
  parseValues,
  calcIfLoanCanBePaidOff,
  ({ cachedMonthlyPayment, chargeAmount, monthlyPayment, interestRate, promoPeriod }, { loanCannotBePaidOff }) => {
    if (!loanCannotBePaidOff && !promoPeriod) {
      return calcMonthsPayback({
        chargeAmount,
        interestRate,
        monthlyPayment:
          (cachedMonthlyPayment == null ? monthlyPayment : cachedMonthlyPayment) + fastTrackPlusBoostAmount,
      });
    }

    return null;
  }
);

const calcFastTrackPlusInterest = createSelector(
  parseValues,
  calcFastTrackPlusMonths,
  ({ cachedMonthlyPayment, chargeAmount, monthlyPayment, promoPeriod }, totalMonthsToPayOff) => {
    if (totalMonthsToPayOff && !promoPeriod) {
      return calcTotalAmountOfInterestPaid({
        chargeAmount,
        monthlyPayment:
          (cachedMonthlyPayment == null ? monthlyPayment : cachedMonthlyPayment) + fastTrackPlusBoostAmount,
        totalMonthsToPayOff,
      });
    }

    return null;
  }
);

export const calcCanLoanBePaidOff = createSelector(
  parseValues,
  calcIfLoanCanBePaidOff,
  calcFastTrackInterest,
  calcFastTrackMonths,
  calcFastTrackPlusInterest,
  calcFastTrackPlusMonths,
  ({ promoPeriod }, calcs, fastTrackInterest, fastTrackMonths, fastTrackPlusInterest, fastTrackPlusMonths) => {
    const fastTrack =
      fastTrackInterest == null || fastTrackMonths == null
        ? null
        : {
            interest: fastTrackInterest,
            months: fastTrackMonths,
            monthsRoundedUp: promoPeriod ? fastTrackMonths : fastTrackMonths.round(0, 3),
          };
    const fastTrackPlus =
      fastTrackPlusInterest == null || fastTrackPlusMonths == null
        ? null
        : {
            interest: fastTrackPlusInterest,
            months: fastTrackPlusMonths,
            monthsRoundedUp: promoPeriod ? fastTrackMonths : fastTrackPlusMonths.round(0, 3),
          };

    return {
      ...calcs,
      fastTrack,
      fastTrackPlus,
    };
  }
);
