/* eslint-disable */
// @ts-nocheck
import medicalCards from './medical';
import autoCards from './auto';
import homeCards from './home';
import otherCards from './other';

const situationalSteps = [...medicalCards, ...autoCards, ...homeCards, ...otherCards];

export default situationalSteps;
