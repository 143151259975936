import { AnyEventObject, assign, EventObject, StateNodeConfig } from 'xstate';

import { isPotentiallyPaidOff, TPaymentPlan } from '@/domain/debtsPlan';
import {
  advanceToNextMonth,
  createPaymentPlanEditingStates,
  updateAmountPaid,
  setActivePaymentPlan,
  updateAmountPaidAndUpsertTransaction,
} from '@/domain/stateMachines/debtManager/paymentPlanStates';
import { createDebtCrudStates, editDebt } from '@/domain/stateMachines/debtManager/debtCrudStates';

import { hasValidDraftPaymentPlan } from './debtsPlanMachineHelpers';

import type { InitialContextT } from '..';
import type { TAccount } from '../../debts';
import type { ApolloQueryResult } from '@apollo/client';

type ShowMonthTransitionPaidOffPopoverEvent = EventObject & ApolloQueryResult<{ data: { updateDebt: TAccount } }>;

type FinishMTEventT = EventObject &
  ApolloQueryResult<{
    data: { advanceToCurrentMonth: { paymentPlan: TPaymentPlan; archivedPaymentPlan: TPaymentPlan } };
  }>;

export const showMonthTransitionPaidOffPopover = assign<InitialContextT, ShowMonthTransitionPaidOffPopoverEvent>({
  showMonthTransitionPaidOffPopover: (_, event) => {
    const { updateDebt } = event.data.data;
    const { minMonthlyPayment, statementBalance } = updateDebt;
    if (isPotentiallyPaidOff(updateDebt) && !minMonthlyPayment && !statementBalance) {
      return !!updateDebt.debtId; // Double check if this is supposed to be boolean
    }
    return false;
  },
});

export const clearMonthTransitionPaidOffPopover = assign({
  showMonthTransitionPaidOffPopover: false,
});

export const finishMonthTransition = assign<InitialContextT, FinishMTEventT>((context, event) => {
  const { currentDate, archivedPaymentPlans } = context;
  const {
    advanceToCurrentMonth: { paymentPlan, archivedPaymentPlan },
  } = event.data.data;

  return {
    paymentPlan,
    draftPaymentPlan: paymentPlan,
    archivedPaymentPlans: [...archivedPaymentPlans, archivedPaymentPlan],
    selectedDate: currentDate,
  };
});

const monthTransitionDebtCrudStates = createDebtCrudStates('initial', {
  navigation: { add: 'navigateToMonthTransitionDebtsList', update: 'navigateToMonthTransitionDebtsList' },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const monthTransitionStates: StateNodeConfig<InitialContextT, any, AnyEventObject> = {
  id: 'monthTransitionEditingStates',
  type: 'parallel',
  states: {
    debtEditingStates: {
      ...monthTransitionDebtCrudStates,
      // @ts-expect-error small thing to fix here
      on: {
        CLEAR_PAID_OFF_POPOVER: {
          actions: 'clearMonthTransitionPaidOffPopover',
        },
        FINISHED_ADDING_ACCOUNTS: [
          {
            target: 'paymentPlanEditing.debtsList',
            cond: hasValidDraftPaymentPlan,
            action: 'navigateToMonthTransitionDebtsList',
          },
          {
            target: 'paymentPlanEditing.advancingPaymentPlan',
          },
        ],
        FINISHED_WHEN_NO_ACCOUNTS: [
          {
            target: 'paymentPlanEditing.debtsList',
            cond: hasValidDraftPaymentPlan,
            action: 'navigateToMonthTransitionDebtsList',
          },
          {
            target: 'paymentPlanEditing.advancingPaymentPlanWithNoAccounts',
          },
        ],
      },
    },
    paymentPlanEditing: {
      initial: 'initial',
      states: {
        ...createPaymentPlanEditingStates('initial'),
        advancingPaymentPlan: {
          invoke: {
            id: 'advancePaymentPlan',
            src: advanceToNextMonth,
            onDone: {
              target: 'debtsList',
              actions: [
                'finishMonthTransition',
                'updateDebtsContext',
                'mergePaymentPlanData',
                'navigateToMonthTransitionDebtsList',
              ],
            },
            onError: {
              target: 'initial',
              actions: 'notifyError',
            },
          },
        },
        advancingPaymentPlanWithNoAccounts: {
          invoke: {
            id: 'advancePaymentPlan',
            src: advanceToNextMonth,
            onDone: {
              target: '#appMachine.loaded.debtManager.paymentPlan.ready',
              actions: ['finishMonthTransition', 'updateDebtsContext', 'mergePaymentPlanData', 'navigateToPaymentPlan'],
            },
            onError: {
              target: 'initial',
              actions: 'notifyError',
            },
          },
        },
        debtsList: {
          on: {
            EDIT_DEBT_FROM_DEBT_LIST: 'editingDebtFromDebtsList',
            FINISHED_UPDATING_DEBTS: {
              target: 'alreadyPaidAccounts',
              actions: 'navigateToMonthTransitionAlreadyPaidAccountsPage',
            },
          },
        },
        alreadyPaidAccounts: {
          on: {
            CANCEL_ALREADY_PAID: {
              target: 'debtsList',
              actions: 'navigateToMonthTransitionDebtsList',
            },
            UPDATE_AMOUNT_PAID_FROM_PAYMENT_PLAN: 'updatingAmountPaidFromDebtsList',
            UPDATE_AMOUNT_PAID_AND_UPSERT_TRANSACTION_FROM_PAYMENT_PLAN:
              'updatingAmountPaidAndUpsertTransactionFromPaymentPlan',
            FINISHED_ALREADY_PAID_ACCOUNTS: 'setActivePaymentPlan',
            SUBMIT_ALREADY_PAID: {
              target: 'confirmBudget',
              actions: 'navigateToMonthTransitionBudgetPage',
            },
          },
        },
        editingDebtFromDebtsList: {
          // @ts-expect-error small fix here
          invoke: {
            id: 'editingDebtFromDebtsList',
            src: editDebt,
            onDone: {
              target: 'debtsList',
              actions: ['updateDebtsContext', 'trackEditedAccount', 'showMonthTransitionPaidOffPopover'],
            },
            onError: {
              target: 'debtsList',
              actions: 'notifyError',
            },
          },
        },
        updatingAmountPaidFromDebtsList: {
          entry: 'trackMarkedDebtAsPaid',
          invoke: {
            id: 'updateAmountPaid',
            src: updateAmountPaid,
            onDone: {
              target: 'alreadyPaidAccounts',
              actions: ['updatePaymentPlan', 'closeModal'],
            },
            onError: {
              target: 'alreadyPaidAccounts',
              actions: 'notifyError',
            },
          },
        },
        updatingAmountPaidAndUpsertTransactionFromDebtEditing: {
          entry: 'trackMarkedDebtAsPaid',
          invoke: {
            id: 'updateAmountPaidAndUpsertTransactionFromDetails',
            src: updateAmountPaidAndUpsertTransaction,
            onDone: [
              {
                target: 'initial',
                actions: ['showProgressPopover', 'setTransactionsAndPaymentPlan', 'showPaidOffPopover'],
              },
            ],
            onError: {
              target: 'initial',
              actions: 'notifyError',
            },
          },
        },
        updatingAmountPaidAndUpsertTransactionFromPaymentPlan: {
          entry: 'trackMarkedDebtAsPaid',
          invoke: {
            id: 'updateAmountPaidAndUpsertTransaction',
            src: updateAmountPaidAndUpsertTransaction,
            onDone: {
              target: 'alreadyPaidAccounts',
              actions: ['updatePaymentPlan', 'closeModal'],
            },
            onError: {
              target: 'alreadyPaidAccounts',
              actions: 'notifyError',
            },
          },
        },
        setActivePaymentPlan: {
          entry: 'mergePaymentPlanData',
          invoke: {
            id: 'setActivePaymentPlan',
            src: setActivePaymentPlan,
            onDone: {
              target: '#appMachine.loaded.debtManager.paymentPlanIntro',
              actions: 'updatePaymentPlan',
            },
            onError: {
              target: 'alreadyPaidAccounts',
              actions: 'notifyError',
            },
          },
        },
        confirmBudget: {
          on: {
            FINISHED_ALREADY_PAID_ACCOUNTS: 'setActivePaymentPlan',
          },
        },
      },
    },
  },
};

export default monthTransitionStates;
