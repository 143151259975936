import React from 'react';

import history from '@/util/history';

import ErrorScreen from '@/pages/ErrorScreen';

import ErrorBoundary from '@/components/ErrorBoundary';

import { TProvider } from '@/types/common';

const DefaultFallbackErrorScreen = ({ clearError }: { clearError: () => void }) => {
  return (
    <ErrorScreen
      retry={() => {
        clearError();
        window.location.reload();
      }}
      goBack={() => {
        clearError();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        history.go(-1);
      }}
    />
  );
};

const GlobalErrorBoundary: TProvider = ({ children }) => (
  <ErrorBoundary FallbackComponent={DefaultFallbackErrorScreen}>{children}</ErrorBoundary>
);

export default GlobalErrorBoundary;
