/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { serialize, expenseFragment, sortByLastModified, TExpense } from '@/domain/expense';
import { defaultUserState, setSafeUserStateValues } from '@/domain/UserState';

import { SessionContext } from '@/services/session';

import { CREATE_EXPENSE } from '@/graphql/mutations';
import { LIST_EXPENSES } from '@/graphql/queries';

function getCurrentExpense(currentExpense, expenses) {
  if (!expenses || !expenses.length) {
    if (currentExpense?.vertical) {
      return setSafeUserStateValues({ ...defaultUserState, ...{ vertical: currentExpense.vertical } });
    }

    return defaultUserState;
  }

  if (!currentExpense?.expenseId) {
    if (currentExpense?.vertical) {
      return setSafeUserStateValues({ ...defaultUserState, ...{ vertical: currentExpense.vertical } });
    }

    // prefer the last updated expense
    const lastModifiedExpense = sortByLastModified(expenses)[expenses.length - 1];
    return lastModifiedExpense;
  }

  return setSafeUserStateValues(expenses.find(e => e.expenseId === currentExpense?.expenseId) || defaultUserState);
}

type ContextT = {
  expenses: {
    loading: boolean;
    data: {
      expenses: {
        status: string;
      }[];
    };
    error: unknown;
  };
  createExpense: () => void;
  setCurrentExpense: (expense: Partial<TExpense>) => void;
};

const initialState: ContextT = {
  expenses: {
    loading: false,
    data: null,
    error: null,
  },
  createExpense: () => {},
  setCurrentExpense: expense => {},
};

export const ExpensesContext = React.createContext(initialState);

const ExpensesProvider = ({ children }) => {
  const { userId, isAuthenticated } = React.useContext(SessionContext);
  const { loading, data, error } = useQuery(LIST_EXPENSES, { variables: { userId }, skip: !isAuthenticated });
  const [currentExpense, setCurrentExpense] = React.useState(null);

  // this creates a new item so we need to update cache manually
  const [createExpense] = useMutation(CREATE_EXPENSE, {
    onCompleted: ({ createExpense: expense }) => setCurrentExpense(expense),
    update(cache, { data: { createExpense: newExpense } }) {
      cache.modify({
        fields: {
          expenses(existingExpensesRefs = [], { readField }) {
            const newExpenseRef = cache.writeFragment({
              data: newExpense,
              fragment: expenseFragment,
            });

            if (existingExpensesRefs.some(ref => readField('expenseId', ref) === newExpense.expenseId)) {
              return existingExpensesRefs;
            }

            return [...existingExpensesRefs, newExpenseRef];
          },
        },
      });
    },
  });

  const selectedExpense = getCurrentExpense(currentExpense, data?.expenses);

  const value = React.useMemo(() => {
    return {
      expenses: {
        loading,
        data,
        error,
      },
      expense: selectedExpense,
      createExpense: async expenseData => {
        const response = await createExpense({
          variables: {
            input: {
              userId,
              expense: serialize(expenseData),
            },
          },
        });

        return response.data;
      },
      setCurrentExpense: expense => setCurrentExpense(expense),
    };
  }, [createExpense, selectedExpense, data, error, loading, userId]);

  return <ExpensesContext.Provider value={value}>{children}</ExpensesContext.Provider>;
};

export default ExpensesProvider;
