/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Icon } from 'semantic-ui-react';

import { getAnswer } from '@/domain/questions/util';
import { loanAmountQ } from '@/domain/questions';

import Currency from '@/components/Currency';
import CalcResultsPlaceholder from '@/components/Placeholder/CalcResultsPlaceholder';
import Divider from '@/components/Divider';
import Paragraph from '@/components/Paragraph';
import { InfoBox } from '@/components/InformationBox';
import withDebouncedRender from '@/components/withDebouncedRender';

import { loanInterestRateQ, loanRepayPeriodQ } from '../questions';
import { calculateMonthlyLoanPayment, calculateTotalInterestPaid } from '../selectors';

import classes from './Results.module.less';

const CalcResults = ({ monthlyLoanPayment, totalInterestPaid }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.resultsWrapper}>
      <Grid padded={false}>
        <Grid.Row columns={2}>
          <Grid.Column className={classes.stat} width={8} stretched>
            <Grid.Row>{t('monthly-payment')}</Grid.Row>
            <Grid.Row>
              <Paragraph noMargin bold size="large">
                <Currency value={monthlyLoanPayment} />
              </Paragraph>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column className={classes.stat} width={8} stretched>
            <Grid.Row>{t('total-interest-paid')}</Grid.Row>
            <Grid.Row>
              <Currency value={totalInterestPaid} />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const Results = ({ answerWrapper }) => {
  const { t } = useTranslation('i18n-unplanned');
  const loanAmount = getAnswer(loanAmountQ, answerWrapper);
  const loanInterestRate = getAnswer(loanInterestRateQ, answerWrapper);
  const loanPaybackTerm = getAnswer(loanRepayPeriodQ, answerWrapper);

  const calculations = React.useMemo(() => {
    if (!loanAmount || !Number(loanInterestRate) || !loanPaybackTerm) {
      return null;
    }

    const monthlyLoanPayment = calculateMonthlyLoanPayment({
      loanAmount,
      loanInterestRate,
      loanPaybackTerm,
    });

    const totalInterestPaid = calculateTotalInterestPaid({
      loanAmount,
      monthlyLoanPayment,
      loanPaybackTerm,
    });

    return {
      monthlyLoanPayment,
      totalInterestPaid,
    };
  }, [loanAmount, loanInterestRate, loanPaybackTerm]);

  if (!calculations) {
    return (
      <Card raised={true}>
        <CalcResultsPlaceholder />
      </Card>
    );
  }

  return (
    <Card raised={true} className={classes.wrapper}>
      <Card.Content>
        <Paragraph noMargin bold size="large">
          {t('results-info.paragraph')}
        </Paragraph>
        <div>
          <CalcResults
            monthlyLoanPayment={calculations?.monthlyLoanPayment}
            totalInterestPaid={calculations?.totalInterestPaid}
          />
        </div>
        <div className={classes.resultCard}>
          <Icon name="warning sign" color="red" />
          <div>
            <b>{t('results-info.heading')}</b>
          </div>
          {t('results-info.content')}
        </div>
      </Card.Content>
    </Card>
  );
};

export default withDebouncedRender(Results);
