export const BASE_PATH_SAVINGS = '/savingsplanner';

export const SAVINGS_LANDING_PAGE_PATH = BASE_PATH_SAVINGS;
export const SAVINGS_DASHBOARD = `${BASE_PATH_SAVINGS}/dashboard`;
export const SAVINGS_ADD_DEPOSIT_PATH = `${BASE_PATH_SAVINGS}/add-deposit`;
export const SAVINGS_ONBOARDING_DASHBOARD = `${BASE_PATH_SAVINGS}/onboarding`;

/**
 * Shared specific paths
 */
export const SAVINGS_PLAN_INTRO_PATH = '/intro';
export const SAVINGS_OVERVIEW_PATH = '/overview';
export const SAVINGS_ACTIVITIES_PATH = '/activities';
export const SAVINGS_ONBOARDING_PATH = '/onboarding';
export const SAVINGS_CONFIRM_BUDGET_PATH = '/confirm-budget';
export const SAVINGS_DEPOSIT_HISTORY_PATH = '/deposit-history';
export const SAVINGS_ALREADY_SAVED_PATH = '/already-saved-amount';
export const SAVINGS_NICKNAME_PATH = '/nickname';
export const SAVINGS_PENDING_PATH = '/pending';

/**
 * "rainy day fund" savings type
 */
export const BASE_PATH_RAINY_DAY_FUND_SAVINGS = `${BASE_PATH_SAVINGS}/rainydayfund`;
export const SAVINGS_RAINY_DAY_FUND_LANDING_PAGE_PATH = BASE_PATH_RAINY_DAY_FUND_SAVINGS;
export const SAVINGS_RAINY_DAY_FUND_ONBOARDING_PATH = `${BASE_PATH_RAINY_DAY_FUND_SAVINGS}${SAVINGS_ONBOARDING_PATH}`;
export const SAVINGS_RAINY_DAY_FUND_INTRO_PATH = `${BASE_PATH_RAINY_DAY_FUND_SAVINGS}${SAVINGS_PLAN_INTRO_PATH}`;
export const SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH = `${BASE_PATH_RAINY_DAY_FUND_SAVINGS}${SAVINGS_OVERVIEW_PATH}`;
export const SAVINGS_RAINY_DAY_FUND_OVERVIEW_ACTIVITIES_PATH = `${SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH}${SAVINGS_ACTIVITIES_PATH}`;
export const SAVINGS_RAINY_DAY_FUND_OVERVIEW_DEPOSIT_HISTORY_PATH = `${SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH}${SAVINGS_DEPOSIT_HISTORY_PATH}`;
export const SAVINGS_RAINY_DAY_FUND_CONFIRM_BUDGET_PATH = `${BASE_PATH_RAINY_DAY_FUND_SAVINGS}${SAVINGS_CONFIRM_BUDGET_PATH}`;
export const SAVINGS_RAINY_DAY_FUND_PENDING_PATH = `${BASE_PATH_RAINY_DAY_FUND_SAVINGS}${SAVINGS_PENDING_PATH}`;

/**
 * "large purchase" savings type
 * We avoid using react-router specific paths here (such as routes with dynamic params)
 * because they only work in the context of creating routes.
 * Using them for navigation or anything else will not work, so we construct those dynamically whenever needed.
 */
export const BASE_PATH_LARGE_PURCHASE_SAVINGS = `${BASE_PATH_SAVINGS}/largepurchase`;
export const SAVINGS_LARGE_PURCHASE_LANDING_PAGE_PATH = BASE_PATH_LARGE_PURCHASE_SAVINGS;
export const SAVINGS_LARGE_PURCHASE_ONBOARDING_PATH = `${BASE_PATH_LARGE_PURCHASE_SAVINGS}${SAVINGS_ONBOARDING_PATH}`;
export const SAVINGS_LARGE_PURCHASE_INTRO_PATH = `${BASE_PATH_LARGE_PURCHASE_SAVINGS}${SAVINGS_PLAN_INTRO_PATH}`;
export const SAVINGS_LARGE_PURCHASE_CONFIRM_BUDGET_PATH = `${BASE_PATH_LARGE_PURCHASE_SAVINGS}${SAVINGS_CONFIRM_BUDGET_PATH}`;
