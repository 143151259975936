/* eslint-disable */
// @ts-nocheck

import createQuestionFactory, { cloneQuestion } from '@/domain/questions/createQuestionFactory';
import { householdIncomeQ } from '@/domain/questions';
import { getAnswerValidationRules } from '@/domain/questions/validation';

import { trans } from '@/util/i18n';

import SliderWithInput from '@/components/Slider/SliderWithInput';
import Field from '@/components/Field';

import HouseholdIncomeWhyAsk from './modal/HouseholdIncomeWhyAsk';

const personalLoansFactory = createQuestionFactory('personalLoans', getAnswerValidationRules);

export const loanInterestRateQ = personalLoansFactory({
  name: 'loanInterestRate',
  title: trans('i18n-questions:interest-rate.title'),
  subtitle: trans('i18n-questions:interest-rate.subtitle'),
  component: Field,
  defaultProps: {
    label: '%',
    labelPosition: 'right',
    width: 16,
  },
});

export const loanRepayPeriodQ = personalLoansFactory({
  name: 'loanRepayPeriod',
  title: trans('i18n-questions:loan-repay-period.title'),
  component: SliderWithInput,
  defaultProps: {
    inputSize: 'large',
    label: trans('years'),
    step: 1,
  },
});

export const householdIncomePLQ = cloneQuestion(householdIncomeQ, {
  links: [HouseholdIncomeWhyAsk],
});
