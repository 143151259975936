import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import classes from './Row.module.less';

/**
 * Render children as a row.
 * @param {Object} props
 * @param {boolean} props.centeredHorizontally
 * @param {boolean} props.centeredVertically
 * @param {boolean} props.spaceBetween - items are evenly distributed in the line; first item is on the start line, last item on the end line
 *
 */

export type RowT = {
  centeredHorizontally?: boolean;
  centeredVertically?: boolean;
  spaceBetween?: boolean;
  className?: string;
  children: React.ReactNode;
};

const Row: FunctionComponent<RowT> = ({
  children,
  centeredHorizontally = false,
  centeredVertically = false,
  spaceBetween = false,
  className = '',
}) => (
  <div
    className={cx(
      classes.row,
      {
        [classes.centeredHorizontally]: centeredHorizontally,
        [classes.centeredVertically]: centeredVertically,
        [classes.spaceBetween]: spaceBetween,
      },
      className
    )}
  >
    {children}
  </div>
);

export default Row;
