import { healthInsuranceQ, medicalBillQ, loanAmountQ, householdIncomeQ } from '@/domain/questions';
import { getAnswer } from '@/domain/questions/util';

import { TDefaultUserState } from '@/types/domain/UserStates';

import { TVerticalsNames, VERTICALS_NAMES } from '../verticals';

export const getLoanAmount = userState => {
  const loanAmountAnswer = String(getAnswer(loanAmountQ, userState));
  return parseInt(loanAmountAnswer, 10);
};

export const getMedicalBill = userState => {
  return getAnswer(medicalBillQ, userState);
};

export const isPlanningAhead = userState => getMedicalBill(userState) === 'planningAhead';

export const haveReceivedBill = userState => getMedicalBill(userState) === 'receivedBill';

export const billPastDue = userState => getMedicalBill(userState) === 'billPastDue';

export const getHealthInsurance = userState => {
  return getAnswer(healthInsuranceQ, userState);
};

export const hasPrivateInsurance = userState => getHealthInsurance(userState) === 'hasPrivateInsurance';

export const hasMedicare = userState => getHealthInsurance(userState) === 'hasMedicare';

export const hasMedicaid = userState => getHealthInsurance(userState) === 'hasMedicaid';

export const isUninsured = userState => getHealthInsurance(userState) === 'uninsured';

export const getHouseholdIncome = userState => {
  return parseInt(String(getAnswer(householdIncomeQ, userState)), 10);
};

export const getYearlyHouseholdIncome = userState => {
  const monthlyHouseholdIncome = getHouseholdIncome(userState);
  return 12 * monthlyHouseholdIncome;
};

export const getCurrentVertical = (userState: TDefaultUserState): TVerticalsNames => {
  return userState.vertical as TVerticalsNames;
};

export const shouldCreateNewExpense = (userState: TDefaultUserState) => {
  // after clicking on the vertical select card, the userState.vertical is set to the selected vertical
  // if the userState.expenseId is not set, it means that the user has not created an expense yet
  // and if the vertical is set, we should create a new expense
  return Object.values(VERTICALS_NAMES).includes(getCurrentVertical(userState)) && !userState.expenseId;
};
