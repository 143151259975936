import Big from 'big.js';

import { TFutureValueOfMonthlyLoanPaymentParams, TFutureValueOfMonthlyLoanPaymentResult } from '@/types/domain/calcs';
/**
 * Calculates the Future Value (FV) of the repaid loan at retirement (assuming all monthly payments are made on-time).
 */

const calculateFVOfMonthlyLoanPayments = ({
  loanPaybackTerm,
  yearsToRetirement,
  retirementAccountExpectedRateOfReturn,
  monthlyLoanPayment,
}: TFutureValueOfMonthlyLoanPaymentParams): TFutureValueOfMonthlyLoanPaymentResult => {
  // Calculate the future value of monthly loan payments at end of payment period:
  const futureValueLoanTerm = Big(monthlyLoanPayment).times(
    Big(1)
      .plus(Big(retirementAccountExpectedRateOfReturn).div(12))
      .pow(loanPaybackTerm * 12)
      .minus(1)
      .div(Big(retirementAccountExpectedRateOfReturn).div(12))
  );

  // Calculate the future value of monthly loan payments at retirement:
  const futureValueLoanRetirementExponential = Big(retirementAccountExpectedRateOfReturn)
    .div(12)
    .plus(1)
    .pow((yearsToRetirement - loanPaybackTerm) * 12);

  const futureValueLoanRetirement = Big(futureValueLoanTerm).times(futureValueLoanRetirementExponential);

  return {
    futureValueLoanTerm,
    futureValueLoanRetirement,
  };
};

export default calculateFVOfMonthlyLoanPayments;
