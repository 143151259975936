/* eslint-disable */
// @ts-nocheck
import TagManager from 'react-gtm-module';
import qs from 'query-string';
import { crc32 } from 'js-crc';

import history from '@/util/history';
import { envOrDefault } from '@/util/env';
import { removeTrailingSlash } from '@/util/string';

function loadScript(src, { appendToHead = false }) {
  const tag = document.createElement('script');
  tag.async = true;
  tag.src = src;

  if (appendToHead) {
    document.head.appendChild(tag);
  } else {
    document.body.appendChild(tag);
  }
}

// since this is a SPA, we need to send these as event metadata
const utmParams = {};

function storeUtmParams(location) {
  // we only need to pass the query part of the string
  const params = qs.parse(location.search);
  const utmParamNames = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content'];

  // only add the UTM params if they are in the URL
  Object.keys(params).forEach(key => {
    if (utmParamNames.includes(key)) {
      utmParams[key] = params[key];
    }
  });
}

/* eslint-disable-next-line no-console */
const logEvent = (eventName, payload) => console.log(`Tracking event: ${eventName}`, payload);

const EVENT_NAME_CRC_MAP = {
  openModal: (event, path) => `${event.name}/${path}/${event.payload.modalName}`,
  enteredVertical: (event, path) => `${event.name}/${path}/${event.payload.vertical}`,
  pageView: (event, path) => `${event.name}/${path}`,
};
function mapEventToCrc(event, path) {
  const getCrcPayload = EVENT_NAME_CRC_MAP[event.name];

  if (!getCrcPayload) {
    return null;
  }

  return crc32(getCrcPayload(event, path));
}

// Configure tag manager
const tagManagerArgs = {
  gtmId: envOrDefault('REACT_APP_GOOGLE_TAG_MANAGER_ID', ''),
  auth: envOrDefault('REACT_APP_GOOGLE_TAG_MANAGER_AUTH', ''),
  preview: envOrDefault('REACT_APP_GOOGLE_TAG_MANAGER_ENV', ''),
};

const ADOBE_LAUNCH_URL = envOrDefault('REACT_APP_ADOBE_LAUNCH_URL', '');

function getEventMetadata(event, path) {
  const crc = mapEventToCrc(event, path);
  const metadata = {
    path,
    timestamp: Date.now(),
    ...utmParams,
    ...(crc ? { crc } : {}),
  };

  return metadata;
}

/**
 * Safely sets the required values of Adobe Data Layer
 */
function updateAdobeDataLayer(safePath = '') {
  const path = safePath.split('/');

  if (!window.AARP || !window.AARP.MetaInfo) {
    window.AARP = { MetaInfo: {} };
  }

  window.AARP.MetaInfo = {
    pagedata: {
      pagename: 'Money Map',
      var1: 'Money Map',
      var2: path[1],
      var3: path[2],
      var4: path[3],
      var5: safePath,
      pageType: window.location.host,
      language: 'english',
      eventtype: 'event8',
    },
    userdata: {},
  };
}

/**
 * Creates an instance of analytics service. Page views are tracked automatically.
 * All other events require use of the function trackEvent. Can be completely turned
 * off or set to log to console instead.
 */
export const createAnalytics = ({ analyticsEnabled = false, adobeLaunchEnabled = false, logToConsole = false }) => {
  if (analyticsEnabled && tagManagerArgs.gtmId) {
    TagManager.initialize(tagManagerArgs);
  }

  // Adobe Launch is treated separately
  if (adobeLaunchEnabled && ADOBE_LAUNCH_URL !== '') {
    loadScript(ADOBE_LAUNCH_URL, { appendToHead: true });
  }

  // store the UTM params to be used when reporting events
  storeUtmParams(window.location);

  /**
   * Be specific about tracking page views
   */
  const trackPageView = path => {
    const safePath = removeTrailingSlash(path);
    const payload = {
      path: safePath,
    };
    const event = { name: 'pageView', payload };
    const metadata = getEventMetadata(event, path);

    if (logToConsole) {
      logEvent('pageView', { payload, metadata });
    }

    if (!analyticsEnabled) {
      return;
    }

    // push to GTM data layer
    TagManager.dataLayer({
      dataLayer: {
        event: event.name,
        payload,
        metadata,
      },
    });

    // push to Adobe Launch
    if (adobeLaunchEnabled && typeof document.dispatchEvent === 'function') {
      // update data layer before sending the event
      updateAdobeDataLayer(safePath);

      document.dispatchEvent(
        new CustomEvent('virtual_page_load', {
          detail: {
            path: safePath,
          },
        })
      );
    }
  };

  /**
   * Track any kind of event.
   */
  const trackEvent = event => {
    const path = removeTrailingSlash(window.location.pathname);
    const metadata = getEventMetadata(event, path);

    if (logToConsole) {
      logEvent(event.name, {
        payload: event.payload,
        metadata,
      });
    }

    if (!analyticsEnabled) {
      return;
    }

    // push to GTM data layer
    TagManager.dataLayer({
      dataLayer: {
        event: event.name,
        payload: event.payload,
        metadata,
      },
    });
  };

  // track page views on route change (does not handle initial page view, only navigation)
  const clearHistoryListener = history.listen(location => {
    trackPageView(location.pathname);
  });

  // public API
  return {
    trackPageView,
    trackEvent,
    clearHistoryListener,
  };
};
