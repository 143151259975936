/* eslint-disable */
// @ts-nocheck
import { FUNDING_FLOW_QUESTIONS_FIRST, HELP_FLOW_QUESTIONS, FREE_FLOW_QUESTIONS_FIRST } from '@/domain/paths';
import { BASE_PATH_DEBT_SOLUTION } from '@/domain/paths/debt';
/**
 * We will need this later
 */
// import checkBill from '@/images/checkBill.svg';
// import continuePayingBills from '@/images/continuePayingBills.svg';
// import paymentPlan from '@/images/paymentPlan.svg';

import { trans } from '@/util/i18n';

import myPlanGrants from '@/pages/MyPlan/images/myPlanGrants.svg';
import myPlanLoan from '@/pages/MyPlan/images/myPlanLoan.svg';
import help from '@/pages/MyPlan/images/findResourcesAndPeopleWhoCanHelp.svg';
import debtManager from '@/pages/MyPlan/images/debtManager.svg';

export const coreSteps = [
  {
    id: 'helpFlow',
    image: help,
    title: trans('help-flow.find-local-resources'),
    shortText: trans('help-flow.people-and-programs'),
    text: trans('help-flow.qualify-financial-assistance'),
    button: {
      text: trans('i18n-actions:button.see-your-options'),
      url: HELP_FLOW_QUESTIONS,
      microcopy: trans('7-options'),
    },
  },
  {
    id: 'freeFundsFlow',
    image: myPlanGrants,
    title: trans('free-funds-flow.sources-of-funds'),
    shortText: trans('free-funds-flow.explore-grants.short-text', 'Explore grants, financial aid and crowdfunding.'),
    text: trans('free-funds-flow.qualify-for-grant'),
    infoBox: {
      content: trans('free-funds-flow.eligibility-for-grants'),
    },
    button: {
      text: trans('i18n-actions:button.see-your-options'),
      url: FREE_FLOW_QUESTIONS_FIRST,
      microcopy: trans('9-options'),
    },
  },
  {
    id: 'fundingFlow',
    image: myPlanLoan,
    title: trans('funding-flow.explore-paying-bill'),
    shortText: trans('funding-flow.have-more-options'),
    text: trans('funding-flow.consider-funding-sources'),
    button: {
      text: trans('i18n-actions:button.see-your-options'),
      url: FUNDING_FLOW_QUESTIONS_FIRST,
      microcopy: trans('12-options'),
    },
  },
  {
    id: 'paybackStrategy',
    image: debtManager,
    title: trans('payback-strategy.create-strategy'),
    shortText: trans('payback-strategy.not-alone'),
    text: trans('payback-strategy.make-a-plan'),
    button: {
      text: trans('i18n-actions:button.get-started'),
      url: BASE_PATH_DEBT_SOLUTION,
      openInNewTab: true,
    },
  },
];

export const authenticatedSuccessMessages = [
  {
    title: trans('way-to-go'),
    description: trans('way-to-go.authenticated'),
  },
  {
    title: trans('making-progress'),
    description: trans('making-progress.authenticated'),
  },
  {
    title: trans('keep-it-up'),
    description: trans('keep-it-up.authenticated'),
  },
  {
    title: trans('need-a-break'),
    description: trans('need-a-break.authenticated'),
  },
  {
    title: trans('all-right-things'),
    description: trans('all-right-things.authenticated'),
  },
  {
    title: trans('not-alone'),
    description: trans('not-alone.authenticated'),
  },
  {
    title: trans('all-done'),
    description: trans('all-done.authenticated'),
  },
];
