import React, { FunctionComponent } from 'react';
import { Header, HeaderProps } from 'semantic-ui-react';
import cx from 'classnames';

import classes from './Heading.module.less';

const Heading: FunctionComponent<HeaderProps & { bold?: boolean; semibold?: boolean }> = ({
  as = 'h1',
  bold = false,
  semibold = false,
  content,
  ...props
}) => (
  <Header
    {...props}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    as={as}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    className={cx(classes[as], props.className, {
      [classes.bold]: bold,
      [classes.semiBold]: semibold,
    })}
    content={content}
  />
);

export default Heading;
