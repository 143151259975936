import { boolean, string, number, array, object } from 'yup';
import {
  ACCOUNT_TYPES,
  STANDARD_ACCOUNT_TYPES,
  ESSENTIAL_ACCOUNT_TYPES,
  CREDITOR_AGREEMENT_TYPES,
  mayBePrioritized,
  nicknameShouldNotBeDuplicated,
  isMinMonthlyPaymentLessOrEqualThanStatementBalance,
  creditorAgreementRegex,
  accountStatusRegex,
  accountTypeRegex,
  ACCOUNT_STATUSES,
} from 'common/model/debt';

import {
  CREDIT_CARD_HELP_PATH,
  FEDERAL_STUDENT_LOAN_HELP_PATH,
  MEDICAL_BILL_HELP_PATH,
  PERSONAL_LOAN_HELP_PATH,
  PRIVATE_STUDENT_LOAN_HELP_PATH,
} from '@/domain/paths/debt';

import i18n from '@/libs/i18n';

import { trans } from '@/util/i18n';
import { nicknameDoesNotContainAccountNumber, MAX_NICKNAME_DIGITS } from '@/util/validation';

export type TEssentialAccountTypes = ['MORTGAGE' | 'HOME_EQUITY_LOAN' | 'AUTO_LOAN'];
export type TStandardAccountTypes = ['CREDIT_CARD' | 'PERSONAL_LOAN' | 'PRIVATE_STUDENT_LOAN'];
export type TFederalStudentLoanType = 'FEDERAL_STUDENT_LOAN';
export type TMedicalBill = 'MEDICAL_BILL';

export type TDebtFieldNameValidation = keyof typeof debtValidationRules;
export type TCreditorAgreementType = keyof typeof CREDITOR_AGREEMENT_TYPES;
export type TAccountType = keyof typeof ACCOUNT_TYPES;
export type TAccount = {
  amount: number;
  debtId: string;
  nickname: string;
  type: TAccountType;
  status: string;
  paymentDueDate: number;
  isDelinquent: boolean;
  minMonthlyPayment: number;
  lastPaymentMade: number;
  statementBalance: number;
  interestRate: number;
  daysDelinquent: number;
  isPrioritized: boolean;
  creditorAgreement: TCreditorAgreementType;
  dateCreated: number;
  debtBudget: number;
};

export const ACCOUNT_TYPE_LABELS_I18N_KEY = {
  [ACCOUNT_TYPES.MORTGAGE]: 'mortgage',
  [ACCOUNT_TYPES.HOME_EQUITY_LOAN]: 'home-equity-loan',
  [ACCOUNT_TYPES.AUTO_LOAN]: 'auto-loan',
  [ACCOUNT_TYPES.CREDIT_CARD]: 'credit-card',
  [ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN]: 'federal-student-loan',
  [ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN]: 'private-student-loan',
  [ACCOUNT_TYPES.PERSONAL_LOAN]: 'personal-loan',
  [ACCOUNT_TYPES.MEDICAL_BILL]: 'medical-debt',
};

export const mapAccountTypeToLabel = (accountType: TAccountType) => {
  return i18n.t(ACCOUNT_TYPE_LABELS_I18N_KEY[accountType]);
};

export const CREDITOR_AGREEMENT_LABELS_I18N_KEY = {
  [CREDITOR_AGREEMENT_TYPES.NO_SPECIAL_AGREEMENT]: 'no-special-agreements',
  [CREDITOR_AGREEMENT_TYPES.DEFERMENT]: 'loan-in-deferment',
  [CREDITOR_AGREEMENT_TYPES.FORBEARANCE]: 'loan-in-forbearance',
  [CREDITOR_AGREEMENT_TYPES.PROMO_PERIOD]: 'loan-in-promotion-period',
  [CREDITOR_AGREEMENT_TYPES.OTHER]: 'other-monthly-repayment',
};

export const mapCreditorAgreementTypeToLabel = (creditorAgreementType: TCreditorAgreementType) => {
  return i18n.t(CREDITOR_AGREEMENT_LABELS_I18N_KEY[creditorAgreementType]);
};

export const mapCreditorAgreementTypeToShortLabel = (creditorAgreementType: TCreditorAgreementType) => {
  const CREDITOR_AGREEMENT_SHORT_LABELS = {
    [CREDITOR_AGREEMENT_TYPES.NO_SPECIAL_AGREEMENT]: i18n.t('none'),
    [CREDITOR_AGREEMENT_TYPES.DEFERMENT]: i18n.t('deferment'),
    [CREDITOR_AGREEMENT_TYPES.FORBEARANCE]: i18n.t('forbearance'),
    [CREDITOR_AGREEMENT_TYPES.PROMO_PERIOD]: i18n.t('promotion'),
    [CREDITOR_AGREEMENT_TYPES.OTHER]: i18n.t('special'),
  };

  return CREDITOR_AGREEMENT_SHORT_LABELS[creditorAgreementType];
};

// Debt type with extended set of properties

const ESSENTIAL_ACCOUNT_DELINQUENCY_THRESHOLD = 0;
export const NON_ESSENTIAL_ACCOUNT_DELINQUENCY_THRESHOLD = 59;
const HIGH_BALANCE_THRESHOLD = 10000;

// Debt Utility Functions
export const getMinPaymentForAccount = ({ minMonthlyPayment }: TAccount) => {
  return minMonthlyPayment;
};
export const getDebtToInterestRatio = ({ statementBalance, interestRate }) => {
  // note: Returns Infinite when interestRate is 0
  return statementBalance / interestRate;
};
export const isPrioritizedAccount = ({ isPrioritized }) => isPrioritized === true;
export const isEssentialAccount = ({ type }: TAccount) => ESSENTIAL_ACCOUNT_TYPES.includes(type);
export const isStandardAccount = ({ type }: TAccount) => STANDARD_ACCOUNT_TYPES.includes(type);
export const isFederalStudentLoan = ({ type }: TAccount) => ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN === type;
export const isMedicalBill = ({ type }: TAccount) => ACCOUNT_TYPES.MEDICAL_BILL === type;
export const isCreditCard = ({ type }: Partial<TAccount>) => ACCOUNT_TYPES.CREDIT_CARD === type;
export const isOverAccountDelinquencyThreshold = (account: TAccount) => {
  const { daysDelinquent } = account;
  const threshold = isEssentialAccount(account)
    ? ESSENTIAL_ACCOUNT_DELINQUENCY_THRESHOLD
    : NON_ESSENTIAL_ACCOUNT_DELINQUENCY_THRESHOLD;

  return Number(daysDelinquent) > threshold;
};
export const isDelinquentBetween30And59Days = ({ daysDelinquent }) =>
  Number(daysDelinquent) >= 30 && Number(daysDelinquent) <= NON_ESSENTIAL_ACCOUNT_DELINQUENCY_THRESHOLD;
export const hasHighBalance = ({ statementBalance }) => statementBalance >= HIGH_BALANCE_THRESHOLD;

export const hasNoMinMonthlyPayment = ({ minMonthlyPayment }) => minMonthlyPayment === 0;
export const hasCreditorAgreement = ({ creditorAgreement }: TAccount) =>
  ![undefined, null, CREDITOR_AGREEMENT_TYPES.NO_SPECIAL_AGREEMENT].includes(creditorAgreement);

// Account Comparison Utility Functions
export const compareMinPayment = (accountA: TAccount, accountB: TAccount) =>
  getMinPaymentForAccount(accountA) - getMinPaymentForAccount(accountB);
export const compareDebtToInterestRatio = (accountA: TAccount, accountB: TAccount) =>
  getDebtToInterestRatio(accountA) - getDebtToInterestRatio(accountB);
export const compareStatementBalance = (accountA: TAccount, accountB: TAccount) =>
  accountA.statementBalance - accountB.statementBalance;
export const compareDateCreated = (accountA: TAccount, accountB: TAccount) =>
  accountB.dateCreated - accountA.dateCreated;
export const compareAccountType = (accountA: TAccount, accountB: TAccount) => {
  // This array maintains an ordered list of account types by relative priority
  const ORDERED_ACCOUNT_TYPES = [
    ...ESSENTIAL_ACCOUNT_TYPES,
    ...STANDARD_ACCOUNT_TYPES,
    ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN,
    ACCOUNT_TYPES.MEDICAL_BILL,
  ];
  return ORDERED_ACCOUNT_TYPES.indexOf(accountA.type) - ORDERED_ACCOUNT_TYPES.indexOf(accountB.type);
};
export const compareAccountStatus = (accountA: TAccount, accountB: TAccount) => {
  const ORDERED_ACCOUNT_STATUS = [ACCOUNT_STATUSES.PENDING, ACCOUNT_STATUSES.ACTIVE];
  return ORDERED_ACCOUNT_STATUS.indexOf(accountA.status) - ORDERED_ACCOUNT_STATUS.indexOf(accountB.status);
};

// Account Filtering Utility Functions
export const filterAccountsByType = (accounts: TAccount[], targetTypes: Partial<TAccountType>[] | TAccountType) => {
  if (typeof targetTypes === 'string') {
    targetTypes = [targetTypes];
  }
  return accounts.filter(({ type }) => targetTypes.includes(type));
};
export const filterAccountsByPrioritized = (accounts: TAccount[], targetPrioritizedState = true) =>
  accounts.filter(({ isPrioritized }) => isPrioritized === targetPrioritizedState);
export const areAccountsEqual = (accountA: TAccount, accountB: TAccount) => {
  if (
    accountA.type === accountB.type &&
    accountA.statementBalance === accountB.statementBalance &&
    accountA.minMonthlyPayment === accountB.minMonthlyPayment &&
    accountA.interestRate === accountB.interestRate
  ) {
    return true;
  }
  return false;
};

export function applyCreditorAgreementToDebt(creditorAgreement: TCreditorAgreementType, debt: TAccount) {
  switch (creditorAgreement) {
    case CREDITOR_AGREEMENT_TYPES.DEFERMENT:
      return { ...debt, creditorAgreement, minMonthlyPayment: 0 };
    case CREDITOR_AGREEMENT_TYPES.FORBEARANCE:
    case CREDITOR_AGREEMENT_TYPES.PROMO_PERIOD:
    case CREDITOR_AGREEMENT_TYPES.OTHER:
      if (mayBePrioritized(debt)) {
        return { ...debt, creditorAgreement, isPrioritized: true };
      }
      return { ...debt, creditorAgreement };

    default:
      return { ...debt, creditorAgreement };
  }
}

const ACCOUNT_TYPE_TO_PATH_MAP = {
  [ACCOUNT_TYPES.CREDIT_CARD]: CREDIT_CARD_HELP_PATH,
  [ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN]: FEDERAL_STUDENT_LOAN_HELP_PATH,
  [ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN]: PRIVATE_STUDENT_LOAN_HELP_PATH,
  [ACCOUNT_TYPES.PERSONAL_LOAN]: PERSONAL_LOAN_HELP_PATH,
  [ACCOUNT_TYPES.MEDICAL_BILL]: MEDICAL_BILL_HELP_PATH,
};

export function mapAccountTypeToTipsLink(account: TAccount) {
  return ACCOUNT_TYPE_TO_PATH_MAP[account?.type] ?? null;
}

/**
 * Checks if 'NO_SPECIAL_AGREEMENT' agreement option is selected when another option was initially set.
 * @param {string} initialValue - initial creditor agreement option selected
 * @param {string} currentValue - current creditor agreement option selected
 */
export const isChangedToNoSpecialAgreement = (
  initialValue: TCreditorAgreementType,
  currentValue: TCreditorAgreementType
) => {
  return (
    currentValue === CREDITOR_AGREEMENT_TYPES.NO_SPECIAL_AGREEMENT &&
    initialValue !== CREDITOR_AGREEMENT_TYPES.NO_SPECIAL_AGREEMENT
  );
};

const REQUIRED_ERROR_TEMPLATE = trans('validation.field-required') as unknown as string;
const PLEASE_ENTER_NUMBER_0_TO_49_9_PERCENT = trans('validation.enter-number-between-min-max-10') as unknown as string;

export const debtValidationRules = {
  debtId: string().required(),
  allUserNicknames: array().of(string()),
  nickname: string()
    .max(64)
    .test(
      'nicknameDoesNotContainAccountNumber',
      trans('validation.more-than-characters', { MAX_NICKNAME_DIGITS }) as unknown as string,
      nicknameDoesNotContainAccountNumber
    )
    .test(
      'nicknameShouldNotBeDuplicated',
      trans('validation.nickname-unique') as unknown as string,
      nicknameShouldNotBeDuplicated
    )
    .trim()
    .required(REQUIRED_ERROR_TEMPLATE),
  type: string().matches(accountTypeRegex).required(REQUIRED_ERROR_TEMPLATE),
  paymentDueDate: number().required(REQUIRED_ERROR_TEMPLATE),
  isDelinquent: boolean().required(REQUIRED_ERROR_TEMPLATE),
  statementBalance: number()
    .min(0, trans('validation.between-0-10000000') as unknown as string)
    .max(10000000, trans('validation.between-0-10000000') as unknown as string)
    .typeError(trans('validation.field-required') as unknown as string)
    .required(REQUIRED_ERROR_TEMPLATE),
  interestRate: number()
    .min(0, PLEASE_ENTER_NUMBER_0_TO_49_9_PERCENT)
    .max(49.9, PLEASE_ENTER_NUMBER_0_TO_49_9_PERCENT)
    .typeError(trans('validation.field-required') as unknown as string)
    .required(REQUIRED_ERROR_TEMPLATE),
  minMonthlyPayment: number().when('creditorAgreement', {
    is: (value: string) => value !== CREDITOR_AGREEMENT_TYPES.DEFERMENT,
    then: schema =>
      schema
        .min(0, trans('validation.between-0-100000') as unknown as string)
        .max(100000, trans('validation.between-0-100000') as unknown as string)
        .typeError(trans('validation.field-required') as unknown as string)
        .required(REQUIRED_ERROR_TEMPLATE)
        .test(
          'isMinMonthlyPaymentLessOrEqualThanStatementBalance',
          trans('validation.statement-balance-max') as unknown as string,
          isMinMonthlyPaymentLessOrEqualThanStatementBalance
        ),
  }),
  lastPaymentMade: number()
    .min(0, trans('validation.between-0-1000000') as unknown as string)
    .max(1000000, trans('validation.between-0-1000000') as unknown as string)
    .required(REQUIRED_ERROR_TEMPLATE),
  status: string().matches(accountStatusRegex).required(REQUIRED_ERROR_TEMPLATE),
  daysDelinquent: number().when('isDelinquent', {
    is: (value: number) => value === 1,
    then: schema =>
      schema.min(1, trans('validation.at-least-1') as unknown as string).required(REQUIRED_ERROR_TEMPLATE),
  }),
  isPrioritized: boolean().when('type', {
    is: (type: string) => !mayBePrioritized({ type }),
    then: boolean().oneOf([false], trans('validation.essential-account-prioritized') as unknown as string),
  }),
  creditorAgreement: string()
    .matches(creditorAgreementRegex)
    .required(REQUIRED_ERROR_TEMPLATE)
    .typeError(REQUIRED_ERROR_TEMPLATE),
  debtBudget: number()
    .min(0, trans('validation.between-0-10000000') as unknown as string)
    .max(10000000, trans('validation.between-0-10000000') as unknown as string)
    .typeError(trans('validation.field-required') as unknown as string)
    .required(REQUIRED_ERROR_TEMPLATE),
};

export const debtValidationSchema = object().shape(debtValidationRules);

export const isAccountComplete = (account: TAccount) => {
  if (!account.nickname) return false;
  if (!account.statementBalance) return false;
  if (!account.interestRate) return false;
  if (!account.minMonthlyPayment) return false;

  return true;
};

export function getValidationRulesFrontend(debtFieldName: TDebtFieldNameValidation) {
  return debtValidationRules[debtFieldName];
}
