/* eslint-disable */
// @ts-nocheck

import React, { ReactNode, FunctionComponent } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import classes from './IconLink.module.less';

const IconLink: FunctionComponent<{ href: string; icon: string; children: ReactNode }> = ({
  href = '/',
  icon = '',
  children,
}) => (
  <Link className={classes.iconLink} to={href} data-testid="headerLeftLink">
    {icon && <Icon className={classes.icon} name={icon} />}
    <span className={classes.iconLinkText}>{children}</span>
  </Link>
);

export default IconLink;
