const NAMESPACES = {
  DEFAULT: 'i18n-default',
  ACTIONS: 'i18n-actions',
  DASHBOARD: 'i18n-dashboard',
  UNPLANNED: 'i18n-unplanned',
  DEBT_MANAGER: 'i18n-debt-manager',
  QUESTIONS: 'i18n-questions',
  BUDGET: 'i18n-budget',
  CREDIT: 'i18n-credit',
  GOALS: 'i18n-goals',
  SAVINGS: 'i18n-savings',
  WELCOME: 'i18n-welcome',
};
const DEFAULT_NS = NAMESPACES.DEFAULT;
const FALLBACK_LNG = 'en';
const LANGUAGES = ['en', 'es']; // add new language support here
const STORAGE_PREFIX = 'i18next_res_aarp_mm_';
const DEFAULT_VALUE = '__STRING_NOT_TRANSLATED__';
const NS_SEPARATOR = ':';

function getLanguageEnvVar() {
  const obj = {};
  LANGUAGES.forEach(lng => {
    obj[lng] = `REACT_APP_I18N_${lng.toUpperCase()}_VERSION`; // environment variable name
  });
  return obj;
}

const LANGUAGES_ENVS_MAP = getLanguageEnvVar();

module.exports = {
  NAMESPACES,
  DEFAULT_NS,
  FALLBACK_LNG,
  LANGUAGES,
  LANGUAGES_ENVS_MAP,
  DEFAULT_VALUE,
  STORAGE_PREFIX,
  NS_SEPARATOR,
};
