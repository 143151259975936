/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';

import { RETIREMENT_PERSONAL_ASSESSMENT_PATH } from '@/domain/paths';

import PersonalAssessment from '@/pages/common/PersonalAssessment';
import YourPersonalAssessmentCardContent from '@/pages/common/YourPersonalAssessmentCardContent';

import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import Divider from '@/components/Divider';
import CustomContainer from '@/components/CustomContainer';
import UpNext from '@/components/UpNext';
import { WizardContext } from '@/components/QuestionsWizard/StepWrapper';
import NumberedList from '@/components/NumberedList';
import QuestionsForm from '@/components/QuestionsWizard/Form';

import allOptionsIcon from '@/images/allOptionsIcon.svg';

const HaveQuestionsCardContent = () => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <>
      <Heading as="h5">{t('have-questions.heading')}</Heading>
      <Paragraph textAlign="left">{t('have-questions.paragraph')}</Paragraph>
    </>
  );
};

export const HowToBorrowContent = ({ hideRightColumn = false, className = '' }) => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <div className={cx(className)}>
      <Heading as="h1">{t('how-to-borrow-content.heading')}</Heading>
      <Paragraph noHorizontalMargin>{t('how-to-borrow-content.paragraph')}</Paragraph>
      <Divider tiny />

      <Grid relaxed stackable columns={hideRightColumn ? 1 : 2}>
        <Grid.Row>
          <Grid.Column>
            <NumberedList testId="howToBorrowList" items={t('how-to-borrow-content.items')} />
          </Grid.Column>
          {!hideRightColumn && (
            <Grid.Column>
              <PersonalAssessment
                assessmentCard={
                  <YourPersonalAssessmentCardContent
                    url={RETIREMENT_PERSONAL_ASSESSMENT_PATH}
                    content={t('how-to-borrow-content.content')}
                  />
                }
                questionsCard={<HaveQuestionsCardContent />}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
};

const HowToBorrow = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-unplanned');
  const { stepId, handleBack } = React.useContext(WizardContext);

  return (
    <CustomContainer noMargin verticallyPadded>
      <HowToBorrowContent hideRightColumn={fromAssessment} />
      {fromAssessment ? null : (
        <QuestionsForm fullWidth backButton={false}>
          <UpNext
            stepId={stepId}
            onSecondaryClick={() => handleBack()}
            imageSrc={allOptionsIcon}
            primaryButtonText={t('i18n-default:payment-options')}
            primaryButtonProps={{ primary: false, secondary: true }}
          >
            <Paragraph>{t('how-to-borrow.paragraph')}</Paragraph>
          </UpNext>
        </QuestionsForm>
      )}
    </CustomContainer>
  );
};

export default HowToBorrow;
