/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import history from '@/util/history';

import Image from '@/components/Image';
import { WizardContext } from '@/components/QuestionsWizard/StepWrapper';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import Button from '@/components/button';

import classes from './UpNext.module.less';

const goBackInBrowserHistory = () => history.goBack();

/**
 * Primary button triggers a form submit, so no onClick handler is needed.
 */
const UpNext = ({
  stepId = '',
  imageSrc,
  noContent,
  children,
  primaryButtonText,
  secondaryButtonText,
  onSecondaryClick = goBackInBrowserHistory,
  primaryButtonLoading = false,
  primaryButtonDisabled = false,
  primaryButtonProps,
  noPrimaryButton = false,
  secondaryButtonProps,
  className,
}) => {
  const { t } = useTranslation();

  if (noContent) {
    return (
      <div className={classes.buttonsWrapper}>
        <Button
          className={cx(classes.button, classes.backButton)}
          danger
          size="small"
          compact
          fluid
          data-testid={`${stepId}-backBtn`}
          type="button"
          onClick={onSecondaryClick}
          {...secondaryButtonProps}
        >
          {secondaryButtonText || t('i18n-actions:button.back')}
        </Button>
        {noPrimaryButton === false && (
          <Button
            className={classes.nextButton}
            primary
            size="small"
            fluid
            compact
            type="submit"
            data-testid={`${stepId}-nextBtn`}
            disabled={primaryButtonDisabled}
            loading={primaryButtonLoading}
            content={primaryButtonText || t('i18n-actions:button.next')}
            {...primaryButtonProps}
          />
        )}
      </div>
    );
  }

  return (
    <div className={cx(classes.upNext, className)}>
      <Heading as="h6" className={classes.heading}>
        {t('up-next')}
      </Heading>
      <div className={classes.bodyWrapper}>
        <div>
          <Paragraph as="div" noHorizontalMargin className={classes.contentWrapper}>
            {children}
          </Paragraph>
          <div className={classes.buttonsWrapper}>
            <Button
              className={cx(classes.button, classes.backButton)}
              danger
              size="small"
              compact
              fluid
              data-testid={`${stepId}-backBtn`}
              type="button"
              onClick={onSecondaryClick}
              {...secondaryButtonProps}
            >
              {secondaryButtonText || t('i18n-actions:button.back')}
            </Button>
            {noPrimaryButton === false && (
              <Button
                className={classes.nextButton}
                primary
                size="small"
                fluid
                compact
                type="submit"
                data-testid={`${stepId}-nextBtn`}
                disabled={primaryButtonDisabled}
                loading={primaryButtonLoading}
                content={primaryButtonText || t('i18n-actions:button.next')}
                {...primaryButtonProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Context aware version of the same component which gets autoconfigured by using context values.
 */
export const WizardUpNext = ({
  noContent = false,
  imageSrc,
  children,
  primaryButtonText,
  onPrimaryClick,
  ...additionalProps
}) => {
  const { t } = useTranslation('i18n-actions');
  const { stepId, handleBack, isSubmitting, handleSubmit } = React.useContext(WizardContext);

  return (
    <UpNext
      noContent={noContent}
      imageSrc={imageSrc}
      stepId={stepId}
      primaryButtonLoading={isSubmitting}
      primaryButtonDisabled={isSubmitting}
      primaryButtonText={primaryButtonText || t('button.next')}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={handleBack}
      {...additionalProps}
    >
      {children}
    </UpNext>
  );
};

export default UpNext;
