/* eslint-disable */
// @ts-nocheck
import { trans } from '@/util/i18n';

/**
 * Determines if the step related to the given ID is the intro step in its flow.
 * @param {*} stepId
 * @param {*} state
 * @param {*} reverseReducer
 */
export const isIntroStep = (stepId, state, reverseReducer) => {
  const previousStepData = reverseReducer(state, { stepId });
  return !previousStepData.stepId && Boolean(previousStepData.nextLocation);
};

export const getPreviousStepIds = (stepId, reverseReducer, state) => {
  const previousStepIds = [];

  const traversePreviousSteps = currentStepId => {
    const { stepId: previousStepId } = reverseReducer(state, { stepId: currentStepId });
    if (!previousStepId) {
      return;
    }

    previousStepIds.push(previousStepId);
    traversePreviousSteps(previousStepId);
  };

  traversePreviousSteps(stepId);

  return previousStepIds;
};

export const isStepCompleted = (stepId, flowMetadata) => {
  if (!flowMetadata) {
    return false;
  }

  const stepMetadata = flowMetadata[stepId];

  if (!stepMetadata) {
    return false;
  }

  return Boolean(stepMetadata.dateCompleted);
};

/**
 * Checks if the flow has been completed by checking if each configured step has a dateCompleted value.
 * @param {FlowMetadata} flowMetadata
 * @param {WizardConfigT} config
 * @returns {Boolean}
 */
export const isFlowCompleted = (flowMetadata, config) => {
  if (!flowMetadata) {
    return false;
  }

  return (
    Object.keys(config.steps)
      // the assessment isn't part of the flow that we need to check for completion
      .filter(name => !config.steps[name].skipCompleteCheck)
      .every(name => flowMetadata[name] && flowMetadata[name].dateCompleted != null)
  );
};

/**
 * Validates if the user can reach the current step using direct navigation.
 * @param {string} stepId
 * @param {FlowMetadata} flowMetadata
 */
export const isStepReachable = (stepId, reverseReducer, flowMetadata, state) => {
  const stepMetadata = flowMetadata[stepId];

  // first check if all previous steps completed
  const previousStepIds = getPreviousStepIds(stepId, reverseReducer, state);
  const allPreviousStepsCompleted = previousStepIds.every(previousStepId =>
    isStepCompleted(previousStepId, flowMetadata)
  );

  // if all steps before have been completed, this one is definitely valid
  if (allPreviousStepsCompleted) {
    return true;
  }

  // try another strategy if the above one didn't work

  // means this step was never visited
  if (!stepMetadata) {
    return false;
  }

  // step must have date visited
  return Boolean(stepMetadata.dateVisited);
};

/**
 * A function factory. Creates a function determining the first reachable step based on historic data.
 * If the current step is not reachable, use `reverseReducer` to determine the previous step.
 * Goes all the way back to intro step.
 * @param {FlowMetadata} flowMetadata
 * @param {WizardReducerT} reverseReducer
 * @param {UserStateT} state
 */
export const findFirstReachableStepFactory = (flowMetadata, reverseReducer, state) => {
  const findFirstReachableStep = stepId => {
    const currentStepReachable = isStepReachable(stepId, reverseReducer, flowMetadata, state);
    const introStep = isIntroStep(stepId, state, reverseReducer);

    // we shouldn't move beyond intro step
    if (introStep) {
      return stepId;
    }

    if (!currentStepReachable) {
      const { stepId: previousStepId } = reverseReducer(state, { stepId });
      return findFirstReachableStep(previousStepId);
    }

    return stepId;
  };

  return findFirstReachableStep;
};

/**
 * Convenience function to reduce boilerplate - prefers progress to plain strings
 */
export const getStepTitle = step => {
  if (step === undefined || step === null) {
    return '';
  }

  const { progress } = step;
  if (progress) {
    return trans('step-progress', {
      transProgressCurrent: progress.current,
      transProgressTotal: progress.total,
    });
  }
  if (step.header !== undefined && step.header !== null) {
    return step.header;
  }
  return '';
};
