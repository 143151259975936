import { boolean, string, number, ref, array } from 'yup';

export const READ_ONLY_DEBT_FIELD_NAMES = ['type', 'typeLabel'];

export const ACCOUNT_TYPES = {
  MORTGAGE: 'MORTGAGE',
  HOME_EQUITY_LOAN: 'HOME_EQUITY_LOAN',
  AUTO_LOAN: 'AUTO_LOAN',
  CREDIT_CARD: 'CREDIT_CARD',
  FEDERAL_STUDENT_LOAN: 'FEDERAL_STUDENT_LOAN',
  PRIVATE_STUDENT_LOAN: 'PRIVATE_STUDENT_LOAN',
  PERSONAL_LOAN: 'PERSONAL_LOAN',
  MEDICAL_BILL: 'MEDICAL_BILL',
};

export const ACCOUNT_TYPE_LABELS = {
  [ACCOUNT_TYPES.MORTGAGE]: 'Mortgage',
  [ACCOUNT_TYPES.HOME_EQUITY_LOAN]: 'Home equity loan',
  [ACCOUNT_TYPES.AUTO_LOAN]: 'Auto loan',
  [ACCOUNT_TYPES.CREDIT_CARD]: 'Credit card',
  [ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN]: 'Federal student loan',
  [ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN]: 'Private student loan',
  [ACCOUNT_TYPES.PERSONAL_LOAN]: 'Personal loan',
  [ACCOUNT_TYPES.MEDICAL_BILL]: 'Medical debt',
};

export const ESSENTIAL_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.MORTGAGE,
  ACCOUNT_TYPES.HOME_EQUITY_LOAN,
  ACCOUNT_TYPES.AUTO_LOAN,
];

export const STANDARD_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.CREDIT_CARD,
  ACCOUNT_TYPES.PERSONAL_LOAN,
  ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN,
];

export const ACCOUNT_STATUSES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  ARCHIVED: 'ARCHIVED',
  PENDING: 'PENDING',
};

export const DEBT_MANAGER_STATES = {
  EMPTY: 'EMPTY',
  ONBOARDING: 'ONBOARDING',
  PLAN: 'PLAN',
  NO_ACCOUNTS: 'NO_ACCOUNTS',
};

export const CREDITOR_AGREEMENT_TYPES = {
  NO_SPECIAL_AGREEMENT: 'NO_SPECIAL_AGREEMENT',
  DEFERMENT: 'DEFERMENT',
  FORBEARANCE: 'FORBEARANCE',
  PROMO_PERIOD: 'PROMO_PERIOD',
  OTHER: 'OTHER',
};

const REQUIRED_ERROR_TEMPLATE = 'This field is required.';

export const MAX_NICKNAME_DIGITS = 2;

const buildStringMatchRegex = strings => new RegExp(`(${Object.values(strings).join('|')})`);

export const accountTypeRegex = buildStringMatchRegex(Object.values(ACCOUNT_TYPES));
export const accountStatusRegex = buildStringMatchRegex(Object.values(ACCOUNT_STATUSES));
export const creditorAgreementRegex = buildStringMatchRegex(Object.values(CREDITOR_AGREEMENT_TYPES));

export function isMinMonthlyPaymentLessOrEqualThanStatementBalance(value) {
  return value <= ref('statementBalance').getter(this.parent);
}

export function isStatementBalanceGreaterOrEqualThanMinMonthlyPayment(value) {
  return value >= ref('minMonthlyPayment').getter(this.parent);
}

// Essential accounts may not be marked as prioritized
export const mayBePrioritized = ({ type }) => !ESSENTIAL_ACCOUNT_TYPES.includes(type);

export function nicknameDoesNotContainAccountNumber(value) {
  if (!value) {
    return true;
  }
  const digitCount = (value.match(/\d/g) || []).length;
  return digitCount <= MAX_NICKNAME_DIGITS;
}

export function nicknameShouldNotBeDuplicated(value) {
  const nicknames = ref('allUserNicknames').getter(this.parent);
  return !nicknames.includes(value);
}

const debtValidationRules = {
  debtId: string().required(),
  allUserNicknames: array().of(string()),
  nickname: string()
    .max(64)
    .test(
      'nicknameDoesNotContainAccountNumber',
      `Do not enter more than ${MAX_NICKNAME_DIGITS} numeric characters`,
      nicknameDoesNotContainAccountNumber
    )
    .test(
      'nicknameShouldNotBeDuplicated',
      `You've already used this nickname. Please choose a unique name`,
      nicknameShouldNotBeDuplicated
    )
    .trim()
    .required(REQUIRED_ERROR_TEMPLATE),
  type: string().matches(accountTypeRegex).required(REQUIRED_ERROR_TEMPLATE),
  paymentDueDate: number(),
  isDelinquent: boolean().required(REQUIRED_ERROR_TEMPLATE),
  statementBalance: number()
    .min(0, 'Must be between $0 - $10000000')
    .max(10000000, 'Must be between $0 - $10000000')
    .required(REQUIRED_ERROR_TEMPLATE)
    .test(
      'isStatementBalanceGreaterOrEqualThanMinMonthlyPayment',
      'Cannot be less than minimum payment due',
      isStatementBalanceGreaterOrEqualThanMinMonthlyPayment
    ),
  interestRate: number()
    .min(0, 'Please enter a number between 0% and 49.9%')
    .max(49.9, 'Please enter a number between 0% and 49.9%')
    .required(REQUIRED_ERROR_TEMPLATE),
  minMonthlyPayment: number().when('creditorAgreement', {
    is: value => value !== CREDITOR_AGREEMENT_TYPES.DEFERMENT,
    then: schema =>
      schema
        .min(0, 'Must be between $0 - $100000')
        .max(100000, 'Must be between $0 - $100000')
        .required(REQUIRED_ERROR_TEMPLATE)
        .test(
          'isMinMonthlyPaymentLessOrEqualThanStatementBalance',
          'Cannot be greater than statement balance',
          isMinMonthlyPaymentLessOrEqualThanStatementBalance
        ),
  }),
  lastPaymentMade: number()
    .min(0, 'Must be between $0 - $1000000')
    .max(1000000, 'Must be between $0 - $1000000')
    .required(REQUIRED_ERROR_TEMPLATE),
  status: string().matches(accountStatusRegex).required(REQUIRED_ERROR_TEMPLATE),
  daysDelinquent: number().when('isDelinquent', {
    is: value => value === 1,
    then: schema => schema.min(1, 'Must be at least 1').required(REQUIRED_ERROR_TEMPLATE),
  }),
  isPrioritized: boolean().when('type', {
    is: type => !mayBePrioritized({ type }),
    then: boolean().oneOf([false], 'Cannot be prioritized when is an essential account'),
  }),
  creditorAgreement: string().matches(creditorAgreementRegex).required(REQUIRED_ERROR_TEMPLATE),
  debtBudget: number().min(0, 'Must be between $0 - $1000000').max(1000000, 'Must be between $0 - $1000000'),
};

export function getValidationRules(debtFieldName) {
  return debtValidationRules[debtFieldName];
}
