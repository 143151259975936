/* eslint-disable */
// @ts-nocheck
import { isSameMonth } from 'date-fns';
import { matchPath } from 'react-router-dom';
import { NB_SAVINGS_TYPES, NB_SAVINGS_TYPE_ID } from 'common/model/budget';
import { ACCOUNT_TYPES, ACCOUNT_STATUSES } from 'common/model/debt';
import { PAYMENT_PLAN_STATUSES } from 'common/model/paymentPlan';
import { ACTIVITY_STATUSES } from 'common/model/activity';
import { SAVINGS_TYPES } from 'common/model/savings';

import {
  BUDGET_OVERVIEW_PATH,
  DASHBOARD_VERTICAL_SELECT_PATH,
  RETIREMENT_ACCOUNT_SAVINGS_ACCOUNT,
  STUDENT_LOAN_RELIEF_OPTIONS,
  SAVINGS_DASHBOARD,
  SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH,
  SAVINGS_LARGE_PURCHASE_LANDING_PAGE_PATH,
  SAVINGS_ADD_DEPOSIT_PATH,
  FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL,
} from '@/domain/paths';
import { AARP_POST_PANDEMIC_SPENDING_TIPS, AARP_HOW_TO_CREATE_A_HOUSEHOLD_BUDGET } from '@/domain/externalPaths/index';
import {
  createAllocatedPayments,
  DASHBOARD_DEBT_MANAGER_NAV_HACK,
} from '@/domain/stateMachines/debtManager/debtsPlanMachineHelpers';
import { DEBT_PAYMENT_PLAN_PATH, DEBT_ACCOUNTS_PATH } from '@/domain/paths/debt';
import {
  ADD_SAVE_FOR_RETIREMENT_GOAL_DEPOSIT,
  ADD_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS,
  ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS,
  ADD_CREDIT_CARD_DEBT_GOAL_DETAILS,
  ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS,
  ADD_OTHER_DEBT_GOAL_DETAILS,
  ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DETAILS,
  ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS,
} from '@/domain/paths/goals';
import {
  isPaidOff,
  getReasonsForDisqualification,
  mergePaymentPlanWithDebts,
  isOptimalSuggestedAmountPaid,
  isSuggestedAmountPaid,
} from '@/domain/debtsPlan';
import { hasNoMinMonthlyPayment } from '@/domain/debts';

import { trans } from '@/util/i18n';
import history from '@/util/history';
import { createStorage } from '@/util/storage';
import { or } from '@/util/function';
import { getCurrentDate } from '@/util/date';

import {
  getSaveForRainyDayGoalDetails,
  getSaveForLargePurchaseGoalDetails,
  getSaveForRetirementGoalDetails,
} from '@/pages/Goals/MeasureGoals/GoalDetails/selectors';
import { getExpenseStatus, getMyPlanExpenseStats } from '@/pages/Dashboard/UnplannedExpenses/selectors';
import {
  creditCardDebtSizeSelector,
  studentDebtSizeSelector,
  otherDebtSizeSelector,
} from '@/pages/Goals/MeasureGoals/GoalsTimeline/selectors';
import { TAB_NAMES, STORAGE_KEY } from '@/pages/DebtPlan/PaymentPlan/PaymentPlanTabs/constants';

import { firstAccessibleLinkOfExpenseSelector } from '@/components/VerticalSelector';

const storage = createStorage('session');

// UTILS
const isOtherDebts = d =>
  d.type !== ACCOUNT_TYPES.CREDIT_CARD &&
  d.type !== ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN &&
  d.type !== ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN;

const isStudentLoan = d =>
  d.type === ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN || d.type === ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN;

const isCreditCard = d => d.type === ACCOUNT_TYPES.CREDIT_CARD;

const DEBT_GOALS = [
  { goalType: 'payOffCreditCardDebtMeasured', debtCheck: isCreditCard },
  { goalType: 'payOffStudentLoanDebtMeasured', debtCheck: isStudentLoan },
  { goalType: 'payOffOtherDebtMeasured', debtCheck: isOtherDebts },
];

const hasAtLestOneCreditorsBeenContacted = ({ debts, paymentPlans }) => {
  const activePaymentPlan = paymentPlans.find(p => p.status === PAYMENT_PLAN_STATUSES.ACTIVE);

  if (!activePaymentPlan) {
    return false;
  }

  const notPaidDebts = activePaymentPlan.amountsPaid.filter(({ debtId, amount }) => {
    const debt = debts.find(d => d.debtId === debtId);
    if (!debt) {
      return false;
    }
    return !isPaidOff({ amount, statementBalance: debt.statementBalance }) && !hasNoMinMonthlyPayment(debt);
  });

  if (notPaidDebts.length === 0) {
    return false;
  }

  return notPaidDebts.some(({ hasContactedCreditor }) => hasContactedCreditor === true);
};

const isUserNotQualified = ({ debts, paymentPlans }) => {
  // don't show if there are no debts
  if (debts.length === 0) {
    return false;
  }

  const activePaymentPlan = paymentPlans.find(p => p.status === PAYMENT_PLAN_STATUSES.ACTIVE);

  const { allocatedPayments } = createAllocatedPayments({
    debts,
    paymentPlan: activePaymentPlan,
    allocatedPayments: { amountsPaid: [] },
  });

  const debtsPlan = mergePaymentPlanWithDebts({
    paymentPlan: activePaymentPlan,
    debts,
    allocatedPayments,
  });

  const reasonsForDisqualification = getReasonsForDisqualification(debts, debtsPlan);

  const isQualified = reasonsForDisqualification.length === 0;

  return !isQualified;
};

const isDebtSuggestedAmountPaid = ({ debts, paymentPlans, allDebts }) => {
  const activePaymentPlan = paymentPlans.find(p => p.status === PAYMENT_PLAN_STATUSES.ACTIVE);

  if (debts.length === 0) {
    return false;
  }

  const { allocatedPayments } = createAllocatedPayments({
    debts: allDebts,
    paymentPlan: activePaymentPlan,
    allocatedPayments: { amountsPaid: [] },
  });

  const debtsPlan = mergePaymentPlanWithDebts({
    paymentPlan: activePaymentPlan,
    debts,
    allocatedPayments,
  });

  return debtsPlan.amountsPaid.every(or(isOptimalSuggestedAmountPaid, isSuggestedAmountPaid));
};

export const getGoalUnplannedExpense = (...params) => {
  const [globalState, goalId] = params;
  return globalState.expenses.find(expense => expense.answers.general_expenseGoalId === goalId);
};

export const checkIfActivityTypeHasCompleteStatus = (...params) => {
  const [globalState, activityType] = params;
  const { activities } = globalState;
  return (
    activities &&
    activities[activityType] &&
    activities[activityType].activityData &&
    activities[activityType].activityData.status === ACTIVITY_STATUSES.COMPLETED
  );
};

export const debtAmountPaid = (...params) => {
  const [goalsState, accountTypes] = params;
  const creditCardDebtsIds = goalsState.debts.reduce((debtIds, debt) => {
    if (debt.status === ACCOUNT_STATUSES.ACTIVE && accountTypes.includes(debt.type)) {
      return [...debtIds, debt.debtId];
    }

    return debtIds;
  }, []);

  const amountPaid = goalsState.paymentPlans.reduce((amountAcc, plan) => {
    return (
      amountAcc +
      plan.amountsPaid.reduce((monthlyAmountAcc, payment) => {
        if (creditCardDebtsIds.includes(payment.debtId)) {
          return monthlyAmountAcc + payment.amount;
        }
        return monthlyAmountAcc;
      }, 0)
    );
  }, 0);
  return amountPaid;
};

export const creditCardDebtAmountPaid = goalsState => debtAmountPaid(goalsState, [ACCOUNT_TYPES.CREDIT_CARD]);
export const studentLoanDebtAmountPaid = goalsState =>
  debtAmountPaid(goalsState, [ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN, ACCOUNT_TYPES.PRIVATE_STUDENT_LOAN]);
export const otherDebtAmountPaid = goalsState =>
  debtAmountPaid(goalsState, [
    ACCOUNT_TYPES.MORTGAGE,
    ACCOUNT_TYPES.HOME_EQUITY_LOAN,
    ACCOUNT_TYPES.AUTO_LOAN,
    ACCOUNT_TYPES.PERSONAL_LOAN,
    ACCOUNT_TYPES.MEDICAL_BILL,
  ]);

export const savingsContributionAdded = (params, expenseTypes, goalType, largePurchaseSavingsId) => {
  const [goalsState] = params;

  const { goals, savingsTransactions } = goalsState;
  const currentDate = getCurrentDate();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const hasRainyDayTransactionThisMonth = !!savingsTransactions.find(monthOfTransactions => {
    return (
      parseFloat(monthOfTransactions.month) === currentMonth &&
      parseFloat(monthOfTransactions.year) === currentYear &&
      monthOfTransactions.list
        .filter(
          transaction =>
            transaction.date > goals[goalType].goalData.dateCreated &&
            (largePurchaseSavingsId ? transaction.savingsLargePurchaseId === largePurchaseSavingsId : true)
        )
        .find(transaction => expenseTypes.includes(transaction.expense))
    );
  });
  return hasRainyDayTransactionThisMonth;
};

// CHECKS
export const unplannedExpenseIdentified = (...params) => {
  const goalUnplannedExpense = getGoalUnplannedExpense(...params);
  if (!goalUnplannedExpense) return false;

  const goalUnplannedExpenseStatus = getExpenseStatus(goalUnplannedExpense);
  if (goalUnplannedExpenseStatus !== 'onboardingNotFinished') return true;

  return false;
};

export const budgetBuilt = (...params) => {
  const [globalState] = params;
  const userHasSetMonthlyIncome = globalState.budget && globalState.budget.netMonthlyIncome;
  const userHasAddedAtLeastOneExpense =
    globalState.budget &&
    Object.keys(globalState.budget.expenses)
      .find(expenseKey => !!globalState.budget.expenses[expenseKey]);

  if (userHasSetMonthlyIncome && userHasAddedAtLeastOneExpense) return true;
  return false;
};

export const isAllDebtsTracked = (...params) => {
  const [globalState] = params;
  const { goals, debts } = globalState;

  return DEBT_GOALS.reduce((allDebtGoalsTrackedAcc, debtGoal) => {
    if (goals[debtGoal.goalType]) {
      return allDebtGoalsTrackedAcc && debts.some(debtGoal.debtCheck);
    }
    return allDebtGoalsTrackedAcc;
  }, true);
};

export const budgetUpdated = (...params) => {
  const [globalState] = params;

  const currentDate = getCurrentDate().getTime();

  return isSameMonth(globalState?.budget?.dateUpdated, currentDate);
};

export const paymentPlanReviewed = (...params) => {
  const [globalState] = params;
  const currentDate = getCurrentDate();
  const reviewList = globalState?.paymentPlans?.filter(
    // eslint-disable-next-line radix
    plan => parseInt(plan.year) === currentDate.getFullYear() && parseInt(plan.month) === currentDate.getMonth() + 1
  );
  if (reviewList?.length > 0) {
    return true;
  }
  return false;
};

export const paymentPlanCreated = (...params) => {
  const [globalState] = params;

  const currentDate = getCurrentDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  if (
    globalState?.paymentPlans?.find(
      plan =>
        parseFloat(plan.month) === currentMonth &&
        parseFloat(plan.year) === currentYear &&
        plan.status === PAYMENT_PLAN_STATUSES.ACTIVE
    )
  ) {
    return true;
  }
  return false;
};

export const actionOnMyPlanTook = (...params) => {
  const goalUnplannedExpense = getGoalUnplannedExpense(...params);
  if (!goalUnplannedExpense) return false;

  const userTookActionThisMonth = getCurrentDate().getMonth() === new Date(goalUnplannedExpense.dateUpdated).getMonth();
  return userTookActionThisMonth;
};

export const separateSavingsAccountRainyDayStarted = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'startSeparateSavingsAccountRainyDay');
};

export const separateSavingsAccountLargePurchaseStarted = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'startSeparateSavingsAccountLargePurchase');
};

export const automaticContributionsRainyDaySet = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'setAutomaticContributionsRainyDay');
};

export const automaticContributionsRetirementSet = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'setAutomaticContributionsRetirement');
};

export const automaticContributionsLargePurchaseSet = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'setAutomaticContributionsLargePurchase');
};

export const separateSavingsAccountRetirementStarted = (...params) => {
  const [globalState] = params;
  return !!globalState?.activities?.startSeparateSavingsAccountRetirement?.activityData?.answers
    .retirement_retirementAccounts;
};

export const tipsForReducingSavingsChecked = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'tipsForReducingSpendingMeasured');
};

export const currentSpendingTracked = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'trackCurrentSpendingMeasured');
};

export const reliefOptionsExplored = (...params) => {
  const [globalState] = params;
  return !!globalState?.activities?.exploreReliefOptionsMeasured?.activityData?.answers?.studentLoan_reliefOptions;
};

export const haveCreditorsBeenContacted = (...params) => {
  const [globalState] = params;
  const { paymentPlans, goals, debts } = globalState;

  return hasAtLestOneCreditorsBeenContacted({
    debts: debts,
    paymentPlans,
  }) && !isUserNotQualified({ debts: debts, paymentPlans });
};

export const canShowContactCreditors = (...params) => {
  const [globalState] = params;
  const { paymentPlans, goals, debts } = globalState;

  return isUserNotQualified({ debts: debts, paymentPlans }) || haveCreditorsBeenContacted(...params);
};

export const isSuggestedAmountMeasuredPaid = (...params) => {
  if (!isAllDebtsTracked(...params)) {
    return false;
  }

  const [globalState] = params;
  const { paymentPlans, goals, debts } = globalState;

  const allActiveGoalDebts = DEBT_GOALS.reduce((allDebtGoalsTrackedAcc, debtGoal) => {
    if (goals[debtGoal.goalType]) {
      return [...allDebtGoalsTrackedAcc, ...debts.filter(debtGoal.debtCheck)];
    }
    return allDebtGoalsTrackedAcc;
  }, []);

  return isDebtSuggestedAmountPaid({ debts: allActiveGoalDebts, paymentPlans, allDebts: globalState.debts });
};

export const unplannedExpenseGoalDetailsAdded = (...params) => {
  const [goalsState] = params;
  const { goalDate, expenseValue, emergencySavings } = goalsState.goals.manageUnplannedExpenseMeasured.goalData.answers;
  return goalDate && expenseValue && emergencySavings;
};

export const reduceMyExpensesGoalDetailsAdded = (...params) => {
  const [goalsState] = params;
  const { goalDate, monthlyExpensesGoal, initialMonthValue } =
    goalsState.goals.reduceMyExpensesMeasured.goalData.answers;
  return goalDate && monthlyExpensesGoal && initialMonthValue;
};

export const creditCardDebtGoalDetailsAdded = (...params) => {
  const [goalsState] = params;
  const { payOffDate, debtSize } = goalsState.goals.payOffCreditCardDebtMeasured.goalData.answers;
  return payOffDate && debtSize;
};

export const studentLoanDebtGoalDetailsAdded = (...params) => {
  const [goalsState] = params;
  const { payOffDate, debtSize } = goalsState.goals.payOffStudentLoanDebtMeasured.goalData.answers;
  return payOffDate && debtSize;
};

export const otherDebtGoalDetailsAdded = (...params) => {
  const [goalsState] = params;
  const { payOffDate, debtSize } = goalsState.goals.payOffOtherDebtMeasured.goalData.answers;
  return payOffDate && debtSize;
};

export const saveForRainyDayGoalUsedSavingsTool = (...params) => {
  const [goalsState] = params;
  const { savings } = goalsState;

  return !!savings.find(({ type }) => type === SAVINGS_TYPES.RAINY_DAY);
};

export const saveForLargePurchaseGoalUsedSavingsTool = (...params) => {
  const [goalsState] = params;
  const { savings } = goalsState;

  return !!savings.find(({ type }) => type === SAVINGS_TYPES.LARGE_PURCHASE);
};

export const saveForRetirementGoalDetailsAdded = (...params) => {
  const [goalsState] = params;
  const { retirementDate, frequency, contribution } = goalsState.goals.saveForRetirementMeasured.goalData.answers;
  return retirementDate && frequency && contribution;
};

export const saveForRainyDayContributionAdded = (...params) => {
  return savingsContributionAdded(
    params,
    [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EMERGENCY_FUND]],
    'saveForRainyDayMeasured'
  );
};

export const saveForLargePurchaseContributionAdded = (...params) => {
  const [goalsState] = params;
  const { savings } = goalsState;

  const match = matchPath(history.location.pathname, FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL);

  // extract the savings id from the current url
  const currentlyViewingLargePurchaseSavingsGoalWithId = match?.params?.savingsId;
  const currentlyViewingLargePurchaseSavingsDetails = savings.find(
    saving => saving.savingsId === currentlyViewingLargePurchaseSavingsGoalWithId
  );

  if (currentlyViewingLargePurchaseSavingsDetails) {
    return savingsContributionAdded(
      params,
      [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.LARGE_PURCHASE]],
      'saveForLargePurchaseMeasured',
      currentlyViewingLargePurchaseSavingsDetails.savingsId
    );
  }

  const allLargePurchaseSavings = savings.filter(saving => saving.type === SAVINGS_TYPES.LARGE_PURCHASE);
  return (
    allLargePurchaseSavings.length > 0 &&
    allLargePurchaseSavings.every(saving =>
      savingsContributionAdded(
        params,
        [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.LARGE_PURCHASE]],
        'saveForLargePurchaseMeasured',
        saving.savingsId
      )
    )
  );
};

export const saveForRetirementContributionAdded = (...params) => {
  return savingsContributionAdded(
    params,
    [NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_400X], NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.RETIREMENT_IRA]],
    'saveForRetirementMeasured'
  );
};

// ACTIONS
export const identifyUnplannedExpenseClick = (...params) => {
  const [, goalId, selectExpense] = params;
  const goalUnplannedExpense = getGoalUnplannedExpense(...params);

  if (goalUnplannedExpense) {
    selectExpense(goalUnplannedExpense);
    history.push(firstAccessibleLinkOfExpenseSelector(goalUnplannedExpense));
  } else {
    history.push(DASHBOARD_VERTICAL_SELECT_PATH, { goalId });
  }
};

export const buildYourBudgetClick = () => {
  history.push(BUDGET_OVERVIEW_PATH, { prevPath: DASHBOARD_DEBT_MANAGER_NAV_HACK });
};

export const buildRainyDayFundSavingsPlanClick = () => {
  history.push(SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH);
};

export const buildLargePurchaseSavingsPlanClick = () => {
  history.push(SAVINGS_LARGE_PURCHASE_LANDING_PAGE_PATH);
};

export const startSeparateSavingsAccountRetirementClick = () => {
  history.push(RETIREMENT_ACCOUNT_SAVINGS_ACCOUNT);
};

export const exploreReliefOptionsMeasuredClick = () => {
  history.push(STUDENT_LOAN_RELIEF_OPTIONS);
};

export const startSeparateSavingsAccountRainyDayClick = (...params) => {
  const [, , setModalVisible] = params;
  setModalVisible({
    heading: trans('i18n-goals:goals.modal-heading.name-savings'),
    content: trans('i18n-goals:goals.start-separate-account.rainy-day.modal-content'),
  });
};

export const startSeparateSavingsAccountLargePurchaseClick = (...params) => {
  const [, , setModalVisible] = params;
  setModalVisible({
    heading: trans('i18n-goals:goals.modal-heading.name-savings'),
    content: trans('i18n-goals:goals.start-separate-account.large-purchase.modal-content'),
  });
};

export const setAutomaticContributionsRainyDayClick = (...params) => {
  const [, , setModalVisible] = params;
  setModalVisible({
    heading: trans('i18n-goals:goals.modal-heading.automate-savings'),
    content: trans('i18n-goals:goals.automatic-contributions.rainy-day.modal-content'),
  });
};

export const setAutomaticContributionsRetirementClick = (...params) => {
  const [, , setModalVisible] = params;
  setModalVisible({
    heading: trans('i18n-goals:goals.modal-heading.automate-savings'),
    content: trans('i18n-goals:goals.automatic-contributions.retirement.modal-content'),
  });
};

export const setAutomaticContributionsLargePurchaseClick = (...params) => {
  const [, , setModalVisible] = params;
  setModalVisible({
    heading: trans('i18n-goals:goals.modal-heading.automate-savings'),
    content: trans('i18n-goals:goals.automatic-contributions.large-purchase.modal-content'),
  });
};

export const tipsForReducingSpendingClick = (...params) => {
  const [, , setActivityComplete] = params;
  window.open(AARP_POST_PANDEMIC_SPENDING_TIPS, '_blank');
  setActivityComplete();
};

export const trackCurrentSpendingClick = (...params) => {
  const [, , setActivityComplete] = params;
  window.open(AARP_HOW_TO_CREATE_A_HOUSEHOLD_BUDGET, '_blank');
  setActivityComplete();
};

export const reviewSavingsPlanClick = (...params) => {
  const [, , setActivityComplete] = params;
  history.push(SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH);
  setActivityComplete();
};

export const reviewLargePurchaseSavingsPlanClick = (...params) => {
  const [, , setActivityComplete] = params;
  history.push(SAVINGS_DASHBOARD);
  setActivityComplete();
};

export const reviewSavingsPlanCheck = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'reviewSavingsPlan');
};

export const reviewLargePurchaseSavingsPlanCheck = (...params) => {
  const [globalState] = params;
  return checkIfActivityTypeHasCompleteStatus(globalState, 'reviewLargePurchaseSavingsPlan');
};

export const takeActionOnYourPlanClick = (...params) => {
  identifyUnplannedExpenseClick(...params);
};

export const takeActionOnReviewPlanClick = () => {
  history.push(DEBT_PAYMENT_PLAN_PATH);
};

export const trackAllDebtsClick = (...params) => {
  const [, , setModalVisible] = params;
  // force the plan tab in DM to be active
  storage.setItem(STORAGE_KEY, JSON.stringify(TAB_NAMES.PLAN));
  setModalVisible({
    heading: trans('i18n-goals:goals.modal-heading.contact-creditors'),
    content: trans('i18n-goals:goals.modal-content.contact-creditors-line-1'),
    contentSecondary: trans('i18n-goals:goals.modal-content.contact-creditors-line-2'),
    customActions: {
      label: trans('i18n-goals:goals.modal-cta.go-to-debts-manager'),
      onClick: () => history.push(DEBT_ACCOUNTS_PATH),
      testId: 'contact-creditors-debt-manager-cta-button',
    },
  });
};

export const takeActionOnPaymentPlanClick = () => {
  history.push(DEBT_PAYMENT_PLAN_PATH);
};

export const contactCreditorsClick = (...params) => {
  const [, , setModalVisible] = params;
  setModalVisible({
    heading: trans('i18n-goals:goals.modal-heading.contact-creditors'),
    content: trans('i18n-goals:goals.modal-content.contact-creditors-line-1'),
    contentSecondary: trans('i18n-goals:goals.modal-content.contact-creditors-line-2'),
    customActions: {
      label: trans('i18n-goals:goals.modal-cta.go-to-debts-manager'),
      onClick: () => history.push(DEBT_PAYMENT_PLAN_PATH),
      testId: 'contact-creditors-debt-manager-cta-button',
    },
  });
};

export const stayOnTrackWithCreditorsClick = (...params) => {
  const [, , setModalVisible] = params;
  setModalVisible({
    heading: trans('i18n-goals:goals.modal-heading.stay-on-track-with-creditors'),
    content: trans('i18n-goals:goals.modal-content.contact-creditors-line-1'),
    contentSecondary: trans('i18n-goals:goals.modal-content.contact-creditors-line-2'),
    customActions: {
      label: trans('i18n-goals:goals.modal-cta.go-to-debts-manager'),
      onClick: () => history.push(DEBT_PAYMENT_PLAN_PATH),
      testId: 'stay-on-track-with-creditors-debt-manager-cta-button',
    },
  });
};

export const paySuggestedAmountMeasuredClick = () => {
  history.push(DEBT_ACCOUNTS_PATH);
};

export const addUnplannedExpenseGoalDetailsClick = (...params) => {
  const [, goalId] = params;
  history.push(`${ADD_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS}/${goalId}`);
};

export const addReduceMyExpensesGoalDetailsClick = (...params) => {
  const [, goalId] = params;
  history.push(`${ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS}/${goalId}`);
};

export const addCreditCardDebtGoalDetailsClick = (...params) => {
  const [, goalId] = params;
  history.push(`${ADD_CREDIT_CARD_DEBT_GOAL_DETAILS}/${goalId}`);
};

export const addStudentLoanDebtGoalDetailsClick = (...params) => {
  const [, goalId] = params;
  history.push(`${ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS}/${goalId}`);
};

export const addOtherDebtGoalDetailsClick = (...params) => {
  const [, goalId] = params;
  history.push(`${ADD_OTHER_DEBT_GOAL_DETAILS}/${goalId}`);
};

export const addSaveForLargePurchaseGoalDetailsClick = (...params) => {
  const [, goalId] = params;
  history.push(`${ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DETAILS}/${goalId}`);
};

export const addSaveForRetirementGoalDetailsClick = (...params) => {
  const [, goalId] = params;
  history.push(`${ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS}/${goalId}`);
};

export const recordSaveForLargePurchaseContributionClick = () => {
  history.push(SAVINGS_ADD_DEPOSIT_PATH);
};

export const recordSaveForRainyDayContributionClick = () => {
  history.push(SAVINGS_ADD_DEPOSIT_PATH);
};

export const recordSaveForRetirementContributionClick = (...params) => {
  const [, goalId] = params;
  history.push(`${ADD_SAVE_FOR_RETIREMENT_GOAL_DEPOSIT}/${goalId}`);
};

// FORMULAS
export const unplannedExpenseCompletionPercentage = (...params) => {
  const goalUnplannedExpense = getGoalUnplannedExpense(...params);
  if (!goalUnplannedExpense) return 0;

  const goalUnplannedExpenseStatus = getExpenseStatus(goalUnplannedExpense);
  if (goalUnplannedExpenseStatus === 'onboardingNotFinished') return 0;

  const { completedSteps, allSteps } = getMyPlanExpenseStats(goalUnplannedExpense);
  return (completedSteps / allSteps) * 100;
};

export const reduceMyExpensesCompletionPercentage = (...params) => {
  if (!reduceMyExpensesGoalDetailsAdded(...params)) return 0;
  if (reduceMyExpensesGoalDetailsAdded(...params)) {
    const [goalsState] = params;
    const totalPlannedExpensesGoal = goalsState.goals.reduceMyExpensesMeasured.goalData.answers.monthlyExpensesGoal;
    const totalPlannedExpensesInStartingMonth =
      goalsState.goals.reduceMyExpensesMeasured.goalData.answers.initialMonthValue;
    const totalTransactionsAmountInPreviousMonth =
      goalsState.lastMonthTransactions[0]?.list?.reduce((amountAcc, transaction) => {
        return amountAcc + transaction.amount;
      }, 0) || 0;
    const goalPercentageCompleted =
      ((totalPlannedExpensesInStartingMonth - totalTransactionsAmountInPreviousMonth) /
        (totalPlannedExpensesInStartingMonth - totalPlannedExpensesGoal)) *
      100;
    if (goalPercentageCompleted > 100) return 100;
    if (goalPercentageCompleted < 0) return 0;
    return goalPercentageCompleted;
  }
  return 0;
};

export const creditCardDebtGoalPercentage = (...params) => {
  if (!creditCardDebtGoalDetailsAdded(...params)) return 0;
  const [goalsState] = params;
  const { debtSize } = creditCardDebtSizeSelector(goalsState);
  const amountPaid = creditCardDebtAmountPaid(goalsState);

  return (amountPaid * 100) / debtSize || 0;
};

export const studentLoanDebtGoalPercentage = (...params) => {
  if (!studentLoanDebtGoalDetailsAdded(...params)) return 0;
  const [goalsState] = params;
  const { debtSize } = studentDebtSizeSelector(goalsState);
  const amountPaid = studentLoanDebtAmountPaid(goalsState);

  return (amountPaid * 100) / debtSize || 0;
};

export const otherDebtGoalPercentage = (...params) => {
  if (!otherDebtGoalDetailsAdded(...params)) return 0;
  const [goalsState] = params;
  const { debtSize } = otherDebtSizeSelector(goalsState);
  const amountPaid = otherDebtAmountPaid(goalsState);

  return (amountPaid * 100) / debtSize || 0;
};

export const saveForRainyDayGoalPercentage = (...params) => {
  if (!saveForRainyDayGoalUsedSavingsTool(...params)) return 0;
  const [goalsState] = params;
  const { savingsGoal, alreadySaved } = getSaveForRainyDayGoalDetails(goalsState);

  return (alreadySaved * 100) / savingsGoal || 0;
};

export const saveForLargePurchaseGoalPercentage = (goalsState, savingsId) => {
  if (!saveForLargePurchaseGoalUsedSavingsTool(goalsState, savingsId)) return 0;
  const { savingsGoal, alreadySaved } = getSaveForLargePurchaseGoalDetails(goalsState, { savingsId });

  return (alreadySaved * 100) / savingsGoal || 0;
};

export const allSaveForLargePurchaseGoalPercentages = (...params) => {
  if (!saveForLargePurchaseGoalUsedSavingsTool(...params)) return [];
  const [goalsState] = params;

  const { savings } = goalsState;

  const percentages = savings
    .filter(savingsItem => savingsItem.type === SAVINGS_TYPES.LARGE_PURCHASE)
    .map(savingsItem => saveForLargePurchaseGoalPercentage(goalsState, savingsItem.savingsId));

  return percentages;
};

export const saveForLargePurchasesGoalAveragePercentage = (...params) => {
  const percentages = allSaveForLargePurchaseGoalPercentages(...params);
  const averagePercentage = percentages.reduce((a, b) => a + b, 0) / percentages.length || 0;
  return averagePercentage;
};

export const saveForRetirementGoalPercentage = (...params) => {
  if (!saveForRetirementGoalDetailsAdded(...params)) return 0;
  const [goalsState] = params;
  const { savingsGoal, alreadySaved } = getSaveForRetirementGoalDetails(goalsState);

  return (alreadySaved * 100) / savingsGoal || 0;
};

// COMPLETION CHECKS
export const unplannedExpenseManaged = (...params) => {
  const goalUnplannedExpense = getGoalUnplannedExpense(...params);
  if (!goalUnplannedExpense) return false;

  const goalUnplannedExpenseStatus = getExpenseStatus(goalUnplannedExpense);
  if (goalUnplannedExpenseStatus === 'planCompleted') return true;

  return false;
};

export const reduceMyExpensesGoalCompleted = (...params) => {
  const goalPercentage = reduceMyExpensesCompletionPercentage(...params);

  return goalPercentage >= 100;
};

export const creditCardDebtGoalCompleted = (...params) => {
  const goalPercentage = creditCardDebtGoalPercentage(...params);

  return goalPercentage >= 100;
};

export const studentLoanDebtGoalCompleted = (...params) => {
  const goalPercentage = studentLoanDebtGoalPercentage(...params);

  return goalPercentage >= 100;
};

export const otherDebtGoalCompleted = (...params) => {
  const goalPercentage = otherDebtGoalPercentage(...params);

  return goalPercentage >= 100;
};

export const saveForRainyDayGoalCompleted = (...params) => {
  const goalPercentage = saveForRainyDayGoalPercentage(...params);

  return goalPercentage >= 100;
};

export const saveForLargePurchaseGoalCompleted = (...params) => {
  const percentages = allSaveForLargePurchaseGoalPercentages(...params);
  if (percentages.length === 0) return false;
  return percentages.every(a => a >= 100);
};

export const saveForRetirementGoalCompleted = (...params) => {
  const goalPercentage = saveForRetirementGoalPercentage(...params);

  return goalPercentage >= 100;
};
