import { string, number, array, object, boolean } from 'yup';

import activityConfiguration, { ACTIVITY_STATUSES } from './activity';

export const REQUIRED_ERROR_TEMPLATE = 'This field is required.';
const INVALID_MONTH = 'You entered an invalid month';
const INVALID_YEAR = 'You entered an invalid year';

export const MAX_NUMBER_OF_DEBTS = 20;
export const MAX_NUMBER_OF_DEBTS_MESSAGE = `You've reached the limit of ${MAX_NUMBER_OF_DEBTS} accounts.`;
export const AVAILABLE_FUNDS_MIN = 0;
export const AVAILABLE_FUNDS_MAX = 1000000;

export const MAX_NUMBER_OF_DEBTS_IN_PAYMENT_PLAN_MESSAGE = `Payment plan should not have more than ${MAX_NUMBER_OF_DEBTS} debts`;

export const PAYMENT_PLAN_STATUSES = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  NOT_VISITED: 'NOT_VISITED',
  PROJECTED: 'PROJECTED',
};

export const PAYMENT_PLAN_FLAGS = {
  // Flags for popovers which are only displayed once per plan month
  POPOVER_DISMISSED_MARK_AS_PAID: 'popover_dismissedMarkAsPaid',
  POPOVER_DISMISSED_PROGRESS: 'popover_dismissedProgress',
  POPOVER_DISMISSED_OTHER_PAYBACK_PLAN_OPTION: 'popover_dismissedOtherPaybackPlanOption',
};

const statusRegex = new RegExp(`(${Object.values(PAYMENT_PLAN_STATUSES).join('|')})`);

export const amountRule = number()
  .min(0, `Must be between $0 - $${AVAILABLE_FUNDS_MAX}`)
  .max(AVAILABLE_FUNDS_MAX, `Must be between $0 - $${AVAILABLE_FUNDS_MAX}`)
  .required(REQUIRED_ERROR_TEMPLATE);

const amountPaidSchema = object().shape({
  debtId: string().required(REQUIRED_ERROR_TEMPLATE),
  amount: amountRule,
  hasContactedCreditor: boolean(),
});

export const getTotalAmountPaid = amountsPaid => {
  if (Array.isArray(amountsPaid) && amountsPaid.length > 0) {
    return amountsPaid.reduce((sum, { amount = 0 }) => sum + amount, 0);
  }
  // fall back to default minimum
  return AVAILABLE_FUNDS_MIN;
};

export const getMinimumAllowedAvailableFunds = amountsPaid => {
  const totalAmountPaid = getTotalAmountPaid(amountsPaid);
  const minimumRequiredAvailableFunds = Number(totalAmountPaid.toFixed(2));

  return minimumRequiredAvailableFunds;
};

const paymentPlanValidationRules = {
  month: string()
    .matches(/^(0?[1-9]|1[012])$/, INVALID_MONTH)
    .required(REQUIRED_ERROR_TEMPLATE),
  year: string()
    .matches(/^\d{4}$/, INVALID_YEAR)
    .required(REQUIRED_ERROR_TEMPLATE),
  amountsPaid: array()
    .of(amountPaidSchema)
    .test('isDefined', REQUIRED_ERROR_TEMPLATE, value => value != null),
  availableFunds: number().required().min(AVAILABLE_FUNDS_MIN).max(AVAILABLE_FUNDS_MAX),
  status: string().matches(statusRegex).required(REQUIRED_ERROR_TEMPLATE),
  popover_dismissedMarkAsPaid: boolean().required(REQUIRED_ERROR_TEMPLATE),
  popover_dismissedProgress: boolean().required(REQUIRED_ERROR_TEMPLATE),
  popover_dismissedOtherPaybackPlanOption: boolean().required(REQUIRED_ERROR_TEMPLATE),
  task_getFreeCreditCounseling: boolean().required(REQUIRED_ERROR_TEMPLATE),
  task_callAllYourCreditors: boolean().required(REQUIRED_ERROR_TEMPLATE),
  isDisclaimerDisplayed: boolean().required(REQUIRED_ERROR_TEMPLATE),
  hasUpdatedAvailableFunds: boolean().nullable(),
  activities: array().of(object().shape(activityConfiguration)),
};

export function getValidationRules(fieldName) {
  return paymentPlanValidationRules[fieldName];
}

const comparator = (planA, planB) =>
  Number(planA.year) * 12 + Number(planA.month) > Number(planB.year) * 12 + Number(planB.month) ? 1 : -1;

export const sortPlansByMonth = paymentPlans => {
  return [...paymentPlans].sort(comparator);
};

export const isMaxNumberOfDebtsReached = ({ debts }) => debts.length >= MAX_NUMBER_OF_DEBTS;

export const PAYMENT_PLAN_EDIT_PROPERTIES = ['availableFunds'];
export const DEBT_EDIT_PROPERTIES = ['amount', 'hasContactedCreditor'];

export const paymentPlanActivities = [
  {
    type: 'updateOrAddNewDebtAccounts',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'exploreOptionsWithinYourBudget',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'findDebtReliefAndTipsForWorkingWithCreditors',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'prioritizeMonthlyPaymentsHomeAutoDebt',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'paySuggestedAmounts',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
  {
    type: 'recordAllDebtsAsPaid',
    status: ACTIVITY_STATUSES.ACTIVE,
  },
];

export const getPaymentPlanActivities = () => {
  return paymentPlanActivities.map(activity => {
    const { type, status } = activity;
    return {
      type,
      status,
    };
  });
};
