import qs from 'query-string';
import Cookies, { CookieAttributes } from 'js-cookie';

import { TRecord } from '@/types/common';

export const DEFAULT_AARP_SESSION_COOKIE_NAME = 'at';

/**
 * Returns the value of a cookie with the provided name or null if it does not exist
 */
export const getCookie = (name: string) => {
  const v = Cookies.get(name);
  return v ?? null;
};

/**
 * Deletes a cookie with the provided name
 * @param {string} name name of cookie to delete
 * @param {CookieAttributes|null=} options cookie options
 */
export const deleteCookie = (name: string, options?: CookieAttributes): void => {
  Cookies.remove(name, options);
};

/**
 * Returns an object with cookie values. Expects a URI encoded query string as input
 */
const parseURIEncodedSessionCookie = (cookieValue: string) => {
  // this returns a query string
  const decodedURIString = decodeURIComponent(cookieValue);

  // return an object based on key value pairs from query string
  return qs.parse(decodedURIString);
};

/**
 * Returns a boolean value indicating the validity of the AARP session cookie
 */
export const isValidAARPSessionCookie = () => {
  // TODO: This check could be more rigorous by verifying the cookie expiration
  return getCookie(DEFAULT_AARP_SESSION_COOKIE_NAME) != null;
};

/**
 * Returns a subset of the AARP session cookie data as an object with human-readable keys
 */
export const getAARPSessionCookieData = () => {
  const rawCookie = getCookie(DEFAULT_AARP_SESSION_COOKIE_NAME);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const cookieData = (rawCookie && parseURIEncodedSessionCookie(rawCookie)) as TRecord<string>;
  const firstName = process.env.NODE_ENV === 'development' ? 'Developer' : '';

  return {
    firstName: cookieData?.f || firstName,
    email: cookieData?.e,
    username: cookieData?.a,
  };
};

/**
 * Deletes the AARP session cookie.
 */
export const deleteAARPSessionCookie = (): void => {
  deleteCookie(DEFAULT_AARP_SESSION_COOKIE_NAME);
};
