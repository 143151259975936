import React, { FunctionComponent } from 'react';
import { Progress as SemanticProgress } from 'semantic-ui-react';

import type { ProgressProps } from 'semantic-ui-react';

export type ProgressT = ProgressProps & {
  'data-testid'?: string;
};

const Progress: FunctionComponent<ProgressT> = ({
  children = null,
  className = '',
  'data-testid': dataTestId,
  'aria-labelledby': ariaLabelledBy,
  percent,
  size = 'small',
  color,
}) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  <SemanticProgress
    aria-valuenow={percent}
    className={className}
    data-testid={dataTestId}
    aria-labelledby={ariaLabelledBy}
    percent={percent}
    role="progressbar"
    size={size}
    color={color}
  >
    {children}
  </SemanticProgress>
);

export default Progress;
