type ValidStorageTypes = 'local' | 'session';
type StorageCacheT<T> = { [K in keyof T]: Storage };

const isStorageAvailable = (storage: Storage) => {
  if (storage == null) {
    return false;
  }

  try {
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

const isLocalStorageAvailable = () => {
  return isStorageAvailable(window.localStorage);
};

const isSessionStorageAvailable = () => {
  return isStorageAvailable(window.sessionStorage);
};

const storageCache: StorageCacheT<ValidStorageTypes> | object = {};

export const createMemoryStorage = () => {
  let inMemoryStorage = {};

  function clear() {
    inMemoryStorage = {};
  }

  function getItem(name: string) {
    if (Object.prototype.hasOwnProperty.call(inMemoryStorage, name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function key(index: number) {
    return Object.keys(inMemoryStorage)[index] || null;
  }

  function removeItem(name: string) {
    delete inMemoryStorage[name];
  }

  function setItem(name: string, value: unknown) {
    inMemoryStorage[name] = String(value);
  }

  function length() {
    return Object.keys(inMemoryStorage).length;
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    },
  };
};

/**
 * Checks if storage is available on the global object and replaces it with an in memory storage in case it's not there.
 * In some browsers private mode disallows local storage access, this way it's not going to break the app.
 * CAVEAT: in-memory storage replacement is NOT persistent!
 * @param {string} type - storage type (local or session)
 */
export const createStorage = (type: 'local' | 'session' = 'local'): Storage => {
  if (storageCache[type]) {
    return storageCache[type];
  }

  let storage: Storage;
  if (type === 'local' && isLocalStorageAvailable()) {
    storage = window.localStorage;
  } else if (type === 'session' && isSessionStorageAvailable()) {
    storage = window.sessionStorage;
  } else {
    storage = createMemoryStorage();
  }

  storageCache[type] = storage;
  return storage;
};

export default createStorage('local');
