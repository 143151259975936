import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';

import FeatureFlag from '@/components/FeatureFlag';

type RouteProps = {
  /** The component or component instance to render. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FunctionComponent | React.ComponentClass<any> | React.LazyExoticComponent<any>;
  /** Whether the path should be exactly matched or not. */
  exact?: boolean;
  /** The environment variable that determines if a feature is on/off. */
  feature?: string;
  /** The path of the route */
  path: string;
};

/**
 * Wraps the react-router Route component in order to optionally use the FeatureFlag component.
 * @returns {Route}
 */
const Route = ({ component: Component, exact = true, feature, path }: RouteProps) => {
  const featureState = React.useMemo(
    // TODO: Find a way to resolve this
    // eslint-disable-next-line react/no-unstable-nested-components
    () => () => (
      <FeatureFlag feature={feature || ''}>
        <Component />
      </FeatureFlag>
    ),
    [Component, feature]
  );

  return (
    <ReactRoute
      key={path}
      exact={exact}
      path={path}
      component={feature === '' || feature == null ? Component : featureState}
    />
  );
};

export default Route;
