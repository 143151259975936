import React from 'react';

import ApolloProvider from '@/domain/apollo';

import ExternalLinkModalProvider from '@/services/externalLinkModal';
import GlobalErrorBoundary from '@/services/globalErrorBoundary';
import NotificationsProvider from '@/services/notifications';
import FeatureFlagsProvider from '@/services/featureFlags';
import AppContextProvider from '@/services/appContext';
import AnalyticsProvider from '@/services/analytics';
import PopoversProvider from '@/services/popovers';
import ExpensesProvider from '@/services/expenses';
import SessionProvider from '@/services/session';
import BannerProvider from '@/services/banner';
import GoalsProvider from '@/services/goals';
import AuthProvider from '@/services/authContext';
import QuickGuideProvider from '@/services/quickGuideToolContext';

import { TProvider } from '@/types/common';

/**
 * Utility Function to flatten and simplify the combination of Providers at the top of the app
 *
 * Note: Order matters! Providers are nested from top to bottom of the array (or left to right)
 */
const combineProviders = (providers: TProvider[]) =>
  providers.reduce((CombinedProviders, Provider) => ({ children }) => (
    <CombinedProviders>
      <Provider>{children}</Provider>
    </CombinedProviders>
  ));

const Providers = combineProviders([
  GlobalErrorBoundary,
  ApolloProvider,
  FeatureFlagsProvider,
  NotificationsProvider,
  PopoversProvider,
  BannerProvider,
  ExternalLinkModalProvider,
  AnalyticsProvider,
  SessionProvider,
  AppContextProvider,
  ExpensesProvider,
  GoalsProvider,
  AuthProvider,
  QuickGuideProvider,
]);

export default Providers;
