/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

import { trans } from '@/util/i18n';

import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';
import CustomContainer from '@/components/CustomContainer';
import Heading from '@/components/Heading';
import Divider from '@/components/Divider';
import Accordion from '@/components/Accordion';
import BigImageList from '@/components/BigImageList';

import calculatorImage from '@/images/calculatorIcon.svg';

import payYourselfImgSrc from './images/Icon-Pros-You-pay-yourself@2x.png';
import accountNotBankImgSrc from './images/Icon-Pros-Interest-goes-into-your-account-not-bank@2x.png';
import convenientPayingBackImgSrc from './images/Icon-Pros-Paying-back-is-convenient@2x.png';
import impactEarningsImgSrc from './images/Icon-Cons-Borrowing-will-impact-your-potential-investment-earnings@2x.png';
import needCatchUpImgSrc from './images/Icon-Cons-You-may-need-to-catch-up-to-reach-your-retirement-goals@2x.png';
import taxesAndPenaltiesImgSrc from './images/Icon-Cons-If-you-lose-your-job-youll-pay-taxes-and-penalties@2x.png';

const prosItems = [
  {
    key: 'pay-yourself',
    header: trans('i18n-unplanned:borrow-retirement-pros.item-1.header'),
    description: trans('i18n-unplanned:borrow-retirement-pros.item-1.description'),
    image: { src: payYourselfImgSrc, size: 'tiny' },
  },
  {
    key: 'account-not-bank',
    header: trans('i18n-unplanned:borrow-retirement-pros.item-2.header'),
    description: trans('i18n-unplanned:borrow-retirement-pros.item-2.description'),
    image: { src: accountNotBankImgSrc, size: 'tiny' },
  },
  {
    key: 'convenient-paying-back',
    header: trans('i18n-unplanned:borrow-retirement-pros.item-3.header'),
    description: trans('i18n-unplanned:borrow-retirement-pros.item-3.description'),
    image: { src: convenientPayingBackImgSrc, size: 'tiny' },
  },
];

const consItems = [
  {
    key: 'impact-earnings',
    header: trans('i18n-unplanned:borrow-retirement-cons.item-1.header'),
    description: trans('i18n-unplanned:borrow-retirement-cons.item-1.description'),
    image: { src: impactEarningsImgSrc, size: 'tiny' },
  },
  {
    key: 'need-catch-up',
    header: trans('i18n-unplanned:borrow-retirement-cons.item-2.header'),
    description: trans('i18n-unplanned:borrow-retirement-cons.item-2.description'),
    image: { src: needCatchUpImgSrc, size: 'tiny' },
  },
  {
    key: 'taxes-and-penalties',
    header: trans('i18n-unplanned:borrow-retirement-cons.item-3.header'),
    description: trans('i18n-unplanned:borrow-retirement-cons.item-3.description'),
    image: { src: taxesAndPenaltiesImgSrc, size: 'tiny' },
  },
];

const Pros = () => (
  <>
    <Heading as="h1">{trans('pros')}</Heading>
    <Divider hidden />
    <BigImageList testId="borrowFromRetirementAccountsPros" items={prosItems} />
  </>
);

const Cons = () => (
  <>
    <Heading as="h1">{trans('cons')}</Heading>
    <Divider hidden />
    <BigImageList testId="HomeEquityPros" items={consItems} />
  </>
);

const ProsAndConsContent = ({ fromAssessment }) => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <>
      <Grid relaxed stackable columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Pros />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Cons />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Heading as="h5">{t('other-things')}</Heading>
      <Accordion
        fluid
        styled
        panels={[
          {
            key: 'may-take-time',
            active: fromAssessment,
            title: t('time-get-money'),
            content: {
              content: <>{t('time-get-money.content')}</>,
            },
          },
          {
            key: 'future-loans',
            active: fromAssessment,
            title: t('future-loans'),
            content: {
              content: <>{t('future-loans.content')}</>,
            },
          },
        ]}
      />
    </>
  );
};

const ProsAndCons = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-unplanned');
  return (
    <CustomContainer noMargin verticallyPadded>
      <Heading as="h1">{t('borrow-retirement-pros-cons.header')}</Heading>
      <Divider tiny />
      <ProsAndConsContent fromAssessment={fromAssessment} />
      {fromAssessment ? null : (
        <QuestionsForm fullWidth backButton={false}>
          <WizardUpNext imageSrc={calculatorImage}>{t('borrow-retirement-pros-cons.wizard')}</WizardUpNext>
        </QuestionsForm>
      )}
    </CustomContainer>
  );
};

export default ProsAndCons;
