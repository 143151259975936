import gql from 'graphql-tag';
import { array, string, number, lazy, object } from 'yup';
import { GOAL_STATUSES, acceptedGoalOnboarding } from 'common/model/goal';
import activityConfiguration from 'common/model/activity';

import { setSafeGoalValues, setSafeActivityValues } from '@/domain/UserState';
import { LANDING_PAGE_PATH } from '@/domain/paths';

import { trans } from '@/util/i18n';

import { TObject } from '@/types/common';
import { TDefaultActivity, TFlowMetadata } from '@/types/domain/UserStates';

/**
 * Named fragments cannot be imported from .gql modules right now.
 * https://github.com/apollographql/graphql-tag/issues/331
 * https://github.com/apollographql/graphql-tag/pull/257
 */
export const goalFragment = gql`
  fragment GoalMutation on GoalOutput {
    goalId
    flowMetadata
    activities
    answers
    type
    dateUpdated
    dateCreated
    status
  }
`;

export const activityFragment = gql`
  fragment ActivityMutation on ActivityOutput {
    activityId
    flowMetadata
    answers
    type
    dateUpdated
    dateCreated
    status
  }
`;

type TSkeleton = { isFlowEnabled: (TObject) => boolean };

export const goalsWizardFallbackConfig = {
  rules: [
    { condition: (state: TDefaultActivity) => !state?.activityId, fallbackLocation: LANDING_PAGE_PATH },
    {
      condition: (state: TDefaultActivity, skeleton: TSkeleton) => !skeleton.isFlowEnabled(state),
      fallbackLocation: LANDING_PAGE_PATH,
    },
  ],
};

export function serialize(goal: TObject) {
  const { type, status } = setSafeGoalValues(goal);

  return { type, status };
}

export function serializeActivity(activity: TObject) {
  const { type, status } = setSafeActivityValues(activity);

  return { type, status };
}

const REQUIRED_ERROR_TEMPLATE = trans('validation.field-required') as unknown as string;

const getQuestionsRules = (schemaConstructor: typeof string | typeof number) => {
  return {
    is: exist => exist,
    then: schemaConstructor().required(REQUIRED_ERROR_TEMPLATE),
    otherwise: schemaConstructor(),
  };
};

const questionsConfiguration = {
  previousStepId: string().when('$exist', getQuestionsRules(string)),
  nextStepId: string().when('$exist', getQuestionsRules(string)),
  dateVisited: number().when('$exist', getQuestionsRules(number)),
  dateCompleted: number().when('$exist', getQuestionsRules(number)),
};

/**
 * build a dynamic validation schema based on flowMetadata input
 * @param {Object} flowMetadata flowMetadata input
 */
const getFlowMetadataValidationSchema = (flowMetadata: TFlowMetadata) => {
  return object()
    .shape(
      // Creates an object().shape() for each flow (medicalOnboardingFlow, autoOnboardingFlow)
      Object.keys(flowMetadata).reduce((flowSchema, flow) => {
        flowSchema[flow] = object().shape(
          // Creates an object().shape() for each step inside the flow (questions-first, questions-second)
          Object.keys(flowMetadata[flow]).reduce((stepSchema, step) => {
            stepSchema[step] = object().shape(questionsConfiguration);
            return stepSchema;
          }, {})
        );
        return flowSchema;
      }, {})
    )
    .noUnknown();
};

const answersConfiguration = {
  // General
  goal_userGoal: array()
    .of(
      // eslint-disable-next-line no-template-curly-in-string
      string().test('acceptedGoalOnboarding', '${value} is not valid', value => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return acceptedGoalOnboarding.includes(value!);
      })
    )
    .min(1, trans('validation.at-least-1-goal') as unknown as string)
    .required(trans('validation.at-least-1-goal') as unknown as string),
  retirement_retirementAccounts: array()
    .min(1, trans('validation.at-least-1-option') as unknown as string)
    .required(trans('validation.at-least-1-option') as unknown as string),
  studentLoan_reliefOptions: string()
    .oneOf(['FEDERAL_STUDENT_LOAN', 'PRIVATE_STUDENT_LOAN', 'BOTH', 'NONE'])
    .required(trans('validation.at-least-1-option') as unknown as string),
};

export const getGoalValidationRulesFrontend = (id: string) => {
  if (!(id in answersConfiguration)) {
    // TODO: this ends up being a bit noisy -- find a better approach?
    /* eslint-disable-next-line no-console */
    console.warn(`No validation rules for '${id}' have been configured.`);
  }
  return answersConfiguration[id];
};

const goalConfiguration = {
  goalId: string(),
  activities: array().of(object().shape(activityConfiguration)),
  answers: object().notRequired(),
  flowMetadata: lazy(getFlowMetadataValidationSchema),
  status: string().oneOf(Object.keys(GOAL_STATUSES)),
};

export function getValidationRules(fieldName: string) {
  return goalConfiguration[fieldName];
}

export default goalConfiguration;
