import { array, string, lazy, object } from 'yup';

import activityConfiguration from './activity';
import { nicknameShouldNotBeDuplicated } from './debt';
import getFlowMetadataValidationSchema from './flowMetadata';
import { nicknameDoesNotContainAccountNumber } from './savings';

const MAX_NICKNAME_DIGITS = 2;
const MAX_NICKNAME_CHARACTERS = 60;

// tabs ids
export const activitiesId = 'ACTIVITIES';
export const goalsId = 'GOALS';

export const GOAL_STATUSES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  COMPLETED: 'COMPLETED',
};

export const acceptedGoalOnboarding = [
  'manageUnplannedExpenses',
  'reduceMyExpenses',
  'payOffCreditCardDebt',
  'payOffStudentLoan',
  'payOffOtherDebt',
  'saveForRainyDay',
  'saveForLargePurchase',
  'saveForRetirement',
  'manageUnplannedExpenseMeasured',
  'reduceMyExpensesMeasured',
  'payOffCreditCardDebtMeasured',
  'payOffStudentLoanDebtMeasured',
  'payOffOtherDebtMeasured',
  'saveForRainyDayMeasured',
  'saveForLargePurchaseMeasured',
  'saveForRetirementMeasured',
];

const answersConfiguration = {
  retirement_retirementAccounts: array().min(1, '*Choose at least 1 option').required('*Choose at least 1 option'),
  studentLoan_reliefOptions: string()
    .oneOf(['FEDERAL_STUDENT_LOAN', 'PRIVATE_STUDENT_LOAN', 'BOTH', 'NONE'])
    .required('*Choose at least 1 option'),
};

export const getGoalValidationRules = id => {
  if (!(id in answersConfiguration)) {
    /* eslint-disable-next-line no-console */
    console.warn(`No validation rules for '${id}' have been configured.`);
  }
  return answersConfiguration[id];
};

const goalConfiguration = {
  goalId: string(),
  allUserNicknames: array().of(string()),
  nickname: string()
    .max(MAX_NICKNAME_CHARACTERS, `Nickname must not exceed ${MAX_NICKNAME_CHARACTERS} characters`)
    .test(
      'nicknameDoesNotContainAccountNumber',
      `Nickname must not have more than ${MAX_NICKNAME_DIGITS} numbers`,
      nicknameDoesNotContainAccountNumber
    )
    .test(
      'nicknameShouldNotBeDuplicated',
      `You've already used this nickname. Please choose a unique name`,
      nicknameShouldNotBeDuplicated
    )
    .trim(),
  activities: array().of(object().shape(activityConfiguration)),
  // eslint-disable-next-line no-template-curly-in-string
  type: string().test('acceptedGoalOnboarding', '${value} is not valid', value => {
    return acceptedGoalOnboarding.includes(value);
  }),
  answers: object().notRequired(),
  flowMetadata: lazy(getFlowMetadataValidationSchema),
  status: string().oneOf(Object.keys(GOAL_STATUSES)),
};

export function getValidationRules(fieldName) {
  return goalConfiguration[fieldName];
}

export default goalConfiguration;
