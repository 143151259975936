import createQuestionFactory from '@/domain/questions/createQuestionFactory';
import { getAnswer } from '@/domain/questions/util';
import { getAnswerValidationRules } from '@/domain/questions/validation';

import i18n from '@/libs/i18n';

import { trans } from '@/util/i18n';

import SelectField from '@/components/FormField/SelectField';
import Field from '@/components/Field';

const otherQuestionFactory = createQuestionFactory('other', getAnswerValidationRules);

const emergencyExpenses = ['Pet Care', 'Taxes', 'Travel', 'Other'];
const emergencyExpensesKeys = ['pet-care', 'taxes', 'travel', 'other'];

export const emergencyExpenseQ = otherQuestionFactory({
  name: 'emergencyExpense',
  title: trans('i18n-questions:emergency-expense.title'),
  component: SelectField,
  subtitle: trans('why-ask-emergency-expense'),
  defaultProps: {
    search: true,
    selection: true,
    formatAnswer: value => {
      const emergencyExpenseIndex = emergencyExpenses.indexOf(value as string);

      if (emergencyExpenseIndex < 0) {
        return value;
      }

      const emergencyExpenseKeyValue = emergencyExpensesKeys[emergencyExpenseIndex];

      if (!emergencyExpenseKeyValue) {
        return value;
      }

      return trans(`i18n-questions:answer.${emergencyExpenseKeyValue}`);
    },
    options: emergencyExpenses.map((expense, i) => ({
      key: expense,
      text: trans(`i18n-questions:answer.${emergencyExpensesKeys[i]}`),
      value: expense,
    })),
    width: 11,
    placeholder: () => i18n.t('i18n-questions:emergency-expense.placeholder'),
  },
});

export const otherExpenseQ = otherQuestionFactory({
  name: 'otherEmergencyExpense',
  title: trans('i18n-questions:other-expense.title'),
  component: Field,
  defaultProps: {
    placeholder: () => i18n.t('i18n-questions:other-expense.placeholder'),
    trim: true,
  },
});

export const conditionalOtherQ = {
  id: 'other conditional',
  getQuestions: formValues => {
    const expenseAnswer = getAnswer(emergencyExpenseQ, { answers: formValues });

    if (expenseAnswer === 'Other') {
      return [emergencyExpenseQ, otherExpenseQ];
    }

    return [emergencyExpenseQ];
  },
};
