import Big from 'big.js';

import { TTotalAmountOfInterestPaidParams } from '@/types/domain/calcs';

/**
 * Calculate the total amount of interest accrued on a credit card charge over the time that it was being paid back
 * (for card with and without promo period).
 */
const calcTotalAmountOfInterestPaid = ({
  chargeAmount,
  totalMonthsToPayOff,
  monthlyPayment,
}: TTotalAmountOfInterestPaidParams): Big | number => {
  /**
   * Inputs mappings
   * CHARGE AMOUNT <$-AMT>
   * TOTAL MONTHS TO PAY-OFF <MONTHS-PAYBACK>
   * MONTHLY PAYMENT <$-MONTHLY>
   */

  /**
   * This calc uses:
   * <$-TOTAL-INTEREST> = (<MONTHS-PAYBACK> * <$-MONTHLY>)  - <$-AMT>
   */

  const totalInterestCost = Big(totalMonthsToPayOff).times(monthlyPayment).minus(chargeAmount);

  // paid off before the interest kicks in
  if (totalInterestCost.lt(0)) {
    return 0;
  }

  return totalInterestCost;
};

export default calcTotalAmountOfInterestPaid;
