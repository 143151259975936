/* eslint-disable */
// @ts-nocheck
import cx from 'classnames';
import { connect, getIn } from 'formik';

import RadioField from '@/components/FormField/RadioField';
import FieldErrorMessage from '@/components/FormField/FieldErrorMessage';
import classes from '@/components/FormField/RadioField/styles.module.less';
import VerticalSpacing from '@/components/VerticalSpacing';

export const RADIO_GROUP_TYPE = {
  DEFAULT: 'default',
  BIG_LETTER: 'bigLetter',
  SMALL: 'smallFont',
};

/**
 * Generates a group of radio buttons based on the given config.
 * Renders a single error message instead of having one for every field.
 * Can be rendered as a styled group with letters as items.
 * Hooks into formik context to read the errors.
 */
const RadioGroup = connect<typeof RadioField>(
  ({ fields, type = RADIO_GROUP_TYPE.DEFAULT, formik, name, errorMessageClassName = '' }) => {
    // Possibly move away from HOC pattern to use hooks instead? https://formik.org/docs/api/useFormikContext
    const error = getIn(formik.errors, name);

    return (
      <>
        <VerticalSpacing size={4} />
        {fields.map((field, index) => {
          const dataTestid = field.testId || `${name}${field.valueName}`;

          return (
            <RadioField
              key={field.valueName}
              name={name}
              label={field.label}
              aria-labelledby={field.ariaLabelledBy}
              valueName={field.valueName}
              data-testid={dataTestid}
              subText={field.subText}
              className={cx({
                [classes.radioField]: type === RADIO_GROUP_TYPE.BIG_LETTER,
                [classes[`radioField-${index + 1}`]]: type === RADIO_GROUP_TYPE.BIG_LETTER,
                [classes.small]: type === RADIO_GROUP_TYPE.SMALL,
              })}
            />
          );
        })}
        <FieldErrorMessage className={errorMessageClassName} error={error} name={name} />
        <VerticalSpacing size={4} />
      </>
    );
  }
);

export default RadioGroup;
