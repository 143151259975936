import { ACCOUNT_TYPES, STANDARD_ACCOUNT_TYPES, ESSENTIAL_ACCOUNT_TYPES } from 'common/model/debt';

import {
  getMinPaymentForAccount,
  filterAccountsByType,
  filterAccountsByPrioritized,
  compareMinPayment,
  compareDebtToInterestRatio,
  compareStatementBalance,
  compareDateCreated,
  TAccount,
  TEssentialAccountTypes,
  TStandardAccountTypes,
  TFederalStudentLoanType,
  TMedicalBill,
} from '@/domain/debts';
import {
  getRemainingMinPaymentForAccount,
  compareEqualAndPaid,
  TAllocatedPayments,
  TAmountsPaid,
} from '@/domain/debtsPlan';

import { sortBy, hasDuplicateObjectProperties } from '@/util/array';

import { TObject } from '@/types/common';

const DEBUG_LOG_PHASES = false;
const DEBUG_LOG_ALLOCATIONS = false;

const SORTING_STRATEGIES = {
  MIN_PAYMENT_ALLOCATION: [
    compareMinPayment,
    compareDebtToInterestRatio,
    compareStatementBalance,
    compareEqualAndPaid,
    compareDateCreated,
  ],
  REMAINDER_ALLOCATION: [compareDebtToInterestRatio, compareStatementBalance, compareEqualAndPaid, compareDateCreated],
};

// Fund Calculation Utility Functions
const sumSuggestedPayments = (allocatedPayments: TAllocatedPayments[]) => {
  return allocatedPayments.reduce((sum, { suggestedAmount = 0, amount = 0 }) => {
    return sum + amount + suggestedAmount;
  }, 0);
};
const sumSuggestedPaymentsForAccount = ({ amount = 0, suggestedAmount = 0 }) => (amount ?? 0) + (suggestedAmount ?? 0);

const getUnallocatedFunds = (availableFunds: number, allocatedPayments: TAllocatedPayments[]) => {
  const unallocatedFunds = availableFunds - sumSuggestedPayments(allocatedPayments);
  // pevent return of a negative number for unallocatedFunds
  return Math.max(unallocatedFunds, 0);
};

// Account Filtering / Sorting Utility Functions
const filterAccountsByRemainingMinPayment = (
  accounts: TAccount[],
  maximumPossiblePayment: number,
  allocatedPayments: TAllocatedPayments[]
) =>
  accounts.filter(account => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const accountPayments = allocatedPayments.find(allocatedPayment => allocatedPayment?.debtId === account?.debtId)!;
    const paymentSum = sumSuggestedPaymentsForAccount(accountPayments);
    const remainingMinPayment = getRemainingMinPaymentForAccount(account, paymentSum);
    return remainingMinPayment <= maximumPossiblePayment;
  });

const filterAccountsByPaymentAllocation = (
  accounts: TAccount[],
  allocatedPayments: TAllocatedPayments[],
  targetAllocationStatus = false
) => {
  const debtIdsWithAllocations = allocatedPayments.reduce((debtIds, allocatedPayment) => {
    const account = accounts.find(({ debtId }) => debtId === allocatedPayment.debtId);
    if (account) {
      const existingPaymentAmount = sumSuggestedPaymentsForAccount(allocatedPayment);
      const minPaymentAllocated = existingPaymentAmount >= account.minMonthlyPayment;

      if (minPaymentAllocated) {
        return [...debtIds, allocatedPayment.debtId];
      }
    }

    return debtIds;
  }, [] as string[]);
  const result = accounts.filter(({ debtId }) => debtIdsWithAllocations.includes(debtId) === targetAllocationStatus);
  return result;
};

// Allocation Logging Utility Functions
const logAllocationProgres = (availableFunds: number, allocatedPayments: TAllocatedPayments[], message: string) => {
  if (DEBUG_LOG_PHASES) {
    // eslint-disable-next-line no-console
    console.log(`${message} - $${getUnallocatedFunds(availableFunds, allocatedPayments)} remain unallocated`);
  }
};
const logSuggestedPaymentAllocation = (message: string, { nickname, debtId }: TAccount) => {
  if (DEBUG_LOG_ALLOCATIONS) {
    // eslint-disable-next-line no-console
    console.log(` * ${message} --> ${nickname} [id: ${debtId}]`);
  }
};

// Pre-flight Checks
export class InvalidAccountsError extends Error {}
const validateAccounts = (accounts: TObject[]) => {
  if (hasDuplicateObjectProperties(accounts, 'debtId')) {
    throw new InvalidAccountsError('Duplicate debtId in accounts array');
  }
};
// Allocation Logic Functions
const getMaxAllowedAllocationIncrease = (
  { statementBalance = 0 },
  unallocatedFunds: number,
  { amount, suggestedAmount }: TAllocatedPayments
) => {
  const remainingStatementBalance = statementBalance - amount - suggestedAmount;

  if (remainingStatementBalance <= 0) {
    return 0;
  }

  if (unallocatedFunds >= remainingStatementBalance) {
    return remainingStatementBalance;
  }

  return unallocatedFunds;
};
const increasePaymentAllocation = (
  targetAccount: TAccount,
  allocationAmount: number,
  allocatedPayments: TAllocatedPayments[]
) => {
  const existingAllocationIndex = allocatedPayments.findIndex(({ debtId }) => targetAccount.debtId === debtId);
  const existingAllocation = allocatedPayments[existingAllocationIndex];
  const { suggestedAmount } = allocatedPayments[existingAllocationIndex];

  // Here we constrain the maximum additional allocation which can be made
  const additionalAmount = getMaxAllowedAllocationIncrease(targetAccount, allocationAmount, existingAllocation);

  const newSuggestedAmount = suggestedAmount + additionalAmount;
  logSuggestedPaymentAllocation(`Allocating $${additionalAmount} (total $${newSuggestedAmount})`, targetAccount);
  allocatedPayments[existingAllocationIndex].suggestedAmount = newSuggestedAmount;

  return allocatedPayments;
};

const allocateNextMinimumPayment = (
  availableFunds: number,
  accounts: TAccount[],
  allocatedPayments: TAllocatedPayments[]
): [TAccount[], TAllocatedPayments[]] => {
  // Remove accounts where minMonthlyPayment > sumSuggestedPayments
  const unallocatedFunds = getUnallocatedFunds(availableFunds, allocatedPayments);
  const candidateAccounts = filterAccountsByRemainingMinPayment(accounts, unallocatedFunds, allocatedPayments);

  if (candidateAccounts.length > 0) {
    // The first account in the sorted array is shifted out of the array and used as the targetAccount
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const targetAccount = sortBy(candidateAccounts, SORTING_STRATEGIES.MIN_PAYMENT_ALLOCATION).shift()!;

    // Handle any payment that has already been made
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { amount: existingPaymentAmount } = allocatedPayments.find(({ debtId }) => targetAccount.debtId === debtId)!;
    const minPayment = getMinPaymentForAccount(targetAccount);
    const remainingMinPayment = minPayment - existingPaymentAmount;
    const amountToAllocate = Math.max(remainingMinPayment, 0);

    allocatedPayments = increasePaymentAllocation(targetAccount, amountToAllocate, allocatedPayments);
  }

  // Return new account list and new allocated payments list
  return [candidateAccounts, allocatedPayments];
};

const allocatePossibleMinimumPayments = (
  availableFunds: number,
  accounts: TAccount[],
  allocatedPayments: TAllocatedPayments[]
) => {
  if (accounts.length > 0) {
    // Get accounts that have no allocations
    const unalocatedAccounts = filterAccountsByPaymentAllocation(accounts, allocatedPayments);

    // Allocate the minimum payment to a new account
    const [remainingAccounts, newAllocatedPayments] = allocateNextMinimumPayment(
      availableFunds,
      unalocatedAccounts,
      allocatedPayments
    );

    // Handle allocation of min payments for any remaining accounts recursively
    return allocatePossibleMinimumPayments(availableFunds, remainingAccounts, newAllocatedPayments);
  }
  return allocatedPayments;
};
const allocateNextRemainingFunds = (
  availableFunds: number,
  accounts: TAccount[],
  allocatedPayments: TAllocatedPayments[]
) => {
  const unallocatedFunds = getUnallocatedFunds(availableFunds, allocatedPayments);
  if (accounts.length > 0 && unallocatedFunds > 0) {
    // The first account in the sorted array is shifted out of the array and used as the targetAccount
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const targetAccount = sortBy(accounts, SORTING_STRATEGIES.REMAINDER_ALLOCATION).shift()!;

    // Allocate
    const newAllocatedPayments = increasePaymentAllocation(targetAccount, unallocatedFunds, allocatedPayments);

    // Handle allocation of remainder for any remaining accounts recursively
    return allocateNextRemainingFunds(availableFunds, accounts, newAllocatedPayments);
  }
  return allocatedPayments;
};

/**
 * Runs an allocation of avilable funds for the provided accounts (debts)
 * @param {number} availableFunds sets the funds avilable to be allocated
 * @param {array} accounts an array of debts to which we can allocate payments
 */
export const allocatePayments = (availableFunds: number, accounts: TAccount[], amountsPaid: TAmountsPaid = []) => {
  let allocatedPayments = accounts.map(({ debtId }) => {
    const { amount = 0 } = (amountsPaid.length && amountsPaid.find(debt => debt.debtId === debtId)) || { amount: 0 };

    return {
      debtId,
      amount,
      suggestedAmount: 0,
    };
  });

  // Preflight Checks
  validateAccounts(accounts);

  // Handle essential accounts
  const essentialAccounts = filterAccountsByType(accounts, ESSENTIAL_ACCOUNT_TYPES as TEssentialAccountTypes);
  logAllocationProgres(availableFunds, allocatedPayments, '[START] Essential Account Allocation');
  allocatedPayments = allocatePossibleMinimumPayments(availableFunds, essentialAccounts, allocatedPayments);
  logAllocationProgres(availableFunds, allocatedPayments, '[END] Essential Account Allocation');

  // Handle prioritized accounts
  const prioritizedAccounts = filterAccountsByPrioritized(accounts);
  logAllocationProgres(availableFunds, allocatedPayments, '[START] Prioritized Account Allocation');
  allocatedPayments = allocatePossibleMinimumPayments(availableFunds, prioritizedAccounts, allocatedPayments);
  logAllocationProgres(availableFunds, allocatedPayments, '[END] Prioritized Account Allocation');

  const standardAccounts = filterAccountsByType(accounts, STANDARD_ACCOUNT_TYPES as TStandardAccountTypes);
  logAllocationProgres(availableFunds, allocatedPayments, '[START] Standard Account Allocation');
  allocatedPayments = allocatePossibleMinimumPayments(availableFunds, standardAccounts, allocatedPayments);
  logAllocationProgres(availableFunds, allocatedPayments, '[END] Standard Account Allocation');

  // Handle allocation for federal student loans
  const federalStudentLoanAccounts = filterAccountsByType(
    accounts,
    ACCOUNT_TYPES.FEDERAL_STUDENT_LOAN as TFederalStudentLoanType
  );
  logAllocationProgres(availableFunds, allocatedPayments, '[START] Federal Student Loan Account Allocation');
  allocatedPayments = allocatePossibleMinimumPayments(availableFunds, federalStudentLoanAccounts, allocatedPayments);
  logAllocationProgres(availableFunds, allocatedPayments, '[END] Federal Student Loan Account Allocation');

  // Handle allocation for medical debts
  const medicalBillAccounts = filterAccountsByType(accounts, ACCOUNT_TYPES.MEDICAL_BILL as TMedicalBill);
  logAllocationProgres(availableFunds, allocatedPayments, '[START] Medical Bill Account Allocation');
  allocatedPayments = allocatePossibleMinimumPayments(availableFunds, medicalBillAccounts, allocatedPayments);
  logAllocationProgres(availableFunds, allocatedPayments, '[END] Medical Bill Account Allocation');

  // Handle allocation of any remaining unallocated funds to accounts which already have payment allocations
  const accountsWithAllocatedPayments = filterAccountsByPaymentAllocation(accounts, allocatedPayments, true);
  logAllocationProgres(availableFunds, allocatedPayments, '[START] Allocate Remaining Funds');
  allocatedPayments = allocateNextRemainingFunds(availableFunds, accountsWithAllocatedPayments, allocatedPayments);
  logAllocationProgres(availableFunds, allocatedPayments, '[END] Allocate Remaining Funds');

  // Return only debtId and suggestedAmount for each debt
  return allocatedPayments.map(({ debtId, suggestedAmount }) => ({ debtId, suggestedAmount }));
};

export const runParallelAllocations = (availableFunds: number, accounts: TAccount[], amountsPaid: TAmountsPaid) => {
  // Is there another way to be able to have the amounts to easily compare the accounts in sorting?
  const accountsWithAmounts = accounts.map(account => {
    const { amount = 0 } = (amountsPaid.length &&
      amountsPaid.find(amountPaid => amountPaid.debtId === account.debtId)) || { amount: 0 };
    return { ...account, amount };
  });
  const currentSuggestedAllocations = allocatePayments(availableFunds, accountsWithAmounts, amountsPaid);
  const optimalSuggestedAmounts = allocatePayments(availableFunds, accountsWithAmounts).map(
    ({ debtId, suggestedAmount }) => ({
      debtId,
      optimalSuggestedAmount: suggestedAmount,
    })
  );

  const mergedSuggestedAllocations = currentSuggestedAllocations.map(suggestedAllocation => {
    const { optimalSuggestedAmount } = optimalSuggestedAmounts.find(
      ({ debtId }) => suggestedAllocation.debtId === debtId
    ) || { optimalSuggestedAmount: 0 };
    return { ...suggestedAllocation, optimalSuggestedAmount };
  });

  return mergedSuggestedAllocations;
};
