import { ChangeEvent } from 'react';

/**
 * Returns an empty string if the provided value is a number with value 0.
 * Useful for setting initial values on input fields.
 */
export const zeroToEmptyString = (value: unknown) => (value === 0 ? '' : value);

/**
 * Returns the percentage value of the given number. Round to 2 decimals at most, will omit decimals in case of integer.
 * Examples:
 * 0.05 => 5%
 * 0.001 => 0% (always rounded)
 * 0.005 => 1% (always rounded)
 */
export const numberToPercentage = (number: number, minimumFractionDigits = 0, maximumFractionDigits = 2) =>
  number.toLocaleString('en', { style: 'percent', minimumFractionDigits, maximumFractionDigits });

const NUMBER_RE = /^\d*\.?\d*$/;

/**
 * Tests if values are numbers by testing against a RegEx to check if numbers. You can pass either a real number or
 * a string.
 *
 * **NOTE** This does not check if values are truthy so zero will return true here.
 */
export const areNumbers = (...tests: (number | string)[]) =>
  tests.every(num => String(num).length > 0 && String(num).match(NUMBER_RE));

/**
 * Clamps a number to be within the provided min/max
 *
 * **NOTE** This does not check if values are truthy so zero will return true here.
 */
export const clampNumber = (value: string | number, min: number, max: number) =>
  Math.min(Number(max), Math.max(Number(min), Number(value)));

/**
 * Formats a given input to ensure it does not hit the decimal limit
 * @param {Event} e
 */
export const formatDecimalInput = (e: ChangeEvent<HTMLInputElement>) => {
  const limit = 3;
  const dec = e.target.value.indexOf('.');
  const tooLong = e.target.value.length > dec + limit;
  const invalidNum = Number.isNaN(parseFloat(e.target.value));

  if ((dec >= 0 && tooLong) || invalidNum) {
    e.target.value = e.target.value.slice(0, dec + limit);
  }
};
