/* eslint-disable */
// @ts-nocheck
import {
  ApolloClient,
  ApolloProvider as DefaultApolloProvider,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import useErrorHandler from '@/hooks/useErrorHandler';

import { Auth, hasAccessTokenExpired, persistSessionToken, retrieveSessionToken } from '@/util/authentication';
import { envOrDefault } from '@/util/env';

// https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
import possibleTypes from '@/graphql/fragmentTypes.json';

const uri = envOrDefault('REACT_APP_GRAPHQL_ENDPOINT', '/api/graphql');
const SKIP_AUTHENTICATION = envOrDefault<boolean>('REACT_APP_UNSAFE_NO_AUTH', false);

const httpLink = new HttpLink({ uri });
const cache = new InMemoryCache({
  possibleTypes,
  addTypename: true,
  typePolicies: {
    DebtOutput: { keyFields: ['debtId'] },
    ExpenseOutput: { keyFields: ['expenseId'] },
    GoalOutput: { keyFields: ['goalId'] },
    ActivityOutput: { keyFields: ['activityId'] },
    UserStateOutput: { keyFields: ['cacheId'] },
    PaymentPlanOutput: { keyFields: ['userId', 'year', 'month', 'amountsPaid'] },
  },
});

const setAuthLink = () =>
  setContext(async (_, { headers }) => {
    if (SKIP_AUTHENTICATION) return { headers: { ...headers } };

    // Try to get the token from the local storage
    const sessionToken = retrieveSessionToken();

    let authToken = null;

    if (sessionToken != null) {
      authToken = sessionToken;
    } else {
      try {
        // if the token is not present in the local storage yet we try from amplify Auth
        const session = await Auth.currentSession();
        authToken = session.getAccessToken().getJwtToken();

        if (authToken == null) {
          throw new Error('No token available');
        }

        persistSessionToken(authToken);
      } catch (e) {
        console.error('Unable to get Token', e);
        return {
          headers: {
            ...headers,
          },
        };
      }
    }

    if (hasAccessTokenExpired(authToken)) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();

        await new Promise((resolve, reject) => {
          cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
            if (err) reject(err);
            else resolve(session);
          });
        });

        const newSession = await Auth.currentSession();
        authToken = newSession.getAccessToken().getJwtToken();
        persistSessionToken(authToken);
      } catch (e) {
        console.error('Unable to refresh Token', e);
        return {
          headers: {
            ...headers,
          },
        };
      }
    }

    return {
      headers: {
        ...headers,
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
    };
  });

const setErrorLink = handleError =>
  onError(({ graphQLErrors, networkError }) => {
    let authError = null;
    if (graphQLErrors) {
      // check for Auth error and logout
      authError = graphQLErrors.find(error => error.extensions?.code === 'UNAUTHENTICATED');

      graphQLErrors.map(({ message, locations, path }) =>
        /* eslint-disable-next-line no-console */
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
      handleError(graphQLErrors);
    }

    if (networkError) {
      /* eslint-disable-next-line no-console */
      console.log(`[Network error]: ${networkError}`);
      handleError(networkError);
    }

    if (authError) {
      Auth.signOut({ global: true }).then(() => {
        deleteAARPSessionCookie();
        // delete the user session
        storage.clear();
        localStorage.clear();
        sessionStorage.clear();
      });
    }
  });

// Client configuration
// The order of the `link` attribute is important since this basically
// allow apollo to send the request through the pipe, the last item
// of the link array should be the one that hit the server
// in this case `httpLink`.
// The links are considered as `terminating` link when perform the request/hit the network
// more info https://www.apollographql.com/docs/link/overview.html
const setClient = errorLink =>
  new ApolloClient({
    link: ApolloLink.from([setAuthLink(), errorLink, httpLink]),
    cache,
  });

let client: NormalizedCacheObject | null = null;

export const getApolloClient = () => client;

export const getApolloCache = () => cache;

const ApolloProvider = ({ children }) => {
  const { errorHandler } = useErrorHandler();

  if (client === null) {
    client = setClient(setErrorLink(errorHandler));
  }

  return <DefaultApolloProvider client={client}>{children}</DefaultApolloProvider>;
};

export default ApolloProvider;
