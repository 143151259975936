import React, { ReactNode, FunctionComponent, FormEvent } from 'react';
import { Card, Checkbox, CheckboxProps } from 'semantic-ui-react';
import cx from 'classnames';

import WithMicroCopy from '@/components/WithMicroCopy';
import Image from '@/components/Image';

import checkedImageSrc from '@/images/Checked.svg';

import Paragraph from '../Paragraph';

import alternateCheckedImageSrc from './check.svg';

import classes from './styles.module.less';

export type TCardCheckboxProps = {
  name: string;
  value: string;
  image: string;
  description: string;
  header: ReactNode;
  checked: boolean;
  onChange: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  'data-testid': string;
  alternate?: boolean;
};

const CardCheckbox: FunctionComponent<TCardCheckboxProps> = ({
  name,
  value,
  image,
  description,
  header,
  checked,
  onChange,
  'data-testid': dataTestid,
  alternate = false,
}) => {
  return (
    <Card className={cx(classes.cardCheckbox, { [classes.alternate]: alternate })}>
      <Checkbox
        data-testid={dataTestid}
        label={
          <label className={classes.label} htmlFor={value}>
            <div className={classes.imageWrapper}>
              {alternate ? (
                <>
                  <Image src={image} ui={false} />
                  {checked && <Image src={alternateCheckedImageSrc} ui={false} className={classes.check} />}
                </>
              ) : (
                <Image src={checked ? checkedImageSrc : image} ui={false} />
              )}
            </div>
            <Card.Content textAlign={alternate ? undefined : 'center'}>
              {alternate ? (
                <>
                  <Paragraph as="h4" bold>
                    {header}
                  </Paragraph>
                  <Paragraph>{description}</Paragraph>
                </>
              ) : (
                <WithMicroCopy microcopy={description}>
                  <h4>{header}</h4>
                </WithMicroCopy>
              )}
            </Card.Content>
          </label>
        }
        role="checkbox"
        name={name}
        value={value}
        id={value}
        checked={checked}
        onChange={onChange}
        className={classes.checkbox}
      />
    </Card>
  );
};

export default CardCheckbox;
