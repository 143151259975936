/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import Button from '@/components/button';
import OffsetImageCard from '@/components/OffsetImageCard';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';

import yourPersonalAssessment from './yourPersonalAssessment.svg';

const FallbackCard = ({ content, url }) => {
  const { t } = useTranslation();
  return (
    <OffsetImageCard
      image={yourPersonalAssessment}
      content={
        <>
          <Heading as="h5">{t('your-personal-assessment')}</Heading>
          <Paragraph textAlign="center">{content}</Paragraph>
          <Button primary url={url} data-testid="getMyAssessment">
            {t('i18n-actions:button.get-my-assessment')}
          </Button>
        </>
      }
    />
  );
};

export default FallbackCard;
