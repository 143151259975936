import React from 'react';
import isEqual from 'lodash.isequal';

import { FeatureFlagsContext } from '@/services/featureFlags';

import { noop } from '@/util/function';

import { TObject, TProvider } from '@/types/common';

import type { Instance as PopperInstance } from '@popperjs/core';

type TPopoverValue = {
  getTarget: (id: string) => React.Ref<HTMLElement> | void;
  registerTarget: (id: string) => ((target: React.Ref<HTMLElement>) => void) | void;
  addPopover: (data: { popover: PopperInstance; id: string }) => void;
  updatePopovers: () => void;
  removePopover: (id: string) => void;
};

const initialPopoversProviderValue: TPopoverValue = {
  getTarget: noop,
  registerTarget: noop,
  addPopover: noop,
  updatePopovers: noop,
  removePopover: noop,
};

export const PopoversContext = React.createContext(initialPopoversProviderValue);

const PopoversProvider: TProvider = ({ children }) => {
  const { isFeatureEnabled } = React.useContext(FeatureFlagsContext);
  const popoversEnabled = isFeatureEnabled('ABACUS_FLAG_POPOVERS');
  const [refs, setRefs] = React.useState<TObject<React.Ref<HTMLElement>>>({});
  const [popovers, setPopovers] = React.useState<TObject<PopperInstance>>({});

  /**
   * Creates an element ref and adds it to the provider
   * @param {string} id the document id of the html element
   */
  const registerTarget: TPopoverValue['registerTarget'] = (id: string) => {
    return target => {
      setRefs(previousRefs => {
        const previousTarget = previousRefs[id];
        if (target === null) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [id]: omit, ...remainingTargets } = previousRefs;
          return remainingTargets;
        }
        if (!isEqual(previousTarget, target)) {
          return { ...previousRefs, [id]: target };
        }
        return previousRefs;
      });
    };
  };

  /**
   * Removes a popover instance from the provider
   * @param {string} id the document id of the html element
   */
  const removePopover = React.useCallback<TPopoverValue['removePopover']>(id => {
    setPopovers(previousPopovers => {
      delete previousPopovers[id];
      return previousPopovers;
    });
  }, []);

  /**
   * Adds a popover instance to the provider
   * @param {object} props
   * @param {object} props.popover - The popover created instance
   * @param {string} props.id the document id of the html element
   */
  const addPopover = React.useCallback<TPopoverValue['addPopover']>(({ popover, id }) => {
    setPopovers(previousPopovers => {
      if (!previousPopovers[id]) {
        return { ...previousPopovers, [id]: popover };
      }
      return previousPopovers;
    });
  }, []);

  // Replace noops with real functions if feature flag is enabled
  const providerValue = React.useMemo(() => {
    /**
     * Returns the ref of the html based on its id
     * @param {string} id the document id of the html element
     */
    const getTarget: TPopoverValue['getTarget'] = id => {
      const target = refs[id];
      if (target != null) {
        return target;
      }
      return null;
    };

    /**
     * Updates all popover instances in the provider.
     */
    const updatePopovers = () => {
      // We need to use object keys to pass flow.
      const popoversKeys = Object.keys(popovers).map(key => popovers[key]);
      popoversKeys.forEach(popover => {
        popover.update().catch(noop);
      });
    };

    return {
      ...initialPopoversProviderValue,
      // Only set getTarget and registerTarget if popovers are enabled
      ...(popoversEnabled ? { getTarget, registerTarget, addPopover, updatePopovers, removePopover } : {}),
    };
  }, [popoversEnabled, addPopover, removePopover, refs, popovers]);

  return <PopoversContext.Provider value={providerValue}>{children}</PopoversContext.Provider>;
};

export const usePopovers = () => {
  const { getTarget, registerTarget } = React.useContext(PopoversContext);
  return { getTarget, registerTarget };
};

export default PopoversProvider;
