/* eslint-disable */
// @ts-nocheck
import { object } from 'yup';

import { isQuestionCompound, isQuestionConditionallyRendered, trimQ } from '@/domain/questions/util';

import { getSafeInitialValue } from '@/util/input';

/**
 * Reads the configuration file to get a list of form field names used in this component.
 * Searches for defined validation rules and creates a schema based on those.
 */
export const createValidationSchema = (questions, formValues, externalState) => {
  if (!questions) return null;

  return object().shape(
    questions.reduce((rules, question) => {
      // question consists of multiple child questions rendered at the same time

      if (isQuestionCompound(question)) {
        const childRules = {};
        question.questions.forEach(({ id, validationRules }) => {
          childRules[id] = validationRules;
        });

        return {
          ...rules,
          ...childRules,
        };
      }

      // question has conditionally rendered child questions
      if (isQuestionConditionallyRendered(question)) {
        const conditionallyAppliedRules = {};
        const conditionallyRenderedQuestions = question.getQuestions(formValues, externalState);
        conditionallyRenderedQuestions.forEach(({ id, validationRules }) => {
          conditionallyAppliedRules[id] = validationRules;
        });

        return {
          ...rules,
          ...conditionallyAppliedRules,
        };
      }

      // default use case
      const { id, validationRules } = question;
      if (validationRules != null) {
        return {
          ...rules,
          [id]: validationRules,
        };
      }

      return rules;
    }, {})
  );
};

/**
 * Removes values coming from dynamically rendered questions that were NOT VISIBLE at the time of submit.
 * Strips values of compound question parents.
 * @param {[Object]} questions - question configs for this form
 * @param {Object} values - submit payload
 */
export const stripHiddenValues = (questions, values, externalState) => {
  if (!questions || Object.keys(values) === 0) {
    return values;
  }

  // get only the visible inputs of dynamically rendered questions and atomic children
  const visibleQuestions = questions.reduce((aggregation, question) => {
    if (isQuestionConditionallyRendered(question) && typeof question.getQuestions === 'function') {
      aggregation = [...aggregation, ...question.getQuestions(values, externalState)];
    } else if (isQuestionCompound(question) && question.questions) {
      aggregation = [...aggregation, ...question.questions];
    } else {
      aggregation.push(question);
    }

    return aggregation;
  }, []);
  const payload = visibleQuestions.reduce((aggregation, question) => {
    aggregation[question.id] = values[question.id];
    return aggregation;
  }, {});

  return payload;
};
/** Util Function to flatten the array of questions depending on which type it is
 * @param {[Object]} questions - question configs for this form
 * @param {Object} values - submit payload
 */
const getQuestionsArray = (values, questions) => {
  return questions.reduce((qConfig, question) => {
    if (isQuestionConditionallyRendered(question) && typeof question.getQuestions === 'function') {
      qConfig = [...qConfig, ...question.getQuestions(values)];
    } else if (isQuestionCompound(question) && question.questions) {
      qConfig = [...qConfig, ...question.questions];
    } else {
      qConfig = [...qConfig, question];
    }
    return qConfig;
  }, []);
};

/** On submitting each step in case of UE and the whole form in case of DM this function will loop through
 * all the currently added questions and trim the needed values based on the value of the trim prop in the questionConfig
 * @param {[Object]} questions - question configs for this form
 * @param {Object} values - submit payload
 */
export const trimFormValues = (values, questions) => {
  if (!questions) {
    // some forms do not have questions associated with them
    return values;
  }

  const allQuestions = getQuestionsArray(values, questions);
  if (!allQuestions || Object.keys(values) === 0) {
    return values;
  }
  allQuestions.forEach(q => {
    if (q?.props?.trim) {
      values = trimQ(q, values);
    }
  });
  return values;
};
/**
 * Function factory, returns a submit handler which will manage the submit status of the form and
 * call the provided persistence handler with the
 * @param {function} handlePersistence
 */

export const createDefaultSubmitHandler =
  (handlePersistence, questions, externalState, shouldRun = true) =>
  (values, actions) => {
    const trimmedValues = trimFormValues(values, questions);
    const payload = stripHiddenValues(questions, trimmedValues, externalState);

    return handlePersistence(payload)
      .catch(e => {
        /* eslint-disable-next-line no-console */
        console.error(e);
      })
      .finally(() => {
        // keeps the internal formik state correct
        if (shouldRun) actions.setSubmitting(false);
      });
  };

/**
 * Factory function that creates a function that returns the initial values for the current form.
 * @param {*} getFieldValue
 */
export const createGetInitialFormValues =
  getFieldValue => (questions, storedState, currentFormValues, externalState) => {
    if (!questions || questions.length === 0) {
      return {};
    }
    const currentFormValuesIds = Object.keys(currentFormValues);

    // first time this function is called current form values object is {}, so we pick values from user state
    // on subsequent renders, we pick the initial values from the current form state (user state is not updated)
    const sourceOfTruth = currentFormValuesIds.length ? currentFormValues : storedState;

    const values = questions.reduce((initialValues, question) => {
      if (isQuestionConditionallyRendered(question)) {
        question.getQuestions(sourceOfTruth, externalState).forEach(q => {
          // when rendering a dynamic question for the first time, look for answers in user state
          const answerSource = Object.prototype.hasOwnProperty.call(currentFormValues, q.id)
            ? currentFormValues
            : storedState;
          initialValues[q.id] = getSafeInitialValue(getFieldValue(q, answerSource));
        });
        return initialValues;
      }

      if (isQuestionCompound(question)) {
        question.questions.forEach(q => {
          initialValues[q.id] = getSafeInitialValue(getFieldValue(q, sourceOfTruth));
        });
        return initialValues;
      }

      initialValues[question.id] = getSafeInitialValue(getFieldValue(question, sourceOfTruth));
      return initialValues;
    }, {});

    // don't lose the values from hidden fields
    currentFormValuesIds.forEach(valueId => {
      if (Object.prototype.hasOwnProperty.call(values, valueId)) {
        return;
      }

      values[valueId] = currentFormValues[valueId];
    });

    return values;
  };
