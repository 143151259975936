/* eslint-disable */
// @ts-nocheck
import { useState, useEffect } from 'react';

/**
 * This custom hook just enable to any component to start and stop a timeout to run some function within.
 * The caller will pass the desired callback and a delay in ms to call that callback
 * The hook will return two methods that allow the user to start or clear the timeout
 */

function useTimeout(cb, timeoutDelayMs = 0) {
  const [isTimeoutRunning, setIsTimeoutRunning] = useState(false);

  function clear() {
    if (isTimeoutRunning) {
      setIsTimeoutRunning(false);
    }
  }
  function start() {
    if (!isTimeoutRunning) {
      setIsTimeoutRunning(true);
    }
  }

  /* eslint-disable consistent-return */
  useEffect(() => {
    if (isTimeoutRunning) {
      const timeout = window.setTimeout(cb, timeoutDelayMs);
      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [isTimeoutRunning, cb, timeoutDelayMs]);
  /* eslint-enable consistent-return */
  return {
    clear,
    start,
    isTimeoutRunning,
  };
}

export default useTimeout;
