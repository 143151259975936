/* eslint-disable */
// @ts-nocheck
import { useTranslation, Trans } from 'react-i18next';
import { Card } from 'semantic-ui-react';

import { FUNDING_FLOW_OPTIONS } from '@/domain/paths';
import { getAnswer } from '@/domain/questions/util';

import { numberToPercentage } from '@/util/number';

import CalcResultsPlaceholder from '@/components/Placeholder/CalcResultsPlaceholder';
import ProgressBar from '@/components/ProgressBar';
import Paragraph from '@/components/Paragraph';
import Link from '@/components/link';
import MicroCopy from '@/components/microcopy';
import { ErrorBox, WarningBox } from '@/components/InformationBox';
import withDebouncedRender from '@/components/withDebouncedRender';

import { householdIncomeBFRQ } from '../questions';
import { calculateAffordability } from '../selectors';

import classes from './styles.module.less';

const CalcResults = ({ affordability }) => {
  const { t } = useTranslation('i18n-unplanned');
  // affordability is going to be less than one, like 0.050041
  // so we need to get more of a fixed value as the numberToPercentage
  // function wants the value to be less than one.
  const affordabilityPercentage = Number(affordability.toFixed(6));
  const unaffordable = affordabilityPercentage > 0.05;

  return (
    <>
      {unaffordable ? (
        <ErrorBox noMargin icon="warning sign" content={<strong>{t('might-not-be-available')}</strong>} />
      ) : (
        <WarningBox noMargin icon="warning sign" content={<strong>{t('borrow-with-caution')}</strong>} />
      )}

      <div className={classes.wrapper}>
        <ProgressBar
          data-testid="progressbar"
          marker={{
            label: t('goal-under-5'),
            value: 5,
          }}
          maxValue={100}
          minValue={0}
          showMaxValue={false}
          showMinValue={false}
          label={t('i18n-default:monthly-payment')}
          subline={t('monthly-payment-income.progress', {
            affordabilityPercentage: numberToPercentage(affordabilityPercentage),
          })}
          value={Number(affordability.times(100).toFixed(2))}
        />
        <div className={classes.progressDivider} />
        {unaffordable ? (
          <>
            <Paragraph>{t('monthly-payment-income')}</Paragraph>
            <MicroCopy size="small">
              <Trans ns="i18n-unplanned" i18nKey="monthly-payment-income.paragraph">
                If you still want to borrow, make sure you’ll be able to afford your other bills. Continue below or go
                back to{' '}
                <Link to={FUNDING_FLOW_OPTIONS} icon={false}>
                  other funding options
                </Link>
                .
              </Trans>
            </MicroCopy>
          </>
        ) : (
          <Paragraph>
            {t('monthly-payment-percent', { affordabilityPercentage: numberToPercentage(affordabilityPercentage) })}
          </Paragraph>
        )}
      </div>
    </>
  );
};

const Results = ({ answerWrapper, monthlyLoanPayment }) => {
  const monthlyHouseholdIncome = getAnswer(householdIncomeBFRQ, answerWrapper);

  // if an empty string or a string of zeros
  if (!monthlyHouseholdIncome || !Number(monthlyHouseholdIncome)) {
    return (
      <Card raised={false} className={classes.resultCard}>
        <CalcResultsPlaceholder />
      </Card>
    );
  }

  const affordability = calculateAffordability({
    monthlyLoanPayment,
    monthlyHouseholdIncome,
  });

  return (
    <Card raised={false} className={classes.resultCard}>
      <CalcResults affordability={affordability} />
    </Card>
  );
};

export default withDebouncedRender(Results);
