import React from 'react';
import cx from 'classnames';

import { notifyDeprecation } from '@/util/env';

import classes from './ResponsiveSpacing.module.less';

notifyDeprecation('ResponsiveSpacing is deprecated, use VerticalSpacing instead');

type ResponsiveSpacingPropsT = {
  left?: boolean;
  right?: boolean;
  top?: boolean;
  bottom?: boolean;
  size?: string;
  className?: string;
};

/**
 * Wraps the child components in a div with responsive padding.
 */
const ResponsiveSpacing = ({
  children,
  left = false,
  right = false,
  top = false,
  bottom = false,
  size = 'medium',
  className = '',
  style,
}: React.PropsWithChildren<ResponsiveSpacingPropsT & React.HTMLAttributes<HTMLDivElement>>) => (
  <div
    className={cx(
      {
        [classes.left]: left,
        [classes.right]: right,
        [classes.top]: top,
        [classes.bottom]: bottom,
        [classes.small]: size === 'small',
        [classes.large]: size === 'large',
        [classes.big]: size === 'big',
      },
      className
    )}
    style={style}
  >
    {children}
  </div>
);

export default ResponsiveSpacing;
