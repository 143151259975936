import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_FEATURE_FLAGS } from '@/graphql/queries';
import { GetFeatureFlags } from '@/types/generated/GetFeatureFlags';

type TFeatureFlag = { isFeatureEnabled: (feature: string) => boolean; loading: boolean };
export const FeatureFlagsContext = React.createContext({} as TFeatureFlag);

/**
 * This component manages the dynamic feature flags. Lifecycle of flags:
 * - flag defaults are provided during build time
 * - when this component mounts, it queries backend for dynamic flag values
 * - any child component reading these values first has to make sure that the flags have been loaded already
 * - !IMPORTANT if a flag is not found for the given key, default value is `false`
 */
const FeatureFlagsProvider = ({ children }) => {
  const { data, loading, error } = useQuery<GetFeatureFlags>(GET_FEATURE_FLAGS);

  const getFeatureFlags = data?.getFeatureFlags as object;
  const isFeatureEnabled = (feature: string) => {
    if (loading || error || !data) {
      return false;
    }

    const featureEnabled = getFeatureFlags[feature];

    /**
     * This condition warning is triggered if a feature flag is requested which we have no value for.
     *  - local development: verify security.development.env is correctly configured and restart the backend.
     *  - deployed environments: verify both serverless.yml and security.[dev|qa|uat|prod].env are correctly configured.
     */
    if (featureEnabled == null) {
      // eslint-disable-next-line no-console
      console.error(`Missing expected feature flag: ${feature}`);
    }

    return getFeatureFlags[feature];
  };

  // TODO: Fix this eslint error
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <FeatureFlagsContext.Provider value={{ isFeatureEnabled, loading }}>{children}</FeatureFlagsContext.Provider>;
};

export default FeatureFlagsProvider;
