import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Icon } from 'semantic-ui-react';

import { SESSION_SIGNUP_MODAL_CLOSED } from '@/domain/constants';
import { DASHBOARD_PAGE_PATH } from '@/domain/paths';

import { AuthContext } from '@/services/authContext';

import AuthActionHandlers from '@/components/AuthActionHandlers';
import Button, { Clickable } from '@/components/button';
import Heading from '@/components/Heading';
import Link from '@/components/link';

import classes from './style.module.less';

const SignupModal = () => {
  const { t } = useTranslation('i18n-default');
  const { openAuthModal, setAuthModalOpen } = React.useContext(AuthContext);

  const closeModalForSession = () => {
    sessionStorage.setItem(SESSION_SIGNUP_MODAL_CLOSED, SESSION_SIGNUP_MODAL_CLOSED);
    setAuthModalOpen(false);
  };

  return (
    <Modal centered size="tiny" id="signup-modal" className={classes.AuthModalWrapper} open={openAuthModal}>
      <Modal.Header className={classes.header}>
        <Heading as="h2" className={classes.title}>
          {t('register-account.heading-two')}
        </Heading>

        <Clickable className={classes.closeIcon} onClick={closeModalForSession} data-testid="close-signup-modal">
          <Icon name="times circle outline" size="large" />
        </Clickable>
      </Modal.Header>
      <Modal.Content className={classes.content}>
        <Heading as="h4" className={classes.heading}>
          {t('register-account.subtext')}
        </Heading>
        <ul className={classes.benefitsList}>
          <li className={classes.benefitsListItem}>
            <Trans i18nKey="register-account.list-1">
              Seamlessly connect with your{' '}
              <Link to="https://www.aarp.org/" target="_blank">
                AARP.org
              </Link>{' '}
              and AARP Rewards accounts
            </Trans>
          </li>
          <li className={classes.benefitsListItem}>{t('register-account.list-2')}</li>
          <li className={classes.benefitsListItem}>{t('register-account.list-3')}</li>
        </ul>
      </Modal.Content>
      <Modal.Actions className={classes.footer}>
        <AuthActionHandlers>
          {({ handleLogInClick, handleSignUpClick, loading }) => (
            <>
              <Button
                fluidOnMobile
                rounded
                type="button"
                className={classes.registerBtn}
                data-testid="continueWithAARP"
                loading={loading}
                onClick={() => handleSignUpClick({ redirectLocation: DASHBOARD_PAGE_PATH })}
              >
                {t('i18n-actions:button.sign-up')}
              </Button>

              <Clickable onClick={() => handleLogInClick({ redirectLocation: DASHBOARD_PAGE_PATH })}>
                <span className={classes.loginLink}>
                  {t('have-account')} {t('i18n-actions:button.log-in')}
                </span>
              </Clickable>
            </>
          )}
        </AuthActionHandlers>
      </Modal.Actions>
    </Modal>
  );
};

export default SignupModal;
