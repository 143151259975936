import cx from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import classes from './CustomContainer.module.less';

export type CustomContainerT = {
  size: 'medium' | 'medium-wide' | 'medium-fixed-margin' | 'wide-fixed-margin' | 'large' | 'wide' | 'ultrawide';
  className?: string;
  verticallyPadded?: boolean;
  noMargin?: boolean;
  relativePosition?: boolean;
  children: ReactNode;
};

const CustomContainer: FunctionComponent<CustomContainerT> = ({
  size,
  className = '',
  verticallyPadded /* This prop should be removed when Layout component will be implemented */,
  noMargin,
  relativePosition,
  children,
}) => {
  return (
    <div
      className={cx(
        classes.container,
        classes[size],
        {
          [classes.verticallyPadded]: verticallyPadded,
          [classes.noMargin]: noMargin,
          [classes.relativePosition]: relativePosition,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default CustomContainer;
