import React from 'react';
import { Formik } from 'formik';
import { Form as SemanticForm } from 'semantic-ui-react';

import ScrollToError from './ScrollToError';

import type { FormikProps, FormikConfig, FormikValues } from 'formik';

/**
 * Ties together the form wrapper from Semantic and Formik utils.
 */
export type TFormProps<T = Record<string, unknown>> = FormikConfig<T> & {
  formTestId?: string;
  className?: string;
  scrollToError?: boolean;
  formRef?: React.Ref<FormikProps<T>>;
  id?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Form = <P extends FormikValues>({
  enableReinitialize = false,
  initialValues,
  onSubmit,
  onReset,
  validate,
  children,
  validateOnBlur = true,
  validateOnChange = true,
  scrollToError = true,
  validationSchema,
  formTestId,
  className = '',
  formRef,
  id,
}: TFormProps<P>) => {
  return (
    <Formik<P>
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      validationSchema={validationSchema}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      innerRef={formRef}
      onReset={onReset}
    >
      {({ handleSubmit, handleChange, values, errors, touched, ...formikProps }) => (
        <>
          {scrollToError && <ScrollToError />}
          <SemanticForm id={id} data-testid={formTestId} onSubmit={handleSubmit} noValidate className={className}>
            {typeof children === 'function'
              ? children({ handleSubmit, handleChange, values, errors, touched, ...formikProps })
              : children}
          </SemanticForm>
        </>
      )}
    </Formik>
  );
};

export default Form;
