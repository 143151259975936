/* eslint-disable */
// @ts-nocheck
import cx from 'classnames';
import { Checkbox } from 'semantic-ui-react';

import MicroCopy from '@/components/microcopy';
import Field from '@/components/FormField';

import classes from './styles.module.less';

function getLabel(label, subText) {
  if (subText) {
    return (
      <>
        <div>{label}</div>
      </>
    );
  }

  return label;
}

/**
 * Semantic UI checkbox & radio button do not handle change the way formik expects.
 * Formik works with plain change events, and semantic passes click event from the label instead of the input.
 * Formik will handle change for us, but we have to provide the valueName -
 * - it is what the `value` would be equal to if the button was selected
 */
const RadioField = ({
  className = '',
  'data-testid': dataTestId,
  'aria-labelledby': ariaLabelledBy,
  label,
  name = '',
  subText,
  valueName,
  width,
  onChange,
}) => (
  <Field hideError id={name} name={name} width={width} inputType="radio">
    {({ form, name: fieldName, value }) => (
      <>
        <Checkbox
          data-testid={dataTestId}
          radio
          name={fieldName}
          label={{ children: getLabel(label, subText) }}
          aria-label={label}
          aria-labelledby={ariaLabelledBy}
          value={valueName}
          checked={value === valueName}
          onChange={() => {
            form.setFieldValue(fieldName, valueName);
            if (onChange) onChange(valueName);
          }}
          onBlur={() => {
            form.setFieldTouched(fieldName);
          }}
          className={cx(className, classes.radioButton, {
            [classes.subText]: subText != null,
          })}
        />
        <div className={classes.subTextWrapper}>
          <MicroCopy>{subText}</MicroCopy>
        </div>
      </>
    )}
  </Field>
);

export default RadioField;
