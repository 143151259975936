/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';

import { isFlowCompleted } from '@/domain/WizardConfig';

import useUserState from '@/hooks/useUserState';

import { WizardContext } from '../StepWrapper';

const flowCompletedSelector = createSelector(
  (state, skeleton) => state.flowMetadata[skeleton.name],
  (_, skeleton) => skeleton,
  (expenseFlow, skeleton) => isFlowCompleted(expenseFlow, skeleton)
);

const MyPlanButtonText = () => {
  const { t } = useTranslation('i18n-actions');
  const { skeleton } = React.useContext(WizardContext);
  const { state: flowCompleted, loading, error } = useUserState(flowCompletedSelector, skeleton);
  if (loading || error || !flowCompleted) return t('button.get-my-plan');
  return t('button.update-my-plan');
};

export default MyPlanButtonText;
