/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import { loanAmountQ } from '@/domain/questions';
import { getAnswer } from '@/domain/questions/util';

import useUserState from '@/hooks/useUserState';

import { creditScoreQ } from '@/pages/FundingFlow/questions';

import ContainerWithStickyContent from '@/components/ContainerWithStickyContent';
import CustomContainer from '@/components/CustomContainer';
import Divider from '@/components/Divider';
import Heading from '@/components/Heading';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';
import Row from '@/components/Row';
import calculatorImage from '@/images/calculatorIcon.svg';

import { loanInterestRateQ, loanRepayPeriodQ } from '../questions';

import Form from './Form';
import Results from './Results';

import classes from './Results.module.less';

const creditScoreToInterestRate = {
  excellent: 12,
  veryGood: 13,
  good: 16,
  fair: 20,
  poor: 29,
  dontKnow: 20,
};

const getDefaultInterestRate = state => {
  const score = getAnswer(creditScoreQ, state);

  return creditScoreToInterestRate[score];
};

const CostEstimate = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-unplanned');
  const { state, loading, error } = useUserState();
  if (loading) return null;
  if (error) return null;

  const loanAmount = getAnswer(loanAmountQ, state);
  const loanInterestRate = getAnswer(loanInterestRateQ, state);
  const loanPaybackTerm = getAnswer(loanRepayPeriodQ, state);

  const initialValues = {
    [loanAmountQ.id]: loanAmount,
    [loanInterestRateQ.id]: loanInterestRate || getDefaultInterestRate(state),
    [loanRepayPeriodQ.id]: loanPaybackTerm || 3,
  };

  return (
    <CustomContainer noMargin verticallyPadded>
      <Heading as="h1">{t('personal-loan.cost-estimate.heading')}</Heading>
      <QuestionsForm fullWidth backButton={false} initialValues={initialValues}>
        {({ values }) => {
          const answerWrapper = fromAssessment ? state : { answers: values };

          return (
            <>
              <Row>
                <Form fromAssessment={fromAssessment} />
                <Results answerWrapper={answerWrapper} />
              </Row>
              {/* <ContainerWithStickyContent
                disableSticky={fromAssessment}
                leftSideContent={<Form fromAssessment={fromAssessment} />}
                rightSideContent={<Results answerWrapper={answerWrapper} />}
              /> */}
              {fromAssessment ? null : (
                <WizardUpNext imageSrc={calculatorImage}>{t('personal-loan.cost-estimate.wizard')}</WizardUpNext>
              )}
            </>
          );
        }}
      </QuestionsForm>
    </CustomContainer>
  );
};

export default CostEstimate;
