/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'semantic-ui-react';
import cx from 'classnames';

import { toUSD } from '@/util/currency';

import Button from '@/components/button';
import Currency from '@/components/Currency';
import Paragraph from '@/components/Paragraph';
import VerticalSpacing from '@/components/VerticalSpacing';

import { fastTrackBoostAmount, fastTrackPlusBoostAmount } from '../selectors';

import { useCreditCardState } from './State';

import classes from './styles.module.less';

const fastTrackBoostDiff = fastTrackPlusBoostAmount - fastTrackBoostAmount;

const NoPeriodMonthlyPaymentSuggestions = ({ calculations, monthlyPayment, onFastTrack }) => {
  const { t } = useTranslation('i18n-credit');
  const [state] = useCreditCardState();
  const whichMonthlyPayment = state.cachedMonthlyPayment == null ? monthlyPayment : state.cachedMonthlyPayment;
  const monthlyPaymentFastTrack = whichMonthlyPayment + fastTrackBoostAmount;
  const monthlyPaymentFastTrackPlus = whichMonthlyPayment + fastTrackPlusBoostAmount;

  const handleFastTrack = () => onFastTrack && onFastTrack(monthlyPaymentFastTrack);
  const handleFastTrackPlus = () => onFastTrack && onFastTrack(monthlyPaymentFastTrackPlus);

  const monthsRoundedUp = calculations?.fastTrack?.monthsRoundedUp.toFixed(0);
  const monthsRoundedUpPlus = calculations?.fastTrackPlus?.monthsRoundedUp.toFixed(0);

  return (
    <>
      <Paragraph bold size="large">
        {t('no-period-monthly-payment.paragraph-1', {
          possibleInterestSavings: toUSD(calculations.possibleInterestSavings, 0),
        })}
      </Paragraph>
      <Paragraph>{t('no-period-monthly-payment.paragraph-2')}</Paragraph>

      <VerticalSpacing size="2" />

      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column computer={8} tablet={16} stretched>
            <Card className={classes.card}>
              <Card.Header className={classes.cardHeader}>
                <Paragraph size="mini" textAlign="center">
                  {t('fast-track.uppercase')}
                </Paragraph>
              </Card.Header>
              <Card.Content>
                <Paragraph size="massive" textAlign="center" className={classes.fastTrackPayment}>
                  <Currency serifFont value={monthlyPaymentFastTrack} />
                </Paragraph>

                <Paragraph size="tiny" textAlign="center">
                  {t('per-month')}
                </Paragraph>

                <Button secondary fluid size="small" type="button" data-testid="fastTrackBtn" onClick={handleFastTrack}>
                  {t('choose')}
                </Button>
              </Card.Content>
              <Card.Content extra>
                <Paragraph bold size="medium">
                  <Currency value={calculations?.fastTrack?.interest} />
                </Paragraph>

                <Paragraph size="mini">{t('interest-paid')}</Paragraph>

                <Paragraph bold size="medium">
                  {t('months-rounded-up', { monthsRoundedUp })}
                </Paragraph>

                <Paragraph size="mini">{t('time-to-pay-off')}</Paragraph>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} stretched>
            <Card className={cx(classes.card, classes.fastTrackPlusColumn)}>
              <Card.Header className={cx(classes.cardHeader, classes.primaryCardHeader)}>
                <Paragraph size="mini" textAlign="center">
                  {t('only-more.uppercase', { fastTrackBoostDiff })}
                </Paragraph>
              </Card.Header>
              <Card.Content className={classes.primaryCardContent}>
                <Paragraph size="massive" textAlign="center" className={classes.fastTrackPaymentPlus}>
                  <Currency serifFont value={monthlyPaymentFastTrackPlus} className={classes.primaryH1} />
                </Paragraph>

                <Paragraph size="tiny" textAlign="center" className={classes.primaryH1}>
                  {t('per-month')}
                </Paragraph>

                <Button
                  primary
                  fluid
                  size="small"
                  type="button"
                  data-testid="fastTrackPlusBtn"
                  onClick={handleFastTrackPlus}
                >
                  {t('choose')}
                </Button>
              </Card.Content>
              <Card.Content extra>
                <Paragraph bold size="medium">
                  <Currency value={calculations?.fastTrackPlus?.interest} />
                </Paragraph>

                <Paragraph size="mini">{t('interest-paid')}</Paragraph>

                <Paragraph bold size="medium">
                  {t('months-rounded-up', { monthsRoundedUp: monthsRoundedUpPlus })}
                </Paragraph>

                <Paragraph size="mini">{t('time-to-pay-off')}</Paragraph>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default NoPeriodMonthlyPaymentSuggestions;
