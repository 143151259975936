const BASE_PATH_GOALS = '/goals';
export const GOALS_LANDING_PAGE_PATH = BASE_PATH_GOALS;
export const BASE_PATH_ONBOARDING_GOALS = `/goals-onboarding`;
export const SINGLE_ONBOARDING_QUESTION = `${BASE_PATH_ONBOARDING_GOALS}/goals-selection`;
export const ADD_GOAL_DETAILS = `${BASE_PATH_GOALS}/add-goal-details`;
export const EDIT_GOAL_DETAILS = `${BASE_PATH_GOALS}/edit-goal-details`;
export const ADD_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS = `${ADD_GOAL_DETAILS}/manage-unplanned-expense`;
export const ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS = `${ADD_GOAL_DETAILS}/reduce-my-expenses`;
export const ADD_CREDIT_CARD_DEBT_GOAL_DETAILS = `${ADD_GOAL_DETAILS}/credit-card-debt`;
export const ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS = `${ADD_GOAL_DETAILS}/student-loan-debt`;
export const ADD_OTHER_DEBT_GOAL_DETAILS = `${ADD_GOAL_DETAILS}/other-debt`;
export const ADD_SAVE_FOR_RAINY_DAY_GOAL_DETAILS = `${ADD_GOAL_DETAILS}/save-for-rainy-day`;
export const ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DETAILS = `${ADD_GOAL_DETAILS}/save-for-large-purchase`;
export const ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS = `${ADD_GOAL_DETAILS}/save-for-retirement`;
export const EDIT_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS = `${EDIT_GOAL_DETAILS}/manage-unplanned-expense`;
export const EDIT_REDUCE_MY_EXPENSES_GOAL_DETAILS = `${EDIT_GOAL_DETAILS}/reduce-my-expenses`;
export const EDIT_CREDIT_CARD_DEBT_GOAL_DETAILS = `${EDIT_GOAL_DETAILS}/credit-card-debt`;
export const EDIT_STUDENT_LOAN_DEBT_GOAL_DETAILS = `${EDIT_GOAL_DETAILS}/student-loan-debt`;
export const EDIT_OTHER_DEBT_GOAL_DETAILS = `${EDIT_GOAL_DETAILS}/other-debt`;
export const EDIT_SAVE_FOR_RAINY_DAY_GOAL_DETAILS = `${EDIT_GOAL_DETAILS}/save-for-rainy-day`;
export const EDIT_SAVE_FOR_RETIREMENT_GOAL_DETAILS = `${EDIT_GOAL_DETAILS}/save-for-retirement`;
export const ADD_GOAL_DEPOSIT = `${BASE_PATH_GOALS}/add-goal-deposit`;
export const ADD_SAVE_FOR_RAINY_DAY_GOAL_DEPOSIT = `${ADD_GOAL_DEPOSIT}/save-for-rainy-day`;
export const ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DEPOSIT = `${ADD_GOAL_DEPOSIT}/save-for-large-purchase`;
export const ADD_SAVE_FOR_RETIREMENT_GOAL_DEPOSIT = `${ADD_GOAL_DEPOSIT}/save-for-retirement`;
export const FINANCIAL_GOAL_TRACKER = `${BASE_PATH_GOALS}/financial-goal-tracker`;
export const FINANCIAL_GOAL_TRACKER_ACTIVITIES = `${BASE_PATH_GOALS}/financial-goal-tracker/activities`;
export const FINANCIAL_GOAL_TRACKER_GOALS = `${BASE_PATH_GOALS}/financial-goal-tracker/goals`;
export const FINANCIAL_GOAL_DETAIL = `${FINANCIAL_GOAL_TRACKER_GOALS}/:goalId`;
export const FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL = `${FINANCIAL_GOAL_TRACKER_GOALS}/:goalId/:savingsId`;
export const EDIT_GOALS = `${BASE_PATH_GOALS}/edit-goals`;
export const CREATE_NEW_GOAL = `${BASE_PATH_GOALS}/create-goal`;
export const FINANCIAL_GOAL_DETAIL_ACTIVITIES = `${FINANCIAL_GOAL_DETAIL}/activities`;
export const FINANCIAL_GOAL_DETAIL_DEPOSITS = `${FINANCIAL_GOAL_DETAIL}/deposit-history`;
export const FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL_ACTIVITIES = `${FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL}/activities`;
export const FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL_DEPOSITS = `${FINANCIAL_GOAL_LARGE_PURCHASE_DETAIL}/deposit-history`;
