/* eslint-disable */
// @ts-nocheck
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import languageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import { NAMESPACES, DEFAULT_NS, FALLBACK_LNG, STORAGE_PREFIX, LANGUAGES, LANGUAGES_ENVS_MAP } from './constants';

export const ALLOWED_LANGUAGES = {
  en: 'en',
  es: 'es',
} as const;
export type TAllowedLanguages = typeof ALLOWED_LANGUAGES[keyof typeof ALLOWED_LANGUAGES];
export const FALLBACK_LANGUAGE = ALLOWED_LANGUAGES.en;

const env = process.env.NODE_ENV;

export const getI18n = () => {
  i18n
    .use(ChainedBackend)
    .use(languageDetector)
    .use(initReactI18next)
    .use(intervalPlural)
    .init({
      load: 'languageOnly',
      fallbackLng: FALLBACK_LNG,
      ns: Object.values(NAMESPACES),
      defaultNS: DEFAULT_NS,
      keySeparator: false, // we use flat json
      returnObjects: true, // objects/array are returned interpolated when used as value
      saveMissing: false, // send not translated keys to endpoint (only in dev mode)
      interpolation: {
        escapeValue: false,
        skipOnVariables: false,
      },
      backend: {
        backends: [...(env !== 'development' ? [LocalStorageBackend] : []), HttpBackend],
        backendOptions: [
          ...(env !== 'development'
            ? [
                {
                  prefix: STORAGE_PREFIX,
                  defaultVersion: 'v0.0',
                  versions: LANGUAGES.reduce((acc, lng) => {
                    const key = LANGUAGES_ENVS_MAP[lng];
                    acc[lng] = process.env[key] || 'v0.1'; // e.g process.env.REACT_APP_I18N_EN_VERSION
                    return acc;
                  }, {}),
                  expirationTime: 5 * 60 * 1000, // 5min - quickly expire stale, next refresh fetches new content
                },
              ]
            : []),
          {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            addPath: '/locales/add/{{lng}}/{{ns}}',
          },
        ],
      },
    });

  return i18n;
};
export default getI18n();
