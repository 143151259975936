/* eslint-disable */
// @ts-nocheck
import {
  FUNDING_FLOW_OPTIONS,
  RETIREMENT_BORROW_AMOUNT_CALC_PATH,
  RETIREMENT_PROS_AND_CONS_PATH,
  RETIREMENT_COST_CALC_PATH,
  RETIREMENT_AFFORDABILITY_PATH,
  RETIREMENT_HOW_TO_BORROW_PATH,
  BASE_PATH_RETIREMENT_FLOW,
} from '@/domain/paths';

import { trans } from '@/util/i18n';

import SubHeader from '@/pages/BorrowFromRetirementAccountsFlow/common/SubHeader';
import { isBorrowFromRetirementHidden } from '@/pages/FundingFlow/selectors';
import { isPartiallyCovered } from '@/pages/BorrowFromRetirementAccountsFlow/selectors';

import AuthControls from '@/components/AuthControls';
import IconLink from '@/components/IconLink';

import BorrowAmountCalc from './BorrowAmountCalc';
import ProsAndCons from './ProsAndCons';
import CostCalc from './CostCalc';
import Affordability from './AffordabilityCheck';
import HowToBorrow from './HowToBorrow';
import {
  amountInRetirementQ,
  contributingConditionalQ,
  expectedReturnRateQ,
  householdIncomeBFRQ,
  loanAmountBFRQ,
  loanInterestRateQ,
  loanRepayPeriodQ,
  willContinueContributingQ,
  yearsToRetirementQ,
} from './questions';

const header = {
  renderLeftItem: () => (
    <IconLink href={FUNDING_FLOW_OPTIONS} icon="th">
      {trans('payment-options')}
    </IconLink>
  ),
  renderCenterItem: () => trans('i18n-unplanned:borrow-retirement-plan'),
  renderRightItem: () => <AuthControls />,
};

const sidebar = [
  {
    id: 'borrow-amount-calc',
    title: trans('retirement-plan-amount'),
  },
  {
    id: 'pros-and-cons',
    title: trans('pros-and-cons'),
  },
  {
    id: 'cost-calc',
    title: trans('cost-estimate'),
  },
  {
    id: 'affordability-check',
    title: trans('affordability'),
  },
  {
    id: 'how-to-borrow',
    title: trans('how-to-borrow'),
    description: trans('how-to-borrow.description'),
  },
];

const subheader = {
  hasPartialCoverage: state => isPartiallyCovered(state),
  getContent: state => <SubHeader state={state} />,
};

const steps = {
  'borrow-amount-calc': {
    id: 'borrow-amount-calc',
    path: RETIREMENT_BORROW_AMOUNT_CALC_PATH,
    sidebarItemId: 'borrow-amount-calc',
    component: BorrowAmountCalc,
    renderQuestions: false,
    questions: [amountInRetirementQ, loanAmountBFRQ],
  },
  'pros-and-cons': {
    id: 'pros-and-cons',
    path: RETIREMENT_PROS_AND_CONS_PATH,
    sidebarItemId: 'pros-and-cons',
    component: ProsAndCons,
    subheader,
  },
  'cost-calc': {
    id: 'cost-calc',
    path: RETIREMENT_COST_CALC_PATH,
    sidebarItemId: 'cost-calc',
    component: CostCalc,
    renderQuestions: false,
    questions: [
      contributingConditionalQ,
      expectedReturnRateQ,
      loanInterestRateQ,
      loanRepayPeriodQ,
      willContinueContributingQ,
      yearsToRetirementQ,
    ],
    size: 'wide',
    subheader,
  },
  'affordability-check': {
    id: 'affordability-check',
    path: RETIREMENT_AFFORDABILITY_PATH,
    renderQuestions: false,
    questions: [householdIncomeBFRQ],
    sidebarItemId: 'affordability-check',
    component: Affordability,
    subheader,
  },
  'how-to-borrow': {
    id: 'how-to-borrow',
    path: RETIREMENT_HOW_TO_BORROW_PATH,
    sidebarItemId: 'how-to-borrow',
    component: HowToBorrow,
    subheader,
  },
};

const initialState = {
  stepId: 'borrow-amount-calc',
};

const retirementFlowConfig = {
  name: 'retirementFlow',
  path: `${BASE_PATH_RETIREMENT_FLOW}/:id`,
  isFlowEnabled: state => !isBorrowFromRetirementHidden(state),
  assessment: {
    editLink: RETIREMENT_BORROW_AMOUNT_CALC_PATH,
    title: trans('i18n-unplanned:borrow-retirement-assessment'),
  },
  steps,
  sidebar,
  header,
  reducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'borrow-amount-calc':
        return { ...state, stepId: 'pros-and-cons' };
      case 'pros-and-cons':
        return { ...state, stepId: 'cost-calc' };
      case 'cost-calc':
        return { ...state, stepId: 'affordability-check' };
      case 'affordability-check':
        return { ...state, stepId: 'how-to-borrow' };
      case 'how-to-borrow':
      case 'personal-assessment':
        return { ...state, nextLocation: { pathname: FUNDING_FLOW_OPTIONS } };
      default:
        return initialState;
    }
  },
  reverseReducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'borrow-amount-calc':
        return { ...state, nextLocation: { pathname: FUNDING_FLOW_OPTIONS } };
      case 'pros-and-cons':
        return { ...state, stepId: 'borrow-amount-calc' };
      case 'cost-calc':
        return { ...state, stepId: 'pros-and-cons' };
      case 'affordability-check':
        return { ...state, stepId: 'cost-calc' };
      case 'how-to-borrow':
        return { ...state, stepId: 'affordability-check' };
      default:
        return initialState;
    }
  },
};

export default retirementFlowConfig;
