/* eslint-disable */
// @ts-nocheck
const numericFields = ['earnings', 'loanPaybackTerm', 'yearsToRetirement'];

const percentageFields = [
  'loanInterestRate',
  'retirementAccountExpectedRateOfReturn',
  'retirementPlanContribution',
  'retirementPlanEmployerMatch',
];

export const parseValues = values => {
  const newValues = {
    ...values,
  };

  // need to make sure these are numbers since they
  // may be strings when they come from inputs
  numericFields.forEach(field => {
    if (newValues[field]) {
      newValues[field] = Number(newValues[field]);
    }
  });

  // need to turn percentages that come from form fields as
  // 6.5 into 0.065 for math
  percentageFields.forEach(field => {
    if (newValues[field]) {
      newValues[field] /= 100;
    }
  });

  return newValues;
};
