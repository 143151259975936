import React from 'react';

import { FeatureFlagsContext } from '@/services/featureFlags';

import { envOrDefault } from '@/util/env';

import { createAnalytics } from '@/analytics';
import { TProvider } from '@/types/common';

export type TAnalytics = ReturnType<typeof createAnalytics>;
export const AnalyticsContext = React.createContext({} as TAnalytics);

const AnalyticsProvider: TProvider = ({ children }) => {
  const [analytics, setAnalytics] = React.useState<TAnalytics | null>(null);
  const { isFeatureEnabled, loading } = React.useContext(FeatureFlagsContext);

  React.useEffect(() => {
    if (loading) {
      return undefined;
    }

    const config = {
      analyticsEnabled: isFeatureEnabled('ABACUS_FLAG_ANALYTICS_ENABLED'),
      adobeLaunchEnabled: isFeatureEnabled('ABACUS_FLAG_ADOBE_LAUNCH_ENABLED'),
      logToConsole: Boolean(envOrDefault('REACT_APP_LOG_ANALYTICS', true)),
    };

    const analyticsService = createAnalytics(config);

    // initial page view is tracked manually
    analyticsService.trackPageView(window.location.pathname);

    setAnalytics(analyticsService);

    // clear history listener on unmount
    return analyticsService.clearHistoryListener;
  }, [isFeatureEnabled, loading]);

  // don't render if analytics service is not ready
  if (!analytics) {
    return null;
  }

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
