/* eslint-disable */
// @ts-nocheck
import { v4 as uuidv4 } from 'uuid';

import { mergePaymentPlanWithDebts } from '@/domain/debtsPlan';

import { trans } from '@/util/i18n';

import { getChangeTagAccounts } from '@/pages/DebtPlan/PaymentPlan/selectors';

import { InitialContextT } from '..';

export const notifyChangedTags = (context: InitialContextT) => {
  const { notify, sendEvent, highlights } = context;
  // TODO this seems really funky on a call to action method perhaps we already have this?
  const debtsPlan = mergePaymentPlanWithDebts(context);
  const changeTagAccounts = getChangeTagAccounts({ debtsPlan, state: { context } });
  const notificationSpace = 80;
  const numberOfChanges = changeTagAccounts.length;
  const applyChangeTagHighlighting = () =>
    sendEvent({
      type: 'UPDATE_HIGHLIGHTING',
      highlight: {
        ...highlights.changeTag,
        animationKey: uuidv4(),
        shouldHighlight: true,
      },
    });

  // This notification should not show up in any disqualification state
  if (numberOfChanges) {
    const firstAccount = changeTagAccounts[0];
    const viewChanges = () => {
      // Verify that the Plan tab is active
      const planTab = document.getElementById('tab-plan');
      if (!planTab) return;

      const isPlanTabSelected = planTab.getAttribute('aria-selected') === 'true';
      if (!isPlanTabSelected) planTab.click();

      const element = document.getElementById(firstAccount.debtId);
      if (!element) return;

      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: y - notificationSpace });

      // Run changeTag animation after scrolling
      applyChangeTagHighlighting();
    };
    const content = trans('suggested-changes', { count: numberOfChanges });
    const actionContent = trans('view-changes');

    // Run changeTag animation along with the notification
    applyChangeTagHighlighting();

    notify({
      content,
      actionContent,
      action: viewChanges,
      id: 'changeTagsNotification',
    });
  }
};
