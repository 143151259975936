/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { setSafeExpenseValues, defaultUserState, setSafeUserStateValues } from '@/domain/UserState';

import { SessionContext } from '@/services/session';
import { ExpensesContext } from '@/services/expenses';

const filterDeletedExpenses = expenses => {
  return expenses && expenses.filter(exp => exp.status === 'ACTIVE');
};

/**
 * A hook that returns the userState.
 */
const useUserState = (selector, props) => {
  const { userId } = React.useContext(SessionContext);
  const {
    expenses: { loading, data, error },
    expense,
  } = React.useContext(ExpensesContext);

  const applicationState = React.useMemo(
    () => ({ userId, expense, data, loading, error }),
    [userId, expense, data, loading, error]
  );

  const emptyState = { ...defaultUserState };

  if (applicationState.loading || !applicationState.userId) return { loading: true, error: false, state: emptyState };
  if (applicationState.error) return { loading: false, error: true, state: emptyState };

  let state;
  if (!applicationState.data || !applicationState.data.expenses) {
    state = emptyState;
  } else {
    const currentExpense = applicationState.expense || defaultUserState;
    state = {
      ...setSafeUserStateValues(currentExpense),
      expenses: filterDeletedExpenses(applicationState.data.expenses).map(expenses => setSafeExpenseValues(expenses)),
      expenseId: currentExpense.expenseId,
    };
  }

  if (typeof selector !== 'function') return { loading: false, error: false, state };
  return { loading: false, error: false, state: selector(state, props) };
};

export default useUserState;
