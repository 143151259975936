import React from 'react';

import createQuestionFactory from '@/domain/questions/createQuestionFactory';
import { getAnswer } from '@/domain/questions/util';
import { getAnswerValidationRules } from '@/domain/questions/validation';

import { trans } from '@/util/i18n';

import RadioGroup from '@/components/FormField/RadioGroup';

const autoQuestionFactory = createQuestionFactory('auto', getAnswerValidationRules);

export const accidentQ = autoQuestionFactory({
  name: 'inCarAccident',
  title: <strong>{trans('i18n-questions:accident.title')}</strong>,
  component: RadioGroup,
  defaultProps: {
    fields: [
      {
        label: trans('i18n-questions:answer.yes'),
        valueName: 1,
      },
      {
        label: trans('i18n-questions:answer.no'),
        valueName: 0,
      },
    ],
  },
});

export const carRunningQ = autoQuestionFactory({
  name: 'isCarRunning',
  title: <strong>{trans('i18n-questions:car-running.title')}</strong>,
  component: RadioGroup,
  defaultProps: {
    fields: [
      {
        label: trans('i18n-questions:answer.yes'),
        valueName: 1,
      },
      {
        label: trans('i18n-questions:answer.no'),
        valueName: 0,
      },
    ],
  },
});

export const conditionalAutoQ = {
  id: 'auto conditional',
  getQuestions: formValues => {
    const hadAccident = getAnswer(accidentQ, { answers: formValues });

    if (hadAccident === 0) {
      return [accidentQ, carRunningQ];
    }

    return [accidentQ];
  },
};
