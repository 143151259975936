import React from 'react';
import { FieldProps } from 'formik';
import { NumericFormat } from 'react-number-format';

import Field from '@/components/FormField';

type TCurrencyInputField = {
  'aria-label': string;
  className?: string;
  hasCancelIcon?: boolean;
  cancelIconClassName?: string;
  autoSelect?: boolean; // automatically select the field value on focus
  'data-testid'?: string;
  label?: string;
  name: string;
  onChange?: (value: unknown) => void;
  onBlur?: (fieldName: string, value: unknown) => void;
  onCancelClick?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  hidden?: boolean;
  width?: string;
  editMode?: boolean;
  formatAnswer?: (value: number) => string;
  hideError?: boolean;
};

const CurrencyInputField = ({
  'aria-label': ariaLabel,
  className,
  hasCancelIcon: _hasCancelIcon = false,
  cancelIconClassName: _cancelIconClassName,
  autoSelect = false, // automatically select the field value on focus
  'data-testid': dataTestId,
  label,
  name,
  onChange,
  onBlur,
  onCancelClick: _onCancelClick,
  placeholder,
  readOnly,
  hidden = false,
  width,
  editMode = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatAnswer,
  hideError = false,
  ...restProps
}: TCurrencyInputField) => {
  // This is a bit of a hack -- we are substituting an empty string for an undefined floatValue (EX: when field is emptied)
  const handleValueChange = (data, form: FieldProps['form']) => {
    const { floatValue } = data;
    const payload = typeof floatValue === 'undefined' ? '' : floatValue;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    form.setFieldValue(name, payload);
  };

  // Utilized for the input focus and selection.
  const inputFieldRef = React.useRef<HTMLInputElement | null>(null);

  const focusInput = () => {
    if (inputFieldRef.current) {
      inputFieldRef.current.focus();
    }
  };

  // Sets the input focus. The component itself doesn't support the autofocus.
  React.useEffect(() => {
    if (editMode) {
      focusInput();
    }
  }, [editMode]);

  const selectValueOnFocus = () => {
    if (inputFieldRef.current) {
      inputFieldRef.current.select();
    }
  };

  return (
    <>
      <Field
        className={className}
        id={name}
        label={label}
        name={name}
        inputType="number"
        onChange={onChange}
        width={width}
        // @ts-expect-error readOnly prop possibly not defined
        readOnly={readOnly}
        hidden={hidden}
        hideError={hideError}
        {...restProps}
      >
        {(
          // onChange is purposely not passed to NumericFormat to prevent the formatted string from being passed to Formik
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          { name: fieldName, value, onChange: _, error = '', form, ...inputProps }
        ) => (
          <>
            <NumericFormat
              // format={currencyInputFormatter}
              getInputRef={el => {
                inputFieldRef.current = el;
              }}
              prefix="$"
              thousandSeparator
              decimalScale={2}
              aria-label={ariaLabel}
              data-testid={dataTestId}
              name={fieldName}
              placeholder={placeholder}
              value={value}
              {...inputProps}
              {...(autoSelect ? { onFocus: selectValueOnFocus } : {})}
              onValueChange={data => handleValueChange(data, form)}
              {...(onBlur ? { onBlur: () => onBlur(fieldName, value) } : {})}
              inputMode="decimal" // TODO: prop is not supported by the library, double check.
            />
          </>
        )}
      </Field>
    </>
  );
};

export default CurrencyInputField;
