import React from 'react';

import { AnalyticsContext } from '@/services/analytics';

import Modal, { CustomModalProps } from './Modal';

export type AnalyticsModalProps = {
  children: React.ReactNode | ((props: { onMainButtonClicked: () => unknown }) => React.ReactNode);
  name?: string;
  openControlId?: string;
};

/**
 * Extension of the basic Modal component with built-in tracking events.
 * If an `openControlId` is provided, it will be included in the `openModal` event as payload.
 */
const AnalyticsModal = (props: CustomModalProps & AnalyticsModalProps) => {
  const analytics = React.useContext(AnalyticsContext);

  const createEvent = (name, payload = {}) => ({
    name,
    payload: {
      ...payload,
      modalName: props.name,
    },
  });

  const onClose = (event: Event, data = {}) => {
    analytics.trackEvent(createEvent('closeModal'));

    if (props.onClose) {
      props.onClose(event, data);
    }
  };

  const onOpen = (event: Event, data = {}) => {
    analytics.trackEvent(createEvent('openModal', { openControlId: props.openControlId }));
    if (props.onOpen) {
      props.onOpen(event, data);
    }
  };

  const onMainButtonClicked = () => {
    analytics.trackEvent(createEvent('mainButtonModal'));
  };

  const newProps = {
    ...props,
    onClose,
    onOpen,
  };

  const renderChildren = () => {
    const { children } = props;

    if (typeof children === 'function') {
      return children({ onMainButtonClicked });
    }
    if (children != null) {
      return children;
    }
    return null;
  };

  return <Modal {...newProps}>{renderChildren()}</Modal>;
};

export default AnalyticsModal;
