/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import WhyDoYouAskModal from '@/components/modal/WhyDoYouAskModal';

const HouseholdIncomeWhyAsk = () => {
  const { t } = useTranslation('i18n-unplanned');

  return <WhyDoYouAskModal name="householdIncomeWhyAsk">{t('household-income-why-ask')}</WhyDoYouAskModal>;
};

export default HouseholdIncomeWhyAsk;
