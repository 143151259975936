/* eslint-disable */
// @ts-nocheck
import { ACCOUNT_STATUSES } from 'common/model/debt';
import { PAYMENT_PLAN_STATUSES } from 'common/model/paymentPlan';
import { ApolloQueryResult } from '@apollo/client';

import { applyCreditorAgreementToDebt, TAccount } from '@/domain/debts';
import {
  checkIfAnyDebtsPending,
  checkIfAnyDebtsPresent,
  isPaymentPlanStatesIdle,
  serializeAccounts,
  TPaymentPlan,
} from '@/domain/debtsPlan';
import {
  updateAmountPaid,
  updateAmountPaidAndUpsertTransaction,
  updateAvailableFunds,
} from '@/domain/stateMachines/debtManager/paymentPlanStates';

import { not } from '@/util/function';

import {
  CREATE_DEBT,
  CREATE_DEBTS,
  UPDATE_DEBT,
  DELETE_DEBT,
  UPDATE_PAYMENT_PLAN,
  ADVANCE_MONTH,
} from '@/graphql/mutations';
import { LIST_DEBTS, GET_COMBINED_DEBTS_PLAN } from '@/graphql/queries';

import {
  hasActivePaymentPlan,
  isBudgetMachineIdle,
  isInEditFlow,
  isInMonthTransition,
} from './debtsPlanMachineHelpers';

import type { InitialContextT } from '..';
import type { AnyEventObject, EventObject, StateNodeConfig } from 'xstate';

type Nullable<T> = { [K in keyof T]: T[K] | null };

export const addDebt = async (
  context: InitialContextT,
  event: EventObject & { debt: TAccount; status: keyof typeof ACCOUNT_STATUSES }
) => {
  const { userId, client } = context;

  /* Allows us to pass an optional status when adding a debt */
  const { status = ACCOUNT_STATUSES.ACTIVE } = event;

  let { debt } = event;
  /* The next line is a solution regarding story AB-3353, allUserNicknames is a the name of a hidden
    question that's value is the names of all the accounts nicknames that the user created and the main
    use of allUserNicknames is to validate that the newly created nickname is not duplicated. Here the
    property is deleted because it doesn't exist in the schema and it's only for the purpose of validation
    so it doesn't need to be added to BE  */
  const { allUserNicknames: _, ...cleanDebt } = debt;
  debt = { status, ...cleanDebt };

  // Necessary for the optimistic result to have a structure matching the eventual result
  const placeholderFields = {
    debtId: '',
    lastPaymentMade: 0,
    paymentDueDate: null,
    daysDelinquent: null,
    isPrioritized: null,
    dateCreated: null,
    dateUpdated: null,
  };

  const updateAvailableFundsResult = await updateAvailableFunds(context, { debts: [debt] });

  const res = await client.mutate<{ createDebt: TAccount & { __typename: string } }>({
    mutation: CREATE_DEBT,
    optimisticResponse: {
      createDebt: {
        __typename: 'DebtOutput',
        ...debt,
        ...placeholderFields,
      },
    },
    update(cache, { data }) {
      const { createDebt: createdDebt } = data ?? {};
      const query = cache.readQuery<{ debts: TAccount[] }>({ query: LIST_DEBTS, variables: { userId } });
      const { debts } = query ?? ({} as { debts: TAccount[] });

      cache.writeQuery({
        query: LIST_DEBTS,
        variables: { userId },
        data: { debts: [...debts, createdDebt] },
      });
    },
    variables: {
      input: {
        userId,
        debt,
      },
    },
  });

  // merge the result of the mutation with the updateAvailableFundsResult
  const result = {
    data: {
      ...res.data,
      ...updateAvailableFundsResult.data,
    },
  };

  return result;
};

export const editDebt = async (
  context: InitialContextT,
  event: EventObject & { debt: TAccount; values: TAccount & { allUserNicknames?: string[] } }
) => {
  const { userId, client } = context;
  const { debt, values, navigate } = event;
  const { debtId, type } = debt;

  /* if statementBalance or minMonthlyPayment are present in values means that the user has
   * changed them, so we need to pass them to the applyCreditorAgreementToDebt.
   * if they are not present in values means that the user didn't change them so we need to pass
   * the original values that came with the debt */
  let statementBalance = values.statementBalance !== undefined ? values.statementBalance : debt.statementBalance;
  let minMonthlyPayment = values.minMonthlyPayment !== undefined ? values.minMonthlyPayment : debt.minMonthlyPayment;
  let debtBudget = values.debtBudget !== undefined ? values.debtBudget : debt.debtBudget;

  let updatedValues = {
    ...values,
    // we must include debtBudget because the context debt might be used to calculate the statement balance
    debtBudget: debtBudget,
    // turn 0|1 into booleans
    ...(values.isPrioritized != null ? { isPrioritized: Boolean(values.isPrioritized), type } : {}),
    ...(values.isDelinquent != null ? { isDelinquent: Boolean(values.isDelinquent) } : {}),
    // Trim excess whitespace from nickname
    ...(values.nickname != null ? { nickname: values.nickname.trim() } : {}),
  };

  /* The next line is a solution regarding story AB-3353, allUserNicknames is a the name of a hidden
  question that's value is the names of all the accounts nicknames that the user created and the main
  use of allUserNicknames is to validate that the newly created nickname is not duplicated. Here the
  property is deleted because it doesn't exist in the schema and it's only for the purpose of validation
  so it doesn't need to be added to BE  */
  const { allUserNicknames: _, ...cleanValues } = updatedValues;
  updatedValues = { ...cleanValues };

  // if nickname is not changed, we don't need to pass nickname to the mutation
  if (updatedValues.nickname === debt.nickname) {
    delete updatedValues.nickname;
  }

  // side effect: creditor agreements
  if (values.creditorAgreement != null) {
    // statementBalance needs to be there for cross field validation
    updatedValues = applyCreditorAgreementToDebt(values.creditorAgreement, {
      ...updatedValues,
      statementBalance,
      minMonthlyPayment,
    });
  }

  // Necessary for the optimistic result to have a structure matching the eventual result
  const placeholderFields: TAccount = {
    debtId: '',
    lastPaymentMade: 0,
    daysDelinquent: null,
    isPrioritized: null,
    dateCreated: null,
    dateUpdated: null,
    status: ACCOUNT_STATUSES.ACTIVE,
    nickname: '',
    isDelinquent: false,
    paymentDueDate: null,
    minMonthlyPayment: 0,
    statementBalance: 0,
    interestRate: 1,
    creditorAgreement: 'NO_SPECIAL_AGREEMENT',
  };

  const updateAvailableFundsResult = await updateAvailableFunds(context, {
    debts: [
      {
        ...updatedValues,
        debtId,
      },
    ],
  });

  const res = await client.mutate<{ updateDebt: TAccount & { __typename: string } }>({
    mutation: UPDATE_DEBT,
    optimisticResponse: {
      updateDebt: {
        __typename: 'DebtOutput',
        ...placeholderFields,
        ...debt,
      },
    },
    update(cache, { data }) {
      const { updateDebt } = data ?? ({} as { updateDebt: TAccount });
      const query = cache.readQuery<{ debts: TAccount[] }>({
        query: LIST_DEBTS,
        variables: { userId },
      });
      const { debts: oldDebts } = query ?? { debts: [] };
      const updatedDebtIndex = oldDebts.findIndex(oldDebt => oldDebt.debtId === updateDebt?.debtId);
      const debts = [...oldDebts];
      if (updatedDebtIndex > -1) {
        debts.splice(updatedDebtIndex, 1, updateDebt);
      }

      cache.writeQuery({
        query: LIST_DEBTS,
        variables: { userId },
        data: { debts },
      });
    },
    variables: {
      input: {
        userId,
        debt: {
          ...updatedValues,
          debtId,
        },
      },
    },
  });

  const result = {
    data: {
      ...res.data,
      ...updateAvailableFundsResult.data,
    },
    navigate,
  };

  // merge the result of the mutation with the updateAvailableFundsResult
  return result;
};

const addDebts = async (
  context: InitialContextT,
  event: EventObject & {
    debtsAdded: {
      debt: Partial<TAccount>;
      status: string;
    }[];
  }
) => {
  const { client, userId = '' } = context;
  const { debtsAdded, type } = event;

  // Skip mutation if debts is empty so that we use the side effects directly
  if (type !== 'DEBTS_ADDED' || !debtsAdded.length) return Promise.resolve();
  const createDebtsOptimisticResponse: (Nullable<Partial<TAccount>> & { __typename: string; status: string })[] = [];
  const formattedInput: { userId: string; debt: TAccount }[] = [];

  // Necessary for the optimistic result to have a structure matching the eventual result
  const placeholderFields = {
    debtId: '',
    lastPaymentMade: 0,
    paymentDueDate: null,
    daysDelinquent: null,
    isPrioritized: null,
    dateCreated: null,
    dateUpdated: null,
    creditorAgreement: null,
  };

  debtsAdded.forEach(debtWrapper => {
    const { debt, status = ACCOUNT_STATUSES.ACTIVE } = debtWrapper;
    createDebtsOptimisticResponse.push({
      __typename: 'DebtOutput',
      ...debt,
      ...placeholderFields,
      status,
    });
    formattedInput.push({ userId, debt: { ...debt, status } });
  });

  let updateAvailableFundsResult = null;
  try {
    updateAvailableFundsResult = await updateAvailableFunds(context, {
      debts: formattedInput.map(({ debt }) => debt),
    });
  } catch (error) {
    console.log('error', error);
  }

  const res = await client.mutate({
    mutation: CREATE_DEBTS,
    optimisticResponse: {
      createDebts: createDebtsOptimisticResponse,
    },
    update(cache, { data: { createDebts: createdDebts } }) {
      const { debts = [] } = cache.readQuery({ query: LIST_DEBTS, variables: { userId } });

      cache.writeQuery({
        query: LIST_DEBTS,
        variables: { userId },
        // Ensure unique debt ids
        data: { debts: [...new Map([...debts, ...createdDebts].map(item => [item.debtId, item])).values()] },
      });
    },
    variables: {
      input: [...formattedInput],
    },
  });

  // merge the result of the mutation with the updateAvailableFundsResult
  const result = {
    data: {
      ...res.data,
      ...updateAvailableFundsResult.data,
    },
  };

  return result;
};


export async function updateDebtAvailableFunds(context: InitialContextT, event: EventObject & { debt: TAccount }) {
  const { client, selectedDate, userId } = context;

  const { debt }: { debt: TAccount } = event;

  // sum all debt budget into availableFunds
  const existingDebts = context.debts.filter(
    d => d.debtId !== debt.debtId
  );

  const totalDebtBudget = existingDebts.reduce((acc, debt) => acc + debt.debtBudget, 0);

  const paymentPlan = {
    availableFunds: totalDebtBudget,
    month: selectedDate.month,
    year: selectedDate.year,
    hasUpdatedAvailableFunds: true,
  };

  return await client.mutate({ mutation: UPDATE_PAYMENT_PLAN, variables: { input: { userId, paymentPlan } } });
}


const removeDebt = async (context: InitialContextT, event: EventObject & { debt: TAccount }) => {
  const { client, userId } = context;
  const {
    debt: { debtId },
    navigate,
  } = event;

  const res = await client.mutate({
    mutation: DELETE_DEBT,
    variables: {
      input: {
        userId,
        debtId,
      },
    },
  })
  const response = await updateDebtAvailableFunds(context, event)

  return { ...res, data:{...res.data, updatePaymentPlan: response.data.updatePaymentPlan, upsertTransactions: []},  navigate };
};

export function trackAddedAccount(
  context: InitialContextT,
  event: EventObject & ApolloQueryResult<{ data: { createDebt: TAccount } }>
) {
  const { analytics } = context;
  const { createDebt: debt } = event.data.data;

  analytics.trackEvent({ name: 'addedAccount', payload: { debt } });
}

export function trackCreditorAgreement(
  context: InitialContextT,
  event: EventObject & ApolloQueryResult<{ data: { createDebt: TAccount } }>
) {
  const { analytics } = context;
  const { createDebt: debt } = event.data.data;
  analytics.trackEvent({
    name: 'setCreditorAgreement',
    payload: { creditorAgreement: debt.creditorAgreement },
  });
}

export function trackCreditorAgreementUpdate(
  context: InitialContextT,
  event: EventObject & ApolloQueryResult<{ data: { updateDebt: TAccount } }>
) {
  const { analytics } = context;
  const { updateDebt: debt } = event.data.data;

  if (debt.creditorAgreement) {
    analytics.trackEvent({
      name: 'setCreditorAgreement',
      payload: { creditorAgreement: debt.creditorAgreement },
    });
  }
}

export function trackEditedAccount(
  context: InitialContextT,
  event: EventObject & ApolloQueryResult<{ data: { updateDebt: TAccount } }>
) {
  const { analytics } = context;
  const { updateDebt: debt } = event.data.data;

  analytics.trackEvent({ name: 'updatedAccountDetails', payload: { debt } });
}

export function trackMarkedAsPrioritized(
  context: InitialContextT,
  event: EventObject & ApolloQueryResult<{ data: { updateDebt: TAccount } }>
) {
  const { analytics } = context;
  const { updateDebt: debt } = event.data.data;

  if (debt.isPrioritized) {
    analytics.trackEvent({ name: 'markedDebtAsMustPay', payload: { debt } });
  }
}

export const setActivePaymentPlan = (
  context: InitialContextT,
  event: EventObject & { remainingFundsTowardsUnpaidAccounts: number }
) => {
  const { remainingFundsTowardsUnpaidAccounts } = event;
  const { client, paymentPlan, userId, allocatedPayments } = context;

  const fields = {
    amountsPaid: serializeAccounts(allocatedPayments.amountsPaid || []),
    month: paymentPlan?.month,
    year: paymentPlan?.year,
    status: PAYMENT_PLAN_STATUSES.ACTIVE,
  };

  if (remainingFundsTowardsUnpaidAccounts) {
    // @ts-expect-error needs proper typing
    fields.availableFunds = Number(remainingFundsTowardsUnpaidAccounts.toFixed(2));
  }

  return client.mutate({
    mutation: UPDATE_PAYMENT_PLAN,
    variables: {
      input: {
        userId,
        paymentPlan: fields,
      },
    },
  });
};

export function advanceToNextMonth(context: InitialContextT) {
  const { client, selectedDate, userId, archivedPaymentPlans } = context;
  const { month, year } = selectedDate;

  return client.mutate<{
    advanceToCurrentMonth: {
      archivedPaymentPlan: TPaymentPlan;
      debts: TAccount[];
      paymentPlan: TPaymentPlan;
    };
  }>({
    mutation: ADVANCE_MONTH,
    variables: {
      userId,
      planToAdvanceFrom: {
        month,
        year,
      },
    },
    update(cache, { data }) {
      if (!data || !!data.advanceToCurrentMonth) return;
      const { archivedPaymentPlan, debts, paymentPlan } = data.advanceToCurrentMonth;
      // We update the cache with previous archived plans and the information that came with the transition.
      cache.writeQuery({
        query: GET_COMBINED_DEBTS_PLAN,
        variables: { userId },
        data: { debts, paymentPlans: [...archivedPaymentPlans, archivedPaymentPlan, paymentPlan] },
      });
    },
  });
}

export function createDebtCrudStates(
  parentStateName: string,
  options = { navigation: { add: '', update: '' } }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StateNodeConfig<InitialContextT, any, AnyEventObject> {
  const onError = {
    actions: 'notifyError',
  };

  return {
    id: 'debtCrudStates',
    initial: 'initial',
    states: {
      initial: {
        always: [{ target: 'noAccounts', cond: not(checkIfAnyDebtsPresent) }],
        on: {
          START_DEBT_MONTH_TRANSITION: 'advanceToNextMonth',
          FINISH_DEBT_MONTH_TRANSITION: 'setActivePaymentPlan',
          FINISH_DEBT_MONTH_TRANSITION_NLI: 'setActivePaymentPlanNLI',
          UPDATE_AMOUNT_PAID_FROM_PAYMENT_PLAN: 'updatingAmountPaidFromDebtsList',
          UPDATE_AMOUNT_PAID_FROM_RECORD_PAYMENT: 'updatingAmountPaidFromRecordPayment',
          UPDATE_AMOUNT_PAID_AND_UPSERT_TRANSACTION_FROM_PAYMENT_PLAN:
            'updatingAmountPaidAndUpsertTransactionFromPaymentPlan',
          DEBT_ADDED: 'addingDebt',
          CANCEL_ADD_ACCOUNT: { actions: options.navigation.add },
          DEBT_EDITED: 'editingDebt',
          DEBT_EDITED_ONLY: 'editDebtOnly',
          DEBTS_ADDED: 'addingDebts',
          DEBTS_ADDED_DM: 'addingDebtsDM',
          UPDATED_CREDITOR_AGREEMENT: 'updatingCreditorAgreement',
          PRIORITIZED_TOGGLED_FROM_DEBT_EDITING: 'togglingPrioritizedFromDebtEditing',
          PRIORITIZED_TOGGLED_FROM_PAYMENT_PLAN: 'togglingPrioritizedFromPaymentPlan',
          DEBT_REMOVED: 'removingDebt',
        },
      },
      advanceToNextMonth: {
        invoke: {
          id: 'advanceToNextMonth',
          src: advanceToNextMonth,
          onDone: {
            target: parentStateName,
            actions: ['finishMonthTransition', 'updateDebtsContext', 'mergePaymentPlanData', options.navigation.update],
          },
          onError: {
            target: parentStateName,
            actions: 'notifyError',
          },
        },
      },
      setActivePaymentPlanNLI: {
        entry: 'mergePaymentPlanData',
        invoke: {
          id: 'setActivePaymentPlanNLI',
          src: setActivePaymentPlan,
          onDone: {
            target: '#appMachine.loaded.debtManager.paymentPlan.ready',
            actions: ['updatePaymentPlan', 'updateDebtsContext', 'navigateToMonthTransitionConfirmation'],
          },
          onError: {
            target: parentStateName,
            actions: 'notifyError',
          },
        },
      },
      setActivePaymentPlan: {
        entry: 'mergePaymentPlanData',
        invoke: {
          id: 'setActivePaymentPlan',
          src: setActivePaymentPlan,
          onDone: {
            target: '#appMachine.loaded.debtManager.paymentPlan.ready',
            actions: ['updatePaymentPlan', 'updateDebtsContext', 'clearDraftPaymentPlan', 'navigateToPaymentPlan'],
          },
          onError: {
            target: parentStateName,
            actions: 'notifyError',
          },
        },
      },
      updatingAmountPaidFromDebtsList: {
        entry: 'trackMarkedDebtAsPaid',
        invoke: {
          id: 'updateAmountPaid',
          src: updateAmountPaid,
          onDone: {
            target: 'initial',
            actions: ['updatePaymentPlan', 'updateDebtsContext', 'closeModal'],
          },
          onError: {
            target: 'initial',
            actions: 'notifyError',
          },
        },
      },
      updatingAmountPaidFromRecordPayment: {
        entry: 'trackMarkedDebtAsPaid',
        invoke: {
          id: 'updatingAmountPaidFromRecordPayment',
          src: updateAmountPaidAndUpsertTransaction,
          onDone: {
            target: parentStateName,
            actions: ['updatePaymentPlan', 'updateDebtsContext', 'setTransactions', 'navigateBack'],
          },
          onError,
        },
      },
      updatingAmountPaidAndUpsertTransactionFromPaymentPlan: {
        entry: 'trackMarkedDebtAsPaid',
        invoke: {
          id: 'updateAmountPaidAndUpsertTransaction',
          src: updateAmountPaidAndUpsertTransaction,
          onDone: {
            target: parentStateName,
            actions: ['updatePaymentPlan', 'updateDebtsContext', 'closeModal'],
          },
          onError: {
            target: parentStateName,
            actions: 'notifyError',
          },
        },
      },
      noAccounts: {
        on: {
          DEBT_ADDED: 'addingDebt',
          DEBTS_ADDED: 'addingDebts',
          DEBTS_ADDED_DM: 'addingDebtsDM',
          START_DEBT_MONTH_TRANSITION: 'advanceToNextMonth',
          FINISH_DEBT_MONTH_TRANSITION: 'setActivePaymentPlan',
        },
      },
      addingDebt: {
        invoke: {
          id: 'addDebt',
          src: addDebt,
          onDone: {
            target: parentStateName,
            actions: [
              'updateDebtsContext',
              'trackAddedAccount',
              'trackCreditorAgreement',
              options.navigation.add,
              'notifyChangedTags',
              'allocatePayments',
            ],
          },
          onError,
        },
      },
      addingDebtOnly: {
        invoke: {
          id: 'addDebtOnly',
          src: addDebt,
          onDone: {
            target: parentStateName,
            actions: ['updateDebtsContext', 'trackAddedAccount', 'trackCreditorAgreement', 'notifyChangedTags'],
          },
          onError,
        },
      },
      editingDebt: {
        invoke: {
          id: 'editDebt',
          src: editDebt,
          onDone: [
            {
              cond: checkIfAnyDebtsPending,
              target: parentStateName,
              actions: [
                'clearPendingDebtId',
                'showProgressPopover',
                'updateDebtsContext',
                'assignReasonsForDisqualification',
                'trackEditedAccount',
                'navigateBack',
                'showPaidOffPopover',
                'showMonthTransitionPaidOffPopover',
                'notifyEditingDebt',
              ],
            },
            {
              cond: isPaymentPlanStatesIdle,
              target: parentStateName,
              actions: [
                'clearPendingDebtId',
                'showProgressPopover',
                'updateDebtsContext',
                'assignReasonsForDisqualification',
                'trackEditedAccount',
                'navigateBack',
                'showPaidOffPopover',
                'showMonthTransitionPaidOffPopover',
                'notifyEditingDebt',
              ],
            },
            {
              target: 'initial',
              actions: [
                'clearPendingDebtId',
                'showProgressPopover',
                'updateDebtsContext',
                'assignReasonsForDisqualification',
                'trackEditedAccount',
                'navigateBack',
                'showPaidOffPopover',
                'showMonthTransitionPaidOffPopover',
              ],
            },
          ],
          onError,
        },
      },
      editDebtOnly: {
        invoke: {
          id: 'editDebtOnly',
          src: editDebt,
          onDone: {
            target: 'initial',
            actions: ['clearPendingDebtId', 'updateDebtsContext', 'trackEditedAccount', 'notifyEditingDebt'],
          },
          onError,
        },
      },
      addingDebts: {
        invoke: {
          id: 'addingDebts',
          src: addDebts,
          onDone: [
            {
              cond: isInEditFlow,
              target: parentStateName,
              actions: ['updateDebtsContext'],
            },
            {
              target: parentStateName,
              actions: ['updateDebtsContext'],
            },
          ],
          onError,
        },
      },
      addingDebtsDM: {
        invoke: {
          id: 'addingDebtsDM',
          src: addDebts,
          onDone: [
            {
              cond: isInMonthTransition,
              target: 'initial',
              actions: ['updateDebtsContext', 'navigateToMonthTransitionBudgetPage'],
            },
            {
              cond: isBudgetMachineIdle,
              target: 'initial',
              actions: ['updateDebtsContext', 'navigateToOnboardingBudgetPage'],
            },
            {
              cond: hasActivePaymentPlan,
              target: 'initial',
              actions: ['updateDebtsContext', 'navigateToPaymentPlan'],
            },
            {
              target: 'initial',
              actions: ['updateDebtsContext'],
            },
          ],
          onError,
        },
      },
      togglingPrioritizedFromDebtEditing: {
        invoke: {
          id: 'togglingPrioritizedFromDebtEditing',
          src: editDebt,
          onDone: {
            target: parentStateName,
            actions: ['updateDebtsContext', 'trackMarkedAsPrioritized', options.navigation.update, 'notifyEditingDebt'],
          },
          onError,
        },
      },
      togglingPrioritizedFromPaymentPlan: {
        invoke: {
          id: 'togglingPrioritizedFromPaymentPlan',
          src: editDebt,
          onDone: {
            target: parentStateName,
            actions: [
              'updateDebtsContext',
              'trackMarkedAsPrioritized',
              'notifyChangedTags',
              'notifyTogglingPrioritizedFromPaymentPlan',
            ],
          },
          onError,
        },
      },
      removingDebt: {
        invoke: {
          id: 'removeDebt',
          src: removeDebt,
          onDone: {
            target: parentStateName,
            actions: ['setTransactionsAndPaymentPlan', 'updateDebtsContext', 'notifyRemovingDebt', 'notifyChangedTags', 'navigateBack'],
          },
          onError,
        },
      },
      updatingCreditorAgreement: {
        invoke: {
          id: 'editDebt',
          src: editDebt,
          onDone: {
            target: parentStateName,
            actions: [
              'showProgressPopover',
              'updateDebtsContext',
              'trackCreditorAgreementUpdate',
              options.navigation.update,
              'showMonthTransitionPaidOffPopover',
              'notifyEditingDebt',
            ],
          },
          onError,
        },
      },
    },
  };
}

const debtCrudStates = createDebtCrudStates('initial');

export default debtCrudStates;
