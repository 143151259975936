import { gql } from '@apollo/client';

export const $Debt$ = gql`
  fragment Debt on DebtOutput {
    debtId
    nickname
    type
    status
    isDelinquent
    paymentDueDate
    minMonthlyPayment
    lastPaymentMade
    statementBalance
    interestRate
    daysDelinquent
    isPrioritized
    creditorAgreement
    debtBudget
    dateUpdated
    dateCreated
  }
`;

export const $DebtSnapshot$ = gql`
  fragment DebtSnapshot on DebtSnapshotOutput {
    isDelinquent
    minMonthlyPayment
    statementBalance
    interestRate
    daysDelinquent
    isPrioritized
    creditorAgreement
    debtBudget
  }
`;

export const $Transactions$ = gql`
  fragment Transactions on TransactionsOutput {
    userId
    year
    month
    list {
      ... on TransactionsListOutput {
        date
        type
        amount
        debtId
        savingsLargePurchaseId
        savingsRainyDayId
        expense
        description
      }
    }
    dateUpdated
    dateCreated
  }
`;

export const $Savings$ = gql`
  fragment Savings on SavingsOutput {
    type
    status
    nickname
    amountToSave
    dueDate
    amountToContributeMonthly
    frequencyOfContribution
    amountAlreadySaved
    savingsId
    dateUpdated
    dateCreated
    hasDisplayedSavingsPlanMessage
    milestones {
      ... on MilestoneOutput {
        type
        metadata
        lastDisplayedAt
        shouldBeDisplayed
      }
    }
    month
    year
    lastEdited
  }
`;

export const $Activity$ = gql`
  fragment Activity on ActivityOutput {
    activityId
    status
    type
    answers
    flowMetadata
    savingsLargePurchaseId
    dateUpdated
    dateCreated
  }
`;

export const $Expense$ = gql`
  fragment Expense on ExpenseOutput {
    expenseId
    vertical
    flowMetadata
    myPlanCompletedSteps
    answers
    status
    dateUpdated
    dateCreated
    lastEdited
  }
`;

export const $Goal$ = gql`
  fragment Goal on GoalOutput {
    goalId
    flowMetadata
    nickname
    answers
    status
    type
    dateUpdated
    dateCreated
  }
`;

export const $GoalWithActivities$ = gql`
  ${$Goal$}
  ${$Activity$}
  fragment GoalWithActivities on GoalOutput {
    ...Goal
    activities {
      ... on ActivityOutput {
        ...Activity
      }
    }
  }
`;

export const $PaymentPlan$ = gql`
  ${$DebtSnapshot$}
  ${$Activity$}
  fragment PaymentPlan on PaymentPlanOutput {
    userId
    year
    month
    amountsPaid {
      ... on AmountPaidWithDebtSnapshot {
        debtId
        amount
        hasContactedCreditor
        debtSnapshot {
          ...DebtSnapshot
        }
      }
      ... on AmountPaid {
        debtId
        amount
        hasContactedCreditor
      }
    }
    availableFunds
    task_getFreeCreditCounseling
    task_callAllYourCreditors
    popover_dismissedMarkAsPaid
    popover_dismissedProgress
    popover_dismissedOtherPaybackPlanOption
    isDisclaimerDisplayed
    hasUpdatedAvailableFunds
    activities {
      ... on ActivityOutput {
        ...Activity
      }
    }
    dateUpdated
    dateCreated
    status
    lastEdited
  }
`;

export const $Expenses$ = gql`
  fragment Expenses on ExpensesOutput {
    rent
    transportation
    utilities
    groceries
    clothing
    entertainment
    insurance
    phone
    services
    childcare
    adultcare
    savings
    otherExpenses
    personalCare
    cableWifi
    healthServices
    emergencyFund
    largePurchase
    educationSavings
    retirementSavings400
    retirementSavingsIra
    autoInsurance
    homeInsurance
    renter
    healthInsurance
    disabilityInsurance
    lifeInsurance
    pets
    workExpenses
    charity
    homeServices
    eatingOut
    travel
  }
`;

export const $Budget$ = gql`
  ${$Expenses$}
  fragment Budget on BudgetOutput {
    expenses {
      ...Expenses
    }
    customExpenses {
      ... on CustomExpenses {
        id
        name
        amount
        category
        bucket
      }
    }
    netMonthlyIncome
    monthlyModalLastDisplayed
    incompleteDebtExpensesModalDateDisplayed
    updatedFromSavingsFlag
    updatedFromDebtManagerFlag
    status
    dateUpdated
    lastEdited
  }
`;

export const $AdvancePaymentPlan$ = gql`
  ${$Debt$}
  ${$PaymentPlan$}
  fragment AdvancePaymentPlan on AdvancePaymentPlanOutput {
    paymentPlan {
      ...PaymentPlan
    }
    archivedPaymentPlan {
      ...PaymentPlan
    }
    debts {
      ...Debt
    }
  }
`;

export const $BudgetPaymentPlan$ = gql`
  ${$Budget$}
  ${$PaymentPlan$}
  ${$Debt$}
  fragment BudgetPaymentPlan on BudgetPaymentPlanOutput {
    budget {
      ...Budget
    }
    paymentPlan {
      ...PaymentPlan
    }
    debts {
      ...Debt
    }
  }
`;

export const $BudgetSnapshot$ = gql`
  ${$Expenses$}
  fragment BudgetSnapshot on BudgetSnapshotOutput {
    expenses {
      ...Expenses
    }
    customExpenses {
      ... on CustomExpenses {
        id
        name
        amount
        category
        bucket
      }
    }
    netMonthlyIncome
    monthlyModalLastDisplayed
    incompleteDebtExpensesModalDateDisplayed
    updatedFromSavingsFlag
    updatedFromDebtManagerFlag
    status
    year
    month
  }
`;
