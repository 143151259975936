/* eslint-disable */
// @ts-nocheck

import { findDOMNode } from 'react-dom';
import { ReactInstance } from 'react';

import keyCode from '@/util/keyCode';

import { TObject } from '@/types/common';

type TGenericProps = TObject<string | number | boolean>;

export function isEventFromHandle(e: Event, handles: TObject<ReactInstance | null | undefined>) {
  try {
    /* eslint-disable react/no-find-dom-node */
    return Object.keys(handles).some(key => e.target === findDOMNode(handles[key]));
    /* eslint-enable react/no-find-dom-node */
  } catch (error) {
    return false;
  }
}

export function isValueOutOfRange(value, { min, max }) {
  return value < min || value > max;
}

export function isNotTouchEvent(e: TouchEvent) {
  return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0);
}

export function getClosestPoint(
  val,
  { marks, step, min, max }: { marks: TGenericProps; step: number; min: number; max: number }
) {
  const points = Object.keys(marks).map(parseFloat);
  if (step !== null) {
    const maxSteps = Math.floor((max - min) / step);
    const steps = Math.min((val - min) / step, maxSteps);
    const closestStep = Math.round(steps) * step + min;
    points.push(closestStep);
  }
  const diffs = points.map(point => Math.abs(val - point));
  return points[diffs.indexOf(Math.min(...diffs))];
}

export function getPrecision(step: number) {
  const stepString = step.toString();
  let precision = 0;
  if (stepString.indexOf('.') >= 0) {
    precision = stepString.length - stepString.indexOf('.') - 1;
  }
  return precision;
}

export const getMousePosition = (e: Event) => e.pageX;

export const getTouchPosition = (e: TouchEvent) => e.touches[0].pageX;

export function getHandleCenterPosition(handle: HTMLElement) {
  const coords = handle.getBoundingClientRect();
  return window.pageXOffset + coords.left + coords.width * 0.5;
}

export function ensureValueInRange(val, { max, min }) {
  if (val <= min) {
    return min;
  }
  if (val >= max) {
    return max;
  }
  return val;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ensureValuePrecision(val, props: { step: number; [x: string]: any }) {
  const { step } = props;
  const closestPoint = isFinite(getClosestPoint(val, props)) ? getClosestPoint(val, props) : 0; // eslint-disable-line
  return step === null ? closestPoint : parseFloat(closestPoint.toFixed(getPrecision(step)));
}

export function pauseEvent(e: Event) {
  e.stopPropagation();
  e.preventDefault();
}

export function calculateNextValue(func: string, value, props: TGenericProps) {
  const operations = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    increase: (a: number, b: number) => a + b,
    decrease: (a: number, b: number) => a - b,
  };

  const indexToGet = operations[func](Object.keys(props.marks).indexOf(JSON.stringify(value)), 1);
  const keyToGet = Object.keys(props.marks)[indexToGet as number];

  if (props.step) {
    return operations[func](value, props.step);
  }

  if (!!Object.keys(props.marks).length && !!props.marks[keyToGet]) {
    return props.marks[keyToGet];
  }
  return value;
}

export function getKeyboardValueMutator(e: Event) {
  switch (e.keyCode) {
    case keyCode.UP:
    case keyCode.RIGHT:
      return (value, props: TGenericProps) => calculateNextValue('increase', value, props);

    case keyCode.DOWN:
    case keyCode.LEFT:
      return (value, props: TGenericProps) => calculateNextValue('decrease', value, props);

    case keyCode.END:
      return (value, props: TGenericProps) => props.max;
    case keyCode.HOME:
      return (value, props: TGenericProps) => props.min;
    case keyCode.PAGE_UP:
      return (value: number, props: TGenericProps) => value + (props.step as number) * 2;
    case keyCode.PAGE_DOWN:
      return (value: number, props: TGenericProps) => value - props.step * 2;

    default:
      return undefined;
  }
}
