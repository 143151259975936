/* eslint-disable */
// @ts-nocheck

import { householdIncomeQ, loanAmountQ } from '@/domain/questions';
import { getAnswer } from '@/domain/questions/util';
import {
  FUNDING_FLOW_OPTIONS,
  BASE_PATH_PERSONAL_LOANS_FLOW,
  PERSONAL_LOANS_FLOW_ABOUT_PERSONAL_LOANS_PATH,
  PERSONAL_LOANS_FLOW_PROS_AND_CONS_PATH,
  PERSONAL_LOANS_FLOW_COST_ESTIMATE_PATH,
  PERSONAL_LOANS_FLOW_AFFORDABILITY_PATH,
  PERSONAL_LOANS_FLOW_HOW_TO_GET_PATH,
} from '@/domain/paths';

import i18n from '@/libs/i18n';

import { toUSD } from '@/util/currency';
import { trans } from '@/util/i18n';

import AboutPersonalLoans from '@/pages/PersonalLoansFlow/AboutPersonalLoans';
import ProsAndCons from '@/pages/PersonalLoansFlow/ProsAndCons';
import CostEstimate from '@/pages/PersonalLoansFlow/CostEstimate';
import Affordability from '@/pages/PersonalLoansFlow/Affordability';
import HowToGet from '@/pages/PersonalLoansFlow/HowToGet';

import IconLink from '@/components/IconLink';
import AuthControls from '@/components/AuthControls';

import { loanInterestRateQ, loanRepayPeriodQ } from './questions';

const header = {
  renderLeftItem: () => (
    <IconLink href={FUNDING_FLOW_OPTIONS} icon="th">
      {trans('payment-options')}
    </IconLink>
  ),
  renderCenterItem: () => trans('get-a-personal-loan'),
  renderRightItem: () => <AuthControls />,
};

const sidebar = [
  {
    id: 'about-personal-loans',
    title: trans('about-personal-loans'),
  },
  {
    id: 'pros-and-cons',
    title: trans('pros-and-cons'),
  },
  {
    id: 'cost-estimate',
    title: trans('cost-estimate'),
  },
  {
    id: 'affordability',
    title: trans('affordability'),
  },
  { id: 'how-to-get', title: trans('how-to-get-a-personal-loan') },
];

const subheader = {
  hasPartialCoverage: () => false,
  getContent: state => {
    const loanAmount = getAnswer(loanAmountQ, state);

    return i18n.t('borrowing-amount', { loanAmount: toUSD(loanAmount, 0) });
  },
};

const steps = {
  'about-personal-loans': {
    id: 'about-personal-loans',
    path: PERSONAL_LOANS_FLOW_ABOUT_PERSONAL_LOANS_PATH,
    sidebarItemId: 'about-personal-loans',
    component: AboutPersonalLoans,
    subheader,
  },
  'pros-and-cons': {
    id: 'pros-and-cons',
    path: PERSONAL_LOANS_FLOW_PROS_AND_CONS_PATH,
    sidebarItemId: 'pros-and-cons',
    component: ProsAndCons,
    subheader,
  },
  'cost-estimate': {
    id: 'cost-estimate',
    path: PERSONAL_LOANS_FLOW_COST_ESTIMATE_PATH,
    sidebarItemId: 'cost-estimate',
    questions: [loanInterestRateQ, loanRepayPeriodQ],
    renderQuestions: false,
    component: CostEstimate,
    subheader,
  },
  affordability: {
    id: 'affordability',
    path: PERSONAL_LOANS_FLOW_AFFORDABILITY_PATH,
    sidebarItemId: 'affordability',
    questions: [householdIncomeQ],
    renderQuestions: false,
    component: Affordability,
    subheader,
  },
  'how-to-get': {
    id: 'how-to-get',
    path: PERSONAL_LOANS_FLOW_HOW_TO_GET_PATH,
    sidebarItemId: 'how-to-get',
    component: HowToGet,
    subheader,
  },
};

const initialState = {
  stepId: 'about-personal-loans',
};

const personalLoansFlowConfig = {
  name: 'personalLoansFlow',
  isFlowEnabled: () => true, // always enabled
  assessment: {
    editLink: PERSONAL_LOANS_FLOW_ABOUT_PERSONAL_LOANS_PATH,
    title: trans('assessment-personal-loan'),
  },
  path: `${BASE_PATH_PERSONAL_LOANS_FLOW}/:id`,
  steps,
  sidebar,
  header,
  reducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'about-personal-loans':
        return { ...state, stepId: 'pros-and-cons' };
      case 'pros-and-cons':
        return { ...state, stepId: 'cost-estimate' };
      case 'cost-estimate':
        return { ...state, stepId: 'affordability' };
      case 'affordability':
        return { ...state, stepId: 'how-to-get' };
      case 'how-to-get':
      case 'personal-assessment':
        return { ...state, nextLocation: { pathname: FUNDING_FLOW_OPTIONS } };
      default:
        return initialState;
    }
  },
  reverseReducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'about-personal-loans':
        return { ...state, nextLocation: { pathname: FUNDING_FLOW_OPTIONS } };
      case 'pros-and-cons':
        return { ...state, stepId: 'about-personal-loans' };
      case 'cost-estimate':
        return { ...state, stepId: 'pros-and-cons' };
      case 'affordability':
        return { ...state, stepId: 'cost-estimate' };
      case 'how-to-get':
        return { ...state, stepId: 'affordability' };
      default:
        return initialState;
    }
  },
};

export default personalLoansFlowConfig;
