/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import { getAnswer } from '@/domain/questions/util';

import useUserState from '@/hooks/useUserState';

import ContainerWithStickyContent from '@/components/ContainerWithStickyContent';
import Heading from '@/components/Heading';
import Divider from '@/components/Divider';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';
import CustomContainer from '@/components/CustomContainer';

import calculatorImage from '@/images/calculatorIcon.svg';

import {
  amountInRetirementQ,
  householdIncomeBFRQ,
  loanAmountBFRQ,
  loanInterestRateQ,
  loanRepayPeriodQ,
} from '../questions';
import { calculateMonthlyLoanPayment } from '../calcsSelectors';

import Form from './Form';
import Results from './Results';

export const AffordabilityCheck = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-unplanned');
  const { state, loading, error } = useUserState();
  if (loading) return null;
  if (error) return null;

  const amountNeeded = getAnswer(loanAmountBFRQ, state);
  const loanInterestRate = getAnswer(loanInterestRateQ, state);
  const loanPaybackTerm = getAnswer(loanRepayPeriodQ, state);
  const retirementAccountBalance = getAnswer(amountInRetirementQ, state);

  const monthlyLoanPayment = calculateMonthlyLoanPayment({
    amountNeeded,
    retirementAccountBalance,
    loanInterestRate,
    loanPaybackTerm,
  });

  const initialValues = {
    [householdIncomeBFRQ.id]: getAnswer(householdIncomeBFRQ, state),
  };

  return (
    <CustomContainer noMargin verticallyPadded>
      <Heading as="h1" content={t('affordability-check.heading')} />
      <QuestionsForm fullWidth backButton={false} initialValues={initialValues}>
        {({ values }) => {
          const answerWrapper = fromAssessment ? state : { answers: values };

          return (
            <>
              <ContainerWithStickyContent
                disableSticky={fromAssessment}
                leftSideContent={<Form fromAssessment={fromAssessment} monthlyLoanPayment={monthlyLoanPayment} />}
                rightSideContent={<Results answerWrapper={answerWrapper} monthlyLoanPayment={monthlyLoanPayment} />}
              />
              {fromAssessment ? null : (
                <WizardUpNext imageSrc={calculatorImage}>{t('affordability-check.wizard')}</WizardUpNext>
              )}
            </>
          );
        }}
      </QuestionsForm>
    </CustomContainer>
  );
};

export default AffordabilityCheck;
