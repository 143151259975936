/* eslint-disable */
// @ts-nocheck
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { trans } from '@/util/i18n';

import CustomContainer from '@/components/CustomContainer';
import Divider from '@/components/Divider';
import Heading from '@/components/Heading';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';
import BigImageList from '@/components/BigImageList';
import Accordion from '@/components/Accordion';

import calculatorImage from '@/images/calculatorIcon.svg';

const prosItems = [
  {
    key: 'flexible-repayments',
    header: trans('i18n-credit:flexible-payments'),
    description: trans('i18n-credit:flexible-payments.description'),
  },
  {
    key: 'easy-to-access',
    header: trans('i18n-credit:easy-to-access'),
    description: trans('i18n-credit:easy-to-access.description'),
  },
];

const consItems = [
  {
    key: 'total-cost',
    header: trans('i18n-credit:total-cost'),
    description: trans('i18n-credit:total-cost.description'),
  },
  {
    key: 'impact-to-credit-score',
    header: trans('i18n-credit:impact-to-credit-score'),
    description: trans('i18n-credit:impact-to-credit-score.description'),
  },
  {
    key: 'potential-default',
    header: trans('i18n-credit:potential-default'),
    description: trans('i18n-credit:potential-default.description'),
  },
];

const Pros = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading as="h1">{t('pros')}</Heading>
      <Divider hidden />
      <BigImageList testId="creditCardPros" items={prosItems} />
    </>
  );
};

const Cons = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading as="h1">{t('cons')}</Heading>
      <Divider hidden />
      <BigImageList testId="creditCardCons" items={consItems} />
    </>
  );
};

export const ProsAndConsContent = ({ fromAssessment }) => {
  const { t } = useTranslation('i18n-credit');

  return (
    <>
      <Heading as="h1">{t('pros-and-cons.heading')}</Heading>
      <Grid relaxed stackable columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Pros />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Cons />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Heading as="h5">{t('pros-and-cons.subheading')}</Heading>
      <Accordion
        fluid
        styled
        panels={[
          {
            key: 'current-debts',
            active: fromAssessment,
            title: trans('i18n-credit:debts.current.title'),
            content: {
              content: trans('i18n-credit:debts.current.content'),
            },
          },
          {
            key: 'duration-of-introductory-offer',
            active: fromAssessment,
            title: trans('i18n-credit:introductory-offer.duration.title'),
            content: {
              content: trans('i18n-credit:introductory-offer.duration.content'),
            },
          },
        ]}
      />
    </>
  );
};

const ProsAndCons = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-credit');

  return (
    <CustomContainer noMargin verticallyPadded>
      <ProsAndConsContent fromAssessment={fromAssessment} />
      {fromAssessment ? null : (
        <QuestionsForm fullWidth backButton={false}>
          <WizardUpNext imageSrc={calculatorImage}>{t('pros-and-cons.up-next.wizard')}</WizardUpNext>
        </QuestionsForm>
      )}
    </CustomContainer>
  );
};

export default ProsAndCons;
