/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import CustomContainer from '@/components/CustomContainer';
import Divider from '@/components/Divider';
import Heading from '@/components/Heading';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';
import Paragraph from '@/components/Paragraph';
import VerticalSpacing from '@/components/VerticalSpacing';

import scalesImage from '@/images/Scales-Icon.svg';

export const AboutCreditCardsContent = () => {
  const { t } = useTranslation('i18n-credit');

  return (
    <>
      <Heading as="h1">{t('about.credit-cards')}</Heading>

      <Heading as="h2">{t('low-interest.credit-cards')}</Heading>
      <Paragraph noHorizontalMargin>{t('low-interest.credit-cards.paragraph')}</Paragraph>

      <VerticalSpacing />

      <Heading as="h2">{t('other-cards')}</Heading>
      <Paragraph noHorizontalMargin>{t('other-cards.paragraph')}</Paragraph>

      <VerticalSpacing />
    </>
  );
};

const AboutCreditCards = ({ fromAssessment = false }) => {
  const { t } = useTranslation('i18n-credit');

  return (
    <CustomContainer noMargin verticallyPadded>
      <AboutCreditCardsContent />
      {fromAssessment ? null : (
        <QuestionsForm fullWidth backButton={false}>
          <WizardUpNext imageSrc={scalesImage}>{t('about-credit-cards.up-next.wizard')}</WizardUpNext>
        </QuestionsForm>
      )}
    </CustomContainer>
  );
};

export default AboutCreditCards;
