import { useTranslation } from 'react-i18next';
import { Accordion as DefaultAccordion, AccordionPanelProps } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';

import { Clickable } from '@/components/button';

import classes from './styles.module.less';

export type AccordionT = {
  /** allows the component to take up 100% width of its parent */
  fluid: boolean;
  /** array with panels items' configurations */
  panels: AccordionPanelProps[];
  /** whether the component is styled */
  styled: boolean;
};

/**
 * Accordion allows users to toggle the display of sections of content
 */
const Accordion: FunctionComponent<AccordionT> = ({ fluid, panels, styled }) => {
  const { t } = useTranslation();
  const accordionPanels = panels.map(({ active, title, ...rest }) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const accordionAriaLabel = `${title as string} - ${t('toggle-accordion')}`;
    const activeProp = active === true ? { active } : {};

    return {
      ...rest,
      ...activeProp,
      title: {
        content: (
          <Clickable className={classes.accordionTitle} aria-label={accordionAriaLabel}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore:next-line */}
            <strong>{title}</strong>
          </Clickable>
        ),
      },
    };
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  return <DefaultAccordion fluid={fluid} panels={accordionPanels} styled={styled} />;
};

export default Accordion;
