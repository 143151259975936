/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, Redirect } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import queryString from 'query-string';

import { AUTHENTICATION_CALLBACK_PATH, LANDING_PAGE_PATHS, DASHBOARD_PAGE_PATH, WELCOME_PAGE } from '@/domain/paths';
import { isBudgetCreated } from '@/domain/budget';
import { isBudgetLoaded } from '@/domain/budget';
import { hasAnySavingsPlan } from '@/domain/savings';
import { AppContext } from '@/services/appContext';
import { SessionContext } from '@/services/session';

import { isSessionFromLoginProvider, retrieveRedirectLocation } from '@/util/authentication';
import useUserState from '@/hooks/useUserState';
import useDashboardState from '@/hooks/useDashboardState';
import { replaceExcludedPath } from '@/util/paths';
import { envOrDefault } from '@/util/env';

import ErrorScreen from '@/pages/ErrorScreen';

import Image from '@/components/Image';
import Paragraph from '@/components/Paragraph';
import Heading from '@/components/Heading';
import FakeLoadingDelay from '@/components/fake-loading-delay';
import CustomContainer from '@/components/CustomContainer';
import FeatureFlag from '@/components/FeatureFlag';

import logo from '@/images/Ascend-no-text.svg';

import { calculateDebtManagerState } from '../DashboardOverhaul/DebtProjectionsWidget/helpers';
import { DEBT_MANAGER_STATES } from '../../../../common/model/debt';
import classes from './Authentication.module.less';

const PromoIframe = () => {
  const AUTH_PROMO_IFRAME_URL = envOrDefault('REACT_APP_AUTH_PROMO_IFRAME_URL', '');
  return (
    <FeatureFlag noMessage feature="ABACUS_FLAG_AUTH_PROMO_IFRAME">
      <iframe
        src={AUTH_PROMO_IFRAME_URL}
        className={classes.hidden}
        width="0"
        height="0"
        tabIndex="-1"
        title="aarpPromo"
      />
    </FeatureFlag>
  );
};

const LoaderScreen = () => {
  const { t } = useTranslation();

  return (
    <CustomContainer size="medium" verticallyPadded>
      <Image aria-hidden={false} src={logo} size="tiny" alt={t('money-map-logo')} />
      <Heading as="h1">{t('welcome-back')}</Heading>
      <Paragraph noHorizontalMargin>{t('where-you-left-off')}</Paragraph>
      <PromoIframe />
    </CustomContainer>
  );
};

const SpinnerScreen = () => (
  <div>
    <Icon loading name="spinner" size="big" color="red" className={classes.spinner} />
  </div>
);

const isRecoverableAuthenticationError = location => {
  const { error_description: errorDescription } = queryString.parse(location.search);
  const recoverableErrorDescriptions = [
    'Request expired',
    'The client specified not to prompt, but the user is not logged in.; error=login_required',
  ];

  return recoverableErrorDescriptions.includes(errorDescription);
};

const hasUserOnboardAnyTool = (appState, expensesData) => {
  if (isBudgetCreated(appState.context)) return true;
  if (hasAnySavingsPlan(appState.context)) return true;

  const { expenses } = expensesData;
  if (expenses?.length > 0) return true;

  const debtManagerState = calculateDebtManagerState(appState);
  if (debtManagerState !== DEBT_MANAGER_STATES.EMPTY) return true;

  return false;
};

const Authentication = ({ location }) => {
  const { signInHandler, initialized, isAuthenticated } = React.useContext(SessionContext);
  const { appState } = React.useContext(AppContext);
  const expensesData = useUserState();
  const budgetIsLoaded = isBudgetLoaded(appState.context);
  const { loading: loadingData } = useDashboardState();

  const redirectPathExclusions = [AUTHENTICATION_CALLBACK_PATH, ...LANDING_PAGE_PATHS];
  const sessionRedirectLocation = retrieveRedirectLocation();
  let redirectLocation = replaceExcludedPath(sessionRedirectLocation, redirectPathExclusions, DASHBOARD_PAGE_PATH);

  redirectLocation = hasUserOnboardAnyTool(appState, expensesData) ? redirectLocation : WELCOME_PAGE;

  const { error } = queryString.parse(location.search);
  const retry = () => {
    // Only call the sign in handler if SessionContext is initialized
    if (initialized) {
      signInHandler({ redirectLocation });
    }
  };

  const goBack = () => {
    return <Redirect to={redirectLocation} />;
  };

  if (error) {
    // Handle recoverable auth flow error types
    if (isRecoverableAuthenticationError(location)) {
      retry();
      return <SpinnerScreen />;
    }
    // Render the error screen if some error came back from Cognito/AARP flow
    // Pass the error code to the screen to define what to show
    return <ErrorScreen auth retry={retry} goBack={goBack} />;
  }

  // Show loading spinner if we are waiting for a new auth flow to begin
  if (!isSessionFromLoginProvider()) {
    return <SpinnerScreen />;
  }

  // Standard loading page, dsiplayed for a minimum of 4 seconds (increaded to allow time for iFrame to load).
  return (
    <FakeLoadingDelay
      loadingChildren={<LoaderScreen />}
      timeout={4000}
      mayRenderChildren={isAuthenticated && budgetIsLoaded && !loadingData}
    >
      <Redirect to={redirectLocation} />
    </FakeLoadingDelay>
  );
};

export default withRouter(Authentication);
