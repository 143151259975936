import * as React from 'react';
import { FormikHandlers, FormikProps } from 'formik';

import { isQuestionConditionallyRendered } from '@/domain/questions/util';

import Question from '@/components/Question';
import ConditionalQuestion from '@/components/ConditionalQuestion';
import Form from '@/components/Form';

import { TQuestionConfig, TSection } from '@/types/domain/question';
import { TObject } from '@/types/common';

import SectionHeading from '../SectionHeading';
import VerticalSpacing from '../VerticalSpacing';

import { createDefaultSubmitHandler, createValidationSchema } from './util';

type TFormBoilerplateProps = {
  children?: React.ReactNode | ((args: unknown) => React.ReactNode);
  backButton?: React.ComponentType<{ isSubmitting: boolean }> | React.ReactNode;
  initialValues?: unknown;
  getInitialFormValues: (
    questions: TQuestionConfig[],
    state: unknown,
    formValues: unknown,
    externalState: unknown
  ) => unknown;
  state?: unknown;
  externalState?: TObject<string | number | boolean>;
  handleSubmit?: FormikHandlers['handleSubmit'];
  questions?: TQuestionConfig[];
  sections?: TSection[];
  renderQuestions?: boolean;
  fieldsToBeDisabled?: number[];
  name: string;
  className?: string;
  getCurrentValues?: (formValues: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef?: React.Ref<FormikProps<any>>;
};

/**
 * Reduces boilerplate for setting up smart forms. Sets up the initial values and conditional rendering of questions.
 * Automatically renders the questions unless a flag is raised to prevent that.
 * @param {Object} props
 * @param {React.Node || function} props.children - can be a function that receives form values on every change
 * @param {React.Node} props.backButton - a back button component to be rendered
 * @param {Object} props.initialValues - manually set initial values (skips building them automatically based on state)
 * @param {function} props.getInitialFormValues - a function that returns the initial values based on user state
 * @param {object} props.state - application state from where the initial values can be read
 * @param {object} props.externalState - application state used to determine what conditional questions to render
 * @param {Promise} props.handleSubmit - a promise that successfully resolves when form values have been stored
 * @param {QuestionConfigT[]} props.questions - list of question configs to be used in the form
 * @param {boolean} props.renderQuestions - flag to prevent automatic rendering if set to false, used for complex forms
 * @param {string} props.name - form name, present in tests
 * @param {boolean} props.focusFirstQuestion - default false, should form focus the first question on mount
 * @param {boolean} props.fieldsToBeDisabled - an array indicating the index of fields to be disabled
 * @param {function} props.getCurrentValues - Function to get form values real time
 * @param {string} props.formRef - Ref for the form
 */
const FormBoilerplate = ({
  children,
  backButton: BackButton,
  initialValues,
  getInitialFormValues,
  state,
  externalState,
  handleSubmit,
  questions = [],
  sections = [],
  renderQuestions,
  fieldsToBeDisabled = [],
  name,
  className,
  getCurrentValues,
  formRef,
}: TFormBoilerplateProps) => {
  // const questions = mapQuestions(propsQuestions);
  // this allows us to reinitialize the form when a conditionally rendered question is updated
  const [formValues, setFormValues] = React.useState({});
  // This allows us to skip some component side effects if an unmount will happen
  const isMounted = React.useRef(false);
  // current form values are used in case of conditionally rendered questions, first time called with {}

  // if sections is used we need to extract the questions from the sections
  const sectionQuestions = sections?.length > 0 ? sections.flatMap(section => section.questions) : [];
  // merge the questions from the sections with the questions from the props
  const internalQuestions = sectionQuestions.length > 0 ? [...questions, ...sectionQuestions] : [...questions];

  const initialFormValues = initialValues || getInitialFormValues(internalQuestions, state, formValues, externalState);

  const handleFormSubmit = createDefaultSubmitHandler(
    handleSubmit,
    internalQuestions,
    externalState,
    isMounted.current
  );
  const selectedFormValues = Object.keys(formValues).length === 0 ? initialFormValues : formValues;
  const validationSchema = createValidationSchema(internalQuestions, selectedFormValues, externalState);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  /*
  // ** AUTOFOCUS ON FIRST FORM FIELD DISABLED - INCOMPATIBLE CU ON BLUR VALIDATION **

  const [autofocused, setAutofocused] = React.useState(false);
  React.useEffect(() => {
    if (!focusFirstQuestion || autofocused) {
      return;
    }

    if (!questions || !questions.length) {
      return;
    }

    const firstQuestion = questions[0];
    let firstInputId;

    // if question is complex, take the first subquestion
    if (isQuestionConditionallyRendered(firstQuestion)) {
      firstInputId = firstQuestion.getQuestions(formValues, externalState)[0].id;
    } else if (isQuestionCompound(firstQuestion)) {
      firstInputId = firstQuestion.questions[0].id;
    } else {
      firstInputId = firstQuestion.id;
    }

    const firstInputElement = document.getElementById(firstInputId);

    if (firstInputElement) {
      firstInputElement.focus();
      setAutofocused(true);
    }
  }, [autofocused, externalState, focusFirstQuestion, formValues, questions]);
  */

  return (
    <Form
      className={className}
      enableReinitialize
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
      formTestId={`${name}Form`}
      initialValues={initialFormValues}
      formRef={formRef}
    >
      {({ values, isSubmitting, isValid, isValidating, errors }) => {
        if (getCurrentValues) getCurrentValues(values); // Get realtime form values

        const displayQuestions =
          questions != null && renderQuestions
            ? questions.map((question, qIndex) => {
                if (isQuestionConditionallyRendered(question)) {
                  return (
                    <ConditionalQuestion
                      key={`question-${qIndex}`}
                      updateForm={setFormValues}
                      getQuestions={question.getQuestions}
                      values={values}
                      externalState={externalState}
                    />
                  );
                }
                return (
                  <Question
                    question={question}
                    key={`question-${qIndex}`}
                    disabled={fieldsToBeDisabled.includes(qIndex) || undefined}
                  />
                );
              })
            : null;

        const displaySections =
          sections?.length > 0 && renderQuestions
            ? sections.map((section, sIndex) => {
                return (
                  <div key={`${sIndex}-${section.title}`}>
                    <SectionHeading title={section.title} subtitle={section.subtitle} />

                    {section.questions != null && renderQuestions
                      ? section.questions.map((question, qIndex) => {
                          if (isQuestionConditionallyRendered(question)) {
                            return (
                              <ConditionalQuestion
                                key={`question-${qIndex}`}
                                updateForm={setFormValues}
                                getQuestions={question.getQuestions}
                                values={values}
                                externalState={externalState}
                              />
                            );
                          }
                          return (
                            <Question
                              question={question}
                              key={`question-${qIndex}`}
                              disabled={fieldsToBeDisabled.includes(qIndex) || undefined}
                            />
                          );
                        })
                      : null}
                  </div>
                );
              })
            : null;

        return (
          <>
            {questions?.length > 0 && displayQuestions}
            {sections?.length > 0 && displaySections}
            {/* allow children to be a function so that we can pass down an updateForm function in order
            to re-render the form in circumstances like using a ConditionalQuestion */}
            {typeof children === 'function'
              ? children({ updateForm: setFormValues, values, isSubmitting, isValid, isValidating, errors })
              : children}
            {typeof BackButton === 'function' ? (
              <>
                <VerticalSpacing size={4} />
                <BackButton isSubmitting={isSubmitting} />
              </>
            ) : null}
          </>
        );
      }}
    </Form>
  );
};

export default FormBoilerplate;
