import { hasLoadedSavings } from '@/domain/savings';

import { not } from '@/util/function';

import { InitialContextT } from '..';

import {
  isSavingsOnboardingFlow,
  loadSavings,
  removeSavings,
  upsertSavings,
  upsertSavingsAndAddDeposit,
  upsertSavingsBatch,
} from './helpers';

import type { AnyEventObject, StateNodeConfig } from 'xstate';

const SAVINGS_PLAN_INTRO_DELAY = 7000;

const onError = {
  target: 'initial',
  actions: ['notifyError'],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const savingsMachineStates: StateNodeConfig<InitialContextT, any, AnyEventObject> = {
  id: 'savingsMachineStates',
  initial: 'initial',
  states: {
    initial: {
      always: [{ target: 'loadingSavings', cond: not(hasLoadedSavings) }],
      on: {
        UPSERT_SAVINGS: 'upsertingSavings',
        UPSERT_SAVINGS_ONLY: 'upsertingSavingsOnly',
        UPSERT_SAVINGS_AND_ADD_DEPOSIT: 'upsertingSavingsAndAddDeposit',
        REMOVE_SAVINGS: 'removingSavings',
        UPDATING_HAS_DISPLAYED_SAVINGS_PLAN_MESSAGE: 'updatingHasDisplayedSavingsPlanMessage',
        SAVINGS_INTEGRATED_FROM_BUDGET: 'savingsBudgetIntegration',
        SAVINGS_DELETE_FROM_BUDGET: 'savingsDeletionBudgetIntegration',
      },
    },
    loadingSavings: {
      invoke: [
        {
          id: 'loadingSavings',
          src: loadSavings,
          onDone: {
            target: 'initial',
            actions: ['setLoadedSavings'],
          },
          onError: {
            target: 'initial',
            actions: ['setLoadedSavings'],
          },
        },
      ],
    },
    upsertingSavings: {
      invoke: {
        id: 'upsertingSavings',
        src: upsertSavings,
        onDone: [
          {
            cond: isSavingsOnboardingFlow,
            target: 'savingsPlanIntro',
            actions: [
              'trackStartSavings',
              'trackFinishLargePurchaseOnboarding',
              'setSavings',
              'setBudget',
              'storeNewSavingsId',
              'navigateToSavingsPlanIntro',
            ],
          },
          {
            target: 'initial',
            actions: ['setSavings', 'setBudget', 'navigateToSavingsOverview'],
          },
        ],
        onError,
      },
    },
    upsertingSavingsOnly: {
      invoke: {
        id: 'upsertingSavingsOnly',
        src: upsertSavings,
        onDone: {
          target: 'initial',
          actions: ['setSavings'],
        },
        onError,
      },
    },
    upsertingSavingsAndAddDeposit: {
      invoke: {
        id: 'upsertingSavingsAndAddDeposit',
        src: upsertSavingsAndAddDeposit,
        onDone: [
          {
            target: 'initial',
            actions: ['setSavings', 'setTransactions', 'navigateToSavingsOverview'],
          },
        ],
        onError,
      },
    },
    removingSavings: {
      invoke: {
        id: 'removingSavings',
        src: removeSavings,
        onDone: {
          target: 'initial',
          actions: ['setDeletedSavings', 'navigateSavingsPostDelete'],
        },
        onError,
      },
    },
    savingsPlanIntro: {
      after: {
        // navigate to savings overview page after SAVINGS_PLAN_INTRO_DELAY seconds
        [SAVINGS_PLAN_INTRO_DELAY]: {
          target: 'initial',
          actions: ['navigateToSavingsOverview'],
        },
      },
    },
    updatingHasDisplayedSavingsPlanMessage: {
      invoke: {
        id: 'updatingHasDisplayedSavingsPlanMessage',
        src: upsertSavings,
        onDone: {
          target: 'initial',
          actions: ['setSavings'],
        },
        onError,
      },
    },
    savingsBudgetIntegration: {
      invoke: {
        id: 'savingsBudgetIntegration',
        src: upsertSavingsBatch,
        onDone: {
          target: 'initial',
          actions: ['setSavingsBatch', 'setBudget'],
        },
        onError,
      },
    },
    savingsDeletionBudgetIntegration: {
      invoke: {
        id: 'savingsDeletionBudgetIntegration',
        src: removeSavings,
        onDone: {
          target: 'initial',
          actions: ['setDeletedSavings', 'setBudget', 'navigateSavingsPostDelete'],
        },
        onError,
      },
    },
  },
};

export default savingsMachineStates;
