import React from 'react';

import type { AppMachineStateT, AppMachineSendT } from '@/domain/stateMachines';

import { getCurrentMonthYear, createMonthYearIterable, isDateGreaterThan } from '@/util/date';
import type { MonthYear } from '@/util/date';

type ContextType = {
  state?: AppMachineStateT;
  selectedExpenses: Record<string, unknown>[];
  selectedDateIndex?: number;
  defaultSelectedDateIndex?: number;
  transactionsRange?: MonthYear[];
  setSelectedExpenses?: React.Dispatch<React.SetStateAction<Record<string, unknown>[]>>;
  setSelectedDateIndex?: React.Dispatch<React.SetStateAction<number>>;
  sendEvent?: AppMachineSendT;
  remindersChosenDate?: Date;
  setRemindersChosenDate?: React.Dispatch<React.SetStateAction<Date>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAllCategoryCheckbox?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectAllCategoryCheckbox?: any;
  expensesCleared?: boolean;
  hasBudgetTransitioned?: boolean;
  setHasBudgetTransitioned?: React.Dispatch<React.SetStateAction<boolean>>;
  availableFunds?: number;
  setAvailableFunds?: React.Dispatch<React.SetStateAction<number>>;
};

type ProviderPropsT = {
  children: React.ReactNode;
  appState: AppMachineStateT;
  sendEvent: AppMachineSendT;
  selectedExpensesInitialValue?: Record<string, unknown>[];
};

const initialContextValues: ContextType = { selectedExpenses: [] };

export const BudgetContext = React.createContext<ContextType>(initialContextValues);
// TODO: Look into making this more obvious
const expenseClearingConditions = [
  'done.invoke.upsertingBudget',
  'done.invoke.updatingBudgetAndPaymentPlan',
  'done.invoke.updatingBudgetAndPaymentPlanDM',
  'done.invoke.updatingBudgetAndCreatingPaymentPlan',
  'done.invoke.editFlowBudgetAndPaymentPlanAvailableFunds',
  'done.invoke.editFlowBudgetAndCreatingPaymentPlanWithAvailableFunds',
  'done.invoke.updatingBudgetAndPaymentPlanSavings',
  'done.invoke.updatingBudgetAndCreatingPaymentPlanSavings',
];

const currentMonthYear = getCurrentMonthYear();

const BudgetProvider = ({ children, appState, sendEvent, selectedExpensesInitialValue = [] }: ProviderPropsT) => {
  const type = appState.event?.type;
  const budgetIsLoaded = appState.context.budget.isLoaded;

  const { transactions } = appState.context.budget;
  const startingTransaction = transactions?.[0];
  const startMonthYear = startingTransaction || currentMonthYear;
  // @ts-expect-error Need to check types on this one
  const rangeOfDates = isDateGreaterThan(startMonthYear, currentMonthYear) // @ts-expect-error Need to check types on this one
    ? createMonthYearIterable(currentMonthYear, startMonthYear, true) // @ts-expect-error Need to check types on this one
    : createMonthYearIterable(startMonthYear, currentMonthYear, true);
  const transactionsRange = Array.from(rangeOfDates);

  const defaultSelectedDateIndex = transactionsRange.length - 1;
  const [selectedExpenses, setSelectedExpenses] = React.useState(selectedExpensesInitialValue); // Items manually selected by the user
  const [selectedDateIndex, setSelectedDateIndex] = React.useState(defaultSelectedDateIndex);
  const shouldUpdateIndex = React.useRef(true);
  const shouldClearExpenses = expenseClearingConditions.includes(type);
  const [expensesCleared, setExpensesCleared] = React.useState(false);
  const [selectAllCategoryCheckbox, setSelectAllCategoryCheckbox] = React.useState({});
  const [hasBudgetTransitioned, setHasBudgetTransitioned] = React.useState(false);
  const [availableFunds, setAvailableFunds] = React.useState(0);

  const getDateFromSelectedIndex = (index: number) => {
    if (transactionsRange[index]) {
      const { month, year } = transactionsRange[index];
      return { month, year };
    }
    return null;
  };

  React.useEffect(() => {
    if (shouldClearExpenses) {
      setExpensesCleared(true);
      setSelectedExpenses([]);
    }
    return () => {
      setExpensesCleared(false);
    };
  }, [shouldClearExpenses]);

  React.useEffect(() => {
    if (budgetIsLoaded && shouldUpdateIndex.current) {
      shouldUpdateIndex.current = false;
      setSelectedDateIndex(transactionsRange.length - 1);
    }
    // Handles cases where the state machine is reinitialized
    // and we need to start fresh without unmounting the budget provider
    if (!budgetIsLoaded) {
      shouldUpdateIndex.current = true;
    }
  }, [transactionsRange, budgetIsLoaded]);

  // load archived budget each time selectedDateIndex changes
  React.useEffect(() => {
    if (budgetIsLoaded && selectedDateIndex !== undefined) {
      const { month, year } = getDateFromSelectedIndex(selectedDateIndex) || {};

      if (!month || !year) return;

      /* to query for the previous month on the carousel
       * so when we actually click on the previous month, it's already loaded
       * Eg: July currently selected, query for June
       */
      sendEvent({ type: 'LOAD_ARCHIVED_BUDGETS', payload: { month: `${+month - 1}`, year } });

      // check if the selected date is not the current month
      if (month === currentMonthYear.month && year === currentMonthYear.year) return;

      sendEvent({ type: 'LOAD_ARCHIVED_BUDGETS', payload: { month, year } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateIndex]);

  const contextValue = React.useMemo(
    () => ({
      state: appState,
      sendEvent,
      selectedExpenses,
      setSelectedExpenses,
      selectedDateIndex,
      setSelectedDateIndex,
      defaultSelectedDateIndex,
      transactionsRange,
      selectAllCategoryCheckbox,
      setSelectAllCategoryCheckbox,
      expensesCleared,
      hasBudgetTransitioned,
      setHasBudgetTransitioned,
      availableFunds,
      setAvailableFunds,
    }),
    [
      appState,
      sendEvent,
      selectedExpenses,
      selectedDateIndex,
      defaultSelectedDateIndex,
      transactionsRange,
      selectAllCategoryCheckbox,
      expensesCleared,
      hasBudgetTransitioned,
      availableFunds,
    ]
  );

  return <BudgetContext.Provider value={contextValue}>{children}</BudgetContext.Provider>;
};

export default BudgetProvider;
