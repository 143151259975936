/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';

import { isFlowCompleted } from '@/domain/WizardConfig';
import ratioOfMonthlyPaymentToMonthlyIncome from '@/domain/calcs/common/affordability';
import loanAmountCalc from '@/domain/calcs/borrowFromRetirementAccounts/calculateLoanAmount';
import { getAnswer } from '@/domain/questions/util';

import { amountInRetirementQ, loanAmountBFRQ } from './questions';
import { parseValues } from './util';

export const isRetirementFlowCompleted = ({ flowMetadata }, flowConfig) =>
  isFlowCompleted(flowMetadata[flowConfig.name], flowConfig);

export const calculateLoanAmount = createSelector(
  parseValues,
  // calc 1
  loanAmountCalc
);

export const isPartiallyCovered = answerWrapper => {
  const retirementAccountBalance = getAnswer(amountInRetirementQ, answerWrapper);
  const amountNeeded = getAnswer(loanAmountBFRQ, answerWrapper);

  if (!retirementAccountBalance || !amountNeeded) {
    return null;
  }

  const { maxAmountUserCanBorrow } = calculateLoanAmount({
    amountNeeded,
    retirementAccountBalance,
  });

  return maxAmountUserCanBorrow < amountNeeded;
};

export const calculateAffordability = createSelector(parseValues, ({ monthlyLoanPayment, monthlyHouseholdIncome }) =>
  // calc 8
  ratioOfMonthlyPaymentToMonthlyIncome({
    monthlyLoanPayment,
    monthlyHouseholdIncome,
  })
);
