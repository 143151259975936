import React from 'react';

import createQuestionFactory from '@/domain/questions/createQuestionFactory';
import { getAnswer } from '@/domain/questions/util';
import { getAnswerValidationRules } from '@/domain/questions/validation';

import { trans } from '@/util/i18n';

import RadioGroup from '@/components/FormField/RadioGroup';

const homeQuestionFactory = createQuestionFactory('home', getAnswerValidationRules);

export const homeRentOwnQ = homeQuestionFactory({
  name: 'homeRentOwn',
  title: <strong>{trans('i18n-questions:home-rent-own.title')}</strong>,
  component: RadioGroup,
  subtitle: trans('why-do-you-ask-income.rent-own-home'),
  defaultProps: {
    fields: [
      {
        label: trans('rent'),
        valueName: 'rent',
      },
      {
        label: trans('own'),
        valueName: 'own',
      },
    ],
  },
});

export const rentersInsuranceQ = homeQuestionFactory({
  name: 'hasRentersInsurance',
  title: <strong>{trans('i18n-questions:renters-insurance.title')}</strong>,
  subtitle: trans('why-do-you-ask-income.renters-insurance'),
  component: RadioGroup,
  defaultProps: {
    fields: [
      {
        label: trans('i18n-questions:answer.yes'),
        valueName: 1,
      },
      {
        label: trans('i18n-questions:answer.no'),
        valueName: 0,
      },
    ],
  },
});

export const conditionalHomeQ = {
  id: 'home conditional',
  getQuestions: formValues => {
    const rentOrOwnAnswer = getAnswer(homeRentOwnQ, { answers: formValues });

    if (rentOrOwnAnswer === 'rent') {
      return [homeRentOwnQ, rentersInsuranceQ];
    }

    return [homeRentOwnQ];
  },
};
