/* eslint-disable */
// @ts-nocheck
import { GOAL_STATUSES } from 'common/model/goal';

import {
  ADD_CREDIT_CARD_DEBT_GOAL_DETAILS,
  ADD_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS,
  ADD_OTHER_DEBT_GOAL_DETAILS,
  ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS,
  SAVINGS_DASHBOARD,
  ADD_SAVE_FOR_RETIREMENT_GOAL_DEPOSIT,
  SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH,
  ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS,
  ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS,
  EDIT_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS,
  EDIT_REDUCE_MY_EXPENSES_GOAL_DETAILS,
  EDIT_CREDIT_CARD_DEBT_GOAL_DETAILS,
  EDIT_STUDENT_LOAN_DEBT_GOAL_DETAILS,
  EDIT_OTHER_DEBT_GOAL_DETAILS,
  EDIT_SAVE_FOR_RETIREMENT_GOAL_DETAILS,
  BASE_PATH_LARGE_PURCHASE_SAVINGS,
} from '@/domain/paths';

import { trans } from '@/util/i18n';
import { removeDuplicateObjectFromArray } from '@/util/array';

import {
  unplannedExpenseIdentified,
  budgetBuilt,
  budgetUpdated,
  paymentPlanReviewed,
  paymentPlanCreated,
  actionOnMyPlanTook,
  tipsForReducingSavingsChecked,
  automaticContributionsRetirementSet,
  automaticContributionsLargePurchaseSet,
  separateSavingsAccountRetirementStarted,
  reliefOptionsExplored,
  isAllDebtsTracked,
  canShowContactCreditors,
  haveCreditorsBeenContacted,
  isSuggestedAmountMeasuredPaid,
  unplannedExpenseGoalDetailsAdded,
  reduceMyExpensesGoalDetailsAdded,
  creditCardDebtGoalDetailsAdded,
  studentLoanDebtGoalDetailsAdded,
  otherDebtGoalDetailsAdded,
  saveForRainyDayGoalUsedSavingsTool,
  saveForLargePurchaseGoalUsedSavingsTool,
  saveForRetirementGoalDetailsAdded,
  saveForRainyDayContributionAdded,
  saveForLargePurchaseContributionAdded,
  saveForRetirementContributionAdded,
  identifyUnplannedExpenseClick,
  buildYourBudgetClick,
  takeActionOnYourPlanClick,
  tipsForReducingSpendingClick,
  trackCurrentSpendingClick,
  takeActionOnReviewPlanClick,
  takeActionOnPaymentPlanClick,
  startSeparateSavingsAccountRainyDayClick,
  startSeparateSavingsAccountLargePurchaseClick,
  setAutomaticContributionsRainyDayClick,
  setAutomaticContributionsRetirementClick,
  setAutomaticContributionsLargePurchaseClick,
  startSeparateSavingsAccountRetirementClick,
  exploreReliefOptionsMeasuredClick,
  trackAllDebtsClick,
  addUnplannedExpenseGoalDetailsClick,
  addReduceMyExpensesGoalDetailsClick,
  addCreditCardDebtGoalDetailsClick,
  addStudentLoanDebtGoalDetailsClick,
  addOtherDebtGoalDetailsClick,
  addSaveForRetirementGoalDetailsClick,
  contactCreditorsClick,
  stayOnTrackWithCreditorsClick,
  paySuggestedAmountMeasuredClick,
  recordSaveForLargePurchaseContributionClick,
  recordSaveForRainyDayContributionClick,
  recordSaveForRetirementContributionClick,
  reviewLargePurchaseSavingsPlanClick,
  buildRainyDayFundSavingsPlanClick,
  buildLargePurchaseSavingsPlanClick,
  reviewSavingsPlanClick,
  unplannedExpenseCompletionPercentage,
  reduceMyExpensesCompletionPercentage,
  creditCardDebtGoalPercentage,
  studentLoanDebtGoalPercentage,
  otherDebtGoalPercentage,
  saveForRainyDayGoalPercentage,
  saveForRetirementGoalPercentage,
  unplannedExpenseManaged,
  reduceMyExpensesGoalCompleted,
  creditCardDebtGoalCompleted,
  studentLoanDebtGoalCompleted,
  otherDebtGoalCompleted,
  separateSavingsAccountLargePurchaseStarted,
  automaticContributionsRainyDaySet,
  separateSavingsAccountRainyDayStarted,
  currentSpendingTracked,
  saveForRainyDayGoalCompleted,
  saveForLargePurchaseGoalCompleted,
  saveForRetirementGoalCompleted,
  reviewSavingsPlanCheck,
  reviewLargePurchaseSavingsPlanCheck,
  saveForLargePurchasesGoalAveragePercentage,
} from './utils';

export const ACTIVITY_ACTION_TYPES = {
  EXPENSE: 'expense',
  CONTENT_PAGE: 'contentPage',
  CONTENT_MODAL: 'contentModal',
  OTHER: 'other',
};

export const ACTIVITY_IDS = {
  IDENTIFY_UNPLANNED_EXPENSE_MEASURED: 'identifyUnplannedExpenseMeasured',
  BUILD_YOUR_BUDGET_MEASURED: 'buildYourBudgetMeasured',
  BUILD_YOUR_SAVINGS_PLAN_MEASURED: 'buildSavingsPlanMeasured',
  BUILD_YOUR_SAVINGS_PLAN_LARGE_PURCHASE_MEASURED: 'buildSavingsLargePurchasePlanMeasured',
  TRACK_ALL_DEBTS_MEASURED: 'trackAllDebtsMeasured',
  UPDATE_YOUR_BUDGET_MEASURED: 'updateYourBudgetMeasured',
  REVIEW_PAYMENT_PLAN: 'reviewPaymentPlan',
  CREATE_PAYMENT_PLAN: 'createPaymentPlan',
  CONTACT_CREDITORS_MEASURED: 'contactCreditorsMeasured',
  STAY_ON_TRACK_WITH_CREDITORS_MEASURED: 'stayOnTrackWithCreditorsMeasured',
  PAY_SUGGESTED_AMOUNT_MEASURED: 'paySuggestedAmountMeasured',
  TAKE_ACTION_ON_YOUR_PLAN_MEASURED: 'takeActionOnYourPlanMeasured',
  TIPS_FOR_REDUCING_SPENDING_MEASURED: 'tipsForReducingSpendingMeasured',
  TRACK_CURRENT_SPENDING_MEASURED: 'trackCurrentSpendingMeasured',
  START_SEPARATE_SAVINGS_ACCOUNT_RAINY_DAY: 'startSeparateSavingsAccountRainyDay',
  START_SEPARATE_SAVINGS_ACCOUNT_RETIREMENT: 'startSeparateSavingsAccountRetirement',
  EXPLORE_RELIEF_OPTIONS_MEASURED: 'exploreReliefOptionsMeasured',
  START_SEPARATE_SAVINGS_ACCOUNT_LARGE_PURCHASE: 'startSeparateSavingsAccountLargePurchase',
  SET_AUTOMATIC_CONTRIBUTIONS_RAINY_DAY: 'setAutomaticContributionsRainyDay',
  SET_AUTOMATIC_CONTRIBUTIONS_RETIREMENT: 'setAutomaticContributionsRetirement',
  SET_AUTOMATIC_CONTRIBUTIONS_LARGE_PURCHASE: 'setAutomaticContributionsLargePurchase',
  RECORD_LARGE_PURCHASE_CONTRIBUTION_MEASURED: 'recordLargePurchaseContributionMeasured',
  RECORD_RAINY_DAY_CONTRIBUTION_MEASURED: 'recordRainyDayContributionMeasured',
  RECORD_RETIREMENT_CONTRIBUTION_MEASURED: 'recordRetirementContributionMeasured',
  ADD_UNPLANNED_EXPENSE_GOAL_DETAILS: 'addUnplannedExpenseGoalDetails',
  ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS: 'addReduceMyExpensesGoalDetails',
  ADD_CREDIT_CARD_DEBT_GOAL_DETAILS: 'addCreditCardDebtGoalDetails',
  ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS: 'addStudentLoanDebtGoalDetails',
  ADD_OTHER_DEBT_GOAL_DETAILS: 'addOtherDebtGoalDetails',
  ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DETAILS: 'addSaveForLargePurchaseGoalDetails',
  ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS: 'addSaveForRetirementGoalDetails',
  REVIEW_SAVINGS_PLAN: 'reviewSavingsPlan',
  REVIEW_LARGE_PURCHASE_SAVINGS_PLAN: 'reviewLargePurchaseSavingsPlan',
};

export const ACTIVITIES_DISPLAY_ORDER = {
  onboardingActivities: {
    now: [
      ACTIVITY_IDS.IDENTIFY_UNPLANNED_EXPENSE_MEASURED,
      ACTIVITY_IDS.BUILD_YOUR_BUDGET_MEASURED,
      ACTIVITY_IDS.TRACK_ALL_DEBTS_MEASURED,
      ACTIVITY_IDS.BUILD_YOUR_SAVINGS_PLAN_MEASURED,
    ],
    next: [
      ACTIVITY_IDS.TIPS_FOR_REDUCING_SPENDING_MEASURED,
      ACTIVITY_IDS.EXPLORE_RELIEF_OPTIONS_MEASURED,
      ACTIVITY_IDS.CONTACT_CREDITORS_MEASURED,
      ACTIVITY_IDS.START_SEPARATE_SAVINGS_ACCOUNT_RAINY_DAY,
      ACTIVITY_IDS.START_SEPARATE_SAVINGS_ACCOUNT_LARGE_PURCHASE,
      ACTIVITY_IDS.START_SEPARATE_SAVINGS_ACCOUNT_RETIREMENT,
      ACTIVITY_IDS.TRACK_CURRENT_SPENDING_MEASURED,
      ACTIVITY_IDS.CREATE_PAYMENT_PLAN,
      ACTIVITY_IDS.SET_AUTOMATIC_CONTRIBUTIONS_RAINY_DAY,
      ACTIVITY_IDS.SET_AUTOMATIC_CONTRIBUTIONS_LARGE_PURCHASE,
      ACTIVITY_IDS.SET_AUTOMATIC_CONTRIBUTIONS_RETIREMENT,
      ACTIVITY_IDS.PAY_SUGGESTED_AMOUNT_MEASURED,
      ACTIVITY_IDS.ADD_UNPLANNED_EXPENSE_GOAL_DETAILS,
      ACTIVITY_IDS.ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS,
      ACTIVITY_IDS.ADD_CREDIT_CARD_DEBT_GOAL_DETAILS,
      ACTIVITY_IDS.ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS,
      ACTIVITY_IDS.ADD_OTHER_DEBT_GOAL_DETAILS,
      ACTIVITY_IDS.ADD_SAVE_FOR_LARGE_PURCHASE_GOAL_DETAILS,
      ACTIVITY_IDS.ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS,
    ],
  },
  permanentActivities: {
    now: [
      ACTIVITY_IDS.TAKE_ACTION_ON_YOUR_PLAN_MEASURED,
      ACTIVITY_IDS.UPDATE_YOUR_BUDGET_MEASURED,
      ACTIVITY_IDS.REVIEW_PAYMENT_PLAN,
      ACTIVITY_IDS.REVIEW_SAVINGS_PLAN,
    ],
    next: [
      ACTIVITY_IDS.TIPS_FOR_REDUCING_SPENDING_MEASURED,
      ACTIVITY_IDS.STAY_ON_TRACK_WITH_CREDITORS_MEASURED,
      ACTIVITY_IDS.RECORD_RAINY_DAY_CONTRIBUTION_MEASURED,
      ACTIVITY_IDS.RECORD_LARGE_PURCHASE_CONTRIBUTION_MEASURED,
      ACTIVITY_IDS.RECORD_RETIREMENT_CONTRIBUTION_MEASURED,
      ACTIVITY_IDS.TRACK_CURRENT_SPENDING_MEASURED,
      ACTIVITY_IDS.PAY_SUGGESTED_AMOUNT_MEASURED,
    ],
  },
};

export const activities = {
  identifyUnplannedExpenseMeasured: {
    id: 'identifyUnplannedExpenseMeasured',
    title: trans('i18n-goals:activities.identify-unplanned-expense'),
    subtitle: trans('i18n-goals:activities.sub-title.identify-unplanned-expense'),
    actionType: ACTIVITY_ACTION_TYPES.EXPENSE,
    isChecked: unplannedExpenseIdentified,
    onClick: identifyUnplannedExpenseClick,
  },
  buildYourBudgetMeasured: {
    id: 'buildYourBudgetMeasured',
    title: trans('i18n-goals:activities.build-your-budget'),
    subtitle: trans('i18n-goals:activities.sub-title.build-your-budget'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: budgetBuilt,
    onClick: buildYourBudgetClick,
  },
  buildSavingsPlanMeasured: {
    id: 'buildSavingsPlanMeasured',
    title: trans('i18n-goals:activities.build-savings-plan'),
    subtitle: trans('i18n-goals:activities.sub-title.build-savings-plan-rainy-day'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: saveForRainyDayGoalUsedSavingsTool,
    onClick: buildRainyDayFundSavingsPlanClick,
  },
  buildSavingsLargePurchasePlanMeasured: {
    id: 'buildSavingsLargePurchasePlanMeasured',
    title: trans('i18n-goals:activities.build-savings-plan'),
    subtitle: trans('i18n-goals:activities.sub-title.build-savings-plan-large-purchase'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: saveForLargePurchaseGoalUsedSavingsTool,
    onClick: buildLargePurchaseSavingsPlanClick,
  },
  trackAllDebtsMeasured: {
    id: 'trackAllDebtsMeasured',
    title: trans('i18n-goals:activities.track-all-debts'),
    subtitle: trans('i18n-goals:activities.sub-title.track-credit-card-debt'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_MODAL,
    isChecked: isAllDebtsTracked,
    onClick: trackAllDebtsClick,
  },
  updateYourBudgetMeasured: {
    id: 'updateYourBudgetMeasured',
    title: trans('i18n-goals:activities.update-your-budget'),
    subtitle: trans('i18n-goals:activities.sub-title.update-your-budget'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: budgetUpdated,
    onClick: buildYourBudgetClick,
  },
  reviewPaymentPlan: {
    id: 'reviewPaymentPlan',
    title: trans('i18n-goals:activities.review-payback-plan'),
    subtitle: trans('i18n-goals:activities.sub-title.review-payment-plan'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: paymentPlanReviewed,
    onClick: takeActionOnReviewPlanClick,
  },
  createPaymentPlan: {
    id: 'createPaymentPlan',
    title: trans('i18n-goals:activities.view-payback-plan'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    onClick: takeActionOnPaymentPlanClick,
    isChecked: paymentPlanCreated,
    lockedBy: [ACTIVITY_IDS.TRACK_ALL_DEBTS_MEASURED],
  },
  contactCreditorsMeasured: {
    id: 'contactCreditorsMeasured',
    title: trans('i18n-goals:activities.contact-creditors'),
    subtitle: trans('i18n-goals:activities.sub-title.contact-creditors'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_MODAL,
    isChecked: haveCreditorsBeenContacted,
    onClick: contactCreditorsClick,
    showIf: canShowContactCreditors,
    lockedBy: [ACTIVITY_IDS.TRACK_ALL_DEBTS_MEASURED],
  },
  stayOnTrackWithCreditorsMeasured: {
    id: 'stayOnTrackWithCreditorsMeasured',
    title: trans('i18n-goals:activities.stay-on-track-with-creditors'),
    subtitle: trans('i18n-goals:activities.sub-title.stay-on-track-with-creditors'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_MODAL,
    isChecked: haveCreditorsBeenContacted,
    onClick: stayOnTrackWithCreditorsClick,
    showIf: canShowContactCreditors,
    lockedBy: [ACTIVITY_IDS.REVIEW_PAYMENT_PLAN],
  },
  paySuggestedAmountMeasured: {
    id: 'paySuggestedAmountMeasured',
    title: trans('i18n-goals:activities.pay-suggested-amounts'),
    subtitle: trans('i18n-goals:activities.sub-title.loan-suggested-amount'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: isSuggestedAmountMeasuredPaid,
    onClick: paySuggestedAmountMeasuredClick,
    lockedBy: [
      ACTIVITY_IDS.EXPLORE_RELIEF_OPTIONS_MEASURED,
      ACTIVITY_IDS.STAY_ON_TRACK_WITH_CREDITORS_MEASURED,
      ACTIVITY_IDS.CONTACT_CREDITORS_MEASURED,
      ACTIVITY_IDS.CREATE_PAYMENT_PLAN,
      ACTIVITY_IDS.REVIEW_PAYMENT_PLAN,
    ],
  },
  takeActionOnYourPlanMeasured: {
    id: 'takeActionOnYourPlanMeasured',
    title: trans('i18n-goals:activities.take-action-on-your-plan'),
    subtitle: trans('i18n-goals:activities.sub-title.take-action-on-plan'),
    actionType: ACTIVITY_ACTION_TYPES.EXPENSE,
    isChecked: actionOnMyPlanTook,
    onClick: takeActionOnYourPlanClick,
  },
  tipsForReducingSpendingMeasured: {
    id: 'tipsForReducingSpendingMeasured',
    title: trans('i18n-goals:activities.reduce-spending'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_PAGE,
    isChecked: tipsForReducingSavingsChecked,
    onClick: tipsForReducingSpendingClick,
  },
  trackCurrentSpendingMeasured: {
    id: 'trackCurrentSpendingMeasured',
    title: trans('i18n-goals:activities.track-current-spending'),
    subtitle: trans('i18n-goals:activities.sub-title.track-current-spending'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_PAGE,
    isChecked: currentSpendingTracked,
    onClick: trackCurrentSpendingClick,
    lockedBy: [ACTIVITY_IDS.BUILD_YOUR_BUDGET_MEASURED, ACTIVITY_IDS.UPDATE_YOUR_BUDGET_MEASURED],
  },
  startSeparateSavingsAccountRainyDay: {
    id: 'startSeparateSavingsAccountRainyDay',
    title: trans('i18n-goals:activities.start-separate-savings-account'),
    subtitle: trans('i18n-goals:activities.sub-title.separate-savings-account-rainy-day'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_MODAL,
    isChecked: separateSavingsAccountRainyDayStarted,
    onClick: startSeparateSavingsAccountRainyDayClick,
    lockedBy: [ACTIVITY_IDS.BUILD_YOUR_SAVINGS_PLAN_MEASURED],
  },
  startSeparateSavingsAccountRetirement: {
    id: 'startSeparateSavingsAccountRetirement',
    title: trans('i18n-goals:activities.start-separate-savings-account'),
    subtitle: trans('i18n-goals:activities.sub-title.separate-savings-account-retirement'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: separateSavingsAccountRetirementStarted,
    onClick: startSeparateSavingsAccountRetirementClick,
    lockedBy: [ACTIVITY_IDS.BUILD_YOUR_BUDGET_MEASURED],
  },
  exploreReliefOptionsMeasured: {
    id: 'exploreReliefOptionsMeasured',
    title: trans('i18n-goals:activities.explore-relief-options'),
    subtitle: trans('i18n-goals:activities.sub-title.explore-relief-options'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: reliefOptionsExplored,
    onClick: exploreReliefOptionsMeasuredClick,
    lockedBy: [ACTIVITY_IDS.TRACK_ALL_DEBTS_MEASURED],
  },
  startSeparateSavingsAccountLargePurchase: {
    id: 'startSeparateSavingsAccountLargePurchase',
    title: trans('i18n-goals:activities.start-separate-savings-account'),
    subtitle: trans('i18n-goals:activities.sub-title.separate-savings-account-large-purchase'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_MODAL,
    isChecked: separateSavingsAccountLargePurchaseStarted,
    onClick: startSeparateSavingsAccountLargePurchaseClick,
    lockedBy: [ACTIVITY_IDS.BUILD_YOUR_SAVINGS_PLAN_LARGE_PURCHASE_MEASURED],
  },
  setAutomaticContributionsRainyDay: {
    id: 'setAutomaticContributionsRainyDay',
    title: trans('i18n-goals:activities.set-automatic-contributions'),
    subtitle: trans('i18n-goals:activities.sub-title.automatic-contributions-rainy-day'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_MODAL,
    isChecked: automaticContributionsRainyDaySet,
    onClick: setAutomaticContributionsRainyDayClick,
    lockedBy: [ACTIVITY_IDS.START_SEPARATE_SAVINGS_ACCOUNT_RAINY_DAY],
  },
  setAutomaticContributionsRetirement: {
    id: 'setAutomaticContributionsRetirement',
    title: trans('i18n-goals:activities.set-automatic-contributions'),
    subtitle: trans('i18n-goals:activities.sub-title.automatic-contributions-retirement'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_MODAL,
    isChecked: automaticContributionsRetirementSet,
    onClick: setAutomaticContributionsRetirementClick,
    lockedBy: [ACTIVITY_IDS.START_SEPARATE_SAVINGS_ACCOUNT_RETIREMENT],
  },
  setAutomaticContributionsLargePurchase: {
    id: 'setAutomaticContributionsLargePurchase',
    title: trans('i18n-goals:activities.set-automatic-contributions'),
    subtitle: trans('i18n-goals:activities.sub-title.automatic-contributions-large-purchase'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_MODAL,
    isChecked: automaticContributionsLargePurchaseSet,
    onClick: setAutomaticContributionsLargePurchaseClick,
    lockedBy: [ACTIVITY_IDS.START_SEPARATE_SAVINGS_ACCOUNT_LARGE_PURCHASE],
  },
  // TODO: savings tool must be defined
  recordLargePurchaseContributionMeasured: {
    id: 'recordLargePurchaseContributionMeasured',
    title: trans('i18n-goals:activities.record-contribution'),
    subtitle: trans('i18n-goals:activities.sub-title.record-transactions-large-purchase'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: saveForLargePurchaseContributionAdded,
    onClick: recordSaveForLargePurchaseContributionClick,
    lockedBy: [ACTIVITY_IDS.REVIEW_LARGE_PURCHASE_SAVINGS_PLAN],
  },
  recordRainyDayContributionMeasured: {
    id: 'recordRainyDayContributionMeasured',
    title: trans('i18n-goals:activities.record-contribution'),
    subtitle: trans('i18n-goals:activities.sub-title.record-transactions-rainy-day'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: saveForRainyDayContributionAdded,
    onClick: recordSaveForRainyDayContributionClick,
    lockedBy: [ACTIVITY_IDS.REVIEW_SAVINGS_PLAN],
  },
  recordRetirementContributionMeasured: {
    id: 'recordRetirementContributionMeasured',
    title: trans('i18n-goals:activities.record-contribution'),
    subtitle: trans('i18n-goals:activities.sub-title.record-transactions-retirement'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: saveForRetirementContributionAdded,
    onClick: recordSaveForRetirementContributionClick,
  },
  addUnplannedExpenseGoalDetails: {
    id: 'addUnplannedExpenseGoalDetails',
    title: trans('i18n-goals:add-goal-details'),
    subtitle: trans('i18n-goals:activities.add-unplanned-expense-goal-subtitle'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: unplannedExpenseGoalDetailsAdded,
    onClick: addUnplannedExpenseGoalDetailsClick,
  },
  addReduceMyExpensesGoalDetails: {
    id: 'addReduceMyExpensesGoalDetails',
    title: trans('i18n-goals:add-goal-details'),
    subtitle: trans('i18n-goals:activities.add-reduce-my-expenses-goal-subtitle'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: reduceMyExpensesGoalDetailsAdded,
    onClick: addReduceMyExpensesGoalDetailsClick,
  },
  addCreditCardDebtGoalDetails: {
    id: 'addCreditCardDebtGoalDetails',
    title: trans('i18n-goals:add-goal-details'),
    subtitle: trans('i18n-goals:activities.add-credit-card-debt-goal-subtitle'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: creditCardDebtGoalDetailsAdded,
    onClick: addCreditCardDebtGoalDetailsClick,
  },
  addStudentLoanDebtGoalDetails: {
    id: 'addStudentLoanDebtGoalDetails',
    title: trans('i18n-goals:add-goal-details'),
    subtitle: trans('i18n-goals:activities.add-student-loan-debt-goal-subtitle'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: studentLoanDebtGoalDetailsAdded,
    onClick: addStudentLoanDebtGoalDetailsClick,
  },
  addOtherDebtGoalDetails: {
    id: 'addOtherDebtGoalDetails',
    title: trans('i18n-goals:add-goal-details'),
    subtitle: trans('i18n-goals:activities.add-other-debt-goal-subtitle'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: otherDebtGoalDetailsAdded,
    onClick: addOtherDebtGoalDetailsClick,
  },
  addSaveForRetirementGoalDetails: {
    id: 'addSaveForRetirementGoalDetails',
    title: trans('i18n-goals:add-goal-details'),
    subtitle: trans('i18n-goals:activities.add-save-for-retirement-goal-subtitle'),
    actionType: ACTIVITY_ACTION_TYPES.OTHER,
    isChecked: saveForRetirementGoalDetailsAdded,
    onClick: addSaveForRetirementGoalDetailsClick,
  },
  reviewSavingsPlan: {
    id: 'reviewSavingsPlan',
    title: trans('i18n-goals:activities.track-review-savings-plan'),
    subtitle: trans('i18n-goals:activities.sub-title.track-review-savings-plan'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_PAGE,
    isChecked: reviewSavingsPlanCheck,
    onClick: reviewSavingsPlanClick,
  },
  reviewLargePurchaseSavingsPlan: {
    id: 'reviewLargePurchaseSavingsPlan',
    title: trans('i18n-goals:activities.track-review-savings-plan'),
    subtitle: trans('i18n-goals:activities.sub-title.track-review-large-purchase-savings-plan'),
    actionType: ACTIVITY_ACTION_TYPES.CONTENT_PAGE,
    isChecked: reviewLargePurchaseSavingsPlanCheck,
    onClick: reviewLargePurchaseSavingsPlanClick,
  },
};

export const goalsAndActivitiesByKeys = {
  manageUnplannedExpenseMeasured: {
    id: 'manageUnplannedExpenseMeasured',
    header: trans('i18n-questions:user-goals.field-1.header'),
    description: trans('i18n-questions:user-goals.field-1.description'),
    title: trans('i18n-goals:goals.manage-unplanned-expense'),
    badge: {
      name: trans('i18n-goals:badges.unplanned-expense'),
      color: '#07646C',
    },
    isComplete: unplannedExpenseManaged,
    completePercentage: unplannedExpenseCompletionPercentage,
    onboardingActivities: {
      now: [activities.identifyUnplannedExpenseMeasured, activities.buildYourBudgetMeasured],
      next: [activities.addUnplannedExpenseGoalDetails],
    },
    permanentActivities: {
      now: [activities.takeActionOnYourPlanMeasured],
      next: [],
    },
    routes: {
      add: ADD_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS,
      edit: EDIT_MANAGE_UNPLANNED_EXPENSE_GOAL_DETAILS,
    },
  },
  reduceMyExpensesMeasured: {
    id: 'reduceMyExpensesMeasured',
    header: trans('i18n-questions:user-goals.field-2.header'),
    description: trans('i18n-questions:user-goals.field-2.description'),
    title: trans('i18n-goals:goals.reduce-expense'),
    badge: {
      name: trans('i18n-goals:badges.budgeting'),
      color: '#2C7EF5',
    },
    isComplete: reduceMyExpensesGoalCompleted,
    completePercentage: reduceMyExpensesCompletionPercentage,
    onboardingActivities: {
      now: [activities.buildYourBudgetMeasured],
      next: [
        activities.tipsForReducingSpendingMeasured,
        activities.trackCurrentSpendingMeasured,
        activities.addReduceMyExpensesGoalDetails,
      ],
    },
    permanentActivities: {
      now: [activities.updateYourBudgetMeasured],
      next: [activities.tipsForReducingSpendingMeasured, activities.trackCurrentSpendingMeasured],
    },
    routes: {
      add: ADD_REDUCE_MY_EXPENSES_GOAL_DETAILS,
      edit: EDIT_REDUCE_MY_EXPENSES_GOAL_DETAILS,
    },
  },
  payOffCreditCardDebtMeasured: {
    id: 'payOffCreditCardDebtMeasured',
    header: trans('i18n-questions:user-goals.field-3.header'),
    description: trans('i18n-questions:user-goals.field-3.description'),
    title: trans('i18n-questions:user-goals.field-3.header'),
    badge: {
      name: trans('i18n-goals:badges.debt'),
      color: '#CC3C3C',
    },
    isComplete: creditCardDebtGoalCompleted,
    completePercentage: creditCardDebtGoalPercentage,
    onboardingActivities: {
      now: [activities.trackAllDebtsMeasured, activities.buildYourBudgetMeasured],
      next: [
        activities.contactCreditorsMeasured,
        activities.createPaymentPlan,
        activities.paySuggestedAmountMeasured,
        activities.addCreditCardDebtGoalDetails,
      ],
    },
    permanentActivities: {
      now: [activities.updateYourBudgetMeasured, activities.reviewPaymentPlan],
      next: [activities.stayOnTrackWithCreditorsMeasured, activities.paySuggestedAmountMeasured],
    },
    routes: {
      add: ADD_CREDIT_CARD_DEBT_GOAL_DETAILS,
      edit: EDIT_CREDIT_CARD_DEBT_GOAL_DETAILS,
    },
  },
  payOffStudentLoanDebtMeasured: {
    id: 'payOffStudentLoanDebtMeasured',
    header: trans('i18n-questions:user-goals.field-4.header'),
    description: trans('i18n-questions:user-goals.field-4.description'),
    title: trans('i18n-questions:user-goals.field-4.header'),
    badge: {
      name: trans('i18n-goals:badges.debt'),
      color: '#CC3C3C',
    },
    isComplete: studentLoanDebtGoalCompleted,
    completePercentage: studentLoanDebtGoalPercentage,
    onboardingActivities: {
      now: [activities.trackAllDebtsMeasured, activities.buildYourBudgetMeasured],
      next: [
        activities.exploreReliefOptionsMeasured,
        activities.createPaymentPlan,
        activities.paySuggestedAmountMeasured,
        activities.addStudentLoanDebtGoalDetails,
      ],
    },
    permanentActivities: {
      now: [activities.updateYourBudgetMeasured, activities.reviewPaymentPlan],
      next: [activities.stayOnTrackWithCreditorsMeasured, activities.paySuggestedAmountMeasured],
    },
    routes: {
      add: ADD_STUDENT_LOAN_DEBT_GOAL_DETAILS,
      edit: EDIT_STUDENT_LOAN_DEBT_GOAL_DETAILS,
    },
  },
  payOffOtherDebtMeasured: {
    id: 'payOffOtherDebtMeasured',
    header: trans('i18n-questions:user-goals.field-5.header'),
    description: trans('i18n-questions:user-goals.field-5.description'),
    title: trans('i18n-questions:user-goals.field-5.header'),
    badge: {
      name: trans('i18n-goals:badges.debt'),
      color: '#CC3C3C',
    },
    isComplete: otherDebtGoalCompleted,
    completePercentage: otherDebtGoalPercentage,
    onboardingActivities: {
      now: [activities.trackAllDebtsMeasured, activities.buildYourBudgetMeasured],
      next: [
        activities.contactCreditorsMeasured,
        activities.createPaymentPlan,
        activities.paySuggestedAmountMeasured,
        activities.addOtherDebtGoalDetails,
      ],
    },
    permanentActivities: {
      now: [activities.updateYourBudgetMeasured, activities.reviewPaymentPlan],
      next: [activities.stayOnTrackWithCreditorsMeasured, activities.paySuggestedAmountMeasured],
    },
    routes: {
      add: ADD_OTHER_DEBT_GOAL_DETAILS,
      edit: EDIT_OTHER_DEBT_GOAL_DETAILS,
    },
  },
  saveForRainyDayMeasured: {
    id: 'saveForRainyDayMeasured',
    header: trans('i18n-questions:user-goals.field-6.header'),
    description: trans('i18n-questions:user-goals.field-6.description'),
    title: trans('i18n-goals:goals.save-rainy-day'),
    badge: {
      name: trans('i18n-goals:badges.savings'),
      color: '#2BC169',
    },
    isComplete: saveForRainyDayGoalCompleted,
    completePercentage: saveForRainyDayGoalPercentage,
    onboardingActivities: {
      now: [activities.buildSavingsPlanMeasured],
      next: [activities.startSeparateSavingsAccountRainyDay, activities.setAutomaticContributionsRainyDay],
    },
    permanentActivities: {
      now: [activities.reviewSavingsPlan],
      next: [activities.recordRainyDayContributionMeasured],
    },
    routes: {
      add: SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH,
      edit: SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH,
    },
  },
  saveForLargePurchaseMeasured: {
    id: 'saveForLargePurchaseMeasured',
    header: trans('i18n-questions:user-goals.field-7.header'),
    description: trans('i18n-questions:user-goals.field-7.description'),
    title: trans('i18n-goals:goals.save-large-purchase'),
    badge: {
      name: trans('i18n-goals:badges.savings'),
      color: '#2BC169',
    },
    isComplete: saveForLargePurchaseGoalCompleted,
    completePercentage: saveForLargePurchasesGoalAveragePercentage,
    onboardingActivities: {
      now: [activities.buildSavingsLargePurchasePlanMeasured],
      next: [activities.startSeparateSavingsAccountLargePurchase, activities.setAutomaticContributionsLargePurchase],
    },
    permanentActivities: {
      now: [activities.reviewLargePurchaseSavingsPlan],
      next: [activities.recordLargePurchaseContributionMeasured],
    },
    routes: {
      add: SAVINGS_DASHBOARD,
      edit: BASE_PATH_LARGE_PURCHASE_SAVINGS,
    },
  },
  saveForRetirementMeasured: {
    id: 'saveForRetirementMeasured',
    header: trans('i18n-questions:user-goals.field-8.header'),
    description: trans('i18n-questions:user-goals.field-8.description'),
    title: trans('i18n-goals:goals.save-retirement'),
    badge: {
      name: trans('i18n-goals:badges.savings'),
      color: '#2BC169',
    },
    isComplete: saveForRetirementGoalCompleted,
    completePercentage: saveForRetirementGoalPercentage,
    onboardingActivities: {
      now: [activities.buildYourBudgetMeasured],
      next: [
        activities.startSeparateSavingsAccountRetirement,
        activities.setAutomaticContributionsRetirement,
        activities.addSaveForRetirementGoalDetails,
      ],
    },
    permanentActivities: {
      now: [],
      next: [activities.recordRetirementContributionMeasured],
    },
    routes: {
      add: ADD_SAVE_FOR_RETIREMENT_GOAL_DETAILS,
      edit: EDIT_SAVE_FOR_RETIREMENT_GOAL_DETAILS,
      deposit: ADD_SAVE_FOR_RETIREMENT_GOAL_DEPOSIT,
    },
  },
};

export const getActivitiesMapOfGoals = goals => {
  return goals.reduce((allActivities, goal) => {
    return {
      ...allActivities,
      ...[
        ...goalsAndActivitiesByKeys[goal].onboardingActivities.now,
        ...goalsAndActivitiesByKeys[goal].onboardingActivities.next,
        ...goalsAndActivitiesByKeys[goal].permanentActivities.now,
        ...goalsAndActivitiesByKeys[goal].permanentActivities.next,
      ].reduce((goalActivitiesMap, activity) => ({ ...goalActivitiesMap, [activity.id]: activity }), {}),
    };
  }, {});
};

export const getFlatActivitiesOfGoal = goalId => {
  return removeDuplicateObjectFromArray(
    [
      ...goalsAndActivitiesByKeys[goalId].onboardingActivities.now,
      ...goalsAndActivitiesByKeys[goalId].onboardingActivities.next,
      ...goalsAndActivitiesByKeys[goalId].permanentActivities.now,
      ...goalsAndActivitiesByKeys[goalId].permanentActivities.next,
    ],
    'id'
  );
};

export const getOnlyExclusiveActivitiesOfGoal = (goals, goalId) => {
  const activeGoals = Object.keys(goalsAndActivitiesByKeys)
    .filter(goal => goals[goal] && goals[goal].goalData.status === GOAL_STATUSES.ACTIVE)
    .map(goal => goalsAndActivitiesByKeys[goal]);

  const allGoalActivities = (getFlatActivitiesOfGoal(goalId) || []).reduce((flatActivities, activity) => {
    if (activity.subActivities) {
      return [...flatActivities, ...activity.subActivities];
    }
    return [...flatActivities, activity];
  }, []);

  const activitiesOccurrence = activeGoals
    .reduce((allActivities, goal) => [...allActivities, ...getFlatActivitiesOfGoal(goal.id)], [])
    .reduce((occurrenceMap, activity) => {
      if (!activity.subActivities) {
        occurrenceMap[activity.id] = (occurrenceMap[activity.id] || 0) + 1;
      } else {
        activity.subActivities.forEach(subActivity => {
          occurrenceMap[subActivity.id] = (occurrenceMap[subActivity.id] || 0) + 1;
        });
      }
      return occurrenceMap;
    }, {});

  return allGoalActivities.filter(activity => activitiesOccurrence[activity.id] === 1);
};
