import Big from 'big.js';

import { TMissedSavingsOfTheLoanParams, TMissedSavingsOfTheLoanResult } from '@/types/domain/calcs';

/**
 * Calculate the Direct Cost of the Loan (Retirement FV Difference If Borrowed)
 */
const calculateTheMissedSavingsOfTheLoan = ({
  futureValueLoanRetirement = 0,
  loanAmount,
  loanAmountAtRetirement,
  loanPaybackTerm,
  monthlyLoanPayment,
}: TMissedSavingsOfTheLoanParams): TMissedSavingsOfTheLoanResult => {
  const savingsShortfallAtRetirement = Big(loanAmountAtRetirement).minus(futureValueLoanRetirement);

  const trueLoanCost = Big(monthlyLoanPayment)
    .times(loanPaybackTerm * 12)
    .plus(savingsShortfallAtRetirement)
    .minus(loanAmount);

  return { savingsShortfallAtRetirement, trueLoanCost };
};

export default calculateTheMissedSavingsOfTheLoan;
