/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';

import { isQuestionCompound } from '@/domain/questions/util';

import InfoIcon from '@/pages/common/InfoIcon';

import { AltModal } from '@/components/modal';
import Image from '@/components/Image';
import { Clickable } from '@/components/button';
import FieldGroup from '@/components/Field/FieldGroup';
import WithMicroCopy from '@/components/WithMicroCopy';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';

import classes from './styles.module.less';
import VerticalSpacing from '../VerticalSpacing';

const TitleInfo = ({ content, name }) => (
  <AltModal
    heading={''}
    name={name}
    renderControl={open => (
      <Clickable className={classes.iconWrapper} onClick={open} aria-label="info">
        <Icon name="info circle" />
      </Clickable>
    )}
  >
    {content}
  </AltModal>
);

export const Title = ({ info, title, customIcon }) => (
  <Heading as="h4" className={classes.title}>
    {title} {info == null ? null : <TitleInfo {...info} />}{' '}
    {customIcon == null ? null : <Image className={classes.customIcon} src={customIcon} />}
  </Heading>
);

export const SubTitle = ({ subtitle, info }) => (
  <Paragraph size="mini" noHorizontalMargin className={cx(classes.subtitle, { [classes.infoSubtitle]: info })}>
    {subtitle}
  </Paragraph>
);

const CompoundAnswer = ({ question }) => {
  const { Component, props } = question;
  return <Component {...props} className={classes.compoundComponent} />;
};

const Answer = ({ question, ...extraProps }) => {
  if (isQuestionCompound(question)) {
    return (
      <FieldGroup>
        {question.questions.map(q => (
          <CompoundAnswer question={q} key={q.id} />
        ))}
      </FieldGroup>
    );
  }

  // component must be wrapped by Formik.Field
  const { Component, props } = question;

  return (
    <div className={classes.answer}>
      <Component aria-label={question.title} {...extraProps} {...props} />
    </div>
  );
};

const Links = ({ links }) => {
  return (
    <div className={classes.links}>
      {links.map((Component, id) => (
        <Component key={id} />
      ))}
    </div>
  );
};

/**
 * Renders the question based on the given config. Outputs descriptive text and a form control.
 * Initial values are being handled by the parent form.
 */
/* NOTE: The extraProps is just a scape hatch to enable
 * a question component to pass down props that are linked to the
 * parent container like some access to state
 * an example of this is the UnitStepperField used in the
 * CostComparison page, the field trigger a state change on the parent
 * in every onChange call, this cannot be preDefined in the question
 * configuration as part of the defaultProps
 */
const Question = ({ question, ...extraProps }) => {
  const { info, links, microcopy, subtitle, infoSubtitle, title, titleIconSrc, hidden, alternativeLayout } = question;

  if (hidden) {
    return <Answer question={question} {...extraProps} hidden />;
  }

  if (alternativeLayout) {
    return (
      <div className={classes.questionContainer}>
        {title != null && <Title info={info} title={title} customIcon={titleIconSrc} />}
        <VerticalSpacing size={2} />
        {subtitle != null && <SubTitle subtitle={subtitle} />}
        {infoSubtitle != null && <SubTitle info subtitle={infoSubtitle} />}
        {links != null && <Links links={links} />}
        {microcopy ? (
          <WithMicroCopy
            microcopy={microcopy}
            className={classes.microcopy}
            dataTestId={`${question.props['data-testid']}-microcopy`}
          >
            <Answer question={question} {...extraProps} />
          </WithMicroCopy>
        ) : (
          <Answer question={question} {...extraProps} />
        )}
      </div>
    );
  }

  return (
    <div className={classes.questionContainer}>
      {title != null && <Title info={info} title={title} customIcon={titleIconSrc} />}

      {infoSubtitle != null && <SubTitle info subtitle={infoSubtitle} />}

      {microcopy ? (
        <WithMicroCopy
          microcopy={microcopy}
          className={classes.microcopy}
          dataTestId={`${question.props['data-testid']}-microcopy`}
        >
          <Answer question={question} {...extraProps} />
        </WithMicroCopy>
      ) : (
        <Answer question={question} {...extraProps} />
      )}
      <div className={classes.questionBottom}>
        {subtitle != null && <SubTitle subtitle={subtitle} />}
        {links != null && <Links links={links} />}
      </div>
    </div>
  );
};

// it's enough to render question once - form updates inner Fields
const MemoizedQuestion = React.memo(Question);

export default MemoizedQuestion;
