export const submitKeys = ['Enter'];

export const resetKeys = ['Escape'];

export const numberKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

export const decimal = ['.'];

export function keyFilter(event: KeyboardEvent, keys = submitKeys) {
  if (!keys.includes(event.key)) {
    event.preventDefault();
  }
}

/**
 * Converts any undefined and null values to empty strings
 * @param {any} value
 */
export function getSafeInitialValue<T>(value: T) {
  if (value === undefined || value === null) {
    return '';
  }

  return value;
}
