/* eslint-disable */
// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import { Button as SemanticButton, Ref } from 'semantic-ui-react';

import Button from '@/components/button';
import FieldErrorMessage from '@/components/Field/FieldErrorMessage';

import classes from './styles.module.less';
import RadioField from '../FormField/RadioField';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const RadioButton = React.forwardRef<SemanticButton, React.PropsWithChildren<SemanticButton>>((props, ref) => (
  <Button rounded={false} innerRef={ref} {...props}>
    {props.children}
  </Button>
));

type TBaseRadioButtonGroupProps = {
  disabled?: boolean;
  onChange?: (value: number) => void;
  readOnly?: boolean;
  value?: number | null;
  name?: string;
  dataTestIdYes?: string;
  dataTestIdNo?: string;
  yesLabel?: string;
  noLabel?: string;
  asButtons?: boolean;
};

/**
 * @private
 * An uncontrolled, base component that handles clicking and focusing on buttons.
 * @param {Object} props
 * @props {boolean} [props.disabled=false] Whether the group is disabled and should not handle button clicks.
 * @props {Function} [props.onChange] A function that will receive the value of the button that was clicked.
 * @props {boolean} [props.readOnly=false] Whether the group should react to user interactions or not.
 * @props {number} [props.value=null] Pass either `1` (for yes), `0` (for no) or `null` (for none) to select a button.
 * @returns {BaseRadioButtonGroup}
 */
const BaseRadioButtonGroup = ({
  disabled = false,
  onChange,
  readOnly = false,
  value,
  name,
  dataTestIdYes,
  dataTestIdNo,
  yesLabel,
  noLabel,
  asButtons = false,
}: TBaseRadioButtonGroupProps) => {
  const { t } = useTranslation('i18n-questions');
  const [inverted, setInverted] = React.useState<boolean | null>(null);
  const noRef = React.useRef<HTMLElement>(null);
  const yesRef = React.useRef<HTMLElement>(null);

  // onFocus doesn't get trigger in firefox: https://github.com/facebook/react/issues/12993
  // so we still need onClick handler for it
  const buttonHandler = (buttonValue: number) => () => {
    if (onChange) {
      onChange(buttonValue);
    }
  };

  const noFocusHandler = React.useCallback(() => {
    if (!disabled) {
      setInverted(false);
    }
  }, [disabled]);
  const yesFocusHandler = React.useCallback(() => {
    if (!disabled) {
      setInverted(true);
    }
  }, [disabled]);

  React.useEffect(() => {
    if (value == null) {
      setInverted(value || null);
    } else {
      setInverted(value === 1);
    }
  }, [value]);

  // kept for reference if we need to focus the buttons
  // React.useEffect(() => {
  //   // we need to programmatically focus the buttons
  //   // so firefox can get the focus style.
  //   // due to the fact that after focusing on an element the view scrolls to that element
  //   // we need to scroll back to the position before that focus was trigered

  //   if (inverted === true) {
  //     if (yesRef.current && !readOnly) {
  //       const x = window.scrollX,
  //         y = window.scrollY;
  //       yesRef.current.focus();
  //       setTimeout(() => window.scrollTo(x, y), 1);
  //     }
  //   } else if (inverted === false) {
  //     if (noRef.current && !readOnly) {
  //       const x = window.scrollX,
  //         y = window.scrollY;
  //       noRef.current.focus();
  //       setTimeout(() => window.scrollTo(x, y), 1);
  //     }
  //   }
  // }, [inverted, noRef, readOnly, yesRef]);

  if (asButtons) {
    return (
      <div
        className={cx(classes.radioButtonGroup, classes.asButtons, {
          [classes.invertButtons]: inverted,
        })}
      >
        <Ref innerRef={yesRef}>
          <RadioButton
            data-testid={dataTestIdYes}
            key="yesBtn"
            type="button"
            className={cx('secondary', classes.radioButton, classes.leftRadioButton, {
              [classes.disabledButton]: disabled,
              [classes.hasSelection]: value != null,
              [classes.selectedButton]: value === 1,
            })}
            onClick={disabled || readOnly ? noop : buttonHandler(1)}
            onFocus={disabled || readOnly ? noop : yesFocusHandler}
          >
            {yesLabel || t('answer.yes')}
          </RadioButton>
        </Ref>

        <Ref innerRef={noRef}>
          <RadioButton
            data-testid={dataTestIdNo}
            key="noBtn"
            type="button"
            className={cx('secondary', classes.radioButton, classes.rightRadioButton, {
              [classes.disabledButton]: disabled,
              [classes.hasSelection]: value != null,
              [classes.selectedButton]: value === 0,
            })}
            onClick={disabled || readOnly ? noop : buttonHandler(0)}
            onFocus={disabled || readOnly ? noop : noFocusHandler}
            // IF the yes button is selected, we need to set
            // the order to before the yes button so that when
            // the flexbox row-reverse is set the order visually
            // stays proper. This is done so the focus outline
            // can go above the other button yet keep the DOM
            // order the same for keyboard navigation.
            style={{ order: inverted === true ? -1 : 0 }}
          >
            {noLabel || t('answer.no')}
          </RadioButton>
        </Ref>
      </div>
    );
  } else {
    return (
      <div className={cx(classes.radioButtonGroup)}>
        <RadioField
          data-testid={dataTestIdYes}
          name={name}
          valueName={1}
          value={value}
          label={yesLabel || t('answer.yes')}
          onClick={disabled || readOnly ? noop : buttonHandler(0)}
          onFocus={disabled || readOnly ? noop : yesFocusHandler}
        />
        <RadioField
          data-testid={dataTestIdNo}
          name={name}
          valueName={0}
          value={value}
          label={noLabel || t('answer.no')}
          onClick={disabled || readOnly ? noop : buttonHandler(0)}
          onFocus={disabled || readOnly ? noop : noFocusHandler}
        />
      </div>
    );
  }
};

/**
 * A controlled component that wraps the base component to manage internal state to
 * be the source of truth.
 *
 * ```js
 * /**
 * * @typedef {Object} RBGProps
 * * @property {boolean} [RBGProps.disabled=false] Whether the group is disabled and should not handle button clicks.
 * * @property {Function} [RBGProps.onChange] A function that will receive the value of the button that was clicked.
 * * @property {number} [RBGProps.value=null] Pass either `1` (for yes) or `0` (for no) to initially select a button.
 *
 * * @param {RBGProps} props
 * * @returns {RadioButtonGroup}
 * ```
 */
export const RadioButtonGroup = ({
  disabled,
  onChange,
  readOnly = false,
  value,
  yesLabel,
  noLabel,
  dataTestIdNo,
  dataTestIdYes,
  asButtons = false,
}) => {
  const [selected, setSelected] = React.useState(value);

  const internalOnChange = newValue => {
    setSelected(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <BaseRadioButtonGroup
      disabled={disabled}
      onChange={internalOnChange}
      readOnly={readOnly}
      value={selected}
      yesLabel={yesLabel}
      noLabel={noLabel}
      dataTestIdNo={dataTestIdNo}
      dataTestIdYes={dataTestIdYes}
      asButtons={asButtons}
    />
  );
};

type FormikFieldProxyProps = {
  field: FieldProps<unknown>;
  form: FormikProps<unknown>;
  onChange?: (value: number) => void;
};

/**
 * @private
 * A proxy that will handle bubbling the value up to Formik as well
 * as executing the onChange property you can pass. Uses the base
 * component to let Formik be the source of truth.
 */
const FormikFieldProxy = ({ field, form, onChange, ...props }: FormikFieldProxyProps) => {
  const { name, value } = field;

  // add default value
  React.useEffect(() => {
    if (value == null || value == undefined || value === '') form.setFieldValue(name, props.value);
  }, []);

  const internalOnChange = value => {
    form.setFieldTouched(name, true);
    form.setFieldValue(name, value);

    if (onChange) {
      onChange(value);
    }
  };

  const error = form.errors[name];

  return (
    <>
      <BaseRadioButtonGroup {...props} name={name} onChange={internalOnChange} value={value} />
      <FieldErrorMessage error={error} name={name} />
    </>
  );
};

/**
 * Formik field that will pass the value when a button is selected to Formik.
 * Takes Formik field props and PropsT.
 */
const RadioButtonGroupField = props => <Field component={FormikFieldProxy} {...props} />;

export default RadioButtonGroupField;
