import Big from 'big.js';

import { TMonthlyPaymentsPaybackDuringPromoPeriodParams } from '@/types/domain/calcs';

/**
 * Calculates monthly payments to payback during promo period.
 * Used to show the calculated payment that maximizes the effect of the promo period.
 */

const calcMonthlyPaymentsPaybackDuringPromoPeriod = ({
  chargeAmount,
  promoPeriodDuration,
  duringPromoInterestRate,
}: TMonthlyPaymentsPaybackDuringPromoPeriodParams): Big | number => {
  // if promo rate is 0%, just return the charge amount divided per month of promo period
  if (duringPromoInterestRate === 0) {
    return Big(chargeAmount).div(promoPeriodDuration);
  }

  const monthlyPromoRate = Big(duringPromoInterestRate).div(12);

  const monthlyPaymentsPaybackDuringPromoPeriod = Big(chargeAmount)
    .times(monthlyPromoRate)
    .div(Big(1).minus(Big(1).plus(monthlyPromoRate).pow(-promoPeriodDuration)));

  return monthlyPaymentsPaybackDuringPromoPeriod;
};

export default calcMonthlyPaymentsPaybackDuringPromoPeriod;
