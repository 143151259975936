import { string, number } from 'yup';

export const ACTIVITY_STATUSES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
};

const activityTypes = [
  'identifyUnplannedExpenseType',
  'payOffCreditCardDebt',
  'payOffStudentLoan',
  'payOffOtherDebt',
  'exploreLoanRepaymentReliefOptions',
  'trackAllStudentLoanDebt',
  'getOnABudgetSpendingPlan',
  'createAndStickToAPayoffPlan',
  'saveForRainyDay',
  'reduceMyExpenses',
  'trackCurrentExpenses',
  'tipsForReducingSpending',
  'setDecideOnSavingsGoal',
  'createSavingsAccountAutomaticContributions',
  'saveForLargePurchase',
  'saveForRetirement',
  'reduceMyDebt',
  'trackAllDebt',
  'exploreReliefOptions',
  'setDecideOnSavingsAmount',
  'createASeparateAccount',
  'contactCreditors',
  'contactCreditorsOtherDebt',
  'getOnABudgetSpendingPlanDM',
  'trackCurrentSpending',
  'nameYourGoalSavings',
  'setupSeparateAccountSavings',
  'automateSavings',
  'makeFirstDepositSavings',
  'makeMonthlyDepositSavings',
  'createYourBudgetSavings',
  'reviewYourBudgetSavings',
  'reviewYourExpensesSavings',
  'identifyUnplannedExpenseMeasured',
  'buildYourBudgetMeasured',
  'buildSavingsPlanMeasured',
  'trackAllDebtsMeasured',
  'updateYourBudgetMeasured',
  'reviewPaymentPlan',
  'createPaymentPlan',
  'contactCreditorsMeasured',
  'stayOnTrackWithCreditorsMeasured',
  'paySuggestedAmountMeasured',
  'takeActionOnYourPlanMeasured',
  'tipsForReducingSpendingMeasured',
  'trackCurrentSpendingMeasured',
  'startSeparateSavingsAccountRainyDay',
  'startSeparateSavingsAccountRetirement',
  'exploreReliefOptionsMeasured',
  'startSeparateSavingsAccountLargePurchase',
  'setAutomaticContributionsRainyDay',
  'setAutomaticContributionsRetirement',
  'setAutomaticContributionsLargePurchase',
  'recordLargePurchaseContributionMeasured',
  'recordRainyDayContributionMeasured',
  'recordRetirementContributionMeasured',
  'setASavingsGoalMeasured',
  'addUnplannedExpenseGoalDetails',
  'addReduceMyExpensesGoalDetails',
  'addCreditCardDebtGoalDetails',
  'addStudentLoanDebtGoalDetails',
  'addOtherDebtGoalDetails',
  'addSaveForLargePurchaseGoalDetails',
  'addSaveForRetirementGoalDetails',
  'reviewSavingsPlan',
  'reviewLargePurchaseSavingsPlan',
  'setDecideOnSavingsGoalIntegrated',
  'buildSavingsLargePurchasePlanMeasured',
  'setDecideOnSavingsLargePurchaseGoalIntegrated',
  'updateOrAddNewDebtAccounts',
  'exploreOptionsWithinYourBudget',
  'findDebtReliefAndTipsForWorkingWithCreditors',
  'prioritizeMonthlyPaymentsHomeAutoDebt',
  'paySuggestedAmounts',
  'recordAllDebtsAsPaid',
];

const activityConfiguration = {
  activityId: string(),
  type: string().oneOf(activityTypes),
  status: string(),
  dateCreated: number(),
  dateUpdated: number(),
};

export function getValidationRules(fieldName) {
  return activityConfiguration[fieldName];
}

export default activityConfiguration;
