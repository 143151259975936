import { AppMachineStateT } from '@/domain/stateMachines';

export const DEBT_MANAGER_STATES = {
  EMPTY: 'EMPTY',
  ONBOARDING: 'ONBOARDING',
  PLAN: 'PLAN',
  NO_ACCOUNTS: 'NO_ACCOUNTS',
};

export const calculateDebtManagerState = (appState: AppMachineStateT) => {
  const { context } = appState;
  const noStoredPaymentPlans = context.paymentPlan?.status !== 'ACTIVE' && context.archivedPaymentPlans.length === 0;
  const hasDraftPlan = !!context.draftPaymentPlan;

  if (noStoredPaymentPlans && hasDraftPlan) {
    return DEBT_MANAGER_STATES.ONBOARDING;
  }

  if (appState.matches('loaded.debtManager.paymentPlan.displayPaymentPlan')) {
    return DEBT_MANAGER_STATES.PLAN;
  }

  if (!noStoredPaymentPlans && !hasDraftPlan) {
    if (context.debts.length) {
      return DEBT_MANAGER_STATES.PLAN;
    }

    return DEBT_MANAGER_STATES.NO_ACCOUNTS;
  }
  return DEBT_MANAGER_STATES.EMPTY;
};
