import React, { FocusEvent, MouseEvent } from 'react';
import { Form, SemanticWIDTHS } from 'semantic-ui-react';
import { Field as FormikField, FastField, FieldValidator, FieldProps, FieldInputProps, FastFieldProps } from 'formik';
import { NumericFormat } from 'react-number-format';
import cx from 'classnames';

import Input from '../input';
import VerticalSpacing from '../VerticalSpacing';

import FieldErrorMessage from './FieldErrorMessage';

import classes from './styles.module.less';

export type TFieldProps = {
  /** Name used to attach this field to the Formik instance */
  name: string;
  value?: FieldInputProps<string | number>['value'];
  maxLength?: number;
  children?: (
    props: FieldProps['field'] & {
      error?: boolean;
      id?: string;
      form: FieldProps['form'];
    }
  ) => React.ReactNode;
  /** Indicates a hidden field */
  hidden?: boolean;
  className?: string;
  /** Controls visibility of field errors */
  hideError?: boolean;
  /** Field id attribute */
  id?: string;
  inputType?: string;
  trim?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  labelPosition?: 'left' | 'right' | 'left corner' | 'right corner' | undefined;
  /** Placeholder text displayed in the field when empty */
  placeholder?: string;
  isCurrencyInput?: boolean;
  width?: string;
  /** Enables the fastField variant from Formik */
  fast?: boolean;
  /** Provides a custom validation function */
  validate?: FieldValidator;
  /** Shows an asterisk indicating a required field */
  required?: boolean;
  formatAnswer?: (value: string) => string;
  subText?: React.ReactNode;
  secondaryCallback?: (payload: unknown) => void;
};

/**
 * Wraps together the form field component from formik which provides change and validation handlers,
 * form field from Semantic UI which adds some styling and finally the custom input field we created
 * based on Semantic UI.
 *
 * Handles change, validation, label and error message display. Also supports render props.
 */
const Field = ({
  children,
  hidden = false,
  className,
  hideError,
  id,
  inputType,
  name,
  trim: _trim,
  onChange,
  label,
  isCurrencyInput,
  width,
  fast = false,
  validate,
  required = false,
  formatAnswer: _formatAnswer,
  subText,
  secondaryCallback,
  ...inputProps
}: TFieldProps) => {
  const FormikWrapper = fast ? FastField : FormikField;

  return (
    <FormikWrapper name={name} validate={validate}>
      {({ field, form }: FastFieldProps | FieldProps) => {
        // allow onChange to be a prop on a field. formik sets the onChange so we have
        // to cache things and work around it.
        const formikOnChange = field.onChange;
        const onFieldChange = (...args: unknown[]) => {
          if (onChange) {
            // @ts-expect-error Generic function, so we don't know the type of args
            onChange(...args);
          }

          if (formikOnChange) {
            // @ts-expect-error Generic function, so we don't know the type of args
            formikOnChange(...args);
          }
        };

        const handleValueChange = data => {
          const { floatValue } = data;
          const payload = typeof floatValue === 'undefined' ? '' : floatValue;

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          form.setFieldValue(name, payload);
          if (secondaryCallback) {
            secondaryCallback(payload);
          }
        };

        const handleFocus = (e: FocusEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => {
          const { currentTarget } = e;

          // if current target and isn't already focused
          if (currentTarget && document.activeElement !== currentTarget) {
            currentTarget.select();
          }
        };

        const error = !!form.touched[name] && !!form.errors[name];
        const content =
          typeof children === 'function' ? (
            children({ error, id, form, ...field, ...inputProps, onChange: onFieldChange })
          ) : isCurrencyInput ? (
            <NumericFormat
              {...inputProps}
              {...field}
              className={cx(classes.fieldInput, { [classes.removeBottomMargin]: !!subText })}
              value={field.value}
              // Select all text on click
              onChange={undefined}
              id={id || name}
              prefix="$"
              valueIsNumericString
              inputMode="decimal"
              allowNegative={false}
              decimalScale={2}
              thousandSeparator
              onValueChange={data => handleValueChange(data)}
              onFocus={handleFocus}
              onClick={handleFocus}
            />
          ) : (
            <Input
              className={cx(classes.fieldInput, { [classes.error]: !!error, [classes.removeBottomMargin]: !!subText })}
              {...field}
              {...inputProps}
              value={field.value || ''}
              error={error}
              id={id || name}
              onChange={onFieldChange}
              type={inputType}
            />
          );

        return (
          <Form.Field
            width={width as SemanticWIDTHS}
            className={cx(className, { [classes.hiddenField]: hidden })}
            error={error}
          >
            <label className={classes.fieldLabel} htmlFor={name}>
              {label}
              {required && <span className={classes.required}>*</span>}
            </label>
            {content}
            {subText && (
              <>
                {subText}
                <VerticalSpacing size="tiny" />
              </>
            )}
            {!hideError && typeof name === 'string' && <FieldErrorMessage error={error} name={name} dataTestId={id} />}
          </Form.Field>
        );
      }}
    </FormikWrapper>
  );
};

export default Field;
