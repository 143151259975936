import { isMaxNumberOfDebtsReached } from 'common/model/paymentPlan';

import { checkIfAnyDebtsPresent, checkIfAnyDebtsPending } from '@/domain/debtsPlan';
import {
  updateAmountPaid,
  updateAmountPaidAndUpsertTransaction,
  setActivePaymentPlan,
  updatePaymentPlanDisclaimerDisplayed,
} from '@/domain/stateMachines/debtManager/paymentPlanStates';
import { createPaymentPlan } from '@/domain/stateMachines/common/modelsQueries';
import {
  hasActivePaymentPlan,
  isDraftAndActivePlanMissing,
  hasValidDraftPaymentPlan,
  shouldDisclaimerStatusBeUpdated,
  hasAnyPendingDebts,
} from '@/domain/stateMachines/debtManager/debtsPlanMachineHelpers';
import { createDebtCrudStates } from '@/domain/stateMachines/debtManager/debtCrudStates';

import { InitialContextT } from '..';

import type { EventObject, StateNodeConfig } from 'xstate';

const getDebtCrudStates = (targetState: string) =>
  createDebtCrudStates(targetState, {
    navigation: { add: 'navigateToOnboardingAccountList', update: 'navigateToOnboardingAccountDetails' },
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onboardingStates: StateNodeConfig<InitialContextT, any, EventObject & { availableFunds?: number }> = {
  id: 'onboardingStates',
  initial: 'initial',
  type: 'compound',
  states: {
    initial: {
      always: [
        { target: 'accountsEditing', cond: checkIfAnyDebtsPending },
        { target: 'maxAccounts', cond: isMaxNumberOfDebtsReached },
        { target: 'accountsEditing', cond: checkIfAnyDebtsPresent },
        { target: 'showDisclaimer' },
      ],
    },
    showDisclaimer: {
      initial: 'debtCrudStates',
      on: {
        READ_DISCLAIMER: [
          {
            target: 'creatingPaymentPlan',
            cond: context => !hasValidDraftPaymentPlan(context) && isDraftAndActivePlanMissing(context),
          },
          {
            target: 'updatePaymentPlanDisclaimerDisplayed',
            cond: shouldDisclaimerStatusBeUpdated,
          },
          {
            target: 'accountsEditing',
            cond: hasAnyPendingDebts,
            actions: 'navigateToOnboardingAccountList',
          },
          {
            target: 'accountsEditing',
            actions: 'navigateToOnboardingAccountCreate',
          },
        ],
        CANCEL_ADD_ACCOUNT: { target: 'initial', actions: 'navigateToLandingPage' },
        SKIP_DISCLAIMER: { target: 'accountsEditing', actions: 'navigateToOnboardingAccountCreate' },
      },
      ...getDebtCrudStates('#appMachine.loaded.debtManager.onboarding.showDisclaimer'),
    },
    creatingPaymentPlan: {
      invoke: {
        id: 'creatingPaymentPlan',
        src: createPaymentPlan,
        onDone: {
          target: 'accountsEditing',
          actions: ['setCreatedPlan', 'navigateToOnboardingAccountCreate'],
        },
        onError: {
          target: 'showDisclaimer',
          actions: 'notifyError',
        },
      },
    },
    updatePaymentPlanDisclaimerDisplayed: {
      invoke: {
        id: 'updatingPaymentPlanDisclaimerDisplayed',
        src: updatePaymentPlanDisclaimerDisplayed,
        onDone: [
          {
            target: 'accountsEditing',
            cond: hasAnyPendingDebts,
            actions: ['updatePaymentPlan', 'navigateToOnboardingAccountList'],
          },
          {
            target: 'accountsEditing',
            actions: ['updatePaymentPlan', 'navigateToOnboardingAccountCreate'],
          },
        ],
        onError: {
          target: 'showDisclaimer',
          actions: 'notifyError',
        },
      },
    },
    accountsEditing: {
      entry: 'mergePaymentPlanData',
      initial: 'debtCrudStates',
      on: {
        FINISHED_ADDING_ACCOUNTS: [
          /**
           * TODO: Per Product team, we want to confirm budget after adding accounts, even if there is an active payment plan.
           * Revisit this section in case of any issues
           */
          // {
          //   target: '#appMachine.loaded.debtManager.paymentPlan',
          //   cond: hasActivePaymentPlan,
          //   actions: 'navigateToPaymentPlan',
          // },
          {
            target: 'setActivePaymentPlan',
            cond: checkIfAnyDebtsPresent,
          },
        ],
        ACCOUNT_ADDING: {
          cond: isMaxNumberOfDebtsReached,
          actions: 'notifyReachingMaxNumberOfDebts',
        },
        CANCEL_ADD_ACCOUNT: [
          { cond: checkIfAnyDebtsPresent, actions: 'navigateToOnboardingAccountList' },
          { target: 'initial', actions: 'navigateToLandingPage' },
        ],
        PENDING_ACCOUNT_ADDING: {
          cond: checkIfAnyDebtsPending,
          target: 'showDisclaimer',
          actions: 'setPendingDebtId',
        },
        RESET_ONBOARDING_STATE: 'initial',
      },
      ...getDebtCrudStates('#appMachine.loaded.debtManager.onboarding.initial'),
    },
    maxAccounts: {
      initial: 'debtCrudStates',
      on: {
        FINISHED_ADDING_ACCOUNTS: [
          {
            target: '#appMachine.loaded.debtManager.paymentPlan',
            cond: hasActivePaymentPlan,
            actions: 'navigateToPaymentPlan',
          },
        ],
        ACCOUNT_ADDING: {
          cond: isMaxNumberOfDebtsReached,
          actions: 'notifyReachingMaxNumberOfDebts',
        },
      },
      ...getDebtCrudStates('#appMachine.loaded.debtManager.onboarding.initial'),
    },
    alreadyPaidAccounts: {
      initial: 'debtCrudStates',
      on: {
        UPDATE_AMOUNT_PAID_FROM_PAYMENT_PLAN: 'updatingAmountPaidFromOnboarding',
        UPDATE_AMOUNT_PAID_AND_UPSERT_TRANSACTION_FROM_PAYMENT_PLAN:
          'updatingAmountPaidAndUpsertTransactionFromOnboarding',
        CANCEL_ALREADY_PAID: 'accountsEditing',
        FINISHED_ALREADY_PAID_ACCOUNTS: 'setActivePaymentPlan',
        SUBMIT_ALREADY_PAID: 'setActivePaymentPlan',
      },
      ...getDebtCrudStates('#appMachine.loaded.debtManager.onboarding.alreadyPaidAccounts'),
    },

    updatingAmountPaidFromOnboarding: {
      entry: 'trackMarkedDebtAsPaid',
      invoke: {
        id: 'updateAmountPaid',
        src: updateAmountPaid,
        onDone: {
          target: 'alreadyPaidAccounts',
          actions: ['updatePaymentPlan', 'updateDebtsContext', 'closeModal'],
        },
        onError: {
          target: 'alreadyPaidAccounts',
          actions: 'notifyError',
        },
      },
    },
    setActivePaymentPlan: {
      entry: 'mergePaymentPlanData',
      invoke: {
        id: 'setActivePaymentPlan',
        src: setActivePaymentPlan,
        onDone: {
          target: '#appMachine.loaded.debtManager.paymentPlanIntro',
          actions: 'updatePaymentPlan',
        },
        onError: {
          target: 'alreadyPaidAccounts',
          actions: 'notifyError',
        },
      },
    },
    updatingAmountPaidAndUpsertTransactionFromOnboarding: {
      entry: 'trackMarkedDebtAsPaid',
      invoke: {
        id: 'updateAmountPaidAndUpsertTransaction',
        src: updateAmountPaidAndUpsertTransaction,
        onDone: {
          target: 'alreadyPaidAccounts',
          actions: ['updatePaymentPlan', 'updateDebtsContext', 'closeModal'],
        },
        onError: {
          target: 'alreadyPaidAccounts',
          actions: 'notifyError',
        },
      },
    },
  },
};

export default onboardingStates;
