/* eslint-disable */
// @ts-nocheck
import React from 'react';
import cx from 'classnames';

import classes from './styles.module.less';

class Handle extends React.Component {
  state = {
    clickFocused: false,
  };

  componentDidMount() {
    // mouseup won't trigger if mouse moved out of handle,
    // so we listen on document here.
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  setHandleRef = node => {
    this.handle = node;
  };

  setClickFocus(focused) {
    this.setState({ clickFocused: focused });
  }

  handleMouseUp = () => {
    if (document.activeElement === this.handle) {
      this.setClickFocus(true);
    }
  };

  handleMouseDown = () => {
    // fix https://github.com/ant-design/ant-design/issues/15324
    this.focus();
  };

  handleBlur = () => {
    this.setClickFocus(false);
  };

  handleKeyDown = () => {
    this.setClickFocus(false);
  };

  clickFocus() {
    this.setClickFocus(true);
    this.focus();
  }

  focus() {
    this.handle.focus();
  }

  blur() {
    this.handle.blur();
  }

  render() {
    const { error, offset, style, disabled, min, max, value, ...restProps } = this.props;
    const { clickFocused } = this.state;

    const className = cx(classes.handle, {
      [classes.handleError]: error,
      [classes.handleClickFocused]: clickFocused,
    });

    const elStyle = { left: `${offset}%` };

    let tabIndexProp = 0;
    if (disabled) {
      tabIndexProp = null;
    }

    return (
      <div
        ref={this.setHandleRef}
        tabIndex={tabIndexProp}
        {...restProps}
        className={className}
        style={elStyle}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        onMouseDown={this.handleMouseDown}
        // aria attribute
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        aria-disabled={!!disabled}
      >
        <div className={classes.handleInner} />
      </div>
    );
  }
}

export default Handle;
