/* eslint-disable */
// @ts-nocheck
import { connect } from 'formik';
import { useEffect } from 'react';

/**
 * Calls the provided `onChange` callback with current form values every time the form values change.
 * @param {Object} props - Component props.
 * @param {Object} props.formik - Formik properties.
 * @param {Function} props.onChange - The callback that passes values need to be tracked on form input change.
 * @returns {null}
 */
const FormEffect = ({ formik, onChange }) => {
  const { values } = formik;

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  return null;
};

export default connect(FormEffect);
