import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';

import classes from './NumberedList.module.less';

export type TNumberListProps = {
  items: ReactNode[];
  testId?: string;
  className?: string;
};

const NumberedList: FunctionComponent<TNumberListProps> = ({ items, testId, className = '' }) => (
  <ol className={cx(className, classes.numbered)} data-testid={testId}>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ol>
);

export default NumberedList;
