/* eslint-disable */
// @ts-nocheck

import Question from '@/components/Question';

import { amountInRetirementQ, loanAmountBFRQ } from '../questions';

const Form = ({ fromAssessment }) => (
  <>
    <Question question={loanAmountBFRQ} readOnly={fromAssessment} />
    <Question question={amountInRetirementQ} readOnly={fromAssessment} />
  </>
);

export default Form;
