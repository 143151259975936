/* eslint-disable */
// @ts-nocheck

import {
  FUNDING_FLOW_OPTIONS,
  BASE_PATH_CREDIT_CARD_FLOW,
  CREDIT_CARD_FLOW_ABOUT_CREDIT_CARDS_PATH,
  CREDIT_CARD_FLOW_PROS_AND_CONS_PATH,
  CREDIT_CARD_FLOW_CALCULATE_COST_PATH,
  CREDIT_CARD_FLOW_HOW_TO_GET_PATH,
} from '@/domain/paths';
import { loanAmountQ } from '@/domain/questions';
import { getAnswer } from '@/domain/questions/util';

import i18n from '@/libs/i18n';

import { trans } from '@/util/i18n';
import { toUSD } from '@/util/currency';

import HowToGet from '@/pages/CreditCardFlow/HowToGet';
import CalculateCost from '@/pages/CreditCardFlow/CalculateCost';
import AboutCreditCards from '@/pages/CreditCardFlow/AboutCreditCards';
import ProsAndCons from '@/pages/CreditCardFlow/ProsAndCons';

import IconLink from '@/components/IconLink';
import AuthControls from '@/components/AuthControls';

import {
  duringPromoInterestRateQ,
  interestRateQ,
  monthlyPaymentQ,
  postPromoInterestRateQ,
  promoPeriodQ,
  promoPeriodConditionalQ,
  promoPeriodDurationQ,
} from './questions';

const header = {
  renderLeftItem: () => (
    <IconLink href={FUNDING_FLOW_OPTIONS} icon="th">
      {trans('i18n-credit:payment-options')}
    </IconLink>
  ),
  renderCenterItem: () => trans('i18n-credit:use-a-credit-card'),
  renderRightItem: () => <AuthControls />,
};

const sidebar = [
  {
    id: 'about-credit-cards',
    title: trans('i18n-credit:about.credit-cards'),
  },
  {
    id: 'pros-and-cons',
    title: trans('i18n-credit:pros-and-cons'),
  },
  {
    id: 'cost-estimate',
    title: trans('i18n-credit:cost-estimate'),
  },
  {
    id: 'how-to-get',
    title: trans('i18n-credit:how-to-get-a-credit-card'),
  },
];

const subheader = {
  hasPartialCoverage: () => false,
  getContent: state => i18n.t('i18n-credit:charging-amount', { amount: toUSD(getAnswer(loanAmountQ, state), 0) }),
};

const steps = {
  'about-credit-cards': {
    id: 'about-credit-cards',
    sidebarItemId: 'about-credit-cards',
    path: CREDIT_CARD_FLOW_ABOUT_CREDIT_CARDS_PATH,
    component: AboutCreditCards,
    subheader,
  },
  'pros-and-cons': {
    id: 'pros-and-cons',
    sidebarItemId: 'pros-and-cons',
    path: CREDIT_CARD_FLOW_PROS_AND_CONS_PATH,
    component: ProsAndCons,
    subheader,
  },
  'cost-estimate': {
    id: 'cost-estimate',
    sidebarItemId: 'cost-estimate',
    path: CREDIT_CARD_FLOW_CALCULATE_COST_PATH,
    component: CalculateCost,
    renderQuestions: false,
    questions: [
      duringPromoInterestRateQ,
      interestRateQ,
      monthlyPaymentQ,
      postPromoInterestRateQ,
      promoPeriodQ,
      promoPeriodConditionalQ,
      promoPeriodDurationQ,
    ],
    size: 'wide',
    subheader,
  },
  'how-to-get': {
    id: 'how-to-get',
    sidebarItemId: 'how-to-get',
    path: CREDIT_CARD_FLOW_HOW_TO_GET_PATH,
    component: HowToGet,
    subheader,
  },
};

const initialState = {
  stepId: 'about-credit-cards',
};

const creditCardFlowConfig = {
  name: 'creditCardFlow',
  path: `${BASE_PATH_CREDIT_CARD_FLOW}/:id`,
  isFlowEnabled: () => true,
  assessment: {
    editLink: CREDIT_CARD_FLOW_ABOUT_CREDIT_CARDS_PATH,
    title: trans('i18n-credit:credit-card-flow.title'),
  },
  steps,
  sidebar,
  header,
  reducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'about-credit-cards':
        return { ...state, stepId: 'pros-and-cons' };
      case 'pros-and-cons':
        return { ...state, stepId: 'cost-estimate' };
      case 'cost-estimate':
        return { ...state, stepId: 'how-to-get' };
      case 'how-to-get':
      case 'personal-assessment':
        return { ...state, nextLocation: { pathname: FUNDING_FLOW_OPTIONS } };
      default:
        return initialState;
    }
  },
  reverseReducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'about-credit-cards':
        return { ...state, nextLocation: { pathname: FUNDING_FLOW_OPTIONS } };
      case 'pros-and-cons':
        return { ...state, stepId: 'about-credit-cards' };
      case 'cost-estimate':
        return { ...state, stepId: 'pros-and-cons' };
      case 'how-to-get':
        return { ...state, stepId: 'cost-estimate' };
      default:
        return initialState;
    }
  },
};

export default creditCardFlowConfig;
