/* eslint-disable */
// @ts-nocheck

import React from 'react';
import cx from 'classnames';
import { Ref, Modal as SemanticModal, ModalProps } from 'semantic-ui-react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import FocusLock from 'react-focus-lock';

import Button, { Clickable } from '@/components/button';
import Link from '@/components/link';
import Image from '@/components/Image';

import helpIcon from '@/images/help.svg';

import aarpBranding from './logo-white.svg';

import classes from './Modal.module.less';

/**
 * Extracted to a class component in order to simplify managing focus on the close button.
 * Based on ARIA recommendations, modals should have the closing/cancelling control focused when opening.
 * That prevents the users accidentally triggering the "confirm" action.
 */
class CloseButton extends React.Component {
  // eslint-disable-next-line react/sort-comp
  // closeElementRef;

  constructor() {
    super();

    this.closeElementRef = React.createRef();
  }

  componentDidMount() {
    // // put focus on close icon
    // if (this.closeElementRef.current) {
    //   this.closeElementRef.current.focus();
    // }

    // hide all other content from screen readers
    const reactRoot = document.querySelector('#root');
    if (reactRoot && typeof reactRoot.setAttribute === 'function') {
      reactRoot.setAttribute('aria-hidden', 'true');
    }
  }

  componentWillUnmount() {
    // reset the root status
    const reactRoot = document.querySelector('#root');
    if (reactRoot && typeof reactRoot.removeAttribute === 'function') {
      reactRoot.removeAttribute('aria-hidden');
    }
  }

  render() {
    const { onCloseIconClick } = this.props;

    return (
      <Clickable
        data-testid="closeModal"
        aria-label="Close modal dialog"
        ref={this.closeElementRef}
        onClick={onCloseIconClick}
        className={classes.closeIconWrapper}
      >
        <div className={classes.closeIcon} />
      </Clickable>
    );
  }
}

type ModalState = {
  open?: boolean;
};

export type CustomModalProps = Omit<ModalProps, 'onClose'> & {
  open?: boolean;
  onMount?: () => void;
  onUnmount?: () => void;
  onOpen?: (e: Event, data: Record<string, unknown>) => void;
  onClose?: (e: Event, data: Record<string, unknown>) => void;
  children: React.ReactNode;
  open?: boolean;
  aarpHeader?: boolean;
  dismissable?: boolean;
  hasTrigger?: boolean;
  customTrigger?: React.ReactNode;
  renderActions?: (onClose: () => void) => React.ReactNode;
  modalHeaderContent?: React.ReactNode;
  modalActions?: React.ReactNode;
  renderControl?: (open: () => void) => React.ReactNode;
  buttonControl?: string;
  linkControl?: {
    href: string;
    text: string;
  };
  className?: string;
  headerWrapperStyles?: string;
};

/**
 * Button control takes precedence over link control.
 * Note: Button is not syncing its internal state well with the `open` prop
 */
class Modal extends React.Component<CustomModalProps> {
  state = {
    open: false,
  };

  scrollingRef = null;

  controlElement = null;

  componentWillUnmount() {
    this.enabledBodyScroll();
  }

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  toggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  handleScroll = ref => {
    if (ref != null) {
      this.scrollingRef = ref;
      this.disableBodyScroll();
    }
  };

  disableBodyScroll() {
    if (this.scrollingRef != null) {
      disableBodyScroll(this.scrollingRef);
    }
  }

  enabledBodyScroll() {
    if (this.scrollingRef != null) {
      enableBodyScroll(this.scrollingRef);
    }
  }

  onCloseIconClick = e => {
    this.enabledBodyScroll();

    const { onClose } = this.props;

    this.setState(
      {
        open: false,
      },
      () => {
        if (typeof onClose === 'function') onClose(e, {}); // Somehow onClose is a boolean when not passed?
      }
    );
  };

  // save a ref to the element that was used to open the modal
  storeControlElementRef = (event, data) => {
    const { onOpen } = this.props;

    if (event && event.target && event.target instanceof HTMLElement) {
      this.controlElement = event.target;
    }

    if (typeof onOpen === 'function') {
      onOpen(event, data);
    }
  };

  // return focus to the element that opened the modal after it closes
  manageFocusOnUnmount = (event, data) => {
    const { onUnmount } = this.props;

    if (this.controlElement && typeof this.controlElement.focus === 'function') {
      this.controlElement.focus();
    }

    if (typeof onUnmount === 'function') {
      onUnmount(event, data);
    }
  };

  renderTriggerControl() {
    const { renderControl, buttonControl, linkControl } = this.props;

    // give precedence to renderControl if provided
    if (renderControl != null) {
      return renderControl(this.open);
    }

    const props = {
      onClick: this.toggleOpen,
    };

    if (buttonControl != null) {
      return <Button primary {...props} content={buttonControl} />;
    }

    if (linkControl != null) {
      return (
        <Link to={linkControl.href} {...props}>
          {linkControl.text}
        </Link>
      );
    }

    return null;
  }

  render() {
    const {
      onMount,
      children,
      open: defaultOpen,
      aarpHeader,
      dismissable = true,
      hasTrigger,
      customTrigger,
      renderActions,
      modalHeaderContent,
      modalActions,
      className = '',
      headerWrapperStyles = '',
    } = this.props;

    let controlledProps;

    if (defaultOpen) {
      controlledProps = { open: defaultOpen };
    } else {
      const { open } = this.state;

      controlledProps = { open };
    }

    return (
      <>
        {hasTrigger
          ? customTrigger || (
              <Button className={classes.triggerIcon} data-testid="modal-trigger-icon" onClick={this.open}>
                <Image src={helpIcon} />
              </Button>
            )
          : null}
        <SemanticModal
          {...controlledProps}
          onClose={this.onCloseIconClick}
          onMount={onMount}
          onOpen={this.storeControlElementRef}
          onUnmount={this.manageFocusOnUnmount}
          trigger={this.renderTriggerControl()}
          dimmer={{ className: classes.dimmer}}
          role="dialog"
          tabIndex="0"
          className={cx(classes.modal, { [classes.aarp]: aarpHeader, [className]: className })}
        >
          <div aria-modal="true">
            <FocusLock returnFocus>
              <SemanticModal.Header className={cx(classes.header, { [classes.withHeader]: modalHeaderContent })}>
                <div
                  className={cx(
                    classes.headerContent,
                    { [classes.withHeader]: modalHeaderContent !== undefined },
                    headerWrapperStyles
                  )}
                >
                  <div className={classes.modalHeaderContentWrapper}>{modalHeaderContent}</div>
                  {dismissable && <CloseButton onCloseIconClick={this.onCloseIconClick} />}
                  {aarpHeader && (
                    <Image src={aarpBranding} size="small" alt="AARP" className={classes.aarpHeaderBranding} />
                  )}
                </div>
              </SemanticModal.Header>
              <Ref innerRef={this.handleScroll}>
                <SemanticModal.Content scrolling className={classes.content}>
                  <div className={classes.contentSpacing}>{children}</div>
                  {typeof renderActions === 'function' && (
                    <div className={modalHeaderContent ? classes.contentSpacingActions : classes.contentSpacing}>
                      {renderActions(this.onCloseIconClick)}
                    </div>
                  )}
                </SemanticModal.Content>
              </Ref>
              {modalActions && (
                <SemanticModal.Actions className={classes.actionsWrapper}>
                  <div className={classes.actions}>{modalActions}</div>
                </SemanticModal.Actions>
              )}
            </FocusLock>
          </div>
        </SemanticModal>
      </>
    );
  }
}

export default Modal;
