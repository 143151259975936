import React, { forwardRef, Ref as RefType, useEffect, useRef } from 'react';
import { Ref, Input as DefaultInput, InputProps } from 'semantic-ui-react';
import cx from 'classnames';

import classes from './styles.module.less';

export type CustomInputProps = InputProps & {
  value?: string;
  onClick?: () => void;
  innerRef?: RefType<HTMLInputElement>;
  max?: number;
  min?: number;
  name?: string;
  'data-testid'?: string;
};

const Input = forwardRef<HTMLElement, CustomInputProps>(
  (
    {
      className,
      'aria-label': ariaLabel,
      autoComplete,
      children,
      'data-testid': dataTestId,
      error,
      focus,
      id,
      innerRef,
      label,
      labelPosition = 'left',
      max,
      min,
      name,
      pattern,
      type,
      value = '',
      placeholder,
      maxLength,
      showCharCount,
      ...props
    },
    ref
  ) => {
    const inputEl = useRef<HTMLInputElement | null>(null);
    const mountedRef = useRef(false);

    useEffect(() => {
      if (!mountedRef.current && inputEl.current != null) {
        mountedRef.current = true;
        if (inputEl?.current?.setAttribute) {
          inputEl.current.setAttribute('data-testid', dataTestId || '');
        }

        if (focus && inputEl.current instanceof HTMLInputElement) {
          inputEl.current.focus();
        }
      }
    }, [focus, props, dataTestId]);

    const handleRef = (el: HTMLElement) => {
      if (el !== null) {
        const input = el.querySelector('input');
        if (input instanceof HTMLInputElement) {
          inputEl.current = input;

          if (typeof innerRef === 'function') {
            innerRef(input);
          }
        }
      }
    };

    const getExpectedPlaceholder = placeholderParam => {
      if (typeof placeholderParam === 'function') {
        return placeholderParam();
      }

      return placeholderParam;
    };

    const getExpectedLabel = labelProp => {
      if (typeof labelProp === 'object') {
        return { children: labelProp };
      }
      return labelProp;
    };

    // pass the children only if children have something
    const allowedProps = React.Children.count(children) > 0 ? { ...props, children } : props;
    const expectedLabel = getExpectedLabel(label);
    const expectedPlaceholder = getExpectedPlaceholder(placeholder);
    const charCount = String(value).length;

    return (
      <div className={cx(classes.container, { [classes.showCharCount]: showCharCount })}>
        <Ref innerRef={ref || handleRef}>
          <DefaultInput
            {...allowedProps}
            className={cx(classes.inputWrapper, className)}
            placeholder={expectedPlaceholder}
            aria-label={ariaLabel}
            autoComplete={autoComplete}
            error={error}
            data-testid={dataTestId}
            id={id}
            label={expectedLabel}
            labelPosition={labelPosition}
            max={max}
            min={min}
            name={name}
            pattern={pattern}
            type={type}
            value={value}
            maxLength={maxLength}
          />
        </Ref>
        {showCharCount && (
          <div
            className={cx(classes.characterCount, {
              [classes.characterCountExceeded]: maxLength && charCount >= maxLength,
            })}
          >{`${charCount}${maxLength ? `/${maxLength as number}` : ''}`}</div>
        )}
      </div>
    );
  }
);

export default Input;
