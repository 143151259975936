/* eslint-disable */
// @ts-nocheck

import { getStepTitle } from '@/domain/WizardConfig';
import { loanAmountQ } from '@/domain/questions';
import {
  BASE_PATH_FUNDING_FLOW,
  FUNDING_FLOW_OPTIONS,
  FUNDING_FLOW_QUESTIONS_FIRST,
  FUNDING_FLOW_QUESTIONS_SECOND,
  FUNDING_FLOW_QUESTIONS_SECOND_RETIREMENT,
  FUNDING_FLOW_QUESTIONS_THIRD,
  FUNDING_FLOW_QUESTIONS_FOURTH,
  MY_PLAN_PATH,
} from '@/domain/paths';

import { trans } from '@/util/i18n';

import IconLink from '@/components/IconLink';
import AuthControls from '@/components/AuthControls';
import PrerequisiteQuestions from '@/components/PrerequisiteQuestions';

import { retirementQ, assetsQ, debtManagementQ, creditScoreQ } from './questions';

const header = {
  renderLeftItem: () => (
    <IconLink href={MY_PLAN_PATH} icon="clipboard outline">
      {trans('my-plan')}
    </IconLink>
  ),
  renderCenterItem: getStepTitle,
  renderRightItem: () => <AuthControls />,
};

const steps = {
  'questions-first': {
    id: 'questions-first',
    questions: [loanAmountQ],
    path: FUNDING_FLOW_QUESTIONS_FIRST,
    progress: {
      current: 1,
      total: 4,
    },
    component: () => (
      <PrerequisiteQuestions
        heading={trans('about-your-situation')}
        subheader={
          <>
            <strong>{trans('i18n-questions:loan-amount.title')}</strong>
            <br />
            {trans('why-do-you-ask-loan-amount.paragraph')}
          </>
        }
      />
    ),
  },
  'questions-second': {
    id: 'questions-second',
    questions: [assetsQ],
    path: FUNDING_FLOW_QUESTIONS_SECOND,
    progress: {
      current: 2,
      total: 4,
    },
    component: () => (
      <PrerequisiteQuestions
        heading={trans('about-your-situation')}
        subheader={
          <>
            <strong>{trans('i18n-questions:assets.title')}</strong>
            <br />
            {trans('why-do-you-ask.assets-paragraph')}
          </>
        }
        expandForm
      />
    ),
  },
  'questions-second-retirement': {
    id: 'questions-second-retirement',
    questions: [retirementQ],
    path: FUNDING_FLOW_QUESTIONS_SECOND_RETIREMENT,
    progress: {
      current: 2,
      total: 4,
    },
    component: () => (
      <PrerequisiteQuestions
        heading={trans('about-your-situation')}
        subheader={
          <>
            <strong>{trans('i18n-questions:retirement.title')}</strong>
          </>
        }
        expandForm
      />
    ),
  },
  'questions-third': {
    id: 'questions-third',
    questions: [creditScoreQ],
    path: FUNDING_FLOW_QUESTIONS_THIRD,
    progress: {
      current: 3,
      total: 4,
    },
    component: () => (
      <PrerequisiteQuestions
        heading={trans('about-your-situation')}
        subheader={
          <>
            <strong>{trans('i18n-questions:credit-score.title')}</strong>
            <br />
            {trans('why-do-you-ask.credit-score')}
          </>
        }
      />
    ),
  },
  'questions-fourth': {
    id: 'questions-fourth',
    questions: [debtManagementQ],
    path: FUNDING_FLOW_QUESTIONS_FOURTH,
    progress: {
      current: 4,
      total: 4,
    },
    component: () => (
      <PrerequisiteQuestions
        heading={trans('about-your-situation')}
        subheader={
          <>
            <strong>{trans('i18n-questions:debt-management.title')}</strong>
            <br />
            {trans('i18n-questions:debt-management.microcopy')}
          </>
        }
      />
    ),
  },
};

const initialState = {
  stepId: 'questions-first',
};

const fundFlowConfig = {
  name: 'fundFlow',
  isFlowEnabled: () => true,
  path: `${BASE_PATH_FUNDING_FLOW}/:id`,
  header,
  steps,
  reducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'questions-first':
        return { ...state, stepId: 'questions-second' };
      case 'questions-second':
        if (action.payload?.funding_assets.includes('hasRetirement')) {
          return { ...state, stepId: 'questions-second-retirement' };
        }

        return { ...state, stepId: 'questions-third' };
      case 'questions-second-retirement':
        return { ...state, stepId: 'questions-third' };
      case 'questions-third':
        return { ...state, stepId: 'questions-fourth' };
      case 'questions-fourth':
        return { ...state, nextLocation: { pathname: FUNDING_FLOW_OPTIONS } };
      default:
        return initialState;
    }
  },
  reverseReducer: (state = initialState, action) => {
    switch (action.stepId) {
      case 'questions-first':
        return { ...state, nextLocation: { pathname: MY_PLAN_PATH } };
      case 'questions-second':
        return { ...state, stepId: 'questions-first' };
      case 'questions-second-retirement':
        return { ...state, stepId: 'questions-second' };
      case 'questions-third':
        if (state.answers.funding_assets?.includes('hasRetirement')) {
          return { ...state, stepId: 'questions-second-retirement' };
        }
        return { ...state, stepId: 'questions-second' };
      case 'questions-fourth':
        return { ...state, stepId: 'questions-third' };
      default:
        return initialState;
    }
  },
};

export default fundFlowConfig;
