/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import Currency from '@/components/Currency';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import Question from '@/components/Question';

import { householdIncomePLQ } from '../questions';

import classes from './Form.module.less';

const Form = ({ fromAssessment, monthlyLoanPayment }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.leftSideWrapper}>
      <Paragraph noMargin size="small">
        {t('monthly-payment')}
      </Paragraph>
      <Question question={householdIncomePLQ} readOnly={fromAssessment} />
    </div>
  );
};

export default Form;
