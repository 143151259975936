export const BASE_PATH_BUDGET = '/budget';
export const BASE_PATH_BUDGET_EDIT = `${BASE_PATH_BUDGET}/edit`;
export const BUDGET_OVERVIEW_PATH = `${BASE_PATH_BUDGET}/overview`;
export const BASE_PATH_BUDGET_TRANSACTIONS = `${BASE_PATH_BUDGET}/overview/transactions`;

export const BUDGET_LANDING_PAGE_PATH = BASE_PATH_BUDGET;
export const BUDGET_INCOME_PATH = `${BASE_PATH_BUDGET}/income`;
export const BUDGET_EXPENSES_PATH = `${BASE_PATH_BUDGET}/expenses`;
export const BUDGET_EXPENSES_SELECT_PATH = `${BASE_PATH_BUDGET}/select-expenses`;
export const BUDGET_EXPENSES_AMOUNTS_PATH = `${BASE_PATH_BUDGET}/enter-expenses-amounts`;
export const BUDGET_EDIT_INCOME_PATH = `${BASE_PATH_BUDGET_EDIT}/income`;
export const BUDGET_EDIT_EXPENSES_PATH = `${BASE_PATH_BUDGET_EDIT}/expenses`;
export const BUDGET_EDIT_EXPENSES_SELECT_PATH = `${BASE_PATH_BUDGET_EDIT}/select-expenses`;
export const BUDGET_EDIT_EXPENSES_AMOUNTS_PATH = `${BASE_PATH_BUDGET_EDIT}/enter-expenses-amounts`;
export const BUDGET_OVERVIEW_STATISTICS_PATH = `${BUDGET_OVERVIEW_PATH}/statistics`;
export const BUDGET_OVERVIEW_TRANSACTIONS_PATH = `${BUDGET_OVERVIEW_PATH}/transactions`;
export const BUDGET_ADD_TRANSACTION_PATH = `${BASE_PATH_BUDGET_TRANSACTIONS}/add`;
export const BUDGET_EDIT_TRANSACTION_PATH = `${BASE_PATH_BUDGET_TRANSACTIONS}/edit`;
