import React from 'react';
import cx from 'classnames';

import classes from './VerticalSpacing.module.less';

/**
 * Component useful for managing vertical space inbetween other components.
 * Loosely based on this idea: https://medium.com/fed-or-dead/handling-spacing-in-a-ui-component-library-70f3b22ec89
 *
 * Uses padding to add space to prevent accidental margin collapsing.
 *
 * Size scale is multiplier * base value * value of size prop.
 * By default it's 0.25 * 1rem * size.
 * Example: passing size="8" => 0.25 * 1rem * 8 = 2rem spacing.
 */
const VerticalSpacing = ({ size = '4', solid = false }: { size?: string | number; solid?: boolean }) => (
  <div className={cx(classes[`p-${size}`], { [classes.solid]: solid })} />
);

export default VerticalSpacing;
