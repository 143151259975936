/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import set from 'lodash.set';
import get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';

import { clearMetadata } from '@/domain/UserState';
import { haveAnswersChanged } from '@/domain/questions/util';
import { isFlowCompleted, isStepCompleted } from '@/domain/WizardConfig';

import { SessionContext } from '@/services/session';
import { AnalyticsContext } from '@/services/analytics';
import { ExternalLinkModalContext } from '@/services/externalLinkModal';

import history from '@/util/history';
import { isExternalLink, isExternalNonAARPLink } from '@/util/url';
import { MY_PLAN_PATH_INTRO_PATH } from '@/domain/paths';
import fundFlowConfig from '@/pages/FundingFlow/config';
import freeFundsFlowConfig from '@/pages/FreeFundsFlow/config';
import helpFlowConfig from '@/pages/HelpFlow/config';
import retirementFlowConfig from '@/pages/BorrowFromRetirementAccountsFlow/config';
import personalFlowConfig from '@/pages/PersonalLoansFlow/config';
import creditCardFlowConfig from '@/pages/CreditCardFlow/config';

const shouldShowIntro = flowMetadata => {
  // get latest step id completed from flowmetadata based on dateCompleted
  const lastStepCompleted = Object.keys(flowMetadata).reduce((acc, curr) => {
    const flow = flowMetadata[curr];
    const lastStep = Object.keys(flow).reduce((accStep, currStep) => {
      const step = flow[currStep];
      if (!accStep.dateCompleted || step.dateCompleted > accStep.dateCompleted) {
        return { id: curr, step };
      }
      return accStep;
    }, {});
    if (!acc.dateCompleted || lastStep.step.dateCompleted > acc.dateCompleted) {
      return { id: lastStep.id, step: lastStep.step };
    }
    return acc;
  }, {});

  const lastStepCompletedId = lastStepCompleted?.id;

  if (!lastStepCompletedId) return false;

  // we don't want to show the intro for these flows
  return ![
    fundFlowConfig.name,
    freeFundsFlowConfig.name,
    helpFlowConfig.name,
    retirementFlowConfig.name,
    personalFlowConfig.name,
    creditCardFlowConfig.name,
  ].includes(lastStepCompletedId);
};

/**
 * Returns an updated flowMetadata object.
 * Does not mutate the original object.
 */
export const updateFlowMetadata = (initialState, skeleton, stepId, nextQuestionId, answersChanged, questionVisited) => {
  const stateUpdate = cloneDeep(initialState);

  // Update metadata
  set(stateUpdate, `flowMetadata.${skeleton.name}.${stepId}.dateCompleted`, Date.now());

  // skip marking the next step as visited for final questions
  if (nextQuestionId) {
    if (answersChanged) {
      // clear the metadata for the next steps since it's no longer valid
      const clearedMetadata = clearMetadata(stateUpdate.flowMetadata[skeleton.name], nextQuestionId);
      stateUpdate.flowMetadata[skeleton.name] = clearedMetadata;
    }

    set(stateUpdate, `flowMetadata.${skeleton.name}.${stepId}.nextStepId`, nextQuestionId);
    set(stateUpdate, `flowMetadata.${skeleton.name}.${nextQuestionId}.dateVisited`, Date.now());
    set(stateUpdate, `flowMetadata.${skeleton.name}.${nextQuestionId}.previousStepId`, stepId);
  }

  // if dateVisited is not there, use dateCompleted instead
  if (questionVisited === null) {
    set(stateUpdate, `flowMetadata.${skeleton.name}.${stepId}.dateVisited`, Date.now());
  }

  return stateUpdate;
};

/**
 * Wraps up analytics, graphql and custom navigation logic.
 * Creates a handler responsible for form submissions on wizard pages.
 */
const createSubmitHandler =
  (
    analytics,
    session,
    state,
    stepId,
    skeleton,
    updateState,
    setIsSubmitting,
    setExternalLinkModalClose,
    setExternalLink
  ) =>
  async (payload = {}, options = { skipAnalytics: false }) => {
    // get next wizard step
    try {
      const { stepId: nextQuestionId, nextLocation } = skeleton.reducer(state, { stepId, payload });

      // copy over the metadata from the previous state - that one is not updated incrementally
      const stateToBeUpdated = { answers: payload, flowMetadata: cloneDeep(state.flowMetadata) };

      const { questions } = skeleton.steps[stepId];
      const answersChanged = questions && haveAnswersChanged(state, payload, questions);
      const questionVisited = get(state, `flowMetadata.${skeleton.name}.${stepId}.dateVisited`, null);

      const stateUpdated = updateFlowMetadata(
        stateToBeUpdated,
        skeleton,
        stepId,
        nextQuestionId,
        !!answersChanged,
        questionVisited
      );

      // save the state server side (send only incremental updates)
      setIsSubmitting(true);
      await updateState(stateUpdated);

      const previousFlowMetadata = get(state, `flowMetadata.${skeleton.name}`, {});
      const updatedFlowMetadata = get(stateUpdated, `flowMetadata.${skeleton.name}`, {});

      // was the current step completed previously
      const stepCompletedPreviously = isStepCompleted(stepId, previousFlowMetadata);
      const flowCompleted = isFlowCompleted(updatedFlowMetadata, skeleton);
      // prevent tracking flow updates as completion
      const flowCompletedForFirstTime = flowCompleted && !stepCompletedPreviously;

      if (options.skipAnalytics !== true) {
        analytics.trackEvent({ name: 'submitForm', payload });

        // editing a previous step clears all following metadata, not including the current step
        const completedEditedFlow = flowCompleted && stepCompletedPreviously && answersChanged;
        if (flowCompletedForFirstTime || completedEditedFlow) {
          analytics.trackEvent({
            name: 'flowCompleted',
            payload: {
              flow: skeleton.name,
              redirectsTo: (nextLocation && nextLocation.pathname) || skeleton.steps[nextQuestionId].path,
            },
          });
        }
      }

      // clear submitting status before navigation
      setIsSubmitting(false);
      // Check for explicit nextLocation before falling back to path associated with stepId
      if (nextLocation != null && nextLocation !== '') {
        if (isExternalNonAARPLink(nextLocation.pathname)) {
          setExternalLinkModalClose(false);
          setExternalLink(nextLocation.pathname);
        } else if (isExternalLink(nextLocation.pathname)) {
          // Safari si blocking opening windows in a new tab during async function, delaying window opening by a 300 milliseconds
          setTimeout(() => window.open(nextLocation.pathname, '_blank'), 300);
        } else if (flowCompletedForFirstTime && shouldShowIntro(stateUpdated.flowMetadata)) {
          history.push(MY_PLAN_PATH_INTRO_PATH);
        } else {
          history.push(nextLocation);
        }
      } else if (stepId !== nextQuestionId) {
        const { path } = skeleton.steps[nextQuestionId];
        history.push(path);
      }
    } catch (e) {
      // @TODO Do something when an error happens
      setIsSubmitting(false);
      /* eslint-disable-next-line no-console */
      console.error(e);
    }
  };

export const WizardContext = React.createContext({});

/**
 * Provides all the functions a component rendering a wizard step needs.
 */
const StepWrapper = ({ stepId, skeleton, state, children, updateState }) => {
  const { setClose: setExternalLinkModalClose, setExternalLink } = React.useContext(ExternalLinkModalContext);
  const session = React.useContext(SessionContext);
  const analytics = React.useContext(AnalyticsContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // create the back button handler
  const handleBack = React.useCallback(() => {
    const { nextLocation, stepId: previousStepId } = skeleton.reverseReducer(state, { stepId });

    if (nextLocation != null) {
      return history.push(nextLocation);
    }

    const { path } = skeleton.steps[previousStepId] || {};

    if (path != null) {
      return history.push(path);
    }

    return history.goBack();
  }, [skeleton, state, stepId]);

  const { questions } = skeleton.steps[stepId];
  const stepHasQuestions = Array.isArray(questions) && questions.length > 0;
  const handleSubmit = createSubmitHandler(
    analytics,
    session,
    state,
    stepId,
    skeleton,
    updateState,
    setIsSubmitting,
    setExternalLinkModalClose,
    setExternalLink
  );

  const wizardContextValue = {
    skeleton,
    stepId,
    state,
    handleSubmit,
    handleBack,
    isSubmitting,
    stepHasQuestions,
  };

  return <WizardContext.Provider value={wizardContextValue}>{children}</WizardContext.Provider>;
};

export default StepWrapper;
