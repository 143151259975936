/* eslint-disable */
// @ts-nocheck
import { useTranslation } from 'react-i18next';

import { toUSD } from '@/util/currency';

import Question from '@/components/Question';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';

import { householdIncomeBFRQ } from '../questions';

import classes from './styles.module.less';

const Form = ({ fromAssessment, monthlyLoanPayment }) => {
  const { t } = useTranslation();
  const monthlyLoanPaymentToUsd = toUSD(monthlyLoanPayment, 0);

  return (
    <div className={classes.leftSideWrapper}>
      <Heading as="h1" size="large" className={classes.monthlyLoanPayment}>
        {monthlyLoanPaymentToUsd}
      </Heading>
      <Paragraph noMargin size="small">
        {t('monthly-payment')}
      </Paragraph>
      <Question question={householdIncomeBFRQ} readOnly={fromAssessment} />
    </div>
  );
};

export default Form;
