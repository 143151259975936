/* eslint-disable */
// @ts-nocheck
import { array, number, string } from 'yup';

import { trans } from '@/util/i18n';
import { nicknameDoesNotContainAccountNumber, MAX_NICKNAME_DIGITS } from '@/util/validation';
import i18n from "@/libs/i18n";

const ignoredConfigs = ['general_currentVerticals'];
const REQUIRED_ERROR_TEMPLATE = trans('validation.field-required');
const BETWEEN_MIN_MAX = trans('validation.between-min-max');
const PLEASE_ENTER_NUMBER = trans('validation.enter-number-between-min-max');
const PLEASE_ENTER_NUMBER_1_MAX = trans('validation.enter-number-between-min-max-lower');
const PLEASE_ENTER_NUMBER_1_TO_49_PERCENT = trans('validation.enter-number-between-min-max-2');
const PLEASE_ENTER_NUMBER_1_TO_15_PERCENT = trans('validation.enter-number-between-min-max-3');
const PLEASE_ENTER_NUMBER_1_TO_5 = trans('validation.enter-number-between-min-max-4');
const PLEASE_ENTER_NUMBER_0_TO_100_PERCENT = trans('validation.enter-number-between-min-max-5');
const PLEASE_ENTER_NUMBER_0_TO_500_PERCENT = trans('validation.enter-number-between-min-max-6');
const PLEASE_ENTER_NUMBER_0_TO_20_PERCENT = trans('validation.enter-number-between-min-max-7');
const PLEASE_ENTER_NUMBER_1_TO_36 = trans('validation.enter-number-between-min-max-8');
const PLEASE_ENTER_NUMBER_1_TO_50 = trans('validation.enter-number-between-min-max-9');

const answersConfiguration = {
  // General
  general_expenseGoalId: string().nullable(),
  general_healthInsurance: string()
    .oneOf(['hasPrivateInsurance', 'hasMedicare', 'hasMedicaid', 'uninsured'])
    .required(REQUIRED_ERROR_TEMPLATE),
  general_householdIncome: number()
    .min(0, BETWEEN_MIN_MAX)
    .max(1000000, BETWEEN_MIN_MAX)
    .required(trans('validation.household-income-required')),
  general_loanAmount: number()
    .min(100, PLEASE_ENTER_NUMBER)
    .max(10000000, PLEASE_ENTER_NUMBER)
    .required(trans('validation.bill-required')),
  // This was pre-existing typo (medicall) which we can't changed without a migration
  general_medicallBill: string()
    .oneOf(['planningAhead', 'receivedBill', 'billPastDue'])
    .required(REQUIRED_ERROR_TEMPLATE),
  general_city: string().required(trans('validation.city-required')),
  general_zip: string()
    .matches(/^[0-9]{5}$/, { message: trans('validation.zip-match-pattern') })
    .required(trans('validation.zip-required')),
  general_state: string().required(trans('validation.state-required')),
  general_expenseNickname: string()
    .required(REQUIRED_ERROR_TEMPLATE)
    .trim()
    .max(100, trans('validation.nickname-max-characters'))
    .test(
      'nicknameDoesNotContainAccountNumber',
      trans('validation.more-than-characters', { MAX_NICKNAME_DIGITS }),
      nicknameDoesNotContainAccountNumber
    ),
  general_myPlanFeedbackMostHelpfulStep: string().required(),
  general_myPlanFeedbackHelpfulAboutThisTool: string().trim().required(REQUIRED_ERROR_TEMPLATE),
  // Auto Vertical
  auto_inCarAccident: number().oneOf([0, 1]).required(REQUIRED_ERROR_TEMPLATE),
  auto_isCarRunning: number().oneOf([0, 1]).required(REQUIRED_ERROR_TEMPLATE),

  // Home Vertical
  home_homeRentOwn: string().oneOf(['own', 'rent']).required(REQUIRED_ERROR_TEMPLATE),
  home_hasRentersInsurance: number().oneOf([0, 1]).required(REQUIRED_ERROR_TEMPLATE),

  // Other Vertical
  other_emergencyExpense: string().oneOf(['Pet Care', 'Taxes', 'Travel', 'Other']).required(REQUIRED_ERROR_TEMPLATE),
  other_otherEmergencyExpense: string().required(REQUIRED_ERROR_TEMPLATE),

  // Funding Flow
  funding_assets: array().required(REQUIRED_ERROR_TEMPLATE),
  funding_retirement: array().required(REQUIRED_ERROR_TEMPLATE),
  funding_creditScore: string()
    .oneOf(['excellent', 'veryGood', 'good', 'fair', 'poor', 'dontKnow'])
    .required(trans('validation.credit-score-required')),
  funding_debtManagement: string()
    .oneOf(['dontHaveDebts', 'manageable', 'bitMore', 'farMore'])
    .required(trans('validation.debts-required')),

  // Borrow From Retirement Flow
  borrowRetirement_loanAmount: number()
    .min(1, PLEASE_ENTER_NUMBER_1_MAX)
    .max(10000000, PLEASE_ENTER_NUMBER_1_MAX)
    .required(trans('validation.bill-required')),
  borrowRetirement_amountInRetirement: number()
    .min(1, PLEASE_ENTER_NUMBER_1_MAX)
    .max(10000000, PLEASE_ENTER_NUMBER_1_MAX)
    .required(REQUIRED_ERROR_TEMPLATE),
  borrowRetirement_averageMonthlyPay: number()
    .min(100, PLEASE_ENTER_NUMBER)
    .max(10000000, PLEASE_ENTER_NUMBER)
    .required(REQUIRED_ERROR_TEMPLATE),
  borrowRetirement_expectedReturnRate: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_15_PERCENT)
    .max(15, PLEASE_ENTER_NUMBER_1_TO_15_PERCENT)
    .required(REQUIRED_ERROR_TEMPLATE),
  borrowRetirement_loanInterestRate: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_15_PERCENT)
    .max(15, PLEASE_ENTER_NUMBER_1_TO_15_PERCENT)
    .required(REQUIRED_ERROR_TEMPLATE),
  borrowRetirement_loanRepayPeriod: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_5)
    .max(5, PLEASE_ENTER_NUMBER_1_TO_5)
    .required(REQUIRED_ERROR_TEMPLATE),
  borrowRetirement_retirementContribution: number()
    .min(0, PLEASE_ENTER_NUMBER_0_TO_100_PERCENT)
    .max(100, PLEASE_ENTER_NUMBER_0_TO_100_PERCENT)
    .required(REQUIRED_ERROR_TEMPLATE),
  borrowRetirement_retirementEmployerMatch: number()
    .min(0, PLEASE_ENTER_NUMBER_0_TO_20_PERCENT)
    .max(20, PLEASE_ENTER_NUMBER_0_TO_20_PERCENT)
    .required(REQUIRED_ERROR_TEMPLATE),
  borrowRetirement_willContinueContributing: number()
    // nullable gets rid of the ugly cast error when validation is run on a none selected button
    .nullable()
    .required(REQUIRED_ERROR_TEMPLATE),
  borrowRetirement_yearsToRetirement: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_50)
    .max(50, PLEASE_ENTER_NUMBER_1_TO_50)
    .required(REQUIRED_ERROR_TEMPLATE),

  // Credit Cards Flow
  creditCard_loanAmount: number()
    .min(10, PLEASE_ENTER_NUMBER_1_MAX)
    .max(10000000, PLEASE_ENTER_NUMBER_1_MAX)
    .required(trans('validation.bill-required')),
  creditCard_monthlyPayment: number()
    .min(10, PLEASE_ENTER_NUMBER_1_MAX)
    .max(10000000, PLEASE_ENTER_NUMBER_1_MAX)
    .required(REQUIRED_ERROR_TEMPLATE),
  creditCard_promoPeriod: number().nullable().required(REQUIRED_ERROR_TEMPLATE),
  creditCard_CreditCardInterestRate: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_49_PERCENT)
    .max(49.9, PLEASE_ENTER_NUMBER_1_TO_49_PERCENT)
    .typeError(PLEASE_ENTER_NUMBER_1_TO_49_PERCENT)
    .required(REQUIRED_ERROR_TEMPLATE),
  creditCard_CreditCardPromoPeriodDuration: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_36)
    .max(36, PLEASE_ENTER_NUMBER_1_TO_36)
    .required(REQUIRED_ERROR_TEMPLATE),
  creditCard_CreditCardPostPromoPeriodRemainingBalance: number()
    .min(0, PLEASE_ENTER_NUMBER_0_TO_100_PERCENT)
    .max(100, PLEASE_ENTER_NUMBER_0_TO_100_PERCENT)
    .required(REQUIRED_ERROR_TEMPLATE),
  creditCard_CreditCardPostPromoInterestRate: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_49_PERCENT)
    .max(49.9, PLEASE_ENTER_NUMBER_1_TO_49_PERCENT)
    .required(REQUIRED_ERROR_TEMPLATE),

  // Personal Loans Flow
  personalLoans_loanAmount: number()
    .min(1, PLEASE_ENTER_NUMBER_1_MAX)
    .max(10000000, PLEASE_ENTER_NUMBER_1_MAX)
    .required(trans('validation.bill-required')),
  personalLoans_loanInterestRate: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_49_PERCENT)
    .max(49.9, PLEASE_ENTER_NUMBER_1_TO_49_PERCENT)
    .typeError(PLEASE_ENTER_NUMBER_1_TO_49_PERCENT)
    .required(PLEASE_ENTER_NUMBER_1_TO_49_PERCENT),
  personalLoans_loanRepayPeriod: number()
    .min(1, PLEASE_ENTER_NUMBER_1_TO_5)
    .max(5, PLEASE_ENTER_NUMBER_1_TO_5)
    .required(trans('validation.loan-repayment-required')),
};

export const getAnswerValidationRules = id => {
  if (!(id in answersConfiguration) && !ignoredConfigs.includes(id.toString())) {
    // TODO: this ends up being a bit noisy -- find a better approach?
    /* eslint-disable-next-line no-console */
    console.warn(`No validation rules for '${id}' have been configured.`);
  }

  return answersConfiguration[id];
};
