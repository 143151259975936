import { getCurrentVertical } from '@/domain/questions/selectors';

import { steps as otherSteps } from '@/pages/new-onboarding/Other/config';
import { steps as autoSteps } from '@/pages/new-onboarding/Auto/config';
import { steps as medicalSteps } from '@/pages/new-onboarding/Medical/config';
import { steps as homeSteps } from '@/pages/new-onboarding/Home/config';

import { TObject } from '@/types/common';
import { TDefaultUserState, TVertical } from '@/types/domain/UserStates';

export type TVerticalsNames = (typeof VERTICALS_NAMES)[keyof typeof VERTICALS_NAMES];

export const VERTICALS_NAMES = {
  AUTO: 'auto',
  MEDICAL: 'medical',
  HOME: 'home',
  OTHER: 'other',
} as const;

export const VERTICALS_NAMES_I18N_KEY = {
  AUTO: 'auto',
  MEDICAL: 'medical',
  HOME: 'home',
  OTHER: 'other',
} as const;

export const ALL_VERTICALS = Object.keys(VERTICALS_NAMES).map(key => VERTICALS_NAMES[key]);

/**
 * Takes a list of verticals and a selector. Runs the selector only if the provided verticals
 * are a subset of the current verticals.
 * @param {Array} verticals - array of vertical names the selector is supposed to run for
 * @param {Function} selector - a selector function that is called if verticals are enabled
 * @param {any} defaultReturnValue - value to be returned in case we're not in the current vertical
 */
export const runSelectorIfInCurrentVertical =
  (verticals: TVertical, selector, defaultReturnValue = null) =>
  (userState: TDefaultUserState) => {
    const currentVertical = getCurrentVertical(userState);

    const isInVerticals = verticals.includes(currentVertical);

    if (!isInVerticals) {
      return defaultReturnValue;
    }

    return selector(userState);
  };

/**
 * Given an array of items and a function describing how to get the verticals that apply to an array item,
 * returns the filtered subset of that array with items belonging to the current verticals only.
 * @param {Array} array - array being filtered
 * @param {function} accessor - function that picks to property to filter on
 * @param {Object} userState - current user state
 */
export const filterArrayByVerticals = (array: TObject[], accessor, userState: TDefaultUserState) => {
  const currentVertical = getCurrentVertical(userState);

  return array.filter(item => {
    const verticals = accessor(item) as TVertical;

    if (!verticals) {
      return true;
    }

    return verticals.includes(currentVertical);
  });
};
// this array consists of all the possible steps that an expense can have based on what type of
// vertical it is, it's used in ExpenseDetails and ExpenseEditing to get all the steps depending
// On the current vertical since in this case the vertical type is dynamic
export const allPossibleStepsInExpense = [
  {
    expenseType: VERTICALS_NAMES.MEDICAL,
    steps: medicalSteps,
  },
  {
    expenseType: VERTICALS_NAMES.AUTO,
    steps: autoSteps,
  },
  {
    expenseType: VERTICALS_NAMES.HOME,
    steps: homeSteps,
  },
  {
    expenseType: VERTICALS_NAMES.OTHER,
    steps: otherSteps,
  },
];
