import { PAYMENT_PLAN_FLAGS } from 'common/model/paymentPlan';

import { mergePaymentPlanWithDebts } from '@/domain/debtsPlan';

import { isDateEqual } from '@/util/date';

import { UPDATE_PAYMENT_PLAN_AND_UPSERT_TRANSACTIONS_AND_DEBT_BUDGET } from '@/graphql/mutations';
import { TransactionType } from '@/types/generated/globalTypes';

import type { InitialContextT } from '..';

export async function updatingDebtAccountFunction(context: InitialContextT, event) {
  const { client, userId, budget, debts } = context;
  const {
    targetDebtId,
    targetDebtAmount,
    closeModal,
    nickname = null,
    navigate,
    hasContactedCreditorValue,
    sendEvent,
    values,
    type,
    isPendingAccount,
    isMTFlow,
  } = event;
  const debtsPlan = mergePaymentPlanWithDebts(context);
  const isPaid = targetDebtAmount > 0;

  const amountsPaid = debtsPlan.amountsPaid?.map(({ debtId = '', amount = 0, hasContactedCreditor = false }) => {
    const isTargetDebt = targetDebtId === debtId;

    return {
      debtId,
      amount,
      hasContactedCreditor: isTargetDebt ? Boolean(hasContactedCreditorValue) : hasContactedCreditor,
      // set new amount when we find the matching debt
      ...(isTargetDebt ? { amount: Number(targetDebtAmount) } : {}),
    };
  });
  const fields = {
    amountsPaid,
    month: debtsPlan.month,
    year: debtsPlan.year,
    // Automatically dismiss the mark as paid popover when a payment is made
    ...(isPaid ? { [PAYMENT_PLAN_FLAGS.POPOVER_DISMISSED_MARK_AS_PAID]: true } : {}),
  };

  const targetTransaction = budget?.transactions?.find(transaction =>
    isDateEqual(
      { month: debtsPlan.month || '', year: debtsPlan.year || '' },
      { month: transaction.month || '', year: transaction.year || '' }
    )
  );

  const mappedAmountsPaidToTransaction: InitialContextT['budget']['transactions'][0]['list'] =
    amountsPaid?.flatMap(amtPaid => {
      const existingDebt = debts.find(debt => amtPaid.debtId === debt.debtId);
      const { nickname: debtNickname } = existingDebt || {};

      return amtPaid.amount > 0
        ? {
            debtId: amtPaid.debtId,
            date: new Date().getTime(),
            type: 'debit' as TransactionType,
            amount: amtPaid.amount,
            expense: null,
            description: debtNickname || nickname,
            savingsLargePurchaseId: null,
            savingsRainyDayId: null,
          }
        : [];
    }) || null;

  const filteredTransactionList = targetTransaction?.list?.filter(transaction => transaction?.debtId === null) || [];
  const updatedTransactionList = mappedAmountsPaidToTransaction
    ? filteredTransactionList.concat(mappedAmountsPaidToTransaction)
    : filteredTransactionList;

  const transactions = {
    month: debtsPlan.month,
    year: debtsPlan.year,
    list: updatedTransactionList,
  };

  const debtsPlanDebt = debtsPlan.amountsPaid.find(({ debtId }) => debtId === targetDebtId);

  if (!debtsPlanDebt) return null;

  const debt = {
    debtId: targetDebtId,
    debtBudget: Math.max(debtsPlanDebt.debt.debtBudget, +targetDebtAmount),
  };

  const response = await client.mutate({
    mutation: UPDATE_PAYMENT_PLAN_AND_UPSERT_TRANSACTIONS_AND_DEBT_BUDGET,
    variables: {
      paymentPlanInput: { userId, paymentPlan: fields },
      transactionsInput: { userId, transactions },
      debtInput: { userId, debt },
    },
  });

  if (!isPendingAccount && !isMTFlow) {
    sendEvent([
      {
        type: 'DEBT_EDITED',
        debt: { ...debt, type, ...values },
        values,
        navigate,
      },
      { type: 'UPDATE_BUDGET_INTEGRATION_FLAG', debt: { ...debt, type, ...values } },
    ]);
  }

  return {
    ...response,
    debtId: targetDebtId,
    closeModal,
    navigate,
  };
}
