import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Location } from 'history';

import { SessionContext } from '@/services/session';

import { RedirectLocation } from '@/util/authentication';

type AuthActionHandlersProps = {
  children: ({
    handleLogInClick,
    handleSignUpClick,
    handleSignOutClick,
    loading,
  }: {
    handleLogInClick: (props?: Partial<RedirectLocation>) => void | Promise<unknown>;
    handleSignUpClick: (props?: Partial<RedirectLocation>) => void | Promise<unknown>;
    handleSignOutClick: () => void;
    loading: boolean;
  }) => React.ReactElement;
  location: Location<Record<string, unknown>>;
  overrideState?: Record<string, unknown>;
};

const AuthActionHandlers: React.ComponentType<RouteComponentProps & AuthActionHandlersProps> = ({
  children,
  location,
  overrideState,
}) => {
  const { registerHandler, signInHandler, signOutHandler } = React.useContext(SessionContext);
  const [loading, setLoading] = React.useState(false);
  const redirectLocation = { ...location, state: { ...location.state, ...overrideState } };

  const handleSignUpClick = (props?: Partial<RedirectLocation>) => {
    const redirectLocationCustom = props?.redirectLocation;
    setLoading(true);
    return registerHandler({ redirectLocation: redirectLocationCustom || redirectLocation });
  };

  const handleLogInClick = (props?: Partial<RedirectLocation>) => {
    const redirectLocationCustom = props?.redirectLocation;
    setLoading(true);
    return signInHandler({ redirectLocation: redirectLocationCustom || redirectLocation });
  };

  const handleSignOutClick = () => {
    setLoading(true);
    return signOutHandler();
  };

  return children({ handleLogInClick, handleSignUpClick, handleSignOutClick, loading });
};

export default withRouter(AuthActionHandlers);
