import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import classes from './styles.module.less';

/**
 * Acts as a line of text placeholder, which further can be composed.
 */
const PlaceholderLine: FunctionComponent<{ className?: string }> = ({ className }) => (
  <div className={cx(className, classes.placeholder)} />
);

export default PlaceholderLine;
