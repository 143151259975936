import { FunctionComponent } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import { Svg as Logo } from './money-map-logo-mark-main.svg';

import classes from './styles.module.less';

type TAnimatedLogoProps = {
  'data-testid'?: string;
};

const AnimatedLogo: FunctionComponent<TAnimatedLogoProps> = ({ 'data-testid': dataTestId }) => {
  return (
    <div className={classes.loader} data-testid={dataTestId}>
      <Logo />
    </div>
  );
};

export default AnimatedLogo;
