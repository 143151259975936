/* eslint-disable */
// @ts-nocheck
import React, { ReactHTMLElement, ReactNode } from 'react';
import { Link as DefaultLink, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';
import { Icon, IconProps, SemanticICONS } from 'semantic-ui-react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ExternalLinkModalContext } from '@/services/externalLinkModal';
import { AnalyticsContext } from '@/services/analytics';

import {
  isInternalHashLink,
  isExternalLink,
  isExternalNonAARPLink,
  isRedirectLink,
  isRedirectOrExternalLink,
} from '@/util/url';

import classes from './Link.module.less';

type TAnchorProps = ReactHTMLElement<HTMLAnchorElement> & {
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  to: string;
};

interface CustomLinkProps extends Omit<LinkProps, 'to'> {
  children?: ReactNode;
  rightSideIcon?: boolean;
  icon?: false | SemanticICONS | IconProps;
  className?: string;
  underline?: boolean;
  noVisited?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'inherit' | number;
  type?: 'submit' | 'reset' | 'button';
  isActive?: (match: unknown, location: unknown) => boolean;
  exactActiveLink?: undefined;
  activeClassName?: undefined;
  to?: string | React.ReactNode | null;
  tabIndex?: LinkProps['tabIndex'] | string;
}

export interface CustomNavLinkProps extends Omit<NavLinkProps, 'to'> {
  activeClassName: string;
  exactActiveLink?: boolean;
  type?: 'submit' | 'reset' | 'button';
  noVisited?: boolean;
  children?: ReactNode;
  rightSideIcon?: boolean;
  icon?: false | SemanticICONS | IconProps;
  underline?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'inherit' | number;
  to?: string | React.ReactNode | null;
  tabIndex?: LinkProps['tabIndex'] | string;
  bold?: boolean;
}

export type TLinkProps = CustomLinkProps | CustomNavLinkProps;

/**
 * Renders an anchor element and handles analytics event for external links
 *
 * Note: Not to be exported -- use UniversalLink instead!
 */
const Anchor = (props: TAnchorProps) => {
  const { t } = useTranslation();
  const { children, to, ...rest } = props;
  const ariaLabel =
    typeof props.to === 'string' ? t('aria-label.external-site-link', { to: props.to }) : t('aria-label.external-site');

  const anchorProps = {
    ...(isRedirectOrExternalLink(to)
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
          'aria-label': ariaLabel,
        }
      : {}),
  };

  return (
    <a href={to} {...anchorProps} {...rest}>
      {children}
    </a>
  );
};

/**
 * A wrapper around the Anchor component which handles firing an analytics event
 *
 * Note: Not to be exported -- use UniversalLink instead!
 */
const ExternalAnchor = (props: TAnchorProps) => {
  const { trackEvent } = React.useContext(AnalyticsContext);

  const trackExternalLink = () => {
    trackEvent({
      name: 'externalLink',
      payload: {
        url: props.to,
      },
    });
    return false;
  };

  return <Anchor {...props} onClick={trackExternalLink} />;
};

/**
 * A component that swaps in the appropriate link component based on the to prop
 */
export const UniversalLink = (props: TLinkProps) => {
  const { setClose, setExternalLink } = React.useContext(ExternalLinkModalContext);
  const { activeClassName, isActive: _isActive, noVisited: _noVisited, exactActiveLink = true, ...rest } = props; // sanitize props
  const { to, onClick, className } = rest;

  const setExternalLinkOpen = () => {
    setClose(false);
    setExternalLink(to);
  };

  // Handle a link that acts like a button
  if (to == null && typeof onClick === 'function') {
    return <button type="button" {...rest} className={cx(classes.buttonAsLink, className)} />;
  }

  if (activeClassName !== undefined && activeClassName !== null) {
    return <NavLink exact={exactActiveLink} activeClassName={activeClassName} {...rest} />;
  }

  if (isInternalHashLink(to)) {
    return <HashLink {...rest} />;
  }

  if (isExternalNonAARPLink(to)) {
    return (
      <button type="button" {...rest} onClick={setExternalLinkOpen} className={cx(classes.buttonAsLink, className)} />
    );
  }

  if (isExternalLink(to)) {
    return <ExternalAnchor {...rest} />;
  }

  if (isRedirectLink(to)) {
    return <Anchor {...rest} />;
  }

  return <DefaultLink {...rest} />;
};

const getLinkIcon = (to?: LinkProps['to'], icon?: false | SemanticICONS | IconProps) => {
  if (icon === false) {
    return null;
  }
  if (typeof icon === 'object') {
    return <Icon {...icon} />;
  }
  if (icon != null) {
    return <Icon name={icon} />;
  }
  if (isRedirectOrExternalLink(to)) {
    return <Icon name="external alternate" />;
  }
  return null;
};

/*
 * A wrapper around UniversalLink that applies standard link styling and supports icons
 */
const Link = ({
  children,
  rightSideIcon = false,
  icon,
  className,
  underline = true,
  noVisited = false,
  size = 'inherit',
  bold = false,
  type,
  ...rest
}: TLinkProps) => (
  <UniversalLink
    className={cx(
      classes.link,
      {
        [classes.underline]: underline,
        [classes.stepsLink]: type,
        [classes.noVisited]: noVisited,
        [classes.bold]: bold,
        [classes.rightSideIconWrapper]: rightSideIcon,
        // defer handling of pointer events to the icon if there are no other children
        [classes.useIconPointerEvents]: icon != null && children == null,
      },
      classes[`size-${size}`],
      className
    )}
    {...rest}
  >
    {getLinkIcon(rest.to || '', icon)}
    {children}
  </UniversalLink>
);

export const StepLink = (props: LinkProps) => (
  <Link underline={false} size="tiny" noVisited {...props} type="stepLink" />
);

export default Link;
