import Big from 'big.js';

import {
  TFutureValueOfPlannedContributionsParams,
  TFutureValueOfPlannedContributionsResult,
} from '@/types/domain/calcs';

/**
 * Calculates the future value, at time of retirement,
 * of contributions that were not made during the Loan Repayment Period.
 */

const calculateFVOfPlanContributionsForgoneDuringLoanRepaymentPeriod = ({
  earnings,
  retirementAccountExpectedRateOfReturn,
  retirementPlanContribution,
  retirementPlanEmployerMatch = 0,
  loanPaybackTerm,
  yearsToRetirement,
}: TFutureValueOfPlannedContributionsParams): TFutureValueOfPlannedContributionsResult => {
  const match = Big(retirementPlanEmployerMatch).gte(retirementPlanContribution)
    ? retirementPlanContribution
    : retirementPlanEmployerMatch;

  const monthlyContributions = Big(retirementPlanContribution).plus(match).times(earnings);

  const futureValueOfContributionsOverPaybackTerm = Big(retirementAccountExpectedRateOfReturn)
    .div(12)
    .plus(1)
    .pow(loanPaybackTerm * 12)
    .minus(1)
    .div(retirementAccountExpectedRateOfReturn / 12)
    .times(monthlyContributions);

  const futureValueOfContributionsAtRetirement = Big(retirementAccountExpectedRateOfReturn)
    .div(12)
    .plus(1)
    .pow((yearsToRetirement - loanPaybackTerm) * 12)
    .times(futureValueOfContributionsOverPaybackTerm);

  return {
    monthlyContributions,
    futureValueOfContributionsOverPaybackTerm,
    futureValueOfContributionsAtRetirement,
  };
};

export default calculateFVOfPlanContributionsForgoneDuringLoanRepaymentPeriod;
