/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloConsumer } from '@apollo/client';

import { SessionContext } from '@/services/session';
import { AnalyticsContext } from '@/services/analytics';

import history from '@/util/history';

import Button from '@/components/button';
import OffsetImageCard from '@/components/OffsetImageCard';
import Heading from '@/components/Heading';
import WithMicroCopy from '@/components/WithMicroCopy';
import Paragraph from '@/components/Paragraph';
import AuthActionHandlers from '@/components/AuthActionHandlers';
import FeatureSwitch from '@/components/FeatureSwitch';
import { WizardContext } from '@/components/QuestionsWizard/StepWrapper';

import { SEND_ASSESSMENT } from '@/graphql/mutations';

import FallbackCard from './FallbackCard';
import { AssessmentContext, reducer, initialState } from './state';
import SuccessCard from './SuccessCard';
import yourPersonalAssessment from './Illustration-Personal-Assesment.svg';

import classes from './YourPersonalAssessmentCardContent.module.less';
import Sentry, { captureException } from '@/util/Sentry';
import SentryCategories from '@/util/SentryCategories';
import { getAARPSessionCookieData } from '@/util/cookie';

const GetButton = ({ onClick, loading }) => {
  const { t } = useTranslation('i18n-actions');
  return (
    <Button
      fluidOnMobile
      primary
      size="small"
      type="button"
      data-testid="getMyAssessment"
      onClick={onClick}
      loading={loading}
      className={classes.shrinkButton}
    >
      {t('button.email-my-assessment')}
    </Button>
  );
};

const sendAssessment = async ({ setLoading, setEmailFailure, setEmailSent, client, url, flow, analytics, to }) => {
  setLoading(true);

  try {
    const { data } = await client.mutate({
      mutation: SEND_ASSESSMENT,
      variables: {
        url,
        flow,
        to,
      },
    });
    if (data?.sendAssessment.success) {
      setEmailSent();
      analytics.trackEvent({ name: 'personalAssessmentEmailSent', payload: { flow, url } });
    } else {
      setEmailFailure(); // For some reason `success` comes back as `false`
      captureException(new Error(`setEmailFailure. ${data}`));
      Sentry.captureMessage(`setEmailFailure. ${data}`, 'fatal');
      Sentry.addBreadcrumb({
        category: SentryCategories.EMAIL,
        message: `setEmailFailure. ${data}`,
        data,
        level: 'fatal',
      });
    }
  } catch (e) {
    setEmailFailure(e);
  }
  setLoading(false);
};

const GetMyAssessmentButton = ({ client }) => {
  const { isAuthenticated, loading: sessionLoading } = useContext(SessionContext);
  const { initialized, url, setEmailSent, setEmailFailure, setLoading, loading } = useContext(AssessmentContext);
  const { skeleton } = useContext(WizardContext);
  const analytics = useContext(AnalyticsContext);
  const fullyAuthenticated = isAuthenticated && !sessionLoading;
  const { email } = getAARPSessionCookieData();

  const sendAssessmentRequest = useCallback(() => {
    sendAssessment({
      setLoading,
      client,
      url,
      flow: skeleton.name,
      to: email,
      setEmailSent,
      setEmailFailure,
      analytics,
    })
      .then(() => {
        Sentry.captureMessage('Assessment sent.', 'debug');
      })
      .catch(e => {
        Sentry.captureMessage(`Assessment not sent. ${e}`, 'fatal');
        Sentry.addBreadcrumb({
          category: SentryCategories.EMAIL,
          message: 'Assessment not sent error.',
          level: 'fatal',
        });
      });
  }, [setLoading, client, url, skeleton.name, setEmailSent, setEmailFailure, analytics, email]);
  // Automatically fire the mutation only if the component is being rendered
  // after successful auth flow
  useEffect(() => {
    if (initialized && history.location.state?.sendEmail === true && fullyAuthenticated) {
      sendAssessmentRequest();
      const { sendEmail, ...state } = history.location.state;
      history.replace({ ...history.location, state });
    }
  }, [initialized, fullyAuthenticated, sendAssessmentRequest]);

  return fullyAuthenticated ? (
    <GetButton
      onClick={() => {
        sendAssessmentRequest();
      }}
      loading={loading}
    />
  ) : (
    <AuthActionHandlers overrideState={{ sendEmail: true }}>
      {({ handleSignUpClick }) => <GetButton onClick={handleSignUpClick} loading={loading} />}
    </AuthActionHandlers>
  );
};

const BaseContent = ({ content }) => {
  const { t } = useTranslation();
  const { error } = useContext(AssessmentContext);
  return (
    <>
      <Heading as="h5">{t('your-personal-assessment')}</Heading>
      <Paragraph textAlign="left">{content}</Paragraph>
      <WithMicroCopy className={classes.error} microcopy={error !== null ? t('unable-to-email-your-assessment') : ''}>
        <ApolloConsumer>{client => <GetMyAssessmentButton client={client} />}</ApolloConsumer>
      </WithMicroCopy>
    </>
  );
};

const CardContent = ({ content, url }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: 'initialize', payload: { dispatch, url } });
  }, [url]);

  return (
    <AssessmentContext.Provider value={state}>
      {state.emailSent ? (
        <SuccessCard />
      ) : (
        <OffsetImageCard image={yourPersonalAssessment} content={<BaseContent content={content} />} />
      )}
    </AssessmentContext.Provider>
  );
};

const YourPersonalAssessmentCardContent = ({ content, url }) => (
  <FeatureSwitch feature="ABACUS_FLAG_EMAIL_ASSESSMENT" fallback={<FallbackCard content={content} url={url} />}>
    <CardContent content={content} url={url} />
  </FeatureSwitch>
);

export default YourPersonalAssessmentCardContent;
