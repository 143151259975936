import React from 'react';
import cx from 'classnames';

import { AnalyticsModal } from '@/components/modal';
import type { CustomModalProps } from '@/components/modal/Modal';
import type { AnalyticsModalProps } from '@/components/modal/AnalyticsModal';
import Heading from '@/components/Heading';

import classes from './AltModal.module.less';

interface AltModalProps extends CustomModalProps, Omit<AnalyticsModalProps, 'children'> {
  heading: React.ReactNode;
}

const AltModal = (props: AltModalProps) => {
  const { children, heading } = props;

  const newProps = {
    ...props,
    name: props.name || 'altModal',
    className: cx(classes['alt-modal'], { [classes.small]: props.small }),
    modalHeaderContent: (
      <Heading as="h2" className={classes.heading}>
        {heading}
      </Heading>
    ),
    headerWrapperStyles: classes.headerWrapper,
  };

  return <AnalyticsModal {...newProps}>{children}</AnalyticsModal>;
};

export default AltModal;
