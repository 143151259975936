import React, { FunctionComponent, ReactNode, RefObject } from 'react';
import cx from 'classnames';
import { Icon, SemanticCOLORS } from 'semantic-ui-react';

import { toUSD } from '@/util/currency';

import Progress from '@/components/progress/Progress';

import classes from './styles.module.less';

// width (in pixels) of the marker image
const markerSize = 24;

export type TMarker = {
  label: string;
  value: number;
};

export type TMarkerComponentProps = {
  labelAlign: string;
  marker: TMarker;
  offset: number;
};

const Marker: FunctionComponent<TMarkerComponentProps> = ({ labelAlign, marker, offset }) => (
  <div
    className={cx(classes.minMaxMarker, {
      [classes.minMaxMarkerLeftAlign]: labelAlign === 'left',
      [classes.minMaxMarkerRightAlign]: labelAlign === 'right',
    })}
    style={{
      [labelAlign === 'left' ? 'marginRight' : 'marginLeft']: `calc(${offset}% - ${markerSize / 2}px)`,
    }}
  >
    <div className={classes.minMaxMarkerLabel}>{marker.label}</div>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore:next-line */}
    <Icon className={classes.minMaxMarkerIcon} name="circle" />
  </div>
);

const getMarkerProps = ({ marker, maxValue, minValue }: { marker: TMarker; maxValue: number; minValue: number }) => {
  const markerLabel = marker.value <= (maxValue - minValue) / 2;
  const offsetValue = markerLabel ? marker.value - minValue : maxValue - marker.value;
  const offset = (offsetValue / maxValue) * 100;

  return {
    labelAlign: markerLabel ? 'right' : 'left',
    offset,
  };
};

export type TProgressBarProps = {
  className?: string;
  'data-testid'?: string;
  'aria-labelledby'?: string;
  label: ReactNode;
  marker: TMarker;
  minValue: number;
  maxValue: number;
  showMaxValue?: boolean;
  showValueAsMaxValue?: boolean;
  showMinValue?: boolean;
  subline?: ReactNode;
  sublinePosition?: string;
  value: number;
  refElement: RefObject<HTMLDivElement>;
  percent?: string | number;
  size: 'big' | 'small' | 'medium' | 'tiny' | 'large';
  color?: SemanticCOLORS;
};

const ProgressBar: FunctionComponent<TProgressBarProps> = ({
  className,
  'data-testid': dataTestId = '',
  label,
  marker,
  maxValue,
  minValue,
  showMaxValue,
  showValueAsMaxValue = false,
  showMinValue,
  subline,
  sublinePosition = 'top',
  value,
  refElement,
  percent,
  size = 'tiny',
  color,
}) => (
  <div className={className} ref={refElement}>
    {label == null ? null : (
      <div id={dataTestId} className={classes.minMaxLabel}>
        {label}
      </div>
    )}

    {sublinePosition === 'top' && <div id={label == null ? dataTestId : undefined}>{subline}</div>}

    {marker == null ? null : <Marker marker={marker} {...getMarkerProps({ marker, maxValue, minValue })} />}

    <Progress
      aria-labelledby={label !== null && dataTestId}
      className={cx(classes.minMaxProgress, {
        error: color ? false : marker == null ? false : marker.value < value,
      })}
      data-testid={dataTestId}
      percent={percent ?? (value / (maxValue - minValue)) * 100}
      size={size}
      color={color}
    />

    {sublinePosition === 'bottom' && <div>{subline}</div>}

    {showMaxValue === false && showMinValue === false ? null : (
      <div className={classes.minMaxBar}>
        {showMinValue === false ? null : <div data-testid={`${dataTestId}-min-value`}>{toUSD(minValue, 0)}</div>}

        <div className={classes.minMaxSpacer} />

        {showMaxValue === false ? null : <div data-testid={`${dataTestId}-max-value`}>{toUSD(maxValue, 0)}</div>}
      </div>
    )}

    {showValueAsMaxValue && (
      <div className={classes.minMaxBar}>
        {showMinValue === false ? null : <div data-testid={`${dataTestId}-min-value`}>{toUSD(minValue, 0)}</div>}

        <div className={classes.minMaxSpacer} />

        <div data-testid={`${dataTestId}-max-value`}>{toUSD(value, 0)}</div>
      </div>
    )}
  </div>
);

export default ProgressBar;
