import { object, number, string, array, boolean } from 'yup';

const EXPENSE_AMOUNT_MIN = 0;
const EXPENSE_AMOUNT_MAX = 1000000;
const EXPENSE_AMOUNT_LIMIT_MESSAGE = `Expense amount must be between $${EXPENSE_AMOUNT_MIN} - $${EXPENSE_AMOUNT_MAX}`;
const EXPENSE_REQUIRED_ERROR = 'Expense amount field is required to proceed';
const REQUIRED_ERROR_TEMPLATE = 'This field is required.';

const NET_MONTHLY_INCOME_MIN = 0;
const NET_MONTHLY_INCOME_MAX = 1000000;
const NET_MONTHLY_INCOME_LIMIT_MESSAGE = `Must be between $${NET_MONTHLY_INCOME_MIN} - $${NET_MONTHLY_INCOME_MAX}`;

const MODAL_DISPLAY_DATE_MESSAGE = `Must be in YYYY-MM format`;

export const BUDGET_STATUSES = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
};

/*
 * this validation is used everywhere but the budget 3rd step
 * allow the system to store expenses with a zero value on the DB
 * therefore keeping backwards compatibility with the old budget system
 */
export const expensesValidationRules = number()
  .min(EXPENSE_AMOUNT_MIN, EXPENSE_AMOUNT_LIMIT_MESSAGE)
  .max(EXPENSE_AMOUNT_MAX, EXPENSE_AMOUNT_LIMIT_MESSAGE)
  .required(EXPENSE_REQUIRED_ERROR);

/* TYPES */
export const NB_ESSENTIALS_TYPES = {
  RENT: 'RENT',
  PERSONAL_CARE: 'PERSONAL_CARE',
  UTILITIES: 'UTILITIES',
  TRANSPORTATION: 'TRANSPORTATION',
  GROCERIES: 'GROCERIES',
  PHONE: 'PHONE',
  HEALTH_SERVICES: 'HEALTH_SERVICES',
  CABLE_AND_WIFI: 'CABLE_AND_WIFI',
  CHILD_CARE: 'CHILD_CARE',
  ADULT_CARE: 'ADULT_CARE',
  AUTO: 'AUTO',
  HOME_OWNER: 'HOME_OWNER',
  RENTER: 'RENTER',
  HEALTH: 'HEALTH',
  LIFE: 'LIFE',
  DISABILITY: 'DISABILITY',
  OTHER: 'INSURANCE',
  PETS: 'PETS',
  WORK_EXPENSES: 'WORK_EXPENSES',
  CHARITY: 'CHARITY',
  HOME_SERVICES: 'HOME_SERVICES',
  FUN: 'FUN',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  CLOTHING_AND_GOODS: 'CLOTHING_AND_GOODS',
  EATING_OUT: 'EATING_OUT',
  TRAVEL: 'TRAVEL',
  OTHER_EXPENSES: 'OTHER_EXPENSES',
};

export const NB_DEBT_PAYMENTS_TYPES = {
  MORTGAGE: 'MORTGAGE',
  HOME_EQUITY_LOAN: 'HOME_EQUITY_LOAN',
  AUTO_LOAN: 'AUTO_LOAN',
  CREDIT_CARD: 'CREDIT_CARD',
  FEDERAL_STUDENT_LOAN: 'FEDERAL_STUDENT_LOAN',
  PRIVATE_STUDENT_LOAN: 'PRIVATE_STUDENT_LOAN',
  PERSONAL_LOAN: 'PERSONAL_LOAN',
  MEDICAL_BILL: 'MEDICAL_BILL',
};

export const NB_SAVINGS_TYPES = {
  EMERGENCY_FUND: 'EMERGENCY_FUND',
  EDUCATION_SAVINGS: 'EDUCATION_SAVINGS',
  RETIREMENT_400X: 'RETIREMENT_400X',
  RETIREMENT_IRA: 'RETIREMENT_IRA',
  OTHER_SAVINGS: 'SAVINGS',
  LARGE_PURCHASE: 'LARGE_PURCHASE',
};

export const NB_DEBT_PAYMENTS_TYPE_ID = {
  [NB_DEBT_PAYMENTS_TYPES.CREDIT_CARD]: 'CREDIT_CARD',
  [NB_DEBT_PAYMENTS_TYPES.FEDERAL_STUDENT_LOAN]: 'FEDERAL_STUDENT_LOAN',
  [NB_DEBT_PAYMENTS_TYPES.HOME_EQUITY_LOAN]: 'HOME_EQUITY_LOAN',
  [NB_DEBT_PAYMENTS_TYPES.MEDICAL_BILL]: 'MEDICAL_BILL',
  [NB_DEBT_PAYMENTS_TYPES.MORTGAGE]: 'MORTGAGE',
  [NB_DEBT_PAYMENTS_TYPES.PERSONAL_LOAN]: 'PERSONAL_LOAN',
  [NB_DEBT_PAYMENTS_TYPES.PRIVATE_STUDENT_LOAN]: 'PRIVATE_STUDENT_LOAN',
  [NB_DEBT_PAYMENTS_TYPES.AUTO_LOAN]: 'AUTO_LOAN',
};

/* EXPENSES IDs */
export const NB_ESSENTIALS_EXPENSES_TYPE_ID = {
  [NB_ESSENTIALS_TYPES.CABLE_AND_WIFI]: 'cableWifi',
  [NB_ESSENTIALS_TYPES.RENT]: 'rent', // existing one - old budget
  [NB_ESSENTIALS_TYPES.PERSONAL_CARE]: 'personalCare',
  [NB_ESSENTIALS_TYPES.UTILITIES]: 'utilities', // existing one - old budget
  [NB_ESSENTIALS_TYPES.TRANSPORTATION]: 'transportation', // existing one - old budget
  [NB_ESSENTIALS_TYPES.GROCERIES]: 'groceries', // existing one - old budget
  [NB_ESSENTIALS_TYPES.PHONE]: 'phone', // existing one - old budget
  [NB_ESSENTIALS_TYPES.HEALTH_SERVICES]: 'healthServices',
  [NB_ESSENTIALS_TYPES.CHILD_CARE]: 'childcare', // existing one - old budget
  [NB_ESSENTIALS_TYPES.ADULT_CARE]: 'adultcare',
  [NB_ESSENTIALS_TYPES.AUTO]: 'autoInsurance',
  [NB_ESSENTIALS_TYPES.HOME_OWNER]: 'homeInsurance',
  [NB_ESSENTIALS_TYPES.RENTER]: 'renter',
  [NB_ESSENTIALS_TYPES.HEALTH]: 'healthInsurance',
  [NB_ESSENTIALS_TYPES.DISABILITY]: 'disabilityInsurance',
  [NB_ESSENTIALS_TYPES.LIFE]: 'lifeInsurance',
  [NB_ESSENTIALS_TYPES.OTHER]: 'insurance', // existing one - old budget
  [NB_ESSENTIALS_TYPES.PETS]: 'pets',
  [NB_ESSENTIALS_TYPES.WORK_EXPENSES]: 'workExpenses',
  [NB_ESSENTIALS_TYPES.CHARITY]: 'charity',
  [NB_ESSENTIALS_TYPES.HOME_SERVICES]: 'homeServices',
  [NB_ESSENTIALS_TYPES.FUN]: 'entertainment', // existing one - old budget
  [NB_ESSENTIALS_TYPES.SUBSCRIPTIONS]: 'services', // existing one - old budget
  [NB_ESSENTIALS_TYPES.CLOTHING_AND_GOODS]: 'clothing', // existing one - old budget
  [NB_ESSENTIALS_TYPES.EATING_OUT]: 'eatingOut',
  [NB_ESSENTIALS_TYPES.TRAVEL]: 'travel',
  [NB_ESSENTIALS_TYPES.OTHER_EXPENSES]: 'otherExpenses', // existing one - old budget
};
export const NB_SAVINGS_TYPE_ID = {
  [NB_SAVINGS_TYPES.OTHER_SAVINGS]: 'savings', // existing one - old budget
  [NB_SAVINGS_TYPES.EMERGENCY_FUND]: 'emergencyFund',
  [NB_SAVINGS_TYPES.EDUCATION_SAVINGS]: 'educationSavings',
  [NB_SAVINGS_TYPES.RETIREMENT_400X]: 'retirementSavings400',
  [NB_SAVINGS_TYPES.RETIREMENT_IRA]: 'retirementSavingsIra',
  [NB_SAVINGS_TYPES.LARGE_PURCHASE]: 'largePurchase',
};
export const NB_EXPENSES_TYPES = {
  ESSENTIALS: NB_ESSENTIALS_TYPES,
  DEBT_PAYMENTS: NB_DEBT_PAYMENTS_TYPES,
  SAVINGS: NB_SAVINGS_TYPES,
};
export const NB_EXPENSES_IDS = {
  ESSENTIALS: NB_ESSENTIALS_EXPENSES_TYPE_ID,
  DEBT_PAYMENTS: NB_DEBT_PAYMENTS_TYPE_ID, // Replace with Debt Expense names
  SAVINGS: NB_SAVINGS_TYPE_ID,
};

export const allExpensesIdsFlatList = () => Object.values(NB_EXPENSES_IDS).flatMap(e => Object.values(e));
export const validationExceptions = () => Object.values(NB_SAVINGS_TYPE_ID);

// Overview
export const statisticsId = 'STATISTICS';
export const transactionsId = 'TRANSACTIONS';

/**
 * @param {"ESSENTIALS"|"DEBT_PAYMENTS"|"SAVINGS"} category
 * @param {String} type
 * @returns {String}
 */
export const mapExpenseTypeToId = (category, type) => NB_EXPENSES_IDS[category][type];

/**
 * @param {"ESSENTIALS"|"DEBT_PAYMENTS"|"SAVINGS"} category
 * @param {String} id
 * @returns {String}
 */
export const mapExpenseIdToType = (category, id) => NB_EXPENSES_TYPES[category][id];

// Map over EXPENSE_TYPES and generate the schema for all fields or only for the selected ones.
export const getExpensesSchema = selectedExpensesTypes =>
  object().shape(
    Object.assign(
      {},
      ...Object.keys(NB_EXPENSES_TYPES)
        .filter(category => category !== 'DEBT_PAYMENTS')
        .flatMap(category => {
          let expensesTypes = Object.values(NB_EXPENSES_TYPES[category]);
          if (selectedExpensesTypes) {
            expensesTypes = expensesTypes.filter(expenseType =>
              selectedExpensesTypes.some(expense => expense.type === expenseType)
            );
          }

          return expensesTypes.flatMap(type => ({
            [mapExpenseTypeToId(category, type)]: expensesValidationRules,
          }));
        })
    )
  );
const buildStringMatchRegex = strings => new RegExp(`(${Object.values(strings).join('|')})`);
export const accountStatusRegex = buildStringMatchRegex(Object.values(BUDGET_STATUSES));
export const expenseCategoryRegex = buildStringMatchRegex(Object.keys(NB_EXPENSES_IDS));
export const expensesSchema = getExpensesSchema();

const budgetValidationRules = {
  netMonthlyIncome: number()
    .min(NET_MONTHLY_INCOME_MIN, NET_MONTHLY_INCOME_LIMIT_MESSAGE)
    .max(NET_MONTHLY_INCOME_MAX, NET_MONTHLY_INCOME_LIMIT_MESSAGE)
    .required(REQUIRED_ERROR_TEMPLATE),
  expenses: expensesSchema,
  customExpenses: array().of(
    object().shape({
      name: string().required(REQUIRED_ERROR_TEMPLATE),
      amount: expensesValidationRules,
      // can be either one of ESSENTIALS DEBT_PAYMENTS SAVINGS
      category: string().matches(expenseCategoryRegex).required(REQUIRED_ERROR_TEMPLATE),
    })
  ),
  monthlyModalLastDisplayed: string()
    .nullable()
    .length(7, MODAL_DISPLAY_DATE_MESSAGE)
    .matches(/\d{4}-(0[1-9]|1[0-2])/, MODAL_DISPLAY_DATE_MESSAGE),
  incompleteDebtExpensesModalDateDisplayed: number().nullable(),
  updatedFromSavingsFlag: boolean().nullable(),
  updatedFromDebtManagerFlag: boolean().nullable(),
  status: string().matches(accountStatusRegex).required(REQUIRED_ERROR_TEMPLATE),
};

export function getValidationRules(fieldName) {
  return budgetValidationRules[fieldName];
}

// a list of properties that will trigger the lastEdited update
export const BUDGET_EDIT_PROPERTIES = ['expenses', 'customExpenses', 'netMonthlyIncome'];
