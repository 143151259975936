/* eslint-disable */
// @ts-nocheck

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';

import useMediaQuery, { mediaQuery } from '@/hooks/useMediaQuery';

import { SessionContext } from '@/services/session';

import AuthActionHandlers from '@/components/AuthActionHandlers';
import Button, { Clickable } from '@/components/button';
import Microcopy from '@/components/microcopy';

import classes from './AuthControls.module.less';

export type TAuthControlProps = {
  isAuthenticated: boolean;
  signOutHandler: Function;
};

const MobileVersion: FunctionComponent<TAuthControlProps> = ({ isAuthenticated, signOutHandler }) => {
  const { t } = useTranslation('i18n-actions');

  if (isAuthenticated) {
    return (
      <Clickable data-testid="logOutBtn" className={classes.control} onClick={signOutHandler}>
        <span>
          <Icon className={classes.userIcon} name="user" />
          {t('button.log-out')}
        </span>
      </Clickable>
    );
  }

  return (
    <div className={classes.mobileMenu}>
      <AuthActionHandlers>
        {({ handleLogInClick }) => (
          <Button data-testid="loginModalBtn" secondary size="big" fluid onClick={handleLogInClick}>
            {t('button.login')}
          </Button>
        )}
      </AuthActionHandlers>
      <AuthActionHandlers>
        {({ handleSignUpClick }) => (
          <div className={classes.signUpMobile}>
            <Button data-testid="signUpModalBtn" primary size="big" fluid onClick={handleSignUpClick}>
              {t('button.sign-up')}
            </Button>
            <Microcopy className={classes.signUpMicrocopy}>{t('save-progress')}</Microcopy>
          </div>
        )}
      </AuthActionHandlers>
    </div>
  );
};

const DesktopVersion: FunctionComponent<TAuthControlProps> = ({ isAuthenticated, signOutHandler }) => {
  const { t } = useTranslation('i18n-actions');

  return isAuthenticated ? (
    <Clickable
      data-testid="signOutBtn"
      className={cx(classes.control, classes.modalControl)}
      onClick={signOutHandler}
    >
      <span className={classes.modalControlPadding}>{t('button.log-out')}</span>
    </Clickable>
  ) : (
    <AuthActionHandlers>
      {({ handleSignUpClick }) => (
        <Clickable
          allowPadding
          data-testid="loginModalBtn"
          className={cx(classes.modalControl, classes.control)}
          onClick={handleSignUpClick}
        >
          <span className={classes.modalControlPadding}>{t('button.sign-up')}</span>
        </Clickable>
      )}
    </AuthActionHandlers>
  );
};

const AuthControls = () => {
  const { isAuthenticated, signOutHandler } = React.useContext(SessionContext);
  const isMobileAndTablet = useMediaQuery(mediaQuery.MOBILE_AND_TABLET);

  return isMobileAndTablet ? (
    <MobileVersion signOutHandler={signOutHandler} isAuthenticated={isAuthenticated} />
  ) : (
    <DesktopVersion signOutHandler={signOutHandler} isAuthenticated={isAuthenticated} />
  );
};

export default AuthControls;
