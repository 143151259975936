/**
 * Returns value from ENV if it is defined or falls back to the default value if not.
 * Parses strings containing boolean strings (true|false)
 * Returns an empty string if defaultValue is not provided
 */
export const envOrDefault = <T extends string | boolean | number | undefined>(
  variableName: string,
  defaultValue: T
): T => {
  const envValue: string | undefined = process.env[variableName];

  // parse booleans by default
  if (envValue === 'true') {
    return true as T;
  }

  if (envValue === 'false') {
    return false as T;
  }

  if (envValue === undefined) {
    return defaultValue;
  }

  return envValue as T;
};

/**
 * If in development environment, print out the deprecation notices and
 * stack traces to easily identify the deprecated component.
 */
export const notifyDeprecation = (message: string) => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable-next-line no-console */
    console.warn(message);
    /* eslint-disable-next-line no-console */
    console.trace();
  }
};

/**
 * If in development environment, call the provided function.
 */
export const onlyInDevelopment = (cb: Function, message?: string) => {
  if (process.env.NODE_ENV === 'development') {
    if (message != null) {
      /* eslint-disable-next-line no-console */
      console.warn(message);
    }
    cb();
  }
};
