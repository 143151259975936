/* eslint-disable */
// @ts-nocheck
import { SAVINGS_TYPES, SAVINGS_STATUSES, SAVINGS_FREQUENCIES } from 'common/model/savings';
import { NB_SAVINGS_TYPES, NB_SAVINGS_TYPE_ID } from 'common/model/budget';

import { NB_EXPENSES_LABELS } from '@/domain/budget';

import i18n from '@/libs/i18n';

import { getIndexedMonths, getMonthIndex, getCurrentMonthYear, createFutureYearsIterable } from '@/util/date';

import SelectItemWithDescription from '@/components/Field/SelectItemWithDescription';

const WEEKLY_FREQUENCIES = [SAVINGS_FREQUENCIES.WEEKLY, SAVINGS_FREQUENCIES.BI_WEEKLY];

const FREQUENCIES_AND_LABELS = [
  {
    frequency: SAVINGS_FREQUENCIES.WEEKLY,
    label: 'i18n-budget:expense-frequency.weekly',
  },
  {
    frequency: SAVINGS_FREQUENCIES.BI_WEEKLY,
    label: 'i18n-budget:expense-frequency.bi-weekly',
  },
  {
    frequency: SAVINGS_FREQUENCIES.TWICE_MONTH,
    label: 'i18n-budget:expense-frequency.twice-a-month',
  },
  {
    frequency: SAVINGS_FREQUENCIES.MONTHLY,
    label: 'i18n-budget:expense-frequency.monthly',
  },
];

export const shouldUseWeeklyFrequency = frequency => WEEKLY_FREQUENCIES.includes(frequency);

export const getSavingFrequenciesSubtitle = (frequency, replacer, hasBudget = false) => {
  switch (frequency) {
    case SAVINGS_FREQUENCIES.WEEKLY:
      return i18n.t(
        hasBudget
          ? 'i18n-savings:onboarding.weekly-saving-amount-field.has-budget-subtitle'
          : 'i18n-savings:onboarding.weekly-saving-amount-field.has-no-budget-subtitle',
        replacer
      );
    case SAVINGS_FREQUENCIES.BI_WEEKLY:
      return i18n.t(
        hasBudget
          ? 'i18n-savings:onboarding.bi-weekly-saving-amount-field.has-budget-subtitle'
          : 'i18n-savings:onboarding.bi-weekly-saving-amount-field.has-no-budget-subtitle',
        replacer
      );
    case SAVINGS_FREQUENCIES.TWICE_MONTH:
      return i18n.t(
        hasBudget
          ? 'i18n-savings:onboarding.twice-a-monthly-saving-amount-field.has-budget-subtitle'
          : 'i18n-savings:onboarding.twice-a-monthly-saving-amount-field.has-no-budget-subtitle',
        replacer
      );
    case SAVINGS_FREQUENCIES.MONTHLY:
    default:
      return i18n.t(
        hasBudget
          ? 'i18n-savings:onboarding.monthly-saving-amount-field.has-budget-subtitle'
          : 'i18n-savings:onboarding.monthly-saving-amount-field.has-no-budget-subtitle',
        replacer
      );
  }
};
export const getYearsAsOptions = () => {
  const currentMonthYear = getCurrentMonthYear();
  const years = createFutureYearsIterable(Number(currentMonthYear.year), 20);
  return years.map(year => ({
    key: year,
    text: year,
    value: year.toString(),
  }));
};

export const getMonthsAsOptions = () =>
  Object.entries(getIndexedMonths()).map(([monthNumber, monthNameI18nKey]) => ({
    key: monthNumber,
    text: i18n.t(monthNameI18nKey),
    value: monthNumber,
  }));

export const getFrequenciesAsLabels = () => FREQUENCIES_AND_LABELS.map(({ label }) => i18n.t(label));
export const getFrequencyLabel = frequency =>
  i18n.t('i18n-savings:edit-savings.frequency-contribution', {
    frequency: i18n.t(FREQUENCIES_AND_LABELS.find(f => f.frequency === frequency)?.label),
  });

export const getFrequenciesAsOptions = () =>
  FREQUENCIES_AND_LABELS.map(({ frequency, label }) => ({
    key: frequency,
    text: i18n.t(label),
    value: frequency,
    ...(frequency === SAVINGS_FREQUENCIES.TWICE_MONTH && {
      content: (
        <SelectItemWithDescription
          title={i18n.t(label)}
          description={i18n.t('i18n-budget:expense-frequency.twice-a-month.description')}
        />
      ),
    }),
    ...(frequency === SAVINGS_FREQUENCIES.BI_WEEKLY && {
      content: (
        <SelectItemWithDescription
          title={i18n.t(label)}
          description={i18n.t('i18n-budget:expense-frequency.bi-weekly.description')}
        />
      ),
    }),
  }));

const createUniqueLargePurchaseNickname = savingsList => {
  const largePurchaseNickname = NB_EXPENSES_LABELS.SAVINGS.LARGE_PURCHASE.i18nMain();
  const largePurchasesCount = savingsList.filter(s => s.savingsId && s.type === SAVINGS_TYPES.LARGE_PURCHASE).length;
  const pattern = new RegExp(`^${largePurchaseNickname} (\\d+)`, 'i');
  const suffixes = savingsList
    .filter(s => s.savingsId && s.type === SAVINGS_TYPES.LARGE_PURCHASE && s.nickname?.match(pattern))
    .map(s => Number(s.nickname?.match(pattern)[1]));
  let maxSuffix = Math.max(...suffixes);
  maxSuffix = maxSuffix === -Infinity ? 1 : maxSuffix + 1;
  const newSuffix = largePurchasesCount > maxSuffix ? largePurchasesCount : maxSuffix;
  const suffixedNickname = `${largePurchaseNickname} ${newSuffix}`;
  return suffixedNickname;
};

const getSavingsByTypeNickname = (nickname, savingsType, savingsList = []) => {
  if (nickname) return nickname;
  switch (savingsType) {
    case SAVINGS_TYPES.LARGE_PURCHASE: {
      return createUniqueLargePurchaseNickname(savingsList);
    }
    case SAVINGS_TYPES.RAINY_DAY:
    default: {
      return NB_EXPENSES_LABELS.SAVINGS.EMERGENCY_FUND.i18nMain();
    }
  }
};

export type TSavingsData = {
  alreadySavedAmount: number;
  goalTotalAmount: number;
  monthlySavingAmount: number;
  year: number;
  month: number;
  frequency?: string; // replace with actual type if available
  nickname: string;
  milestones: any; // replace with actual type if available
};

export const getSavingsByTypePayload = (
  {
    alreadySavedAmount,
    goalTotalAmount,
    monthlySavingAmount,
    year,
    month,
    frequency = SAVINGS_FREQUENCIES.MONTHLY,
    nickname,
    milestones,
  },
  savingsType,
  savingsList
) => {
  const monthIndex = getMonthIndex(month).toString();
  const currentMonthYear = getCurrentMonthYear();

  const nicknameValue = getSavingsByTypeNickname(nickname, savingsType, savingsList);

  return {
    amountToSave: goalTotalAmount,
    dueDate: new Date(year, monthIndex).getTime(),
    amountAlreadySaved: alreadySavedAmount,
    amountToContributeMonthly: monthlySavingAmount,
    type: savingsType,
    status: SAVINGS_STATUSES.ACTIVE,
    hasDisplayedSavingsPlanMessage: false,
    frequencyOfContribution: frequency,
    nickname: nicknameValue,
    milestones,
    ...currentMonthYear,
  };
};

export const getDepositsPercentageChangeBetweenMonths = (pastMonthDepositsAmount, currentMonthDepositsAmount) => {
  if (!pastMonthDepositsAmount || !currentMonthDepositsAmount) return 0;

  const percentageChange = ((currentMonthDepositsAmount - pastMonthDepositsAmount) / pastMonthDepositsAmount) * 100;
  return Number(percentageChange.toFixed(2));
};

/**
 * Returns the ideal value for calculating frequencies
 * @param {SAVINGS_FREQUENCIES} frequency
 */
export const getFrequencyValue = frequency => {
  switch (frequency) {
    case SAVINGS_FREQUENCIES.WEEKLY:
      return 4;
    case SAVINGS_FREQUENCIES.BI_WEEKLY:
    case SAVINGS_FREQUENCIES.TWICE_MONTH:
      return 2;
    case SAVINGS_FREQUENCIES.MONTHLY:
    default:
      return 1;
  }
};

export const getExpenseBySavingsId = (savingsId, savingsList) => {
  const { type: savingsType } = savingsList.find(savingsItem => savingsItem.savingsId === savingsId);

  switch (savingsType) {
    case SAVINGS_TYPES.RAINY_DAY:
      return NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EMERGENCY_FUND];
    case SAVINGS_TYPES.LARGE_PURCHASE:
      return NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.LARGE_PURCHASE];
    default:
      return NB_SAVINGS_TYPE_ID[NB_SAVINGS_TYPES.EMERGENCY_FUND];
  }
};

export const isRainyDayFund = (savingsId, savingsList) => {
  const { type: savingsType } = savingsList.find(savingsItem => savingsItem.savingsId === savingsId);

  switch (savingsType) {
    case SAVINGS_TYPES.RAINY_DAY:
      return true;
    default:
      return false;
  }
};

export const getSavingTranslatedDefaultNickname = (nickname, savingsType) => {
  return nickname &&
    (savingsType === SAVINGS_TYPES.RAINY_DAY
      ? nickname.toLowerCase() === 'rainy day fund' || nickname.toLowerCase() === 'fondo de día lluvioso'
      : ((nickname.toLowerCase().slice(0, 14) === 'large purchase' && nickname.length === 16) ||
          (nickname.toLowerCase().slice(0, 11) === 'gran compra' && nickname.length === 13)) &&
        !Number.isNaN(nickname.substr(nickname.length - 1) as unknown as number))
    ? savingsType === SAVINGS_TYPES.RAINY_DAY
      ? i18n.t('i18n-budget:rainy-day-fund')
      : `${i18n.t('i18n-budget:large-purchase')} ${nickname.substr(nickname.length - 1)}`
    : nickname;
};

export const isDefaultNickname = (nickname: string, savingsType: string) => {
  if (savingsType === SAVINGS_TYPES.RAINY_DAY) {
    return nickname.toLowerCase() === 'rainy day fund' || nickname.toLowerCase() === 'fondo de día lluvioso';
  } else {
    const nicknameLower = nickname.toLowerCase();
    return (
      (nicknameLower.startsWith('large purchase') || nicknameLower.startsWith('gran compra')) &&
      !isNaN(Number(nicknameLower.slice(-1)))
    );
  }
};
