import React, { FunctionComponent } from 'react';

import Heading from '@/components/Heading';
import CustomContainer from '@/components/CustomContainer';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import Paragraph from '@/components/Paragraph';

import VerticalSpacing from '../VerticalSpacing';

import classes from './styles.module.less';

export type PrerequisiteQuestionsT = {
  heading: string;
  subheader: string;
  expandForm?: boolean;
};

const PrerequisiteQuestions: FunctionComponent<PrerequisiteQuestionsT> = ({ heading, subheader, expandForm }) => {
  return (
    <CustomContainer size="ultrawide" verticallyPadded>
      {heading != null && (
        <>
          <Heading as="h1" className={classes.title}>
            {heading}
          </Heading>
          {subheader != null ? <Paragraph className={classes.subtitle}>{subheader}</Paragraph> : null}
          <VerticalSpacing size={4} />
        </>
      )}

      <QuestionsForm fullWidth={expandForm} />
    </CustomContainer>
  );
};

export default PrerequisiteQuestions;
