import Big from 'big.js';

import { min } from '@/util/Big';

import { TCanLoanBePaidOffParams, TCanLoanBePaidOffResult, TMinMonthlyPaymentParams } from '@/types/domain/calcs';

import calcMinMonthlyPaymentPayOffPromo from './calcMinMonthlyPaymentPayOffPromo';
import calcFirstMonthInterest from './calcFirstMonthInterest';
import calcMonthsPayback from './calcMonthsPayback';
import calcMonthsRemaining from './calcMonthsRemaining';

const calcMinMonthlyPayment = ({
  chargeAmount,
  interestRate,
  monthlyPayment,
  postPromoInterestRate,
  promoPeriodDuration,
  promoPeriod,
}: TMinMonthlyPaymentParams): Big | null => {
  let minMonthlyPaymentPayOffPromo;

  if (promoPeriod) {
    minMonthlyPaymentPayOffPromo = calcMinMonthlyPaymentPayOffPromo({
      chargeAmount,
      promoPeriodDuration,
      promoInterestRate: interestRate,
    });
  }

  const firstMonthInterest = calcFirstMonthInterest({
    chargeAmount,
    interestRate,
    monthlyPayment,
    postPromoInterestRate,
    promoPeriodDuration,
    promoPeriod,
  });

  return minMonthlyPaymentPayOffPromo != null
    ? min(Number(minMonthlyPaymentPayOffPromo), firstMonthInterest)
    : firstMonthInterest;
};

/**
 * Determines if the loan can be paid off by comparing the interest rate in the first month
 * and the suggested monthly payment.
 * CCARD-CALCS calc 2
 */
const calcCanLoanBePaidOff = ({
  chargeAmount, //                    $-AMT
  interestRate, //                    %-INTEREST-RATE
  monthlyPayment, //                  $-MONTHLY
  postPromoInterestRate, //           %-POST-PROMO-RATE
  postPromoPeriodRemainingBalance, // $-REMAINING-AMT
  promoPeriodDuration, //             PROMO_MONTHS
  promoMonthsUsed, //             PROMO_MONTHS_USED
  promoPeriod,
}: TCanLoanBePaidOffParams): TCanLoanBePaidOffResult => {
  const minimalMonthlyPayment = calcMinMonthlyPayment({
    chargeAmount, //                    $-AMT
    interestRate, //                    %-INTEREST-RATE
    monthlyPayment, //                  $-MONTHLY
    postPromoInterestRate, //           %-POST-PROMO-RATE
    promoPeriodDuration, //             PROMO_MONTHS
    promoPeriod,
  });

  const loanCannotBePaidOff = minimalMonthlyPayment?.gt(monthlyPayment) ?? false;

  if (loanCannotBePaidOff) {
    /**
     * If the loan cannot be paid off, do not continue with calcs.
     */
    return {
      firstMonthInterest: minimalMonthlyPayment, //  <$-NO-PROMO-FIRST-MONTH-INTEREST> or <$-POST-PROMO-FIRST-MONTH-INTEREST>
      minimalMonthlyPayment,
      loanCannotBePaidOff, // <NO-PROMO-LOAN-CAN-BE-PAID-OFF> or <POST-PROMO-LOAN-CAN-BE-PAID-OFF>
    };
  }

  const monthsRemaining = calcMonthsRemaining({
    monthlyPayment, //                  $-MONTHLY
    postPromoInterestRate, //           %-POST-PROMO-RATE
    postPromoPeriodRemainingBalance, // $-REMAINING-AMT
    promoPeriod,
  });
  const monthsPayback = calcMonthsPayback({
    chargeAmount, //                    $-AMT
    interestRate, //                    %-INTEREST-RATE
    monthlyPayment, //                  $-MONTHLY
    promoMonthsUsed, //             PROMO-MONTHS-USED
    monthsRemaining,
  });

  return {
    firstMonthInterest: minimalMonthlyPayment, //  <$-NO-PROMO-FIRST-MONTH-INTEREST> or <$-POST-PROMO-FIRST-MONTH-INTEREST>
    minimalMonthlyPayment,
    loanCannotBePaidOff, // <NO-PROMO-LOAN-CAN-BE-PAID-OFF> or <POST-PROMO-LOAN-CAN-BE-PAID-OFF>
    monthsPayback, //       <MONTHS-PAYBACK>
    monthsRemaining, //     <MONTHS-REMAINING>
    promoMonthsUsed, //     <PROMO-MONTHS-USED>
  };
};

export default calcCanLoanBePaidOff;
