import { ActionFunction, assign, EventObject } from 'xstate';
import { SAVINGS_TYPES } from 'common/model/savings';

import {
  getNewSavingsAfterDeleting,
  getNewSavingsAfterLoading,
  getNewSavingsAfterUpserting,
  LATEST_CREATED_SAVINGS,
} from '@/domain/savings';
import {
  BASE_PATH_LARGE_PURCHASE_SAVINGS,
  SAVINGS_DASHBOARD,
  SAVINGS_LANDING_PAGE_PATH,
  SAVINGS_LARGE_PURCHASE_INTRO_PATH,
  SAVINGS_OVERVIEW_PATH,
  SAVINGS_RAINY_DAY_FUND_CONFIRM_BUDGET_PATH,
  SAVINGS_RAINY_DAY_FUND_INTRO_PATH,
  SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH,
} from '@/domain/paths';

import history from '@/util/history';
import storage from '@/util/storage';

import type { Savings } from '@/types/generated/globalTypes';

import type { InitialContextT } from '..';
import type { ApolloQueryResult } from '@apollo/client';

const navigateToSavingsLanding = () => history.push(SAVINGS_LANDING_PAGE_PATH);

const navigateToSavingsOverview: ActionFunction<InitialContextT, EventObject & { data: { navigate: Function } }> = (
  context,
  event
) => {
  if (event?.data?.navigate) {
    event.data.navigate();
  } else history.push(SAVINGS_DASHBOARD);
};

const storeNewSavingsId = (context: InitialContextT) => {
  const { lastSavingsId } = context.savings;
  storage.setItem(LATEST_CREATED_SAVINGS, lastSavingsId);
};

const navigateToSavingsPlanIntro = (context: InitialContextT) => {
  const { savingsType } = context.savings;
  history.push(savingsType === 'largePurchase' ? SAVINGS_LARGE_PURCHASE_INTRO_PATH : SAVINGS_RAINY_DAY_FUND_INTRO_PATH);
};

const navigateToSavingsConfirmBudget = () => history.push(SAVINGS_RAINY_DAY_FUND_CONFIRM_BUDGET_PATH);

const navigateSavingsPostDelete: ActionFunction<InitialContextT, EventObject & { data: { navigate: Function } }> = (
  _,
  event
) => {
  if (event?.data?.navigate) {
    event.data.navigate();
  } else {
    history.push(SAVINGS_DASHBOARD);
  }
};

const setLoadedSavings = assign<InitialContextT, EventObject & ApolloQueryResult<{ data: { listSavings: Savings[] } }>>(
  {
    savings: (context: InitialContextT, event) => {
      const { listSavings: savings } = event.data.data;

      if (savings?.length) {
        const newSavings = getNewSavingsAfterLoading(savings);

        return {
          ...context.savings,
          list: newSavings,
          isLoaded: true,
        };
      }

      return {
        ...context.savings,
        isLoaded: true,
      };
    },
  }
);

const setNonAuthenticatedLoadedSavings = assign<InitialContextT>({
  savings: (context: InitialContextT) => {
    return {
      ...context.savings,
      isLoaded: true,
    };
  },
});

const setSavings = assign<
  InitialContextT,
  EventObject & {
    data: {
      savingsType: string;
      result: ApolloQueryResult<{ upsertSavings: Savings[] & { savingsId: string } }>;
    };
  }
>({
  savings: (context: InitialContextT, event) => {
    const { upsertSavings: savings } = event.data.result.data;
    const { savingsId } = savings;
    const { savingsType } = event.data;

    const newSavings = getNewSavingsAfterUpserting(context.savings.list, savings);

    const largePurchasePath = `${BASE_PATH_LARGE_PURCHASE_SAVINGS}/${savingsId}${SAVINGS_OVERVIEW_PATH}`;

    const path =
      savingsType === SAVINGS_TYPES.LARGE_PURCHASE ? largePurchasePath : SAVINGS_RAINY_DAY_FUND_OVERVIEW_PATH;
    return {
      ...context.savings,
      list: newSavings,
      isLoaded: true,
      path,
      savingsType,
      lastSavingsId: savingsId,
    };
  },
});

const setSavingsBatch = assign<
  InitialContextT,
  EventObject & {
    data: {
      result: ApolloQueryResult<{
        upsertSavingsBatch: ApolloQueryResult<{ upsertSavings: Savings[] & { savingsId: string } }>[];
      }>;
    };
  }
>({
  savings: (context: InitialContextT, event) => {
    const { upsertSavingsBatch: resultList } = event.data.result.data;

    const savingsList = resultList.map(result => result.data.upsertSavings);

    // update the savings list with the new values using getNewSavingsAfterUpserting
    // for each savings in the list
    const newSavings = savingsList.reduce(
      (acc, savings) => getNewSavingsAfterUpserting(acc, savings),
      context.savings.list
    );

    return {
      ...context.savings,
      list: newSavings,
      isLoaded: true,
    };
  },
});

const setDeletedSavings = assign<
  InitialContextT,
  EventObject & { data: { result: ApolloQueryResult<{ deleteSavings: Savings }> } }
>({
  savings: (context: InitialContextT, event) => {
    const { deleteSavings: savings } = event.data.result.data;
    const newSavings = getNewSavingsAfterDeleting(context.savings.list, savings);

    return {
      ...context.savings,
      lastSavingsId: '',
      list: newSavings,
      isLoaded: true,
    };
  },
});

export {
  setLoadedSavings,
  setSavings,
  setSavingsBatch,
  setDeletedSavings,
  setNonAuthenticatedLoadedSavings,
  navigateToSavingsLanding,
  navigateToSavingsOverview,
  navigateToSavingsPlanIntro,
  navigateToSavingsConfirmBudget,
  navigateSavingsPostDelete,
  storeNewSavingsId,
};
