/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getAnswer } from '@/domain/questions/util';

import useUserState from '@/hooks/useUserState';

import CustomContainer from '@/components/CustomContainer';
import ContainerWithStickyContent from '@/components/ContainerWithStickyContent';
import Divider from '@/components/Divider';
import FormEffect from '@/components/Form/FormEffect';
import Heading from '@/components/Heading';
import QuestionsForm from '@/components/QuestionsWizard/Form';
import { WizardUpNext } from '@/components/UpNext';

import calculatorImage from '@/images/calculatorIcon.svg';

import {
  interestRateQ,
  monthlyPaymentQ,
  promoPeriodQ,
  promoPeriodDurationQ,
  postPromoInterestRateQ,
  duringPromoInterestRateQ,
} from '../questions';

import Form from './Form';
import Results from './Results';
import Provider, { useCreditCardState } from './State';

export const CalculateCostContent = () => {
  const { t } = useTranslation('i18n-credit');
  return (
    <>
      <Heading as="h1" content={t('calculate-cost.content')} />
      <Divider tiny />
    </>
  );
};

const initialValues = {
  [interestRateQ.id]: 18,
  [monthlyPaymentQ.id]: 600,
  [promoPeriodQ.id]: 0,
  [promoPeriodDurationQ.id]: 6,
  [postPromoInterestRateQ.id]: 22,
  [duringPromoInterestRateQ.id]: 0,
};

const getFromStateOrQuestion = (question, { formValues = {} }, userState) => {
  const { id } = question;
  const formValue = formValues[id];

  return formValue == null ? getAnswer(question, userState) : formValue;
};

const CalculateCostInner = ({ fromAssessment = false, userState }) => {
  const { t } = useTranslation('i18n-credit');
  const [state, dispatch] = useCreditCardState();
  const formValues = {
    [interestRateQ.id]: getFromStateOrQuestion(interestRateQ, state, userState),
    [monthlyPaymentQ.id]:
      state.suggestedMonthlyPayment == null
        ? getFromStateOrQuestion(monthlyPaymentQ, state, userState)
        : state.suggestedMonthlyPayment,
    [promoPeriodQ.id]: getFromStateOrQuestion(promoPeriodQ, state, userState),
    [promoPeriodDurationQ.id]: getFromStateOrQuestion(promoPeriodDurationQ, state, userState),
    [postPromoInterestRateQ.id]: getFromStateOrQuestion(postPromoInterestRateQ, state, userState),
    [duringPromoInterestRateQ.id]: getFromStateOrQuestion(duringPromoInterestRateQ, state, userState),
  };

  Object.keys(initialValues).forEach(key => {
    if (formValues[key] === undefined) {
      formValues[key] = initialValues[key];
    }
  });

  const onFormChange = React.useCallback(
    (newValues, updateForm) => {
      updateForm(newValues);

      dispatch({
        type: 'sync-with-form',
        formValues: newValues,
      });
    },
    [dispatch]
  );

  return (
    <CustomContainer noMargin verticallyPadded>
      <CalculateCostContent />
      <QuestionsForm fullWidth backButton={false} initialValues={formValues}>
        {({ updateForm, values }) => {
          const answerWrapper = fromAssessment ? userState : { answers: values };

          return (
            <>
              <FormEffect onChange={newValues => onFormChange(newValues, updateForm)} />
              <ContainerWithStickyContent
                disableSticky
                leftSideContent={
                  <Form answerWrapper={answerWrapper} readOnly={fromAssessment} updateForm={updateForm} />
                }
                rightSideContent={<Results answerWrapper={answerWrapper} readOnly={fromAssessment} />}
              />
              {!fromAssessment && <WizardUpNext imageSrc={calculatorImage}>{t('calculate-cost.wizard')}</WizardUpNext>}
            </>
          );
        }}
      </QuestionsForm>
    </CustomContainer>
  );
};

const CalculateCost = ({ fromAssessment = false }) => {
  const { state, loading, error } = useUserState();
  if (loading) return null;
  if (error) return null;

  return (
    <Provider>
      <CalculateCostInner fromAssessment={fromAssessment} userState={state} />
    </Provider>
  );
};

export default CalculateCost;
