import React from 'react';

type ContextType = {
  activityModalContent: Record<string, unknown> | null;
  setActivityModalContent: React.Dispatch<React.SetStateAction<Record<string, unknown> | null>>;
  savingsFunds: number;
  setSavingsFunds: React.Dispatch<React.SetStateAction<number>>;
  onboardingValues: Record<string, unknown> | null;
  setOnboardingValues: React.Dispatch<React.SetStateAction<Record<string, unknown> | null>>;
  savingsSurveyPayload: Record<string, unknown> | null;
  setSavingsSurveyPayload: React.Dispatch<React.SetStateAction<Record<string, unknown> | null>>;
  alreadySavedDepositPayload: Record<string, unknown> | null;
  setAlreadySavedDepositPayload: React.Dispatch<React.SetStateAction<Record<string, unknown> | null>>;
};

const initialContextValues: ContextType = {} as ContextType;

export const SavingsContext = React.createContext(initialContextValues);

const SavingsProvider = ({ children }) => {
  const [activityModalContent, setActivityModalContent] = React.useState<Record<string, unknown> | null>(null);
  const [onboardingValues, setOnboardingValues] = React.useState<Record<string, unknown> | null>(null);
  const [savingsFunds, setSavingsFunds] = React.useState(0);
  const [savingsSurveyPayload, setSavingsSurveyPayload] = React.useState<Record<string, unknown> | null>(null);
  const [alreadySavedDepositPayload, setAlreadySavedDepositPayload] = React.useState<Record<string, unknown> | null>(
    null
  );

  const contextValue = React.useMemo(
    () => ({
      activityModalContent,
      setActivityModalContent,
      savingsFunds,
      setSavingsFunds,
      onboardingValues,
      setOnboardingValues,
      savingsSurveyPayload,
      setSavingsSurveyPayload,
      alreadySavedDepositPayload,
      setAlreadySavedDepositPayload,
    }),
    [
      activityModalContent,
      setActivityModalContent,
      savingsFunds,
      setSavingsFunds,
      onboardingValues,
      setOnboardingValues,
      savingsSurveyPayload,
      setSavingsSurveyPayload,
      alreadySavedDepositPayload,
      setAlreadySavedDepositPayload,
    ]
  );

  return <SavingsContext.Provider value={contextValue}>{children}</SavingsContext.Provider>;
};

export default SavingsProvider;
