/* eslint-disable */
// @ts-nocheck
export const TAB_NAMES = {
  PLAN: 'plan',
  PROGRESS: 'progress',
};

export const STORAGE_KEY = 'PAYMENT_PLAN_ACTIVE_TAB';

export const ESTIMATED_PLAN_STATE = { WORSE: 'worse', SAME: 'same', BETTER: 'better' };
