import Big from 'big.js';

import { TMaximumPossibleLossParams } from '@/types/domain/calcs';

/**
 * Calculates the maximum possible loss if the loan
 * is not repaid.
 */
const calculateMaximumPossibleLoss = ({
  amountNeeded,
  penalty = 0.2,
  retirementAccountExpectedRateOfReturn,
  tax = 0.1,
  yearsToRetirement,
}: TMaximumPossibleLossParams): Big | number => {
  // uses: <$-AMT> = <$-AMT-NEEDED> / (1 - <%-TAX> - <%-PENALTY>)
  const withdrawalAmount = Big(amountNeeded).div(Big(1).minus(tax).minus(penalty));

  // uses: <$-TOTAL-COST> = <$-AMT> * (1 + <%-RET-RATE>) ^ <YEARS-RET>
  const maximumPossibleLoss = withdrawalAmount.times(
    Big(retirementAccountExpectedRateOfReturn).plus(1).pow(yearsToRetirement)
  );

  return maximumPossibleLoss;
};

export default calculateMaximumPossibleLoss;
