import React, { FocusEvent, MouseEvent } from 'react';
import { Form, SemanticWIDTHS } from 'semantic-ui-react';
import { Field as FormikField, FastField, FieldValidator, FieldProps, FastFieldProps } from 'formik';
import { NumericFormat } from 'react-number-format';
import cx from 'classnames';

import Input from '@/components/input';

import FieldErrorMessage from './FieldErrorMessage';

import classes from './styles.module.less';

/**
 * Wraps together the form field component from formik which provides change and validation handlers,
 * form field from Semantic UI which adds some styling and finally the custom input field we created
 * based on Semantic UI. Handle change, validation, label and error message display.
 * Also supports render props.
 *
 * ```js
 * /**
 * * @param {Object} props
 * * @param {boolean} props.fast - use FastField wrapper from Formik instead of Field: prevents renders unless value of this field changed
 * ```
 */

interface TFieldProps {
  children?: (
    props: FieldProps['field'] & {
      error?: boolean;
      id?: string;
      form: FieldProps['form'];
    }
  ) => React.ReactNode;
  hidden?: boolean;
  className?: string;
  hideError?: boolean;
  id?: string;
  inputType?: string;
  name: string;
  trim?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  labelPosition?: string;
  isCurrencyInput?: boolean;
  width?: string;
  fast?: boolean;
  validate?: FieldValidator;
  formatAnswer?: (value: string) => string;
}

const Field = ({
  children,
  hidden = false,
  className,
  hideError,
  id,
  inputType,
  name,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trim,
  onChange,
  label,
  labelPosition,
  isCurrencyInput,
  width,
  fast = false,
  validate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatAnswer,
  ...inputProps
}: TFieldProps) => {
  const FormikWrapper = fast ? FastField : FormikField;

  return (
    <FormikWrapper name={name} validate={validate}>
      {({ field, form }: FastFieldProps | FieldProps) => {
        // allow onChange to be a prop on a field. formik sets the onChange so we have
        // to cache things and work around it.
        const formikOnChange = field.onChange;
        const onFieldChange = (...args: unknown[]) => {
          if (onChange) {
            // @ts-expect-error Generic function, so we don't know the type of args
            onChange(...args);
          }

          if (formikOnChange) {
            // @ts-expect-error Generic function, so we don't know the type of args
            formikOnChange(...args);
          }
        };

        const handleValueChange = data => {
          const { floatValue } = data;
          const payload = typeof floatValue === 'undefined' ? '' : floatValue;

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          form.setFieldValue(name, payload);
        };

        const handleFocus = (e: FocusEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => {
          const { currentTarget } = e;

          // if current target and isn't already focused
          if (currentTarget && document.activeElement !== currentTarget) {
            currentTarget.select();
          }
        };

        const error = !!form.touched[name] && !!form.errors[name];
        const content =
          typeof children === 'function' ? (
            children({ error, id, form, ...field, ...inputProps, onChange: onFieldChange })
          ) : isCurrencyInput ? (
            <NumericFormat
              {...inputProps}
              {...field}
              value={field.value}
              // Select all text on click
              onChange={undefined}
              id={id || name}
              prefix="$"
              valueIsNumericString
              inputMode="decimal"
              allowNegative={false}
              decimalScale={2}
              thousandSeparator
              onValueChange={data => handleValueChange(data)}
              onFocus={handleFocus}
              onClick={handleFocus}
            />
          ) : (
            <Input
              {...field}
              {...inputProps}
              value={field.value || ''}
              error={error}
              id={id || name}
              onChange={onFieldChange}
              type={inputType}
              label={label}
              labelPosition={labelPosition}
            />
          );

        return (
          <Form.Field
            width={width as SemanticWIDTHS}
            className={cx(className, { [classes.hiddenField]: hidden })}
            error={error}
          >
            {content}
            {!hideError && typeof name === 'string' && <FieldErrorMessage error={error} name={name} dataTestId={id} />}
          </Form.Field>
        );
      }}
    </FormikWrapper>
  );
};

export default Field;
