/* eslint-disable */
// @ts-nocheck

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form as SemanticForm, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import { object, string, ref } from 'yup';
import { showTranslations } from 'translation-check';

import useStorageState from '@/hooks/useStorageState';

import i18n from '@/libs/i18n';

import {
  TEST_CURRENT_DATE_OBJECT_STORAGE_KEY,
  getCurrentMonthYear,
  createMonthYear,
  isDateEqual,
  isDateGreaterThan,
} from '@/util/date';
import localStorage, { createStorage } from '@/util/storage';

import Checkbox from '@/components/Checkbox';
import Form from '@/components/Form';
import Field from '@/components/Field';
import Heading from '@/components/Heading';
import Divider from '@/components/Divider';
import Button, { Clickable } from '@/components/button';
import Image from '@/components/Image';
import VerticalSpacing from '@/components/VerticalSpacing';

import calendarIcon from './calendar-frame.svg';
import closeIcon from './close-frame.svg';

import classes from './styles.module.less';

const storage = createStorage('session');

function isMonthYearUpToTheCurrentMonthYear(monthYear: string | number) {
  const currentMonthYear = createMonthYear(new Date());
  return isDateEqual(currentMonthYear, monthYear) || isDateGreaterThan(currentMonthYear, monthYear);
}

function isValidMonth(month: number) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const year = ref('year').getter(this.parent);
  return isMonthYearUpToTheCurrentMonthYear({ month, year });
}

function isValidYear(year: number) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const month = ref('month').getter(this.parent);
  return isMonthYearUpToTheCurrentMonthYear({ month, year });
}

const validationSchema = object().shape({
  year: string().required('Required.').length(4, 'Invalid (YYYY)').test('isValidYear', 'Invalid', isValidYear),
  month: string()
    .required('Required.')
    .oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], 'Invalid')
    .test('isValidMonth', 'Invalid', isValidMonth),
});

const allowFutureDatesValidationSchema = object().shape({
  year: string().required('Required.').length(4, 'Invalid (YYYY)'),
  month: string()
    .required('Required.')
    .oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], 'Invalid'),
});

/**
 * Displays a dev console used to force a certain state of the application.
 * Right now it can be used to set up a mock date.
 * App is reloaded after applying the change.
 *
 * "Reset app" button will clear the app storage (both local and session) and then reload the app.
 */
const DevConsole = () => {
  const { t } = useTranslation();
  const [testDate, setTestDate] = useStorageState({
    storage,
    key: TEST_CURRENT_DATE_OBJECT_STORAGE_KEY,
    defaultValue: getCurrentMonthYear(),
  });
  const [expanded, setExpanded] = React.useState(false);
  const [allowFutureDates, setAllowFutureDates] = React.useState(false);

  const currentMonthYear = createMonthYear(new Date());
  const active = !isDateEqual(currentMonthYear, testDate);

  const { year, month } = testDate;

  const handleSetTestDate = values => {
    setTestDate(values);
    window.location.reload();
  };

  const handleReset = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    localStorage.clear();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    storage.clear();
    window.location.reload();
  };

  const handleStaticAnalysis = () => {
    showTranslations(i18n);
  };

  return (
    <div className={cx(classes.devConsole, { [classes.retracted]: !expanded })} data-testid="devConsole">
      <Clickable
        className={cx(classes.clickableIcon, { [classes.active]: active })}
        onClick={() => setExpanded(currentExpandedValue => !currentExpandedValue)}
      >
        <Image src={expanded ? closeIcon : calendarIcon} />
      </Clickable>
      <div className={cx(classes.monthFormWrapper, { [classes.active]: active })}>
        <Form
          validateOnBlur
          validationSchema={allowFutureDates ? allowFutureDatesValidationSchema : validationSchema}
          initialValues={{ year, month }}
          onSubmit={handleSetTestDate}
        >
          {() => (
            <>
              <Heading as="h6">{t('month-year')}</Heading>
              <SemanticForm.Group>
                <Field id="monthField" width={2} name="month" />
                -
                <Field id="yearField" width={4} name="year" />
              </SemanticForm.Group>
              <Button fluid size="mini" color="blue" type="submit">
                {t('i18n-actions:button.update')}
              </Button>
            </>
          )}
        </Form>
        <VerticalSpacing size="2" />
        <Checkbox
          checked={allowFutureDates}
          onClick={() => setAllowFutureDates(!allowFutureDates)}
          label={t('i18n-actions:checkbox.allow-future-dates')}
        />
        <Divider />
        <Button onClick={handleReset} fluid size="mini" danger type="button">
          <Icon name="warning sign" />
          {t('i18n-actions:button.reset-app')}
        </Button>
        <Divider />
        <>
          <Heading as="h6">{t('internalization')}</Heading>
          <Button onClick={handleStaticAnalysis} fluid size="mini" color="blue" type="button">
            {t('i18n-actions:button.view-translations')}
          </Button>
        </>
      </div>
    </div>
  );
};

export default DevConsole;
