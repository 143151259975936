/* eslint-disable */
// @ts-nocheck
import { Component } from 'react';
import debounce from 'lodash.debounce';

const DEFAULT_DEBOUNCE_WAIT = 200;
const DEFAULT_DEBOUNCE_OPTIONS = {
  maxWait: 800,
};

export default function debounceRender(
  ComponentToDebounce,
  debounceWait = DEFAULT_DEBOUNCE_WAIT,
  debounceOptions = DEFAULT_DEBOUNCE_OPTIONS
) {
  return class DebouncedContainer extends Component {
    updateDebounced = debounce(this.forceUpdate, debounceWait, debounceOptions);

    shouldComponentUpdate() {
      this.updateDebounced();
      return false;
    }

    componentWillUnmount() {
      this.updateDebounced.cancel();
    }

    render() {
      return <ComponentToDebounce {...this.props} />;
    }
  };
}
