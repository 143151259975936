import { GOALS_LANDING_PAGE_PATH } from './goals';
import { BUDGET_LANDING_PAGE_PATH } from './budget';
import { DEBT_PLAN_LANDING_PAGE_PATH } from './debt';
import { SAVINGS_LANDING_PAGE_PATH } from './savings';

// Reusable Paths

export * from './auto';
export * from './budget';
export * from './home';
export * from './medical';
export * from './other';
export * from './goals';
export * from './savings';

export const PLACEHOLDER_LINK = '/#TODO_LINK';

/** *** MVP ROUTES *** */
export const GRANTS_FEED_PATH = '/grants-feed';
export const CROWDFUNDING_PATH = '/crowdfunding';
export const MY_PLAN_PATH = '/my-plan';
export const ABOUT_PATH = '/about';
export const REDIRECT_PATH = '/redirect';
export const NOT_FOUND_PATH = '/not-found';
export const AUTHENTICATION_CALLBACK_PATH_WITHOUT_LEADING_SLASH = 'authentication';
export const AUTHENTICATION_CALLBACK_PATH = `/${AUTHENTICATION_CALLBACK_PATH_WITHOUT_LEADING_SLASH}`;
export const AUTHENTICATION_SIGNOUT_PATH = '/signout';
export const PERSONAL_ASSESSMENT_GATE_PATH = '/assessment';
export const EMAIL_CONFIRMATION_PAGE_PATH = '/subscription-confirmed';
export const DEEP_LINK_PATH = '/deep';
export const EXPENSE_EDIT = `${MY_PLAN_PATH}/edit`;
export const MY_PLAN_PATH_INTRO_PATH = `${MY_PLAN_PATH}/intro`;
export const SINGLE_EXPENSE_QUESTION_EDIT = `${EXPENSE_EDIT}/:fieldName`;

/** Landing pages * */
export const LANDING_PAGE_PATH = '/';
export const BILLS_LANDING_PAGE_PATH = '/bills';
export const AUTO_VERTICAL_LANDING_PAGE_PATH = `${BILLS_LANDING_PAGE_PATH}/auto`;
export const MEDICAL_VERTICAL_LANDING_PAGE_PATH = `${BILLS_LANDING_PAGE_PATH}/medical`;
export const HOME_VERTICAL_LANDING_PAGE_PATH = `${BILLS_LANDING_PAGE_PATH}/home`;
export const LANDING_PAGE_PATHS = [
  LANDING_PAGE_PATH,
  AUTO_VERTICAL_LANDING_PAGE_PATH,
  MEDICAL_VERTICAL_LANDING_PAGE_PATH,
  HOME_VERTICAL_LANDING_PAGE_PATH,
  GOALS_LANDING_PAGE_PATH,
  BUDGET_LANDING_PAGE_PATH,
  SAVINGS_LANDING_PAGE_PATH,
  DEBT_PLAN_LANDING_PAGE_PATH,
];

/** Legacy Landing Page Paths * */
export const LEGACY_AUTO_VERTICAL_LANDING_PAGE_PATH = '/auto';
export const LEGACY_MEDICAL_VERTICAL_LANDING_PAGE_PATH = '/medical';
export const LEGACY_HOME_VERTICAL_LANDING_PAGE_PATH = '/home';

/** * Onboarding wizard * */
export const BASE_PATH_ONBOARDING_WIZARD = '/onboarding';
export const QUESTIONS_FIRST_PATH = `${BASE_PATH_ONBOARDING_WIZARD}/questions-first`;
export const QUESTIONS_SECOND_PATH = `${BASE_PATH_ONBOARDING_WIZARD}/questions-second`;
export const QUESTIONS_NICKNAME = `${BASE_PATH_ONBOARDING_WIZARD}/questions-nickname`;

/** Funding Flow */
export const BASE_PATH_FUNDING_FLOW = '/funding-flow';
export const FUNDING_FLOW_QUESTIONS_FIRST = `${BASE_PATH_FUNDING_FLOW}/questions-first`;
export const FUNDING_FLOW_QUESTIONS_SECOND = `${BASE_PATH_FUNDING_FLOW}/questions-second`;
export const FUNDING_FLOW_QUESTIONS_SECOND_RETIREMENT = `${BASE_PATH_FUNDING_FLOW}/questions-second-retirement`;
export const FUNDING_FLOW_QUESTIONS_THIRD = `${BASE_PATH_FUNDING_FLOW}/questions-third`;
export const FUNDING_FLOW_QUESTIONS_FOURTH = `${BASE_PATH_FUNDING_FLOW}/questions-fourth`;
export const FUNDING_FLOW_OPTIONS = `${BASE_PATH_FUNDING_FLOW}/options`;

/** Borrow From Retirement Accounts * */
export const BASE_PATH_RETIREMENT_FLOW = `${FUNDING_FLOW_OPTIONS}/retirement-plan`;
export const RETIREMENT_BORROW_AMOUNT_CALC_PATH = `${BASE_PATH_RETIREMENT_FLOW}/borrow-amount-calc`;
export const RETIREMENT_PROS_AND_CONS_PATH = `${BASE_PATH_RETIREMENT_FLOW}/pros-and-cons`;
export const RETIREMENT_COST_CALC_PATH = `${BASE_PATH_RETIREMENT_FLOW}/cost-calc`;
export const RETIREMENT_AFFORDABILITY_PATH = `${BASE_PATH_RETIREMENT_FLOW}/affordability-check`;
export const RETIREMENT_HOW_TO_BORROW_PATH = `${BASE_PATH_RETIREMENT_FLOW}/how-to-borrow`;
export const RETIREMENT_PERSONAL_ASSESSMENT_PATH = `${BASE_PATH_RETIREMENT_FLOW}/personal-assessment`;

/** Personal Loans Flow */
export const BASE_PATH_PERSONAL_LOANS_FLOW = `${BASE_PATH_FUNDING_FLOW}/options/personal-loans`;
export const PERSONAL_LOANS_FLOW_ABOUT_PERSONAL_LOANS_PATH = `${BASE_PATH_PERSONAL_LOANS_FLOW}/about-personal-loans`;
export const PERSONAL_LOANS_FLOW_PROS_AND_CONS_PATH = `${BASE_PATH_PERSONAL_LOANS_FLOW}/pros-and-cons`;
export const PERSONAL_LOANS_FLOW_COST_ESTIMATE_PATH = `${BASE_PATH_PERSONAL_LOANS_FLOW}/cost-estimate`;
export const PERSONAL_LOANS_FLOW_AFFORDABILITY_PATH = `${BASE_PATH_PERSONAL_LOANS_FLOW}/affordability`;
export const PERSONAL_LOANS_FLOW_HOW_TO_GET_PATH = `${BASE_PATH_PERSONAL_LOANS_FLOW}/how-to-get`;
export const PERSONAL_LOANS_PERSONAL_ASSESSMENT_PATH = `${BASE_PATH_PERSONAL_LOANS_FLOW}/personal-assessment`;

/** Home Equity wizard * */
export const BASE_PATH_HOME_EQUITY_WIZARD = `${FUNDING_FLOW_OPTIONS}/home-equity`;
export const UNDERSTANDING_HOME_EQUITY_PATH = `${BASE_PATH_HOME_EQUITY_WIZARD}/understanding-home-equity`;
export const OPTIONS_FOR_BORROWING_PATH = `${BASE_PATH_HOME_EQUITY_WIZARD}/options-for-borrowing`;
export const HOME_EQUITY_PROS_AND_CONS_PATH = `${BASE_PATH_HOME_EQUITY_WIZARD}/pros-and-cons`;
export const HOME_EQUITY_COST_COMPARISON_PATH = `${BASE_PATH_HOME_EQUITY_WIZARD}/cost-comparison`;
export const HOME_EQUITY_HOW_TO_BORROW_PATH = `${BASE_PATH_HOME_EQUITY_WIZARD}/how-to-borrow`;
export const HOME_EQUITY_ASSESSMENT_PATH = `${BASE_PATH_HOME_EQUITY_WIZARD}/personal-assessment`;

/** Help Flow * */
export const BASE_PATH_HELP_FLOW = '/help-flow';
export const HELP_FLOW_QUESTIONS = `${BASE_PATH_HELP_FLOW}/questions-first`;
export const HELP_FLOW_OPTIONS = `${BASE_PATH_HELP_FLOW}/options`;
export const HELP_FLOW_CHARITY_CARE_PATH = `/charity-care`;

/** Free Funds Flow */
export const BASE_PATH_FREE_FLOW = '/free-funds';
export const FREE_FLOW_QUESTIONS_FIRST = `${BASE_PATH_FREE_FLOW}/questions-first`;
export const FREE_FLOW_QUESTIONS_SECOND = `${BASE_PATH_FREE_FLOW}/questions-second`;
export const FREE_FLOW_OPTIONS = `${BASE_PATH_FREE_FLOW}/options`;
export const FREE_FLOW_CHARITY_CARE_PATH = '/charity-care';

/** Credit Card Flow */
export const BASE_PATH_CREDIT_CARD_FLOW = `${FUNDING_FLOW_OPTIONS}/credit-card`;
export const CREDIT_CARD_FLOW_ABOUT_CREDIT_CARDS_PATH = `${BASE_PATH_CREDIT_CARD_FLOW}/about-credit-cards`;
export const CREDIT_CARD_FLOW_PROS_AND_CONS_PATH = `${BASE_PATH_CREDIT_CARD_FLOW}/pros-and-cons`;
export const CREDIT_CARD_FLOW_CALCULATE_COST_PATH = `${BASE_PATH_CREDIT_CARD_FLOW}/cost-estimate`;
export const CREDIT_CARD_FLOW_HOW_TO_GET_PATH = `${BASE_PATH_CREDIT_CARD_FLOW}/how-to-get`;
export const CREDIT_CARD_FLOW_PERSONAL_ASSESSMENT_PATH = `${BASE_PATH_CREDIT_CARD_FLOW}/personal-assessment`;

/** Retirement Account Flow */
export const RETIREMENT_ACCOUNT_FLOW = '/retirement';
export const RETIREMENT_ACCOUNT_SAVINGS_ACCOUNT = `${RETIREMENT_ACCOUNT_FLOW}/separate-savings-account`;

/** Content pages related to flows */
export const ALTERNATIVE_FINANCING_OPTIONS_PATH = '/alternative-financing-options';
export const LEGAL_HELP_PATH = '/legal-help';
export const LEARN_ABOUT_BANKRUPTCY_PATH = '/learn-about-bankruptcy';
export const REFINANCE_YOUR_MORTGAGE = '/refinance-your-mortgage';
export const TAP_YOUR_SAVINGS_PATH = '/tap-your-savings';
export const TAP_YOUR_INVESTMENT_ACCOUNTS_PATH = '/tap-your-investment-accounts';
export const TAP_YOUR_LIFE_INSURANCE_POLICY_PATH = '/tap-your-life-insurance-policy';
export const WITHDRAW_FROM_RETIREMENT_PLAN = '/withdraw-retirement-plan';
export const WITHDRAW_FROM_YOUR_IRA = '/withdraw-from-your-ira';

/** Dashboard routes */
export const DASHBOARD_PAGE_PATH = '/dashboard';
export const DASHBOARD_TRANSACTIONS_PATH = `${DASHBOARD_PAGE_PATH}/record-transactions`;
export const DASHBOARD_VERTICAL_SELECT_PATH = `${DASHBOARD_PAGE_PATH}/vertical-select`;
export const DASHBOARD_UNPLANNED_EXPENSES_PATH = `${DASHBOARD_PAGE_PATH}/unplanned-expense`;
export const DASHBOARD_COMPLETED_EXPENSES_PATH = `${DASHBOARD_PAGE_PATH}/completed-expense`;
/** Student Loan Flow */
export const STUDENT_LOAN_FLOW = '/student-loan';
export const STUDENT_LOAN_RELIEF_OPTIONS = `${STUDENT_LOAN_FLOW}/explore-relief-options`;

/** New Month Flow */
export const HOW_IT_WORKS_PAGE = '/how-it-works';

/** Public Calculators */
export const PUBLIC_PERSONAL_LOAN_FLOW_BASE = '/personal-loan-calculator';
export const PUBLIC_CREDIT_CARD_FLOW_BASE = '/credit-card-payoff-calculator';
export const PUBLIC_RETIREMENT_PLAN_FLOW_BASE = '/retirement-plan-loan-calculator';

export const WELCOME_PAGE = '/welcome';
