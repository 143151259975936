import React from 'react';
import cx from 'classnames';
import { Button as SemanticButton } from 'semantic-ui-react';

import { isRedirectOrExternalLink, isValidToLocation } from '@/util/url';

import { UniversalLink } from '@/components/link';

import classes from './Button.module.less';

import type { ButtonProps, SemanticShorthandItem, IconProps } from 'semantic-ui-react';

type CustomButtonProps = ButtonProps & {
  to?: string | null;
  /** Our extension of "negative" prop supported in Semantic */
  danger?: boolean;
  rounded?: boolean;
  fluidOnMobile?: boolean;
  innerRef?: React.LegacyRef<SemanticButton>;
  /** The button can open the link in a new tab if target prop is specified as _blank */
  target?: string;
  /** Applies a semi-bold style to the button text for ease of use */
  semibold?: boolean;
};

type LinkPropsForButtonT = CustomButtonProps & { icon?: SemanticShorthandItem<IconProps> };

type ClickableProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  'data-testid'?: string;
};

// Returns whether any emphasis props have been set for a Button
const hasEmphasisSet = ({ primary, secondary }: CustomButtonProps) => primary || secondary;

// Generates props for when a Button is used as a link
const getLinkPropsForButton = ({ to = null, icon, ...rest }: LinkPropsForButtonT) => ({
  // Set required props for all links
  ...(isValidToLocation(to)
    ? {
        as: UniversalLink,
        role: 'link',
        to,
      }
    : {}),
  ...(isRedirectOrExternalLink(to)
    ? {
        // Fallback to external alternate icon for external link buttons if it's not explicitly omitted
        icon: icon || 'external alternate',
        // Default to secondary for redirect for external link buttons
        ...(!hasEmphasisSet(rest) ? { secondary: true } : {}),
      }
    : {}),
});

/**
 * Button component based on SUI's implementation. Added styles on top + overrides.
 * Setting the loading prop also makes the button disabled, unless disabled prop is explicitly set to false.
 * Supports rendering as anchor if used as link.
 */
const Button = ({
  className,
  innerRef,
  fluidOnMobile = false,
  fluidOnTabAndMobile = false,
  rounded = true,
  danger = false,
  primary = true,
  semibold = false,
  ...rest
}: CustomButtonProps) => {
  const linkProps = getLinkPropsForButton({ ...rest });
  const disabledIfLoading = {
    ...(rest.loading && rest.disabled !== false ? { disabled: true } : {}),
  };

  const secondary = danger ? true : rest.secondary;
  primary = secondary ? false : primary;

  return (
    <SemanticButton
      ref={innerRef}
      className={cx(className, {
        [classes.fluidOnMobile]: fluidOnMobile,
        [classes.fluidOnTabAndMobile]: fluidOnTabAndMobile,
        [classes.danger]: danger,
        [classes.rounded]: rounded,
        [classes.semibold]: semibold,
      })}
      secondary={secondary}
      primary={primary}
      {...linkProps}
      {...rest}
      {...disabledIfLoading}
    />
  );
};

/**
 * Wraps any allowed content with a button to make it clickable
 */
export const Clickable = React.forwardRef<HTMLButtonElement, ClickableProps>(
  (
    {
      id,
      className = '',
      children,
      disabled,
      onClick,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      tabIndex,
      type = 'button',
      role,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-checked': ariaChecked,
      'aria-selected': ariaSelected,
      'aria-controls': ariaControls,
      'data-testid': dataTestId,
    },
    ref
  ) => (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      role={role}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      aria-checked={ariaChecked}
      aria-labelledby={ariaLabelledBy}
      aria-selected={ariaSelected}
      aria-controls={ariaControls}
      className={cx(classes.clickable, className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      ref={ref}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  )
);

export default Button;
