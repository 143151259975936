/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

const initialState = {
  showUpdateBox: false,
};

export const Context = React.createContext(initialState);

const areObjectsEqual = (obj1, obj2) => {
  if (!obj1 || !obj2) {
    return false;
  }

  const obj1Keys = Object.keys(obj1).sort();
  const obj2Keys = Object.keys(obj2).sort();

  if (JSON.stringify(obj1Keys) !== JSON.stringify(obj2Keys)) {
    return false;
  }

  return obj1Keys.every(key => obj1[key] === obj2[key]) && obj2Keys.every(key => obj1[key] === obj2[key]);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'sync-with-form':
      if (areObjectsEqual(state.formValues, action.formValues)) {
        return state;
      }

      return {
        ...state,
        formValues: { ...action.formValues },
      };
    case 'toggle-update-box':
      return {
        ...state,
        showUpdateBox: action.show,
      };
    case 'use-suggested-monthly-payment':
      return {
        ...state,
        cachedMonthlyPayment: action.cachedMonthlyPayment,
        suggestedMonthlyPayment: action.suggestedMonthlyPayment,
      };
    case 'clear-suggested-monthly-payment':
      return {
        ...state,
        cachedMonthlyPayment: null,
        suggestedMonthlyPayment: null,
      };
    default:
      throw new Error('Unhandled action type in credit card state reducer.');
  }
};

const Provider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export default Provider;

export const useCreditCardState = () => React.useContext(Context);
