import React, { FunctionComponent, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import type { Location } from 'history';

const SCROLL_TO_TOP_EXCEPTIONS: string[] = [];

export type ScrollToTopT = {
  location: Location;
  children?: ReactElement;
  elementId?: string | null;
} & RouteComponentProps;

const ScrollToTop: FunctionComponent<ScrollToTopT> = ({ children, location, elementId = null }) => {
  const { pathname } = location;
  React.useEffect(() => {
    const shouldScrollToTop = !(!children && SCROLL_TO_TOP_EXCEPTIONS.find(exception => exception.match(pathname)));
    if (shouldScrollToTop) {
      if (elementId) {
        const element = document.getElementById(elementId);

        if (element) {
          element.scrollTo(0, 0);
        }
      } else window.scrollTo(0, 0);
    }
  }, [children, pathname, elementId]);
  return children || null;
};

export default withRouter(ScrollToTop);
