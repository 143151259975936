/* eslint-disable */
// @ts-nocheck
import { useTranslation, Trans } from 'react-i18next';

import { envOrDefault } from '@/util/env';

import Image from '@/components/Image';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import Button from '@/components/button';
import CustomContainer from '@/components/CustomContainer';
import VerticalSpacing from '@/components/VerticalSpacing';

import errorImg from './No-Internet-Icon.svg?bundle';
import authErrorImg from './No-Auth-Icon.svg';

import classes from './styles.module.less';

const contactEmail = envOrDefault('REACT_APP_CONTACT_PLAIN_EMAIL');

const ButtonsPanel = ({ retry, goBack }) => {
  const { t } = useTranslation('i18n-actions');

  return (
    <>
      <span className={classes.rightSpacing}>
        <Button secondary onClick={goBack}>
          {t('button.go-back')}
        </Button>
      </span>
      <Button primary onClick={retry}>
        {t('button.try-again')}
      </Button>
    </>
  );
};

const ErrorScreen = ({
  auth = false,
  retry = () => window.location.reload(),
  goBack = () => window.history.back(),
}) => {
  const { t } = useTranslation();

  return (
    <CustomContainer size="medium">
      <VerticalSpacing size={16} />
      <Image size="medium" src={auth ? authErrorImg : errorImg} centered />
      <VerticalSpacing size={8} />
      <Heading as="h1">{t('error-screen.heading')}</Heading>
      <Paragraph>
        <Trans i18nKey="error-screen.paragraph" values={{ contactEmail }}>
          Sorry, but this page didn&apos;t load correctly. Try reloading your browser or checking your internet
          connection. If the problem continues, contact us at <a href={`mailto:${contactEmail}`}>{{ contactEmail }}</a>.
        </Trans>
      </Paragraph>
      <VerticalSpacing />
      <ButtonsPanel auth={auth} retry={retry} goBack={goBack} />
    </CustomContainer>
  );
};

export default ErrorScreen;
