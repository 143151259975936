/* eslint-disable */
// @ts-nocheck
import { createSelector } from 'reselect';

import {
  calculateFundsThatCoverMinMonthlyPayments,
  calculateMinMonthlyPayments,
  calculateSumOfMostOfMinMonthlyPayments,
  getRemainingMinPaymentForAccount,
  doAvailableFundsCoverMinMonthlyPayments,
  checkIfAnyReasonsForDisqualificationPresent,
  getReasonsForDisqualification as queryReasonsForDisqualification,
  canRequiredAmountBeCovered,
  isAnAccountRequiredAmountNotAbleToBeCovered,
  hasPayment,
  hasSuggestedPayment,
  isPaidOff,
  ACCOUNTS_TYPES,
  DISQUALIFICATION_REASONS,
  isRequiredAmountPaid,
  compareSuggestedAmount,
  compareDifferenceInAmountPaidAndOptimimalSuggestedAmount,
} from '@/domain/debtsPlan';
import { isPrioritizedAccount, isEssentialAccount, TAccount } from '@/domain/debts';
import { runPaymentAllocationAlgorithm } from '@/domain/stateMachines/debtManager/debtsPlanMachineHelpers';
import type { AppMachineStateT, InitialContextT } from '@/domain/stateMachines';

import { sortBy, sortEachByAndConcatenate } from '@/util/array';
import { not } from '@/util/function';
import { trans } from '@/util/i18n';
import { getCurrentMonthYear, getMonthYearFromTimestamp } from '@/util/date';

import { getDebtIdFromProps, DEBTS_PLAN_ACCOUNT_SORTING_STRATEGY } from '@/pages/DebtPlan/Debts/selectors';

// required for composition & memoization
export const getDebtsPlan = ({ debtsPlan }: { debtsPlan: InitialContextT['paymentPlan'] }) => debtsPlan;
// TODO: getStateMachineContext should be something to be used in general and not specific to the payment plan.
export const getStateMachineContext = ({ state }: { state: AppMachineStateT }) => state.context;

export const getPreviousMonth = createSelector(getStateMachineContext, context => context.selectedDate);
export const getCurrentMonth = createSelector(getStateMachineContext, context => context.currentDate);
export const getPaymentPLanLastEdited = createSelector(getDebtsPlan, debtsPlan => debtsPlan?.lastEdited);
export const getAvailableFunds = createSelector(getDebtsPlan, debtsPlan => {
  if (!debtsPlan || !debtsPlan.availableFunds) {
    return 0;
  }
  return debtsPlan.availableFunds;
});

export const getArchivedPaymentPlans = createSelector(getStateMachineContext, context => {
  return context.archivedPaymentPlans;
});

export const getAppState = ({ state }) => state;

export const getDisqualificationState = createSelector(getStateMachineContext, ({ reasonsForDisqualification }) =>
  checkIfAnyReasonsForDisqualificationPresent({ reasonsForDisqualification })
);

const getPreviousAmountsPaid = createSelector(getStateMachineContext, ({ previousAmountsPaid }) => previousAmountsPaid);

export const getReasonsForDisqualification = createSelector(
  getStateMachineContext,
  ({ reasonsForDisqualification }) => reasonsForDisqualification
);

const showInsufficientFundsSection = (debt, isDisqualified) => !canRequiredAmountBeCovered(debt) && !isDisqualified;

export const isAccountInInsufficientFundsSection = (debt: TAccount, isDisqualified) =>
  showInsufficientFundsSection(debt, isDisqualified) && !isPaidOff(debt);

export const getAccountsFromDebtsPlan = createSelector(getDebtsPlan, debtsPlan =>
  debtsPlan.amountsPaid.map(({ debt }) => debt)
);

export const getSumOfMinMonthlyPayments = createSelector(
  getDebtsPlan,
  getAccountsFromDebtsPlan,
  (debtsPlan, accounts) => calculateMinMonthlyPayments(accounts)
);

const getSumOfMinMonthlyPaymentsForEssentialAccounts = createSelector(
  getDebtsPlan,
  getAccountsFromDebtsPlan,
  (debtsPlan, accounts) => calculateMinMonthlyPayments(accounts, isEssentialAccount)
);

const getSumOfMostOfMinMonthlyPayments = createSelector(getDebtsPlan, getAccountsFromDebtsPlan, (debtsPlan, accounts) =>
  calculateSumOfMostOfMinMonthlyPayments(accounts)
);

/**
 * Calculates the amount a user is missing to cover min monthly payments for debts that have not been paid the full required amount yet.
 */
export const getAmountToCoverUnpaidMinMonthlyPayments = createSelector(getDebtsPlan, debtsPlan => {
  if (!debtsPlan) return 0;
  const { remainingFunds } = debtsPlan;
  const unpaidMinMonthlyPayments = debtsPlan.amountsPaid.reduce((sum, { amount, debt }) => {
    return sum + getRemainingMinPaymentForAccount(debt, amount);
  }, 0);

  return Math.abs(unpaidMinMonthlyPayments - remainingFunds);
});

const getPreviousSuggestedAmountPaid = createSelector(
  getDebtsPlan,
  getDebtIdFromProps,
  getPreviousAmountsPaid,
  (debtsPlan, debtId, previousAmountsPaid) => {
    if (!debtsPlan || !previousAmountsPaid || !debtsPlan.amountsPaid) {
      return null;
    }

    const { amountsPaid } = debtsPlan;

    const amountPaid = amountsPaid.find(amountPaidItem => amountPaidItem.debt.debtId === debtId);
    const previousAmountPaid = previousAmountsPaid.find(
      previousAmountPaidItem => previousAmountPaidItem.debt.debtId === debtId
    );

    // If there's no suggested payment or previous state of account.
    if (!hasSuggestedPayment(amountPaid) || !previousAmountPaid) {
      return null;
    }

    /*
     * We don't need to show a change tag when the account is just marked as paid
     * since it could be confusing for the user
     */
    if (!hasPayment(previousAmountPaid) && hasPayment(amountPaid)) {
      return null;
    }

    return amountPaid.suggestedAmount !== previousAmountPaid.suggestedAmount
      ? amountPaid.suggestedAmount - previousAmountPaid.suggestedAmount
      : null;
  }
);

export const getDebtViewModels = (
  debtsPlan: InitialContextT['paymentPlan'],
  previousAmountsPaid: InitialContextT['previousAmountsPaid']
) =>
  debtsPlan.amountsPaid.map(amountPaid => {
    const { debt, suggestedAmount, optimalSuggestedAmount, amount, hasContactedCreditor } = amountPaid;
    const previousSuggestedAmount = getPreviousSuggestedAmountPaid(
      { debtsPlan, state: { context: { previousAmountsPaid } } },
      { debtId: debt?.debtId }
    );
    const { nickname } = debt || {};
    const paid = amount !== 0;

    const account = {
      ...debt,
      title: nickname,
      changeTag: previousSuggestedAmount,
      checked: paid || debt?.statementBalance === 0,
      suggestedAmount,
      optimalSuggestedAmount,
      amount,
      hasContactedCreditor,
    };

    return account;
  });

export const calculateIfFundsCoverPayments = createSelector(
  getDebtsPlan,
  getAccountsFromDebtsPlan,
  getSumOfMinMonthlyPayments,
  getSumOfMostOfMinMonthlyPayments,
  getSumOfMinMonthlyPaymentsForEssentialAccounts,
  (
    debtsPlan,
    accounts,
    sumOfMinMonthlyPayments,
    sumOfMostOfMinMonthlyPayments,
    sumOfMinMonthlyPaymentsForEssentialAccounts
  ) => {
    if (!debtsPlan) return {};
    const { availableFunds } = debtsPlan;
    const sumOfMinMonthlyPaymentsForPrioritizedAccounts = calculateMinMonthlyPayments(accounts, isPrioritizedAccount);

    return {
      amountToCoverMinMonthlyPayments: calculateFundsThatCoverMinMonthlyPayments(availableFunds, accounts),
      availableFundsCoverPrioritizedPayments: doAvailableFundsCoverMinMonthlyPayments(
        /**
         * We need to subtract the sumOfMinMonthlyPaymentsForEssentialAccounts since essential accounts
         * take precedence over the prioritized non-essential accounts during allocation
         */
        availableFunds - sumOfMinMonthlyPaymentsForEssentialAccounts,
        sumOfMinMonthlyPaymentsForPrioritizedAccounts
      ),
      sumOfMinMonthlyPayments,
      sumOfMostOfMinMonthlyPayments,
      availableFunds,
      isAnAccountRequiredAmountNotAbleToBeCovered: isAnAccountRequiredAmountNotAbleToBeCovered(
        getDebtViewModels(debtsPlan, [])
      ),
    };
  }
);

const isEssentialDebt = (debt, isDisqualified) =>
  isEssentialAccount(debt) && !showInsufficientFundsSection(debt, isDisqualified) && !isPaidOff(debt);
const isOtherDebt = (debt, isDisqualified) =>
  !isEssentialAccount(debt) && !showInsufficientFundsSection(debt, isDisqualified) && !isPaidOff(debt);
const isInsufficientFundsDebt = (debt, isDisqualified) => isAccountInInsufficientFundsSection(debt, isDisqualified);

const groupDebts = (debts: TAccount[], isDisqualified: boolean) => {
  const groupedDebts: {
    accounts: TAccount[];
    type: string;
    label: JSX.Element;
    isCollapsible: boolean;
  }[] = [];

  const ongoingDebts = debts
    .filter(debt => !isPaidOff(debt))
    .map(debt => ({
      ...debt,
      groupType: isEssentialDebt(debt, isDisqualified)
        ? ACCOUNTS_TYPES.ESSENTIAL_ACCOUNTS
        : isOtherDebt(debt, isDisqualified)
        ? ACCOUNTS_TYPES.OTHER_ACCOUNTS
        : isInsufficientFundsDebt(debt, isDisqualified)
        ? ACCOUNTS_TYPES.INSUFFICIENT_FUNDS_ACCOUNTS
        : undefined,
    }))
    .filter(debt => !!debt.type);

  const paidOffAccounts = debts.filter(isPaidOff);

  if (ongoingDebts.length) {
    groupedDebts.push({
      accounts: ongoingDebts,
      isCollapsible: false,
    });
  }

  if (paidOffAccounts.length) {
    groupedDebts.push({
      accounts: paidOffAccounts,
      type: ACCOUNTS_TYPES.PAID_OFF_ACCOUNTS,
      label: trans('heading.paid-off-amount', { paidOffAccountsLength: paidOffAccounts.length }),
      isCollapsible: true,
    });
  }

  return groupedDebts;
};

export const getSortedDebtViewModels = createSelector(
  getDebtsPlan,
  getPreviousAmountsPaid,
  (debtsPlan, previousAmountsPaid) => {
    return sortBy(getDebtViewModels(debtsPlan, previousAmountsPaid), DEBTS_PLAN_ACCOUNT_SORTING_STRATEGY);
  }
);

/**
 * Sorts the accounts only in the payment plan view, the objective of this algorithm is to have
 * the critical actionable items higher in the list.
 * We still keep using getSortedDebtViewModels since it's needed in other places like the onbording and month transition.
 */
export const getPaymentPlanViewSortedViewModels = createSelector(getSortedDebtViewModels, sortedDebtViewModels => {
  const unpaidAccounts = sortedDebtViewModels.filter(not(hasPayment));
  const paidAccounts = sortedDebtViewModels.filter(hasPayment);
  const lessThanRequiredAccounts = paidAccounts.filter(not(isRequiredAmountPaid));
  const requiredPaidAccounts = paidAccounts.filter(isRequiredAmountPaid);
  const hasSuggestedPaymentRequiredPaidAccounts = requiredPaidAccounts.filter(hasSuggestedPayment);
  const doestNotHaveSuggestedPaymentRequiredPaidAccounts = requiredPaidAccounts
    .filter(not(hasSuggestedPayment))
    .sort(compareDifferenceInAmountPaidAndOptimimalSuggestedAmount);

  // We do not need to sort the accounts that don't have a suggested payment so we just concatenate to the final array
  return sortEachByAndConcatenate(
    [unpaidAccounts, lessThanRequiredAccounts, hasSuggestedPaymentRequiredPaidAccounts],
    compareSuggestedAmount
  ).concat(doestNotHaveSuggestedPaymentRequiredPaidAccounts);
});

export const getGroupedByPaidOffDebtViewModels = createSelector(getSortedDebtViewModels, sortedDebtViewModels => {
  return {
    nonPaidOffAccounts: sortedDebtViewModels.filter(not(isPaidOff)),
    paidOffAccounts: sortedDebtViewModels.filter(isPaidOff),
  };
});

export const getAccountsAlreadyPaidTotalAmount = createSelector(getSortedDebtViewModels, sortedDebtViewModels =>
  sortedDebtViewModels.reduce((sum, account) => sum + account.amount, 0)
);

export const getSumOfDebtMinimumPayments = createSelector(getSortedDebtViewModels, sortedDebtViewModels =>
  sortedDebtViewModels.reduce((sum, account) => sum + account.minMonthlyPayment, 0)
);

export const getSumOfDebtsBudget = createSelector(getSortedDebtViewModels, sortedDebtViewModels =>
  sortedDebtViewModels.reduce((sum, account) => sum + account.debtBudget, 0)
);

export const getGroupedDebtViewModels = createSelector(
  getPaymentPlanViewSortedViewModels,
  getDisqualificationState,
  (sortedDebtViewModels, isDisqualified) => {
    return groupDebts(sortedDebtViewModels, isDisqualified);
  }
);

export const getChangeTagAccounts = createSelector(getGroupedDebtViewModels, groupedDebtViewModels =>
  groupedDebtViewModels
    .reduce<typeof groupedDebtViewModels>((acc, group) => [...acc, ...group.accounts], [])
    .filter(account => account.changeTag)
);

export const getAmountPaidAndSuggestedAmountForDebt = createSelector(
  getDebtsPlan,
  getDebtIdFromProps,
  (debtsPlan, debtId) => {
    if (!debtsPlan || !debtsPlan.amountsPaid) {
      return {};
    }

    return debtsPlan.amountsPaid.find(amountPaid => amountPaid.debt.debtId === debtId) || {};
  }
);

// TODO resolve the payment plan type issue
export const getArchivedPaymentPlanCandidates = createSelector(
  getArchivedPaymentPlans,
  getStateMachineContext,
  (archivedPaymentPlans, context) => {
    return archivedPaymentPlans.map(archivedPlan => {
      // deleted debts are ignored
      const debts = archivedPlan.amountsPaid.reduce((activeDebts, { debtSnapshot, debtId }) => {
        const activeDebt = context.debts.find(d => d.debtId === debtId);

        if (!activeDebt) {
          return activeDebts;
        }

        // type is missing from the snapshot
        return [...activeDebts, { ...debtSnapshot, debtId, type: activeDebt.type }];
      }, []);

      const amountsPaid = archivedPlan.amountsPaid.reduce((activeAmountsPaid, amountPaid) => {
        const activeDebt = debts.find(d => d.debtId === amountPaid.debtId);

        if (!activeDebt) {
          return activeAmountsPaid;
        }

        const debt = { ...activeDebt, ...amountPaid.debtSnapshot };
        const activeAmountPaid = { ...amountPaid, debt };
        delete activeAmountPaid.debtSnapshot;
        delete activeAmountPaid.__typename;
        return [...activeAmountsPaid, activeAmountPaid];
      }, []);

      const paymentPlan = { ...archivedPlan, amountsPaid };
      const reasonsForDisqualification = queryReasonsForDisqualification(debts, paymentPlan);

      return { paymentPlan, debts, reasonsForDisqualification };
    });
  }
);

export const getAllocatedPaymentsForArchivedPaymentPlans = createSelector(
  getArchivedPaymentPlanCandidates,
  paymentPlanCandidates => {
    return paymentPlanCandidates.map(candidate => {
      const { debts, paymentPlan: debtsPlan } = candidate;
      return { ...candidate, ...runPaymentAllocationAlgorithm({ debts, debtsPlan }) };
    });
  }
);

export const getLatestArchivedPaymentPlan = createSelector(getArchivedPaymentPlans, archivedPaymentPlans => {
  if (archivedPaymentPlans.length === 0) {
    return null;
  }

  return archivedPaymentPlans[archivedPaymentPlans.length - 1];
});

/**
 * Compares the statement balances of all accounts that were present both in the current and the most recent archived payment plan.
 * Returns true only if all the balances changed or there were no previous balances recorded.
 */
export const haveAllStatementBalancesChangedFromPreviousPaymentPlan = createSelector(
  getLatestArchivedPaymentPlan,
  getAccountsFromDebtsPlan,
  (latestArchivedPaymentPlan, accounts) => {
    // changed by default since there was no previous payment plan
    if (!latestArchivedPaymentPlan) {
      return true;
    }

    // we only test if the already existing accounts have been changed
    const archivedDebtSnapshots = latestArchivedPaymentPlan.amountsPaid
      .filter(({ debtId }) => Boolean(accounts.find(account => account.debtId === debtId)))
      .map(({ debtId, debtSnapshot }) => ({ ...debtSnapshot, debtId }));

    // changed by default since there is nothing to compare against.
    if (!archivedDebtSnapshots.length) {
      return true;
    }

    // every account present in the snapshot must have a different balance when compared to the current state
    return accounts.every(account => {
      const debtSnapshot = archivedDebtSnapshots.find(snapshot => snapshot.debtId === account.debtId);

      // ignore accounts that did not exist in the previous payment plan iteration
      if (!debtSnapshot) {
        return true;
      }

      return debtSnapshot.statementBalance !== account.statementBalance;
    });
  }
);

export const isDisqualifiedForDelinquency = createSelector(
  getReasonsForDisqualification,
  reasonsForDisqualification =>
    Array.isArray(reasonsForDisqualification) &&
    reasonsForDisqualification.includes(DISQUALIFICATION_REASONS.DEBT_DELINQUENT)
);

export const getDebtBudgetForThisMonth = createSelector(
  getStateMachineContext,
  getSortedDebtViewModels,
  (context, debtAccounts) => {
    // TODO:: May need to calculate/persist statement balance at the start of the month because statement balance can be updated during the month.
    const debtAccountsCount = debtAccounts.length;
    const { month, year } = getCurrentMonthYear();
    const { amountAlreadyPaidThisMonth, statementBalanceThisMonth, startOfMonthStatementBalance } = debtAccounts.reduce(
      (acc, { dateCreated, amount, statementBalance }) => {
        const date = getMonthYearFromTimestamp(dateCreated);
        const isCreatedPreviousMonth = date.month < month || date.year < year;
        const {
          amountAlreadyPaidThisMonth: accAmounts,
          statementBalanceThisMonth: accStatementBalance,
          startOfMonthStatementBalance: startingBalance,
        } = acc;
        return {
          amountAlreadyPaidThisMonth: accAmounts + amount,
          statementBalanceThisMonth: accStatementBalance + statementBalance,
          startOfMonthStatementBalance: startingBalance + (isCreatedPreviousMonth ? statementBalance : 0),
        };
      },
      { amountAlreadyPaidThisMonth: 0, statementBalanceThisMonth: 0, startOfMonthStatementBalance: 0 }
    );

    const { availableFunds = 0 } = context.paymentPlan || {};

    const remainingFundsTowardsUnpaidAccounts = availableFunds - amountAlreadyPaidThisMonth;

    return {
      debtAccounts,
      debtAccountsCount,
      budgetAmount: availableFunds,
      statementBalanceThisMonth,
      amountAlreadyPaidThisMonth,
      startOfMonthStatementBalance,
      remainingFundsTowardsUnpaidAccounts,
    };
  }
);
